import { graphql } from '../../gql';

export const VAGO_DOWNLOAD_APPLICATION_DOCUMENTATION = graphql(`
  query vagoDownloadApplicationDocumentation(
    $token: String!
    $quoteUuid: ID!
    $includeApplication: Boolean
  ) {
    vagoDownloadApplicationDocumentation(
      token: $token
      quoteUuid: $quoteUuid
      includeApplication: $includeApplication
    ) {
      filename
      data
    }
  }
`);
