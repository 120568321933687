// @ts-nocheck
import { useState, useEffect, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import {
  Form,
  TextField,
  Select,
  Checkbox,
} from '@calefy-inc/informedMaterial';
import Tooltip from '@mui/material/Tooltip';
import { connect } from 'react-redux';
import { formStore, ProgramBuilderWizard } from '../../../../../store';

import { EditAllSwitch } from './EditAllSwitch';
import ComponentRegistry from '../../../../common/QuoteComponents';
import { addNodeUnderParent } from 'react-sortable-tree';
import getTopLevelQuestion from '../../../FormBuilder/common/getTopLevelQuestion';
import {
  validateExists,
  filterNotNullishOrEmpty,
  questionInstanceFormDirty,
  validateApiName,
  generateValidateLanguages,
} from './utility';
import { LanguageFields } from './LanguageFields';
import { DisplayFormErrors } from './DisplayFormErrors';

// types and classes
import { FormValues, FormApi, FormState } from 'informed';
import type { $TSFixMe, GenericObject } from '@calefy-inc/utilityTypes';
import { Language } from '../../../../../Typescript/classes';
import {
  ProgramBuilderQuestionInstance,
  ProgramBuilderForm,
} from '../../../classes';
import { HideInLocations } from './HideInLocations';
import { useCanEditQuestion } from '../../../../../hooks';
import LoadingScreen from '../../../../ManagementPanel/components/LoadingScreen';
import { debounce } from 'lodash';

const quoteComponentTypes = ComponentRegistry.getComponentTypes()
  .map(({ type, typeLabel }) => ({ value: type, label: typeLabel }))
  .sort((a, b) => a.label.localeCompare(b.label));

interface QuestionInstanceFormDisplayProps {
  handleClose: $TSFixMe;
  /* searchAndDestroy: $TSFixMe; */
  onQuestionInstanceCreated: $TSFixMe;
  onQuestionInstanceEdited: $TSFixMe;
  onCustomSave: $TSFixMe;
  questionInstance: ProgramBuilderQuestionInstance;
  classes: GenericObject;
  parentForm: $TSFixMe;
  forms: $TSFixMe;
  isEditing: boolean;
  path: $TSFixMe;
  node: $TSFixMe;
  clearPendingQuestion: () => void;
  setDirty: $TSFixMe;
  updateEditAll: $TSFixMe;
  updateForm: (form: $TSFixMe) => void;
  languages: Language[];
  [k: string]: $TSFixMe;
}

/**
 * Displays a form to enter information about a given QuestionInstance
 * @param {function} setDirty - used to set information about whether the form is dirty or not. Used in the FormModal to decide whether to display a confirmation dialog before a user leaves a form that they have entered information into.
 */
export function QuestionInstanceFormDisplay({
  handleClose,
  /* searchAndDestroy, */
  onQuestionInstanceCreated,
  onQuestionInstanceEdited,
  onCustomSave,
  questionInstance,
  classes = {},
  parentForm,
  forms,
  isEditing,
  path,
  node,
  clearPendingQuestion,
  setDirty,
  updateEditAll,
  updateForm,
  languages,
  // @ts-expect-error
  ...otherProps
}: QuestionInstanceFormDisplayProps) {
  /* debugger; */
  const { questionInstances } = parentForm;
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pendingFormState, setPendingFormState] = useState<$TSFixMe>({});
  const [topLevelQuestion, setTopLevelQuestion] =
    useState<ProgramBuilderQuestionInstance | null>(null);
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editAllSiblingFormCount, setEditAllSiblingsFormCount] =
    useState<number>(0);
  // yes, this is formState.values
  const [currentFormValues, setCurrentFormValues] = useState<FormValues>({});
  const [updatedParentForm, setUpdatedParentForm] =
    useState<$TSFixMe>(parentForm);
  const [newQuestionInstance, setNewQuestionInstance] =
    useState<ProgramBuilderQuestionInstance | null>(questionInstance || null);
  const [editAll, setEditAll] = useState<boolean>(
    questionInstance ? questionInstance.options.editAll : false,
  );
  const [unsavedChangesPresent, setUnsavedChangesPresent] =
    useState<boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState<$TSFixMe>();
  const formApiRef = useRef<FormApi>();
  const { global: globalQuestionEditPermissions } =
    useCanEditQuestion(questionInstance);

  // set the top-level question from the passed-in questionInstance
  useEffect(() => {
    let top_level_question = null;
    if (questionInstance) {
      top_level_question = getTopLevelQuestion(
        questionInstance,
        questionInstances,
      );
    } else {
      if (node) {
        top_level_question = getTopLevelQuestion(node, questionInstances);
      }
    }
    setTopLevelQuestion(top_level_question);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // set the editAllSiblingFormCount when we get the top-level questionInstance
  useEffect(() => {
    setEditAllSiblingsFormCount(
      topLevelQuestion && topLevelQuestion.ancillary.parentForms
        ? topLevelQuestion.ancillary.parentForms.filter(
            (myForm) => parentForm.id !== myForm.id,
          ).length
        : 0,
    );
  }, [topLevelQuestion, parentForm]);

  // // update the newQuestionInstance and parentForm as appropriate
  useEffect(() => {
    // first check if the form is empty - if so, clean the question
    if (
      Object.values(currentFormValues).filter(filterNotNullishOrEmpty)
        .length === 0
    ) {
      setNewQuestionInstance(null);
      return;
    }

    /*     const { propsBlob, nonPropProperties, languageAwareProperties } = */
    /*       apportionFormState(currentFormValues); */
    /*     const nonPropProperties = filterPropProperties(currentFormValues); */
    /*     const propsBlob = groupPropProperties(currentFormValues); */

    const dataType =
      ComponentRegistry.getComponentDataType(
        currentFormValues.component as string,
      ) || 'string';

    // if the questionInstance already exists, then we want to grab some (but not all) of the properties to map onto the new question instance

    let generatedQuestionInstance =
      ProgramBuilderQuestionInstance.generateFromValues({
        formValues: currentFormValues,
        languages,
        oldQuestion: questionInstance,
        otherAttributes: {
          dataType,
        },
      });

    // sort out editAll
    if (
      questionInstance &&
      topLevelQuestion &&
      questionInstance.matchById(topLevelQuestion)
    ) {
      generatedQuestionInstance.options.editAll = editAll;
    }

    const gatherSubQuestions = ComponentRegistry.getGatherSubQuestionsFunction(
      currentFormValues.component as string,
    );

    let newUpdatedParentForm = {};
    if (gatherSubQuestions) {
      generatedQuestionInstance = gatherSubQuestions(
        currentFormValues,
        generatedQuestionInstance,
      );
      newUpdatedParentForm = parentForm;
    }

    setNewQuestionInstance(generatedQuestionInstance);
    setUpdatedParentForm(newUpdatedParentForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    questionInstance,
    currentFormValues,
    isEditing,
    parentForm,
    topLevelQuestion,
    editAll,
  ]);

  // Whenever the old and new questionInstances change, update whether there are unsaved changes
  useEffect(() => {
    const changesPresent = questionInstanceFormDirty({
      initialFormValues,
      currentFormValues,
      oldQuestion: questionInstance,
      newQuestion: newQuestionInstance,
    });
    setUnsavedChangesPresent(changesPresent);
  }, [
    questionInstance,
    newQuestionInstance,
    setUnsavedChangesPresent,
    currentFormValues,
    initialFormValues,
  ]);

  // when there are unsaved changes, fire off the setDirty function
  useEffect(() => {
    setDirty(unsavedChangesPresent);
  }, [unsavedChangesPresent, setDirty]);

  // set the initial form values based on the passed-in question instance (only the first time)
  useEffect(() => {
    //console.log('Setting initial form values');
    const initialFormValuesTimer = setTimeout(() => {
      if (questionInstance) {
        setInitialFormValues(questionInstance.convertToFormState());
      } else {
        setInitialFormValues(null);
      }
    }, 0);
    return () => clearTimeout(initialFormValuesTimer);
  }, [questionInstance?.apiName]);

  if (isEditing && !questionInstance) {
    handleClose();
    return null;
  }

  const handleSubmit = (
    // @ts-expect-error
    formState: FormValues,
    questionInstance: ProgramBuilderQuestionInstance | null,
    topLevelQuestion: ProgramBuilderQuestionInstance | null,
  ) => {
    /*console.log('In handleSubmit with questionInstance', questionInstance); */
    if (isEditing) {
      onQuestionInstanceEdited({
        parentForm: updatedParentForm,
        node,
        path,
        questionInstance,
        topLevelQuestion: topLevelQuestion,
      });
    } else {
      onQuestionInstanceCreated({
        parentForm: updatedParentForm,
        forms,
        node,
        path,
        questionInstance,
        topLevelQuestion: topLevelQuestion,
      });
    }
    clearPendingQuestion();
    handleClose();
  };

  const debouncedUpdatedFormState = debounce(
    (formState: FormState) => {
      //console.log('Setting form state');
      setCurrentFormValues(formState.values ? { ...formState.values } : {});
    },
    100,
    {
      initial: false,
      trailing: true,
    },
  );

  if (questionInstance && !initialFormValues) {
    return <LoadingScreen />;
  }
  if (initialFormValues === undefined) {
    return null;
  }
  return (
    <>
      <Form
        className={classes.form}
        autoComplete='off'
        noValidate
        allowEmptyStrings={true}
        initialValues={initialFormValues}
        data-testid='QuestionInstanceForm'
        getApi={(api) => {
          formApiRef.current = api;
        }}
        onChange={debouncedUpdatedFormState}
        onSubmit={(values: FormValues) => {
          handleSubmit(values, newQuestionInstance, topLevelQuestion);
        }}
        validate={generateValidateLanguages(languages)}
      >
        {({ formState }) => {
          let AdditionalCustomizationOptions;
          if (formState.values.component) {
            AdditionalCustomizationOptions =
              ComponentRegistry.getProgramBuilderComponent(
                formState.values.component as string,
              );
          }
          return (
            <>
              <Tooltip
                title={
                  !globalQuestionEditPermissions
                    ? 'You do not have permission to change the component'
                    : ''
                }
              >
                <span>
                  <Select
                    disabled={globalQuestionEditPermissions !== true}
                    fullWidth={globalQuestionEditPermissions !== true}
                    field='component'
                    label='Question Input Type'
                    inputId='component-select'
                    isClearable={true}
                    validate={validateExists}
                    required
                    className={classes.selectField}
                    classes={classes}
                    options={quoteComponentTypes}
                    placeholder='Select the type of UI input displayed to the user'
                    containerTestId='component-selection'
                    fullWidth={true}
                  />
                </span>
              </Tooltip>
              {languages.map((language) => {
                return (
                  <LanguageFields
                    languages={languages}
                    language={language}
                    questionInstance={questionInstance}
                    classes={classes}
                  />
                );
              })}
              <Tooltip
                title={
                  !globalQuestionEditPermissions
                    ? 'You do not have permission to change the API name'
                    : ''
                }
              >
                <span>
                  <TextField
                    disabled={globalQuestionEditPermissions !== true}
                    className={classes.textField}
                    classes={classes}
                    label='Enter the private name of the question. This will be used internally to retrieve client answer data. It will not be shown to the user.'
                    field='apiName'
                    required
                    validate={validateApiName}
                    id='name'
                    variant='standard'
                  />
                </span>
              </Tooltip>
              {AdditionalCustomizationOptions ? (
                <AdditionalCustomizationOptions
                  parentForm={parentForm}
                  questionInstances={questionInstances}
                  classes={classes}
                  languages={languages}
                  questionInstance={questionInstance}
                />
              ) : null}
              <Checkbox
                field='required'
                label='Required'
                helperText='Checking this box will make this question instance required'
                classes={classes}
                className={classes.checkbox}
              />

              {!topLevelQuestion ||
              topLevelQuestion.equals(questionInstance) ? (
                <Checkbox
                  field='askOnRenewal'
                  label='Ask on Renewal'
                  helperText='Checking this box will make this a question asked on the renewal form'
                  classes={classes}
                  className={classes.checkbox}
                />
              ) : null}
              {formState.values.askOnRenewal === true ? (
                <Checkbox
                  field='prefillOnRenewal'
                  label='Prefill answers on renewal'
                  helperText='Checking this prefills this question on a renewal form with the previous answer'
                  classes={classes}
                  className={classes.checkbox}
                />
              ) : null}
              <HideInLocations />
              <EditAllSwitch
                editAll={editAll}
                setEditAll={setEditAll}
                questionInstance={questionInstance}
                disabled={!unsavedChangesPresent}
                form={parentForm}
              />
              <DisplayFormErrors />
              <div className={classes.buttonContainer}>
                <Button onClick={() => handleClose()}>Cancel</Button>
                <Button variant='contained' type='submit'>
                  Submit
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </>
  );
}

/* Theming */
const QuestionInstanceFormWithStyles = withStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectField: {
    margin: `${theme.spacing(2)} 0`,
    '& > label': {
      ...theme.typography.label,
      marginBottom: theme.spacing(1.5),
    },
  },
  textField: {
    margin: `${theme.spacing(2)} 0`,
  },
  checkbox: {
    // marginTop: theme.spacing(1.5),
    '& > label': {
      ...theme.typography.label,
    },
  },
  sliderFormGroup: {
    display: 'flex',
    flexDirection: 'column',
    '& $textField': {
      marginTop: theme.spacing(1.5),
    },
  },
  textarea: {
    maxWidth: '100%',
    margin: `${theme.spacing(3)} 0`,
  },
  calendar: {
    display: 'flex',
    maxWidth: '100%',
    margin: `${theme.spacing(2)} 0`,
  },
  instructionText: {
    ...theme.typography.caption,
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    alignSelf: 'flex-end',
    '& > button': {
      marginLeft: theme.spacing(1.5),
    },
  },
  formErrorText: {
    color: theme.palette.error.main,
  },
  finalLanguageDivider: {
    marginBottom: theme.spacing(2),
  },
}))((props: $TSFixMe) => <QuestionInstanceFormDisplay {...props} />);

/* Redux connect */

function mapStateToProps(state: $TSFixMe) {
  const { questionInstances, forms } = state.formStore;
  const { path, node } = state.programBuilder.pendingQuestion;
  const { languages } = state.programBuilder;

  return {
    forms,
    questionInstances,
    path,
    node,
    languages,
  };
}

function mapDispatchToProps(dispatch: $TSFixMe) {
  function onQuestionInstanceEdited({
    parentForm,
    node,
    path,
    questionInstance,
    editAll,
    topLevelQuestion,
  }: $TSFixMe) {
    dispatch(
      formStore.actions.updateQuestionInstance({
        parentForm,
        node,
        path,
        questionInstance,
        // @ts-expect-error
        editAll,
        topLevelQuestion,
      }),
    );
  }

  function onQuestionInstanceCreated({
    // @ts-expect-error
    node,
    path,
    questionInstance,
    parentForm,
    // @ts-expect-error
    forms,
    editAll,
    topLevelQuestion,
  }: $TSFixMe) {
    const recursiveTransform = (list: Array<$TSFixMe>): $TSFixMe =>
      list.map((ele) => {
        if (
          topLevelQuestion &&
          (ele.id === topLevelQuestion.id ||
            (ele.clientId && ele.clientId === topLevelQuestion.clientId))
        ) {
          return new ProgramBuilderQuestionInstance({
            ...ele,
            ancillary: {
              ...ele.ancillary,
              editAll: editAll,
            },
            title: ele.name,
            children: ele.subQuestions
              ? recursiveTransform(ele.subQuestions)
              : null,
            expanded: true,
          });
        }
        return new ProgramBuilderQuestionInstance({
          ...ele,
          title: ele.name,
          children: ele.subQuestions
            ? recursiveTransform(ele.subQuestions)
            : null,
          expanded: true,
        });
      });
    let form = parentForm;
    if (path) {
      const treeData = form.questionInstances
        ? recursiveTransform(form.questionInstances)
        : [];

      form = new ProgramBuilderForm({
        ...form,
        questionInstances: addNodeUnderParent({
          treeData: treeData,
          parentKey: path[path.length - 1],
          expandParent: true,
          getNodeKey: ({ treeIndex }) => treeIndex,
          newNode: questionInstance,
          addAsFirstChild: false,
        }).treeData,
      });
    } else {
      form = new ProgramBuilderForm({
        ...form,
        questionInstances: form.questionInstances
          ? [...form.questionInstances, questionInstance]
          : [questionInstance], //this seems to be sometimes null?
      });
    }

    return dispatch(formStore.actions.updateTreeStructure({ form }));
  }

  function onCustomSave(
    formState: $TSFixMe,
    onSave: $TSFixMe,
    isEditing: $TSFixMe,
    questionInstance: $TSFixMe,
  ) {
    return onSave(dispatch, formState, isEditing, questionInstance);
  }

  function clearPendingQuestion() {
    dispatch(ProgramBuilderWizard.actions.clearPendingQuestion());
  }

  function updateEditAll(
    form: ProgramBuilderForm,
    question: ProgramBuilderQuestionInstance,
    editAllFlag: boolean,
  ) {
    return dispatch(
      formStore.actions.setEditAll({ form, question, editAllFlag }),
    );
  }

  // TODO actually finish the updating of the form
  function updateForm(form: $TSFixMe): void {
    dispatch(formStore.actions.updateExistingForm({ form }));
  }

  return {
    onQuestionInstanceEdited,
    onQuestionInstanceCreated,
    onCustomSave,
    clearPendingQuestion,
    updateEditAll,
    updateForm,
  };
}

export const QuestionInstanceFormConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default QuestionInstanceFormConnect((mappedState: $TSFixMe) => {
  return <QuestionInstanceFormWithStyles {...mappedState} />;
});
