/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename?: 'Query';
  allAnswerInstances?: Maybe<Array<Maybe<AnswerInstanceType>>>;
  allAuthorizationTokens?: Maybe<Array<Maybe<AuthorizationTokenType>>>;
  /** Return a list of the inputs that users have enetered into the system along with what they ended up selecting */
  allBusinessSelectorInputs?: Maybe<Array<Maybe<BusinessSelectionInputs>>>;
  allBusinessTypes?: Maybe<Array<Maybe<BusinessTypeType>>>;
  allEmptyBusinessSpecificPolicies?: Maybe<Array<Maybe<PolicyType>>>;
  /** Return a list of all of the FinalFormModifiers belonging to the requesting organization. */
  allFinalFormModifiers?: Maybe<Array<Maybe<FinalFormModifierType>>>;
  /** All FinalForms from the requesting organization */
  allFinalForms?: Maybe<Array<Maybe<FinalFormType>>>;
  allIndustries?: Maybe<Array<Maybe<IndustryType>>>;
  allLanguages?: Maybe<Array<Maybe<LanguageType>>>;
  /** Return all of the organizations in the system */
  allOrganizations?: Maybe<Array<Maybe<OrganizationType>>>;
  allPolicies?: Maybe<Array<Maybe<PolicyType>>>;
  allQuestionInstances?: Maybe<Array<Maybe<QuestionInstanceType>>>;
  allQuestionSubQuestionMembership?: Maybe<
    Array<Maybe<QuestionSubQuestionMembershipType>>
  >;
  allQuotes?: Maybe<Array<Maybe<QuoteType>>>;
  allReferrers?: Maybe<Array<Maybe<ReferrerType>>>;
  /** Get all of the extant statistical sessions. TODO: add filtering options */
  allStatisticalSessions?: Maybe<Array<Maybe<StatisticalSessionType>>>;
  answerInstance?: Maybe<AnswerInstanceType>;
  /** Return all of the insurer quotes attached to a quote (application) */
  applicationSpecificInsurerQuotes?: Maybe<Array<Maybe<InsurerQuoteType>>>;
  archivedQuote?: Maybe<ArchivedQuoteType>;
  /** For a given business, return a list of the policies for which forms exist. This take into account things like getting the default forms, &c. */
  availableFormPolicies?: Maybe<Array<Maybe<PolicyType>>>;
  /** Return the form from the requesting organization which matches the requested business and policy */
  businessAndPolicySpecificForm?: Maybe<FinalFormType>;
  /** Get a list of the percentage of applications with a given business type which are completed. */
  businessCompletionPercentages?: Maybe<
    Array<Maybe<BusinessCompletionPercentageType>>
  >;
  businessType?: Maybe<BusinessTypeType>;
  checkFormIntegrity?: Maybe<Scalars['Boolean']>;
  /** Check if a referral code is valid for the current organization. Good code: return true. Bad code: return error */
  checkReferralCodeValidity?: Maybe<Scalars['Boolean']>;
  /** Full check for token validity. Good token: return true. Bad token: returns the error. */
  checkTokenValidity?: Maybe<Scalars['Boolean']>;
  dailyPoliciesData?: Maybe<DailyPoliciesData>;
  dailyQuotesData?: Maybe<DailyQuotesData>;
  doesGeneralInfoExist?: Maybe<Scalars['Boolean']>;
  /** Gets a form by ID. Normally restricted to the requesting organization, but you can optionally pass in a token beloning to Calefy and with is:admin permissions to get ones from another organization. */
  finalForm?: Maybe<FinalFormType>;
  /** Get a single FinalFormModifier, either by specifying the business and policy id or simply by the id of the modifier itself */
  finalFormModifier?: Maybe<FinalFormModifierType>;
  formDataQuery?: Maybe<FormDataType>;
  /** Return the final forms that would be amended by a given final form modifier (specified by business and policy id) */
  formModifierRelevantFinalForms?: Maybe<Array<Maybe<FinalFormType>>>;
  /** Return a list of words along with their frequency for times where someone couldn't find their business */
  genericBusinessWordcloud?: Maybe<Array<Maybe<WordcloudType>>>;
  /** Return a list of words along with their frequency for times where someone couldn't find their business (split up by individual quote) */
  genericBusinessWordcloudIndividualQuotes?: Maybe<
    Array<Maybe<IndividualQuoteWordcloudType>>
  >;
  /** Get a report of the mailing address and date submitted for all applications falling within the date range (if given) for the organization. */
  geographyReport?: Maybe<Array<Maybe<GeographyReportType>>>;
  /** Get the general information form for a specified business (by organization) */
  getGeneralInformationFormForBusiness?: Maybe<FinalFormType>;
  /** Return the unique Insurer Quote corresponding to the quote and insurer quote identifier */
  getInsurerQuote?: Maybe<InsurerQuoteType>;
  /** Get all of the relevant changes to rates */
  getRateTableAudit?: Maybe<Array<Maybe<InsuranceRateAuditType>>>;
  /** Get a task pool */
  getTaskPool?: Maybe<TaskPoolType>;
  /** Get a list of VaGo subscribers (for use in the frontend autocomplete) */
  getVagoSubscribers?: Maybe<Array<Maybe<VaGoSubscriberType>>>;
  industry?: Maybe<IndustryType>;
  locationData?: Maybe<LocationData>;
  locationsFromQuoteHash?: Maybe<Array<Maybe<AnswerInstanceType>>>;
  monthlyQuotesData?: Maybe<MonthlyQuotesData>;
  municipalities?: Maybe<MunicipalitiesType>;
  newQuotesData?: Maybe<NewQuotesData>;
  organizationFinalForms?: Maybe<Array<Maybe<FinalFormType>>>;
  organizationSpecificInsuranceRates?: Maybe<Array<Maybe<InsuranceRateType>>>;
  pinkSlip?: Maybe<PinkSlipType>;
  policy?: Maybe<PolicyType>;
  premiumProposalsFromHash?: Maybe<Array<Maybe<PremiumProposalType>>>;
  prospects?: Maybe<Array<Maybe<ProspectBusinessType>>>;
  questionInstance?: Maybe<QuestionInstanceType>;
  /** This is a list of question instances to be displayed on the form creation page */
  questionsDatabase?: Maybe<Array<Maybe<QuestionInstanceType>>>;
  questionsTableQuestions?: Maybe<QuestionInstanceConnection>;
  /** Get the information for a specified quote. If the quote is complete, then a valid token must be provided. */
  quote?: Maybe<QuoteType>;
  quoteDataQuery?: Maybe<QuoteDataType>;
  quoteFromHash?: Maybe<QuotePdfUnion>;
  /** Get all of the insurance policies belonging to a single quote */
  quoteInsurancePolicies?: Maybe<Array<Maybe<InsurancePolicyType>>>;
  quotePdf?: Maybe<QuotePdf>;
  quoteVersions?: Maybe<Array<Maybe<QuoteVersionData>>>;
  referrerXlsx?: Maybe<Scalars['String']>;
  relayBusinesses?: Maybe<BusinessConnection>;
  relayForms?: Maybe<FinalFormConnection>;
  relayQuotes?: Maybe<QuoteConnection>;
  sendAccountingEmail?: Maybe<SendAccountingEmailType>;
  shallowProspects?: Maybe<ShallowProspectsType>;
  someFinalForms?: Maybe<Array<Maybe<AmendedFinalFormType>>>;
  submissionTimesData?: Maybe<SubmissionTimesData>;
  topBusinessesData?: Maybe<TopBusinessesData>;
  totalQuotesData?: Maybe<TotalQuotesData>;
  triggerQuestionDeduplication?: Maybe<DedupeResults>;
  /** Get the base-64 encoded csv file for the client summary */
  vagoClientSummary?: Maybe<Scalars['String']>;
  vagoCommercialPackBindingOptions?: Maybe<VagoCommercialPackBindingOptions>;
  /** Force creation of the various different pdf and excel files that they want generated and upload them as a zip file. */
  vagoDownloadApplicationDocumentation?: Maybe<VagoDownloadApplicationdocumentationType>;
  /** Get a list of IBC business types for VaGo. ATM this is just using our business list, but in the future we might want to modify this behavviour. */
  vagoIbcList?: Maybe<Array<Maybe<BusinessTypeType>>>;
  waveToken?: Maybe<WaveTokenType>;
  /** Returns the organization from which the request was sent. */
  whoamiOrganization?: Maybe<OrganizationType>;
};

export type QueryAllAuthorizationTokensArgs = {
  token: Scalars['String'];
};

export type QueryAllEmptyBusinessSpecificPoliciesArgs = {
  businessId: Scalars['ID'];
  token: Scalars['String'];
};

export type QueryAllFinalFormsArgs = {
  token: Scalars['String'];
};

export type QueryAllOrganizationsArgs = {
  token: Scalars['String'];
};

export type QueryAllQuotesArgs = {
  search?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type QueryAllReferrersArgs = {
  token: Scalars['String'];
};

export type QueryAllStatisticalSessionsArgs = {
  organization?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type QueryAnswerInstanceArgs = {
  id?: InputMaybe<Scalars['ID']>;
  token: Scalars['String'];
};

export type QueryApplicationSpecificInsurerQuotesArgs = {
  quoteUuid?: InputMaybe<Scalars['String']>;
};

export type QueryArchivedQuoteArgs = {
  archiveID?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type QueryAvailableFormPoliciesArgs = {
  businessId: Scalars['ID'];
  token: Scalars['String'];
};

export type QueryBusinessAndPolicySpecificFormArgs = {
  businessId: Scalars['ID'];
  policyId?: InputMaybe<Scalars['ID']>;
  token?: InputMaybe<Scalars['String']>;
};

export type QueryBusinessTypeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  internalName?: InputMaybe<Scalars['String']>;
};

export type QueryCheckFormIntegrityArgs = {
  email: Scalars['String'];
};

export type QueryCheckReferralCodeValidityArgs = {
  referralCode?: InputMaybe<Scalars['String']>;
};

export type QueryCheckTokenValidityArgs = {
  token: Scalars['String'];
};

export type QueryDailyPoliciesDataArgs = {
  token: Scalars['String'];
};

export type QueryDailyQuotesDataArgs = {
  token: Scalars['String'];
};

export type QueryDoesGeneralInfoExistArgs = {
  businessId: Scalars['ID'];
  token: Scalars['String'];
};

export type QueryFinalFormArgs = {
  id: Scalars['ID'];
  token?: InputMaybe<Scalars['String']>;
};

export type QueryFinalFormModifierArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  policyId?: InputMaybe<Scalars['ID']>;
  raiseErrors?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFormDataQueryArgs = {
  token: Scalars['String'];
};

export type QueryFormModifierRelevantFinalFormsArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
  policyId?: InputMaybe<Scalars['ID']>;
};

export type QueryGenericBusinessWordcloudArgs = {
  token: Scalars['String'];
};

export type QueryGenericBusinessWordcloudIndividualQuotesArgs = {
  token: Scalars['String'];
};

export type QueryGeographyReportArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  organizationName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  token: Scalars['String'];
};

export type QueryGetGeneralInformationFormForBusinessArgs = {
  businessId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetInsurerQuoteArgs = {
  insurerQuoteIdentifier?: InputMaybe<Scalars['String']>;
  quoteUuid?: InputMaybe<Scalars['String']>;
};

export type QueryGetRateTableAuditArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  organizationName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  token: Scalars['String'];
};

export type QueryGetTaskPoolArgs = {
  taskPoolId: Scalars['ID'];
};

export type QueryGetVagoSubscribersArgs = {
  token: Scalars['String'];
};

export type QueryIndustryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryLocationDataArgs = {
  token: Scalars['String'];
};

export type QueryLocationsFromQuoteHashArgs = {
  hash?: InputMaybe<Scalars['String']>;
};

export type QueryMonthlyQuotesDataArgs = {
  token: Scalars['String'];
};

export type QueryMunicipalitiesArgs = {
  municipalities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  province: Scalars['String'];
};

export type QueryNewQuotesDataArgs = {
  token: Scalars['String'];
};

export type QueryOrganizationFinalFormsArgs = {
  includeDefault?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrganizationSpecificInsuranceRatesArgs = {
  token: Scalars['String'];
};

export type QueryPinkSlipArgs = {
  blob?: InputMaybe<Scalars['String']>;
  brokerEmail?: InputMaybe<Scalars['String']>;
  descript?: InputMaybe<Scalars['String']>;
  effectiveDt?: InputMaybe<Scalars['String']>;
  expiryDt?: InputMaybe<Scalars['String']>;
  insuranceAddr?: InputMaybe<Scalars['String']>;
  insuranceCity?: InputMaybe<Scalars['String']>;
  insuranceName?: InputMaybe<Scalars['String']>;
  insurancePostal?: InputMaybe<Scalars['String']>;
  insuranceProv?: InputMaybe<Scalars['String']>;
  insuredAddr1?: InputMaybe<Scalars['String']>;
  insuredAddr2?: InputMaybe<Scalars['String']>;
  insuredCity?: InputMaybe<Scalars['String']>;
  insuredEmail?: InputMaybe<Scalars['String']>;
  insuredName?: InputMaybe<Scalars['String']>;
  insuredPostal?: InputMaybe<Scalars['String']>;
  insuredProv?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  policyNum?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type QueryPolicyArgs = {
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  internalName?: InputMaybe<Scalars['String']>;
};

export type QueryPremiumProposalsFromHashArgs = {
  hash: Scalars['UUID'];
  insurerName: Scalars['String'];
};

export type QueryProspectsArgs = {
  email: Scalars['String'];
  maxApiCalls?: InputMaybe<Scalars['String']>;
  queries: Array<InputMaybe<Scalars['String']>>;
  searchCircles: Array<InputMaybe<ProspectSearchCircleInput>>;
  token: Scalars['String'];
};

export type QueryQuestionInstanceArgs = {
  apiName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryQuestionsDatabaseArgs = {
  query?: InputMaybe<Scalars['String']>;
};

export type QueryQuestionsTableQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fuzzy: Scalars['Boolean'];
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type QueryQuoteArgs = {
  token?: InputMaybe<Scalars['String']>;
  uniqueID: Scalars['String'];
};

export type QueryQuoteDataQueryArgs = {
  token: Scalars['String'];
};

export type QueryQuoteFromHashArgs = {
  delete?: InputMaybe<Scalars['Boolean']>;
  hash: Scalars['String'];
};

export type QueryQuoteInsurancePoliciesArgs = {
  token: Scalars['String'];
  uniqueId: Scalars['String'];
};

export type QueryQuotePdfArgs = {
  archiveID?: InputMaybe<Scalars['String']>;
  downloadType?: InputMaybe<Scalars['String']>;
  renewalOnly?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
  uniqueID: Scalars['String'];
};

export type QueryQuoteVersionsArgs = {
  token: Scalars['String'];
  uniqueID: Scalars['String'];
};

export type QueryReferrerXlsxArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type QueryRelayBusinessesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryRelayFormsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};

export type QueryRelayQuotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};

export type QuerySendAccountingEmailArgs = {
  body: Scalars['String'];
  csvAttachment: Scalars['String'];
  error?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  toEmail: Scalars['String'];
  token: Scalars['String'];
};

export type QueryShallowProspectsArgs = {
  municipalities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  province: Scalars['String'];
  query: Scalars['String'];
  results?: InputMaybe<Scalars['Int']>;
};

export type QuerySomeFinalFormsArgs = {
  business?: InputMaybe<Scalars['ID']>;
  policy?: InputMaybe<Scalars['ID']>;
};

export type QuerySubmissionTimesDataArgs = {
  token: Scalars['String'];
};

export type QueryTopBusinessesDataArgs = {
  token: Scalars['String'];
};

export type QueryTotalQuotesDataArgs = {
  token: Scalars['String'];
};

export type QueryTriggerQuestionDeduplicationArgs = {
  token: Scalars['String'];
};

export type QueryVagoClientSummaryArgs = {
  token: Scalars['String'];
};

export type QueryVagoDownloadApplicationDocumentationArgs = {
  includeApplication?: InputMaybe<Scalars['Boolean']>;
  quoteUuid: Scalars['ID'];
  token: Scalars['String'];
};

export type QueryVagoIbcListArgs = {
  token: Scalars['String'];
};

export type QueryWaveTokenArgs = {
  token: Scalars['String'];
};

export type QueryWhoamiOrganizationArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type AnswerInstanceType = {
  __typename?: 'AnswerInstanceType';
  apiName: Scalars['String'];
  askOnRenewal: Scalars['Boolean'];
  component: Scalars['String'];
  dataType: Scalars['String'];
  date: Scalars['DateTime'];
  defaultValue?: Maybe<Scalars['String']>;
  details?: Maybe<AnswerInstanceDetailsType>;
  displayName: Scalars['String'];
  helpText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  parentAnswer?: Maybe<AnswerInstanceType>;
  parentForm?: Maybe<CompletedFormType>;
  prefillOnRenewal: Scalars['Boolean'];
  propsBlob?: Maybe<Scalars['JSONString']>;
  required: Scalars['Boolean'];
  subAnswers: Array<AnswerInstanceType>;
  value: Scalars['String'];
};

export type AnswerInstanceDetailsType = {
  __typename?: 'AnswerInstanceDetailsType';
  google?: Maybe<GoogleDetailsType>;
  id: Scalars['ID'];
  images: Array<GoogleImageType>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  openingHours: Array<OpeningHoursType>;
  opta?: Maybe<OptaDetailsType>;
  parentAnswer?: Maybe<AnswerInstanceType>;
};

export type GoogleDetailsType = {
  __typename?: 'GoogleDetailsType';
  id: Scalars['ID'];
  numberOfRatings?: Maybe<Scalars['Int']>;
  parentDetails: Array<AnswerInstanceDetailsType>;
  placeId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
};

export type GoogleImageType = {
  __typename?: 'GoogleImageType';
  category: CoreGoogleImageCategoryChoices;
  data?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  parentDetails?: Maybe<AnswerInstanceDetailsType>;
  shortDescription: Scalars['String'];
};

/** An enumeration. */
export enum CoreGoogleImageCategoryChoices {
  /** None */
  A = 'A_',
  /** Map */
  Map = 'MAP',
  /** Satellite */
  Satellite = 'SATELLITE',
  /** Street View */
  StreetView = 'STREET_VIEW',
}

export type OpeningHoursType = {
  __typename?: 'OpeningHoursType';
  close?: Maybe<Scalars['Time']>;
  day: Scalars['Int'];
  id: Scalars['ID'];
  open?: Maybe<Scalars['Time']>;
  parentDetails?: Maybe<AnswerInstanceDetailsType>;
};

export type OptaDetailsType = {
  __typename?: 'OPTADetailsType';
  id: Scalars['ID'];
  optaCommercialPerilScoreData?: Maybe<OptaCommercialPerilScoreDataType>;
  optaFloodscoreData?: Maybe<OptaFloodScoreDataType>;
  optaFusData?: Maybe<OptaFusDataType>;
  optaIclarifyCommercialConstructionData?: Maybe<OptaIclarifyCommercialConstructionDataType>;
  parentDetails: Array<AnswerInstanceDetailsType>;
};

export type OptaCommercialPerilScoreDataType = {
  __typename?: 'OptaCommercialPerilScoreDataType';
  Address?: Maybe<OptaAddressType>;
  AllPerilsScore?: Maybe<CommercialPerilScoreType>;
  CasualtyPerilScores?: Maybe<CasualtyCommercialPerilsType>;
  id: Scalars['ID'];
  Messages?: Maybe<Messages>;
  PropertyPerilScores?: Maybe<PropertyCommercialPerilsType>;
};

export type OptaAddressType = {
  __typename?: 'OptaAddressType';
  id: Scalars['ID'];
  LegalDescription?: Maybe<LegalDescriptionType>;
  Municipality?: Maybe<Scalars['String']>;
  OptaAddressKey?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  Province?: Maybe<Scalars['String']>;
  StreetAddress?: Maybe<Scalars['String']>;
  StreetDirection?: Maybe<Scalars['String']>;
  StreetName?: Maybe<Scalars['String']>;
  StreetNumber?: Maybe<Scalars['String']>;
  StreetType?: Maybe<Scalars['String']>;
  UnitNumber?: Maybe<Scalars['String']>;
};

export type LegalDescriptionType = {
  __typename?: 'LegalDescriptionType';
  id: Scalars['ID'];
  LegalSubdivision?: Maybe<Scalars['String']>;
  Meridian?: Maybe<Scalars['String']>;
  Quarter?: Maybe<Scalars['String']>;
  Range?: Maybe<Scalars['String']>;
  Section?: Maybe<Scalars['String']>;
  Township?: Maybe<Scalars['String']>;
};

export type CommercialPerilScoreType = {
  __typename?: 'CommercialPerilScoreType';
  id: Scalars['ID'];
  LossCostScore?: Maybe<Scalars['Int']>;
  PredictedLossCost?: Maybe<Scalars['Float']>;
};

export type CasualtyCommercialPerilsType = {
  __typename?: 'CasualtyCommercialPerilsType';
  id: Scalars['ID'];
  Peril?: Maybe<Array<Maybe<PerilDetailsType>>>;
};

export type PerilDetailsType = {
  __typename?: 'PerilDetailsType';
  id: Scalars['ID'];
  LossCostScore?: Maybe<Scalars['Int']>;
  PerilType?: Maybe<Scalars['String']>;
  PredictedLossCost?: Maybe<Scalars['Float']>;
};

export type Messages = {
  __typename?: 'Messages';
  id: Scalars['ID'];
  Message?: Maybe<Array<Maybe<Scalars['String']>>>;
  optacommercialperilscoredata?: Maybe<OptaCommercialPerilScoreDataType>;
};

export type PropertyCommercialPerilsType = {
  __typename?: 'PropertyCommercialPerilsType';
  id: Scalars['ID'];
  Peril?: Maybe<Array<Maybe<PerilDetailsType>>>;
};

export type OptaFloodScoreDataType = {
  __typename?: 'OptaFloodScoreDataType';
  Address?: Maybe<OptaAddressType>;
  FloodScores?: Maybe<FloodScoresType>;
  id: Scalars['ID'];
};

export type FloodScoresType = {
  __typename?: 'FloodScoresType';
  FloodMap: Array<FloodMapType>;
  FloodScore: Array<FloodScoreType>;
  id: Scalars['ID'];
};

export type FloodMapType = {
  __typename?: 'FloodMapType';
  Content?: Maybe<ContentType>;
  FloodEvent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ContentType = {
  __typename?: 'ContentType';
  ContentURL?: Maybe<Scalars['String']>;
  Expiry?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  MIMEType?: Maybe<Scalars['String']>;
};

export type FloodScoreType = {
  __typename?: 'FloodScoreType';
  AdditionalDetails: Array<FloodDetailType>;
  AverageAnnualLossScore?: Maybe<Scalars['Int']>;
  AverageAnnualLossValues?: Maybe<FloodValueType>;
  Deductible?: Maybe<Scalars['Float']>;
  Default?: Maybe<Scalars['Boolean']>;
  FloodScore?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  Limit?: Maybe<Scalars['Float']>;
  MultiResidentialModifiers?: Maybe<MultiResidentialConstructionFeaturesType>;
  Premium?: Maybe<FloodValueType>;
  RateFactor?: Maybe<Scalars['Float']>;
  ResidentialModifiers?: Maybe<ResidentialConstructionFeaturesType>;
  Zone?: Maybe<Scalars['String']>;
};

export type FloodDetailType = {
  __typename?: 'FloodDetailType';
  id: Scalars['ID'];
  Key?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type FloodValueType = {
  __typename?: 'FloodValueType';
  Building?: Maybe<Scalars['Float']>;
  Contents?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  Total?: Maybe<Scalars['Float']>;
};

export type MultiResidentialConstructionFeaturesType = {
  __typename?: 'MultiResidentialConstructionFeaturesType';
  BasementPresent?: Maybe<Scalars['Boolean']>;
  ConstructionType?: Maybe<Scalars['String']>;
  FloorLevel?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
};

export type ResidentialConstructionFeaturesType = {
  __typename?: 'ResidentialConstructionFeaturesType';
  ArchitecturalStyleType?: Maybe<Scalars['String']>;
  AuxiliaryHeatingType?: Maybe<Scalars['String']>;
  BathroomCount?: Maybe<Scalars['String']>;
  CommercialIndicator?: Maybe<Scalars['String']>;
  ExteriorWallType?: Maybe<Scalars['String']>;
  FinishedBasement?: Maybe<Scalars['Boolean']>;
  FoundationType?: Maybe<Scalars['String']>;
  GarageNumberOfCars?: Maybe<Scalars['Int']>;
  GarageType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  InspectionDetails?: Maybe<InspectionDetailsType>;
  KitchenCount?: Maybe<Scalars['String']>;
  OutbuildingPresent?: Maybe<Scalars['String']>;
  PlumbingType?: Maybe<Scalars['String']>;
  PrimaryHeatingType?: Maybe<Scalars['String']>;
  RoofType?: Maybe<Scalars['String']>;
  SquareFootage?: Maybe<Scalars['Int']>;
  StoreyCount?: Maybe<Scalars['String']>;
  SwimmingPoolType?: Maybe<Scalars['String']>;
  YearBuilt?: Maybe<Scalars['String']>;
};

export type InspectionDetailsType = {
  __typename?: 'InspectionDetailsType';
  id: Scalars['ID'];
  InspectionDate?: Maybe<Scalars['Date']>;
  SponsoredInspection?: Maybe<Scalars['Boolean']>;
};

export type OptaFusDataType = {
  __typename?: 'OptaFusDataType';
  Address?: Maybe<OptaAddressType>;
  Commercial?: Maybe<FusProductCommercialType>;
  Dwelling?: Maybe<FusProductDwellingType>;
  EarthquakeCodes?: Maybe<EarthquakeCodesType>;
  FusMaps?: Maybe<FusMapsType>;
  HydrantCounts?: Maybe<HydrantCountsType>;
  id: Scalars['ID'];
};

export type FusProductCommercialType = {
  __typename?: 'FusProductCommercialType';
  CommercialGrade: Array<CommercialGradeType>;
  RespondingFirehall?: Maybe<FirehallType>;
  SearchMessage?: Maybe<Scalars['String']>;
};

export type CommercialGradeType = {
  __typename?: 'CommercialGradeType';
  id: Scalars['ID'];
  PublicFireProtectionClassification?: Maybe<Scalars['Int']>;
  RiskSCORDetail: Array<RiskScorDetailType>;
  WaterSystem?: Maybe<Scalars['String']>;
};

export type RiskScorDetailType = {
  __typename?: 'RiskSCORDetailType';
  Adjustment: Array<RiskScorAdjustmentType>;
  RiskSCOR?: Maybe<Scalars['Int']>;
  Type?: Maybe<Scalars['String']>;
};

export type RiskScorAdjustmentType = {
  __typename?: 'RiskSCORAdjustmentType';
  Adjustment?: Maybe<Scalars['String']>;
  AdjustmentType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type FirehallType = {
  __typename?: 'FirehallType';
  Address?: Maybe<Scalars['String']>;
  FirefightersPerFireStation?: Maybe<FirefightersPerFireStationType>;
  GoverningAgency?: Maybe<Scalars['String']>;
  GradingDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  Name?: Maybe<Scalars['String']>;
  ProtectionGradeTerritories?: Maybe<ProtectedGradeTerritoriesType>;
  PumperFlowImperialGallonsPerMinute?: Maybe<Scalars['Int']>;
  RoadDistanceKilometres?: Maybe<Scalars['Float']>;
  SuperiorTankerServiceAccreditationDate?: Maybe<Scalars['Date']>;
  TankerCapacityImperialGallons?: Maybe<Scalars['Int']>;
};

export type FirefightersPerFireStationType = {
  __typename?: 'FirefightersPerFireStationType';
  FullTime?: Maybe<Scalars['Float']>;
  Volunteer?: Maybe<Scalars['Float']>;
};

export type ProtectedGradeTerritoriesType = {
  __typename?: 'ProtectedGradeTerritoriesType';
  Commercial?: Maybe<Scalars['String']>;
  Dwelling?: Maybe<Scalars['String']>;
};

export type FusProductDwellingType = {
  __typename?: 'FusProductDwellingType';
  DwellingGrades?: Maybe<DwellingGradesType>;
  id: Scalars['ID'];
  RespondingFirehall?: Maybe<FirehallType>;
  SearchMessage?: Maybe<Scalars['String']>;
};

export type DwellingGradesType = {
  __typename?: 'DwellingGradesType';
  AllOtherAreasGrade: Array<DwellingProtectedGradeType>;
  FirehallProtectedGrade: Array<DwellingProtectedGradeType>;
  HydrantProtectedGrade: Array<DwellingProtectedGradeType>;
  id: Scalars['ID'];
};

export type DwellingProtectedGradeType = {
  __typename?: 'DwellingProtectedGradeType';
  Grade?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  WaterSystem?: Maybe<Scalars['String']>;
};

export type EarthquakeCodesType = {
  __typename?: 'EarthquakeCodesType';
  EarthquakeCode: Array<EarthquakeCodeType>;
  id: Scalars['ID'];
  SearchMessage?: Maybe<Scalars['String']>;
};

export type EarthquakeCodeType = {
  __typename?: 'EarthquakeCodeType';
  Description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  SystemName?: Maybe<Scalars['String']>;
  Zone?: Maybe<Scalars['String']>;
};

export type FusMapsType = {
  __typename?: 'FusMapsType';
  FusMap: Array<FusMapType>;
  id: Scalars['ID'];
  SearchMessage?: Maybe<Scalars['String']>;
};

export type FusMapType = {
  __typename?: 'FusMapType';
  Business?: Maybe<Scalars['String']>;
  FusContent?: Maybe<ContentType>;
  id: Scalars['ID'];
};

export type HydrantCountsType = {
  __typename?: 'HydrantCountsType';
  HydrantCount: Array<HydrantCountType>;
  id: Scalars['ID'];
  SearchMessage?: Maybe<Scalars['String']>;
};

export type HydrantCountType = {
  __typename?: 'HydrantCountType';
  id: Scalars['ID'];
  NumberOfHydrants: Scalars['Int'];
  Ownership?: Maybe<Scalars['String']>;
  RadiusMetres?: Maybe<Scalars['String']>;
};

export type OptaIclarifyCommercialConstructionDataType = {
  __typename?: 'OptaIclarifyCommercialConstructionDataType';
  Address?: Maybe<OptaAddressType>;
  id: Scalars['ID'];
  Messages?: Maybe<Messages>;
  Property?: Maybe<CommercialPropertyType>;
};

export type CommercialPropertyType = {
  __typename?: 'CommercialPropertyType';
  Buildings?: Maybe<BuildingsType>;
  id: Scalars['ID'];
  Industry?: Maybe<BusinessIndustryType>;
  LotSize?: Maybe<Scalars['Int']>;
  LotSizeUnitOfMeasurement?: Maybe<Scalars['String']>;
  PropertyType?: Maybe<Scalars['String']>;
};

export type BuildingsType = {
  __typename?: 'BuildingsType';
  Building: Array<BuildingType>;
  id: Scalars['ID'];
};

export type BuildingType = {
  __typename?: 'BuildingType';
  ConstructionFeatures?: Maybe<CommercialConstructionFeaturesType>;
  id: Scalars['ID'];
  Protection?: Maybe<ProtectionType>;
  StructureID?: Maybe<Scalars['String']>;
};

export type CommercialConstructionFeaturesType = {
  __typename?: 'CommercialConstructionFeaturesType';
  Basement?: Maybe<CommercialBasementType>;
  BuildingUpdates?: Maybe<BuildingUpdatesType>;
  CombustibilityClass?: Maybe<Scalars['String']>;
  CommercialExteriorWallTypes?: Maybe<CommercialExteriorWallTypesType>;
  ConstructionClass?: Maybe<Scalars['String']>;
  Elevator?: Maybe<ElevatorType>;
  FloorHeight?: Maybe<Scalars['Int']>;
  FloorHeightUnitOfMeasurement?: Maybe<Scalars['String']>;
  Heating?: Maybe<CommercialHeatingType>;
  id: Scalars['ID'];
  LoadingDockPresent?: Maybe<Scalars['Boolean']>;
  NumberOfStoreys?: Maybe<Scalars['Int']>;
  NumberOfUnits?: Maybe<Scalars['Int']>;
  Parking?: Maybe<CommercialParkingType>;
  PlumbingTypes?: Maybe<CommercialPlumbingType>;
  RoofAttributes?: Maybe<RoofAttributesType>;
  TotalFloorArea?: Maybe<Scalars['Float']>;
  TotalFloorAreaUnitOfMeasurement?: Maybe<Scalars['String']>;
  WaterHeater?: Maybe<CommercialWaterHeaterType>;
  Wiring?: Maybe<CommercialWiringType>;
  YearBuilt?: Maybe<Scalars['Int']>;
};

export type CommercialBasementType = {
  __typename?: 'CommercialBasementType';
  BasementArea?: Maybe<Scalars['Int']>;
  BasementAreaUnitOfMeasurement?: Maybe<Scalars['String']>;
  BasementPresent?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type BuildingUpdatesType = {
  __typename?: 'BuildingUpdatesType';
  BuildingUpdate?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id: Scalars['ID'];
};

export type CommercialExteriorWallTypesType = {
  __typename?: 'CommercialExteriorWallTypesType';
  ExteriorWallType?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
};

export type ElevatorType = {
  __typename?: 'ElevatorType';
  ElevatorCount?: Maybe<Scalars['Int']>;
  ElevatorPresent?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type CommercialHeatingType = {
  __typename?: 'CommercialHeatingType';
  HeatingFuelSources?: Maybe<HeatingFuelSourcesType>;
  HeatingTypes?: Maybe<HeatingTypesType>;
  id: Scalars['ID'];
};

export type HeatingFuelSourcesType = {
  __typename?: 'HeatingFuelSourcesType';
  HeatingFuelSource?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
};

export type HeatingTypesType = {
  __typename?: 'HeatingTypesType';
  HeatingType?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
};

export type CommercialParkingType = {
  __typename?: 'CommercialParkingType';
  id: Scalars['ID'];
  ParkingPresent?: Maybe<Scalars['Boolean']>;
  ParkingTypes?: Maybe<CommercialParkingTypesType>;
};

export type CommercialParkingTypesType = {
  __typename?: 'CommercialParkingTypesType';
  id: Scalars['ID'];
  ParkingType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommercialPlumbingType = {
  __typename?: 'CommercialPlumbingType';
  id: Scalars['ID'];
  PlumbingType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoofAttributesType = {
  __typename?: 'RoofAttributesType';
  id: Scalars['ID'];
  RoofConstructions?: Maybe<RoofConstructionsType>;
  RoofSurfaces?: Maybe<RoofSurfacesType>;
  RoofTypes?: Maybe<RoofTypesType>;
};

export type RoofConstructionsType = {
  __typename?: 'RoofConstructionsType';
  id: Scalars['ID'];
  RoofConstruction?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoofSurfacesType = {
  __typename?: 'RoofSurfacesType';
  id: Scalars['ID'];
  RoofSurface?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoofTypesType = {
  __typename?: 'RoofTypesType';
  id: Scalars['ID'];
  RoofType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommercialWaterHeaterType = {
  __typename?: 'CommercialWaterHeaterType';
  id: Scalars['ID'];
  WaterHeaterFuelType?: Maybe<Array<Maybe<Scalars['String']>>>;
  WaterHeaterType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommercialWiringType = {
  __typename?: 'CommercialWiringType';
  id: Scalars['ID'];
  WiringPanelTypes?: Maybe<CommercialWiringPanelTypesType>;
  WiringTypes?: Maybe<CommercialWiringTypesType>;
};

export type CommercialWiringPanelTypesType = {
  __typename?: 'CommercialWiringPanelTypesType';
  id: Scalars['ID'];
  WiringPanelType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CommercialWiringTypesType = {
  __typename?: 'CommercialWiringTypesType';
  id: Scalars['ID'];
  WiringType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProtectionType = {
  __typename?: 'ProtectionType';
  FireAlarmPresent?: Maybe<Scalars['Boolean']>;
  FireAlarmType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  PhysicalSecurityPresent?: Maybe<Scalars['Boolean']>;
  PhysicalSecurityTypes?: Maybe<PhysicalSecurityTypesType>;
  SecurityAlarmPresent?: Maybe<Scalars['Boolean']>;
  SecurityAlarmType?: Maybe<Scalars['String']>;
  SprinklerPresent?: Maybe<Scalars['Boolean']>;
  SprinklerTypeCode?: Maybe<Scalars['String']>;
};

export type PhysicalSecurityTypesType = {
  __typename?: 'PhysicalSecurityTypesType';
  id: Scalars['ID'];
  PhysicalSecurityType?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BusinessIndustryType = {
  __typename?: 'BusinessIndustryType';
  IBCCode?: Maybe<Scalars['String']>;
  IBCIndustryDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  SICCode?: Maybe<Scalars['String']>;
  SICIndustryDescription?: Maybe<Scalars['String']>;
};

export type CompletedFormType = {
  __typename?: 'CompletedFormType';
  answers: Array<AnswerInstanceType>;
  extensions?: Maybe<Array<Maybe<PolicyExtensionType>>>;
  id: Scalars['ID'];
  parentArchivedQuote?: Maybe<ArchivedQuoteType>;
  parentQuote?: Maybe<QuoteType>;
  policy?: Maybe<PolicyType>;
};

export type PolicyExtensionType = {
  __typename?: 'PolicyExtensionType';
  finalForm: AmendedFinalFormType;
  id: Scalars['ID'];
  label: Scalars['String'];
  multiplier?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Like a FinalForm, but merges the question instances with those of the matching OrganizationQuestionsForm, if present  */
export type AmendedFinalFormType = {
  __typename?: 'AmendedFinalFormType';
  businessLine?: Maybe<BusinessTypeType>;
  id: Scalars['ID'];
  organization?: Maybe<OrganizationType>;
  policy?: Maybe<PolicyType>;
  policyExtension: Array<PolicyExtensionType>;
  qualifyingQuestion?: Maybe<QuestionInstanceType>;
  questionInstances: Array<QuestionInstanceType>;
  questionMembership: Array<FormQuestionMembershipType>;
  required: Scalars['Boolean'];
};

export type BusinessTypeType = {
  __typename?: 'BusinessTypeType';
  /** A list of aliases to use to generate 'fake' businesses on the frontend, all of which point back to this original business */
  aliases: Scalars['JSONString'];
  archivedQuotes: Array<ArchivedQuoteType>;
  displayName: Scalars['String'];
  /** The business line which this form refers to. Null means that it applies to all businesses */
  finalFormModifiers: Array<FinalFormModifierType>;
  finalformSet: Array<AmendedFinalFormType>;
  id: Scalars['ID'];
  industry: IndustryType;
  internalName: Scalars['String'];
  isDeletable: Scalars['Boolean'];
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  quotes: Array<QuoteType>;
  sicCode: Scalars['Int'];
};

export type ArchivedQuoteType = {
  __typename?: 'ArchivedQuoteType';
  additionalInformation: Scalars['String'];
  bindings: Array<BindingType>;
  businessLine?: Maybe<BusinessTypeType>;
  completedForms: Array<CompletedFormType>;
  currentVersion?: Maybe<QuoteType>;
  dateAdded?: Maybe<Scalars['DateTime']>;
  dateArchived?: Maybe<Scalars['DateTime']>;
  decodedToken: Scalars['JSONString'];
  friendlyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurancePoliciesForArchived: Array<InsurancePolicyType>;
  language?: Maybe<LanguageType>;
  organizationSpecificQuoteCount?: Maybe<Scalars['Int']>;
  premiumProposals: Array<PremiumProposalType>;
  producerId: Scalars['String'];
  referrer?: Maybe<ReferrerType>;
  signature?: Maybe<SignatureType>;
  status: Scalars['String'];
  structuredData?: Maybe<StructuredQuoteDataType>;
  testing: Scalars['Boolean'];
  uniqueID: Scalars['UUID'];
  vagoCommercialPackStructuredData?: Maybe<VagoCommercialPackStructuredDataType>;
};

export type BindingType = {
  __typename?: 'BindingType';
  archivedQuote?: Maybe<ArchivedQuoteType>;
  bound: Scalars['Boolean'];
  id: Scalars['ID'];
  label: Scalars['String'];
  quote?: Maybe<QuoteType>;
  unboundReason: Scalars['String'];
};

export type QuoteType = {
  __typename?: 'QuoteType';
  additionalInformation: Scalars['String'];
  archives: Array<ArchivedQuoteType>;
  bindings: Array<BindingType>;
  businessLine?: Maybe<BusinessTypeType>;
  combinedPdfBlob?: Maybe<Scalars['String']>;
  completedForms: Array<CompletedFormType>;
  dateAdded: Scalars['DateTime'];
  decodedToken: Scalars['JSONString'];
  /** If this event is associated with an existing quote, mark that here */
  events: Array<StatisticalEventType>;
  friendlyId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurancePolicies: Array<InsurancePolicyType>;
  insurerquoteSet: Array<InsurerQuoteType>;
  language?: Maybe<LanguageType>;
  premiumProposals: Array<PremiumProposalType>;
  producerId: Scalars['String'];
  referrer?: Maybe<ReferrerType>;
  /** The quote associated with this session */
  sessions: Array<StatisticalSessionType>;
  signature?: Maybe<SignatureType>;
  status: Scalars['String'];
  structuredData?: Maybe<StructuredQuoteDataType>;
  testing: Scalars['Boolean'];
  uniqueID: Scalars['UUID'];
  vagoCommercialPackStructuredData?: Maybe<VagoCommercialPackStructuredDataType>;
  withinOrganizationIdProxy?: Maybe<Scalars['Int']>;
};

export type StatisticalEventType = {
  __typename?: 'StatisticalEventType';
  /** Any arbitrary JSON information which is relevant to this event type */
  data: Scalars['JSONString'];
  /** The unique identifier for this particular event */
  eventId: Scalars['UUID'];
  eventType: CoreStatisticalEventEventTypeChoices;
  id: Scalars['ID'];
  /** The organization to which this event is associated. */
  organization?: Maybe<OrganizationType>;
  /** If this event is associated with an existing quote, mark that here */
  quote?: Maybe<QuoteType>;
  /** The parent session for this event. */
  session?: Maybe<StatisticalSessionType>;
  /** The time at which this event was created. */
  timeCreated: Scalars['DateTime'];
  /** If present, the email of the logged in user */
  userEmail?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CoreStatisticalEventEventTypeChoices {
  /** Application Begun */
  ApplicationBegun = 'APPLICATION_BEGUN',
  /** Application Finished */
  ApplicationFinished = 'APPLICATION_FINISHED',
  /** Application Resumed */
  ApplicationResumed = 'APPLICATION_RESUMED',
  /** Application Saved */
  ApplicationSaved = 'APPLICATION_SAVED',
  /** Business Selection Input */
  BusinessSelectionInput = 'BUSINESS_SELECTION_INPUT',
  /** Business Selection Selected */
  BusinessSelectionSelected = 'BUSINESS_SELECTION_SELECTED',
  /** Form Loaded */
  FormLoaded = 'FORM_LOADED',
  /** Page Load */
  PageLoad = 'PAGE_LOAD',
}

export type OrganizationType = {
  __typename?: 'OrganizationType';
  category: CoreOrganizationCategoryChoices;
  contactEmail: Scalars['String'];
  documentLogoPath: Scalars['String'];
  emailLogoUrl?: Maybe<Scalars['String']>;
  /** The organization to which this applies */
  finalFormModifiers: Array<FinalFormModifierType>;
  finalForms: Array<AmendedFinalFormType>;
  id: Scalars['ID'];
  insurancerateauditSet: Array<InsuranceRateAuditType>;
  insurancerateSet: Array<InsuranceRateType>;
  insurerPremiumProposals: Array<PremiumProposalType>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationPremiumProposals: Array<PremiumProposalType>;
  phoneNumber: Scalars['String'];
  quoteDetailsBaseUrl?: Maybe<Scalars['String']>;
  quoteEmails: Scalars['JSONString'];
  /** The address from which to send a quote email */
  quoteFromEmail?: Maybe<Scalars['String']>;
  referrers: Array<ReferrerType>;
  /** The organization to which this event is associated. */
  statisticaleventSet: Array<StatisticalEventType>;
  /** The organization which created this session */
  statisticalSessions: Array<StatisticalSessionType>;
  tokens: Array<AuthorizationTokenType>;
};

/** An enumeration. */
export enum CoreOrganizationCategoryChoices {
  /** Broker */
  Broker = 'BROKER',
  /** Insurer */
  Insurer = 'INSURER',
  /** MGA */
  Mga = 'MGA',
  /** Other */
  Other = 'OTHER',
  /** Vendor */
  Vendor = 'VENDOR',
}

export type FinalFormModifierType = {
  __typename?: 'FinalFormModifierType';
  apiNamesForRemoval?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The business line which this form refers to. Null means that it applies to all businesses */
  business?: Maybe<BusinessTypeType>;
  id: Scalars['ID'];
  /** Whether or not a form exists on this organization which would 'mask' this modifier. */
  masked?: Maybe<Scalars['Boolean']>;
  /** The organization to which this applies */
  organization: OrganizationType;
  /** The policy to which this applies. If null, it refers to a general information form. */
  policy?: Maybe<PolicyType>;
  /** The question instances to add or replace */
  questionInstances: Array<QuestionInstanceType>;
};

export type PolicyType = {
  __typename?: 'PolicyType';
  completedformSet: Array<CompletedFormType>;
  displayName: Scalars['String'];
  /** The policy to which this applies. If null, it refers to a general information form. */
  finalFormModifiers: Array<FinalFormModifierType>;
  finalformSet: Array<AmendedFinalFormType>;
  id: Scalars['ID'];
  internalName: Scalars['String'];
  isDeletable: Scalars['Boolean'];
  premiumProposals: Array<PremiumProposalType>;
};

export type PremiumProposalType = {
  __typename?: 'PremiumProposalType';
  amountInCents: Scalars['Int'];
  archivedQuote?: Maybe<ArchivedQuoteType>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  insurer?: Maybe<OrganizationType>;
  notes: Scalars['String'];
  organization?: Maybe<OrganizationType>;
  policies: Array<PolicyType>;
  quote?: Maybe<QuoteType>;
  quoteBusinessLine?: Maybe<BusinessTypeType>;
  quoteStructuredData?: Maybe<StructuredQuoteDataType>;
  referralReasons: Scalars['JSONString'];
  rejectedReasons: Scalars['JSONString'];
  status?: Maybe<Scalars['String']>;
};

export type StructuredQuoteDataType = {
  __typename?: 'StructuredQuoteDataType';
  alreadyContactedBrokerName?: Maybe<Scalars['String']>;
  businessLegalName?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessTradeName?: Maybe<Scalars['String']>;
  contactAddress?: Maybe<AddressType>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  parentArchivedQuote?: Maybe<ArchivedQuoteType>;
  parentQuote?: Maybe<QuoteType>;
  revenue?: Maybe<Scalars['Int']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  postal?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type QuestionInstanceType = {
  __typename?: 'QuestionInstanceType';
  apiName?: Maybe<Scalars['String']>;
  askOnRenewal: Scalars['Boolean'];
  component: Scalars['String'];
  dataType: Scalars['String'];
  defaultValue?: Maybe<Scalars['String']>;
  displayNames: Array<LanguageAwareStringType>;
  formMembership: Array<FormQuestionMembershipType>;
  helpTexts: Array<LanguageAwareStringType>;
  id: Scalars['ID'];
  labels: Array<LanguageAwareStringType>;
  /** Whether or not this is a modified form of an existing question on a FinalFormModifier. Can usually be ignored. */
  modified?: Maybe<Scalars['Boolean']>;
  /** The question instances to add or replace */
  parentFinalFormModifiers: Array<FinalFormModifierType>;
  parentForms: Array<AmendedFinalFormType>;
  parentQuestion: Array<QuestionInstanceType>;
  parentQuestionMembership: Array<QuestionSubQuestionMembershipType>;
  prefillOnRenewal: Scalars['Boolean'];
  propsBlob?: Maybe<Scalars['JSONString']>;
  qualifyingFinalForm?: Maybe<AmendedFinalFormType>;
  required: Scalars['Boolean'];
  subQuestionMembership: Array<QuestionSubQuestionMembershipType>;
  subQuestions: Array<QuestionInstanceType>;
};

export type LanguageAwareStringType = {
  __typename?: 'LanguageAwareStringType';
  id: Scalars['ID'];
  language?: Maybe<LanguageType>;
  parentQuestionInstanceForDisplayNames?: Maybe<QuestionInstanceType>;
  parentQuestionInstanceForHelpTexts?: Maybe<QuestionInstanceType>;
  parentQuestionInstanceForLabels?: Maybe<QuestionInstanceType>;
  value: Scalars['String'];
};

export type LanguageType = {
  __typename?: 'LanguageType';
  archivedquoteSet: Array<ArchivedQuoteType>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  languageawarestringSet: Array<LanguageAwareStringType>;
  quoteSet: Array<QuoteType>;
  shortName: Scalars['String'];
};

export type FormQuestionMembershipType = {
  __typename?: 'FormQuestionMembershipType';
  dateAdded: Scalars['DateTime'];
  form: AmendedFinalFormType;
  id: Scalars['ID'];
  question: QuestionInstanceType;
};

export type QuestionSubQuestionMembershipType = {
  __typename?: 'QuestionSubQuestionMembershipType';
  dateAdded: Scalars['DateTime'];
  id: Scalars['ID'];
  parentQuestion: QuestionInstanceType;
  subQuestion: QuestionInstanceType;
};

export type InsuranceRateAuditType = {
  __typename?: 'InsuranceRateAuditType';
  action: CoreInsuranceRateAuditActionChoices;
  actualRateId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  headers: Scalars['JSONString'];
  id: Scalars['ID'];
  internalName: Scalars['String'];
  ipAddress: Scalars['String'];
  newDisplayName: Scalars['String'];
  newGroup: Scalars['String'];
  newSymbol: Scalars['String'];
  newValue?: Maybe<Scalars['Decimal']>;
  oldDisplayName: Scalars['String'];
  oldGroup: Scalars['String'];
  oldSymbol: Scalars['String'];
  oldValue?: Maybe<Scalars['Decimal']>;
  organization?: Maybe<OrganizationType>;
  organizationName: Scalars['String'];
  rate?: Maybe<InsuranceRateType>;
  token: Scalars['String'];
  userAgent: Scalars['String'];
  userEmail: Scalars['String'];
};

/** An enumeration. */
export enum CoreInsuranceRateAuditActionChoices {
  /** Create */
  Create = 'CREATE',
  /** Delete */
  Delete = 'DELETE',
  /** Edit */
  Edit = 'EDIT',
}

export type InsuranceRateType = {
  __typename?: 'InsuranceRateType';
  audits: Array<InsuranceRateAuditType>;
  description: Scalars['String'];
  displayName: Scalars['String'];
  group: Scalars['String'];
  id: Scalars['ID'];
  internalName: Scalars['String'];
  organization: OrganizationType;
  symbol: Scalars['String'];
  value: Scalars['Decimal'];
};

export type ReferrerType = {
  __typename?: 'ReferrerType';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<OrganizationType>;
  referralCode: Scalars['String'];
  referrerArchivedQuotes: Array<ArchivedQuoteType>;
  referrerQuotes: Array<QuoteType>;
};

export type StatisticalSessionType = {
  __typename?: 'StatisticalSessionType';
  /** The parent session for this event. */
  events: Array<StatisticalEventType>;
  id: Scalars['ID'];
  /** The organization which created this session */
  organization?: Maybe<OrganizationType>;
  /** The quote associated with this session */
  quote?: Maybe<QuoteType>;
  /** The UUID for this session. Used to uniquely identify this particular session, both on the frontend and the backend. */
  sessionId: Scalars['UUID'];
  /** The time at which this session was created. */
  timeCreated: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type AuthorizationTokenType = {
  __typename?: 'AuthorizationTokenType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  organization: OrganizationType;
  token: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type InsurancePolicyType = {
  __typename?: 'InsurancePolicyType';
  additionalInformation: Scalars['JSONString'];
  archivedQuote?: Maybe<ArchivedQuoteType>;
  commissionAmount?: Maybe<Scalars['Decimal']>;
  commissionRate?: Maybe<Scalars['Decimal']>;
  coverages: Array<CoverageType>;
  description: Scalars['String'];
  exclusions: Array<InsurancePolicyExclusionType>;
  feeAmount?: Maybe<Scalars['Decimal']>;
  feeRate?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  policyEffectiveDate?: Maybe<Scalars['Date']>;
  policyExpiryDate?: Maybe<Scalars['Date']>;
  policyNumber: Scalars['String'];
  quote?: Maybe<QuoteType>;
};

export type CoverageType = {
  __typename?: 'CoverageType';
  coveredLocations: Array<CoveredLocationType>;
  id: Scalars['ID'];
  insurancePolicy: InsurancePolicyType;
  name: Scalars['String'];
  perils: Array<PerilType>;
};

export type CoveredLocationType = {
  __typename?: 'CoveredLocationType';
  additionalInformation: Scalars['JSONString'];
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coverages: Array<CoverageType>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  postal?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type PerilType = {
  __typename?: 'PerilType';
  additionalInformation: Scalars['JSONString'];
  basis?: Maybe<Scalars['Int']>;
  coinsurance?: Maybe<Scalars['Decimal']>;
  coverage: CoverageType;
  deductible?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  limit: Scalars['Int'];
  name: Scalars['String'];
  override: Scalars['Boolean'];
  premium?: Maybe<Scalars['Decimal']>;
  rate?: Maybe<Scalars['Float']>;
  subscriptions: Array<SubscriptionType>;
};

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  amount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lead: Scalars['Boolean'];
  percent?: Maybe<Scalars['Decimal']>;
  peril: PerilType;
  subscriberName: Scalars['String'];
};

export type InsurancePolicyExclusionType = {
  __typename?: 'InsurancePolicyExclusionType';
  id: Scalars['ID'];
  policy: InsurancePolicyType;
  reason: Scalars['String'];
};

export type InsurerQuoteType = {
  __typename?: 'InsurerQuoteType';
  comparisons: Array<InsurerQuoteComparisonType>;
  id: Scalars['ID'];
  insurerQuoteIdentifier?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  llmResponseBlob: Scalars['JSONString'];
  parentQuote: QuoteType;
  pdfFilePath?: Maybe<Scalars['String']>;
};

export type InsurerQuoteComparisonType = {
  __typename?: 'InsurerQuoteComparisonType';
  id: Scalars['ID'];
  insurerQuotes: Array<InsurerQuoteType>;
  llmResponseBlob: Scalars['JSONString'];
  uuid: Scalars['UUID'];
};

export type SignatureType = {
  __typename?: 'SignatureType';
  archivedquote?: Maybe<ArchivedQuoteType>;
  id: Scalars['ID'];
  quote?: Maybe<QuoteType>;
  signatureData?: Maybe<Scalars['String']>;
  signatureTime?: Maybe<Scalars['DateTime']>;
};

export type VagoCommercialPackStructuredDataType = {
  __typename?: 'VagoCommercialPackStructuredDataType';
  /** Whether the Arag (= legal solutions) policy has been bound */
  aragBound?: Maybe<Scalars['Boolean']>;
  archivedQuote: Array<ArchivedQuoteType>;
  /** Is the Aviva (= boiler or equipment breakdown) policy bound */
  avivaBound?: Maybe<Scalars['Boolean']>;
  /** CGL limit */
  cglLimit?: Maybe<Scalars['Int']>;
  /** The CGL (= liability) premium */
  cglPremium?: Maybe<Scalars['Int']>;
  /** The name of the client - ATM this is the company legal name */
  clientName?: Maybe<Scalars['String']>;
  /** The code for this application in Epic */
  epicLookupCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Has the liability policy been bound */
  liabilityBound?: Maybe<Scalars['Boolean']>;
  /** Has the property policy been bound */
  propertyBound?: Maybe<Scalars['Boolean']>;
  /** The combined property premium */
  propertyPremium?: Maybe<Scalars['Int']>;
  quote: Array<QuoteType>;
  /** Total insurable value: property + contents + business interruption + misc */
  tiv?: Maybe<Scalars['Int']>;
  /** The transaction / policy type (e.g. Renewal, New Business) */
  transaction?: Maybe<Scalars['String']>;
  /** The email of the user that submitted the application */
  user?: Maybe<Scalars['String']>;
};

export type IndustryType = {
  __typename?: 'IndustryType';
  businesstypeSet: Array<BusinessTypeType>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  internalName: Scalars['String'];
};

export type BusinessSelectionInputs = {
  __typename?: 'BusinessSelectionInputs';
  /** The UniqueID of the associated quote, if any. */
  associatedQuoteUuid?: Maybe<Scalars['UUID']>;
  /** The display name of the business they ended up selecting. May be null. */
  businessSelected?: Maybe<Scalars['String']>;
  /** The date at which the session was created */
  date?: Maybe<Scalars['DateTime']>;
  /** A list of the strings they entered, in chronological order */
  inputs: Array<Maybe<Scalars['String']>>;
  /** The UUID of the parent StatisticalSession */
  sessionId: Scalars['UUID'];
};

export type FinalFormType = {
  __typename?: 'FinalFormType';
  businessLine?: Maybe<BusinessTypeType>;
  id: Scalars['ID'];
  organization?: Maybe<OrganizationType>;
  policy?: Maybe<PolicyType>;
  policyExtension: Array<PolicyExtensionType>;
  qualifyingQuestion?: Maybe<QuestionInstanceType>;
  questionInstances: Array<QuestionInstanceType>;
  questionMembership: Array<FormQuestionMembershipType>;
  required: Scalars['Boolean'];
};

export type BusinessCompletionPercentageType = {
  __typename?: 'BusinessCompletionPercentageType';
  /** The display name for the business */
  businessDisplayName?: Maybe<Scalars['String']>;
  /** The lower 90% credible interval */
  lowerCi?: Maybe<Scalars['Float']>;
  /** The completion percentage with the highest likelihood */
  maximumAPosteriori?: Maybe<Scalars['Float']>;
  /** The raw number of applications begun */
  rawBegun?: Maybe<Scalars['Int']>;
  /** The raw number of completed applications */
  rawCompleted?: Maybe<Scalars['Int']>;
  /** The raw percentage completed / begun */
  rawPercentage?: Maybe<Scalars['Float']>;
  /** The upper 90% credible interval */
  upperCi?: Maybe<Scalars['Float']>;
};

export type DailyPoliciesData = {
  __typename?: 'DailyPoliciesData';
  dailyPolicies?: Maybe<Array<Maybe<Scalars['Int']>>>;
  policiesToday?: Maybe<Scalars['Int']>;
  weeklyPolicyPercentDiff?: Maybe<Scalars['Float']>;
};

export type DailyQuotesData = {
  __typename?: 'DailyQuotesData';
  dailyQuotes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  quotesToday?: Maybe<Scalars['Int']>;
  weeklyQuotePercentDiff?: Maybe<Scalars['Float']>;
};

export type FormDataType = {
  __typename?: 'FormDataType';
  totalCount?: Maybe<Scalars['Int']>;
};

export type WordcloudType = {
  __typename?: 'WordcloudType';
  frequency?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type IndividualQuoteWordcloudType = {
  __typename?: 'IndividualQuoteWordcloudType';
  additionalInformation?: Maybe<Scalars['String']>;
  quoteIdentifierName?: Maybe<Scalars['String']>;
  quoteUuid?: Maybe<Scalars['String']>;
  wordcloud?: Maybe<Array<Maybe<WordcloudType>>>;
};

export type GeographyReportType = {
  __typename?: 'GeographyReportType';
  dateAdded?: Maybe<Scalars['DateTime']>;
  mailingAddress?: Maybe<GenericAddressType>;
  uniqueId?: Maybe<Scalars['String']>;
};

export type GenericAddressType = {
  __typename?: 'GenericAddressType';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type TaskPoolType = {
  __typename?: 'TaskPoolType';
  additionalInformation: Scalars['JSONString'];
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  tasks: Array<TaskType>;
};

export type TaskType = {
  __typename?: 'TaskType';
  additionalInformation: Scalars['JSONString'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  numCompletedSubtasks: Scalars['Int'];
  numSubtasks: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
  status: CoreTaskStatusChoices;
  taskPool?: Maybe<TaskPoolType>;
};

/** An enumeration. */
export enum CoreTaskStatusChoices {
  /** Completed */
  Completed = 'COMPLETED',
  /** Failed */
  Failed = 'FAILED',
  /** Pending */
  Pending = 'PENDING',
  /** Started */
  Started = 'STARTED',
}

export type VaGoSubscriberType = {
  __typename?: 'VaGoSubscriberType';
  id: Scalars['ID'];
  lastAccessed: Scalars['Date'];
  name: Scalars['String'];
};

export type LocationData = {
  __typename?: 'LocationData';
  geoLocations?: Maybe<Array<Maybe<CoordinateType>>>;
};

export type CoordinateType = {
  __typename?: 'CoordinateType';
  id: Scalars['ID'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type MonthlyQuotesData = {
  __typename?: 'MonthlyQuotesData';
  monthlyQuotes?: Maybe<Array<Maybe<MonthlyQuotes>>>;
};

export type MonthlyQuotes = {
  __typename?: 'MonthlyQuotes';
  data?: Maybe<Array<Maybe<OrganizationQuoteData>>>;
  year?: Maybe<Scalars['Int']>;
};

export type OrganizationQuoteData = {
  __typename?: 'OrganizationQuoteData';
  data?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name?: Maybe<Scalars['String']>;
};

export type MunicipalitiesType = {
  __typename?: 'MunicipalitiesType';
  municipalities?: Maybe<Array<Maybe<Scalars['String']>>>;
  province?: Maybe<Scalars['String']>;
};

export type NewQuotesData = {
  __typename?: 'NewQuotesData';
  newQuotes?: Maybe<Array<Maybe<QuoteType>>>;
};

export type PinkSlipType = {
  __typename?: 'PinkSlipType';
  blob?: Maybe<Scalars['String']>;
  brokerEmail?: Maybe<Scalars['String']>;
  descript?: Maybe<Scalars['String']>;
  effectiveDt?: Maybe<Scalars['String']>;
  expiryDt?: Maybe<Scalars['String']>;
  insuranceAddr?: Maybe<Scalars['String']>;
  insuranceCity?: Maybe<Scalars['String']>;
  insuranceName?: Maybe<Scalars['String']>;
  insurancePostal?: Maybe<Scalars['String']>;
  insuranceProv?: Maybe<Scalars['String']>;
  insuredAddr1?: Maybe<Scalars['String']>;
  insuredAddr2?: Maybe<Scalars['String']>;
  insuredCity?: Maybe<Scalars['String']>;
  insuredEmail?: Maybe<Scalars['String']>;
  insuredName?: Maybe<Scalars['String']>;
  insuredPostal?: Maybe<Scalars['String']>;
  insuredProv?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  policyNum?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type ProspectSearchCircleInput = {
  centre?: InputMaybe<ProspectCoordinateInput>;
  radius: Scalars['Float'];
};

export type ProspectCoordinateInput = {
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type ProspectBusinessType = {
  __typename?: 'ProspectBusinessType';
  addressComponents?: Maybe<ProspectAddressComponents>;
  contacts?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  provenance?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type ProspectAddressComponents = {
  __typename?: 'ProspectAddressComponents';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};

export type QuestionInstanceConnection = {
  __typename?: 'QuestionInstanceConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuestionInstanceEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `QuestionInstance` and its cursor. */
export type QuestionInstanceEdge = {
  __typename?: 'QuestionInstanceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<QuestionInstanceType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type QuoteDataType = {
  __typename?: 'QuoteDataType';
  dailyPolicies?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dailyQuotes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  geoLocations?: Maybe<Array<Maybe<CoordinateType>>>;
  monthlyQuotePercentDiff?: Maybe<Scalars['Int']>;
  monthlyQuotes?: Maybe<Array<Maybe<MonthlyQuotes>>>;
  newQuotes?: Maybe<Array<Maybe<QuoteType>>>;
  policiesToday?: Maybe<Scalars['Int']>;
  quotesToday?: Maybe<Scalars['Int']>;
  submissionTimes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  topBusinesses?: Maybe<Array<Maybe<TopBusinesses>>>;
  totalQuotes?: Maybe<Scalars['Int']>;
  weeklyPolicyPercentDiff?: Maybe<Scalars['Int']>;
  weeklyQuotePercentDiff?: Maybe<Scalars['Int']>;
};

export type TopBusinesses = {
  __typename?: 'TopBusinesses';
  name?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type QuotePdfUnion = ArchivedQuoteType | QuoteType;

export type QuotePdf = {
  __typename?: 'QuotePdf';
  businessName?: Maybe<Scalars['String']>;
  pdfString?: Maybe<Scalars['String']>;
};

export type QuoteVersionData = {
  __typename?: 'QuoteVersionData';
  dateAdded?: Maybe<Scalars['String']>;
  quoteId?: Maybe<Scalars['String']>;
};

export type BusinessConnection = {
  __typename?: 'BusinessConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BusinessEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Business` and its cursor. */
export type BusinessEdge = {
  __typename?: 'BusinessEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BusinessTypeType>;
};

export type FinalFormConnection = {
  __typename?: 'FinalFormConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FinalFormEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FinalForm` and its cursor. */
export type FinalFormEdge = {
  __typename?: 'FinalFormEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FinalFormType>;
};

export type QuoteConnection = {
  __typename?: 'QuoteConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuoteEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Quote` and its cursor. */
export type QuoteEdge = {
  __typename?: 'QuoteEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<QuoteType>;
};

export type SendAccountingEmailType = {
  __typename?: 'SendAccountingEmailType';
  body?: Maybe<Scalars['String']>;
  csvAttachment?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
};

export type ShallowProspectsType = {
  __typename?: 'ShallowProspectsType';
  municipalities?: Maybe<Array<Maybe<Scalars['String']>>>;
  province?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['Int']>;
};

export type SubmissionTimesData = {
  __typename?: 'SubmissionTimesData';
  submissionTimes?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TopBusinessesData = {
  __typename?: 'TopBusinessesData';
  topBusinesses?: Maybe<Array<Maybe<TopBusinesses>>>;
};

export type TotalQuotesData = {
  __typename?: 'TotalQuotesData';
  monthlyQuotePercentDiff?: Maybe<Scalars['Float']>;
  monthlyQuotes?: Maybe<Array<Maybe<MonthlyQuotes>>>;
  totalQuotes?: Maybe<Scalars['Int']>;
};

export type DedupeResults = {
  __typename?: 'DedupeResults';
  error?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  questionsDeduped?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum VagoCommercialPackBindingOptions {
  Boiler = 'BOILER',
  LegalSolutions = 'LEGAL_SOLUTIONS',
  Liability = 'LIABILITY',
  Property = 'PROPERTY',
}

export type VagoDownloadApplicationdocumentationType = {
  __typename?: 'VagoDownloadApplicationdocumentationType';
  data?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
};

export type WaveTokenType = {
  __typename?: 'WaveTokenType';
  waveToken?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a new authorization token, associating it with an organization  */
  createAuthorizationToken?: Maybe<CreateAuthorizationToken>;
  createBusiness?: Maybe<CreateBusiness>;
  /** Used for creating a new final form */
  createFinalForm?: Maybe<CreateFinalForm>;
  /** Either create a brand new FinalFormModifier or update an existing one  */
  createFinalFormModifier?: Maybe<CreateFinalFormModifier>;
  /** Create a new insurance rate associated with the requesting organization  */
  createInsuranceRate?: Maybe<CreateInsuranceRate>;
  createInsurerQuote?: Maybe<CreateInsurerQuote>;
  createInsurerQuoteComparison?: Maybe<CreateInsurerQuoteComparison>;
  createPolicy?: Maybe<CreatePolicy>;
  createQuestionInstance?: Maybe<CreateQuestionInstance>;
  /**
   * Create a quote (and potentially) send an email confirming that the quote was created. Also archives an object linking the sent email to the quote.
   *
   * Arguments:
   *     language_code (String): the short_name of the language which should be associated with the quote. This also controls the name and label that is used for the associated answers.
   *     business_line_id (ID): The business line id
   *     completed_forms ([CompletedFormInput]): A list of the completed forms which are part of this quote
   *     client_email (string): The email to which the confirmation email will be send, if desired
   *     client_name (string): the name of the email recipient
   *     status (string): the status to set the quote to once the process is complete
   *     message (string): a short message to the email recipient
   *     unique_ID (string | None): the uniqueID of the quote being submitted. This will be a UUID V4 string if the quote has previously lived in the backend and was then saved for later (so that the form currently being submitted is has been resumed by someone)
   */
  createQuote?: Maybe<CreateQuote>;
  createQuoteFromPdf?: Maybe<CreateQuoteFromPdf>;
  createReferrer?: Maybe<CreateReferrer>;
  /** Create a new Statistical Event and return the UUID. If passed back with no session, one is automatically created and returned.  */
  createStatisticalEvent?: Maybe<CreateStatisticalEvent>;
  /** Create a new Statistical Session and return the UUID  */
  createStatisticalSession?: Maybe<CreateStatisticalSession>;
  deleteBusiness?: Maybe<DeleteBusiness>;
  deleteFinalForm?: Maybe<DeleteFinalForm>;
  /** Delete an existing insurer quote(s) based on its quote identifier and parent quote */
  deleteInsurerQuotes?: Maybe<DeleteInsurerQuotes>;
  deletePolicy?: Maybe<DeletePolicy>;
  DeleteQuestionInstance?: Maybe<DeleteQuestionInstance>;
  /** Edit an existing insurance rate associated with the requesting organization  */
  deleteRate?: Maybe<DeleteInsuranceRate>;
  downloadVagoBordereau?: Maybe<DownloadVagoBordereau>;
  editBindings?: Maybe<EditBindings>;
  editInsurancePolicy?: Maybe<EditInsurancePolicy>;
  editInsurancePolicyDates?: Maybe<EditInsurancePolicyDates>;
  /** DON'T USE THIS - IT'S OLD AND NOT PROPERLY TESTED  */
  editPeril?: Maybe<EditPeril>;
  editPerils?: Maybe<EditPerils>;
  /** Edit an existing insurance rate associated with the requesting organization  */
  editRate?: Maybe<EditInsuranceRate>;
  generateQuoteConfirmationCorrespondence?: Maybe<GenerateQuoteConfirmationCorrespondence>;
  getAllOptaData?: Maybe<GetAllOptaData>;
  /**
   * Mutation to:
   *     - fetch OPTA Commercial Peril Score data for a given location AnswerInstance,
   *     - amend that information to the AnswerInstance AnswerInstanceDetails object,
   *     - return that data to the frontend
   */
  getOptaCommercialPerilScoreData?: Maybe<GetOptaCommercialPerilScoreData>;
  /**
   * Mutation to:
   *     - fetch OPTA FloordScore data for a given location AnswerInstance,
   *     - amend that information to the AnswerInstance AnswerInstanceDetails object,
   *     - return that data to the frontend
   */
  getOptaFloodscoreData?: Maybe<GetOptaFloodScoreData>;
  /**
   * Mutation to:
   *     - fetch OPTA FUS data for a given location AnswerInstance,
   *     - amend that information to the AnswerInstance AnswerInstanceDetails object,
   *     - return that data to the frontend
   */
  getOptaFusData?: Maybe<GetOptaFusData>;
  /**
   * Mutation to:
   *     - fetch OPTA iClarify commercial construction data for a given location AnswerInstance,
   *     - amend that information to the AnswerInstance AnswerInstanceDetails object,
   *     - return that data to the frontend
   */
  getOptaIclarifyCommercialConstructionData?: Maybe<GetOptaIclarifyCommercialConstructionData>;
  markAsRenewalInProgress?: Maybe<MarkAsRenewalInProgress>;
  /** Mark the quote as having a renewal link generated (ready to renew) and return the actual link */
  markAsRenewalLinkGenerated?: Maybe<MarkAsRenewalLinkGenerated>;
  markQuoteAsConfirmationInProgress?: Maybe<MarkQuoteAsConfirmationInProgress>;
  refreshToken?: Maybe<Refresh>;
  sendRenewalEmail?: Maybe<SendRenewalEmail>;
  sendVagoBordereauEmail?: Maybe<SendVagoBordereauEmail>;
  /** Set the validity of the authorization token. In practice, should be used to invalidate them.  */
  setAuthorizationTokenValidity?: Maybe<SetAuthorizationTokenValidity>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  updateBusinessList?: Maybe<UpdateBusinessList>;
  updateFinalForm?: Maybe<UpdateFinalForm>;
  updateOrganization?: Maybe<UpdateOrganization>;
  updatePolicy?: Maybe<UpdatePolicy>;
  UpdateQuestionInstance?: Maybe<UpdateQuestionInstance>;
  vagoForceReRate?: Maybe<VagoForceReRate>;
  verifyToken?: Maybe<Verify>;
};

export type MutationCreateAuthorizationTokenArgs = {
  description: Scalars['String'];
  organizationId: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationCreateBusinessArgs = {
  aliases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  displayName: Scalars['String'];
  industryId: Scalars['ID'];
  internalName: Scalars['String'];
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sicCode: Scalars['Int'];
  token: Scalars['String'];
};

export type MutationCreateFinalFormArgs = {
  businessLineId: Scalars['ID'];
  policyExtension?: InputMaybe<Array<InputMaybe<PolicyExtensionInput>>>;
  policyId?: InputMaybe<Scalars['ID']>;
  qualifyingQuestion?: InputMaybe<QuestionInstanceInput>;
  questionInstances: Array<InputMaybe<QuestionInstanceInput>>;
  required?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type MutationCreateFinalFormModifierArgs = {
  apiNamesForRemoval?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  businessId?: InputMaybe<Scalars['ID']>;
  existingFormId?: InputMaybe<Scalars['ID']>;
  policyId?: InputMaybe<Scalars['ID']>;
  questionInstances: Array<InputMaybe<QuestionInstanceInput>>;
  token: Scalars['String'];
};

export type MutationCreateInsuranceRateArgs = {
  displayName: Scalars['String'];
  group: Scalars['String'];
  internalName: Scalars['String'];
  symbol: Scalars['String'];
  token: Scalars['String'];
  value: Scalars['String'];
};

export type MutationCreateInsurerQuoteArgs = {
  insurerQuoteIdentifier: Scalars['String'];
  pdfFile: Scalars['String'];
  quoteUuid: Scalars['String'];
};

export type MutationCreateInsurerQuoteComparisonArgs = {
  forceRegen?: InputMaybe<Scalars['Boolean']>;
  insurerQuoteIds: Array<InputMaybe<Scalars['String']>>;
};

export type MutationCreatePolicyArgs = {
  displayName: Scalars['String'];
  internalName: Scalars['String'];
  token: Scalars['String'];
};

export type MutationCreateQuestionInstanceArgs = {
  component: Scalars['String'];
  dataType: Scalars['String'];
  helpText?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  name: Scalars['String'];
  propsBlob?: InputMaybe<Scalars['JSONString']>;
  required?: InputMaybe<Scalars['Boolean']>;
  subQuestions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  token: Scalars['String'];
};

export type MutationCreateQuoteArgs = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  businessLineId?: InputMaybe<Scalars['ID']>;
  clientEmail?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  completedForms: Array<InputMaybe<CompletedFormInput>>;
  languageCode: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  producerId?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  renewalInProgress?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  uniqueId?: InputMaybe<Scalars['String']>;
};

export type MutationCreateQuoteFromPdfArgs = {
  businessLineId: Scalars['ID'];
  files: Array<InputMaybe<FileInput>>;
  formIds: Array<InputMaybe<Scalars['ID']>>;
  token: Scalars['String'];
};

export type MutationCreateReferrerArgs = {
  name: Scalars['String'];
  referralCode: Scalars['String'];
  token: Scalars['String'];
};

export type MutationCreateStatisticalEventArgs = {
  data?: InputMaybe<Scalars['JSONString']>;
  eventType: StatisticalEventTypes;
  quoteUuid?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['UUID']>;
  userEmail?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteBusinessArgs = {
  businessId: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationDeleteFinalFormArgs = {
  formId: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationDeleteInsurerQuotesArgs = {
  insurerQuoteIds: Array<InputMaybe<Scalars['ID']>>;
  parentQuoteUuid: Scalars['UUID'];
};

export type MutationDeletePolicyArgs = {
  policyId: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationDeleteQuestionInstanceArgs = {
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationDeleteRateArgs = {
  rateId: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationDownloadVagoBordereauArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  token: Scalars['String'];
};

export type MutationEditBindingsArgs = {
  bindingsToEdit: Array<InputMaybe<EditBindingInput>>;
  token: Scalars['String'];
};

export type MutationEditInsurancePolicyArgs = {
  insurancePolicyId: Scalars['ID'];
  policyNumber?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationEditInsurancePolicyDatesArgs = {
  insurancePolicyId: Scalars['ID'];
  policyEffectiveDate?: InputMaybe<Scalars['String']>;
  policyExpiryDate?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationEditPerilArgs = {
  overridePremium?: InputMaybe<Scalars['Boolean']>;
  perilId: Scalars['ID'];
  premium?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationEditPerilsArgs = {
  perils: Array<InputMaybe<EditPerilInput>>;
  token: Scalars['String'];
};

export type MutationEditRateArgs = {
  displayName: Scalars['String'];
  group: Scalars['String'];
  internalName: Scalars['String'];
  rateId: Scalars['ID'];
  symbol: Scalars['String'];
  token: Scalars['String'];
  value: Scalars['String'];
};

export type MutationGenerateQuoteConfirmationCorrespondenceArgs = {
  token: Scalars['String'];
  uuid: Scalars['String'];
};

export type MutationGetAllOptaDataArgs = {
  answer: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationGetOptaCommercialPerilScoreDataArgs = {
  answer: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationGetOptaFloodscoreDataArgs = {
  answer: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationGetOptaFusDataArgs = {
  answer: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationGetOptaIclarifyCommercialConstructionDataArgs = {
  answer: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationMarkAsRenewalInProgressArgs = {
  uuid: Scalars['UUID'];
};

export type MutationMarkAsRenewalLinkGeneratedArgs = {
  token: Scalars['String'];
  uuid: Scalars['UUID'];
};

export type MutationMarkQuoteAsConfirmationInProgressArgs = {
  hash: Scalars['UUID'];
};

export type MutationRefreshTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type MutationSendRenewalEmailArgs = {
  token: Scalars['String'];
  uuid: Scalars['UUID'];
};

export type MutationSendVagoBordereauEmailArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  token: Scalars['String'];
};

export type MutationSetAuthorizationTokenValidityArgs = {
  authorizationTokenId: Scalars['ID'];
  token: Scalars['String'];
  validity: Scalars['Boolean'];
};

export type MutationTokenAuthArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationUpdateBusinessListArgs = {
  aliases?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  businessId: Scalars['ID'];
  displayName: Scalars['String'];
  industryId: Scalars['ID'];
  internalName: Scalars['String'];
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sicCode: Scalars['Int'];
  token: Scalars['String'];
};

export type MutationUpdateFinalFormArgs = {
  businessLineId: Scalars['ID'];
  finalFormId: Scalars['ID'];
  policyExtension?: InputMaybe<Array<InputMaybe<PolicyExtensionInput>>>;
  policyId?: InputMaybe<Scalars['ID']>;
  qualifyingQuestion?: InputMaybe<QuestionInstanceInput>;
  questionInstances: Array<InputMaybe<QuestionInstanceInput>>;
  required?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  quoteEmails: Array<InputMaybe<Scalars['String']>>;
  quoteFromEmail?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationUpdatePolicyArgs = {
  displayName: Scalars['String'];
  internalName: Scalars['String'];
  policyId: Scalars['ID'];
  token: Scalars['String'];
};

export type MutationUpdateQuestionInstanceArgs = {
  component: Scalars['String'];
  dataType: Scalars['String'];
  helpTexts?: InputMaybe<Array<InputMaybe<LanguageAwareStringInputType>>>;
  id: Scalars['ID'];
  labels: Array<InputMaybe<LanguageAwareStringInputType>>;
  names: Array<InputMaybe<LanguageAwareStringInputType>>;
  propsBlob?: InputMaybe<Scalars['JSONString']>;
  required: Scalars['Boolean'];
  subQuestions?: InputMaybe<Array<InputMaybe<QuestionInstanceInput>>>;
  token: Scalars['String'];
};

export type MutationVagoForceReRateArgs = {
  token: Scalars['String'];
  uniqueId: Scalars['String'];
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

/** Create a new authorization token, associating it with an organization  */
export type CreateAuthorizationToken = {
  __typename?: 'CreateAuthorizationToken';
  token?: Maybe<AuthorizationTokenType>;
};

export type CreateBusiness = {
  __typename?: 'CreateBusiness';
  business?: Maybe<BusinessTypeType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type PolicyExtensionInput = {
  label: Scalars['String'];
  multiplier?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type QuestionInstanceInput = {
  apiName: Scalars['String'];
  askOnRenewal?: InputMaybe<Scalars['Boolean']>;
  component: Scalars['String'];
  dataType: Scalars['String'];
  defaultValue?: InputMaybe<Scalars['String']>;
  displayNames: Array<InputMaybe<LanguageAwareStringInputType>>;
  editAll?: InputMaybe<Scalars['Boolean']>;
  helpTexts?: InputMaybe<Array<InputMaybe<LanguageAwareStringInputType>>>;
  labels: Array<InputMaybe<LanguageAwareStringInputType>>;
  modified?: InputMaybe<Scalars['Boolean']>;
  pk?: InputMaybe<Scalars['ID']>;
  prefillOnRenewal?: InputMaybe<Scalars['Boolean']>;
  propsBlob?: InputMaybe<Scalars['JSONString']>;
  required?: InputMaybe<Scalars['Boolean']>;
  subQuestions?: InputMaybe<Array<InputMaybe<QuestionInstanceInput>>>;
};

export type LanguageAwareStringInputType = {
  language?: InputMaybe<LanguageInputType>;
  value?: InputMaybe<Scalars['String']>;
};

export type LanguageInputType = {
  fullName?: InputMaybe<Scalars['String']>;
  shortName?: InputMaybe<Scalars['String']>;
};

/** Used for creating a new final form */
export type CreateFinalForm = {
  __typename?: 'CreateFinalForm';
  finalForm?: Maybe<FinalFormType>;
  ok?: Maybe<Scalars['Boolean']>;
};

/** Either create a brand new FinalFormModifier or update an existing one  */
export type CreateFinalFormModifier = {
  __typename?: 'CreateFinalFormModifier';
  finalFormModifier?: Maybe<FinalFormModifierType>;
};

/** Create a new insurance rate associated with the requesting organization  */
export type CreateInsuranceRate = {
  __typename?: 'CreateInsuranceRate';
  rate?: Maybe<InsuranceRateType>;
};

export type CreateInsurerQuote = {
  __typename?: 'CreateInsurerQuote';
  insurerQuote?: Maybe<InsurerQuoteType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateInsurerQuoteComparison = {
  __typename?: 'CreateInsurerQuoteComparison';
  insurerQuoteComparison?: Maybe<InsurerQuoteComparisonType>;
  new?: Maybe<Scalars['Boolean']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreatePolicy = {
  __typename?: 'CreatePolicy';
  ok?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<PolicyType>;
};

export type CreateQuestionInstance = {
  __typename?: 'CreateQuestionInstance';
  ok?: Maybe<Scalars['Boolean']>;
  questionInstance?: Maybe<QuestionInstanceType>;
};

export type CompletedFormInput = {
  answers: Array<InputMaybe<AnswerInstanceInput>>;
  policy?: InputMaybe<Scalars['ID']>;
};

/**
 * An AnswerInstance Input
 *
 * Variables:
 * data:
 * method:
 * data_type:
 * details:
 * question_instance_id:
 * sub_questions:
 * sub_answers:
 */
export type AnswerInstanceInput = {
  apiName: Scalars['String'];
  askOnRenewal?: InputMaybe<Scalars['Boolean']>;
  component: Scalars['String'];
  dataType: Scalars['String'];
  defaultValue?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<AnswerInstanceDetailsInput>;
  displayName: Scalars['String'];
  helpText?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  method?: InputMaybe<Scalars['String']>;
  prefillOnRenewal?: InputMaybe<Scalars['Boolean']>;
  propsBlob: Scalars['String'];
  required: Scalars['Boolean'];
  subAnswers?: InputMaybe<Array<InputMaybe<AnswerInstanceInput>>>;
  value: Scalars['String'];
};

export type AnswerInstanceDetailsInput = {
  google?: InputMaybe<GoogleDetailsInput>;
  images?: InputMaybe<Array<InputMaybe<ImageInput>>>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  openingHours?: InputMaybe<Array<InputMaybe<OpeningHoursInput>>>;
};

export type GoogleDetailsInput = {
  numberOfRatings?: InputMaybe<Scalars['Int']>;
  placeId?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Float']>;
};

export type ImageInput = {
  category?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
};

export type OpeningHoursInput = {
  close?: InputMaybe<Scalars['Time']>;
  day: Scalars['Int'];
  open?: InputMaybe<Scalars['Time']>;
};

/**
 * Create a quote (and potentially) send an email confirming that the quote was created. Also archives an object linking the sent email to the quote.
 *
 * Arguments:
 *     language_code (String): the short_name of the language which should be associated with the quote. This also controls the name and label that is used for the associated answers.
 *     business_line_id (ID): The business line id
 *     completed_forms ([CompletedFormInput]): A list of the completed forms which are part of this quote
 *     client_email (string): The email to which the confirmation email will be send, if desired
 *     client_name (string): the name of the email recipient
 *     status (string): the status to set the quote to once the process is complete
 *     message (string): a short message to the email recipient
 *     unique_ID (string | None): the uniqueID of the quote being submitted. This will be a UUID V4 string if the quote has previously lived in the backend and was then saved for later (so that the form currently being submitted is has been resumed by someone)
 */
export type CreateQuote = {
  __typename?: 'CreateQuote';
  ok?: Maybe<Scalars['Boolean']>;
  quote?: Maybe<QuoteType>;
};

/** A file being sent back from the frontend  */
export type FileInput = {
  data: Scalars['String'];
  filename: Scalars['String'];
};

export type CreateQuoteFromPdf = {
  __typename?: 'CreateQuoteFromPdf';
  taskPoolId?: Maybe<Scalars['ID']>;
};

export type CreateReferrer = {
  __typename?: 'CreateReferrer';
  ok?: Maybe<Scalars['Boolean']>;
  referrer?: Maybe<ReferrerType>;
};

/**
 *
 *         The different event types - add to this as new statistical information is collected.
 *
 *         ** Keep in sync with the frontend (src/store/analyticsStore) **
 *
 */
export enum StatisticalEventTypes {
  ApplicationBegun = 'APPLICATION_BEGUN',
  ApplicationFinished = 'APPLICATION_FINISHED',
  ApplicationResumed = 'APPLICATION_RESUMED',
  ApplicationSaved = 'APPLICATION_SAVED',
  BusinessSelectionInput = 'BUSINESS_SELECTION_INPUT',
  BusinessSelectionSelected = 'BUSINESS_SELECTION_SELECTED',
  FormLoaded = 'FORM_LOADED',
  PageLoad = 'PAGE_LOAD',
}

/** Create a new Statistical Event and return the UUID. If passed back with no session, one is automatically created and returned.  */
export type CreateStatisticalEvent = {
  __typename?: 'CreateStatisticalEvent';
  event?: Maybe<StatisticalEventType>;
  session?: Maybe<StatisticalSessionType>;
};

/** Create a new Statistical Session and return the UUID  */
export type CreateStatisticalSession = {
  __typename?: 'CreateStatisticalSession';
  session?: Maybe<StatisticalSessionType>;
};

export type DeleteBusiness = {
  __typename?: 'DeleteBusiness';
  deletedId?: Maybe<Scalars['ID']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteFinalForm = {
  __typename?: 'DeleteFinalForm';
  formId?: Maybe<Scalars['ID']>;
  ok?: Maybe<Scalars['Boolean']>;
};

/** Delete an existing insurer quote(s) based on its quote identifier and parent quote */
export type DeleteInsurerQuotes = {
  __typename?: 'DeleteInsurerQuotes';
  deletedInsurerQuotes?: Maybe<Array<Maybe<InsurerQuoteType>>>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeletePolicy = {
  __typename?: 'DeletePolicy';
  deletedId?: Maybe<Scalars['ID']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteQuestionInstance = {
  __typename?: 'DeleteQuestionInstance';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Edit an existing insurance rate associated with the requesting organization  */
export type DeleteInsuranceRate = {
  __typename?: 'DeleteInsuranceRate';
  id?: Maybe<Scalars['ID']>;
};

export type DownloadVagoBordereau = {
  __typename?: 'DownloadVagoBordereau';
  data?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  filename?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
};

/** Fields to edit different bindings  */
export type EditBindingInput = {
  bindingId: Scalars['ID'];
  bound: Scalars['Boolean'];
  unboundReason?: InputMaybe<Scalars['String']>;
};

export type EditBindings = {
  __typename?: 'EditBindings';
  bindings?: Maybe<Array<Maybe<BindingType>>>;
};

export type EditInsurancePolicy = {
  __typename?: 'EditInsurancePolicy';
  policy?: Maybe<InsurancePolicyType>;
};

export type EditInsurancePolicyDates = {
  __typename?: 'EditInsurancePolicyDates';
  insurancePolicy?: Maybe<InsurancePolicyType>;
};

/** DON'T USE THIS - IT'S OLD AND NOT PROPERLY TESTED  */
export type EditPeril = {
  __typename?: 'EditPeril';
  peril?: Maybe<PerilType>;
};

/** Fields to edit multiple perils at once. Add new fields here as we need to edit them  */
export type EditPerilInput = {
  override?: InputMaybe<Scalars['Boolean']>;
  perilId: Scalars['ID'];
  premium?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['String']>;
};

export type EditPerils = {
  __typename?: 'EditPerils';
  perils?: Maybe<Array<Maybe<PerilType>>>;
};

/** Edit an existing insurance rate associated with the requesting organization  */
export type EditInsuranceRate = {
  __typename?: 'EditInsuranceRate';
  rate?: Maybe<InsuranceRateType>;
};

export type GenerateQuoteConfirmationCorrespondence = {
  __typename?: 'GenerateQuoteConfirmationCorrespondence';
  hash?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GetAllOptaData = {
  __typename?: 'GetAllOptaData';
  data?: Maybe<OptaDetailsType>;
  ok?: Maybe<Scalars['Boolean']>;
};

/**
 * Mutation to:
 *     - fetch OPTA Commercial Peril Score data for a given location AnswerInstance,
 *     - amend that information to the AnswerInstance AnswerInstanceDetails object,
 *     - return that data to the frontend
 */
export type GetOptaCommercialPerilScoreData = {
  __typename?: 'GetOptaCommercialPerilScoreData';
  ok?: Maybe<Scalars['Boolean']>;
  optaCommercialPerilScoreData?: Maybe<OptaCommercialPerilScoreDataType>;
};

/**
 * Mutation to:
 *     - fetch OPTA FloordScore data for a given location AnswerInstance,
 *     - amend that information to the AnswerInstance AnswerInstanceDetails object,
 *     - return that data to the frontend
 */
export type GetOptaFloodScoreData = {
  __typename?: 'GetOptaFloodScoreData';
  ok?: Maybe<Scalars['Boolean']>;
  optaFloodscoreData?: Maybe<OptaFloodScoreDataType>;
};

/**
 * Mutation to:
 *     - fetch OPTA FUS data for a given location AnswerInstance,
 *     - amend that information to the AnswerInstance AnswerInstanceDetails object,
 *     - return that data to the frontend
 */
export type GetOptaFusData = {
  __typename?: 'GetOptaFusData';
  ok?: Maybe<Scalars['Boolean']>;
  optaFusData?: Maybe<OptaFusDataType>;
};

/**
 * Mutation to:
 *     - fetch OPTA iClarify commercial construction data for a given location AnswerInstance,
 *     - amend that information to the AnswerInstance AnswerInstanceDetails object,
 *     - return that data to the frontend
 */
export type GetOptaIclarifyCommercialConstructionData = {
  __typename?: 'GetOptaIclarifyCommercialConstructionData';
  ok?: Maybe<Scalars['Boolean']>;
  optaIclarifyCommercialConstructionData?: Maybe<OptaIclarifyCommercialConstructionDataType>;
};

export type MarkAsRenewalInProgress = {
  __typename?: 'MarkAsRenewalInProgress';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Mark the quote as having a renewal link generated (ready to renew) and return the actual link */
export type MarkAsRenewalLinkGenerated = {
  __typename?: 'MarkAsRenewalLinkGenerated';
  link?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type MarkQuoteAsConfirmationInProgress = {
  __typename?: 'MarkQuoteAsConfirmationInProgress';
  ok?: Maybe<Scalars['Boolean']>;
  /** The UUID of the quote from the QuoteHashCorrespondence */
  uuid?: Maybe<Scalars['UUID']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type SendRenewalEmail = {
  __typename?: 'SendRenewalEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SendVagoBordereauEmail = {
  __typename?: 'SendVagoBordereauEmail';
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

/** Set the validity of the authorization token. In practice, should be used to invalidate them.  */
export type SetAuthorizationTokenValidity = {
  __typename?: 'SetAuthorizationTokenValidity';
  token?: Maybe<AuthorizationTokenType>;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type UpdateBusinessList = {
  __typename?: 'UpdateBusinessList';
  business?: Maybe<BusinessTypeType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateFinalForm = {
  __typename?: 'UpdateFinalForm';
  finalForm?: Maybe<FinalFormType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateOrganization = {
  __typename?: 'UpdateOrganization';
  organization?: Maybe<OrganizationType>;
};

export type UpdatePolicy = {
  __typename?: 'UpdatePolicy';
  ok?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<PolicyType>;
};

export type UpdateQuestionInstance = {
  __typename?: 'UpdateQuestionInstance';
  ok?: Maybe<Scalars['Boolean']>;
  questionInstance?: Maybe<QuestionInstanceType>;
};

export type VagoForceReRate = {
  __typename?: 'VagoForceReRate';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type DownloadVagoBordereauMutationVariables = Exact<{
  token: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;

export type DownloadVagoBordereauMutation = {
  __typename?: 'Mutation';
  downloadVagoBordereau?: {
    __typename?: 'DownloadVagoBordereau';
    startDate?: any | null;
    endDate?: any | null;
    data?: string | null;
    filename?: string | null;
  } | null;
};

export type GetFormDataQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetFormDataQuery = {
  __typename?: 'Query';
  formDataQuery?: {
    __typename?: 'FormDataType';
    totalCount?: number | null;
  } | null;
};

export type GetDailyQuotesDataQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetDailyQuotesDataQuery = {
  __typename?: 'Query';
  dailyQuotesData?: {
    __typename?: 'DailyQuotesData';
    quotesToday?: number | null;
    dailyQuotes?: Array<number | null> | null;
    weeklyQuotePercentDiff?: number | null;
  } | null;
};

export type GetDailyPoliciesDataQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetDailyPoliciesDataQuery = {
  __typename?: 'Query';
  dailyPoliciesData?: {
    __typename?: 'DailyPoliciesData';
    policiesToday?: number | null;
    dailyPolicies?: Array<number | null> | null;
    weeklyPolicyPercentDiff?: number | null;
  } | null;
};

export type GetTotalQuotesDataQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetTotalQuotesDataQuery = {
  __typename?: 'Query';
  totalQuotesData?: {
    __typename?: 'TotalQuotesData';
    totalQuotes?: number | null;
    monthlyQuotePercentDiff?: number | null;
    monthlyQuotes?: Array<{
      __typename?: 'MonthlyQuotes';
      year?: number | null;
      data?: Array<{
        __typename?: 'OrganizationQuoteData';
        name?: string | null;
        data?: Array<number | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetTopBusinessesDataQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetTopBusinessesDataQuery = {
  __typename?: 'Query';
  topBusinessesData?: {
    __typename?: 'TopBusinessesData';
    topBusinesses?: Array<{
      __typename?: 'TopBusinesses';
      name?: string | null;
      totalCount?: number | null;
    } | null> | null;
  } | null;
};

export type GetMonthlyQuotesQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetMonthlyQuotesQuery = {
  __typename?: 'Query';
  monthlyQuotesData?: {
    __typename?: 'MonthlyQuotesData';
    monthlyQuotes?: Array<{
      __typename?: 'MonthlyQuotes';
      year?: number | null;
      data?: Array<{
        __typename?: 'OrganizationQuoteData';
        name?: string | null;
        data?: Array<number | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetLocationDataQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetLocationDataQuery = {
  __typename?: 'Query';
  locationData?: {
    __typename?: 'LocationData';
    geoLocations?: Array<{
      __typename?: 'CoordinateType';
      lat: number;
      long: number;
    } | null> | null;
  } | null;
};

export type GetSubmissionTimeQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetSubmissionTimeQuery = {
  __typename?: 'Query';
  submissionTimesData?: {
    __typename?: 'SubmissionTimesData';
    submissionTimes?: Array<number | null> | null;
  } | null;
};

export type GetNewQuotesQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetNewQuotesQuery = {
  __typename?: 'Query';
  newQuotesData?: {
    __typename?: 'NewQuotesData';
    newQuotes?: Array<{
      __typename?: 'QuoteType';
      id: string;
      uniqueID: any;
      friendlyId?: string | null;
      status: string;
      businessLine?: {
        __typename?: 'BusinessTypeType';
        id: string;
        displayName: string;
      } | null;
      structuredData?: {
        __typename?: 'StructuredQuoteDataType';
        businessLegalName?: string | null;
        businessName?: string | null;
        businessTradeName?: string | null;
        contactName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AllBusinessSelectorInputsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllBusinessSelectorInputsQuery = {
  __typename?: 'Query';
  allBusinessSelectorInputs?: Array<{
    __typename?: 'BusinessSelectionInputs';
    sessionId: any;
    inputs: Array<string | null>;
    businessSelected?: string | null;
    date?: any | null;
    associatedQuoteUuid?: any | null;
  } | null> | null;
};

export type AllBusinessTypesQueryVariables = Exact<{ [key: string]: never }>;

export type AllBusinessTypesQuery = {
  __typename?: 'Query';
  allBusinessTypes?: Array<{
    __typename?: 'BusinessTypeType';
    id: string;
    displayName: string;
    internalName: string;
    keywords?: Array<string | null> | null;
    aliases: any;
    industry: { __typename?: 'IndustryType'; id: string; displayName: string };
  } | null> | null;
};

export type RelayBusinessesQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type RelayBusinessesQuery = {
  __typename?: 'Query';
  relayBusinesses?: {
    __typename?: 'BusinessConnection';
    edges: Array<{
      __typename?: 'BusinessEdge';
      node?: {
        __typename?: 'BusinessTypeType';
        id: string;
        displayName: string;
        internalName: string;
        keywords?: Array<string | null> | null;
        aliases: any;
        sicCode: number;
        isDeletable: boolean;
        industry: {
          __typename?: 'IndustryType';
          id: string;
          displayName: string;
        };
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type BusinessTypesQueryVariables = Exact<{ [key: string]: never }>;

export type BusinessTypesQuery = {
  __typename?: 'Query';
  allBusinessTypes?: Array<{
    __typename?: 'BusinessTypeType';
    id: string;
    displayName: string;
    internalName: string;
    keywords?: Array<string | null> | null;
    aliases: any;
    sicCode: number;
    isDeletable: boolean;
    industry: {
      __typename?: 'IndustryType';
      id: string;
      displayName: string;
      internalName: string;
    };
  } | null> | null;
};

export type BusinessTypeQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  internalName?: InputMaybe<Scalars['String']>;
}>;

export type BusinessTypeQuery = {
  __typename?: 'Query';
  businessType?: {
    __typename?: 'BusinessTypeType';
    id: string;
    internalName: string;
    displayName: string;
    keywords?: Array<string | null> | null;
    aliases: any;
    sicCode: number;
    industry: {
      __typename?: 'IndustryType';
      id: string;
      internalName: string;
      displayName: string;
    };
  } | null;
};

export type IndustryTypesQueryVariables = Exact<{ [key: string]: never }>;

export type IndustryTypesQuery = {
  __typename?: 'Query';
  allIndustries?: Array<{
    __typename?: 'IndustryType';
    id: string;
    displayName: string;
    internalName: string;
  } | null> | null;
};

export type CreateBusinessMutationVariables = Exact<{
  displayName: Scalars['String'];
  internalName: Scalars['String'];
  sicCode: Scalars['Int'];
  industryId: Scalars['ID'];
  keywords?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  aliases?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  token: Scalars['String'];
}>;

export type CreateBusinessMutation = {
  __typename?: 'Mutation';
  createBusiness?: {
    __typename?: 'CreateBusiness';
    ok?: boolean | null;
    business?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
      internalName: string;
      keywords?: Array<string | null> | null;
      aliases: any;
      sicCode: number;
      isDeletable: boolean;
      industry: {
        __typename?: 'IndustryType';
        id: string;
        displayName: string;
      };
    } | null;
  } | null;
};

export type UpdateBusinessesMutationVariables = Exact<{
  businessId: Scalars['ID'];
  displayName: Scalars['String'];
  internalName: Scalars['String'];
  keywords?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  aliases?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  sicCode: Scalars['Int'];
  industryId: Scalars['ID'];
  token: Scalars['String'];
}>;

export type UpdateBusinessesMutation = {
  __typename?: 'Mutation';
  updateBusinessList?: {
    __typename?: 'UpdateBusinessList';
    ok?: boolean | null;
    business?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
      internalName: string;
      keywords?: Array<string | null> | null;
      aliases: any;
      sicCode: number;
      isDeletable: boolean;
      industry: {
        __typename?: 'IndustryType';
        id: string;
        displayName: string;
      };
    } | null;
  } | null;
};

export type DeleteBusinessMutationVariables = Exact<{
  businessId: Scalars['ID'];
  token: Scalars['String'];
}>;

export type DeleteBusinessMutation = {
  __typename?: 'Mutation';
  deleteBusiness?: {
    __typename?: 'DeleteBusiness';
    ok?: boolean | null;
    deletedId?: string | null;
  } | null;
};

export type AllFinalFormModifiersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllFinalFormModifiersQuery = {
  __typename?: 'Query';
  allFinalFormModifiers?: Array<{
    __typename?: 'FinalFormModifierType';
    id: string;
    masked?: boolean | null;
    business?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
    } | null;
  } | null> | null;
};

export type AllFinalFormsQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type AllFinalFormsQuery = {
  __typename?: 'Query';
  allFinalForms?: Array<{
    __typename?: 'FinalFormType';
    id: string;
    required: boolean;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
    } | null;
  } | null> | null;
};

export type AllLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type AllLanguagesQuery = {
  __typename?: 'Query';
  allLanguages?: Array<{
    __typename?: 'LanguageType';
    shortName: string;
    fullName: string;
  } | null> | null;
};

export type PoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type PoliciesQuery = {
  __typename?: 'Query';
  allPolicies?: Array<{
    __typename?: 'PolicyType';
    id: string;
    displayName: string;
    internalName: string;
    isDeletable: boolean;
  } | null> | null;
};

export type PolicyQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  internalName?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
}>;

export type PolicyQuery = {
  __typename?: 'Query';
  policy?: {
    __typename?: 'PolicyType';
    id: string;
    internalName: string;
    displayName: string;
  } | null;
};

export type CreatePolicyMutationVariables = Exact<{
  displayName: Scalars['String'];
  internalName: Scalars['String'];
  token: Scalars['String'];
}>;

export type CreatePolicyMutation = {
  __typename?: 'Mutation';
  createPolicy?: {
    __typename?: 'CreatePolicy';
    ok?: boolean | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
      isDeletable: boolean;
    } | null;
  } | null;
};

export type DeletePolicyMutationVariables = Exact<{
  policyId: Scalars['ID'];
  token: Scalars['String'];
}>;

export type DeletePolicyMutation = {
  __typename?: 'Mutation';
  deletePolicy?: {
    __typename?: 'DeletePolicy';
    ok?: boolean | null;
    deletedId?: string | null;
  } | null;
};

export type UpdatePolicyMutationVariables = Exact<{
  policyId: Scalars['ID'];
  displayName: Scalars['String'];
  internalName: Scalars['String'];
  token: Scalars['String'];
}>;

export type UpdatePolicyMutation = {
  __typename?: 'Mutation';
  updatePolicy?: {
    __typename?: 'UpdatePolicy';
    ok?: boolean | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
      isDeletable: boolean;
    } | null;
  } | null;
};

export type GetAllQuotesQueryVariables = Exact<{
  token: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;

export type GetAllQuotesQuery = {
  __typename?: 'Query';
  allQuotes?: Array<{
    __typename?: 'QuoteType';
    id: string;
    uniqueID: any;
    dateAdded: any;
    status: string;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
    } | null;
    structuredData?: {
      __typename?: 'StructuredQuoteDataType';
      businessLegalName?: string | null;
      businessName?: string | null;
      businessTradeName?: string | null;
    } | null;
    vagoCommercialPackStructuredData?: {
      __typename?: 'VagoCommercialPackStructuredDataType';
      clientName?: string | null;
      transaction?: string | null;
      epicLookupCode?: string | null;
      cglLimit?: number | null;
      tiv?: number | null;
      cglPremium?: number | null;
      propertyPremium?: number | null;
      user?: string | null;
      aragBound?: boolean | null;
      avivaBound?: boolean | null;
      propertyBound?: boolean | null;
      liabilityBound?: boolean | null;
    } | null;
    bindings: Array<{
      __typename?: 'BindingType';
      id: string;
      label: string;
      bound: boolean;
      unboundReason: string;
    }>;
  } | null> | null;
};

export type AllStatisticalSessionsQueryVariables = Exact<{
  token: Scalars['String'];
  organization?: InputMaybe<Scalars['String']>;
}>;

export type AllStatisticalSessionsQuery = {
  __typename?: 'Query';
  allStatisticalSessions?: Array<{
    __typename?: 'StatisticalSessionType';
    sessionId: any;
    timeCreated: any;
    organization?: { __typename?: 'OrganizationType'; name: string } | null;
    events: Array<{
      __typename?: 'StatisticalEventType';
      eventId: any;
      timeCreated: any;
      eventType: CoreStatisticalEventEventTypeChoices;
      data: any;
      userEmail?: string | null;
      quote?: { __typename?: 'QuoteType'; uniqueID: any } | null;
    }>;
  } | null> | null;
};

export type AvailablePoliciesQueryVariables = Exact<{
  businessId: Scalars['ID'];
  token: Scalars['String'];
}>;

export type AvailablePoliciesQuery = {
  __typename?: 'Query';
  availableFormPolicies?: Array<{
    __typename?: 'PolicyType';
    id: string;
    internalName: string;
    displayName: string;
  } | null> | null;
};

export type BusinessAndPolicySpecificFormQueryVariables = Exact<{
  businessId: Scalars['ID'];
  policyId?: InputMaybe<Scalars['ID']>;
  token: Scalars['String'];
}>;

export type BusinessAndPolicySpecificFormQuery = {
  __typename?: 'Query';
  businessAndPolicySpecificForm?: {
    __typename?: 'FinalFormType';
    id: string;
    required: boolean;
    questionInstances: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
    qualifyingQuestion?: {
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
      internalName: string;
      sicCode: number;
      industry: {
        __typename?: 'IndustryType';
        id: string;
        displayName: string;
      };
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
    } | null;
    policyExtension: Array<{
      __typename?: 'PolicyExtensionType';
      id: string;
      label: string;
      multiplier?: string | null;
      questionId?: string | null;
      value?: string | null;
    }>;
  } | null;
};

export type BusinessCompletionPercentagesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BusinessCompletionPercentagesQuery = {
  __typename?: 'Query';
  businessCompletionPercentages?: Array<{
    __typename?: 'BusinessCompletionPercentageType';
    businessDisplayName?: string | null;
    rawPercentage?: number | null;
    rawBegun?: number | null;
    rawCompleted?: number | null;
    maximumAPosteriori?: number | null;
    lowerCi?: number | null;
    upperCi?: number | null;
  } | null> | null;
};

export type GetBusinessSpecificFormsQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;

export type GetBusinessSpecificFormsQuery = {
  __typename?: 'Query';
  someFinalForms?: Array<{
    __typename?: 'AmendedFinalFormType';
    required: boolean;
    id: string;
    questionInstances: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
    qualifyingQuestion?: {
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
      internalName: string;
      industry: {
        __typename?: 'IndustryType';
        id: string;
        displayName: string;
      };
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
    } | null;
    policyExtension: Array<{
      __typename?: 'PolicyExtensionType';
      label: string;
      value?: string | null;
      questionId?: string | null;
      multiplier?: string | null;
    }>;
  } | null> | null;
};

export type CheckFormIntegrityQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type CheckFormIntegrityQuery = {
  __typename?: 'Query';
  checkFormIntegrity?: boolean | null;
};

export type CheckTokenValidityQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type CheckTokenValidityQuery = {
  __typename?: 'Query';
  checkTokenValidity?: boolean | null;
};

export type CreateFinalFormModifierMutationVariables = Exact<{
  existingFormId?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
  policyId?: InputMaybe<Scalars['ID']>;
  questionInstances:
    | Array<InputMaybe<QuestionInstanceInput>>
    | InputMaybe<QuestionInstanceInput>;
  apiNamesForRemoval?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  token: Scalars['String'];
}>;

export type CreateFinalFormModifierMutation = {
  __typename?: 'Mutation';
  createFinalFormModifier?: {
    __typename?: 'CreateFinalFormModifier';
    finalFormModifier?: {
      __typename?: 'FinalFormModifierType';
      id: string;
      apiNamesForRemoval?: Array<string | null> | null;
      business?: {
        __typename?: 'BusinessTypeType';
        id: string;
        displayName: string;
      } | null;
      policy?: {
        __typename?: 'PolicyType';
        id: string;
        displayName: string;
      } | null;
      questionInstances: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        subQuestions: Array<{
                          __typename?: 'QuestionInstanceType';
                          id: string;
                          apiName?: string | null;
                          component: string;
                          dataType: string;
                          required: boolean;
                          askOnRenewal: boolean;
                          prefillOnRenewal: boolean;
                          propsBlob?: any | null;
                          modified?: boolean | null;
                          defaultValue?: string | null;
                          displayNames: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          labels: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          helpTexts: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          parentForms: Array<{
                            __typename?: 'AmendedFinalFormType';
                            id: string;
                          }>;
                        }>;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
    } | null;
  } | null;
};

export type DeleteFormMutationVariables = Exact<{
  formId: Scalars['ID'];
  token: Scalars['String'];
}>;

export type DeleteFormMutation = {
  __typename?: 'Mutation';
  deleteFinalForm?: {
    __typename?: 'DeleteFinalForm';
    ok?: boolean | null;
    formId?: string | null;
  } | null;
};

export type FinalFormModifierQueryVariables = Exact<{
  policyId?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
  raiseErrors: Scalars['Boolean'];
}>;

export type FinalFormModifierQuery = {
  __typename?: 'Query';
  finalFormModifier?: {
    __typename?: 'FinalFormModifierType';
    id: string;
    apiNamesForRemoval?: Array<string | null> | null;
    business?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
    } | null;
    questionInstances: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
  } | null;
};

export type GetMissingPoliciesQueryVariables = Exact<{
  businessId: Scalars['ID'];
  token: Scalars['String'];
}>;

export type GetMissingPoliciesQuery = {
  __typename?: 'Query';
  doesGeneralInfoExist?: boolean | null;
  allEmptyBusinessSpecificPolicies?: Array<{
    __typename?: 'PolicyType';
    id: string;
    displayName: string;
    internalName: string;
  } | null> | null;
};

export type FormModifierRelevantFinalFormsQueryVariables = Exact<{
  businessId?: InputMaybe<Scalars['ID']>;
  policyId?: InputMaybe<Scalars['ID']>;
}>;

export type FormModifierRelevantFinalFormsQuery = {
  __typename?: 'Query';
  formModifierRelevantFinalForms?: Array<{
    __typename?: 'FinalFormType';
    id: string;
    policy?: { __typename?: 'PolicyType'; displayName: string } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      displayName: string;
    } | null;
    questionInstances: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
  } | null> | null;
};

export type FormModifierRelevantFinalFormsFullQueryVariables = Exact<{
  businessId?: InputMaybe<Scalars['ID']>;
  policyId?: InputMaybe<Scalars['ID']>;
}>;

export type FormModifierRelevantFinalFormsFullQuery = {
  __typename?: 'Query';
  formModifierRelevantFinalForms?: Array<{
    __typename?: 'FinalFormType';
    id: string;
    required: boolean;
    questionInstances: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
    qualifyingQuestion?: {
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
      internalName: string;
      sicCode: number;
      industry: {
        __typename?: 'IndustryType';
        id: string;
        displayName: string;
      };
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
    } | null;
    policyExtension: Array<{
      __typename?: 'PolicyExtensionType';
      id: string;
      label: string;
      multiplier?: string | null;
      questionId?: string | null;
      value?: string | null;
    }>;
  } | null> | null;
};

export type LanguageAwareStringFieldsFragment = {
  __typename?: 'LanguageAwareStringType';
  value: string;
  language?: {
    __typename?: 'LanguageType';
    shortName: string;
    fullName: string;
  } | null;
};

export type FinalFormFieldsFragment = {
  __typename?: 'FinalFormType';
  id: string;
  required: boolean;
  questionInstances: Array<{
    __typename?: 'QuestionInstanceType';
    id: string;
    apiName?: string | null;
    component: string;
    dataType: string;
    required: boolean;
    askOnRenewal: boolean;
    prefillOnRenewal: boolean;
    propsBlob?: any | null;
    modified?: boolean | null;
    defaultValue?: string | null;
    subQuestions: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
    displayNames: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    labels: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    helpTexts: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
  }>;
  qualifyingQuestion?: {
    __typename?: 'QuestionInstanceType';
    id: string;
    apiName?: string | null;
    component: string;
    dataType: string;
    required: boolean;
    askOnRenewal: boolean;
    prefillOnRenewal: boolean;
    propsBlob?: any | null;
    modified?: boolean | null;
    defaultValue?: string | null;
    displayNames: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    labels: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    helpTexts: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
  } | null;
  businessLine?: {
    __typename?: 'BusinessTypeType';
    id: string;
    displayName: string;
    internalName: string;
    sicCode: number;
    industry: { __typename?: 'IndustryType'; id: string; displayName: string };
  } | null;
  policy?: {
    __typename?: 'PolicyType';
    id: string;
    displayName: string;
    internalName: string;
  } | null;
  policyExtension: Array<{
    __typename?: 'PolicyExtensionType';
    id: string;
    label: string;
    multiplier?: string | null;
    questionId?: string | null;
    value?: string | null;
  }>;
};

export type AmendedFormFieldsFragment = {
  __typename?: 'AmendedFinalFormType';
  id: string;
  required: boolean;
  questionInstances: Array<{
    __typename?: 'QuestionInstanceType';
    id: string;
    apiName?: string | null;
    component: string;
    dataType: string;
    required: boolean;
    askOnRenewal: boolean;
    prefillOnRenewal: boolean;
    propsBlob?: any | null;
    modified?: boolean | null;
    defaultValue?: string | null;
    subQuestions: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
    displayNames: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    labels: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    helpTexts: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
  }>;
  qualifyingQuestion?: {
    __typename?: 'QuestionInstanceType';
    id: string;
    apiName?: string | null;
    component: string;
    dataType: string;
    required: boolean;
    askOnRenewal: boolean;
    prefillOnRenewal: boolean;
    propsBlob?: any | null;
    modified?: boolean | null;
    defaultValue?: string | null;
    displayNames: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    labels: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    helpTexts: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
  } | null;
  businessLine?: {
    __typename?: 'BusinessTypeType';
    id: string;
    displayName: string;
    internalName: string;
    sicCode: number;
    industry: { __typename?: 'IndustryType'; id: string; displayName: string };
  } | null;
  policy?: {
    __typename?: 'PolicyType';
    id: string;
    displayName: string;
    internalName: string;
  } | null;
  policyExtension: Array<{
    __typename?: 'PolicyExtensionType';
    id: string;
    label: string;
    multiplier?: string | null;
    questionId?: string | null;
    value?: string | null;
  }>;
};

export type QuestionInstanceFieldsFragment = {
  __typename?: 'QuestionInstanceType';
  id: string;
  apiName?: string | null;
  component: string;
  dataType: string;
  required: boolean;
  askOnRenewal: boolean;
  prefillOnRenewal: boolean;
  propsBlob?: any | null;
  modified?: boolean | null;
  defaultValue?: string | null;
  displayNames: Array<{
    __typename?: 'LanguageAwareStringType';
    value: string;
    language?: {
      __typename?: 'LanguageType';
      shortName: string;
      fullName: string;
    } | null;
  }>;
  labels: Array<{
    __typename?: 'LanguageAwareStringType';
    value: string;
    language?: {
      __typename?: 'LanguageType';
      shortName: string;
      fullName: string;
    } | null;
  }>;
  helpTexts: Array<{
    __typename?: 'LanguageAwareStringType';
    value: string;
    language?: {
      __typename?: 'LanguageType';
      shortName: string;
      fullName: string;
    } | null;
  }>;
  parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
};

export type AnswerInstanceFieldsFragment = {
  __typename?: 'AnswerInstanceType';
  id: string;
  apiName: string;
  displayName: string;
  label: string;
  helpText?: string | null;
  component: string;
  propsBlob?: any | null;
  required: boolean;
  askOnRenewal: boolean;
  prefillOnRenewal: boolean;
  value: string;
  date: any;
  method?: string | null;
  dataType: string;
  defaultValue?: string | null;
  details?: {
    __typename?: 'AnswerInstanceDetailsType';
    lat?: number | null;
    lng?: number | null;
    images: Array<{
      __typename?: 'GoogleImageType';
      description: string;
      shortDescription: string;
      category: CoreGoogleImageCategoryChoices;
      data?: string | null;
    }>;
    google?: {
      __typename?: 'GoogleDetailsType';
      placeId?: string | null;
      rating?: number | null;
      numberOfRatings?: number | null;
    } | null;
    opta?: {
      __typename?: 'OPTADetailsType';
      optaFusData?: {
        __typename?: 'OptaFusDataType';
        Address?: {
          __typename?: 'OptaAddressType';
          StreetAddress?: string | null;
          StreetNumber?: string | null;
          StreetName?: string | null;
          StreetType?: string | null;
          StreetDirection?: string | null;
          UnitNumber?: string | null;
          Municipality?: string | null;
          Province?: string | null;
          PostalCode?: string | null;
          LegalDescription?: {
            __typename?: 'LegalDescriptionType';
            Quarter?: string | null;
            LegalSubdivision?: string | null;
            Section?: string | null;
            Township?: string | null;
            Range?: string | null;
            Meridian?: string | null;
          } | null;
        } | null;
        Commercial?: {
          __typename?: 'FusProductCommercialType';
          SearchMessage?: string | null;
          CommercialGrade: Array<{
            __typename?: 'CommercialGradeType';
            PublicFireProtectionClassification?: number | null;
            WaterSystem?: string | null;
            RiskSCORDetail: Array<{
              __typename?: 'RiskSCORDetailType';
              RiskSCOR?: number | null;
              Type?: string | null;
              Adjustment: Array<{
                __typename?: 'RiskSCORAdjustmentType';
                Adjustment?: string | null;
                AdjustmentType?: string | null;
              }>;
            }>;
          }>;
          RespondingFirehall?: {
            __typename?: 'FirehallType';
            Address?: string | null;
            GoverningAgency?: string | null;
            GradingDate?: any | null;
            Name?: string | null;
            PumperFlowImperialGallonsPerMinute?: number | null;
            RoadDistanceKilometres?: number | null;
            SuperiorTankerServiceAccreditationDate?: any | null;
            FirefightersPerFireStation?: {
              __typename?: 'FirefightersPerFireStationType';
              FullTime?: number | null;
              Volunteer?: number | null;
            } | null;
            ProtectionGradeTerritories?: {
              __typename?: 'ProtectedGradeTerritoriesType';
              Commercial?: string | null;
              Dwelling?: string | null;
            } | null;
          } | null;
        } | null;
        Dwelling?: {
          __typename?: 'FusProductDwellingType';
          SearchMessage?: string | null;
          DwellingGrades?: {
            __typename?: 'DwellingGradesType';
            AllOtherAreasGrade: Array<{
              __typename?: 'DwellingProtectedGradeType';
              Grade?: string | null;
              WaterSystem?: string | null;
            }>;
            FirehallProtectedGrade: Array<{
              __typename?: 'DwellingProtectedGradeType';
              Grade?: string | null;
              WaterSystem?: string | null;
            }>;
            HydrantProtectedGrade: Array<{
              __typename?: 'DwellingProtectedGradeType';
              Grade?: string | null;
              WaterSystem?: string | null;
            }>;
          } | null;
          RespondingFirehall?: {
            __typename?: 'FirehallType';
            Address?: string | null;
            GoverningAgency?: string | null;
            GradingDate?: any | null;
            Name?: string | null;
            PumperFlowImperialGallonsPerMinute?: number | null;
            RoadDistanceKilometres?: number | null;
            SuperiorTankerServiceAccreditationDate?: any | null;
            FirefightersPerFireStation?: {
              __typename?: 'FirefightersPerFireStationType';
              FullTime?: number | null;
              Volunteer?: number | null;
            } | null;
            ProtectionGradeTerritories?: {
              __typename?: 'ProtectedGradeTerritoriesType';
              Commercial?: string | null;
              Dwelling?: string | null;
            } | null;
          } | null;
        } | null;
        EarthquakeCodes?: {
          __typename?: 'EarthquakeCodesType';
          SearchMessage?: string | null;
          EarthquakeCode: Array<{
            __typename?: 'EarthquakeCodeType';
            Description?: string | null;
            SystemName?: string | null;
            Zone?: string | null;
          }>;
        } | null;
        FusMaps?: {
          __typename?: 'FusMapsType';
          SearchMessage?: string | null;
          FusMap: Array<{
            __typename?: 'FusMapType';
            Business?: string | null;
            FusContent?: {
              __typename?: 'ContentType';
              ContentURL?: string | null;
              Expiry?: any | null;
              MIMEType?: string | null;
            } | null;
          }>;
        } | null;
        HydrantCounts?: {
          __typename?: 'HydrantCountsType';
          SearchMessage?: string | null;
          HydrantCount: Array<{
            __typename?: 'HydrantCountType';
            NumberOfHydrants: number;
            Ownership?: string | null;
            RadiusMetres?: string | null;
          }>;
        } | null;
      } | null;
      optaFloodscoreData?: {
        __typename?: 'OptaFloodScoreDataType';
        Address?: {
          __typename?: 'OptaAddressType';
          StreetAddress?: string | null;
          StreetNumber?: string | null;
          StreetName?: string | null;
          StreetType?: string | null;
          StreetDirection?: string | null;
          UnitNumber?: string | null;
          Municipality?: string | null;
          Province?: string | null;
          PostalCode?: string | null;
          LegalDescription?: {
            __typename?: 'LegalDescriptionType';
            Quarter?: string | null;
            LegalSubdivision?: string | null;
            Section?: string | null;
            Township?: string | null;
            Range?: string | null;
            Meridian?: string | null;
          } | null;
        } | null;
        FloodScores?: {
          __typename?: 'FloodScoresType';
          FloodScore: Array<{
            __typename?: 'FloodScoreType';
            AverageAnnualLossScore?: number | null;
            Deductible?: number | null;
            Default?: boolean | null;
            FloodScore?: number | null;
            Limit?: number | null;
            RateFactor?: number | null;
            Zone?: string | null;
            ResidentialModifiers?: {
              __typename?: 'ResidentialConstructionFeaturesType';
              ArchitecturalStyleType?: string | null;
              AuxiliaryHeatingType?: string | null;
              BathroomCount?: string | null;
              CommercialIndicator?: string | null;
              ExteriorWallType?: string | null;
              FinishedBasement?: boolean | null;
              FoundationType?: string | null;
              GarageNumberOfCars?: number | null;
              GarageType?: string | null;
              KitchenCount?: string | null;
              OutbuildingPresent?: string | null;
              PlumbingType?: string | null;
              PrimaryHeatingType?: string | null;
              RoofType?: string | null;
              SquareFootage?: number | null;
              StoreyCount?: string | null;
              SwimmingPoolType?: string | null;
              YearBuilt?: string | null;
              InspectionDetails?: {
                __typename?: 'InspectionDetailsType';
                InspectionDate?: any | null;
                SponsoredInspection?: boolean | null;
              } | null;
            } | null;
            MultiResidentialModifiers?: {
              __typename?: 'MultiResidentialConstructionFeaturesType';
              BasementPresent?: boolean | null;
              ConstructionType?: string | null;
              FloorLevel?: number | null;
            } | null;
            AverageAnnualLossValues?: {
              __typename?: 'FloodValueType';
              Building?: number | null;
              Contents?: number | null;
              Total?: number | null;
            } | null;
            AdditionalDetails: Array<{
              __typename?: 'FloodDetailType';
              Key?: string | null;
              Value?: string | null;
            }>;
            Premium?: {
              __typename?: 'FloodValueType';
              Building?: number | null;
              Contents?: number | null;
              Total?: number | null;
            } | null;
          }>;
          FloodMap: Array<{
            __typename?: 'FloodMapType';
            FloodEvent?: string | null;
            Content?: {
              __typename?: 'ContentType';
              ContentURL?: string | null;
              Expiry?: any | null;
              MIMEType?: string | null;
            } | null;
          }>;
        } | null;
      } | null;
      optaIclarifyCommercialConstructionData?: {
        __typename?: 'OptaIclarifyCommercialConstructionDataType';
        Address?: {
          __typename?: 'OptaAddressType';
          StreetAddress?: string | null;
          StreetNumber?: string | null;
          StreetName?: string | null;
          StreetType?: string | null;
          StreetDirection?: string | null;
          UnitNumber?: string | null;
          Municipality?: string | null;
          Province?: string | null;
          PostalCode?: string | null;
          LegalDescription?: {
            __typename?: 'LegalDescriptionType';
            Quarter?: string | null;
            LegalSubdivision?: string | null;
            Section?: string | null;
            Township?: string | null;
            Range?: string | null;
            Meridian?: string | null;
          } | null;
        } | null;
        Messages?: {
          __typename?: 'Messages';
          Message?: Array<string | null> | null;
        } | null;
        Property?: {
          __typename?: 'CommercialPropertyType';
          LotSize?: number | null;
          LotSizeUnitOfMeasurement?: string | null;
          PropertyType?: string | null;
          Buildings?: {
            __typename?: 'BuildingsType';
            Building: Array<{
              __typename?: 'BuildingType';
              StructureID?: string | null;
              ConstructionFeatures?: {
                __typename?: 'CommercialConstructionFeaturesType';
                CombustibilityClass?: string | null;
                ConstructionClass?: string | null;
                FloorHeight?: number | null;
                FloorHeightUnitOfMeasurement?: string | null;
                LoadingDockPresent?: boolean | null;
                NumberOfStoreys?: number | null;
                NumberOfUnits?: number | null;
                TotalFloorArea?: number | null;
                TotalFloorAreaUnitOfMeasurement?: string | null;
                YearBuilt?: number | null;
                Basement?: {
                  __typename?: 'CommercialBasementType';
                  BasementArea?: number | null;
                  BasementAreaUnitOfMeasurement?: string | null;
                  BasementPresent?: boolean | null;
                } | null;
                BuildingUpdates?: {
                  __typename?: 'BuildingUpdatesType';
                  BuildingUpdate?: Array<number | null> | null;
                } | null;
                CommercialExteriorWallTypes?: {
                  __typename?: 'CommercialExteriorWallTypesType';
                  ExteriorWallType?: Array<string | null> | null;
                } | null;
                Elevator?: {
                  __typename?: 'ElevatorType';
                  ElevatorCount?: number | null;
                  ElevatorPresent?: boolean | null;
                } | null;
                Heating?: {
                  __typename?: 'CommercialHeatingType';
                  HeatingFuelSources?: {
                    __typename?: 'HeatingFuelSourcesType';
                    HeatingFuelSource?: Array<string | null> | null;
                  } | null;
                  HeatingTypes?: {
                    __typename?: 'HeatingTypesType';
                    HeatingType?: Array<string | null> | null;
                  } | null;
                } | null;
                Parking?: {
                  __typename?: 'CommercialParkingType';
                  ParkingPresent?: boolean | null;
                  ParkingTypes?: {
                    __typename?: 'CommercialParkingTypesType';
                    ParkingType?: Array<string | null> | null;
                  } | null;
                } | null;
                PlumbingTypes?: {
                  __typename?: 'CommercialPlumbingType';
                  PlumbingType?: Array<string | null> | null;
                } | null;
                RoofAttributes?: {
                  __typename?: 'RoofAttributesType';
                  RoofConstructions?: {
                    __typename?: 'RoofConstructionsType';
                    RoofConstruction?: Array<string | null> | null;
                  } | null;
                  RoofSurfaces?: {
                    __typename?: 'RoofSurfacesType';
                    RoofSurface?: Array<string | null> | null;
                  } | null;
                  RoofTypes?: {
                    __typename?: 'RoofTypesType';
                    RoofType?: Array<string | null> | null;
                  } | null;
                } | null;
                WaterHeater?: {
                  __typename?: 'CommercialWaterHeaterType';
                  WaterHeaterFuelType?: Array<string | null> | null;
                  WaterHeaterType?: Array<string | null> | null;
                } | null;
                Wiring?: {
                  __typename?: 'CommercialWiringType';
                  WiringPanelTypes?: {
                    __typename?: 'CommercialWiringPanelTypesType';
                    WiringPanelType?: Array<string | null> | null;
                  } | null;
                  WiringTypes?: {
                    __typename?: 'CommercialWiringTypesType';
                    WiringType?: Array<string | null> | null;
                  } | null;
                } | null;
              } | null;
              Protection?: {
                __typename?: 'ProtectionType';
                FireAlarmPresent?: boolean | null;
                FireAlarmType?: string | null;
                PhysicalSecurityPresent?: boolean | null;
                SecurityAlarmPresent?: boolean | null;
                SecurityAlarmType?: string | null;
                SprinklerPresent?: boolean | null;
                SprinklerTypeCode?: string | null;
                PhysicalSecurityTypes?: {
                  __typename?: 'PhysicalSecurityTypesType';
                  PhysicalSecurityType?: Array<string | null> | null;
                } | null;
              } | null;
            }>;
          } | null;
          Industry?: {
            __typename?: 'BusinessIndustryType';
            IBCCode?: string | null;
            IBCIndustryDescription?: string | null;
            SICIndustryDescription?: string | null;
            SICCode?: string | null;
          } | null;
        } | null;
      } | null;
      optaCommercialPerilScoreData?: {
        __typename?: 'OptaCommercialPerilScoreDataType';
        Address?: {
          __typename?: 'OptaAddressType';
          StreetAddress?: string | null;
          StreetNumber?: string | null;
          StreetName?: string | null;
          StreetType?: string | null;
          StreetDirection?: string | null;
          UnitNumber?: string | null;
          Municipality?: string | null;
          Province?: string | null;
          PostalCode?: string | null;
          LegalDescription?: {
            __typename?: 'LegalDescriptionType';
            Quarter?: string | null;
            LegalSubdivision?: string | null;
            Section?: string | null;
            Township?: string | null;
            Range?: string | null;
            Meridian?: string | null;
          } | null;
        } | null;
        PropertyPerilScores?: {
          __typename?: 'PropertyCommercialPerilsType';
          Peril?: Array<{
            __typename?: 'PerilDetailsType';
            LossCostScore?: number | null;
            PerilType?: string | null;
            PredictedLossCost?: number | null;
          } | null> | null;
        } | null;
        CasualtyPerilScores?: {
          __typename?: 'CasualtyCommercialPerilsType';
          Peril?: Array<{
            __typename?: 'PerilDetailsType';
            LossCostScore?: number | null;
            PerilType?: string | null;
            PredictedLossCost?: number | null;
          } | null> | null;
        } | null;
        AllPerilsScore?: {
          __typename?: 'CommercialPerilScoreType';
          LossCostScore?: number | null;
          PredictedLossCost?: number | null;
        } | null;
      } | null;
    } | null;
    openingHours: Array<{
      __typename?: 'OpeningHoursType';
      day: number;
      open?: any | null;
      close?: any | null;
    }>;
  } | null;
};

export type OptaAddressFieldsFragment = {
  __typename?: 'OptaAddressType';
  StreetAddress?: string | null;
  StreetNumber?: string | null;
  StreetName?: string | null;
  StreetType?: string | null;
  StreetDirection?: string | null;
  UnitNumber?: string | null;
  Municipality?: string | null;
  Province?: string | null;
  PostalCode?: string | null;
  LegalDescription?: {
    __typename?: 'LegalDescriptionType';
    Quarter?: string | null;
    LegalSubdivision?: string | null;
    Section?: string | null;
    Township?: string | null;
    Range?: string | null;
    Meridian?: string | null;
  } | null;
};

export type OptaCommercialPerilScoreDataFieldsFragment = {
  __typename?: 'OptaCommercialPerilScoreDataType';
  Address?: {
    __typename?: 'OptaAddressType';
    StreetAddress?: string | null;
    StreetNumber?: string | null;
    StreetName?: string | null;
    StreetType?: string | null;
    StreetDirection?: string | null;
    UnitNumber?: string | null;
    Municipality?: string | null;
    Province?: string | null;
    PostalCode?: string | null;
    LegalDescription?: {
      __typename?: 'LegalDescriptionType';
      Quarter?: string | null;
      LegalSubdivision?: string | null;
      Section?: string | null;
      Township?: string | null;
      Range?: string | null;
      Meridian?: string | null;
    } | null;
  } | null;
  PropertyPerilScores?: {
    __typename?: 'PropertyCommercialPerilsType';
    Peril?: Array<{
      __typename?: 'PerilDetailsType';
      LossCostScore?: number | null;
      PerilType?: string | null;
      PredictedLossCost?: number | null;
    } | null> | null;
  } | null;
  CasualtyPerilScores?: {
    __typename?: 'CasualtyCommercialPerilsType';
    Peril?: Array<{
      __typename?: 'PerilDetailsType';
      LossCostScore?: number | null;
      PerilType?: string | null;
      PredictedLossCost?: number | null;
    } | null> | null;
  } | null;
  AllPerilsScore?: {
    __typename?: 'CommercialPerilScoreType';
    LossCostScore?: number | null;
    PredictedLossCost?: number | null;
  } | null;
};

export type OptaIclarifyCommercialConstructionDataFieldsFragment = {
  __typename?: 'OptaIclarifyCommercialConstructionDataType';
  Address?: {
    __typename?: 'OptaAddressType';
    StreetAddress?: string | null;
    StreetNumber?: string | null;
    StreetName?: string | null;
    StreetType?: string | null;
    StreetDirection?: string | null;
    UnitNumber?: string | null;
    Municipality?: string | null;
    Province?: string | null;
    PostalCode?: string | null;
    LegalDescription?: {
      __typename?: 'LegalDescriptionType';
      Quarter?: string | null;
      LegalSubdivision?: string | null;
      Section?: string | null;
      Township?: string | null;
      Range?: string | null;
      Meridian?: string | null;
    } | null;
  } | null;
  Messages?: {
    __typename?: 'Messages';
    Message?: Array<string | null> | null;
  } | null;
  Property?: {
    __typename?: 'CommercialPropertyType';
    LotSize?: number | null;
    LotSizeUnitOfMeasurement?: string | null;
    PropertyType?: string | null;
    Buildings?: {
      __typename?: 'BuildingsType';
      Building: Array<{
        __typename?: 'BuildingType';
        StructureID?: string | null;
        ConstructionFeatures?: {
          __typename?: 'CommercialConstructionFeaturesType';
          CombustibilityClass?: string | null;
          ConstructionClass?: string | null;
          FloorHeight?: number | null;
          FloorHeightUnitOfMeasurement?: string | null;
          LoadingDockPresent?: boolean | null;
          NumberOfStoreys?: number | null;
          NumberOfUnits?: number | null;
          TotalFloorArea?: number | null;
          TotalFloorAreaUnitOfMeasurement?: string | null;
          YearBuilt?: number | null;
          Basement?: {
            __typename?: 'CommercialBasementType';
            BasementArea?: number | null;
            BasementAreaUnitOfMeasurement?: string | null;
            BasementPresent?: boolean | null;
          } | null;
          BuildingUpdates?: {
            __typename?: 'BuildingUpdatesType';
            BuildingUpdate?: Array<number | null> | null;
          } | null;
          CommercialExteriorWallTypes?: {
            __typename?: 'CommercialExteriorWallTypesType';
            ExteriorWallType?: Array<string | null> | null;
          } | null;
          Elevator?: {
            __typename?: 'ElevatorType';
            ElevatorCount?: number | null;
            ElevatorPresent?: boolean | null;
          } | null;
          Heating?: {
            __typename?: 'CommercialHeatingType';
            HeatingFuelSources?: {
              __typename?: 'HeatingFuelSourcesType';
              HeatingFuelSource?: Array<string | null> | null;
            } | null;
            HeatingTypes?: {
              __typename?: 'HeatingTypesType';
              HeatingType?: Array<string | null> | null;
            } | null;
          } | null;
          Parking?: {
            __typename?: 'CommercialParkingType';
            ParkingPresent?: boolean | null;
            ParkingTypes?: {
              __typename?: 'CommercialParkingTypesType';
              ParkingType?: Array<string | null> | null;
            } | null;
          } | null;
          PlumbingTypes?: {
            __typename?: 'CommercialPlumbingType';
            PlumbingType?: Array<string | null> | null;
          } | null;
          RoofAttributes?: {
            __typename?: 'RoofAttributesType';
            RoofConstructions?: {
              __typename?: 'RoofConstructionsType';
              RoofConstruction?: Array<string | null> | null;
            } | null;
            RoofSurfaces?: {
              __typename?: 'RoofSurfacesType';
              RoofSurface?: Array<string | null> | null;
            } | null;
            RoofTypes?: {
              __typename?: 'RoofTypesType';
              RoofType?: Array<string | null> | null;
            } | null;
          } | null;
          WaterHeater?: {
            __typename?: 'CommercialWaterHeaterType';
            WaterHeaterFuelType?: Array<string | null> | null;
            WaterHeaterType?: Array<string | null> | null;
          } | null;
          Wiring?: {
            __typename?: 'CommercialWiringType';
            WiringPanelTypes?: {
              __typename?: 'CommercialWiringPanelTypesType';
              WiringPanelType?: Array<string | null> | null;
            } | null;
            WiringTypes?: {
              __typename?: 'CommercialWiringTypesType';
              WiringType?: Array<string | null> | null;
            } | null;
          } | null;
        } | null;
        Protection?: {
          __typename?: 'ProtectionType';
          FireAlarmPresent?: boolean | null;
          FireAlarmType?: string | null;
          PhysicalSecurityPresent?: boolean | null;
          SecurityAlarmPresent?: boolean | null;
          SecurityAlarmType?: string | null;
          SprinklerPresent?: boolean | null;
          SprinklerTypeCode?: string | null;
          PhysicalSecurityTypes?: {
            __typename?: 'PhysicalSecurityTypesType';
            PhysicalSecurityType?: Array<string | null> | null;
          } | null;
        } | null;
      }>;
    } | null;
    Industry?: {
      __typename?: 'BusinessIndustryType';
      IBCCode?: string | null;
      IBCIndustryDescription?: string | null;
      SICIndustryDescription?: string | null;
      SICCode?: string | null;
    } | null;
  } | null;
};

export type OptaFloodScoreDataFieldsFragment = {
  __typename?: 'OptaFloodScoreDataType';
  Address?: {
    __typename?: 'OptaAddressType';
    StreetAddress?: string | null;
    StreetNumber?: string | null;
    StreetName?: string | null;
    StreetType?: string | null;
    StreetDirection?: string | null;
    UnitNumber?: string | null;
    Municipality?: string | null;
    Province?: string | null;
    PostalCode?: string | null;
    LegalDescription?: {
      __typename?: 'LegalDescriptionType';
      Quarter?: string | null;
      LegalSubdivision?: string | null;
      Section?: string | null;
      Township?: string | null;
      Range?: string | null;
      Meridian?: string | null;
    } | null;
  } | null;
  FloodScores?: {
    __typename?: 'FloodScoresType';
    FloodScore: Array<{
      __typename?: 'FloodScoreType';
      AverageAnnualLossScore?: number | null;
      Deductible?: number | null;
      Default?: boolean | null;
      FloodScore?: number | null;
      Limit?: number | null;
      RateFactor?: number | null;
      Zone?: string | null;
      ResidentialModifiers?: {
        __typename?: 'ResidentialConstructionFeaturesType';
        ArchitecturalStyleType?: string | null;
        AuxiliaryHeatingType?: string | null;
        BathroomCount?: string | null;
        CommercialIndicator?: string | null;
        ExteriorWallType?: string | null;
        FinishedBasement?: boolean | null;
        FoundationType?: string | null;
        GarageNumberOfCars?: number | null;
        GarageType?: string | null;
        KitchenCount?: string | null;
        OutbuildingPresent?: string | null;
        PlumbingType?: string | null;
        PrimaryHeatingType?: string | null;
        RoofType?: string | null;
        SquareFootage?: number | null;
        StoreyCount?: string | null;
        SwimmingPoolType?: string | null;
        YearBuilt?: string | null;
        InspectionDetails?: {
          __typename?: 'InspectionDetailsType';
          InspectionDate?: any | null;
          SponsoredInspection?: boolean | null;
        } | null;
      } | null;
      MultiResidentialModifiers?: {
        __typename?: 'MultiResidentialConstructionFeaturesType';
        BasementPresent?: boolean | null;
        ConstructionType?: string | null;
        FloorLevel?: number | null;
      } | null;
      AverageAnnualLossValues?: {
        __typename?: 'FloodValueType';
        Building?: number | null;
        Contents?: number | null;
        Total?: number | null;
      } | null;
      AdditionalDetails: Array<{
        __typename?: 'FloodDetailType';
        Key?: string | null;
        Value?: string | null;
      }>;
      Premium?: {
        __typename?: 'FloodValueType';
        Building?: number | null;
        Contents?: number | null;
        Total?: number | null;
      } | null;
    }>;
    FloodMap: Array<{
      __typename?: 'FloodMapType';
      FloodEvent?: string | null;
      Content?: {
        __typename?: 'ContentType';
        ContentURL?: string | null;
        Expiry?: any | null;
        MIMEType?: string | null;
      } | null;
    }>;
  } | null;
};

export type OptaFusDataFieldsFragment = {
  __typename?: 'OptaFusDataType';
  Address?: {
    __typename?: 'OptaAddressType';
    StreetAddress?: string | null;
    StreetNumber?: string | null;
    StreetName?: string | null;
    StreetType?: string | null;
    StreetDirection?: string | null;
    UnitNumber?: string | null;
    Municipality?: string | null;
    Province?: string | null;
    PostalCode?: string | null;
    LegalDescription?: {
      __typename?: 'LegalDescriptionType';
      Quarter?: string | null;
      LegalSubdivision?: string | null;
      Section?: string | null;
      Township?: string | null;
      Range?: string | null;
      Meridian?: string | null;
    } | null;
  } | null;
  Commercial?: {
    __typename?: 'FusProductCommercialType';
    SearchMessage?: string | null;
    CommercialGrade: Array<{
      __typename?: 'CommercialGradeType';
      PublicFireProtectionClassification?: number | null;
      WaterSystem?: string | null;
      RiskSCORDetail: Array<{
        __typename?: 'RiskSCORDetailType';
        RiskSCOR?: number | null;
        Type?: string | null;
        Adjustment: Array<{
          __typename?: 'RiskSCORAdjustmentType';
          Adjustment?: string | null;
          AdjustmentType?: string | null;
        }>;
      }>;
    }>;
    RespondingFirehall?: {
      __typename?: 'FirehallType';
      Address?: string | null;
      GoverningAgency?: string | null;
      GradingDate?: any | null;
      Name?: string | null;
      PumperFlowImperialGallonsPerMinute?: number | null;
      RoadDistanceKilometres?: number | null;
      SuperiorTankerServiceAccreditationDate?: any | null;
      FirefightersPerFireStation?: {
        __typename?: 'FirefightersPerFireStationType';
        FullTime?: number | null;
        Volunteer?: number | null;
      } | null;
      ProtectionGradeTerritories?: {
        __typename?: 'ProtectedGradeTerritoriesType';
        Commercial?: string | null;
        Dwelling?: string | null;
      } | null;
    } | null;
  } | null;
  Dwelling?: {
    __typename?: 'FusProductDwellingType';
    SearchMessage?: string | null;
    DwellingGrades?: {
      __typename?: 'DwellingGradesType';
      AllOtherAreasGrade: Array<{
        __typename?: 'DwellingProtectedGradeType';
        Grade?: string | null;
        WaterSystem?: string | null;
      }>;
      FirehallProtectedGrade: Array<{
        __typename?: 'DwellingProtectedGradeType';
        Grade?: string | null;
        WaterSystem?: string | null;
      }>;
      HydrantProtectedGrade: Array<{
        __typename?: 'DwellingProtectedGradeType';
        Grade?: string | null;
        WaterSystem?: string | null;
      }>;
    } | null;
    RespondingFirehall?: {
      __typename?: 'FirehallType';
      Address?: string | null;
      GoverningAgency?: string | null;
      GradingDate?: any | null;
      Name?: string | null;
      PumperFlowImperialGallonsPerMinute?: number | null;
      RoadDistanceKilometres?: number | null;
      SuperiorTankerServiceAccreditationDate?: any | null;
      FirefightersPerFireStation?: {
        __typename?: 'FirefightersPerFireStationType';
        FullTime?: number | null;
        Volunteer?: number | null;
      } | null;
      ProtectionGradeTerritories?: {
        __typename?: 'ProtectedGradeTerritoriesType';
        Commercial?: string | null;
        Dwelling?: string | null;
      } | null;
    } | null;
  } | null;
  EarthquakeCodes?: {
    __typename?: 'EarthquakeCodesType';
    SearchMessage?: string | null;
    EarthquakeCode: Array<{
      __typename?: 'EarthquakeCodeType';
      Description?: string | null;
      SystemName?: string | null;
      Zone?: string | null;
    }>;
  } | null;
  FusMaps?: {
    __typename?: 'FusMapsType';
    SearchMessage?: string | null;
    FusMap: Array<{
      __typename?: 'FusMapType';
      Business?: string | null;
      FusContent?: {
        __typename?: 'ContentType';
        ContentURL?: string | null;
        Expiry?: any | null;
        MIMEType?: string | null;
      } | null;
    }>;
  } | null;
  HydrantCounts?: {
    __typename?: 'HydrantCountsType';
    SearchMessage?: string | null;
    HydrantCount: Array<{
      __typename?: 'HydrantCountType';
      NumberOfHydrants: number;
      Ownership?: string | null;
      RadiusMetres?: string | null;
    }>;
  } | null;
};

export type PerilFieldsFragment = {
  __typename?: 'PerilType';
  id: string;
  name: string;
  limit: number;
  deductible?: number | null;
  premium?: any | null;
  rate?: number | null;
  override: boolean;
  basis?: number | null;
};

export type PolicyFieldsFragment = {
  __typename?: 'PolicyType';
  displayName: string;
  internalName: string;
};

export type QuestionDatabaseFieldsFragment = {
  __typename?: 'QuestionInstanceType';
  id: string;
  apiName?: string | null;
  component: string;
  dataType: string;
  required: boolean;
  propsBlob?: any | null;
  displayNames: Array<{
    __typename?: 'LanguageAwareStringType';
    value: string;
    language?: {
      __typename?: 'LanguageType';
      shortName: string;
      fullName: string;
    } | null;
  }>;
  labels: Array<{
    __typename?: 'LanguageAwareStringType';
    value: string;
    language?: {
      __typename?: 'LanguageType';
      shortName: string;
      fullName: string;
    } | null;
  }>;
  helpTexts: Array<{
    __typename?: 'LanguageAwareStringType';
    value: string;
    language?: {
      __typename?: 'LanguageType';
      shortName: string;
      fullName: string;
    } | null;
  }>;
};

export type QuoteFieldsFragment = {
  __typename?: 'QuoteType';
  id: string;
  uniqueID: any;
  friendlyId?: string | null;
  additionalInformation: string;
  producerId: string;
  QuoteStatus: string;
  language?: {
    __typename?: 'LanguageType';
    shortName: string;
    fullName: string;
  } | null;
  businessLine?: {
    __typename?: 'BusinessTypeType';
    id: string;
    displayName: string;
  } | null;
  structuredData?: {
    __typename?: 'StructuredQuoteDataType';
    businessLegalName?: string | null;
    businessName?: string | null;
    businessTradeName?: string | null;
    contactName?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    revenue?: number | null;
    alreadyContactedBrokerName?: string | null;
    contactAddress?: {
      __typename?: 'AddressType';
      formattedAddress?: string | null;
    } | null;
  } | null;
  completedForms: Array<{
    __typename?: 'CompletedFormType';
    id: string;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
    } | null;
    extensions?: Array<{
      __typename?: 'PolicyExtensionType';
      id: string;
      label: string;
      multiplier?: string | null;
      questionId?: string | null;
      value?: string | null;
    } | null> | null;
    answers: Array<{
      __typename?: 'AnswerInstanceType';
      id: string;
      apiName: string;
      displayName: string;
      label: string;
      helpText?: string | null;
      component: string;
      propsBlob?: any | null;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      value: string;
      date: any;
      method?: string | null;
      dataType: string;
      defaultValue?: string | null;
      subAnswers: Array<{
        __typename?: 'AnswerInstanceType';
        id: string;
        apiName: string;
        displayName: string;
        label: string;
        helpText?: string | null;
        component: string;
        propsBlob?: any | null;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        value: string;
        date: any;
        method?: string | null;
        dataType: string;
        defaultValue?: string | null;
        subAnswers: Array<{
          __typename?: 'AnswerInstanceType';
          id: string;
          apiName: string;
          displayName: string;
          label: string;
          helpText?: string | null;
          component: string;
          propsBlob?: any | null;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          value: string;
          date: any;
          method?: string | null;
          dataType: string;
          defaultValue?: string | null;
          subAnswers: Array<{
            __typename?: 'AnswerInstanceType';
            id: string;
            apiName: string;
            displayName: string;
            label: string;
            helpText?: string | null;
            component: string;
            propsBlob?: any | null;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            value: string;
            date: any;
            method?: string | null;
            dataType: string;
            defaultValue?: string | null;
            subAnswers: Array<{
              __typename?: 'AnswerInstanceType';
              id: string;
              apiName: string;
              displayName: string;
              label: string;
              helpText?: string | null;
              component: string;
              propsBlob?: any | null;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              value: string;
              date: any;
              method?: string | null;
              dataType: string;
              defaultValue?: string | null;
              subAnswers: Array<{
                __typename?: 'AnswerInstanceType';
                id: string;
                apiName: string;
                displayName: string;
                label: string;
                helpText?: string | null;
                component: string;
                propsBlob?: any | null;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                value: string;
                date: any;
                method?: string | null;
                dataType: string;
                defaultValue?: string | null;
                subAnswers: Array<{
                  __typename?: 'AnswerInstanceType';
                  id: string;
                  apiName: string;
                  displayName: string;
                  label: string;
                  helpText?: string | null;
                  component: string;
                  propsBlob?: any | null;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  value: string;
                  date: any;
                  method?: string | null;
                  dataType: string;
                  defaultValue?: string | null;
                  subAnswers: Array<{
                    __typename?: 'AnswerInstanceType';
                    id: string;
                    apiName: string;
                    displayName: string;
                    label: string;
                    helpText?: string | null;
                    component: string;
                    propsBlob?: any | null;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    value: string;
                    date: any;
                    method?: string | null;
                    dataType: string;
                    defaultValue?: string | null;
                    subAnswers: Array<{
                      __typename?: 'AnswerInstanceType';
                      id: string;
                      apiName: string;
                      displayName: string;
                      label: string;
                      helpText?: string | null;
                      component: string;
                      propsBlob?: any | null;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      value: string;
                      date: any;
                      method?: string | null;
                      dataType: string;
                      defaultValue?: string | null;
                      subAnswers: Array<{
                        __typename?: 'AnswerInstanceType';
                        id: string;
                        apiName: string;
                        displayName: string;
                        label: string;
                        helpText?: string | null;
                        component: string;
                        propsBlob?: any | null;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        value: string;
                        date: any;
                        method?: string | null;
                        dataType: string;
                        defaultValue?: string | null;
                        details?: {
                          __typename?: 'AnswerInstanceDetailsType';
                          lat?: number | null;
                          lng?: number | null;
                          images: Array<{
                            __typename?: 'GoogleImageType';
                            description: string;
                            shortDescription: string;
                            category: CoreGoogleImageCategoryChoices;
                            data?: string | null;
                          }>;
                          google?: {
                            __typename?: 'GoogleDetailsType';
                            placeId?: string | null;
                            rating?: number | null;
                            numberOfRatings?: number | null;
                          } | null;
                          opta?: {
                            __typename?: 'OPTADetailsType';
                            optaFusData?: {
                              __typename?: 'OptaFusDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Commercial?: {
                                __typename?: 'FusProductCommercialType';
                                SearchMessage?: string | null;
                                CommercialGrade: Array<{
                                  __typename?: 'CommercialGradeType';
                                  PublicFireProtectionClassification?:
                                    | number
                                    | null;
                                  WaterSystem?: string | null;
                                  RiskSCORDetail: Array<{
                                    __typename?: 'RiskSCORDetailType';
                                    RiskSCOR?: number | null;
                                    Type?: string | null;
                                    Adjustment: Array<{
                                      __typename?: 'RiskSCORAdjustmentType';
                                      Adjustment?: string | null;
                                      AdjustmentType?: string | null;
                                    }>;
                                  }>;
                                }>;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              Dwelling?: {
                                __typename?: 'FusProductDwellingType';
                                SearchMessage?: string | null;
                                DwellingGrades?: {
                                  __typename?: 'DwellingGradesType';
                                  AllOtherAreasGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  FirehallProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  HydrantProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                } | null;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              EarthquakeCodes?: {
                                __typename?: 'EarthquakeCodesType';
                                SearchMessage?: string | null;
                                EarthquakeCode: Array<{
                                  __typename?: 'EarthquakeCodeType';
                                  Description?: string | null;
                                  SystemName?: string | null;
                                  Zone?: string | null;
                                }>;
                              } | null;
                              FusMaps?: {
                                __typename?: 'FusMapsType';
                                SearchMessage?: string | null;
                                FusMap: Array<{
                                  __typename?: 'FusMapType';
                                  Business?: string | null;
                                  FusContent?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                              HydrantCounts?: {
                                __typename?: 'HydrantCountsType';
                                SearchMessage?: string | null;
                                HydrantCount: Array<{
                                  __typename?: 'HydrantCountType';
                                  NumberOfHydrants: number;
                                  Ownership?: string | null;
                                  RadiusMetres?: string | null;
                                }>;
                              } | null;
                            } | null;
                            optaFloodscoreData?: {
                              __typename?: 'OptaFloodScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              FloodScores?: {
                                __typename?: 'FloodScoresType';
                                FloodScore: Array<{
                                  __typename?: 'FloodScoreType';
                                  AverageAnnualLossScore?: number | null;
                                  Deductible?: number | null;
                                  Default?: boolean | null;
                                  FloodScore?: number | null;
                                  Limit?: number | null;
                                  RateFactor?: number | null;
                                  Zone?: string | null;
                                  ResidentialModifiers?: {
                                    __typename?: 'ResidentialConstructionFeaturesType';
                                    ArchitecturalStyleType?: string | null;
                                    AuxiliaryHeatingType?: string | null;
                                    BathroomCount?: string | null;
                                    CommercialIndicator?: string | null;
                                    ExteriorWallType?: string | null;
                                    FinishedBasement?: boolean | null;
                                    FoundationType?: string | null;
                                    GarageNumberOfCars?: number | null;
                                    GarageType?: string | null;
                                    KitchenCount?: string | null;
                                    OutbuildingPresent?: string | null;
                                    PlumbingType?: string | null;
                                    PrimaryHeatingType?: string | null;
                                    RoofType?: string | null;
                                    SquareFootage?: number | null;
                                    StoreyCount?: string | null;
                                    SwimmingPoolType?: string | null;
                                    YearBuilt?: string | null;
                                    InspectionDetails?: {
                                      __typename?: 'InspectionDetailsType';
                                      InspectionDate?: any | null;
                                      SponsoredInspection?: boolean | null;
                                    } | null;
                                  } | null;
                                  MultiResidentialModifiers?: {
                                    __typename?: 'MultiResidentialConstructionFeaturesType';
                                    BasementPresent?: boolean | null;
                                    ConstructionType?: string | null;
                                    FloorLevel?: number | null;
                                  } | null;
                                  AverageAnnualLossValues?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                  AdditionalDetails: Array<{
                                    __typename?: 'FloodDetailType';
                                    Key?: string | null;
                                    Value?: string | null;
                                  }>;
                                  Premium?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                }>;
                                FloodMap: Array<{
                                  __typename?: 'FloodMapType';
                                  FloodEvent?: string | null;
                                  Content?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            optaIclarifyCommercialConstructionData?: {
                              __typename?: 'OptaIclarifyCommercialConstructionDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Messages?: {
                                __typename?: 'Messages';
                                Message?: Array<string | null> | null;
                              } | null;
                              Property?: {
                                __typename?: 'CommercialPropertyType';
                                LotSize?: number | null;
                                LotSizeUnitOfMeasurement?: string | null;
                                PropertyType?: string | null;
                                Buildings?: {
                                  __typename?: 'BuildingsType';
                                  Building: Array<{
                                    __typename?: 'BuildingType';
                                    StructureID?: string | null;
                                    ConstructionFeatures?: {
                                      __typename?: 'CommercialConstructionFeaturesType';
                                      CombustibilityClass?: string | null;
                                      ConstructionClass?: string | null;
                                      FloorHeight?: number | null;
                                      FloorHeightUnitOfMeasurement?:
                                        | string
                                        | null;
                                      LoadingDockPresent?: boolean | null;
                                      NumberOfStoreys?: number | null;
                                      NumberOfUnits?: number | null;
                                      TotalFloorArea?: number | null;
                                      TotalFloorAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      YearBuilt?: number | null;
                                      Basement?: {
                                        __typename?: 'CommercialBasementType';
                                        BasementArea?: number | null;
                                        BasementAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        BasementPresent?: boolean | null;
                                      } | null;
                                      BuildingUpdates?: {
                                        __typename?: 'BuildingUpdatesType';
                                        BuildingUpdate?: Array<
                                          number | null
                                        > | null;
                                      } | null;
                                      CommercialExteriorWallTypes?: {
                                        __typename?: 'CommercialExteriorWallTypesType';
                                        ExteriorWallType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Elevator?: {
                                        __typename?: 'ElevatorType';
                                        ElevatorCount?: number | null;
                                        ElevatorPresent?: boolean | null;
                                      } | null;
                                      Heating?: {
                                        __typename?: 'CommercialHeatingType';
                                        HeatingFuelSources?: {
                                          __typename?: 'HeatingFuelSourcesType';
                                          HeatingFuelSource?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        HeatingTypes?: {
                                          __typename?: 'HeatingTypesType';
                                          HeatingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      Parking?: {
                                        __typename?: 'CommercialParkingType';
                                        ParkingPresent?: boolean | null;
                                        ParkingTypes?: {
                                          __typename?: 'CommercialParkingTypesType';
                                          ParkingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      PlumbingTypes?: {
                                        __typename?: 'CommercialPlumbingType';
                                        PlumbingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofAttributes?: {
                                        __typename?: 'RoofAttributesType';
                                        RoofConstructions?: {
                                          __typename?: 'RoofConstructionsType';
                                          RoofConstruction?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofSurfaces?: {
                                          __typename?: 'RoofSurfacesType';
                                          RoofSurface?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofTypes?: {
                                          __typename?: 'RoofTypesType';
                                          RoofType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      WaterHeater?: {
                                        __typename?: 'CommercialWaterHeaterType';
                                        WaterHeaterFuelType?: Array<
                                          string | null
                                        > | null;
                                        WaterHeaterType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Wiring?: {
                                        __typename?: 'CommercialWiringType';
                                        WiringPanelTypes?: {
                                          __typename?: 'CommercialWiringPanelTypesType';
                                          WiringPanelType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        WiringTypes?: {
                                          __typename?: 'CommercialWiringTypesType';
                                          WiringType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    Protection?: {
                                      __typename?: 'ProtectionType';
                                      FireAlarmPresent?: boolean | null;
                                      FireAlarmType?: string | null;
                                      PhysicalSecurityPresent?: boolean | null;
                                      SecurityAlarmPresent?: boolean | null;
                                      SecurityAlarmType?: string | null;
                                      SprinklerPresent?: boolean | null;
                                      SprinklerTypeCode?: string | null;
                                      PhysicalSecurityTypes?: {
                                        __typename?: 'PhysicalSecurityTypesType';
                                        PhysicalSecurityType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  }>;
                                } | null;
                                Industry?: {
                                  __typename?: 'BusinessIndustryType';
                                  IBCCode?: string | null;
                                  IBCIndustryDescription?: string | null;
                                  SICIndustryDescription?: string | null;
                                  SICCode?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            optaCommercialPerilScoreData?: {
                              __typename?: 'OptaCommercialPerilScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              PropertyPerilScores?: {
                                __typename?: 'PropertyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              CasualtyPerilScores?: {
                                __typename?: 'CasualtyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              AllPerilsScore?: {
                                __typename?: 'CommercialPerilScoreType';
                                LossCostScore?: number | null;
                                PredictedLossCost?: number | null;
                              } | null;
                            } | null;
                          } | null;
                          openingHours: Array<{
                            __typename?: 'OpeningHoursType';
                            day: number;
                            open?: any | null;
                            close?: any | null;
                          }>;
                        } | null;
                      }>;
                      details?: {
                        __typename?: 'AnswerInstanceDetailsType';
                        lat?: number | null;
                        lng?: number | null;
                        images: Array<{
                          __typename?: 'GoogleImageType';
                          description: string;
                          shortDescription: string;
                          category: CoreGoogleImageCategoryChoices;
                          data?: string | null;
                        }>;
                        google?: {
                          __typename?: 'GoogleDetailsType';
                          placeId?: string | null;
                          rating?: number | null;
                          numberOfRatings?: number | null;
                        } | null;
                        opta?: {
                          __typename?: 'OPTADetailsType';
                          optaFusData?: {
                            __typename?: 'OptaFusDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Commercial?: {
                              __typename?: 'FusProductCommercialType';
                              SearchMessage?: string | null;
                              CommercialGrade: Array<{
                                __typename?: 'CommercialGradeType';
                                PublicFireProtectionClassification?:
                                  | number
                                  | null;
                                WaterSystem?: string | null;
                                RiskSCORDetail: Array<{
                                  __typename?: 'RiskSCORDetailType';
                                  RiskSCOR?: number | null;
                                  Type?: string | null;
                                  Adjustment: Array<{
                                    __typename?: 'RiskSCORAdjustmentType';
                                    Adjustment?: string | null;
                                    AdjustmentType?: string | null;
                                  }>;
                                }>;
                              }>;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            Dwelling?: {
                              __typename?: 'FusProductDwellingType';
                              SearchMessage?: string | null;
                              DwellingGrades?: {
                                __typename?: 'DwellingGradesType';
                                AllOtherAreasGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                FirehallProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                HydrantProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                              } | null;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            EarthquakeCodes?: {
                              __typename?: 'EarthquakeCodesType';
                              SearchMessage?: string | null;
                              EarthquakeCode: Array<{
                                __typename?: 'EarthquakeCodeType';
                                Description?: string | null;
                                SystemName?: string | null;
                                Zone?: string | null;
                              }>;
                            } | null;
                            FusMaps?: {
                              __typename?: 'FusMapsType';
                              SearchMessage?: string | null;
                              FusMap: Array<{
                                __typename?: 'FusMapType';
                                Business?: string | null;
                                FusContent?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                            HydrantCounts?: {
                              __typename?: 'HydrantCountsType';
                              SearchMessage?: string | null;
                              HydrantCount: Array<{
                                __typename?: 'HydrantCountType';
                                NumberOfHydrants: number;
                                Ownership?: string | null;
                                RadiusMetres?: string | null;
                              }>;
                            } | null;
                          } | null;
                          optaFloodscoreData?: {
                            __typename?: 'OptaFloodScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            FloodScores?: {
                              __typename?: 'FloodScoresType';
                              FloodScore: Array<{
                                __typename?: 'FloodScoreType';
                                AverageAnnualLossScore?: number | null;
                                Deductible?: number | null;
                                Default?: boolean | null;
                                FloodScore?: number | null;
                                Limit?: number | null;
                                RateFactor?: number | null;
                                Zone?: string | null;
                                ResidentialModifiers?: {
                                  __typename?: 'ResidentialConstructionFeaturesType';
                                  ArchitecturalStyleType?: string | null;
                                  AuxiliaryHeatingType?: string | null;
                                  BathroomCount?: string | null;
                                  CommercialIndicator?: string | null;
                                  ExteriorWallType?: string | null;
                                  FinishedBasement?: boolean | null;
                                  FoundationType?: string | null;
                                  GarageNumberOfCars?: number | null;
                                  GarageType?: string | null;
                                  KitchenCount?: string | null;
                                  OutbuildingPresent?: string | null;
                                  PlumbingType?: string | null;
                                  PrimaryHeatingType?: string | null;
                                  RoofType?: string | null;
                                  SquareFootage?: number | null;
                                  StoreyCount?: string | null;
                                  SwimmingPoolType?: string | null;
                                  YearBuilt?: string | null;
                                  InspectionDetails?: {
                                    __typename?: 'InspectionDetailsType';
                                    InspectionDate?: any | null;
                                    SponsoredInspection?: boolean | null;
                                  } | null;
                                } | null;
                                MultiResidentialModifiers?: {
                                  __typename?: 'MultiResidentialConstructionFeaturesType';
                                  BasementPresent?: boolean | null;
                                  ConstructionType?: string | null;
                                  FloorLevel?: number | null;
                                } | null;
                                AverageAnnualLossValues?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                                AdditionalDetails: Array<{
                                  __typename?: 'FloodDetailType';
                                  Key?: string | null;
                                  Value?: string | null;
                                }>;
                                Premium?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                              }>;
                              FloodMap: Array<{
                                __typename?: 'FloodMapType';
                                FloodEvent?: string | null;
                                Content?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          optaIclarifyCommercialConstructionData?: {
                            __typename?: 'OptaIclarifyCommercialConstructionDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Messages?: {
                              __typename?: 'Messages';
                              Message?: Array<string | null> | null;
                            } | null;
                            Property?: {
                              __typename?: 'CommercialPropertyType';
                              LotSize?: number | null;
                              LotSizeUnitOfMeasurement?: string | null;
                              PropertyType?: string | null;
                              Buildings?: {
                                __typename?: 'BuildingsType';
                                Building: Array<{
                                  __typename?: 'BuildingType';
                                  StructureID?: string | null;
                                  ConstructionFeatures?: {
                                    __typename?: 'CommercialConstructionFeaturesType';
                                    CombustibilityClass?: string | null;
                                    ConstructionClass?: string | null;
                                    FloorHeight?: number | null;
                                    FloorHeightUnitOfMeasurement?:
                                      | string
                                      | null;
                                    LoadingDockPresent?: boolean | null;
                                    NumberOfStoreys?: number | null;
                                    NumberOfUnits?: number | null;
                                    TotalFloorArea?: number | null;
                                    TotalFloorAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    YearBuilt?: number | null;
                                    Basement?: {
                                      __typename?: 'CommercialBasementType';
                                      BasementArea?: number | null;
                                      BasementAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      BasementPresent?: boolean | null;
                                    } | null;
                                    BuildingUpdates?: {
                                      __typename?: 'BuildingUpdatesType';
                                      BuildingUpdate?: Array<
                                        number | null
                                      > | null;
                                    } | null;
                                    CommercialExteriorWallTypes?: {
                                      __typename?: 'CommercialExteriorWallTypesType';
                                      ExteriorWallType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Elevator?: {
                                      __typename?: 'ElevatorType';
                                      ElevatorCount?: number | null;
                                      ElevatorPresent?: boolean | null;
                                    } | null;
                                    Heating?: {
                                      __typename?: 'CommercialHeatingType';
                                      HeatingFuelSources?: {
                                        __typename?: 'HeatingFuelSourcesType';
                                        HeatingFuelSource?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      HeatingTypes?: {
                                        __typename?: 'HeatingTypesType';
                                        HeatingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    Parking?: {
                                      __typename?: 'CommercialParkingType';
                                      ParkingPresent?: boolean | null;
                                      ParkingTypes?: {
                                        __typename?: 'CommercialParkingTypesType';
                                        ParkingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    PlumbingTypes?: {
                                      __typename?: 'CommercialPlumbingType';
                                      PlumbingType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofAttributes?: {
                                      __typename?: 'RoofAttributesType';
                                      RoofConstructions?: {
                                        __typename?: 'RoofConstructionsType';
                                        RoofConstruction?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofSurfaces?: {
                                        __typename?: 'RoofSurfacesType';
                                        RoofSurface?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofTypes?: {
                                        __typename?: 'RoofTypesType';
                                        RoofType?: Array<string | null> | null;
                                      } | null;
                                    } | null;
                                    WaterHeater?: {
                                      __typename?: 'CommercialWaterHeaterType';
                                      WaterHeaterFuelType?: Array<
                                        string | null
                                      > | null;
                                      WaterHeaterType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Wiring?: {
                                      __typename?: 'CommercialWiringType';
                                      WiringPanelTypes?: {
                                        __typename?: 'CommercialWiringPanelTypesType';
                                        WiringPanelType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      WiringTypes?: {
                                        __typename?: 'CommercialWiringTypesType';
                                        WiringType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  Protection?: {
                                    __typename?: 'ProtectionType';
                                    FireAlarmPresent?: boolean | null;
                                    FireAlarmType?: string | null;
                                    PhysicalSecurityPresent?: boolean | null;
                                    SecurityAlarmPresent?: boolean | null;
                                    SecurityAlarmType?: string | null;
                                    SprinklerPresent?: boolean | null;
                                    SprinklerTypeCode?: string | null;
                                    PhysicalSecurityTypes?: {
                                      __typename?: 'PhysicalSecurityTypesType';
                                      PhysicalSecurityType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                  } | null;
                                }>;
                              } | null;
                              Industry?: {
                                __typename?: 'BusinessIndustryType';
                                IBCCode?: string | null;
                                IBCIndustryDescription?: string | null;
                                SICIndustryDescription?: string | null;
                                SICCode?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          optaCommercialPerilScoreData?: {
                            __typename?: 'OptaCommercialPerilScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            PropertyPerilScores?: {
                              __typename?: 'PropertyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            CasualtyPerilScores?: {
                              __typename?: 'CasualtyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            AllPerilsScore?: {
                              __typename?: 'CommercialPerilScoreType';
                              LossCostScore?: number | null;
                              PredictedLossCost?: number | null;
                            } | null;
                          } | null;
                        } | null;
                        openingHours: Array<{
                          __typename?: 'OpeningHoursType';
                          day: number;
                          open?: any | null;
                          close?: any | null;
                        }>;
                      } | null;
                    }>;
                    details?: {
                      __typename?: 'AnswerInstanceDetailsType';
                      lat?: number | null;
                      lng?: number | null;
                      images: Array<{
                        __typename?: 'GoogleImageType';
                        description: string;
                        shortDescription: string;
                        category: CoreGoogleImageCategoryChoices;
                        data?: string | null;
                      }>;
                      google?: {
                        __typename?: 'GoogleDetailsType';
                        placeId?: string | null;
                        rating?: number | null;
                        numberOfRatings?: number | null;
                      } | null;
                      opta?: {
                        __typename?: 'OPTADetailsType';
                        optaFusData?: {
                          __typename?: 'OptaFusDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Commercial?: {
                            __typename?: 'FusProductCommercialType';
                            SearchMessage?: string | null;
                            CommercialGrade: Array<{
                              __typename?: 'CommercialGradeType';
                              PublicFireProtectionClassification?:
                                | number
                                | null;
                              WaterSystem?: string | null;
                              RiskSCORDetail: Array<{
                                __typename?: 'RiskSCORDetailType';
                                RiskSCOR?: number | null;
                                Type?: string | null;
                                Adjustment: Array<{
                                  __typename?: 'RiskSCORAdjustmentType';
                                  Adjustment?: string | null;
                                  AdjustmentType?: string | null;
                                }>;
                              }>;
                            }>;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          Dwelling?: {
                            __typename?: 'FusProductDwellingType';
                            SearchMessage?: string | null;
                            DwellingGrades?: {
                              __typename?: 'DwellingGradesType';
                              AllOtherAreasGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              FirehallProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              HydrantProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                            } | null;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          EarthquakeCodes?: {
                            __typename?: 'EarthquakeCodesType';
                            SearchMessage?: string | null;
                            EarthquakeCode: Array<{
                              __typename?: 'EarthquakeCodeType';
                              Description?: string | null;
                              SystemName?: string | null;
                              Zone?: string | null;
                            }>;
                          } | null;
                          FusMaps?: {
                            __typename?: 'FusMapsType';
                            SearchMessage?: string | null;
                            FusMap: Array<{
                              __typename?: 'FusMapType';
                              Business?: string | null;
                              FusContent?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                          HydrantCounts?: {
                            __typename?: 'HydrantCountsType';
                            SearchMessage?: string | null;
                            HydrantCount: Array<{
                              __typename?: 'HydrantCountType';
                              NumberOfHydrants: number;
                              Ownership?: string | null;
                              RadiusMetres?: string | null;
                            }>;
                          } | null;
                        } | null;
                        optaFloodscoreData?: {
                          __typename?: 'OptaFloodScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          FloodScores?: {
                            __typename?: 'FloodScoresType';
                            FloodScore: Array<{
                              __typename?: 'FloodScoreType';
                              AverageAnnualLossScore?: number | null;
                              Deductible?: number | null;
                              Default?: boolean | null;
                              FloodScore?: number | null;
                              Limit?: number | null;
                              RateFactor?: number | null;
                              Zone?: string | null;
                              ResidentialModifiers?: {
                                __typename?: 'ResidentialConstructionFeaturesType';
                                ArchitecturalStyleType?: string | null;
                                AuxiliaryHeatingType?: string | null;
                                BathroomCount?: string | null;
                                CommercialIndicator?: string | null;
                                ExteriorWallType?: string | null;
                                FinishedBasement?: boolean | null;
                                FoundationType?: string | null;
                                GarageNumberOfCars?: number | null;
                                GarageType?: string | null;
                                KitchenCount?: string | null;
                                OutbuildingPresent?: string | null;
                                PlumbingType?: string | null;
                                PrimaryHeatingType?: string | null;
                                RoofType?: string | null;
                                SquareFootage?: number | null;
                                StoreyCount?: string | null;
                                SwimmingPoolType?: string | null;
                                YearBuilt?: string | null;
                                InspectionDetails?: {
                                  __typename?: 'InspectionDetailsType';
                                  InspectionDate?: any | null;
                                  SponsoredInspection?: boolean | null;
                                } | null;
                              } | null;
                              MultiResidentialModifiers?: {
                                __typename?: 'MultiResidentialConstructionFeaturesType';
                                BasementPresent?: boolean | null;
                                ConstructionType?: string | null;
                                FloorLevel?: number | null;
                              } | null;
                              AverageAnnualLossValues?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                              AdditionalDetails: Array<{
                                __typename?: 'FloodDetailType';
                                Key?: string | null;
                                Value?: string | null;
                              }>;
                              Premium?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                            }>;
                            FloodMap: Array<{
                              __typename?: 'FloodMapType';
                              FloodEvent?: string | null;
                              Content?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        optaIclarifyCommercialConstructionData?: {
                          __typename?: 'OptaIclarifyCommercialConstructionDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Messages?: {
                            __typename?: 'Messages';
                            Message?: Array<string | null> | null;
                          } | null;
                          Property?: {
                            __typename?: 'CommercialPropertyType';
                            LotSize?: number | null;
                            LotSizeUnitOfMeasurement?: string | null;
                            PropertyType?: string | null;
                            Buildings?: {
                              __typename?: 'BuildingsType';
                              Building: Array<{
                                __typename?: 'BuildingType';
                                StructureID?: string | null;
                                ConstructionFeatures?: {
                                  __typename?: 'CommercialConstructionFeaturesType';
                                  CombustibilityClass?: string | null;
                                  ConstructionClass?: string | null;
                                  FloorHeight?: number | null;
                                  FloorHeightUnitOfMeasurement?: string | null;
                                  LoadingDockPresent?: boolean | null;
                                  NumberOfStoreys?: number | null;
                                  NumberOfUnits?: number | null;
                                  TotalFloorArea?: number | null;
                                  TotalFloorAreaUnitOfMeasurement?:
                                    | string
                                    | null;
                                  YearBuilt?: number | null;
                                  Basement?: {
                                    __typename?: 'CommercialBasementType';
                                    BasementArea?: number | null;
                                    BasementAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    BasementPresent?: boolean | null;
                                  } | null;
                                  BuildingUpdates?: {
                                    __typename?: 'BuildingUpdatesType';
                                    BuildingUpdate?: Array<
                                      number | null
                                    > | null;
                                  } | null;
                                  CommercialExteriorWallTypes?: {
                                    __typename?: 'CommercialExteriorWallTypesType';
                                    ExteriorWallType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Elevator?: {
                                    __typename?: 'ElevatorType';
                                    ElevatorCount?: number | null;
                                    ElevatorPresent?: boolean | null;
                                  } | null;
                                  Heating?: {
                                    __typename?: 'CommercialHeatingType';
                                    HeatingFuelSources?: {
                                      __typename?: 'HeatingFuelSourcesType';
                                      HeatingFuelSource?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    HeatingTypes?: {
                                      __typename?: 'HeatingTypesType';
                                      HeatingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  Parking?: {
                                    __typename?: 'CommercialParkingType';
                                    ParkingPresent?: boolean | null;
                                    ParkingTypes?: {
                                      __typename?: 'CommercialParkingTypesType';
                                      ParkingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  PlumbingTypes?: {
                                    __typename?: 'CommercialPlumbingType';
                                    PlumbingType?: Array<string | null> | null;
                                  } | null;
                                  RoofAttributes?: {
                                    __typename?: 'RoofAttributesType';
                                    RoofConstructions?: {
                                      __typename?: 'RoofConstructionsType';
                                      RoofConstruction?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofSurfaces?: {
                                      __typename?: 'RoofSurfacesType';
                                      RoofSurface?: Array<string | null> | null;
                                    } | null;
                                    RoofTypes?: {
                                      __typename?: 'RoofTypesType';
                                      RoofType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  WaterHeater?: {
                                    __typename?: 'CommercialWaterHeaterType';
                                    WaterHeaterFuelType?: Array<
                                      string | null
                                    > | null;
                                    WaterHeaterType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Wiring?: {
                                    __typename?: 'CommercialWiringType';
                                    WiringPanelTypes?: {
                                      __typename?: 'CommercialWiringPanelTypesType';
                                      WiringPanelType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    WiringTypes?: {
                                      __typename?: 'CommercialWiringTypesType';
                                      WiringType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Protection?: {
                                  __typename?: 'ProtectionType';
                                  FireAlarmPresent?: boolean | null;
                                  FireAlarmType?: string | null;
                                  PhysicalSecurityPresent?: boolean | null;
                                  SecurityAlarmPresent?: boolean | null;
                                  SecurityAlarmType?: string | null;
                                  SprinklerPresent?: boolean | null;
                                  SprinklerTypeCode?: string | null;
                                  PhysicalSecurityTypes?: {
                                    __typename?: 'PhysicalSecurityTypesType';
                                    PhysicalSecurityType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                } | null;
                              }>;
                            } | null;
                            Industry?: {
                              __typename?: 'BusinessIndustryType';
                              IBCCode?: string | null;
                              IBCIndustryDescription?: string | null;
                              SICIndustryDescription?: string | null;
                              SICCode?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        optaCommercialPerilScoreData?: {
                          __typename?: 'OptaCommercialPerilScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          PropertyPerilScores?: {
                            __typename?: 'PropertyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          CasualtyPerilScores?: {
                            __typename?: 'CasualtyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          AllPerilsScore?: {
                            __typename?: 'CommercialPerilScoreType';
                            LossCostScore?: number | null;
                            PredictedLossCost?: number | null;
                          } | null;
                        } | null;
                      } | null;
                      openingHours: Array<{
                        __typename?: 'OpeningHoursType';
                        day: number;
                        open?: any | null;
                        close?: any | null;
                      }>;
                    } | null;
                  }>;
                  details?: {
                    __typename?: 'AnswerInstanceDetailsType';
                    lat?: number | null;
                    lng?: number | null;
                    images: Array<{
                      __typename?: 'GoogleImageType';
                      description: string;
                      shortDescription: string;
                      category: CoreGoogleImageCategoryChoices;
                      data?: string | null;
                    }>;
                    google?: {
                      __typename?: 'GoogleDetailsType';
                      placeId?: string | null;
                      rating?: number | null;
                      numberOfRatings?: number | null;
                    } | null;
                    opta?: {
                      __typename?: 'OPTADetailsType';
                      optaFusData?: {
                        __typename?: 'OptaFusDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Commercial?: {
                          __typename?: 'FusProductCommercialType';
                          SearchMessage?: string | null;
                          CommercialGrade: Array<{
                            __typename?: 'CommercialGradeType';
                            PublicFireProtectionClassification?: number | null;
                            WaterSystem?: string | null;
                            RiskSCORDetail: Array<{
                              __typename?: 'RiskSCORDetailType';
                              RiskSCOR?: number | null;
                              Type?: string | null;
                              Adjustment: Array<{
                                __typename?: 'RiskSCORAdjustmentType';
                                Adjustment?: string | null;
                                AdjustmentType?: string | null;
                              }>;
                            }>;
                          }>;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        Dwelling?: {
                          __typename?: 'FusProductDwellingType';
                          SearchMessage?: string | null;
                          DwellingGrades?: {
                            __typename?: 'DwellingGradesType';
                            AllOtherAreasGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            FirehallProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            HydrantProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                          } | null;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        EarthquakeCodes?: {
                          __typename?: 'EarthquakeCodesType';
                          SearchMessage?: string | null;
                          EarthquakeCode: Array<{
                            __typename?: 'EarthquakeCodeType';
                            Description?: string | null;
                            SystemName?: string | null;
                            Zone?: string | null;
                          }>;
                        } | null;
                        FusMaps?: {
                          __typename?: 'FusMapsType';
                          SearchMessage?: string | null;
                          FusMap: Array<{
                            __typename?: 'FusMapType';
                            Business?: string | null;
                            FusContent?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                        HydrantCounts?: {
                          __typename?: 'HydrantCountsType';
                          SearchMessage?: string | null;
                          HydrantCount: Array<{
                            __typename?: 'HydrantCountType';
                            NumberOfHydrants: number;
                            Ownership?: string | null;
                            RadiusMetres?: string | null;
                          }>;
                        } | null;
                      } | null;
                      optaFloodscoreData?: {
                        __typename?: 'OptaFloodScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        FloodScores?: {
                          __typename?: 'FloodScoresType';
                          FloodScore: Array<{
                            __typename?: 'FloodScoreType';
                            AverageAnnualLossScore?: number | null;
                            Deductible?: number | null;
                            Default?: boolean | null;
                            FloodScore?: number | null;
                            Limit?: number | null;
                            RateFactor?: number | null;
                            Zone?: string | null;
                            ResidentialModifiers?: {
                              __typename?: 'ResidentialConstructionFeaturesType';
                              ArchitecturalStyleType?: string | null;
                              AuxiliaryHeatingType?: string | null;
                              BathroomCount?: string | null;
                              CommercialIndicator?: string | null;
                              ExteriorWallType?: string | null;
                              FinishedBasement?: boolean | null;
                              FoundationType?: string | null;
                              GarageNumberOfCars?: number | null;
                              GarageType?: string | null;
                              KitchenCount?: string | null;
                              OutbuildingPresent?: string | null;
                              PlumbingType?: string | null;
                              PrimaryHeatingType?: string | null;
                              RoofType?: string | null;
                              SquareFootage?: number | null;
                              StoreyCount?: string | null;
                              SwimmingPoolType?: string | null;
                              YearBuilt?: string | null;
                              InspectionDetails?: {
                                __typename?: 'InspectionDetailsType';
                                InspectionDate?: any | null;
                                SponsoredInspection?: boolean | null;
                              } | null;
                            } | null;
                            MultiResidentialModifiers?: {
                              __typename?: 'MultiResidentialConstructionFeaturesType';
                              BasementPresent?: boolean | null;
                              ConstructionType?: string | null;
                              FloorLevel?: number | null;
                            } | null;
                            AverageAnnualLossValues?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                            AdditionalDetails: Array<{
                              __typename?: 'FloodDetailType';
                              Key?: string | null;
                              Value?: string | null;
                            }>;
                            Premium?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                          }>;
                          FloodMap: Array<{
                            __typename?: 'FloodMapType';
                            FloodEvent?: string | null;
                            Content?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      optaIclarifyCommercialConstructionData?: {
                        __typename?: 'OptaIclarifyCommercialConstructionDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Messages?: {
                          __typename?: 'Messages';
                          Message?: Array<string | null> | null;
                        } | null;
                        Property?: {
                          __typename?: 'CommercialPropertyType';
                          LotSize?: number | null;
                          LotSizeUnitOfMeasurement?: string | null;
                          PropertyType?: string | null;
                          Buildings?: {
                            __typename?: 'BuildingsType';
                            Building: Array<{
                              __typename?: 'BuildingType';
                              StructureID?: string | null;
                              ConstructionFeatures?: {
                                __typename?: 'CommercialConstructionFeaturesType';
                                CombustibilityClass?: string | null;
                                ConstructionClass?: string | null;
                                FloorHeight?: number | null;
                                FloorHeightUnitOfMeasurement?: string | null;
                                LoadingDockPresent?: boolean | null;
                                NumberOfStoreys?: number | null;
                                NumberOfUnits?: number | null;
                                TotalFloorArea?: number | null;
                                TotalFloorAreaUnitOfMeasurement?: string | null;
                                YearBuilt?: number | null;
                                Basement?: {
                                  __typename?: 'CommercialBasementType';
                                  BasementArea?: number | null;
                                  BasementAreaUnitOfMeasurement?: string | null;
                                  BasementPresent?: boolean | null;
                                } | null;
                                BuildingUpdates?: {
                                  __typename?: 'BuildingUpdatesType';
                                  BuildingUpdate?: Array<number | null> | null;
                                } | null;
                                CommercialExteriorWallTypes?: {
                                  __typename?: 'CommercialExteriorWallTypesType';
                                  ExteriorWallType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                Elevator?: {
                                  __typename?: 'ElevatorType';
                                  ElevatorCount?: number | null;
                                  ElevatorPresent?: boolean | null;
                                } | null;
                                Heating?: {
                                  __typename?: 'CommercialHeatingType';
                                  HeatingFuelSources?: {
                                    __typename?: 'HeatingFuelSourcesType';
                                    HeatingFuelSource?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  HeatingTypes?: {
                                    __typename?: 'HeatingTypesType';
                                    HeatingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                Parking?: {
                                  __typename?: 'CommercialParkingType';
                                  ParkingPresent?: boolean | null;
                                  ParkingTypes?: {
                                    __typename?: 'CommercialParkingTypesType';
                                    ParkingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                PlumbingTypes?: {
                                  __typename?: 'CommercialPlumbingType';
                                  PlumbingType?: Array<string | null> | null;
                                } | null;
                                RoofAttributes?: {
                                  __typename?: 'RoofAttributesType';
                                  RoofConstructions?: {
                                    __typename?: 'RoofConstructionsType';
                                    RoofConstruction?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  RoofSurfaces?: {
                                    __typename?: 'RoofSurfacesType';
                                    RoofSurface?: Array<string | null> | null;
                                  } | null;
                                  RoofTypes?: {
                                    __typename?: 'RoofTypesType';
                                    RoofType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                WaterHeater?: {
                                  __typename?: 'CommercialWaterHeaterType';
                                  WaterHeaterFuelType?: Array<
                                    string | null
                                  > | null;
                                  WaterHeaterType?: Array<string | null> | null;
                                } | null;
                                Wiring?: {
                                  __typename?: 'CommercialWiringType';
                                  WiringPanelTypes?: {
                                    __typename?: 'CommercialWiringPanelTypesType';
                                    WiringPanelType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  WiringTypes?: {
                                    __typename?: 'CommercialWiringTypesType';
                                    WiringType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                              } | null;
                              Protection?: {
                                __typename?: 'ProtectionType';
                                FireAlarmPresent?: boolean | null;
                                FireAlarmType?: string | null;
                                PhysicalSecurityPresent?: boolean | null;
                                SecurityAlarmPresent?: boolean | null;
                                SecurityAlarmType?: string | null;
                                SprinklerPresent?: boolean | null;
                                SprinklerTypeCode?: string | null;
                                PhysicalSecurityTypes?: {
                                  __typename?: 'PhysicalSecurityTypesType';
                                  PhysicalSecurityType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                              } | null;
                            }>;
                          } | null;
                          Industry?: {
                            __typename?: 'BusinessIndustryType';
                            IBCCode?: string | null;
                            IBCIndustryDescription?: string | null;
                            SICIndustryDescription?: string | null;
                            SICCode?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      optaCommercialPerilScoreData?: {
                        __typename?: 'OptaCommercialPerilScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        PropertyPerilScores?: {
                          __typename?: 'PropertyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        CasualtyPerilScores?: {
                          __typename?: 'CasualtyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        AllPerilsScore?: {
                          __typename?: 'CommercialPerilScoreType';
                          LossCostScore?: number | null;
                          PredictedLossCost?: number | null;
                        } | null;
                      } | null;
                    } | null;
                    openingHours: Array<{
                      __typename?: 'OpeningHoursType';
                      day: number;
                      open?: any | null;
                      close?: any | null;
                    }>;
                  } | null;
                }>;
                details?: {
                  __typename?: 'AnswerInstanceDetailsType';
                  lat?: number | null;
                  lng?: number | null;
                  images: Array<{
                    __typename?: 'GoogleImageType';
                    description: string;
                    shortDescription: string;
                    category: CoreGoogleImageCategoryChoices;
                    data?: string | null;
                  }>;
                  google?: {
                    __typename?: 'GoogleDetailsType';
                    placeId?: string | null;
                    rating?: number | null;
                    numberOfRatings?: number | null;
                  } | null;
                  opta?: {
                    __typename?: 'OPTADetailsType';
                    optaFusData?: {
                      __typename?: 'OptaFusDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Commercial?: {
                        __typename?: 'FusProductCommercialType';
                        SearchMessage?: string | null;
                        CommercialGrade: Array<{
                          __typename?: 'CommercialGradeType';
                          PublicFireProtectionClassification?: number | null;
                          WaterSystem?: string | null;
                          RiskSCORDetail: Array<{
                            __typename?: 'RiskSCORDetailType';
                            RiskSCOR?: number | null;
                            Type?: string | null;
                            Adjustment: Array<{
                              __typename?: 'RiskSCORAdjustmentType';
                              Adjustment?: string | null;
                              AdjustmentType?: string | null;
                            }>;
                          }>;
                        }>;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      Dwelling?: {
                        __typename?: 'FusProductDwellingType';
                        SearchMessage?: string | null;
                        DwellingGrades?: {
                          __typename?: 'DwellingGradesType';
                          AllOtherAreasGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          FirehallProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          HydrantProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                        } | null;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      EarthquakeCodes?: {
                        __typename?: 'EarthquakeCodesType';
                        SearchMessage?: string | null;
                        EarthquakeCode: Array<{
                          __typename?: 'EarthquakeCodeType';
                          Description?: string | null;
                          SystemName?: string | null;
                          Zone?: string | null;
                        }>;
                      } | null;
                      FusMaps?: {
                        __typename?: 'FusMapsType';
                        SearchMessage?: string | null;
                        FusMap: Array<{
                          __typename?: 'FusMapType';
                          Business?: string | null;
                          FusContent?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                      HydrantCounts?: {
                        __typename?: 'HydrantCountsType';
                        SearchMessage?: string | null;
                        HydrantCount: Array<{
                          __typename?: 'HydrantCountType';
                          NumberOfHydrants: number;
                          Ownership?: string | null;
                          RadiusMetres?: string | null;
                        }>;
                      } | null;
                    } | null;
                    optaFloodscoreData?: {
                      __typename?: 'OptaFloodScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      FloodScores?: {
                        __typename?: 'FloodScoresType';
                        FloodScore: Array<{
                          __typename?: 'FloodScoreType';
                          AverageAnnualLossScore?: number | null;
                          Deductible?: number | null;
                          Default?: boolean | null;
                          FloodScore?: number | null;
                          Limit?: number | null;
                          RateFactor?: number | null;
                          Zone?: string | null;
                          ResidentialModifiers?: {
                            __typename?: 'ResidentialConstructionFeaturesType';
                            ArchitecturalStyleType?: string | null;
                            AuxiliaryHeatingType?: string | null;
                            BathroomCount?: string | null;
                            CommercialIndicator?: string | null;
                            ExteriorWallType?: string | null;
                            FinishedBasement?: boolean | null;
                            FoundationType?: string | null;
                            GarageNumberOfCars?: number | null;
                            GarageType?: string | null;
                            KitchenCount?: string | null;
                            OutbuildingPresent?: string | null;
                            PlumbingType?: string | null;
                            PrimaryHeatingType?: string | null;
                            RoofType?: string | null;
                            SquareFootage?: number | null;
                            StoreyCount?: string | null;
                            SwimmingPoolType?: string | null;
                            YearBuilt?: string | null;
                            InspectionDetails?: {
                              __typename?: 'InspectionDetailsType';
                              InspectionDate?: any | null;
                              SponsoredInspection?: boolean | null;
                            } | null;
                          } | null;
                          MultiResidentialModifiers?: {
                            __typename?: 'MultiResidentialConstructionFeaturesType';
                            BasementPresent?: boolean | null;
                            ConstructionType?: string | null;
                            FloorLevel?: number | null;
                          } | null;
                          AverageAnnualLossValues?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                          AdditionalDetails: Array<{
                            __typename?: 'FloodDetailType';
                            Key?: string | null;
                            Value?: string | null;
                          }>;
                          Premium?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                        }>;
                        FloodMap: Array<{
                          __typename?: 'FloodMapType';
                          FloodEvent?: string | null;
                          Content?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    optaIclarifyCommercialConstructionData?: {
                      __typename?: 'OptaIclarifyCommercialConstructionDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Messages?: {
                        __typename?: 'Messages';
                        Message?: Array<string | null> | null;
                      } | null;
                      Property?: {
                        __typename?: 'CommercialPropertyType';
                        LotSize?: number | null;
                        LotSizeUnitOfMeasurement?: string | null;
                        PropertyType?: string | null;
                        Buildings?: {
                          __typename?: 'BuildingsType';
                          Building: Array<{
                            __typename?: 'BuildingType';
                            StructureID?: string | null;
                            ConstructionFeatures?: {
                              __typename?: 'CommercialConstructionFeaturesType';
                              CombustibilityClass?: string | null;
                              ConstructionClass?: string | null;
                              FloorHeight?: number | null;
                              FloorHeightUnitOfMeasurement?: string | null;
                              LoadingDockPresent?: boolean | null;
                              NumberOfStoreys?: number | null;
                              NumberOfUnits?: number | null;
                              TotalFloorArea?: number | null;
                              TotalFloorAreaUnitOfMeasurement?: string | null;
                              YearBuilt?: number | null;
                              Basement?: {
                                __typename?: 'CommercialBasementType';
                                BasementArea?: number | null;
                                BasementAreaUnitOfMeasurement?: string | null;
                                BasementPresent?: boolean | null;
                              } | null;
                              BuildingUpdates?: {
                                __typename?: 'BuildingUpdatesType';
                                BuildingUpdate?: Array<number | null> | null;
                              } | null;
                              CommercialExteriorWallTypes?: {
                                __typename?: 'CommercialExteriorWallTypesType';
                                ExteriorWallType?: Array<string | null> | null;
                              } | null;
                              Elevator?: {
                                __typename?: 'ElevatorType';
                                ElevatorCount?: number | null;
                                ElevatorPresent?: boolean | null;
                              } | null;
                              Heating?: {
                                __typename?: 'CommercialHeatingType';
                                HeatingFuelSources?: {
                                  __typename?: 'HeatingFuelSourcesType';
                                  HeatingFuelSource?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                HeatingTypes?: {
                                  __typename?: 'HeatingTypesType';
                                  HeatingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              Parking?: {
                                __typename?: 'CommercialParkingType';
                                ParkingPresent?: boolean | null;
                                ParkingTypes?: {
                                  __typename?: 'CommercialParkingTypesType';
                                  ParkingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              PlumbingTypes?: {
                                __typename?: 'CommercialPlumbingType';
                                PlumbingType?: Array<string | null> | null;
                              } | null;
                              RoofAttributes?: {
                                __typename?: 'RoofAttributesType';
                                RoofConstructions?: {
                                  __typename?: 'RoofConstructionsType';
                                  RoofConstruction?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                RoofSurfaces?: {
                                  __typename?: 'RoofSurfacesType';
                                  RoofSurface?: Array<string | null> | null;
                                } | null;
                                RoofTypes?: {
                                  __typename?: 'RoofTypesType';
                                  RoofType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              WaterHeater?: {
                                __typename?: 'CommercialWaterHeaterType';
                                WaterHeaterFuelType?: Array<
                                  string | null
                                > | null;
                                WaterHeaterType?: Array<string | null> | null;
                              } | null;
                              Wiring?: {
                                __typename?: 'CommercialWiringType';
                                WiringPanelTypes?: {
                                  __typename?: 'CommercialWiringPanelTypesType';
                                  WiringPanelType?: Array<string | null> | null;
                                } | null;
                                WiringTypes?: {
                                  __typename?: 'CommercialWiringTypesType';
                                  WiringType?: Array<string | null> | null;
                                } | null;
                              } | null;
                            } | null;
                            Protection?: {
                              __typename?: 'ProtectionType';
                              FireAlarmPresent?: boolean | null;
                              FireAlarmType?: string | null;
                              PhysicalSecurityPresent?: boolean | null;
                              SecurityAlarmPresent?: boolean | null;
                              SecurityAlarmType?: string | null;
                              SprinklerPresent?: boolean | null;
                              SprinklerTypeCode?: string | null;
                              PhysicalSecurityTypes?: {
                                __typename?: 'PhysicalSecurityTypesType';
                                PhysicalSecurityType?: Array<
                                  string | null
                                > | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                        Industry?: {
                          __typename?: 'BusinessIndustryType';
                          IBCCode?: string | null;
                          IBCIndustryDescription?: string | null;
                          SICIndustryDescription?: string | null;
                          SICCode?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    optaCommercialPerilScoreData?: {
                      __typename?: 'OptaCommercialPerilScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      PropertyPerilScores?: {
                        __typename?: 'PropertyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      CasualtyPerilScores?: {
                        __typename?: 'CasualtyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      AllPerilsScore?: {
                        __typename?: 'CommercialPerilScoreType';
                        LossCostScore?: number | null;
                        PredictedLossCost?: number | null;
                      } | null;
                    } | null;
                  } | null;
                  openingHours: Array<{
                    __typename?: 'OpeningHoursType';
                    day: number;
                    open?: any | null;
                    close?: any | null;
                  }>;
                } | null;
              }>;
              details?: {
                __typename?: 'AnswerInstanceDetailsType';
                lat?: number | null;
                lng?: number | null;
                images: Array<{
                  __typename?: 'GoogleImageType';
                  description: string;
                  shortDescription: string;
                  category: CoreGoogleImageCategoryChoices;
                  data?: string | null;
                }>;
                google?: {
                  __typename?: 'GoogleDetailsType';
                  placeId?: string | null;
                  rating?: number | null;
                  numberOfRatings?: number | null;
                } | null;
                opta?: {
                  __typename?: 'OPTADetailsType';
                  optaFusData?: {
                    __typename?: 'OptaFusDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Commercial?: {
                      __typename?: 'FusProductCommercialType';
                      SearchMessage?: string | null;
                      CommercialGrade: Array<{
                        __typename?: 'CommercialGradeType';
                        PublicFireProtectionClassification?: number | null;
                        WaterSystem?: string | null;
                        RiskSCORDetail: Array<{
                          __typename?: 'RiskSCORDetailType';
                          RiskSCOR?: number | null;
                          Type?: string | null;
                          Adjustment: Array<{
                            __typename?: 'RiskSCORAdjustmentType';
                            Adjustment?: string | null;
                            AdjustmentType?: string | null;
                          }>;
                        }>;
                      }>;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    Dwelling?: {
                      __typename?: 'FusProductDwellingType';
                      SearchMessage?: string | null;
                      DwellingGrades?: {
                        __typename?: 'DwellingGradesType';
                        AllOtherAreasGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        FirehallProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        HydrantProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                      } | null;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    EarthquakeCodes?: {
                      __typename?: 'EarthquakeCodesType';
                      SearchMessage?: string | null;
                      EarthquakeCode: Array<{
                        __typename?: 'EarthquakeCodeType';
                        Description?: string | null;
                        SystemName?: string | null;
                        Zone?: string | null;
                      }>;
                    } | null;
                    FusMaps?: {
                      __typename?: 'FusMapsType';
                      SearchMessage?: string | null;
                      FusMap: Array<{
                        __typename?: 'FusMapType';
                        Business?: string | null;
                        FusContent?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                    HydrantCounts?: {
                      __typename?: 'HydrantCountsType';
                      SearchMessage?: string | null;
                      HydrantCount: Array<{
                        __typename?: 'HydrantCountType';
                        NumberOfHydrants: number;
                        Ownership?: string | null;
                        RadiusMetres?: string | null;
                      }>;
                    } | null;
                  } | null;
                  optaFloodscoreData?: {
                    __typename?: 'OptaFloodScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    FloodScores?: {
                      __typename?: 'FloodScoresType';
                      FloodScore: Array<{
                        __typename?: 'FloodScoreType';
                        AverageAnnualLossScore?: number | null;
                        Deductible?: number | null;
                        Default?: boolean | null;
                        FloodScore?: number | null;
                        Limit?: number | null;
                        RateFactor?: number | null;
                        Zone?: string | null;
                        ResidentialModifiers?: {
                          __typename?: 'ResidentialConstructionFeaturesType';
                          ArchitecturalStyleType?: string | null;
                          AuxiliaryHeatingType?: string | null;
                          BathroomCount?: string | null;
                          CommercialIndicator?: string | null;
                          ExteriorWallType?: string | null;
                          FinishedBasement?: boolean | null;
                          FoundationType?: string | null;
                          GarageNumberOfCars?: number | null;
                          GarageType?: string | null;
                          KitchenCount?: string | null;
                          OutbuildingPresent?: string | null;
                          PlumbingType?: string | null;
                          PrimaryHeatingType?: string | null;
                          RoofType?: string | null;
                          SquareFootage?: number | null;
                          StoreyCount?: string | null;
                          SwimmingPoolType?: string | null;
                          YearBuilt?: string | null;
                          InspectionDetails?: {
                            __typename?: 'InspectionDetailsType';
                            InspectionDate?: any | null;
                            SponsoredInspection?: boolean | null;
                          } | null;
                        } | null;
                        MultiResidentialModifiers?: {
                          __typename?: 'MultiResidentialConstructionFeaturesType';
                          BasementPresent?: boolean | null;
                          ConstructionType?: string | null;
                          FloorLevel?: number | null;
                        } | null;
                        AverageAnnualLossValues?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                        AdditionalDetails: Array<{
                          __typename?: 'FloodDetailType';
                          Key?: string | null;
                          Value?: string | null;
                        }>;
                        Premium?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                      }>;
                      FloodMap: Array<{
                        __typename?: 'FloodMapType';
                        FloodEvent?: string | null;
                        Content?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  optaIclarifyCommercialConstructionData?: {
                    __typename?: 'OptaIclarifyCommercialConstructionDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Messages?: {
                      __typename?: 'Messages';
                      Message?: Array<string | null> | null;
                    } | null;
                    Property?: {
                      __typename?: 'CommercialPropertyType';
                      LotSize?: number | null;
                      LotSizeUnitOfMeasurement?: string | null;
                      PropertyType?: string | null;
                      Buildings?: {
                        __typename?: 'BuildingsType';
                        Building: Array<{
                          __typename?: 'BuildingType';
                          StructureID?: string | null;
                          ConstructionFeatures?: {
                            __typename?: 'CommercialConstructionFeaturesType';
                            CombustibilityClass?: string | null;
                            ConstructionClass?: string | null;
                            FloorHeight?: number | null;
                            FloorHeightUnitOfMeasurement?: string | null;
                            LoadingDockPresent?: boolean | null;
                            NumberOfStoreys?: number | null;
                            NumberOfUnits?: number | null;
                            TotalFloorArea?: number | null;
                            TotalFloorAreaUnitOfMeasurement?: string | null;
                            YearBuilt?: number | null;
                            Basement?: {
                              __typename?: 'CommercialBasementType';
                              BasementArea?: number | null;
                              BasementAreaUnitOfMeasurement?: string | null;
                              BasementPresent?: boolean | null;
                            } | null;
                            BuildingUpdates?: {
                              __typename?: 'BuildingUpdatesType';
                              BuildingUpdate?: Array<number | null> | null;
                            } | null;
                            CommercialExteriorWallTypes?: {
                              __typename?: 'CommercialExteriorWallTypesType';
                              ExteriorWallType?: Array<string | null> | null;
                            } | null;
                            Elevator?: {
                              __typename?: 'ElevatorType';
                              ElevatorCount?: number | null;
                              ElevatorPresent?: boolean | null;
                            } | null;
                            Heating?: {
                              __typename?: 'CommercialHeatingType';
                              HeatingFuelSources?: {
                                __typename?: 'HeatingFuelSourcesType';
                                HeatingFuelSource?: Array<string | null> | null;
                              } | null;
                              HeatingTypes?: {
                                __typename?: 'HeatingTypesType';
                                HeatingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            Parking?: {
                              __typename?: 'CommercialParkingType';
                              ParkingPresent?: boolean | null;
                              ParkingTypes?: {
                                __typename?: 'CommercialParkingTypesType';
                                ParkingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            PlumbingTypes?: {
                              __typename?: 'CommercialPlumbingType';
                              PlumbingType?: Array<string | null> | null;
                            } | null;
                            RoofAttributes?: {
                              __typename?: 'RoofAttributesType';
                              RoofConstructions?: {
                                __typename?: 'RoofConstructionsType';
                                RoofConstruction?: Array<string | null> | null;
                              } | null;
                              RoofSurfaces?: {
                                __typename?: 'RoofSurfacesType';
                                RoofSurface?: Array<string | null> | null;
                              } | null;
                              RoofTypes?: {
                                __typename?: 'RoofTypesType';
                                RoofType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            WaterHeater?: {
                              __typename?: 'CommercialWaterHeaterType';
                              WaterHeaterFuelType?: Array<string | null> | null;
                              WaterHeaterType?: Array<string | null> | null;
                            } | null;
                            Wiring?: {
                              __typename?: 'CommercialWiringType';
                              WiringPanelTypes?: {
                                __typename?: 'CommercialWiringPanelTypesType';
                                WiringPanelType?: Array<string | null> | null;
                              } | null;
                              WiringTypes?: {
                                __typename?: 'CommercialWiringTypesType';
                                WiringType?: Array<string | null> | null;
                              } | null;
                            } | null;
                          } | null;
                          Protection?: {
                            __typename?: 'ProtectionType';
                            FireAlarmPresent?: boolean | null;
                            FireAlarmType?: string | null;
                            PhysicalSecurityPresent?: boolean | null;
                            SecurityAlarmPresent?: boolean | null;
                            SecurityAlarmType?: string | null;
                            SprinklerPresent?: boolean | null;
                            SprinklerTypeCode?: string | null;
                            PhysicalSecurityTypes?: {
                              __typename?: 'PhysicalSecurityTypesType';
                              PhysicalSecurityType?: Array<
                                string | null
                              > | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                      Industry?: {
                        __typename?: 'BusinessIndustryType';
                        IBCCode?: string | null;
                        IBCIndustryDescription?: string | null;
                        SICIndustryDescription?: string | null;
                        SICCode?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  optaCommercialPerilScoreData?: {
                    __typename?: 'OptaCommercialPerilScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    PropertyPerilScores?: {
                      __typename?: 'PropertyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    CasualtyPerilScores?: {
                      __typename?: 'CasualtyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    AllPerilsScore?: {
                      __typename?: 'CommercialPerilScoreType';
                      LossCostScore?: number | null;
                      PredictedLossCost?: number | null;
                    } | null;
                  } | null;
                } | null;
                openingHours: Array<{
                  __typename?: 'OpeningHoursType';
                  day: number;
                  open?: any | null;
                  close?: any | null;
                }>;
              } | null;
            }>;
            details?: {
              __typename?: 'AnswerInstanceDetailsType';
              lat?: number | null;
              lng?: number | null;
              images: Array<{
                __typename?: 'GoogleImageType';
                description: string;
                shortDescription: string;
                category: CoreGoogleImageCategoryChoices;
                data?: string | null;
              }>;
              google?: {
                __typename?: 'GoogleDetailsType';
                placeId?: string | null;
                rating?: number | null;
                numberOfRatings?: number | null;
              } | null;
              opta?: {
                __typename?: 'OPTADetailsType';
                optaFusData?: {
                  __typename?: 'OptaFusDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Commercial?: {
                    __typename?: 'FusProductCommercialType';
                    SearchMessage?: string | null;
                    CommercialGrade: Array<{
                      __typename?: 'CommercialGradeType';
                      PublicFireProtectionClassification?: number | null;
                      WaterSystem?: string | null;
                      RiskSCORDetail: Array<{
                        __typename?: 'RiskSCORDetailType';
                        RiskSCOR?: number | null;
                        Type?: string | null;
                        Adjustment: Array<{
                          __typename?: 'RiskSCORAdjustmentType';
                          Adjustment?: string | null;
                          AdjustmentType?: string | null;
                        }>;
                      }>;
                    }>;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  Dwelling?: {
                    __typename?: 'FusProductDwellingType';
                    SearchMessage?: string | null;
                    DwellingGrades?: {
                      __typename?: 'DwellingGradesType';
                      AllOtherAreasGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      FirehallProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      HydrantProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                    } | null;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  EarthquakeCodes?: {
                    __typename?: 'EarthquakeCodesType';
                    SearchMessage?: string | null;
                    EarthquakeCode: Array<{
                      __typename?: 'EarthquakeCodeType';
                      Description?: string | null;
                      SystemName?: string | null;
                      Zone?: string | null;
                    }>;
                  } | null;
                  FusMaps?: {
                    __typename?: 'FusMapsType';
                    SearchMessage?: string | null;
                    FusMap: Array<{
                      __typename?: 'FusMapType';
                      Business?: string | null;
                      FusContent?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                  HydrantCounts?: {
                    __typename?: 'HydrantCountsType';
                    SearchMessage?: string | null;
                    HydrantCount: Array<{
                      __typename?: 'HydrantCountType';
                      NumberOfHydrants: number;
                      Ownership?: string | null;
                      RadiusMetres?: string | null;
                    }>;
                  } | null;
                } | null;
                optaFloodscoreData?: {
                  __typename?: 'OptaFloodScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  FloodScores?: {
                    __typename?: 'FloodScoresType';
                    FloodScore: Array<{
                      __typename?: 'FloodScoreType';
                      AverageAnnualLossScore?: number | null;
                      Deductible?: number | null;
                      Default?: boolean | null;
                      FloodScore?: number | null;
                      Limit?: number | null;
                      RateFactor?: number | null;
                      Zone?: string | null;
                      ResidentialModifiers?: {
                        __typename?: 'ResidentialConstructionFeaturesType';
                        ArchitecturalStyleType?: string | null;
                        AuxiliaryHeatingType?: string | null;
                        BathroomCount?: string | null;
                        CommercialIndicator?: string | null;
                        ExteriorWallType?: string | null;
                        FinishedBasement?: boolean | null;
                        FoundationType?: string | null;
                        GarageNumberOfCars?: number | null;
                        GarageType?: string | null;
                        KitchenCount?: string | null;
                        OutbuildingPresent?: string | null;
                        PlumbingType?: string | null;
                        PrimaryHeatingType?: string | null;
                        RoofType?: string | null;
                        SquareFootage?: number | null;
                        StoreyCount?: string | null;
                        SwimmingPoolType?: string | null;
                        YearBuilt?: string | null;
                        InspectionDetails?: {
                          __typename?: 'InspectionDetailsType';
                          InspectionDate?: any | null;
                          SponsoredInspection?: boolean | null;
                        } | null;
                      } | null;
                      MultiResidentialModifiers?: {
                        __typename?: 'MultiResidentialConstructionFeaturesType';
                        BasementPresent?: boolean | null;
                        ConstructionType?: string | null;
                        FloorLevel?: number | null;
                      } | null;
                      AverageAnnualLossValues?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                      AdditionalDetails: Array<{
                        __typename?: 'FloodDetailType';
                        Key?: string | null;
                        Value?: string | null;
                      }>;
                      Premium?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                    }>;
                    FloodMap: Array<{
                      __typename?: 'FloodMapType';
                      FloodEvent?: string | null;
                      Content?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                } | null;
                optaIclarifyCommercialConstructionData?: {
                  __typename?: 'OptaIclarifyCommercialConstructionDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Messages?: {
                    __typename?: 'Messages';
                    Message?: Array<string | null> | null;
                  } | null;
                  Property?: {
                    __typename?: 'CommercialPropertyType';
                    LotSize?: number | null;
                    LotSizeUnitOfMeasurement?: string | null;
                    PropertyType?: string | null;
                    Buildings?: {
                      __typename?: 'BuildingsType';
                      Building: Array<{
                        __typename?: 'BuildingType';
                        StructureID?: string | null;
                        ConstructionFeatures?: {
                          __typename?: 'CommercialConstructionFeaturesType';
                          CombustibilityClass?: string | null;
                          ConstructionClass?: string | null;
                          FloorHeight?: number | null;
                          FloorHeightUnitOfMeasurement?: string | null;
                          LoadingDockPresent?: boolean | null;
                          NumberOfStoreys?: number | null;
                          NumberOfUnits?: number | null;
                          TotalFloorArea?: number | null;
                          TotalFloorAreaUnitOfMeasurement?: string | null;
                          YearBuilt?: number | null;
                          Basement?: {
                            __typename?: 'CommercialBasementType';
                            BasementArea?: number | null;
                            BasementAreaUnitOfMeasurement?: string | null;
                            BasementPresent?: boolean | null;
                          } | null;
                          BuildingUpdates?: {
                            __typename?: 'BuildingUpdatesType';
                            BuildingUpdate?: Array<number | null> | null;
                          } | null;
                          CommercialExteriorWallTypes?: {
                            __typename?: 'CommercialExteriorWallTypesType';
                            ExteriorWallType?: Array<string | null> | null;
                          } | null;
                          Elevator?: {
                            __typename?: 'ElevatorType';
                            ElevatorCount?: number | null;
                            ElevatorPresent?: boolean | null;
                          } | null;
                          Heating?: {
                            __typename?: 'CommercialHeatingType';
                            HeatingFuelSources?: {
                              __typename?: 'HeatingFuelSourcesType';
                              HeatingFuelSource?: Array<string | null> | null;
                            } | null;
                            HeatingTypes?: {
                              __typename?: 'HeatingTypesType';
                              HeatingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          Parking?: {
                            __typename?: 'CommercialParkingType';
                            ParkingPresent?: boolean | null;
                            ParkingTypes?: {
                              __typename?: 'CommercialParkingTypesType';
                              ParkingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          PlumbingTypes?: {
                            __typename?: 'CommercialPlumbingType';
                            PlumbingType?: Array<string | null> | null;
                          } | null;
                          RoofAttributes?: {
                            __typename?: 'RoofAttributesType';
                            RoofConstructions?: {
                              __typename?: 'RoofConstructionsType';
                              RoofConstruction?: Array<string | null> | null;
                            } | null;
                            RoofSurfaces?: {
                              __typename?: 'RoofSurfacesType';
                              RoofSurface?: Array<string | null> | null;
                            } | null;
                            RoofTypes?: {
                              __typename?: 'RoofTypesType';
                              RoofType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          WaterHeater?: {
                            __typename?: 'CommercialWaterHeaterType';
                            WaterHeaterFuelType?: Array<string | null> | null;
                            WaterHeaterType?: Array<string | null> | null;
                          } | null;
                          Wiring?: {
                            __typename?: 'CommercialWiringType';
                            WiringPanelTypes?: {
                              __typename?: 'CommercialWiringPanelTypesType';
                              WiringPanelType?: Array<string | null> | null;
                            } | null;
                            WiringTypes?: {
                              __typename?: 'CommercialWiringTypesType';
                              WiringType?: Array<string | null> | null;
                            } | null;
                          } | null;
                        } | null;
                        Protection?: {
                          __typename?: 'ProtectionType';
                          FireAlarmPresent?: boolean | null;
                          FireAlarmType?: string | null;
                          PhysicalSecurityPresent?: boolean | null;
                          SecurityAlarmPresent?: boolean | null;
                          SecurityAlarmType?: string | null;
                          SprinklerPresent?: boolean | null;
                          SprinklerTypeCode?: string | null;
                          PhysicalSecurityTypes?: {
                            __typename?: 'PhysicalSecurityTypesType';
                            PhysicalSecurityType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                    Industry?: {
                      __typename?: 'BusinessIndustryType';
                      IBCCode?: string | null;
                      IBCIndustryDescription?: string | null;
                      SICIndustryDescription?: string | null;
                      SICCode?: string | null;
                    } | null;
                  } | null;
                } | null;
                optaCommercialPerilScoreData?: {
                  __typename?: 'OptaCommercialPerilScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  PropertyPerilScores?: {
                    __typename?: 'PropertyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  CasualtyPerilScores?: {
                    __typename?: 'CasualtyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  AllPerilsScore?: {
                    __typename?: 'CommercialPerilScoreType';
                    LossCostScore?: number | null;
                    PredictedLossCost?: number | null;
                  } | null;
                } | null;
              } | null;
              openingHours: Array<{
                __typename?: 'OpeningHoursType';
                day: number;
                open?: any | null;
                close?: any | null;
              }>;
            } | null;
          }>;
          details?: {
            __typename?: 'AnswerInstanceDetailsType';
            lat?: number | null;
            lng?: number | null;
            images: Array<{
              __typename?: 'GoogleImageType';
              description: string;
              shortDescription: string;
              category: CoreGoogleImageCategoryChoices;
              data?: string | null;
            }>;
            google?: {
              __typename?: 'GoogleDetailsType';
              placeId?: string | null;
              rating?: number | null;
              numberOfRatings?: number | null;
            } | null;
            opta?: {
              __typename?: 'OPTADetailsType';
              optaFusData?: {
                __typename?: 'OptaFusDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                Commercial?: {
                  __typename?: 'FusProductCommercialType';
                  SearchMessage?: string | null;
                  CommercialGrade: Array<{
                    __typename?: 'CommercialGradeType';
                    PublicFireProtectionClassification?: number | null;
                    WaterSystem?: string | null;
                    RiskSCORDetail: Array<{
                      __typename?: 'RiskSCORDetailType';
                      RiskSCOR?: number | null;
                      Type?: string | null;
                      Adjustment: Array<{
                        __typename?: 'RiskSCORAdjustmentType';
                        Adjustment?: string | null;
                        AdjustmentType?: string | null;
                      }>;
                    }>;
                  }>;
                  RespondingFirehall?: {
                    __typename?: 'FirehallType';
                    Address?: string | null;
                    GoverningAgency?: string | null;
                    GradingDate?: any | null;
                    Name?: string | null;
                    PumperFlowImperialGallonsPerMinute?: number | null;
                    RoadDistanceKilometres?: number | null;
                    SuperiorTankerServiceAccreditationDate?: any | null;
                    FirefightersPerFireStation?: {
                      __typename?: 'FirefightersPerFireStationType';
                      FullTime?: number | null;
                      Volunteer?: number | null;
                    } | null;
                    ProtectionGradeTerritories?: {
                      __typename?: 'ProtectedGradeTerritoriesType';
                      Commercial?: string | null;
                      Dwelling?: string | null;
                    } | null;
                  } | null;
                } | null;
                Dwelling?: {
                  __typename?: 'FusProductDwellingType';
                  SearchMessage?: string | null;
                  DwellingGrades?: {
                    __typename?: 'DwellingGradesType';
                    AllOtherAreasGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                    FirehallProtectedGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                    HydrantProtectedGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                  } | null;
                  RespondingFirehall?: {
                    __typename?: 'FirehallType';
                    Address?: string | null;
                    GoverningAgency?: string | null;
                    GradingDate?: any | null;
                    Name?: string | null;
                    PumperFlowImperialGallonsPerMinute?: number | null;
                    RoadDistanceKilometres?: number | null;
                    SuperiorTankerServiceAccreditationDate?: any | null;
                    FirefightersPerFireStation?: {
                      __typename?: 'FirefightersPerFireStationType';
                      FullTime?: number | null;
                      Volunteer?: number | null;
                    } | null;
                    ProtectionGradeTerritories?: {
                      __typename?: 'ProtectedGradeTerritoriesType';
                      Commercial?: string | null;
                      Dwelling?: string | null;
                    } | null;
                  } | null;
                } | null;
                EarthquakeCodes?: {
                  __typename?: 'EarthquakeCodesType';
                  SearchMessage?: string | null;
                  EarthquakeCode: Array<{
                    __typename?: 'EarthquakeCodeType';
                    Description?: string | null;
                    SystemName?: string | null;
                    Zone?: string | null;
                  }>;
                } | null;
                FusMaps?: {
                  __typename?: 'FusMapsType';
                  SearchMessage?: string | null;
                  FusMap: Array<{
                    __typename?: 'FusMapType';
                    Business?: string | null;
                    FusContent?: {
                      __typename?: 'ContentType';
                      ContentURL?: string | null;
                      Expiry?: any | null;
                      MIMEType?: string | null;
                    } | null;
                  }>;
                } | null;
                HydrantCounts?: {
                  __typename?: 'HydrantCountsType';
                  SearchMessage?: string | null;
                  HydrantCount: Array<{
                    __typename?: 'HydrantCountType';
                    NumberOfHydrants: number;
                    Ownership?: string | null;
                    RadiusMetres?: string | null;
                  }>;
                } | null;
              } | null;
              optaFloodscoreData?: {
                __typename?: 'OptaFloodScoreDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                FloodScores?: {
                  __typename?: 'FloodScoresType';
                  FloodScore: Array<{
                    __typename?: 'FloodScoreType';
                    AverageAnnualLossScore?: number | null;
                    Deductible?: number | null;
                    Default?: boolean | null;
                    FloodScore?: number | null;
                    Limit?: number | null;
                    RateFactor?: number | null;
                    Zone?: string | null;
                    ResidentialModifiers?: {
                      __typename?: 'ResidentialConstructionFeaturesType';
                      ArchitecturalStyleType?: string | null;
                      AuxiliaryHeatingType?: string | null;
                      BathroomCount?: string | null;
                      CommercialIndicator?: string | null;
                      ExteriorWallType?: string | null;
                      FinishedBasement?: boolean | null;
                      FoundationType?: string | null;
                      GarageNumberOfCars?: number | null;
                      GarageType?: string | null;
                      KitchenCount?: string | null;
                      OutbuildingPresent?: string | null;
                      PlumbingType?: string | null;
                      PrimaryHeatingType?: string | null;
                      RoofType?: string | null;
                      SquareFootage?: number | null;
                      StoreyCount?: string | null;
                      SwimmingPoolType?: string | null;
                      YearBuilt?: string | null;
                      InspectionDetails?: {
                        __typename?: 'InspectionDetailsType';
                        InspectionDate?: any | null;
                        SponsoredInspection?: boolean | null;
                      } | null;
                    } | null;
                    MultiResidentialModifiers?: {
                      __typename?: 'MultiResidentialConstructionFeaturesType';
                      BasementPresent?: boolean | null;
                      ConstructionType?: string | null;
                      FloorLevel?: number | null;
                    } | null;
                    AverageAnnualLossValues?: {
                      __typename?: 'FloodValueType';
                      Building?: number | null;
                      Contents?: number | null;
                      Total?: number | null;
                    } | null;
                    AdditionalDetails: Array<{
                      __typename?: 'FloodDetailType';
                      Key?: string | null;
                      Value?: string | null;
                    }>;
                    Premium?: {
                      __typename?: 'FloodValueType';
                      Building?: number | null;
                      Contents?: number | null;
                      Total?: number | null;
                    } | null;
                  }>;
                  FloodMap: Array<{
                    __typename?: 'FloodMapType';
                    FloodEvent?: string | null;
                    Content?: {
                      __typename?: 'ContentType';
                      ContentURL?: string | null;
                      Expiry?: any | null;
                      MIMEType?: string | null;
                    } | null;
                  }>;
                } | null;
              } | null;
              optaIclarifyCommercialConstructionData?: {
                __typename?: 'OptaIclarifyCommercialConstructionDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                Messages?: {
                  __typename?: 'Messages';
                  Message?: Array<string | null> | null;
                } | null;
                Property?: {
                  __typename?: 'CommercialPropertyType';
                  LotSize?: number | null;
                  LotSizeUnitOfMeasurement?: string | null;
                  PropertyType?: string | null;
                  Buildings?: {
                    __typename?: 'BuildingsType';
                    Building: Array<{
                      __typename?: 'BuildingType';
                      StructureID?: string | null;
                      ConstructionFeatures?: {
                        __typename?: 'CommercialConstructionFeaturesType';
                        CombustibilityClass?: string | null;
                        ConstructionClass?: string | null;
                        FloorHeight?: number | null;
                        FloorHeightUnitOfMeasurement?: string | null;
                        LoadingDockPresent?: boolean | null;
                        NumberOfStoreys?: number | null;
                        NumberOfUnits?: number | null;
                        TotalFloorArea?: number | null;
                        TotalFloorAreaUnitOfMeasurement?: string | null;
                        YearBuilt?: number | null;
                        Basement?: {
                          __typename?: 'CommercialBasementType';
                          BasementArea?: number | null;
                          BasementAreaUnitOfMeasurement?: string | null;
                          BasementPresent?: boolean | null;
                        } | null;
                        BuildingUpdates?: {
                          __typename?: 'BuildingUpdatesType';
                          BuildingUpdate?: Array<number | null> | null;
                        } | null;
                        CommercialExteriorWallTypes?: {
                          __typename?: 'CommercialExteriorWallTypesType';
                          ExteriorWallType?: Array<string | null> | null;
                        } | null;
                        Elevator?: {
                          __typename?: 'ElevatorType';
                          ElevatorCount?: number | null;
                          ElevatorPresent?: boolean | null;
                        } | null;
                        Heating?: {
                          __typename?: 'CommercialHeatingType';
                          HeatingFuelSources?: {
                            __typename?: 'HeatingFuelSourcesType';
                            HeatingFuelSource?: Array<string | null> | null;
                          } | null;
                          HeatingTypes?: {
                            __typename?: 'HeatingTypesType';
                            HeatingType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        Parking?: {
                          __typename?: 'CommercialParkingType';
                          ParkingPresent?: boolean | null;
                          ParkingTypes?: {
                            __typename?: 'CommercialParkingTypesType';
                            ParkingType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        PlumbingTypes?: {
                          __typename?: 'CommercialPlumbingType';
                          PlumbingType?: Array<string | null> | null;
                        } | null;
                        RoofAttributes?: {
                          __typename?: 'RoofAttributesType';
                          RoofConstructions?: {
                            __typename?: 'RoofConstructionsType';
                            RoofConstruction?: Array<string | null> | null;
                          } | null;
                          RoofSurfaces?: {
                            __typename?: 'RoofSurfacesType';
                            RoofSurface?: Array<string | null> | null;
                          } | null;
                          RoofTypes?: {
                            __typename?: 'RoofTypesType';
                            RoofType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        WaterHeater?: {
                          __typename?: 'CommercialWaterHeaterType';
                          WaterHeaterFuelType?: Array<string | null> | null;
                          WaterHeaterType?: Array<string | null> | null;
                        } | null;
                        Wiring?: {
                          __typename?: 'CommercialWiringType';
                          WiringPanelTypes?: {
                            __typename?: 'CommercialWiringPanelTypesType';
                            WiringPanelType?: Array<string | null> | null;
                          } | null;
                          WiringTypes?: {
                            __typename?: 'CommercialWiringTypesType';
                            WiringType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      } | null;
                      Protection?: {
                        __typename?: 'ProtectionType';
                        FireAlarmPresent?: boolean | null;
                        FireAlarmType?: string | null;
                        PhysicalSecurityPresent?: boolean | null;
                        SecurityAlarmPresent?: boolean | null;
                        SecurityAlarmType?: string | null;
                        SprinklerPresent?: boolean | null;
                        SprinklerTypeCode?: string | null;
                        PhysicalSecurityTypes?: {
                          __typename?: 'PhysicalSecurityTypesType';
                          PhysicalSecurityType?: Array<string | null> | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                  Industry?: {
                    __typename?: 'BusinessIndustryType';
                    IBCCode?: string | null;
                    IBCIndustryDescription?: string | null;
                    SICIndustryDescription?: string | null;
                    SICCode?: string | null;
                  } | null;
                } | null;
              } | null;
              optaCommercialPerilScoreData?: {
                __typename?: 'OptaCommercialPerilScoreDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                PropertyPerilScores?: {
                  __typename?: 'PropertyCommercialPerilsType';
                  Peril?: Array<{
                    __typename?: 'PerilDetailsType';
                    LossCostScore?: number | null;
                    PerilType?: string | null;
                    PredictedLossCost?: number | null;
                  } | null> | null;
                } | null;
                CasualtyPerilScores?: {
                  __typename?: 'CasualtyCommercialPerilsType';
                  Peril?: Array<{
                    __typename?: 'PerilDetailsType';
                    LossCostScore?: number | null;
                    PerilType?: string | null;
                    PredictedLossCost?: number | null;
                  } | null> | null;
                } | null;
                AllPerilsScore?: {
                  __typename?: 'CommercialPerilScoreType';
                  LossCostScore?: number | null;
                  PredictedLossCost?: number | null;
                } | null;
              } | null;
            } | null;
            openingHours: Array<{
              __typename?: 'OpeningHoursType';
              day: number;
              open?: any | null;
              close?: any | null;
            }>;
          } | null;
        }>;
        details?: {
          __typename?: 'AnswerInstanceDetailsType';
          lat?: number | null;
          lng?: number | null;
          images: Array<{
            __typename?: 'GoogleImageType';
            description: string;
            shortDescription: string;
            category: CoreGoogleImageCategoryChoices;
            data?: string | null;
          }>;
          google?: {
            __typename?: 'GoogleDetailsType';
            placeId?: string | null;
            rating?: number | null;
            numberOfRatings?: number | null;
          } | null;
          opta?: {
            __typename?: 'OPTADetailsType';
            optaFusData?: {
              __typename?: 'OptaFusDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              Commercial?: {
                __typename?: 'FusProductCommercialType';
                SearchMessage?: string | null;
                CommercialGrade: Array<{
                  __typename?: 'CommercialGradeType';
                  PublicFireProtectionClassification?: number | null;
                  WaterSystem?: string | null;
                  RiskSCORDetail: Array<{
                    __typename?: 'RiskSCORDetailType';
                    RiskSCOR?: number | null;
                    Type?: string | null;
                    Adjustment: Array<{
                      __typename?: 'RiskSCORAdjustmentType';
                      Adjustment?: string | null;
                      AdjustmentType?: string | null;
                    }>;
                  }>;
                }>;
                RespondingFirehall?: {
                  __typename?: 'FirehallType';
                  Address?: string | null;
                  GoverningAgency?: string | null;
                  GradingDate?: any | null;
                  Name?: string | null;
                  PumperFlowImperialGallonsPerMinute?: number | null;
                  RoadDistanceKilometres?: number | null;
                  SuperiorTankerServiceAccreditationDate?: any | null;
                  FirefightersPerFireStation?: {
                    __typename?: 'FirefightersPerFireStationType';
                    FullTime?: number | null;
                    Volunteer?: number | null;
                  } | null;
                  ProtectionGradeTerritories?: {
                    __typename?: 'ProtectedGradeTerritoriesType';
                    Commercial?: string | null;
                    Dwelling?: string | null;
                  } | null;
                } | null;
              } | null;
              Dwelling?: {
                __typename?: 'FusProductDwellingType';
                SearchMessage?: string | null;
                DwellingGrades?: {
                  __typename?: 'DwellingGradesType';
                  AllOtherAreasGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                  FirehallProtectedGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                  HydrantProtectedGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                } | null;
                RespondingFirehall?: {
                  __typename?: 'FirehallType';
                  Address?: string | null;
                  GoverningAgency?: string | null;
                  GradingDate?: any | null;
                  Name?: string | null;
                  PumperFlowImperialGallonsPerMinute?: number | null;
                  RoadDistanceKilometres?: number | null;
                  SuperiorTankerServiceAccreditationDate?: any | null;
                  FirefightersPerFireStation?: {
                    __typename?: 'FirefightersPerFireStationType';
                    FullTime?: number | null;
                    Volunteer?: number | null;
                  } | null;
                  ProtectionGradeTerritories?: {
                    __typename?: 'ProtectedGradeTerritoriesType';
                    Commercial?: string | null;
                    Dwelling?: string | null;
                  } | null;
                } | null;
              } | null;
              EarthquakeCodes?: {
                __typename?: 'EarthquakeCodesType';
                SearchMessage?: string | null;
                EarthquakeCode: Array<{
                  __typename?: 'EarthquakeCodeType';
                  Description?: string | null;
                  SystemName?: string | null;
                  Zone?: string | null;
                }>;
              } | null;
              FusMaps?: {
                __typename?: 'FusMapsType';
                SearchMessage?: string | null;
                FusMap: Array<{
                  __typename?: 'FusMapType';
                  Business?: string | null;
                  FusContent?: {
                    __typename?: 'ContentType';
                    ContentURL?: string | null;
                    Expiry?: any | null;
                    MIMEType?: string | null;
                  } | null;
                }>;
              } | null;
              HydrantCounts?: {
                __typename?: 'HydrantCountsType';
                SearchMessage?: string | null;
                HydrantCount: Array<{
                  __typename?: 'HydrantCountType';
                  NumberOfHydrants: number;
                  Ownership?: string | null;
                  RadiusMetres?: string | null;
                }>;
              } | null;
            } | null;
            optaFloodscoreData?: {
              __typename?: 'OptaFloodScoreDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              FloodScores?: {
                __typename?: 'FloodScoresType';
                FloodScore: Array<{
                  __typename?: 'FloodScoreType';
                  AverageAnnualLossScore?: number | null;
                  Deductible?: number | null;
                  Default?: boolean | null;
                  FloodScore?: number | null;
                  Limit?: number | null;
                  RateFactor?: number | null;
                  Zone?: string | null;
                  ResidentialModifiers?: {
                    __typename?: 'ResidentialConstructionFeaturesType';
                    ArchitecturalStyleType?: string | null;
                    AuxiliaryHeatingType?: string | null;
                    BathroomCount?: string | null;
                    CommercialIndicator?: string | null;
                    ExteriorWallType?: string | null;
                    FinishedBasement?: boolean | null;
                    FoundationType?: string | null;
                    GarageNumberOfCars?: number | null;
                    GarageType?: string | null;
                    KitchenCount?: string | null;
                    OutbuildingPresent?: string | null;
                    PlumbingType?: string | null;
                    PrimaryHeatingType?: string | null;
                    RoofType?: string | null;
                    SquareFootage?: number | null;
                    StoreyCount?: string | null;
                    SwimmingPoolType?: string | null;
                    YearBuilt?: string | null;
                    InspectionDetails?: {
                      __typename?: 'InspectionDetailsType';
                      InspectionDate?: any | null;
                      SponsoredInspection?: boolean | null;
                    } | null;
                  } | null;
                  MultiResidentialModifiers?: {
                    __typename?: 'MultiResidentialConstructionFeaturesType';
                    BasementPresent?: boolean | null;
                    ConstructionType?: string | null;
                    FloorLevel?: number | null;
                  } | null;
                  AverageAnnualLossValues?: {
                    __typename?: 'FloodValueType';
                    Building?: number | null;
                    Contents?: number | null;
                    Total?: number | null;
                  } | null;
                  AdditionalDetails: Array<{
                    __typename?: 'FloodDetailType';
                    Key?: string | null;
                    Value?: string | null;
                  }>;
                  Premium?: {
                    __typename?: 'FloodValueType';
                    Building?: number | null;
                    Contents?: number | null;
                    Total?: number | null;
                  } | null;
                }>;
                FloodMap: Array<{
                  __typename?: 'FloodMapType';
                  FloodEvent?: string | null;
                  Content?: {
                    __typename?: 'ContentType';
                    ContentURL?: string | null;
                    Expiry?: any | null;
                    MIMEType?: string | null;
                  } | null;
                }>;
              } | null;
            } | null;
            optaIclarifyCommercialConstructionData?: {
              __typename?: 'OptaIclarifyCommercialConstructionDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              Messages?: {
                __typename?: 'Messages';
                Message?: Array<string | null> | null;
              } | null;
              Property?: {
                __typename?: 'CommercialPropertyType';
                LotSize?: number | null;
                LotSizeUnitOfMeasurement?: string | null;
                PropertyType?: string | null;
                Buildings?: {
                  __typename?: 'BuildingsType';
                  Building: Array<{
                    __typename?: 'BuildingType';
                    StructureID?: string | null;
                    ConstructionFeatures?: {
                      __typename?: 'CommercialConstructionFeaturesType';
                      CombustibilityClass?: string | null;
                      ConstructionClass?: string | null;
                      FloorHeight?: number | null;
                      FloorHeightUnitOfMeasurement?: string | null;
                      LoadingDockPresent?: boolean | null;
                      NumberOfStoreys?: number | null;
                      NumberOfUnits?: number | null;
                      TotalFloorArea?: number | null;
                      TotalFloorAreaUnitOfMeasurement?: string | null;
                      YearBuilt?: number | null;
                      Basement?: {
                        __typename?: 'CommercialBasementType';
                        BasementArea?: number | null;
                        BasementAreaUnitOfMeasurement?: string | null;
                        BasementPresent?: boolean | null;
                      } | null;
                      BuildingUpdates?: {
                        __typename?: 'BuildingUpdatesType';
                        BuildingUpdate?: Array<number | null> | null;
                      } | null;
                      CommercialExteriorWallTypes?: {
                        __typename?: 'CommercialExteriorWallTypesType';
                        ExteriorWallType?: Array<string | null> | null;
                      } | null;
                      Elevator?: {
                        __typename?: 'ElevatorType';
                        ElevatorCount?: number | null;
                        ElevatorPresent?: boolean | null;
                      } | null;
                      Heating?: {
                        __typename?: 'CommercialHeatingType';
                        HeatingFuelSources?: {
                          __typename?: 'HeatingFuelSourcesType';
                          HeatingFuelSource?: Array<string | null> | null;
                        } | null;
                        HeatingTypes?: {
                          __typename?: 'HeatingTypesType';
                          HeatingType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      Parking?: {
                        __typename?: 'CommercialParkingType';
                        ParkingPresent?: boolean | null;
                        ParkingTypes?: {
                          __typename?: 'CommercialParkingTypesType';
                          ParkingType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      PlumbingTypes?: {
                        __typename?: 'CommercialPlumbingType';
                        PlumbingType?: Array<string | null> | null;
                      } | null;
                      RoofAttributes?: {
                        __typename?: 'RoofAttributesType';
                        RoofConstructions?: {
                          __typename?: 'RoofConstructionsType';
                          RoofConstruction?: Array<string | null> | null;
                        } | null;
                        RoofSurfaces?: {
                          __typename?: 'RoofSurfacesType';
                          RoofSurface?: Array<string | null> | null;
                        } | null;
                        RoofTypes?: {
                          __typename?: 'RoofTypesType';
                          RoofType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      WaterHeater?: {
                        __typename?: 'CommercialWaterHeaterType';
                        WaterHeaterFuelType?: Array<string | null> | null;
                        WaterHeaterType?: Array<string | null> | null;
                      } | null;
                      Wiring?: {
                        __typename?: 'CommercialWiringType';
                        WiringPanelTypes?: {
                          __typename?: 'CommercialWiringPanelTypesType';
                          WiringPanelType?: Array<string | null> | null;
                        } | null;
                        WiringTypes?: {
                          __typename?: 'CommercialWiringTypesType';
                          WiringType?: Array<string | null> | null;
                        } | null;
                      } | null;
                    } | null;
                    Protection?: {
                      __typename?: 'ProtectionType';
                      FireAlarmPresent?: boolean | null;
                      FireAlarmType?: string | null;
                      PhysicalSecurityPresent?: boolean | null;
                      SecurityAlarmPresent?: boolean | null;
                      SecurityAlarmType?: string | null;
                      SprinklerPresent?: boolean | null;
                      SprinklerTypeCode?: string | null;
                      PhysicalSecurityTypes?: {
                        __typename?: 'PhysicalSecurityTypesType';
                        PhysicalSecurityType?: Array<string | null> | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
                Industry?: {
                  __typename?: 'BusinessIndustryType';
                  IBCCode?: string | null;
                  IBCIndustryDescription?: string | null;
                  SICIndustryDescription?: string | null;
                  SICCode?: string | null;
                } | null;
              } | null;
            } | null;
            optaCommercialPerilScoreData?: {
              __typename?: 'OptaCommercialPerilScoreDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              PropertyPerilScores?: {
                __typename?: 'PropertyCommercialPerilsType';
                Peril?: Array<{
                  __typename?: 'PerilDetailsType';
                  LossCostScore?: number | null;
                  PerilType?: string | null;
                  PredictedLossCost?: number | null;
                } | null> | null;
              } | null;
              CasualtyPerilScores?: {
                __typename?: 'CasualtyCommercialPerilsType';
                Peril?: Array<{
                  __typename?: 'PerilDetailsType';
                  LossCostScore?: number | null;
                  PerilType?: string | null;
                  PredictedLossCost?: number | null;
                } | null> | null;
              } | null;
              AllPerilsScore?: {
                __typename?: 'CommercialPerilScoreType';
                LossCostScore?: number | null;
                PredictedLossCost?: number | null;
              } | null;
            } | null;
          } | null;
          openingHours: Array<{
            __typename?: 'OpeningHoursType';
            day: number;
            open?: any | null;
            close?: any | null;
          }>;
        } | null;
      }>;
      details?: {
        __typename?: 'AnswerInstanceDetailsType';
        lat?: number | null;
        lng?: number | null;
        images: Array<{
          __typename?: 'GoogleImageType';
          description: string;
          shortDescription: string;
          category: CoreGoogleImageCategoryChoices;
          data?: string | null;
        }>;
        google?: {
          __typename?: 'GoogleDetailsType';
          placeId?: string | null;
          rating?: number | null;
          numberOfRatings?: number | null;
        } | null;
        opta?: {
          __typename?: 'OPTADetailsType';
          optaFusData?: {
            __typename?: 'OptaFusDataType';
            Address?: {
              __typename?: 'OptaAddressType';
              StreetAddress?: string | null;
              StreetNumber?: string | null;
              StreetName?: string | null;
              StreetType?: string | null;
              StreetDirection?: string | null;
              UnitNumber?: string | null;
              Municipality?: string | null;
              Province?: string | null;
              PostalCode?: string | null;
              LegalDescription?: {
                __typename?: 'LegalDescriptionType';
                Quarter?: string | null;
                LegalSubdivision?: string | null;
                Section?: string | null;
                Township?: string | null;
                Range?: string | null;
                Meridian?: string | null;
              } | null;
            } | null;
            Commercial?: {
              __typename?: 'FusProductCommercialType';
              SearchMessage?: string | null;
              CommercialGrade: Array<{
                __typename?: 'CommercialGradeType';
                PublicFireProtectionClassification?: number | null;
                WaterSystem?: string | null;
                RiskSCORDetail: Array<{
                  __typename?: 'RiskSCORDetailType';
                  RiskSCOR?: number | null;
                  Type?: string | null;
                  Adjustment: Array<{
                    __typename?: 'RiskSCORAdjustmentType';
                    Adjustment?: string | null;
                    AdjustmentType?: string | null;
                  }>;
                }>;
              }>;
              RespondingFirehall?: {
                __typename?: 'FirehallType';
                Address?: string | null;
                GoverningAgency?: string | null;
                GradingDate?: any | null;
                Name?: string | null;
                PumperFlowImperialGallonsPerMinute?: number | null;
                RoadDistanceKilometres?: number | null;
                SuperiorTankerServiceAccreditationDate?: any | null;
                FirefightersPerFireStation?: {
                  __typename?: 'FirefightersPerFireStationType';
                  FullTime?: number | null;
                  Volunteer?: number | null;
                } | null;
                ProtectionGradeTerritories?: {
                  __typename?: 'ProtectedGradeTerritoriesType';
                  Commercial?: string | null;
                  Dwelling?: string | null;
                } | null;
              } | null;
            } | null;
            Dwelling?: {
              __typename?: 'FusProductDwellingType';
              SearchMessage?: string | null;
              DwellingGrades?: {
                __typename?: 'DwellingGradesType';
                AllOtherAreasGrade: Array<{
                  __typename?: 'DwellingProtectedGradeType';
                  Grade?: string | null;
                  WaterSystem?: string | null;
                }>;
                FirehallProtectedGrade: Array<{
                  __typename?: 'DwellingProtectedGradeType';
                  Grade?: string | null;
                  WaterSystem?: string | null;
                }>;
                HydrantProtectedGrade: Array<{
                  __typename?: 'DwellingProtectedGradeType';
                  Grade?: string | null;
                  WaterSystem?: string | null;
                }>;
              } | null;
              RespondingFirehall?: {
                __typename?: 'FirehallType';
                Address?: string | null;
                GoverningAgency?: string | null;
                GradingDate?: any | null;
                Name?: string | null;
                PumperFlowImperialGallonsPerMinute?: number | null;
                RoadDistanceKilometres?: number | null;
                SuperiorTankerServiceAccreditationDate?: any | null;
                FirefightersPerFireStation?: {
                  __typename?: 'FirefightersPerFireStationType';
                  FullTime?: number | null;
                  Volunteer?: number | null;
                } | null;
                ProtectionGradeTerritories?: {
                  __typename?: 'ProtectedGradeTerritoriesType';
                  Commercial?: string | null;
                  Dwelling?: string | null;
                } | null;
              } | null;
            } | null;
            EarthquakeCodes?: {
              __typename?: 'EarthquakeCodesType';
              SearchMessage?: string | null;
              EarthquakeCode: Array<{
                __typename?: 'EarthquakeCodeType';
                Description?: string | null;
                SystemName?: string | null;
                Zone?: string | null;
              }>;
            } | null;
            FusMaps?: {
              __typename?: 'FusMapsType';
              SearchMessage?: string | null;
              FusMap: Array<{
                __typename?: 'FusMapType';
                Business?: string | null;
                FusContent?: {
                  __typename?: 'ContentType';
                  ContentURL?: string | null;
                  Expiry?: any | null;
                  MIMEType?: string | null;
                } | null;
              }>;
            } | null;
            HydrantCounts?: {
              __typename?: 'HydrantCountsType';
              SearchMessage?: string | null;
              HydrantCount: Array<{
                __typename?: 'HydrantCountType';
                NumberOfHydrants: number;
                Ownership?: string | null;
                RadiusMetres?: string | null;
              }>;
            } | null;
          } | null;
          optaFloodscoreData?: {
            __typename?: 'OptaFloodScoreDataType';
            Address?: {
              __typename?: 'OptaAddressType';
              StreetAddress?: string | null;
              StreetNumber?: string | null;
              StreetName?: string | null;
              StreetType?: string | null;
              StreetDirection?: string | null;
              UnitNumber?: string | null;
              Municipality?: string | null;
              Province?: string | null;
              PostalCode?: string | null;
              LegalDescription?: {
                __typename?: 'LegalDescriptionType';
                Quarter?: string | null;
                LegalSubdivision?: string | null;
                Section?: string | null;
                Township?: string | null;
                Range?: string | null;
                Meridian?: string | null;
              } | null;
            } | null;
            FloodScores?: {
              __typename?: 'FloodScoresType';
              FloodScore: Array<{
                __typename?: 'FloodScoreType';
                AverageAnnualLossScore?: number | null;
                Deductible?: number | null;
                Default?: boolean | null;
                FloodScore?: number | null;
                Limit?: number | null;
                RateFactor?: number | null;
                Zone?: string | null;
                ResidentialModifiers?: {
                  __typename?: 'ResidentialConstructionFeaturesType';
                  ArchitecturalStyleType?: string | null;
                  AuxiliaryHeatingType?: string | null;
                  BathroomCount?: string | null;
                  CommercialIndicator?: string | null;
                  ExteriorWallType?: string | null;
                  FinishedBasement?: boolean | null;
                  FoundationType?: string | null;
                  GarageNumberOfCars?: number | null;
                  GarageType?: string | null;
                  KitchenCount?: string | null;
                  OutbuildingPresent?: string | null;
                  PlumbingType?: string | null;
                  PrimaryHeatingType?: string | null;
                  RoofType?: string | null;
                  SquareFootage?: number | null;
                  StoreyCount?: string | null;
                  SwimmingPoolType?: string | null;
                  YearBuilt?: string | null;
                  InspectionDetails?: {
                    __typename?: 'InspectionDetailsType';
                    InspectionDate?: any | null;
                    SponsoredInspection?: boolean | null;
                  } | null;
                } | null;
                MultiResidentialModifiers?: {
                  __typename?: 'MultiResidentialConstructionFeaturesType';
                  BasementPresent?: boolean | null;
                  ConstructionType?: string | null;
                  FloorLevel?: number | null;
                } | null;
                AverageAnnualLossValues?: {
                  __typename?: 'FloodValueType';
                  Building?: number | null;
                  Contents?: number | null;
                  Total?: number | null;
                } | null;
                AdditionalDetails: Array<{
                  __typename?: 'FloodDetailType';
                  Key?: string | null;
                  Value?: string | null;
                }>;
                Premium?: {
                  __typename?: 'FloodValueType';
                  Building?: number | null;
                  Contents?: number | null;
                  Total?: number | null;
                } | null;
              }>;
              FloodMap: Array<{
                __typename?: 'FloodMapType';
                FloodEvent?: string | null;
                Content?: {
                  __typename?: 'ContentType';
                  ContentURL?: string | null;
                  Expiry?: any | null;
                  MIMEType?: string | null;
                } | null;
              }>;
            } | null;
          } | null;
          optaIclarifyCommercialConstructionData?: {
            __typename?: 'OptaIclarifyCommercialConstructionDataType';
            Address?: {
              __typename?: 'OptaAddressType';
              StreetAddress?: string | null;
              StreetNumber?: string | null;
              StreetName?: string | null;
              StreetType?: string | null;
              StreetDirection?: string | null;
              UnitNumber?: string | null;
              Municipality?: string | null;
              Province?: string | null;
              PostalCode?: string | null;
              LegalDescription?: {
                __typename?: 'LegalDescriptionType';
                Quarter?: string | null;
                LegalSubdivision?: string | null;
                Section?: string | null;
                Township?: string | null;
                Range?: string | null;
                Meridian?: string | null;
              } | null;
            } | null;
            Messages?: {
              __typename?: 'Messages';
              Message?: Array<string | null> | null;
            } | null;
            Property?: {
              __typename?: 'CommercialPropertyType';
              LotSize?: number | null;
              LotSizeUnitOfMeasurement?: string | null;
              PropertyType?: string | null;
              Buildings?: {
                __typename?: 'BuildingsType';
                Building: Array<{
                  __typename?: 'BuildingType';
                  StructureID?: string | null;
                  ConstructionFeatures?: {
                    __typename?: 'CommercialConstructionFeaturesType';
                    CombustibilityClass?: string | null;
                    ConstructionClass?: string | null;
                    FloorHeight?: number | null;
                    FloorHeightUnitOfMeasurement?: string | null;
                    LoadingDockPresent?: boolean | null;
                    NumberOfStoreys?: number | null;
                    NumberOfUnits?: number | null;
                    TotalFloorArea?: number | null;
                    TotalFloorAreaUnitOfMeasurement?: string | null;
                    YearBuilt?: number | null;
                    Basement?: {
                      __typename?: 'CommercialBasementType';
                      BasementArea?: number | null;
                      BasementAreaUnitOfMeasurement?: string | null;
                      BasementPresent?: boolean | null;
                    } | null;
                    BuildingUpdates?: {
                      __typename?: 'BuildingUpdatesType';
                      BuildingUpdate?: Array<number | null> | null;
                    } | null;
                    CommercialExteriorWallTypes?: {
                      __typename?: 'CommercialExteriorWallTypesType';
                      ExteriorWallType?: Array<string | null> | null;
                    } | null;
                    Elevator?: {
                      __typename?: 'ElevatorType';
                      ElevatorCount?: number | null;
                      ElevatorPresent?: boolean | null;
                    } | null;
                    Heating?: {
                      __typename?: 'CommercialHeatingType';
                      HeatingFuelSources?: {
                        __typename?: 'HeatingFuelSourcesType';
                        HeatingFuelSource?: Array<string | null> | null;
                      } | null;
                      HeatingTypes?: {
                        __typename?: 'HeatingTypesType';
                        HeatingType?: Array<string | null> | null;
                      } | null;
                    } | null;
                    Parking?: {
                      __typename?: 'CommercialParkingType';
                      ParkingPresent?: boolean | null;
                      ParkingTypes?: {
                        __typename?: 'CommercialParkingTypesType';
                        ParkingType?: Array<string | null> | null;
                      } | null;
                    } | null;
                    PlumbingTypes?: {
                      __typename?: 'CommercialPlumbingType';
                      PlumbingType?: Array<string | null> | null;
                    } | null;
                    RoofAttributes?: {
                      __typename?: 'RoofAttributesType';
                      RoofConstructions?: {
                        __typename?: 'RoofConstructionsType';
                        RoofConstruction?: Array<string | null> | null;
                      } | null;
                      RoofSurfaces?: {
                        __typename?: 'RoofSurfacesType';
                        RoofSurface?: Array<string | null> | null;
                      } | null;
                      RoofTypes?: {
                        __typename?: 'RoofTypesType';
                        RoofType?: Array<string | null> | null;
                      } | null;
                    } | null;
                    WaterHeater?: {
                      __typename?: 'CommercialWaterHeaterType';
                      WaterHeaterFuelType?: Array<string | null> | null;
                      WaterHeaterType?: Array<string | null> | null;
                    } | null;
                    Wiring?: {
                      __typename?: 'CommercialWiringType';
                      WiringPanelTypes?: {
                        __typename?: 'CommercialWiringPanelTypesType';
                        WiringPanelType?: Array<string | null> | null;
                      } | null;
                      WiringTypes?: {
                        __typename?: 'CommercialWiringTypesType';
                        WiringType?: Array<string | null> | null;
                      } | null;
                    } | null;
                  } | null;
                  Protection?: {
                    __typename?: 'ProtectionType';
                    FireAlarmPresent?: boolean | null;
                    FireAlarmType?: string | null;
                    PhysicalSecurityPresent?: boolean | null;
                    SecurityAlarmPresent?: boolean | null;
                    SecurityAlarmType?: string | null;
                    SprinklerPresent?: boolean | null;
                    SprinklerTypeCode?: string | null;
                    PhysicalSecurityTypes?: {
                      __typename?: 'PhysicalSecurityTypesType';
                      PhysicalSecurityType?: Array<string | null> | null;
                    } | null;
                  } | null;
                }>;
              } | null;
              Industry?: {
                __typename?: 'BusinessIndustryType';
                IBCCode?: string | null;
                IBCIndustryDescription?: string | null;
                SICIndustryDescription?: string | null;
                SICCode?: string | null;
              } | null;
            } | null;
          } | null;
          optaCommercialPerilScoreData?: {
            __typename?: 'OptaCommercialPerilScoreDataType';
            Address?: {
              __typename?: 'OptaAddressType';
              StreetAddress?: string | null;
              StreetNumber?: string | null;
              StreetName?: string | null;
              StreetType?: string | null;
              StreetDirection?: string | null;
              UnitNumber?: string | null;
              Municipality?: string | null;
              Province?: string | null;
              PostalCode?: string | null;
              LegalDescription?: {
                __typename?: 'LegalDescriptionType';
                Quarter?: string | null;
                LegalSubdivision?: string | null;
                Section?: string | null;
                Township?: string | null;
                Range?: string | null;
                Meridian?: string | null;
              } | null;
            } | null;
            PropertyPerilScores?: {
              __typename?: 'PropertyCommercialPerilsType';
              Peril?: Array<{
                __typename?: 'PerilDetailsType';
                LossCostScore?: number | null;
                PerilType?: string | null;
                PredictedLossCost?: number | null;
              } | null> | null;
            } | null;
            CasualtyPerilScores?: {
              __typename?: 'CasualtyCommercialPerilsType';
              Peril?: Array<{
                __typename?: 'PerilDetailsType';
                LossCostScore?: number | null;
                PerilType?: string | null;
                PredictedLossCost?: number | null;
              } | null> | null;
            } | null;
            AllPerilsScore?: {
              __typename?: 'CommercialPerilScoreType';
              LossCostScore?: number | null;
              PredictedLossCost?: number | null;
            } | null;
          } | null;
        } | null;
        openingHours: Array<{
          __typename?: 'OpeningHoursType';
          day: number;
          open?: any | null;
          close?: any | null;
        }>;
      } | null;
    }>;
  }>;
  signature?: {
    __typename?: 'SignatureType';
    signatureData?: string | null;
    signatureTime?: any | null;
  } | null;
  bindings: Array<{
    __typename?: 'BindingType';
    id: string;
    label: string;
    bound: boolean;
    unboundReason: string;
  }>;
};

export type ArchivedQuoteFieldsFragment = {
  __typename?: 'ArchivedQuoteType';
  id: string;
  uniqueID: any;
  friendlyId?: string | null;
  additionalInformation: string;
  producerId: string;
  ArchivedQuoteStatus: string;
  language?: {
    __typename?: 'LanguageType';
    shortName: string;
    fullName: string;
  } | null;
  businessLine?: {
    __typename?: 'BusinessTypeType';
    id: string;
    displayName: string;
  } | null;
  structuredData?: {
    __typename?: 'StructuredQuoteDataType';
    businessLegalName?: string | null;
    businessName?: string | null;
    businessTradeName?: string | null;
    contactName?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    revenue?: number | null;
    alreadyContactedBrokerName?: string | null;
    contactAddress?: {
      __typename?: 'AddressType';
      formattedAddress?: string | null;
    } | null;
  } | null;
  completedForms: Array<{
    __typename?: 'CompletedFormType';
    id: string;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
    } | null;
    extensions?: Array<{
      __typename?: 'PolicyExtensionType';
      id: string;
      label: string;
      multiplier?: string | null;
      questionId?: string | null;
      value?: string | null;
    } | null> | null;
    answers: Array<{
      __typename?: 'AnswerInstanceType';
      id: string;
      apiName: string;
      displayName: string;
      label: string;
      helpText?: string | null;
      component: string;
      propsBlob?: any | null;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      value: string;
      date: any;
      method?: string | null;
      dataType: string;
      defaultValue?: string | null;
      subAnswers: Array<{
        __typename?: 'AnswerInstanceType';
        id: string;
        apiName: string;
        displayName: string;
        label: string;
        helpText?: string | null;
        component: string;
        propsBlob?: any | null;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        value: string;
        date: any;
        method?: string | null;
        dataType: string;
        defaultValue?: string | null;
        subAnswers: Array<{
          __typename?: 'AnswerInstanceType';
          id: string;
          apiName: string;
          displayName: string;
          label: string;
          helpText?: string | null;
          component: string;
          propsBlob?: any | null;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          value: string;
          date: any;
          method?: string | null;
          dataType: string;
          defaultValue?: string | null;
          subAnswers: Array<{
            __typename?: 'AnswerInstanceType';
            id: string;
            apiName: string;
            displayName: string;
            label: string;
            helpText?: string | null;
            component: string;
            propsBlob?: any | null;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            value: string;
            date: any;
            method?: string | null;
            dataType: string;
            defaultValue?: string | null;
            subAnswers: Array<{
              __typename?: 'AnswerInstanceType';
              id: string;
              apiName: string;
              displayName: string;
              label: string;
              helpText?: string | null;
              component: string;
              propsBlob?: any | null;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              value: string;
              date: any;
              method?: string | null;
              dataType: string;
              defaultValue?: string | null;
              subAnswers: Array<{
                __typename?: 'AnswerInstanceType';
                id: string;
                apiName: string;
                displayName: string;
                label: string;
                helpText?: string | null;
                component: string;
                propsBlob?: any | null;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                value: string;
                date: any;
                method?: string | null;
                dataType: string;
                defaultValue?: string | null;
                subAnswers: Array<{
                  __typename?: 'AnswerInstanceType';
                  id: string;
                  apiName: string;
                  displayName: string;
                  label: string;
                  helpText?: string | null;
                  component: string;
                  propsBlob?: any | null;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  value: string;
                  date: any;
                  method?: string | null;
                  dataType: string;
                  defaultValue?: string | null;
                  subAnswers: Array<{
                    __typename?: 'AnswerInstanceType';
                    id: string;
                    apiName: string;
                    displayName: string;
                    label: string;
                    helpText?: string | null;
                    component: string;
                    propsBlob?: any | null;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    value: string;
                    date: any;
                    method?: string | null;
                    dataType: string;
                    defaultValue?: string | null;
                    subAnswers: Array<{
                      __typename?: 'AnswerInstanceType';
                      id: string;
                      apiName: string;
                      displayName: string;
                      label: string;
                      helpText?: string | null;
                      component: string;
                      propsBlob?: any | null;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      value: string;
                      date: any;
                      method?: string | null;
                      dataType: string;
                      defaultValue?: string | null;
                      subAnswers: Array<{
                        __typename?: 'AnswerInstanceType';
                        id: string;
                        apiName: string;
                        displayName: string;
                        label: string;
                        helpText?: string | null;
                        component: string;
                        propsBlob?: any | null;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        value: string;
                        date: any;
                        method?: string | null;
                        dataType: string;
                        defaultValue?: string | null;
                        details?: {
                          __typename?: 'AnswerInstanceDetailsType';
                          lat?: number | null;
                          lng?: number | null;
                          images: Array<{
                            __typename?: 'GoogleImageType';
                            description: string;
                            shortDescription: string;
                            category: CoreGoogleImageCategoryChoices;
                            data?: string | null;
                          }>;
                          google?: {
                            __typename?: 'GoogleDetailsType';
                            placeId?: string | null;
                            rating?: number | null;
                            numberOfRatings?: number | null;
                          } | null;
                          opta?: {
                            __typename?: 'OPTADetailsType';
                            optaFusData?: {
                              __typename?: 'OptaFusDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Commercial?: {
                                __typename?: 'FusProductCommercialType';
                                SearchMessage?: string | null;
                                CommercialGrade: Array<{
                                  __typename?: 'CommercialGradeType';
                                  PublicFireProtectionClassification?:
                                    | number
                                    | null;
                                  WaterSystem?: string | null;
                                  RiskSCORDetail: Array<{
                                    __typename?: 'RiskSCORDetailType';
                                    RiskSCOR?: number | null;
                                    Type?: string | null;
                                    Adjustment: Array<{
                                      __typename?: 'RiskSCORAdjustmentType';
                                      Adjustment?: string | null;
                                      AdjustmentType?: string | null;
                                    }>;
                                  }>;
                                }>;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              Dwelling?: {
                                __typename?: 'FusProductDwellingType';
                                SearchMessage?: string | null;
                                DwellingGrades?: {
                                  __typename?: 'DwellingGradesType';
                                  AllOtherAreasGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  FirehallProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  HydrantProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                } | null;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              EarthquakeCodes?: {
                                __typename?: 'EarthquakeCodesType';
                                SearchMessage?: string | null;
                                EarthquakeCode: Array<{
                                  __typename?: 'EarthquakeCodeType';
                                  Description?: string | null;
                                  SystemName?: string | null;
                                  Zone?: string | null;
                                }>;
                              } | null;
                              FusMaps?: {
                                __typename?: 'FusMapsType';
                                SearchMessage?: string | null;
                                FusMap: Array<{
                                  __typename?: 'FusMapType';
                                  Business?: string | null;
                                  FusContent?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                              HydrantCounts?: {
                                __typename?: 'HydrantCountsType';
                                SearchMessage?: string | null;
                                HydrantCount: Array<{
                                  __typename?: 'HydrantCountType';
                                  NumberOfHydrants: number;
                                  Ownership?: string | null;
                                  RadiusMetres?: string | null;
                                }>;
                              } | null;
                            } | null;
                            optaFloodscoreData?: {
                              __typename?: 'OptaFloodScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              FloodScores?: {
                                __typename?: 'FloodScoresType';
                                FloodScore: Array<{
                                  __typename?: 'FloodScoreType';
                                  AverageAnnualLossScore?: number | null;
                                  Deductible?: number | null;
                                  Default?: boolean | null;
                                  FloodScore?: number | null;
                                  Limit?: number | null;
                                  RateFactor?: number | null;
                                  Zone?: string | null;
                                  ResidentialModifiers?: {
                                    __typename?: 'ResidentialConstructionFeaturesType';
                                    ArchitecturalStyleType?: string | null;
                                    AuxiliaryHeatingType?: string | null;
                                    BathroomCount?: string | null;
                                    CommercialIndicator?: string | null;
                                    ExteriorWallType?: string | null;
                                    FinishedBasement?: boolean | null;
                                    FoundationType?: string | null;
                                    GarageNumberOfCars?: number | null;
                                    GarageType?: string | null;
                                    KitchenCount?: string | null;
                                    OutbuildingPresent?: string | null;
                                    PlumbingType?: string | null;
                                    PrimaryHeatingType?: string | null;
                                    RoofType?: string | null;
                                    SquareFootage?: number | null;
                                    StoreyCount?: string | null;
                                    SwimmingPoolType?: string | null;
                                    YearBuilt?: string | null;
                                    InspectionDetails?: {
                                      __typename?: 'InspectionDetailsType';
                                      InspectionDate?: any | null;
                                      SponsoredInspection?: boolean | null;
                                    } | null;
                                  } | null;
                                  MultiResidentialModifiers?: {
                                    __typename?: 'MultiResidentialConstructionFeaturesType';
                                    BasementPresent?: boolean | null;
                                    ConstructionType?: string | null;
                                    FloorLevel?: number | null;
                                  } | null;
                                  AverageAnnualLossValues?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                  AdditionalDetails: Array<{
                                    __typename?: 'FloodDetailType';
                                    Key?: string | null;
                                    Value?: string | null;
                                  }>;
                                  Premium?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                }>;
                                FloodMap: Array<{
                                  __typename?: 'FloodMapType';
                                  FloodEvent?: string | null;
                                  Content?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            optaIclarifyCommercialConstructionData?: {
                              __typename?: 'OptaIclarifyCommercialConstructionDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Messages?: {
                                __typename?: 'Messages';
                                Message?: Array<string | null> | null;
                              } | null;
                              Property?: {
                                __typename?: 'CommercialPropertyType';
                                LotSize?: number | null;
                                LotSizeUnitOfMeasurement?: string | null;
                                PropertyType?: string | null;
                                Buildings?: {
                                  __typename?: 'BuildingsType';
                                  Building: Array<{
                                    __typename?: 'BuildingType';
                                    StructureID?: string | null;
                                    ConstructionFeatures?: {
                                      __typename?: 'CommercialConstructionFeaturesType';
                                      CombustibilityClass?: string | null;
                                      ConstructionClass?: string | null;
                                      FloorHeight?: number | null;
                                      FloorHeightUnitOfMeasurement?:
                                        | string
                                        | null;
                                      LoadingDockPresent?: boolean | null;
                                      NumberOfStoreys?: number | null;
                                      NumberOfUnits?: number | null;
                                      TotalFloorArea?: number | null;
                                      TotalFloorAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      YearBuilt?: number | null;
                                      Basement?: {
                                        __typename?: 'CommercialBasementType';
                                        BasementArea?: number | null;
                                        BasementAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        BasementPresent?: boolean | null;
                                      } | null;
                                      BuildingUpdates?: {
                                        __typename?: 'BuildingUpdatesType';
                                        BuildingUpdate?: Array<
                                          number | null
                                        > | null;
                                      } | null;
                                      CommercialExteriorWallTypes?: {
                                        __typename?: 'CommercialExteriorWallTypesType';
                                        ExteriorWallType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Elevator?: {
                                        __typename?: 'ElevatorType';
                                        ElevatorCount?: number | null;
                                        ElevatorPresent?: boolean | null;
                                      } | null;
                                      Heating?: {
                                        __typename?: 'CommercialHeatingType';
                                        HeatingFuelSources?: {
                                          __typename?: 'HeatingFuelSourcesType';
                                          HeatingFuelSource?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        HeatingTypes?: {
                                          __typename?: 'HeatingTypesType';
                                          HeatingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      Parking?: {
                                        __typename?: 'CommercialParkingType';
                                        ParkingPresent?: boolean | null;
                                        ParkingTypes?: {
                                          __typename?: 'CommercialParkingTypesType';
                                          ParkingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      PlumbingTypes?: {
                                        __typename?: 'CommercialPlumbingType';
                                        PlumbingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofAttributes?: {
                                        __typename?: 'RoofAttributesType';
                                        RoofConstructions?: {
                                          __typename?: 'RoofConstructionsType';
                                          RoofConstruction?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofSurfaces?: {
                                          __typename?: 'RoofSurfacesType';
                                          RoofSurface?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofTypes?: {
                                          __typename?: 'RoofTypesType';
                                          RoofType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      WaterHeater?: {
                                        __typename?: 'CommercialWaterHeaterType';
                                        WaterHeaterFuelType?: Array<
                                          string | null
                                        > | null;
                                        WaterHeaterType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Wiring?: {
                                        __typename?: 'CommercialWiringType';
                                        WiringPanelTypes?: {
                                          __typename?: 'CommercialWiringPanelTypesType';
                                          WiringPanelType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        WiringTypes?: {
                                          __typename?: 'CommercialWiringTypesType';
                                          WiringType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    Protection?: {
                                      __typename?: 'ProtectionType';
                                      FireAlarmPresent?: boolean | null;
                                      FireAlarmType?: string | null;
                                      PhysicalSecurityPresent?: boolean | null;
                                      SecurityAlarmPresent?: boolean | null;
                                      SecurityAlarmType?: string | null;
                                      SprinklerPresent?: boolean | null;
                                      SprinklerTypeCode?: string | null;
                                      PhysicalSecurityTypes?: {
                                        __typename?: 'PhysicalSecurityTypesType';
                                        PhysicalSecurityType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  }>;
                                } | null;
                                Industry?: {
                                  __typename?: 'BusinessIndustryType';
                                  IBCCode?: string | null;
                                  IBCIndustryDescription?: string | null;
                                  SICIndustryDescription?: string | null;
                                  SICCode?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            optaCommercialPerilScoreData?: {
                              __typename?: 'OptaCommercialPerilScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              PropertyPerilScores?: {
                                __typename?: 'PropertyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              CasualtyPerilScores?: {
                                __typename?: 'CasualtyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              AllPerilsScore?: {
                                __typename?: 'CommercialPerilScoreType';
                                LossCostScore?: number | null;
                                PredictedLossCost?: number | null;
                              } | null;
                            } | null;
                          } | null;
                          openingHours: Array<{
                            __typename?: 'OpeningHoursType';
                            day: number;
                            open?: any | null;
                            close?: any | null;
                          }>;
                        } | null;
                      }>;
                      details?: {
                        __typename?: 'AnswerInstanceDetailsType';
                        lat?: number | null;
                        lng?: number | null;
                        images: Array<{
                          __typename?: 'GoogleImageType';
                          description: string;
                          shortDescription: string;
                          category: CoreGoogleImageCategoryChoices;
                          data?: string | null;
                        }>;
                        google?: {
                          __typename?: 'GoogleDetailsType';
                          placeId?: string | null;
                          rating?: number | null;
                          numberOfRatings?: number | null;
                        } | null;
                        opta?: {
                          __typename?: 'OPTADetailsType';
                          optaFusData?: {
                            __typename?: 'OptaFusDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Commercial?: {
                              __typename?: 'FusProductCommercialType';
                              SearchMessage?: string | null;
                              CommercialGrade: Array<{
                                __typename?: 'CommercialGradeType';
                                PublicFireProtectionClassification?:
                                  | number
                                  | null;
                                WaterSystem?: string | null;
                                RiskSCORDetail: Array<{
                                  __typename?: 'RiskSCORDetailType';
                                  RiskSCOR?: number | null;
                                  Type?: string | null;
                                  Adjustment: Array<{
                                    __typename?: 'RiskSCORAdjustmentType';
                                    Adjustment?: string | null;
                                    AdjustmentType?: string | null;
                                  }>;
                                }>;
                              }>;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            Dwelling?: {
                              __typename?: 'FusProductDwellingType';
                              SearchMessage?: string | null;
                              DwellingGrades?: {
                                __typename?: 'DwellingGradesType';
                                AllOtherAreasGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                FirehallProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                HydrantProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                              } | null;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            EarthquakeCodes?: {
                              __typename?: 'EarthquakeCodesType';
                              SearchMessage?: string | null;
                              EarthquakeCode: Array<{
                                __typename?: 'EarthquakeCodeType';
                                Description?: string | null;
                                SystemName?: string | null;
                                Zone?: string | null;
                              }>;
                            } | null;
                            FusMaps?: {
                              __typename?: 'FusMapsType';
                              SearchMessage?: string | null;
                              FusMap: Array<{
                                __typename?: 'FusMapType';
                                Business?: string | null;
                                FusContent?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                            HydrantCounts?: {
                              __typename?: 'HydrantCountsType';
                              SearchMessage?: string | null;
                              HydrantCount: Array<{
                                __typename?: 'HydrantCountType';
                                NumberOfHydrants: number;
                                Ownership?: string | null;
                                RadiusMetres?: string | null;
                              }>;
                            } | null;
                          } | null;
                          optaFloodscoreData?: {
                            __typename?: 'OptaFloodScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            FloodScores?: {
                              __typename?: 'FloodScoresType';
                              FloodScore: Array<{
                                __typename?: 'FloodScoreType';
                                AverageAnnualLossScore?: number | null;
                                Deductible?: number | null;
                                Default?: boolean | null;
                                FloodScore?: number | null;
                                Limit?: number | null;
                                RateFactor?: number | null;
                                Zone?: string | null;
                                ResidentialModifiers?: {
                                  __typename?: 'ResidentialConstructionFeaturesType';
                                  ArchitecturalStyleType?: string | null;
                                  AuxiliaryHeatingType?: string | null;
                                  BathroomCount?: string | null;
                                  CommercialIndicator?: string | null;
                                  ExteriorWallType?: string | null;
                                  FinishedBasement?: boolean | null;
                                  FoundationType?: string | null;
                                  GarageNumberOfCars?: number | null;
                                  GarageType?: string | null;
                                  KitchenCount?: string | null;
                                  OutbuildingPresent?: string | null;
                                  PlumbingType?: string | null;
                                  PrimaryHeatingType?: string | null;
                                  RoofType?: string | null;
                                  SquareFootage?: number | null;
                                  StoreyCount?: string | null;
                                  SwimmingPoolType?: string | null;
                                  YearBuilt?: string | null;
                                  InspectionDetails?: {
                                    __typename?: 'InspectionDetailsType';
                                    InspectionDate?: any | null;
                                    SponsoredInspection?: boolean | null;
                                  } | null;
                                } | null;
                                MultiResidentialModifiers?: {
                                  __typename?: 'MultiResidentialConstructionFeaturesType';
                                  BasementPresent?: boolean | null;
                                  ConstructionType?: string | null;
                                  FloorLevel?: number | null;
                                } | null;
                                AverageAnnualLossValues?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                                AdditionalDetails: Array<{
                                  __typename?: 'FloodDetailType';
                                  Key?: string | null;
                                  Value?: string | null;
                                }>;
                                Premium?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                              }>;
                              FloodMap: Array<{
                                __typename?: 'FloodMapType';
                                FloodEvent?: string | null;
                                Content?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          optaIclarifyCommercialConstructionData?: {
                            __typename?: 'OptaIclarifyCommercialConstructionDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Messages?: {
                              __typename?: 'Messages';
                              Message?: Array<string | null> | null;
                            } | null;
                            Property?: {
                              __typename?: 'CommercialPropertyType';
                              LotSize?: number | null;
                              LotSizeUnitOfMeasurement?: string | null;
                              PropertyType?: string | null;
                              Buildings?: {
                                __typename?: 'BuildingsType';
                                Building: Array<{
                                  __typename?: 'BuildingType';
                                  StructureID?: string | null;
                                  ConstructionFeatures?: {
                                    __typename?: 'CommercialConstructionFeaturesType';
                                    CombustibilityClass?: string | null;
                                    ConstructionClass?: string | null;
                                    FloorHeight?: number | null;
                                    FloorHeightUnitOfMeasurement?:
                                      | string
                                      | null;
                                    LoadingDockPresent?: boolean | null;
                                    NumberOfStoreys?: number | null;
                                    NumberOfUnits?: number | null;
                                    TotalFloorArea?: number | null;
                                    TotalFloorAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    YearBuilt?: number | null;
                                    Basement?: {
                                      __typename?: 'CommercialBasementType';
                                      BasementArea?: number | null;
                                      BasementAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      BasementPresent?: boolean | null;
                                    } | null;
                                    BuildingUpdates?: {
                                      __typename?: 'BuildingUpdatesType';
                                      BuildingUpdate?: Array<
                                        number | null
                                      > | null;
                                    } | null;
                                    CommercialExteriorWallTypes?: {
                                      __typename?: 'CommercialExteriorWallTypesType';
                                      ExteriorWallType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Elevator?: {
                                      __typename?: 'ElevatorType';
                                      ElevatorCount?: number | null;
                                      ElevatorPresent?: boolean | null;
                                    } | null;
                                    Heating?: {
                                      __typename?: 'CommercialHeatingType';
                                      HeatingFuelSources?: {
                                        __typename?: 'HeatingFuelSourcesType';
                                        HeatingFuelSource?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      HeatingTypes?: {
                                        __typename?: 'HeatingTypesType';
                                        HeatingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    Parking?: {
                                      __typename?: 'CommercialParkingType';
                                      ParkingPresent?: boolean | null;
                                      ParkingTypes?: {
                                        __typename?: 'CommercialParkingTypesType';
                                        ParkingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    PlumbingTypes?: {
                                      __typename?: 'CommercialPlumbingType';
                                      PlumbingType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofAttributes?: {
                                      __typename?: 'RoofAttributesType';
                                      RoofConstructions?: {
                                        __typename?: 'RoofConstructionsType';
                                        RoofConstruction?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofSurfaces?: {
                                        __typename?: 'RoofSurfacesType';
                                        RoofSurface?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofTypes?: {
                                        __typename?: 'RoofTypesType';
                                        RoofType?: Array<string | null> | null;
                                      } | null;
                                    } | null;
                                    WaterHeater?: {
                                      __typename?: 'CommercialWaterHeaterType';
                                      WaterHeaterFuelType?: Array<
                                        string | null
                                      > | null;
                                      WaterHeaterType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Wiring?: {
                                      __typename?: 'CommercialWiringType';
                                      WiringPanelTypes?: {
                                        __typename?: 'CommercialWiringPanelTypesType';
                                        WiringPanelType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      WiringTypes?: {
                                        __typename?: 'CommercialWiringTypesType';
                                        WiringType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  Protection?: {
                                    __typename?: 'ProtectionType';
                                    FireAlarmPresent?: boolean | null;
                                    FireAlarmType?: string | null;
                                    PhysicalSecurityPresent?: boolean | null;
                                    SecurityAlarmPresent?: boolean | null;
                                    SecurityAlarmType?: string | null;
                                    SprinklerPresent?: boolean | null;
                                    SprinklerTypeCode?: string | null;
                                    PhysicalSecurityTypes?: {
                                      __typename?: 'PhysicalSecurityTypesType';
                                      PhysicalSecurityType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                  } | null;
                                }>;
                              } | null;
                              Industry?: {
                                __typename?: 'BusinessIndustryType';
                                IBCCode?: string | null;
                                IBCIndustryDescription?: string | null;
                                SICIndustryDescription?: string | null;
                                SICCode?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          optaCommercialPerilScoreData?: {
                            __typename?: 'OptaCommercialPerilScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            PropertyPerilScores?: {
                              __typename?: 'PropertyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            CasualtyPerilScores?: {
                              __typename?: 'CasualtyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            AllPerilsScore?: {
                              __typename?: 'CommercialPerilScoreType';
                              LossCostScore?: number | null;
                              PredictedLossCost?: number | null;
                            } | null;
                          } | null;
                        } | null;
                        openingHours: Array<{
                          __typename?: 'OpeningHoursType';
                          day: number;
                          open?: any | null;
                          close?: any | null;
                        }>;
                      } | null;
                    }>;
                    details?: {
                      __typename?: 'AnswerInstanceDetailsType';
                      lat?: number | null;
                      lng?: number | null;
                      images: Array<{
                        __typename?: 'GoogleImageType';
                        description: string;
                        shortDescription: string;
                        category: CoreGoogleImageCategoryChoices;
                        data?: string | null;
                      }>;
                      google?: {
                        __typename?: 'GoogleDetailsType';
                        placeId?: string | null;
                        rating?: number | null;
                        numberOfRatings?: number | null;
                      } | null;
                      opta?: {
                        __typename?: 'OPTADetailsType';
                        optaFusData?: {
                          __typename?: 'OptaFusDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Commercial?: {
                            __typename?: 'FusProductCommercialType';
                            SearchMessage?: string | null;
                            CommercialGrade: Array<{
                              __typename?: 'CommercialGradeType';
                              PublicFireProtectionClassification?:
                                | number
                                | null;
                              WaterSystem?: string | null;
                              RiskSCORDetail: Array<{
                                __typename?: 'RiskSCORDetailType';
                                RiskSCOR?: number | null;
                                Type?: string | null;
                                Adjustment: Array<{
                                  __typename?: 'RiskSCORAdjustmentType';
                                  Adjustment?: string | null;
                                  AdjustmentType?: string | null;
                                }>;
                              }>;
                            }>;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          Dwelling?: {
                            __typename?: 'FusProductDwellingType';
                            SearchMessage?: string | null;
                            DwellingGrades?: {
                              __typename?: 'DwellingGradesType';
                              AllOtherAreasGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              FirehallProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              HydrantProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                            } | null;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          EarthquakeCodes?: {
                            __typename?: 'EarthquakeCodesType';
                            SearchMessage?: string | null;
                            EarthquakeCode: Array<{
                              __typename?: 'EarthquakeCodeType';
                              Description?: string | null;
                              SystemName?: string | null;
                              Zone?: string | null;
                            }>;
                          } | null;
                          FusMaps?: {
                            __typename?: 'FusMapsType';
                            SearchMessage?: string | null;
                            FusMap: Array<{
                              __typename?: 'FusMapType';
                              Business?: string | null;
                              FusContent?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                          HydrantCounts?: {
                            __typename?: 'HydrantCountsType';
                            SearchMessage?: string | null;
                            HydrantCount: Array<{
                              __typename?: 'HydrantCountType';
                              NumberOfHydrants: number;
                              Ownership?: string | null;
                              RadiusMetres?: string | null;
                            }>;
                          } | null;
                        } | null;
                        optaFloodscoreData?: {
                          __typename?: 'OptaFloodScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          FloodScores?: {
                            __typename?: 'FloodScoresType';
                            FloodScore: Array<{
                              __typename?: 'FloodScoreType';
                              AverageAnnualLossScore?: number | null;
                              Deductible?: number | null;
                              Default?: boolean | null;
                              FloodScore?: number | null;
                              Limit?: number | null;
                              RateFactor?: number | null;
                              Zone?: string | null;
                              ResidentialModifiers?: {
                                __typename?: 'ResidentialConstructionFeaturesType';
                                ArchitecturalStyleType?: string | null;
                                AuxiliaryHeatingType?: string | null;
                                BathroomCount?: string | null;
                                CommercialIndicator?: string | null;
                                ExteriorWallType?: string | null;
                                FinishedBasement?: boolean | null;
                                FoundationType?: string | null;
                                GarageNumberOfCars?: number | null;
                                GarageType?: string | null;
                                KitchenCount?: string | null;
                                OutbuildingPresent?: string | null;
                                PlumbingType?: string | null;
                                PrimaryHeatingType?: string | null;
                                RoofType?: string | null;
                                SquareFootage?: number | null;
                                StoreyCount?: string | null;
                                SwimmingPoolType?: string | null;
                                YearBuilt?: string | null;
                                InspectionDetails?: {
                                  __typename?: 'InspectionDetailsType';
                                  InspectionDate?: any | null;
                                  SponsoredInspection?: boolean | null;
                                } | null;
                              } | null;
                              MultiResidentialModifiers?: {
                                __typename?: 'MultiResidentialConstructionFeaturesType';
                                BasementPresent?: boolean | null;
                                ConstructionType?: string | null;
                                FloorLevel?: number | null;
                              } | null;
                              AverageAnnualLossValues?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                              AdditionalDetails: Array<{
                                __typename?: 'FloodDetailType';
                                Key?: string | null;
                                Value?: string | null;
                              }>;
                              Premium?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                            }>;
                            FloodMap: Array<{
                              __typename?: 'FloodMapType';
                              FloodEvent?: string | null;
                              Content?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        optaIclarifyCommercialConstructionData?: {
                          __typename?: 'OptaIclarifyCommercialConstructionDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Messages?: {
                            __typename?: 'Messages';
                            Message?: Array<string | null> | null;
                          } | null;
                          Property?: {
                            __typename?: 'CommercialPropertyType';
                            LotSize?: number | null;
                            LotSizeUnitOfMeasurement?: string | null;
                            PropertyType?: string | null;
                            Buildings?: {
                              __typename?: 'BuildingsType';
                              Building: Array<{
                                __typename?: 'BuildingType';
                                StructureID?: string | null;
                                ConstructionFeatures?: {
                                  __typename?: 'CommercialConstructionFeaturesType';
                                  CombustibilityClass?: string | null;
                                  ConstructionClass?: string | null;
                                  FloorHeight?: number | null;
                                  FloorHeightUnitOfMeasurement?: string | null;
                                  LoadingDockPresent?: boolean | null;
                                  NumberOfStoreys?: number | null;
                                  NumberOfUnits?: number | null;
                                  TotalFloorArea?: number | null;
                                  TotalFloorAreaUnitOfMeasurement?:
                                    | string
                                    | null;
                                  YearBuilt?: number | null;
                                  Basement?: {
                                    __typename?: 'CommercialBasementType';
                                    BasementArea?: number | null;
                                    BasementAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    BasementPresent?: boolean | null;
                                  } | null;
                                  BuildingUpdates?: {
                                    __typename?: 'BuildingUpdatesType';
                                    BuildingUpdate?: Array<
                                      number | null
                                    > | null;
                                  } | null;
                                  CommercialExteriorWallTypes?: {
                                    __typename?: 'CommercialExteriorWallTypesType';
                                    ExteriorWallType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Elevator?: {
                                    __typename?: 'ElevatorType';
                                    ElevatorCount?: number | null;
                                    ElevatorPresent?: boolean | null;
                                  } | null;
                                  Heating?: {
                                    __typename?: 'CommercialHeatingType';
                                    HeatingFuelSources?: {
                                      __typename?: 'HeatingFuelSourcesType';
                                      HeatingFuelSource?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    HeatingTypes?: {
                                      __typename?: 'HeatingTypesType';
                                      HeatingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  Parking?: {
                                    __typename?: 'CommercialParkingType';
                                    ParkingPresent?: boolean | null;
                                    ParkingTypes?: {
                                      __typename?: 'CommercialParkingTypesType';
                                      ParkingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  PlumbingTypes?: {
                                    __typename?: 'CommercialPlumbingType';
                                    PlumbingType?: Array<string | null> | null;
                                  } | null;
                                  RoofAttributes?: {
                                    __typename?: 'RoofAttributesType';
                                    RoofConstructions?: {
                                      __typename?: 'RoofConstructionsType';
                                      RoofConstruction?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofSurfaces?: {
                                      __typename?: 'RoofSurfacesType';
                                      RoofSurface?: Array<string | null> | null;
                                    } | null;
                                    RoofTypes?: {
                                      __typename?: 'RoofTypesType';
                                      RoofType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  WaterHeater?: {
                                    __typename?: 'CommercialWaterHeaterType';
                                    WaterHeaterFuelType?: Array<
                                      string | null
                                    > | null;
                                    WaterHeaterType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Wiring?: {
                                    __typename?: 'CommercialWiringType';
                                    WiringPanelTypes?: {
                                      __typename?: 'CommercialWiringPanelTypesType';
                                      WiringPanelType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    WiringTypes?: {
                                      __typename?: 'CommercialWiringTypesType';
                                      WiringType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Protection?: {
                                  __typename?: 'ProtectionType';
                                  FireAlarmPresent?: boolean | null;
                                  FireAlarmType?: string | null;
                                  PhysicalSecurityPresent?: boolean | null;
                                  SecurityAlarmPresent?: boolean | null;
                                  SecurityAlarmType?: string | null;
                                  SprinklerPresent?: boolean | null;
                                  SprinklerTypeCode?: string | null;
                                  PhysicalSecurityTypes?: {
                                    __typename?: 'PhysicalSecurityTypesType';
                                    PhysicalSecurityType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                } | null;
                              }>;
                            } | null;
                            Industry?: {
                              __typename?: 'BusinessIndustryType';
                              IBCCode?: string | null;
                              IBCIndustryDescription?: string | null;
                              SICIndustryDescription?: string | null;
                              SICCode?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        optaCommercialPerilScoreData?: {
                          __typename?: 'OptaCommercialPerilScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          PropertyPerilScores?: {
                            __typename?: 'PropertyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          CasualtyPerilScores?: {
                            __typename?: 'CasualtyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          AllPerilsScore?: {
                            __typename?: 'CommercialPerilScoreType';
                            LossCostScore?: number | null;
                            PredictedLossCost?: number | null;
                          } | null;
                        } | null;
                      } | null;
                      openingHours: Array<{
                        __typename?: 'OpeningHoursType';
                        day: number;
                        open?: any | null;
                        close?: any | null;
                      }>;
                    } | null;
                  }>;
                  details?: {
                    __typename?: 'AnswerInstanceDetailsType';
                    lat?: number | null;
                    lng?: number | null;
                    images: Array<{
                      __typename?: 'GoogleImageType';
                      description: string;
                      shortDescription: string;
                      category: CoreGoogleImageCategoryChoices;
                      data?: string | null;
                    }>;
                    google?: {
                      __typename?: 'GoogleDetailsType';
                      placeId?: string | null;
                      rating?: number | null;
                      numberOfRatings?: number | null;
                    } | null;
                    opta?: {
                      __typename?: 'OPTADetailsType';
                      optaFusData?: {
                        __typename?: 'OptaFusDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Commercial?: {
                          __typename?: 'FusProductCommercialType';
                          SearchMessage?: string | null;
                          CommercialGrade: Array<{
                            __typename?: 'CommercialGradeType';
                            PublicFireProtectionClassification?: number | null;
                            WaterSystem?: string | null;
                            RiskSCORDetail: Array<{
                              __typename?: 'RiskSCORDetailType';
                              RiskSCOR?: number | null;
                              Type?: string | null;
                              Adjustment: Array<{
                                __typename?: 'RiskSCORAdjustmentType';
                                Adjustment?: string | null;
                                AdjustmentType?: string | null;
                              }>;
                            }>;
                          }>;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        Dwelling?: {
                          __typename?: 'FusProductDwellingType';
                          SearchMessage?: string | null;
                          DwellingGrades?: {
                            __typename?: 'DwellingGradesType';
                            AllOtherAreasGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            FirehallProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            HydrantProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                          } | null;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        EarthquakeCodes?: {
                          __typename?: 'EarthquakeCodesType';
                          SearchMessage?: string | null;
                          EarthquakeCode: Array<{
                            __typename?: 'EarthquakeCodeType';
                            Description?: string | null;
                            SystemName?: string | null;
                            Zone?: string | null;
                          }>;
                        } | null;
                        FusMaps?: {
                          __typename?: 'FusMapsType';
                          SearchMessage?: string | null;
                          FusMap: Array<{
                            __typename?: 'FusMapType';
                            Business?: string | null;
                            FusContent?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                        HydrantCounts?: {
                          __typename?: 'HydrantCountsType';
                          SearchMessage?: string | null;
                          HydrantCount: Array<{
                            __typename?: 'HydrantCountType';
                            NumberOfHydrants: number;
                            Ownership?: string | null;
                            RadiusMetres?: string | null;
                          }>;
                        } | null;
                      } | null;
                      optaFloodscoreData?: {
                        __typename?: 'OptaFloodScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        FloodScores?: {
                          __typename?: 'FloodScoresType';
                          FloodScore: Array<{
                            __typename?: 'FloodScoreType';
                            AverageAnnualLossScore?: number | null;
                            Deductible?: number | null;
                            Default?: boolean | null;
                            FloodScore?: number | null;
                            Limit?: number | null;
                            RateFactor?: number | null;
                            Zone?: string | null;
                            ResidentialModifiers?: {
                              __typename?: 'ResidentialConstructionFeaturesType';
                              ArchitecturalStyleType?: string | null;
                              AuxiliaryHeatingType?: string | null;
                              BathroomCount?: string | null;
                              CommercialIndicator?: string | null;
                              ExteriorWallType?: string | null;
                              FinishedBasement?: boolean | null;
                              FoundationType?: string | null;
                              GarageNumberOfCars?: number | null;
                              GarageType?: string | null;
                              KitchenCount?: string | null;
                              OutbuildingPresent?: string | null;
                              PlumbingType?: string | null;
                              PrimaryHeatingType?: string | null;
                              RoofType?: string | null;
                              SquareFootage?: number | null;
                              StoreyCount?: string | null;
                              SwimmingPoolType?: string | null;
                              YearBuilt?: string | null;
                              InspectionDetails?: {
                                __typename?: 'InspectionDetailsType';
                                InspectionDate?: any | null;
                                SponsoredInspection?: boolean | null;
                              } | null;
                            } | null;
                            MultiResidentialModifiers?: {
                              __typename?: 'MultiResidentialConstructionFeaturesType';
                              BasementPresent?: boolean | null;
                              ConstructionType?: string | null;
                              FloorLevel?: number | null;
                            } | null;
                            AverageAnnualLossValues?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                            AdditionalDetails: Array<{
                              __typename?: 'FloodDetailType';
                              Key?: string | null;
                              Value?: string | null;
                            }>;
                            Premium?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                          }>;
                          FloodMap: Array<{
                            __typename?: 'FloodMapType';
                            FloodEvent?: string | null;
                            Content?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      optaIclarifyCommercialConstructionData?: {
                        __typename?: 'OptaIclarifyCommercialConstructionDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Messages?: {
                          __typename?: 'Messages';
                          Message?: Array<string | null> | null;
                        } | null;
                        Property?: {
                          __typename?: 'CommercialPropertyType';
                          LotSize?: number | null;
                          LotSizeUnitOfMeasurement?: string | null;
                          PropertyType?: string | null;
                          Buildings?: {
                            __typename?: 'BuildingsType';
                            Building: Array<{
                              __typename?: 'BuildingType';
                              StructureID?: string | null;
                              ConstructionFeatures?: {
                                __typename?: 'CommercialConstructionFeaturesType';
                                CombustibilityClass?: string | null;
                                ConstructionClass?: string | null;
                                FloorHeight?: number | null;
                                FloorHeightUnitOfMeasurement?: string | null;
                                LoadingDockPresent?: boolean | null;
                                NumberOfStoreys?: number | null;
                                NumberOfUnits?: number | null;
                                TotalFloorArea?: number | null;
                                TotalFloorAreaUnitOfMeasurement?: string | null;
                                YearBuilt?: number | null;
                                Basement?: {
                                  __typename?: 'CommercialBasementType';
                                  BasementArea?: number | null;
                                  BasementAreaUnitOfMeasurement?: string | null;
                                  BasementPresent?: boolean | null;
                                } | null;
                                BuildingUpdates?: {
                                  __typename?: 'BuildingUpdatesType';
                                  BuildingUpdate?: Array<number | null> | null;
                                } | null;
                                CommercialExteriorWallTypes?: {
                                  __typename?: 'CommercialExteriorWallTypesType';
                                  ExteriorWallType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                Elevator?: {
                                  __typename?: 'ElevatorType';
                                  ElevatorCount?: number | null;
                                  ElevatorPresent?: boolean | null;
                                } | null;
                                Heating?: {
                                  __typename?: 'CommercialHeatingType';
                                  HeatingFuelSources?: {
                                    __typename?: 'HeatingFuelSourcesType';
                                    HeatingFuelSource?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  HeatingTypes?: {
                                    __typename?: 'HeatingTypesType';
                                    HeatingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                Parking?: {
                                  __typename?: 'CommercialParkingType';
                                  ParkingPresent?: boolean | null;
                                  ParkingTypes?: {
                                    __typename?: 'CommercialParkingTypesType';
                                    ParkingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                PlumbingTypes?: {
                                  __typename?: 'CommercialPlumbingType';
                                  PlumbingType?: Array<string | null> | null;
                                } | null;
                                RoofAttributes?: {
                                  __typename?: 'RoofAttributesType';
                                  RoofConstructions?: {
                                    __typename?: 'RoofConstructionsType';
                                    RoofConstruction?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  RoofSurfaces?: {
                                    __typename?: 'RoofSurfacesType';
                                    RoofSurface?: Array<string | null> | null;
                                  } | null;
                                  RoofTypes?: {
                                    __typename?: 'RoofTypesType';
                                    RoofType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                WaterHeater?: {
                                  __typename?: 'CommercialWaterHeaterType';
                                  WaterHeaterFuelType?: Array<
                                    string | null
                                  > | null;
                                  WaterHeaterType?: Array<string | null> | null;
                                } | null;
                                Wiring?: {
                                  __typename?: 'CommercialWiringType';
                                  WiringPanelTypes?: {
                                    __typename?: 'CommercialWiringPanelTypesType';
                                    WiringPanelType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  WiringTypes?: {
                                    __typename?: 'CommercialWiringTypesType';
                                    WiringType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                              } | null;
                              Protection?: {
                                __typename?: 'ProtectionType';
                                FireAlarmPresent?: boolean | null;
                                FireAlarmType?: string | null;
                                PhysicalSecurityPresent?: boolean | null;
                                SecurityAlarmPresent?: boolean | null;
                                SecurityAlarmType?: string | null;
                                SprinklerPresent?: boolean | null;
                                SprinklerTypeCode?: string | null;
                                PhysicalSecurityTypes?: {
                                  __typename?: 'PhysicalSecurityTypesType';
                                  PhysicalSecurityType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                              } | null;
                            }>;
                          } | null;
                          Industry?: {
                            __typename?: 'BusinessIndustryType';
                            IBCCode?: string | null;
                            IBCIndustryDescription?: string | null;
                            SICIndustryDescription?: string | null;
                            SICCode?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      optaCommercialPerilScoreData?: {
                        __typename?: 'OptaCommercialPerilScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        PropertyPerilScores?: {
                          __typename?: 'PropertyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        CasualtyPerilScores?: {
                          __typename?: 'CasualtyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        AllPerilsScore?: {
                          __typename?: 'CommercialPerilScoreType';
                          LossCostScore?: number | null;
                          PredictedLossCost?: number | null;
                        } | null;
                      } | null;
                    } | null;
                    openingHours: Array<{
                      __typename?: 'OpeningHoursType';
                      day: number;
                      open?: any | null;
                      close?: any | null;
                    }>;
                  } | null;
                }>;
                details?: {
                  __typename?: 'AnswerInstanceDetailsType';
                  lat?: number | null;
                  lng?: number | null;
                  images: Array<{
                    __typename?: 'GoogleImageType';
                    description: string;
                    shortDescription: string;
                    category: CoreGoogleImageCategoryChoices;
                    data?: string | null;
                  }>;
                  google?: {
                    __typename?: 'GoogleDetailsType';
                    placeId?: string | null;
                    rating?: number | null;
                    numberOfRatings?: number | null;
                  } | null;
                  opta?: {
                    __typename?: 'OPTADetailsType';
                    optaFusData?: {
                      __typename?: 'OptaFusDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Commercial?: {
                        __typename?: 'FusProductCommercialType';
                        SearchMessage?: string | null;
                        CommercialGrade: Array<{
                          __typename?: 'CommercialGradeType';
                          PublicFireProtectionClassification?: number | null;
                          WaterSystem?: string | null;
                          RiskSCORDetail: Array<{
                            __typename?: 'RiskSCORDetailType';
                            RiskSCOR?: number | null;
                            Type?: string | null;
                            Adjustment: Array<{
                              __typename?: 'RiskSCORAdjustmentType';
                              Adjustment?: string | null;
                              AdjustmentType?: string | null;
                            }>;
                          }>;
                        }>;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      Dwelling?: {
                        __typename?: 'FusProductDwellingType';
                        SearchMessage?: string | null;
                        DwellingGrades?: {
                          __typename?: 'DwellingGradesType';
                          AllOtherAreasGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          FirehallProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          HydrantProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                        } | null;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      EarthquakeCodes?: {
                        __typename?: 'EarthquakeCodesType';
                        SearchMessage?: string | null;
                        EarthquakeCode: Array<{
                          __typename?: 'EarthquakeCodeType';
                          Description?: string | null;
                          SystemName?: string | null;
                          Zone?: string | null;
                        }>;
                      } | null;
                      FusMaps?: {
                        __typename?: 'FusMapsType';
                        SearchMessage?: string | null;
                        FusMap: Array<{
                          __typename?: 'FusMapType';
                          Business?: string | null;
                          FusContent?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                      HydrantCounts?: {
                        __typename?: 'HydrantCountsType';
                        SearchMessage?: string | null;
                        HydrantCount: Array<{
                          __typename?: 'HydrantCountType';
                          NumberOfHydrants: number;
                          Ownership?: string | null;
                          RadiusMetres?: string | null;
                        }>;
                      } | null;
                    } | null;
                    optaFloodscoreData?: {
                      __typename?: 'OptaFloodScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      FloodScores?: {
                        __typename?: 'FloodScoresType';
                        FloodScore: Array<{
                          __typename?: 'FloodScoreType';
                          AverageAnnualLossScore?: number | null;
                          Deductible?: number | null;
                          Default?: boolean | null;
                          FloodScore?: number | null;
                          Limit?: number | null;
                          RateFactor?: number | null;
                          Zone?: string | null;
                          ResidentialModifiers?: {
                            __typename?: 'ResidentialConstructionFeaturesType';
                            ArchitecturalStyleType?: string | null;
                            AuxiliaryHeatingType?: string | null;
                            BathroomCount?: string | null;
                            CommercialIndicator?: string | null;
                            ExteriorWallType?: string | null;
                            FinishedBasement?: boolean | null;
                            FoundationType?: string | null;
                            GarageNumberOfCars?: number | null;
                            GarageType?: string | null;
                            KitchenCount?: string | null;
                            OutbuildingPresent?: string | null;
                            PlumbingType?: string | null;
                            PrimaryHeatingType?: string | null;
                            RoofType?: string | null;
                            SquareFootage?: number | null;
                            StoreyCount?: string | null;
                            SwimmingPoolType?: string | null;
                            YearBuilt?: string | null;
                            InspectionDetails?: {
                              __typename?: 'InspectionDetailsType';
                              InspectionDate?: any | null;
                              SponsoredInspection?: boolean | null;
                            } | null;
                          } | null;
                          MultiResidentialModifiers?: {
                            __typename?: 'MultiResidentialConstructionFeaturesType';
                            BasementPresent?: boolean | null;
                            ConstructionType?: string | null;
                            FloorLevel?: number | null;
                          } | null;
                          AverageAnnualLossValues?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                          AdditionalDetails: Array<{
                            __typename?: 'FloodDetailType';
                            Key?: string | null;
                            Value?: string | null;
                          }>;
                          Premium?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                        }>;
                        FloodMap: Array<{
                          __typename?: 'FloodMapType';
                          FloodEvent?: string | null;
                          Content?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    optaIclarifyCommercialConstructionData?: {
                      __typename?: 'OptaIclarifyCommercialConstructionDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Messages?: {
                        __typename?: 'Messages';
                        Message?: Array<string | null> | null;
                      } | null;
                      Property?: {
                        __typename?: 'CommercialPropertyType';
                        LotSize?: number | null;
                        LotSizeUnitOfMeasurement?: string | null;
                        PropertyType?: string | null;
                        Buildings?: {
                          __typename?: 'BuildingsType';
                          Building: Array<{
                            __typename?: 'BuildingType';
                            StructureID?: string | null;
                            ConstructionFeatures?: {
                              __typename?: 'CommercialConstructionFeaturesType';
                              CombustibilityClass?: string | null;
                              ConstructionClass?: string | null;
                              FloorHeight?: number | null;
                              FloorHeightUnitOfMeasurement?: string | null;
                              LoadingDockPresent?: boolean | null;
                              NumberOfStoreys?: number | null;
                              NumberOfUnits?: number | null;
                              TotalFloorArea?: number | null;
                              TotalFloorAreaUnitOfMeasurement?: string | null;
                              YearBuilt?: number | null;
                              Basement?: {
                                __typename?: 'CommercialBasementType';
                                BasementArea?: number | null;
                                BasementAreaUnitOfMeasurement?: string | null;
                                BasementPresent?: boolean | null;
                              } | null;
                              BuildingUpdates?: {
                                __typename?: 'BuildingUpdatesType';
                                BuildingUpdate?: Array<number | null> | null;
                              } | null;
                              CommercialExteriorWallTypes?: {
                                __typename?: 'CommercialExteriorWallTypesType';
                                ExteriorWallType?: Array<string | null> | null;
                              } | null;
                              Elevator?: {
                                __typename?: 'ElevatorType';
                                ElevatorCount?: number | null;
                                ElevatorPresent?: boolean | null;
                              } | null;
                              Heating?: {
                                __typename?: 'CommercialHeatingType';
                                HeatingFuelSources?: {
                                  __typename?: 'HeatingFuelSourcesType';
                                  HeatingFuelSource?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                HeatingTypes?: {
                                  __typename?: 'HeatingTypesType';
                                  HeatingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              Parking?: {
                                __typename?: 'CommercialParkingType';
                                ParkingPresent?: boolean | null;
                                ParkingTypes?: {
                                  __typename?: 'CommercialParkingTypesType';
                                  ParkingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              PlumbingTypes?: {
                                __typename?: 'CommercialPlumbingType';
                                PlumbingType?: Array<string | null> | null;
                              } | null;
                              RoofAttributes?: {
                                __typename?: 'RoofAttributesType';
                                RoofConstructions?: {
                                  __typename?: 'RoofConstructionsType';
                                  RoofConstruction?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                RoofSurfaces?: {
                                  __typename?: 'RoofSurfacesType';
                                  RoofSurface?: Array<string | null> | null;
                                } | null;
                                RoofTypes?: {
                                  __typename?: 'RoofTypesType';
                                  RoofType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              WaterHeater?: {
                                __typename?: 'CommercialWaterHeaterType';
                                WaterHeaterFuelType?: Array<
                                  string | null
                                > | null;
                                WaterHeaterType?: Array<string | null> | null;
                              } | null;
                              Wiring?: {
                                __typename?: 'CommercialWiringType';
                                WiringPanelTypes?: {
                                  __typename?: 'CommercialWiringPanelTypesType';
                                  WiringPanelType?: Array<string | null> | null;
                                } | null;
                                WiringTypes?: {
                                  __typename?: 'CommercialWiringTypesType';
                                  WiringType?: Array<string | null> | null;
                                } | null;
                              } | null;
                            } | null;
                            Protection?: {
                              __typename?: 'ProtectionType';
                              FireAlarmPresent?: boolean | null;
                              FireAlarmType?: string | null;
                              PhysicalSecurityPresent?: boolean | null;
                              SecurityAlarmPresent?: boolean | null;
                              SecurityAlarmType?: string | null;
                              SprinklerPresent?: boolean | null;
                              SprinklerTypeCode?: string | null;
                              PhysicalSecurityTypes?: {
                                __typename?: 'PhysicalSecurityTypesType';
                                PhysicalSecurityType?: Array<
                                  string | null
                                > | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                        Industry?: {
                          __typename?: 'BusinessIndustryType';
                          IBCCode?: string | null;
                          IBCIndustryDescription?: string | null;
                          SICIndustryDescription?: string | null;
                          SICCode?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    optaCommercialPerilScoreData?: {
                      __typename?: 'OptaCommercialPerilScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      PropertyPerilScores?: {
                        __typename?: 'PropertyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      CasualtyPerilScores?: {
                        __typename?: 'CasualtyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      AllPerilsScore?: {
                        __typename?: 'CommercialPerilScoreType';
                        LossCostScore?: number | null;
                        PredictedLossCost?: number | null;
                      } | null;
                    } | null;
                  } | null;
                  openingHours: Array<{
                    __typename?: 'OpeningHoursType';
                    day: number;
                    open?: any | null;
                    close?: any | null;
                  }>;
                } | null;
              }>;
              details?: {
                __typename?: 'AnswerInstanceDetailsType';
                lat?: number | null;
                lng?: number | null;
                images: Array<{
                  __typename?: 'GoogleImageType';
                  description: string;
                  shortDescription: string;
                  category: CoreGoogleImageCategoryChoices;
                  data?: string | null;
                }>;
                google?: {
                  __typename?: 'GoogleDetailsType';
                  placeId?: string | null;
                  rating?: number | null;
                  numberOfRatings?: number | null;
                } | null;
                opta?: {
                  __typename?: 'OPTADetailsType';
                  optaFusData?: {
                    __typename?: 'OptaFusDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Commercial?: {
                      __typename?: 'FusProductCommercialType';
                      SearchMessage?: string | null;
                      CommercialGrade: Array<{
                        __typename?: 'CommercialGradeType';
                        PublicFireProtectionClassification?: number | null;
                        WaterSystem?: string | null;
                        RiskSCORDetail: Array<{
                          __typename?: 'RiskSCORDetailType';
                          RiskSCOR?: number | null;
                          Type?: string | null;
                          Adjustment: Array<{
                            __typename?: 'RiskSCORAdjustmentType';
                            Adjustment?: string | null;
                            AdjustmentType?: string | null;
                          }>;
                        }>;
                      }>;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    Dwelling?: {
                      __typename?: 'FusProductDwellingType';
                      SearchMessage?: string | null;
                      DwellingGrades?: {
                        __typename?: 'DwellingGradesType';
                        AllOtherAreasGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        FirehallProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        HydrantProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                      } | null;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    EarthquakeCodes?: {
                      __typename?: 'EarthquakeCodesType';
                      SearchMessage?: string | null;
                      EarthquakeCode: Array<{
                        __typename?: 'EarthquakeCodeType';
                        Description?: string | null;
                        SystemName?: string | null;
                        Zone?: string | null;
                      }>;
                    } | null;
                    FusMaps?: {
                      __typename?: 'FusMapsType';
                      SearchMessage?: string | null;
                      FusMap: Array<{
                        __typename?: 'FusMapType';
                        Business?: string | null;
                        FusContent?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                    HydrantCounts?: {
                      __typename?: 'HydrantCountsType';
                      SearchMessage?: string | null;
                      HydrantCount: Array<{
                        __typename?: 'HydrantCountType';
                        NumberOfHydrants: number;
                        Ownership?: string | null;
                        RadiusMetres?: string | null;
                      }>;
                    } | null;
                  } | null;
                  optaFloodscoreData?: {
                    __typename?: 'OptaFloodScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    FloodScores?: {
                      __typename?: 'FloodScoresType';
                      FloodScore: Array<{
                        __typename?: 'FloodScoreType';
                        AverageAnnualLossScore?: number | null;
                        Deductible?: number | null;
                        Default?: boolean | null;
                        FloodScore?: number | null;
                        Limit?: number | null;
                        RateFactor?: number | null;
                        Zone?: string | null;
                        ResidentialModifiers?: {
                          __typename?: 'ResidentialConstructionFeaturesType';
                          ArchitecturalStyleType?: string | null;
                          AuxiliaryHeatingType?: string | null;
                          BathroomCount?: string | null;
                          CommercialIndicator?: string | null;
                          ExteriorWallType?: string | null;
                          FinishedBasement?: boolean | null;
                          FoundationType?: string | null;
                          GarageNumberOfCars?: number | null;
                          GarageType?: string | null;
                          KitchenCount?: string | null;
                          OutbuildingPresent?: string | null;
                          PlumbingType?: string | null;
                          PrimaryHeatingType?: string | null;
                          RoofType?: string | null;
                          SquareFootage?: number | null;
                          StoreyCount?: string | null;
                          SwimmingPoolType?: string | null;
                          YearBuilt?: string | null;
                          InspectionDetails?: {
                            __typename?: 'InspectionDetailsType';
                            InspectionDate?: any | null;
                            SponsoredInspection?: boolean | null;
                          } | null;
                        } | null;
                        MultiResidentialModifiers?: {
                          __typename?: 'MultiResidentialConstructionFeaturesType';
                          BasementPresent?: boolean | null;
                          ConstructionType?: string | null;
                          FloorLevel?: number | null;
                        } | null;
                        AverageAnnualLossValues?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                        AdditionalDetails: Array<{
                          __typename?: 'FloodDetailType';
                          Key?: string | null;
                          Value?: string | null;
                        }>;
                        Premium?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                      }>;
                      FloodMap: Array<{
                        __typename?: 'FloodMapType';
                        FloodEvent?: string | null;
                        Content?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  optaIclarifyCommercialConstructionData?: {
                    __typename?: 'OptaIclarifyCommercialConstructionDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Messages?: {
                      __typename?: 'Messages';
                      Message?: Array<string | null> | null;
                    } | null;
                    Property?: {
                      __typename?: 'CommercialPropertyType';
                      LotSize?: number | null;
                      LotSizeUnitOfMeasurement?: string | null;
                      PropertyType?: string | null;
                      Buildings?: {
                        __typename?: 'BuildingsType';
                        Building: Array<{
                          __typename?: 'BuildingType';
                          StructureID?: string | null;
                          ConstructionFeatures?: {
                            __typename?: 'CommercialConstructionFeaturesType';
                            CombustibilityClass?: string | null;
                            ConstructionClass?: string | null;
                            FloorHeight?: number | null;
                            FloorHeightUnitOfMeasurement?: string | null;
                            LoadingDockPresent?: boolean | null;
                            NumberOfStoreys?: number | null;
                            NumberOfUnits?: number | null;
                            TotalFloorArea?: number | null;
                            TotalFloorAreaUnitOfMeasurement?: string | null;
                            YearBuilt?: number | null;
                            Basement?: {
                              __typename?: 'CommercialBasementType';
                              BasementArea?: number | null;
                              BasementAreaUnitOfMeasurement?: string | null;
                              BasementPresent?: boolean | null;
                            } | null;
                            BuildingUpdates?: {
                              __typename?: 'BuildingUpdatesType';
                              BuildingUpdate?: Array<number | null> | null;
                            } | null;
                            CommercialExteriorWallTypes?: {
                              __typename?: 'CommercialExteriorWallTypesType';
                              ExteriorWallType?: Array<string | null> | null;
                            } | null;
                            Elevator?: {
                              __typename?: 'ElevatorType';
                              ElevatorCount?: number | null;
                              ElevatorPresent?: boolean | null;
                            } | null;
                            Heating?: {
                              __typename?: 'CommercialHeatingType';
                              HeatingFuelSources?: {
                                __typename?: 'HeatingFuelSourcesType';
                                HeatingFuelSource?: Array<string | null> | null;
                              } | null;
                              HeatingTypes?: {
                                __typename?: 'HeatingTypesType';
                                HeatingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            Parking?: {
                              __typename?: 'CommercialParkingType';
                              ParkingPresent?: boolean | null;
                              ParkingTypes?: {
                                __typename?: 'CommercialParkingTypesType';
                                ParkingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            PlumbingTypes?: {
                              __typename?: 'CommercialPlumbingType';
                              PlumbingType?: Array<string | null> | null;
                            } | null;
                            RoofAttributes?: {
                              __typename?: 'RoofAttributesType';
                              RoofConstructions?: {
                                __typename?: 'RoofConstructionsType';
                                RoofConstruction?: Array<string | null> | null;
                              } | null;
                              RoofSurfaces?: {
                                __typename?: 'RoofSurfacesType';
                                RoofSurface?: Array<string | null> | null;
                              } | null;
                              RoofTypes?: {
                                __typename?: 'RoofTypesType';
                                RoofType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            WaterHeater?: {
                              __typename?: 'CommercialWaterHeaterType';
                              WaterHeaterFuelType?: Array<string | null> | null;
                              WaterHeaterType?: Array<string | null> | null;
                            } | null;
                            Wiring?: {
                              __typename?: 'CommercialWiringType';
                              WiringPanelTypes?: {
                                __typename?: 'CommercialWiringPanelTypesType';
                                WiringPanelType?: Array<string | null> | null;
                              } | null;
                              WiringTypes?: {
                                __typename?: 'CommercialWiringTypesType';
                                WiringType?: Array<string | null> | null;
                              } | null;
                            } | null;
                          } | null;
                          Protection?: {
                            __typename?: 'ProtectionType';
                            FireAlarmPresent?: boolean | null;
                            FireAlarmType?: string | null;
                            PhysicalSecurityPresent?: boolean | null;
                            SecurityAlarmPresent?: boolean | null;
                            SecurityAlarmType?: string | null;
                            SprinklerPresent?: boolean | null;
                            SprinklerTypeCode?: string | null;
                            PhysicalSecurityTypes?: {
                              __typename?: 'PhysicalSecurityTypesType';
                              PhysicalSecurityType?: Array<
                                string | null
                              > | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                      Industry?: {
                        __typename?: 'BusinessIndustryType';
                        IBCCode?: string | null;
                        IBCIndustryDescription?: string | null;
                        SICIndustryDescription?: string | null;
                        SICCode?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  optaCommercialPerilScoreData?: {
                    __typename?: 'OptaCommercialPerilScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    PropertyPerilScores?: {
                      __typename?: 'PropertyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    CasualtyPerilScores?: {
                      __typename?: 'CasualtyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    AllPerilsScore?: {
                      __typename?: 'CommercialPerilScoreType';
                      LossCostScore?: number | null;
                      PredictedLossCost?: number | null;
                    } | null;
                  } | null;
                } | null;
                openingHours: Array<{
                  __typename?: 'OpeningHoursType';
                  day: number;
                  open?: any | null;
                  close?: any | null;
                }>;
              } | null;
            }>;
            details?: {
              __typename?: 'AnswerInstanceDetailsType';
              lat?: number | null;
              lng?: number | null;
              images: Array<{
                __typename?: 'GoogleImageType';
                description: string;
                shortDescription: string;
                category: CoreGoogleImageCategoryChoices;
                data?: string | null;
              }>;
              google?: {
                __typename?: 'GoogleDetailsType';
                placeId?: string | null;
                rating?: number | null;
                numberOfRatings?: number | null;
              } | null;
              opta?: {
                __typename?: 'OPTADetailsType';
                optaFusData?: {
                  __typename?: 'OptaFusDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Commercial?: {
                    __typename?: 'FusProductCommercialType';
                    SearchMessage?: string | null;
                    CommercialGrade: Array<{
                      __typename?: 'CommercialGradeType';
                      PublicFireProtectionClassification?: number | null;
                      WaterSystem?: string | null;
                      RiskSCORDetail: Array<{
                        __typename?: 'RiskSCORDetailType';
                        RiskSCOR?: number | null;
                        Type?: string | null;
                        Adjustment: Array<{
                          __typename?: 'RiskSCORAdjustmentType';
                          Adjustment?: string | null;
                          AdjustmentType?: string | null;
                        }>;
                      }>;
                    }>;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  Dwelling?: {
                    __typename?: 'FusProductDwellingType';
                    SearchMessage?: string | null;
                    DwellingGrades?: {
                      __typename?: 'DwellingGradesType';
                      AllOtherAreasGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      FirehallProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      HydrantProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                    } | null;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  EarthquakeCodes?: {
                    __typename?: 'EarthquakeCodesType';
                    SearchMessage?: string | null;
                    EarthquakeCode: Array<{
                      __typename?: 'EarthquakeCodeType';
                      Description?: string | null;
                      SystemName?: string | null;
                      Zone?: string | null;
                    }>;
                  } | null;
                  FusMaps?: {
                    __typename?: 'FusMapsType';
                    SearchMessage?: string | null;
                    FusMap: Array<{
                      __typename?: 'FusMapType';
                      Business?: string | null;
                      FusContent?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                  HydrantCounts?: {
                    __typename?: 'HydrantCountsType';
                    SearchMessage?: string | null;
                    HydrantCount: Array<{
                      __typename?: 'HydrantCountType';
                      NumberOfHydrants: number;
                      Ownership?: string | null;
                      RadiusMetres?: string | null;
                    }>;
                  } | null;
                } | null;
                optaFloodscoreData?: {
                  __typename?: 'OptaFloodScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  FloodScores?: {
                    __typename?: 'FloodScoresType';
                    FloodScore: Array<{
                      __typename?: 'FloodScoreType';
                      AverageAnnualLossScore?: number | null;
                      Deductible?: number | null;
                      Default?: boolean | null;
                      FloodScore?: number | null;
                      Limit?: number | null;
                      RateFactor?: number | null;
                      Zone?: string | null;
                      ResidentialModifiers?: {
                        __typename?: 'ResidentialConstructionFeaturesType';
                        ArchitecturalStyleType?: string | null;
                        AuxiliaryHeatingType?: string | null;
                        BathroomCount?: string | null;
                        CommercialIndicator?: string | null;
                        ExteriorWallType?: string | null;
                        FinishedBasement?: boolean | null;
                        FoundationType?: string | null;
                        GarageNumberOfCars?: number | null;
                        GarageType?: string | null;
                        KitchenCount?: string | null;
                        OutbuildingPresent?: string | null;
                        PlumbingType?: string | null;
                        PrimaryHeatingType?: string | null;
                        RoofType?: string | null;
                        SquareFootage?: number | null;
                        StoreyCount?: string | null;
                        SwimmingPoolType?: string | null;
                        YearBuilt?: string | null;
                        InspectionDetails?: {
                          __typename?: 'InspectionDetailsType';
                          InspectionDate?: any | null;
                          SponsoredInspection?: boolean | null;
                        } | null;
                      } | null;
                      MultiResidentialModifiers?: {
                        __typename?: 'MultiResidentialConstructionFeaturesType';
                        BasementPresent?: boolean | null;
                        ConstructionType?: string | null;
                        FloorLevel?: number | null;
                      } | null;
                      AverageAnnualLossValues?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                      AdditionalDetails: Array<{
                        __typename?: 'FloodDetailType';
                        Key?: string | null;
                        Value?: string | null;
                      }>;
                      Premium?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                    }>;
                    FloodMap: Array<{
                      __typename?: 'FloodMapType';
                      FloodEvent?: string | null;
                      Content?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                } | null;
                optaIclarifyCommercialConstructionData?: {
                  __typename?: 'OptaIclarifyCommercialConstructionDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Messages?: {
                    __typename?: 'Messages';
                    Message?: Array<string | null> | null;
                  } | null;
                  Property?: {
                    __typename?: 'CommercialPropertyType';
                    LotSize?: number | null;
                    LotSizeUnitOfMeasurement?: string | null;
                    PropertyType?: string | null;
                    Buildings?: {
                      __typename?: 'BuildingsType';
                      Building: Array<{
                        __typename?: 'BuildingType';
                        StructureID?: string | null;
                        ConstructionFeatures?: {
                          __typename?: 'CommercialConstructionFeaturesType';
                          CombustibilityClass?: string | null;
                          ConstructionClass?: string | null;
                          FloorHeight?: number | null;
                          FloorHeightUnitOfMeasurement?: string | null;
                          LoadingDockPresent?: boolean | null;
                          NumberOfStoreys?: number | null;
                          NumberOfUnits?: number | null;
                          TotalFloorArea?: number | null;
                          TotalFloorAreaUnitOfMeasurement?: string | null;
                          YearBuilt?: number | null;
                          Basement?: {
                            __typename?: 'CommercialBasementType';
                            BasementArea?: number | null;
                            BasementAreaUnitOfMeasurement?: string | null;
                            BasementPresent?: boolean | null;
                          } | null;
                          BuildingUpdates?: {
                            __typename?: 'BuildingUpdatesType';
                            BuildingUpdate?: Array<number | null> | null;
                          } | null;
                          CommercialExteriorWallTypes?: {
                            __typename?: 'CommercialExteriorWallTypesType';
                            ExteriorWallType?: Array<string | null> | null;
                          } | null;
                          Elevator?: {
                            __typename?: 'ElevatorType';
                            ElevatorCount?: number | null;
                            ElevatorPresent?: boolean | null;
                          } | null;
                          Heating?: {
                            __typename?: 'CommercialHeatingType';
                            HeatingFuelSources?: {
                              __typename?: 'HeatingFuelSourcesType';
                              HeatingFuelSource?: Array<string | null> | null;
                            } | null;
                            HeatingTypes?: {
                              __typename?: 'HeatingTypesType';
                              HeatingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          Parking?: {
                            __typename?: 'CommercialParkingType';
                            ParkingPresent?: boolean | null;
                            ParkingTypes?: {
                              __typename?: 'CommercialParkingTypesType';
                              ParkingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          PlumbingTypes?: {
                            __typename?: 'CommercialPlumbingType';
                            PlumbingType?: Array<string | null> | null;
                          } | null;
                          RoofAttributes?: {
                            __typename?: 'RoofAttributesType';
                            RoofConstructions?: {
                              __typename?: 'RoofConstructionsType';
                              RoofConstruction?: Array<string | null> | null;
                            } | null;
                            RoofSurfaces?: {
                              __typename?: 'RoofSurfacesType';
                              RoofSurface?: Array<string | null> | null;
                            } | null;
                            RoofTypes?: {
                              __typename?: 'RoofTypesType';
                              RoofType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          WaterHeater?: {
                            __typename?: 'CommercialWaterHeaterType';
                            WaterHeaterFuelType?: Array<string | null> | null;
                            WaterHeaterType?: Array<string | null> | null;
                          } | null;
                          Wiring?: {
                            __typename?: 'CommercialWiringType';
                            WiringPanelTypes?: {
                              __typename?: 'CommercialWiringPanelTypesType';
                              WiringPanelType?: Array<string | null> | null;
                            } | null;
                            WiringTypes?: {
                              __typename?: 'CommercialWiringTypesType';
                              WiringType?: Array<string | null> | null;
                            } | null;
                          } | null;
                        } | null;
                        Protection?: {
                          __typename?: 'ProtectionType';
                          FireAlarmPresent?: boolean | null;
                          FireAlarmType?: string | null;
                          PhysicalSecurityPresent?: boolean | null;
                          SecurityAlarmPresent?: boolean | null;
                          SecurityAlarmType?: string | null;
                          SprinklerPresent?: boolean | null;
                          SprinklerTypeCode?: string | null;
                          PhysicalSecurityTypes?: {
                            __typename?: 'PhysicalSecurityTypesType';
                            PhysicalSecurityType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                    Industry?: {
                      __typename?: 'BusinessIndustryType';
                      IBCCode?: string | null;
                      IBCIndustryDescription?: string | null;
                      SICIndustryDescription?: string | null;
                      SICCode?: string | null;
                    } | null;
                  } | null;
                } | null;
                optaCommercialPerilScoreData?: {
                  __typename?: 'OptaCommercialPerilScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  PropertyPerilScores?: {
                    __typename?: 'PropertyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  CasualtyPerilScores?: {
                    __typename?: 'CasualtyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  AllPerilsScore?: {
                    __typename?: 'CommercialPerilScoreType';
                    LossCostScore?: number | null;
                    PredictedLossCost?: number | null;
                  } | null;
                } | null;
              } | null;
              openingHours: Array<{
                __typename?: 'OpeningHoursType';
                day: number;
                open?: any | null;
                close?: any | null;
              }>;
            } | null;
          }>;
          details?: {
            __typename?: 'AnswerInstanceDetailsType';
            lat?: number | null;
            lng?: number | null;
            images: Array<{
              __typename?: 'GoogleImageType';
              description: string;
              shortDescription: string;
              category: CoreGoogleImageCategoryChoices;
              data?: string | null;
            }>;
            google?: {
              __typename?: 'GoogleDetailsType';
              placeId?: string | null;
              rating?: number | null;
              numberOfRatings?: number | null;
            } | null;
            opta?: {
              __typename?: 'OPTADetailsType';
              optaFusData?: {
                __typename?: 'OptaFusDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                Commercial?: {
                  __typename?: 'FusProductCommercialType';
                  SearchMessage?: string | null;
                  CommercialGrade: Array<{
                    __typename?: 'CommercialGradeType';
                    PublicFireProtectionClassification?: number | null;
                    WaterSystem?: string | null;
                    RiskSCORDetail: Array<{
                      __typename?: 'RiskSCORDetailType';
                      RiskSCOR?: number | null;
                      Type?: string | null;
                      Adjustment: Array<{
                        __typename?: 'RiskSCORAdjustmentType';
                        Adjustment?: string | null;
                        AdjustmentType?: string | null;
                      }>;
                    }>;
                  }>;
                  RespondingFirehall?: {
                    __typename?: 'FirehallType';
                    Address?: string | null;
                    GoverningAgency?: string | null;
                    GradingDate?: any | null;
                    Name?: string | null;
                    PumperFlowImperialGallonsPerMinute?: number | null;
                    RoadDistanceKilometres?: number | null;
                    SuperiorTankerServiceAccreditationDate?: any | null;
                    FirefightersPerFireStation?: {
                      __typename?: 'FirefightersPerFireStationType';
                      FullTime?: number | null;
                      Volunteer?: number | null;
                    } | null;
                    ProtectionGradeTerritories?: {
                      __typename?: 'ProtectedGradeTerritoriesType';
                      Commercial?: string | null;
                      Dwelling?: string | null;
                    } | null;
                  } | null;
                } | null;
                Dwelling?: {
                  __typename?: 'FusProductDwellingType';
                  SearchMessage?: string | null;
                  DwellingGrades?: {
                    __typename?: 'DwellingGradesType';
                    AllOtherAreasGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                    FirehallProtectedGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                    HydrantProtectedGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                  } | null;
                  RespondingFirehall?: {
                    __typename?: 'FirehallType';
                    Address?: string | null;
                    GoverningAgency?: string | null;
                    GradingDate?: any | null;
                    Name?: string | null;
                    PumperFlowImperialGallonsPerMinute?: number | null;
                    RoadDistanceKilometres?: number | null;
                    SuperiorTankerServiceAccreditationDate?: any | null;
                    FirefightersPerFireStation?: {
                      __typename?: 'FirefightersPerFireStationType';
                      FullTime?: number | null;
                      Volunteer?: number | null;
                    } | null;
                    ProtectionGradeTerritories?: {
                      __typename?: 'ProtectedGradeTerritoriesType';
                      Commercial?: string | null;
                      Dwelling?: string | null;
                    } | null;
                  } | null;
                } | null;
                EarthquakeCodes?: {
                  __typename?: 'EarthquakeCodesType';
                  SearchMessage?: string | null;
                  EarthquakeCode: Array<{
                    __typename?: 'EarthquakeCodeType';
                    Description?: string | null;
                    SystemName?: string | null;
                    Zone?: string | null;
                  }>;
                } | null;
                FusMaps?: {
                  __typename?: 'FusMapsType';
                  SearchMessage?: string | null;
                  FusMap: Array<{
                    __typename?: 'FusMapType';
                    Business?: string | null;
                    FusContent?: {
                      __typename?: 'ContentType';
                      ContentURL?: string | null;
                      Expiry?: any | null;
                      MIMEType?: string | null;
                    } | null;
                  }>;
                } | null;
                HydrantCounts?: {
                  __typename?: 'HydrantCountsType';
                  SearchMessage?: string | null;
                  HydrantCount: Array<{
                    __typename?: 'HydrantCountType';
                    NumberOfHydrants: number;
                    Ownership?: string | null;
                    RadiusMetres?: string | null;
                  }>;
                } | null;
              } | null;
              optaFloodscoreData?: {
                __typename?: 'OptaFloodScoreDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                FloodScores?: {
                  __typename?: 'FloodScoresType';
                  FloodScore: Array<{
                    __typename?: 'FloodScoreType';
                    AverageAnnualLossScore?: number | null;
                    Deductible?: number | null;
                    Default?: boolean | null;
                    FloodScore?: number | null;
                    Limit?: number | null;
                    RateFactor?: number | null;
                    Zone?: string | null;
                    ResidentialModifiers?: {
                      __typename?: 'ResidentialConstructionFeaturesType';
                      ArchitecturalStyleType?: string | null;
                      AuxiliaryHeatingType?: string | null;
                      BathroomCount?: string | null;
                      CommercialIndicator?: string | null;
                      ExteriorWallType?: string | null;
                      FinishedBasement?: boolean | null;
                      FoundationType?: string | null;
                      GarageNumberOfCars?: number | null;
                      GarageType?: string | null;
                      KitchenCount?: string | null;
                      OutbuildingPresent?: string | null;
                      PlumbingType?: string | null;
                      PrimaryHeatingType?: string | null;
                      RoofType?: string | null;
                      SquareFootage?: number | null;
                      StoreyCount?: string | null;
                      SwimmingPoolType?: string | null;
                      YearBuilt?: string | null;
                      InspectionDetails?: {
                        __typename?: 'InspectionDetailsType';
                        InspectionDate?: any | null;
                        SponsoredInspection?: boolean | null;
                      } | null;
                    } | null;
                    MultiResidentialModifiers?: {
                      __typename?: 'MultiResidentialConstructionFeaturesType';
                      BasementPresent?: boolean | null;
                      ConstructionType?: string | null;
                      FloorLevel?: number | null;
                    } | null;
                    AverageAnnualLossValues?: {
                      __typename?: 'FloodValueType';
                      Building?: number | null;
                      Contents?: number | null;
                      Total?: number | null;
                    } | null;
                    AdditionalDetails: Array<{
                      __typename?: 'FloodDetailType';
                      Key?: string | null;
                      Value?: string | null;
                    }>;
                    Premium?: {
                      __typename?: 'FloodValueType';
                      Building?: number | null;
                      Contents?: number | null;
                      Total?: number | null;
                    } | null;
                  }>;
                  FloodMap: Array<{
                    __typename?: 'FloodMapType';
                    FloodEvent?: string | null;
                    Content?: {
                      __typename?: 'ContentType';
                      ContentURL?: string | null;
                      Expiry?: any | null;
                      MIMEType?: string | null;
                    } | null;
                  }>;
                } | null;
              } | null;
              optaIclarifyCommercialConstructionData?: {
                __typename?: 'OptaIclarifyCommercialConstructionDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                Messages?: {
                  __typename?: 'Messages';
                  Message?: Array<string | null> | null;
                } | null;
                Property?: {
                  __typename?: 'CommercialPropertyType';
                  LotSize?: number | null;
                  LotSizeUnitOfMeasurement?: string | null;
                  PropertyType?: string | null;
                  Buildings?: {
                    __typename?: 'BuildingsType';
                    Building: Array<{
                      __typename?: 'BuildingType';
                      StructureID?: string | null;
                      ConstructionFeatures?: {
                        __typename?: 'CommercialConstructionFeaturesType';
                        CombustibilityClass?: string | null;
                        ConstructionClass?: string | null;
                        FloorHeight?: number | null;
                        FloorHeightUnitOfMeasurement?: string | null;
                        LoadingDockPresent?: boolean | null;
                        NumberOfStoreys?: number | null;
                        NumberOfUnits?: number | null;
                        TotalFloorArea?: number | null;
                        TotalFloorAreaUnitOfMeasurement?: string | null;
                        YearBuilt?: number | null;
                        Basement?: {
                          __typename?: 'CommercialBasementType';
                          BasementArea?: number | null;
                          BasementAreaUnitOfMeasurement?: string | null;
                          BasementPresent?: boolean | null;
                        } | null;
                        BuildingUpdates?: {
                          __typename?: 'BuildingUpdatesType';
                          BuildingUpdate?: Array<number | null> | null;
                        } | null;
                        CommercialExteriorWallTypes?: {
                          __typename?: 'CommercialExteriorWallTypesType';
                          ExteriorWallType?: Array<string | null> | null;
                        } | null;
                        Elevator?: {
                          __typename?: 'ElevatorType';
                          ElevatorCount?: number | null;
                          ElevatorPresent?: boolean | null;
                        } | null;
                        Heating?: {
                          __typename?: 'CommercialHeatingType';
                          HeatingFuelSources?: {
                            __typename?: 'HeatingFuelSourcesType';
                            HeatingFuelSource?: Array<string | null> | null;
                          } | null;
                          HeatingTypes?: {
                            __typename?: 'HeatingTypesType';
                            HeatingType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        Parking?: {
                          __typename?: 'CommercialParkingType';
                          ParkingPresent?: boolean | null;
                          ParkingTypes?: {
                            __typename?: 'CommercialParkingTypesType';
                            ParkingType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        PlumbingTypes?: {
                          __typename?: 'CommercialPlumbingType';
                          PlumbingType?: Array<string | null> | null;
                        } | null;
                        RoofAttributes?: {
                          __typename?: 'RoofAttributesType';
                          RoofConstructions?: {
                            __typename?: 'RoofConstructionsType';
                            RoofConstruction?: Array<string | null> | null;
                          } | null;
                          RoofSurfaces?: {
                            __typename?: 'RoofSurfacesType';
                            RoofSurface?: Array<string | null> | null;
                          } | null;
                          RoofTypes?: {
                            __typename?: 'RoofTypesType';
                            RoofType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        WaterHeater?: {
                          __typename?: 'CommercialWaterHeaterType';
                          WaterHeaterFuelType?: Array<string | null> | null;
                          WaterHeaterType?: Array<string | null> | null;
                        } | null;
                        Wiring?: {
                          __typename?: 'CommercialWiringType';
                          WiringPanelTypes?: {
                            __typename?: 'CommercialWiringPanelTypesType';
                            WiringPanelType?: Array<string | null> | null;
                          } | null;
                          WiringTypes?: {
                            __typename?: 'CommercialWiringTypesType';
                            WiringType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      } | null;
                      Protection?: {
                        __typename?: 'ProtectionType';
                        FireAlarmPresent?: boolean | null;
                        FireAlarmType?: string | null;
                        PhysicalSecurityPresent?: boolean | null;
                        SecurityAlarmPresent?: boolean | null;
                        SecurityAlarmType?: string | null;
                        SprinklerPresent?: boolean | null;
                        SprinklerTypeCode?: string | null;
                        PhysicalSecurityTypes?: {
                          __typename?: 'PhysicalSecurityTypesType';
                          PhysicalSecurityType?: Array<string | null> | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                  Industry?: {
                    __typename?: 'BusinessIndustryType';
                    IBCCode?: string | null;
                    IBCIndustryDescription?: string | null;
                    SICIndustryDescription?: string | null;
                    SICCode?: string | null;
                  } | null;
                } | null;
              } | null;
              optaCommercialPerilScoreData?: {
                __typename?: 'OptaCommercialPerilScoreDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                PropertyPerilScores?: {
                  __typename?: 'PropertyCommercialPerilsType';
                  Peril?: Array<{
                    __typename?: 'PerilDetailsType';
                    LossCostScore?: number | null;
                    PerilType?: string | null;
                    PredictedLossCost?: number | null;
                  } | null> | null;
                } | null;
                CasualtyPerilScores?: {
                  __typename?: 'CasualtyCommercialPerilsType';
                  Peril?: Array<{
                    __typename?: 'PerilDetailsType';
                    LossCostScore?: number | null;
                    PerilType?: string | null;
                    PredictedLossCost?: number | null;
                  } | null> | null;
                } | null;
                AllPerilsScore?: {
                  __typename?: 'CommercialPerilScoreType';
                  LossCostScore?: number | null;
                  PredictedLossCost?: number | null;
                } | null;
              } | null;
            } | null;
            openingHours: Array<{
              __typename?: 'OpeningHoursType';
              day: number;
              open?: any | null;
              close?: any | null;
            }>;
          } | null;
        }>;
        details?: {
          __typename?: 'AnswerInstanceDetailsType';
          lat?: number | null;
          lng?: number | null;
          images: Array<{
            __typename?: 'GoogleImageType';
            description: string;
            shortDescription: string;
            category: CoreGoogleImageCategoryChoices;
            data?: string | null;
          }>;
          google?: {
            __typename?: 'GoogleDetailsType';
            placeId?: string | null;
            rating?: number | null;
            numberOfRatings?: number | null;
          } | null;
          opta?: {
            __typename?: 'OPTADetailsType';
            optaFusData?: {
              __typename?: 'OptaFusDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              Commercial?: {
                __typename?: 'FusProductCommercialType';
                SearchMessage?: string | null;
                CommercialGrade: Array<{
                  __typename?: 'CommercialGradeType';
                  PublicFireProtectionClassification?: number | null;
                  WaterSystem?: string | null;
                  RiskSCORDetail: Array<{
                    __typename?: 'RiskSCORDetailType';
                    RiskSCOR?: number | null;
                    Type?: string | null;
                    Adjustment: Array<{
                      __typename?: 'RiskSCORAdjustmentType';
                      Adjustment?: string | null;
                      AdjustmentType?: string | null;
                    }>;
                  }>;
                }>;
                RespondingFirehall?: {
                  __typename?: 'FirehallType';
                  Address?: string | null;
                  GoverningAgency?: string | null;
                  GradingDate?: any | null;
                  Name?: string | null;
                  PumperFlowImperialGallonsPerMinute?: number | null;
                  RoadDistanceKilometres?: number | null;
                  SuperiorTankerServiceAccreditationDate?: any | null;
                  FirefightersPerFireStation?: {
                    __typename?: 'FirefightersPerFireStationType';
                    FullTime?: number | null;
                    Volunteer?: number | null;
                  } | null;
                  ProtectionGradeTerritories?: {
                    __typename?: 'ProtectedGradeTerritoriesType';
                    Commercial?: string | null;
                    Dwelling?: string | null;
                  } | null;
                } | null;
              } | null;
              Dwelling?: {
                __typename?: 'FusProductDwellingType';
                SearchMessage?: string | null;
                DwellingGrades?: {
                  __typename?: 'DwellingGradesType';
                  AllOtherAreasGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                  FirehallProtectedGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                  HydrantProtectedGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                } | null;
                RespondingFirehall?: {
                  __typename?: 'FirehallType';
                  Address?: string | null;
                  GoverningAgency?: string | null;
                  GradingDate?: any | null;
                  Name?: string | null;
                  PumperFlowImperialGallonsPerMinute?: number | null;
                  RoadDistanceKilometres?: number | null;
                  SuperiorTankerServiceAccreditationDate?: any | null;
                  FirefightersPerFireStation?: {
                    __typename?: 'FirefightersPerFireStationType';
                    FullTime?: number | null;
                    Volunteer?: number | null;
                  } | null;
                  ProtectionGradeTerritories?: {
                    __typename?: 'ProtectedGradeTerritoriesType';
                    Commercial?: string | null;
                    Dwelling?: string | null;
                  } | null;
                } | null;
              } | null;
              EarthquakeCodes?: {
                __typename?: 'EarthquakeCodesType';
                SearchMessage?: string | null;
                EarthquakeCode: Array<{
                  __typename?: 'EarthquakeCodeType';
                  Description?: string | null;
                  SystemName?: string | null;
                  Zone?: string | null;
                }>;
              } | null;
              FusMaps?: {
                __typename?: 'FusMapsType';
                SearchMessage?: string | null;
                FusMap: Array<{
                  __typename?: 'FusMapType';
                  Business?: string | null;
                  FusContent?: {
                    __typename?: 'ContentType';
                    ContentURL?: string | null;
                    Expiry?: any | null;
                    MIMEType?: string | null;
                  } | null;
                }>;
              } | null;
              HydrantCounts?: {
                __typename?: 'HydrantCountsType';
                SearchMessage?: string | null;
                HydrantCount: Array<{
                  __typename?: 'HydrantCountType';
                  NumberOfHydrants: number;
                  Ownership?: string | null;
                  RadiusMetres?: string | null;
                }>;
              } | null;
            } | null;
            optaFloodscoreData?: {
              __typename?: 'OptaFloodScoreDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              FloodScores?: {
                __typename?: 'FloodScoresType';
                FloodScore: Array<{
                  __typename?: 'FloodScoreType';
                  AverageAnnualLossScore?: number | null;
                  Deductible?: number | null;
                  Default?: boolean | null;
                  FloodScore?: number | null;
                  Limit?: number | null;
                  RateFactor?: number | null;
                  Zone?: string | null;
                  ResidentialModifiers?: {
                    __typename?: 'ResidentialConstructionFeaturesType';
                    ArchitecturalStyleType?: string | null;
                    AuxiliaryHeatingType?: string | null;
                    BathroomCount?: string | null;
                    CommercialIndicator?: string | null;
                    ExteriorWallType?: string | null;
                    FinishedBasement?: boolean | null;
                    FoundationType?: string | null;
                    GarageNumberOfCars?: number | null;
                    GarageType?: string | null;
                    KitchenCount?: string | null;
                    OutbuildingPresent?: string | null;
                    PlumbingType?: string | null;
                    PrimaryHeatingType?: string | null;
                    RoofType?: string | null;
                    SquareFootage?: number | null;
                    StoreyCount?: string | null;
                    SwimmingPoolType?: string | null;
                    YearBuilt?: string | null;
                    InspectionDetails?: {
                      __typename?: 'InspectionDetailsType';
                      InspectionDate?: any | null;
                      SponsoredInspection?: boolean | null;
                    } | null;
                  } | null;
                  MultiResidentialModifiers?: {
                    __typename?: 'MultiResidentialConstructionFeaturesType';
                    BasementPresent?: boolean | null;
                    ConstructionType?: string | null;
                    FloorLevel?: number | null;
                  } | null;
                  AverageAnnualLossValues?: {
                    __typename?: 'FloodValueType';
                    Building?: number | null;
                    Contents?: number | null;
                    Total?: number | null;
                  } | null;
                  AdditionalDetails: Array<{
                    __typename?: 'FloodDetailType';
                    Key?: string | null;
                    Value?: string | null;
                  }>;
                  Premium?: {
                    __typename?: 'FloodValueType';
                    Building?: number | null;
                    Contents?: number | null;
                    Total?: number | null;
                  } | null;
                }>;
                FloodMap: Array<{
                  __typename?: 'FloodMapType';
                  FloodEvent?: string | null;
                  Content?: {
                    __typename?: 'ContentType';
                    ContentURL?: string | null;
                    Expiry?: any | null;
                    MIMEType?: string | null;
                  } | null;
                }>;
              } | null;
            } | null;
            optaIclarifyCommercialConstructionData?: {
              __typename?: 'OptaIclarifyCommercialConstructionDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              Messages?: {
                __typename?: 'Messages';
                Message?: Array<string | null> | null;
              } | null;
              Property?: {
                __typename?: 'CommercialPropertyType';
                LotSize?: number | null;
                LotSizeUnitOfMeasurement?: string | null;
                PropertyType?: string | null;
                Buildings?: {
                  __typename?: 'BuildingsType';
                  Building: Array<{
                    __typename?: 'BuildingType';
                    StructureID?: string | null;
                    ConstructionFeatures?: {
                      __typename?: 'CommercialConstructionFeaturesType';
                      CombustibilityClass?: string | null;
                      ConstructionClass?: string | null;
                      FloorHeight?: number | null;
                      FloorHeightUnitOfMeasurement?: string | null;
                      LoadingDockPresent?: boolean | null;
                      NumberOfStoreys?: number | null;
                      NumberOfUnits?: number | null;
                      TotalFloorArea?: number | null;
                      TotalFloorAreaUnitOfMeasurement?: string | null;
                      YearBuilt?: number | null;
                      Basement?: {
                        __typename?: 'CommercialBasementType';
                        BasementArea?: number | null;
                        BasementAreaUnitOfMeasurement?: string | null;
                        BasementPresent?: boolean | null;
                      } | null;
                      BuildingUpdates?: {
                        __typename?: 'BuildingUpdatesType';
                        BuildingUpdate?: Array<number | null> | null;
                      } | null;
                      CommercialExteriorWallTypes?: {
                        __typename?: 'CommercialExteriorWallTypesType';
                        ExteriorWallType?: Array<string | null> | null;
                      } | null;
                      Elevator?: {
                        __typename?: 'ElevatorType';
                        ElevatorCount?: number | null;
                        ElevatorPresent?: boolean | null;
                      } | null;
                      Heating?: {
                        __typename?: 'CommercialHeatingType';
                        HeatingFuelSources?: {
                          __typename?: 'HeatingFuelSourcesType';
                          HeatingFuelSource?: Array<string | null> | null;
                        } | null;
                        HeatingTypes?: {
                          __typename?: 'HeatingTypesType';
                          HeatingType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      Parking?: {
                        __typename?: 'CommercialParkingType';
                        ParkingPresent?: boolean | null;
                        ParkingTypes?: {
                          __typename?: 'CommercialParkingTypesType';
                          ParkingType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      PlumbingTypes?: {
                        __typename?: 'CommercialPlumbingType';
                        PlumbingType?: Array<string | null> | null;
                      } | null;
                      RoofAttributes?: {
                        __typename?: 'RoofAttributesType';
                        RoofConstructions?: {
                          __typename?: 'RoofConstructionsType';
                          RoofConstruction?: Array<string | null> | null;
                        } | null;
                        RoofSurfaces?: {
                          __typename?: 'RoofSurfacesType';
                          RoofSurface?: Array<string | null> | null;
                        } | null;
                        RoofTypes?: {
                          __typename?: 'RoofTypesType';
                          RoofType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      WaterHeater?: {
                        __typename?: 'CommercialWaterHeaterType';
                        WaterHeaterFuelType?: Array<string | null> | null;
                        WaterHeaterType?: Array<string | null> | null;
                      } | null;
                      Wiring?: {
                        __typename?: 'CommercialWiringType';
                        WiringPanelTypes?: {
                          __typename?: 'CommercialWiringPanelTypesType';
                          WiringPanelType?: Array<string | null> | null;
                        } | null;
                        WiringTypes?: {
                          __typename?: 'CommercialWiringTypesType';
                          WiringType?: Array<string | null> | null;
                        } | null;
                      } | null;
                    } | null;
                    Protection?: {
                      __typename?: 'ProtectionType';
                      FireAlarmPresent?: boolean | null;
                      FireAlarmType?: string | null;
                      PhysicalSecurityPresent?: boolean | null;
                      SecurityAlarmPresent?: boolean | null;
                      SecurityAlarmType?: string | null;
                      SprinklerPresent?: boolean | null;
                      SprinklerTypeCode?: string | null;
                      PhysicalSecurityTypes?: {
                        __typename?: 'PhysicalSecurityTypesType';
                        PhysicalSecurityType?: Array<string | null> | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
                Industry?: {
                  __typename?: 'BusinessIndustryType';
                  IBCCode?: string | null;
                  IBCIndustryDescription?: string | null;
                  SICIndustryDescription?: string | null;
                  SICCode?: string | null;
                } | null;
              } | null;
            } | null;
            optaCommercialPerilScoreData?: {
              __typename?: 'OptaCommercialPerilScoreDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              PropertyPerilScores?: {
                __typename?: 'PropertyCommercialPerilsType';
                Peril?: Array<{
                  __typename?: 'PerilDetailsType';
                  LossCostScore?: number | null;
                  PerilType?: string | null;
                  PredictedLossCost?: number | null;
                } | null> | null;
              } | null;
              CasualtyPerilScores?: {
                __typename?: 'CasualtyCommercialPerilsType';
                Peril?: Array<{
                  __typename?: 'PerilDetailsType';
                  LossCostScore?: number | null;
                  PerilType?: string | null;
                  PredictedLossCost?: number | null;
                } | null> | null;
              } | null;
              AllPerilsScore?: {
                __typename?: 'CommercialPerilScoreType';
                LossCostScore?: number | null;
                PredictedLossCost?: number | null;
              } | null;
            } | null;
          } | null;
          openingHours: Array<{
            __typename?: 'OpeningHoursType';
            day: number;
            open?: any | null;
            close?: any | null;
          }>;
        } | null;
      }>;
      details?: {
        __typename?: 'AnswerInstanceDetailsType';
        lat?: number | null;
        lng?: number | null;
        images: Array<{
          __typename?: 'GoogleImageType';
          description: string;
          shortDescription: string;
          category: CoreGoogleImageCategoryChoices;
          data?: string | null;
        }>;
        google?: {
          __typename?: 'GoogleDetailsType';
          placeId?: string | null;
          rating?: number | null;
          numberOfRatings?: number | null;
        } | null;
        opta?: {
          __typename?: 'OPTADetailsType';
          optaFusData?: {
            __typename?: 'OptaFusDataType';
            Address?: {
              __typename?: 'OptaAddressType';
              StreetAddress?: string | null;
              StreetNumber?: string | null;
              StreetName?: string | null;
              StreetType?: string | null;
              StreetDirection?: string | null;
              UnitNumber?: string | null;
              Municipality?: string | null;
              Province?: string | null;
              PostalCode?: string | null;
              LegalDescription?: {
                __typename?: 'LegalDescriptionType';
                Quarter?: string | null;
                LegalSubdivision?: string | null;
                Section?: string | null;
                Township?: string | null;
                Range?: string | null;
                Meridian?: string | null;
              } | null;
            } | null;
            Commercial?: {
              __typename?: 'FusProductCommercialType';
              SearchMessage?: string | null;
              CommercialGrade: Array<{
                __typename?: 'CommercialGradeType';
                PublicFireProtectionClassification?: number | null;
                WaterSystem?: string | null;
                RiskSCORDetail: Array<{
                  __typename?: 'RiskSCORDetailType';
                  RiskSCOR?: number | null;
                  Type?: string | null;
                  Adjustment: Array<{
                    __typename?: 'RiskSCORAdjustmentType';
                    Adjustment?: string | null;
                    AdjustmentType?: string | null;
                  }>;
                }>;
              }>;
              RespondingFirehall?: {
                __typename?: 'FirehallType';
                Address?: string | null;
                GoverningAgency?: string | null;
                GradingDate?: any | null;
                Name?: string | null;
                PumperFlowImperialGallonsPerMinute?: number | null;
                RoadDistanceKilometres?: number | null;
                SuperiorTankerServiceAccreditationDate?: any | null;
                FirefightersPerFireStation?: {
                  __typename?: 'FirefightersPerFireStationType';
                  FullTime?: number | null;
                  Volunteer?: number | null;
                } | null;
                ProtectionGradeTerritories?: {
                  __typename?: 'ProtectedGradeTerritoriesType';
                  Commercial?: string | null;
                  Dwelling?: string | null;
                } | null;
              } | null;
            } | null;
            Dwelling?: {
              __typename?: 'FusProductDwellingType';
              SearchMessage?: string | null;
              DwellingGrades?: {
                __typename?: 'DwellingGradesType';
                AllOtherAreasGrade: Array<{
                  __typename?: 'DwellingProtectedGradeType';
                  Grade?: string | null;
                  WaterSystem?: string | null;
                }>;
                FirehallProtectedGrade: Array<{
                  __typename?: 'DwellingProtectedGradeType';
                  Grade?: string | null;
                  WaterSystem?: string | null;
                }>;
                HydrantProtectedGrade: Array<{
                  __typename?: 'DwellingProtectedGradeType';
                  Grade?: string | null;
                  WaterSystem?: string | null;
                }>;
              } | null;
              RespondingFirehall?: {
                __typename?: 'FirehallType';
                Address?: string | null;
                GoverningAgency?: string | null;
                GradingDate?: any | null;
                Name?: string | null;
                PumperFlowImperialGallonsPerMinute?: number | null;
                RoadDistanceKilometres?: number | null;
                SuperiorTankerServiceAccreditationDate?: any | null;
                FirefightersPerFireStation?: {
                  __typename?: 'FirefightersPerFireStationType';
                  FullTime?: number | null;
                  Volunteer?: number | null;
                } | null;
                ProtectionGradeTerritories?: {
                  __typename?: 'ProtectedGradeTerritoriesType';
                  Commercial?: string | null;
                  Dwelling?: string | null;
                } | null;
              } | null;
            } | null;
            EarthquakeCodes?: {
              __typename?: 'EarthquakeCodesType';
              SearchMessage?: string | null;
              EarthquakeCode: Array<{
                __typename?: 'EarthquakeCodeType';
                Description?: string | null;
                SystemName?: string | null;
                Zone?: string | null;
              }>;
            } | null;
            FusMaps?: {
              __typename?: 'FusMapsType';
              SearchMessage?: string | null;
              FusMap: Array<{
                __typename?: 'FusMapType';
                Business?: string | null;
                FusContent?: {
                  __typename?: 'ContentType';
                  ContentURL?: string | null;
                  Expiry?: any | null;
                  MIMEType?: string | null;
                } | null;
              }>;
            } | null;
            HydrantCounts?: {
              __typename?: 'HydrantCountsType';
              SearchMessage?: string | null;
              HydrantCount: Array<{
                __typename?: 'HydrantCountType';
                NumberOfHydrants: number;
                Ownership?: string | null;
                RadiusMetres?: string | null;
              }>;
            } | null;
          } | null;
          optaFloodscoreData?: {
            __typename?: 'OptaFloodScoreDataType';
            Address?: {
              __typename?: 'OptaAddressType';
              StreetAddress?: string | null;
              StreetNumber?: string | null;
              StreetName?: string | null;
              StreetType?: string | null;
              StreetDirection?: string | null;
              UnitNumber?: string | null;
              Municipality?: string | null;
              Province?: string | null;
              PostalCode?: string | null;
              LegalDescription?: {
                __typename?: 'LegalDescriptionType';
                Quarter?: string | null;
                LegalSubdivision?: string | null;
                Section?: string | null;
                Township?: string | null;
                Range?: string | null;
                Meridian?: string | null;
              } | null;
            } | null;
            FloodScores?: {
              __typename?: 'FloodScoresType';
              FloodScore: Array<{
                __typename?: 'FloodScoreType';
                AverageAnnualLossScore?: number | null;
                Deductible?: number | null;
                Default?: boolean | null;
                FloodScore?: number | null;
                Limit?: number | null;
                RateFactor?: number | null;
                Zone?: string | null;
                ResidentialModifiers?: {
                  __typename?: 'ResidentialConstructionFeaturesType';
                  ArchitecturalStyleType?: string | null;
                  AuxiliaryHeatingType?: string | null;
                  BathroomCount?: string | null;
                  CommercialIndicator?: string | null;
                  ExteriorWallType?: string | null;
                  FinishedBasement?: boolean | null;
                  FoundationType?: string | null;
                  GarageNumberOfCars?: number | null;
                  GarageType?: string | null;
                  KitchenCount?: string | null;
                  OutbuildingPresent?: string | null;
                  PlumbingType?: string | null;
                  PrimaryHeatingType?: string | null;
                  RoofType?: string | null;
                  SquareFootage?: number | null;
                  StoreyCount?: string | null;
                  SwimmingPoolType?: string | null;
                  YearBuilt?: string | null;
                  InspectionDetails?: {
                    __typename?: 'InspectionDetailsType';
                    InspectionDate?: any | null;
                    SponsoredInspection?: boolean | null;
                  } | null;
                } | null;
                MultiResidentialModifiers?: {
                  __typename?: 'MultiResidentialConstructionFeaturesType';
                  BasementPresent?: boolean | null;
                  ConstructionType?: string | null;
                  FloorLevel?: number | null;
                } | null;
                AverageAnnualLossValues?: {
                  __typename?: 'FloodValueType';
                  Building?: number | null;
                  Contents?: number | null;
                  Total?: number | null;
                } | null;
                AdditionalDetails: Array<{
                  __typename?: 'FloodDetailType';
                  Key?: string | null;
                  Value?: string | null;
                }>;
                Premium?: {
                  __typename?: 'FloodValueType';
                  Building?: number | null;
                  Contents?: number | null;
                  Total?: number | null;
                } | null;
              }>;
              FloodMap: Array<{
                __typename?: 'FloodMapType';
                FloodEvent?: string | null;
                Content?: {
                  __typename?: 'ContentType';
                  ContentURL?: string | null;
                  Expiry?: any | null;
                  MIMEType?: string | null;
                } | null;
              }>;
            } | null;
          } | null;
          optaIclarifyCommercialConstructionData?: {
            __typename?: 'OptaIclarifyCommercialConstructionDataType';
            Address?: {
              __typename?: 'OptaAddressType';
              StreetAddress?: string | null;
              StreetNumber?: string | null;
              StreetName?: string | null;
              StreetType?: string | null;
              StreetDirection?: string | null;
              UnitNumber?: string | null;
              Municipality?: string | null;
              Province?: string | null;
              PostalCode?: string | null;
              LegalDescription?: {
                __typename?: 'LegalDescriptionType';
                Quarter?: string | null;
                LegalSubdivision?: string | null;
                Section?: string | null;
                Township?: string | null;
                Range?: string | null;
                Meridian?: string | null;
              } | null;
            } | null;
            Messages?: {
              __typename?: 'Messages';
              Message?: Array<string | null> | null;
            } | null;
            Property?: {
              __typename?: 'CommercialPropertyType';
              LotSize?: number | null;
              LotSizeUnitOfMeasurement?: string | null;
              PropertyType?: string | null;
              Buildings?: {
                __typename?: 'BuildingsType';
                Building: Array<{
                  __typename?: 'BuildingType';
                  StructureID?: string | null;
                  ConstructionFeatures?: {
                    __typename?: 'CommercialConstructionFeaturesType';
                    CombustibilityClass?: string | null;
                    ConstructionClass?: string | null;
                    FloorHeight?: number | null;
                    FloorHeightUnitOfMeasurement?: string | null;
                    LoadingDockPresent?: boolean | null;
                    NumberOfStoreys?: number | null;
                    NumberOfUnits?: number | null;
                    TotalFloorArea?: number | null;
                    TotalFloorAreaUnitOfMeasurement?: string | null;
                    YearBuilt?: number | null;
                    Basement?: {
                      __typename?: 'CommercialBasementType';
                      BasementArea?: number | null;
                      BasementAreaUnitOfMeasurement?: string | null;
                      BasementPresent?: boolean | null;
                    } | null;
                    BuildingUpdates?: {
                      __typename?: 'BuildingUpdatesType';
                      BuildingUpdate?: Array<number | null> | null;
                    } | null;
                    CommercialExteriorWallTypes?: {
                      __typename?: 'CommercialExteriorWallTypesType';
                      ExteriorWallType?: Array<string | null> | null;
                    } | null;
                    Elevator?: {
                      __typename?: 'ElevatorType';
                      ElevatorCount?: number | null;
                      ElevatorPresent?: boolean | null;
                    } | null;
                    Heating?: {
                      __typename?: 'CommercialHeatingType';
                      HeatingFuelSources?: {
                        __typename?: 'HeatingFuelSourcesType';
                        HeatingFuelSource?: Array<string | null> | null;
                      } | null;
                      HeatingTypes?: {
                        __typename?: 'HeatingTypesType';
                        HeatingType?: Array<string | null> | null;
                      } | null;
                    } | null;
                    Parking?: {
                      __typename?: 'CommercialParkingType';
                      ParkingPresent?: boolean | null;
                      ParkingTypes?: {
                        __typename?: 'CommercialParkingTypesType';
                        ParkingType?: Array<string | null> | null;
                      } | null;
                    } | null;
                    PlumbingTypes?: {
                      __typename?: 'CommercialPlumbingType';
                      PlumbingType?: Array<string | null> | null;
                    } | null;
                    RoofAttributes?: {
                      __typename?: 'RoofAttributesType';
                      RoofConstructions?: {
                        __typename?: 'RoofConstructionsType';
                        RoofConstruction?: Array<string | null> | null;
                      } | null;
                      RoofSurfaces?: {
                        __typename?: 'RoofSurfacesType';
                        RoofSurface?: Array<string | null> | null;
                      } | null;
                      RoofTypes?: {
                        __typename?: 'RoofTypesType';
                        RoofType?: Array<string | null> | null;
                      } | null;
                    } | null;
                    WaterHeater?: {
                      __typename?: 'CommercialWaterHeaterType';
                      WaterHeaterFuelType?: Array<string | null> | null;
                      WaterHeaterType?: Array<string | null> | null;
                    } | null;
                    Wiring?: {
                      __typename?: 'CommercialWiringType';
                      WiringPanelTypes?: {
                        __typename?: 'CommercialWiringPanelTypesType';
                        WiringPanelType?: Array<string | null> | null;
                      } | null;
                      WiringTypes?: {
                        __typename?: 'CommercialWiringTypesType';
                        WiringType?: Array<string | null> | null;
                      } | null;
                    } | null;
                  } | null;
                  Protection?: {
                    __typename?: 'ProtectionType';
                    FireAlarmPresent?: boolean | null;
                    FireAlarmType?: string | null;
                    PhysicalSecurityPresent?: boolean | null;
                    SecurityAlarmPresent?: boolean | null;
                    SecurityAlarmType?: string | null;
                    SprinklerPresent?: boolean | null;
                    SprinklerTypeCode?: string | null;
                    PhysicalSecurityTypes?: {
                      __typename?: 'PhysicalSecurityTypesType';
                      PhysicalSecurityType?: Array<string | null> | null;
                    } | null;
                  } | null;
                }>;
              } | null;
              Industry?: {
                __typename?: 'BusinessIndustryType';
                IBCCode?: string | null;
                IBCIndustryDescription?: string | null;
                SICIndustryDescription?: string | null;
                SICCode?: string | null;
              } | null;
            } | null;
          } | null;
          optaCommercialPerilScoreData?: {
            __typename?: 'OptaCommercialPerilScoreDataType';
            Address?: {
              __typename?: 'OptaAddressType';
              StreetAddress?: string | null;
              StreetNumber?: string | null;
              StreetName?: string | null;
              StreetType?: string | null;
              StreetDirection?: string | null;
              UnitNumber?: string | null;
              Municipality?: string | null;
              Province?: string | null;
              PostalCode?: string | null;
              LegalDescription?: {
                __typename?: 'LegalDescriptionType';
                Quarter?: string | null;
                LegalSubdivision?: string | null;
                Section?: string | null;
                Township?: string | null;
                Range?: string | null;
                Meridian?: string | null;
              } | null;
            } | null;
            PropertyPerilScores?: {
              __typename?: 'PropertyCommercialPerilsType';
              Peril?: Array<{
                __typename?: 'PerilDetailsType';
                LossCostScore?: number | null;
                PerilType?: string | null;
                PredictedLossCost?: number | null;
              } | null> | null;
            } | null;
            CasualtyPerilScores?: {
              __typename?: 'CasualtyCommercialPerilsType';
              Peril?: Array<{
                __typename?: 'PerilDetailsType';
                LossCostScore?: number | null;
                PerilType?: string | null;
                PredictedLossCost?: number | null;
              } | null> | null;
            } | null;
            AllPerilsScore?: {
              __typename?: 'CommercialPerilScoreType';
              LossCostScore?: number | null;
              PredictedLossCost?: number | null;
            } | null;
          } | null;
        } | null;
        openingHours: Array<{
          __typename?: 'OpeningHoursType';
          day: number;
          open?: any | null;
          close?: any | null;
        }>;
      } | null;
    }>;
  }>;
  signature?: {
    __typename?: 'SignatureType';
    signatureData?: string | null;
    signatureTime?: any | null;
  } | null;
  bindings: Array<{
    __typename?: 'BindingType';
    label: string;
    bound: boolean;
    unboundReason: string;
  }>;
};

export type GenerateQuoteConfirmationCorrespondenceMutationVariables = Exact<{
  token: Scalars['String'];
  uuid: Scalars['String'];
}>;

export type GenerateQuoteConfirmationCorrespondenceMutation = {
  __typename?: 'Mutation';
  generateQuoteConfirmationCorrespondence?: {
    __typename?: 'GenerateQuoteConfirmationCorrespondence';
    ok?: boolean | null;
    hash?: string | null;
  } | null;
};

export type GetAllOptaDataMutationVariables = Exact<{
  answer: Scalars['ID'];
  token: Scalars['String'];
}>;

export type GetAllOptaDataMutation = {
  __typename?: 'Mutation';
  getAllOptaData?: {
    __typename?: 'GetAllOptaData';
    ok?: boolean | null;
    data?: {
      __typename?: 'OPTADetailsType';
      optaFusData?: {
        __typename?: 'OptaFusDataType';
        Address?: {
          __typename?: 'OptaAddressType';
          StreetAddress?: string | null;
          StreetNumber?: string | null;
          StreetName?: string | null;
          StreetType?: string | null;
          StreetDirection?: string | null;
          UnitNumber?: string | null;
          Municipality?: string | null;
          Province?: string | null;
          PostalCode?: string | null;
          LegalDescription?: {
            __typename?: 'LegalDescriptionType';
            Quarter?: string | null;
            LegalSubdivision?: string | null;
            Section?: string | null;
            Township?: string | null;
            Range?: string | null;
            Meridian?: string | null;
          } | null;
        } | null;
        Commercial?: {
          __typename?: 'FusProductCommercialType';
          SearchMessage?: string | null;
          CommercialGrade: Array<{
            __typename?: 'CommercialGradeType';
            PublicFireProtectionClassification?: number | null;
            WaterSystem?: string | null;
            RiskSCORDetail: Array<{
              __typename?: 'RiskSCORDetailType';
              RiskSCOR?: number | null;
              Type?: string | null;
              Adjustment: Array<{
                __typename?: 'RiskSCORAdjustmentType';
                Adjustment?: string | null;
                AdjustmentType?: string | null;
              }>;
            }>;
          }>;
          RespondingFirehall?: {
            __typename?: 'FirehallType';
            Address?: string | null;
            GoverningAgency?: string | null;
            GradingDate?: any | null;
            Name?: string | null;
            PumperFlowImperialGallonsPerMinute?: number | null;
            RoadDistanceKilometres?: number | null;
            SuperiorTankerServiceAccreditationDate?: any | null;
            FirefightersPerFireStation?: {
              __typename?: 'FirefightersPerFireStationType';
              FullTime?: number | null;
              Volunteer?: number | null;
            } | null;
            ProtectionGradeTerritories?: {
              __typename?: 'ProtectedGradeTerritoriesType';
              Commercial?: string | null;
              Dwelling?: string | null;
            } | null;
          } | null;
        } | null;
        Dwelling?: {
          __typename?: 'FusProductDwellingType';
          SearchMessage?: string | null;
          DwellingGrades?: {
            __typename?: 'DwellingGradesType';
            AllOtherAreasGrade: Array<{
              __typename?: 'DwellingProtectedGradeType';
              Grade?: string | null;
              WaterSystem?: string | null;
            }>;
            FirehallProtectedGrade: Array<{
              __typename?: 'DwellingProtectedGradeType';
              Grade?: string | null;
              WaterSystem?: string | null;
            }>;
            HydrantProtectedGrade: Array<{
              __typename?: 'DwellingProtectedGradeType';
              Grade?: string | null;
              WaterSystem?: string | null;
            }>;
          } | null;
          RespondingFirehall?: {
            __typename?: 'FirehallType';
            Address?: string | null;
            GoverningAgency?: string | null;
            GradingDate?: any | null;
            Name?: string | null;
            PumperFlowImperialGallonsPerMinute?: number | null;
            RoadDistanceKilometres?: number | null;
            SuperiorTankerServiceAccreditationDate?: any | null;
            FirefightersPerFireStation?: {
              __typename?: 'FirefightersPerFireStationType';
              FullTime?: number | null;
              Volunteer?: number | null;
            } | null;
            ProtectionGradeTerritories?: {
              __typename?: 'ProtectedGradeTerritoriesType';
              Commercial?: string | null;
              Dwelling?: string | null;
            } | null;
          } | null;
        } | null;
        EarthquakeCodes?: {
          __typename?: 'EarthquakeCodesType';
          SearchMessage?: string | null;
          EarthquakeCode: Array<{
            __typename?: 'EarthquakeCodeType';
            Description?: string | null;
            SystemName?: string | null;
            Zone?: string | null;
          }>;
        } | null;
        FusMaps?: {
          __typename?: 'FusMapsType';
          SearchMessage?: string | null;
          FusMap: Array<{
            __typename?: 'FusMapType';
            Business?: string | null;
            FusContent?: {
              __typename?: 'ContentType';
              ContentURL?: string | null;
              Expiry?: any | null;
              MIMEType?: string | null;
            } | null;
          }>;
        } | null;
        HydrantCounts?: {
          __typename?: 'HydrantCountsType';
          SearchMessage?: string | null;
          HydrantCount: Array<{
            __typename?: 'HydrantCountType';
            NumberOfHydrants: number;
            Ownership?: string | null;
            RadiusMetres?: string | null;
          }>;
        } | null;
      } | null;
      optaFloodscoreData?: {
        __typename?: 'OptaFloodScoreDataType';
        Address?: {
          __typename?: 'OptaAddressType';
          StreetAddress?: string | null;
          StreetNumber?: string | null;
          StreetName?: string | null;
          StreetType?: string | null;
          StreetDirection?: string | null;
          UnitNumber?: string | null;
          Municipality?: string | null;
          Province?: string | null;
          PostalCode?: string | null;
          LegalDescription?: {
            __typename?: 'LegalDescriptionType';
            Quarter?: string | null;
            LegalSubdivision?: string | null;
            Section?: string | null;
            Township?: string | null;
            Range?: string | null;
            Meridian?: string | null;
          } | null;
        } | null;
        FloodScores?: {
          __typename?: 'FloodScoresType';
          FloodScore: Array<{
            __typename?: 'FloodScoreType';
            AverageAnnualLossScore?: number | null;
            Deductible?: number | null;
            Default?: boolean | null;
            FloodScore?: number | null;
            Limit?: number | null;
            RateFactor?: number | null;
            Zone?: string | null;
            ResidentialModifiers?: {
              __typename?: 'ResidentialConstructionFeaturesType';
              ArchitecturalStyleType?: string | null;
              AuxiliaryHeatingType?: string | null;
              BathroomCount?: string | null;
              CommercialIndicator?: string | null;
              ExteriorWallType?: string | null;
              FinishedBasement?: boolean | null;
              FoundationType?: string | null;
              GarageNumberOfCars?: number | null;
              GarageType?: string | null;
              KitchenCount?: string | null;
              OutbuildingPresent?: string | null;
              PlumbingType?: string | null;
              PrimaryHeatingType?: string | null;
              RoofType?: string | null;
              SquareFootage?: number | null;
              StoreyCount?: string | null;
              SwimmingPoolType?: string | null;
              YearBuilt?: string | null;
              InspectionDetails?: {
                __typename?: 'InspectionDetailsType';
                InspectionDate?: any | null;
                SponsoredInspection?: boolean | null;
              } | null;
            } | null;
            MultiResidentialModifiers?: {
              __typename?: 'MultiResidentialConstructionFeaturesType';
              BasementPresent?: boolean | null;
              ConstructionType?: string | null;
              FloorLevel?: number | null;
            } | null;
            AverageAnnualLossValues?: {
              __typename?: 'FloodValueType';
              Building?: number | null;
              Contents?: number | null;
              Total?: number | null;
            } | null;
            AdditionalDetails: Array<{
              __typename?: 'FloodDetailType';
              Key?: string | null;
              Value?: string | null;
            }>;
            Premium?: {
              __typename?: 'FloodValueType';
              Building?: number | null;
              Contents?: number | null;
              Total?: number | null;
            } | null;
          }>;
          FloodMap: Array<{
            __typename?: 'FloodMapType';
            FloodEvent?: string | null;
            Content?: {
              __typename?: 'ContentType';
              ContentURL?: string | null;
              Expiry?: any | null;
              MIMEType?: string | null;
            } | null;
          }>;
        } | null;
      } | null;
      optaIclarifyCommercialConstructionData?: {
        __typename?: 'OptaIclarifyCommercialConstructionDataType';
        Address?: {
          __typename?: 'OptaAddressType';
          StreetAddress?: string | null;
          StreetNumber?: string | null;
          StreetName?: string | null;
          StreetType?: string | null;
          StreetDirection?: string | null;
          UnitNumber?: string | null;
          Municipality?: string | null;
          Province?: string | null;
          PostalCode?: string | null;
          LegalDescription?: {
            __typename?: 'LegalDescriptionType';
            Quarter?: string | null;
            LegalSubdivision?: string | null;
            Section?: string | null;
            Township?: string | null;
            Range?: string | null;
            Meridian?: string | null;
          } | null;
        } | null;
        Messages?: {
          __typename?: 'Messages';
          Message?: Array<string | null> | null;
        } | null;
        Property?: {
          __typename?: 'CommercialPropertyType';
          LotSize?: number | null;
          LotSizeUnitOfMeasurement?: string | null;
          PropertyType?: string | null;
          Buildings?: {
            __typename?: 'BuildingsType';
            Building: Array<{
              __typename?: 'BuildingType';
              StructureID?: string | null;
              ConstructionFeatures?: {
                __typename?: 'CommercialConstructionFeaturesType';
                CombustibilityClass?: string | null;
                ConstructionClass?: string | null;
                FloorHeight?: number | null;
                FloorHeightUnitOfMeasurement?: string | null;
                LoadingDockPresent?: boolean | null;
                NumberOfStoreys?: number | null;
                NumberOfUnits?: number | null;
                TotalFloorArea?: number | null;
                TotalFloorAreaUnitOfMeasurement?: string | null;
                YearBuilt?: number | null;
                Basement?: {
                  __typename?: 'CommercialBasementType';
                  BasementArea?: number | null;
                  BasementAreaUnitOfMeasurement?: string | null;
                  BasementPresent?: boolean | null;
                } | null;
                BuildingUpdates?: {
                  __typename?: 'BuildingUpdatesType';
                  BuildingUpdate?: Array<number | null> | null;
                } | null;
                CommercialExteriorWallTypes?: {
                  __typename?: 'CommercialExteriorWallTypesType';
                  ExteriorWallType?: Array<string | null> | null;
                } | null;
                Elevator?: {
                  __typename?: 'ElevatorType';
                  ElevatorCount?: number | null;
                  ElevatorPresent?: boolean | null;
                } | null;
                Heating?: {
                  __typename?: 'CommercialHeatingType';
                  HeatingFuelSources?: {
                    __typename?: 'HeatingFuelSourcesType';
                    HeatingFuelSource?: Array<string | null> | null;
                  } | null;
                  HeatingTypes?: {
                    __typename?: 'HeatingTypesType';
                    HeatingType?: Array<string | null> | null;
                  } | null;
                } | null;
                Parking?: {
                  __typename?: 'CommercialParkingType';
                  ParkingPresent?: boolean | null;
                  ParkingTypes?: {
                    __typename?: 'CommercialParkingTypesType';
                    ParkingType?: Array<string | null> | null;
                  } | null;
                } | null;
                PlumbingTypes?: {
                  __typename?: 'CommercialPlumbingType';
                  PlumbingType?: Array<string | null> | null;
                } | null;
                RoofAttributes?: {
                  __typename?: 'RoofAttributesType';
                  RoofConstructions?: {
                    __typename?: 'RoofConstructionsType';
                    RoofConstruction?: Array<string | null> | null;
                  } | null;
                  RoofSurfaces?: {
                    __typename?: 'RoofSurfacesType';
                    RoofSurface?: Array<string | null> | null;
                  } | null;
                  RoofTypes?: {
                    __typename?: 'RoofTypesType';
                    RoofType?: Array<string | null> | null;
                  } | null;
                } | null;
                WaterHeater?: {
                  __typename?: 'CommercialWaterHeaterType';
                  WaterHeaterFuelType?: Array<string | null> | null;
                  WaterHeaterType?: Array<string | null> | null;
                } | null;
                Wiring?: {
                  __typename?: 'CommercialWiringType';
                  WiringPanelTypes?: {
                    __typename?: 'CommercialWiringPanelTypesType';
                    WiringPanelType?: Array<string | null> | null;
                  } | null;
                  WiringTypes?: {
                    __typename?: 'CommercialWiringTypesType';
                    WiringType?: Array<string | null> | null;
                  } | null;
                } | null;
              } | null;
              Protection?: {
                __typename?: 'ProtectionType';
                FireAlarmPresent?: boolean | null;
                FireAlarmType?: string | null;
                PhysicalSecurityPresent?: boolean | null;
                SecurityAlarmPresent?: boolean | null;
                SecurityAlarmType?: string | null;
                SprinklerPresent?: boolean | null;
                SprinklerTypeCode?: string | null;
                PhysicalSecurityTypes?: {
                  __typename?: 'PhysicalSecurityTypesType';
                  PhysicalSecurityType?: Array<string | null> | null;
                } | null;
              } | null;
            }>;
          } | null;
          Industry?: {
            __typename?: 'BusinessIndustryType';
            IBCCode?: string | null;
            IBCIndustryDescription?: string | null;
            SICIndustryDescription?: string | null;
            SICCode?: string | null;
          } | null;
        } | null;
      } | null;
      optaCommercialPerilScoreData?: {
        __typename?: 'OptaCommercialPerilScoreDataType';
        Address?: {
          __typename?: 'OptaAddressType';
          StreetAddress?: string | null;
          StreetNumber?: string | null;
          StreetName?: string | null;
          StreetType?: string | null;
          StreetDirection?: string | null;
          UnitNumber?: string | null;
          Municipality?: string | null;
          Province?: string | null;
          PostalCode?: string | null;
          LegalDescription?: {
            __typename?: 'LegalDescriptionType';
            Quarter?: string | null;
            LegalSubdivision?: string | null;
            Section?: string | null;
            Township?: string | null;
            Range?: string | null;
            Meridian?: string | null;
          } | null;
        } | null;
        PropertyPerilScores?: {
          __typename?: 'PropertyCommercialPerilsType';
          Peril?: Array<{
            __typename?: 'PerilDetailsType';
            LossCostScore?: number | null;
            PerilType?: string | null;
            PredictedLossCost?: number | null;
          } | null> | null;
        } | null;
        CasualtyPerilScores?: {
          __typename?: 'CasualtyCommercialPerilsType';
          Peril?: Array<{
            __typename?: 'PerilDetailsType';
            LossCostScore?: number | null;
            PerilType?: string | null;
            PredictedLossCost?: number | null;
          } | null> | null;
        } | null;
        AllPerilsScore?: {
          __typename?: 'CommercialPerilScoreType';
          LossCostScore?: number | null;
          PredictedLossCost?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetGeneralInformationFormForBusinessQueryVariables = Exact<{
  businessId?: InputMaybe<Scalars['ID']>;
}>;

export type GetGeneralInformationFormForBusinessQuery = {
  __typename?: 'Query';
  getGeneralInformationFormForBusiness?: {
    __typename?: 'FinalFormType';
    id: string;
    required: boolean;
    questionInstances: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
    qualifyingQuestion?: {
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
      internalName: string;
      sicCode: number;
      industry: {
        __typename?: 'IndustryType';
        id: string;
        displayName: string;
      };
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
    } | null;
    policyExtension: Array<{
      __typename?: 'PolicyExtensionType';
      id: string;
      label: string;
      multiplier?: string | null;
      questionId?: string | null;
      value?: string | null;
    }>;
  } | null;
};

export type LocationsFromQuoteHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type LocationsFromQuoteHashQuery = {
  __typename?: 'Query';
  locationsFromQuoteHash?: Array<{
    __typename?: 'AnswerInstanceType';
    id: string;
    apiName: string;
    displayName: string;
    label: string;
    helpText?: string | null;
    component: string;
    propsBlob?: any | null;
    required: boolean;
    askOnRenewal: boolean;
    prefillOnRenewal: boolean;
    value: string;
    date: any;
    method?: string | null;
    dataType: string;
    defaultValue?: string | null;
    details?: {
      __typename?: 'AnswerInstanceDetailsType';
      lat?: number | null;
      lng?: number | null;
      images: Array<{
        __typename?: 'GoogleImageType';
        description: string;
        shortDescription: string;
        category: CoreGoogleImageCategoryChoices;
        data?: string | null;
      }>;
      google?: {
        __typename?: 'GoogleDetailsType';
        placeId?: string | null;
        rating?: number | null;
        numberOfRatings?: number | null;
      } | null;
      opta?: {
        __typename?: 'OPTADetailsType';
        optaFusData?: {
          __typename?: 'OptaFusDataType';
          Address?: {
            __typename?: 'OptaAddressType';
            StreetAddress?: string | null;
            StreetNumber?: string | null;
            StreetName?: string | null;
            StreetType?: string | null;
            StreetDirection?: string | null;
            UnitNumber?: string | null;
            Municipality?: string | null;
            Province?: string | null;
            PostalCode?: string | null;
            LegalDescription?: {
              __typename?: 'LegalDescriptionType';
              Quarter?: string | null;
              LegalSubdivision?: string | null;
              Section?: string | null;
              Township?: string | null;
              Range?: string | null;
              Meridian?: string | null;
            } | null;
          } | null;
          Commercial?: {
            __typename?: 'FusProductCommercialType';
            SearchMessage?: string | null;
            CommercialGrade: Array<{
              __typename?: 'CommercialGradeType';
              PublicFireProtectionClassification?: number | null;
              WaterSystem?: string | null;
              RiskSCORDetail: Array<{
                __typename?: 'RiskSCORDetailType';
                RiskSCOR?: number | null;
                Type?: string | null;
                Adjustment: Array<{
                  __typename?: 'RiskSCORAdjustmentType';
                  Adjustment?: string | null;
                  AdjustmentType?: string | null;
                }>;
              }>;
            }>;
            RespondingFirehall?: {
              __typename?: 'FirehallType';
              Address?: string | null;
              GoverningAgency?: string | null;
              GradingDate?: any | null;
              Name?: string | null;
              PumperFlowImperialGallonsPerMinute?: number | null;
              RoadDistanceKilometres?: number | null;
              SuperiorTankerServiceAccreditationDate?: any | null;
              FirefightersPerFireStation?: {
                __typename?: 'FirefightersPerFireStationType';
                FullTime?: number | null;
                Volunteer?: number | null;
              } | null;
              ProtectionGradeTerritories?: {
                __typename?: 'ProtectedGradeTerritoriesType';
                Commercial?: string | null;
                Dwelling?: string | null;
              } | null;
            } | null;
          } | null;
          Dwelling?: {
            __typename?: 'FusProductDwellingType';
            SearchMessage?: string | null;
            DwellingGrades?: {
              __typename?: 'DwellingGradesType';
              AllOtherAreasGrade: Array<{
                __typename?: 'DwellingProtectedGradeType';
                Grade?: string | null;
                WaterSystem?: string | null;
              }>;
              FirehallProtectedGrade: Array<{
                __typename?: 'DwellingProtectedGradeType';
                Grade?: string | null;
                WaterSystem?: string | null;
              }>;
              HydrantProtectedGrade: Array<{
                __typename?: 'DwellingProtectedGradeType';
                Grade?: string | null;
                WaterSystem?: string | null;
              }>;
            } | null;
            RespondingFirehall?: {
              __typename?: 'FirehallType';
              Address?: string | null;
              GoverningAgency?: string | null;
              GradingDate?: any | null;
              Name?: string | null;
              PumperFlowImperialGallonsPerMinute?: number | null;
              RoadDistanceKilometres?: number | null;
              SuperiorTankerServiceAccreditationDate?: any | null;
              FirefightersPerFireStation?: {
                __typename?: 'FirefightersPerFireStationType';
                FullTime?: number | null;
                Volunteer?: number | null;
              } | null;
              ProtectionGradeTerritories?: {
                __typename?: 'ProtectedGradeTerritoriesType';
                Commercial?: string | null;
                Dwelling?: string | null;
              } | null;
            } | null;
          } | null;
          EarthquakeCodes?: {
            __typename?: 'EarthquakeCodesType';
            SearchMessage?: string | null;
            EarthquakeCode: Array<{
              __typename?: 'EarthquakeCodeType';
              Description?: string | null;
              SystemName?: string | null;
              Zone?: string | null;
            }>;
          } | null;
          FusMaps?: {
            __typename?: 'FusMapsType';
            SearchMessage?: string | null;
            FusMap: Array<{
              __typename?: 'FusMapType';
              Business?: string | null;
              FusContent?: {
                __typename?: 'ContentType';
                ContentURL?: string | null;
                Expiry?: any | null;
                MIMEType?: string | null;
              } | null;
            }>;
          } | null;
          HydrantCounts?: {
            __typename?: 'HydrantCountsType';
            SearchMessage?: string | null;
            HydrantCount: Array<{
              __typename?: 'HydrantCountType';
              NumberOfHydrants: number;
              Ownership?: string | null;
              RadiusMetres?: string | null;
            }>;
          } | null;
        } | null;
        optaFloodscoreData?: {
          __typename?: 'OptaFloodScoreDataType';
          Address?: {
            __typename?: 'OptaAddressType';
            StreetAddress?: string | null;
            StreetNumber?: string | null;
            StreetName?: string | null;
            StreetType?: string | null;
            StreetDirection?: string | null;
            UnitNumber?: string | null;
            Municipality?: string | null;
            Province?: string | null;
            PostalCode?: string | null;
            LegalDescription?: {
              __typename?: 'LegalDescriptionType';
              Quarter?: string | null;
              LegalSubdivision?: string | null;
              Section?: string | null;
              Township?: string | null;
              Range?: string | null;
              Meridian?: string | null;
            } | null;
          } | null;
          FloodScores?: {
            __typename?: 'FloodScoresType';
            FloodScore: Array<{
              __typename?: 'FloodScoreType';
              AverageAnnualLossScore?: number | null;
              Deductible?: number | null;
              Default?: boolean | null;
              FloodScore?: number | null;
              Limit?: number | null;
              RateFactor?: number | null;
              Zone?: string | null;
              ResidentialModifiers?: {
                __typename?: 'ResidentialConstructionFeaturesType';
                ArchitecturalStyleType?: string | null;
                AuxiliaryHeatingType?: string | null;
                BathroomCount?: string | null;
                CommercialIndicator?: string | null;
                ExteriorWallType?: string | null;
                FinishedBasement?: boolean | null;
                FoundationType?: string | null;
                GarageNumberOfCars?: number | null;
                GarageType?: string | null;
                KitchenCount?: string | null;
                OutbuildingPresent?: string | null;
                PlumbingType?: string | null;
                PrimaryHeatingType?: string | null;
                RoofType?: string | null;
                SquareFootage?: number | null;
                StoreyCount?: string | null;
                SwimmingPoolType?: string | null;
                YearBuilt?: string | null;
                InspectionDetails?: {
                  __typename?: 'InspectionDetailsType';
                  InspectionDate?: any | null;
                  SponsoredInspection?: boolean | null;
                } | null;
              } | null;
              MultiResidentialModifiers?: {
                __typename?: 'MultiResidentialConstructionFeaturesType';
                BasementPresent?: boolean | null;
                ConstructionType?: string | null;
                FloorLevel?: number | null;
              } | null;
              AverageAnnualLossValues?: {
                __typename?: 'FloodValueType';
                Building?: number | null;
                Contents?: number | null;
                Total?: number | null;
              } | null;
              AdditionalDetails: Array<{
                __typename?: 'FloodDetailType';
                Key?: string | null;
                Value?: string | null;
              }>;
              Premium?: {
                __typename?: 'FloodValueType';
                Building?: number | null;
                Contents?: number | null;
                Total?: number | null;
              } | null;
            }>;
            FloodMap: Array<{
              __typename?: 'FloodMapType';
              FloodEvent?: string | null;
              Content?: {
                __typename?: 'ContentType';
                ContentURL?: string | null;
                Expiry?: any | null;
                MIMEType?: string | null;
              } | null;
            }>;
          } | null;
        } | null;
        optaIclarifyCommercialConstructionData?: {
          __typename?: 'OptaIclarifyCommercialConstructionDataType';
          Address?: {
            __typename?: 'OptaAddressType';
            StreetAddress?: string | null;
            StreetNumber?: string | null;
            StreetName?: string | null;
            StreetType?: string | null;
            StreetDirection?: string | null;
            UnitNumber?: string | null;
            Municipality?: string | null;
            Province?: string | null;
            PostalCode?: string | null;
            LegalDescription?: {
              __typename?: 'LegalDescriptionType';
              Quarter?: string | null;
              LegalSubdivision?: string | null;
              Section?: string | null;
              Township?: string | null;
              Range?: string | null;
              Meridian?: string | null;
            } | null;
          } | null;
          Messages?: {
            __typename?: 'Messages';
            Message?: Array<string | null> | null;
          } | null;
          Property?: {
            __typename?: 'CommercialPropertyType';
            LotSize?: number | null;
            LotSizeUnitOfMeasurement?: string | null;
            PropertyType?: string | null;
            Buildings?: {
              __typename?: 'BuildingsType';
              Building: Array<{
                __typename?: 'BuildingType';
                StructureID?: string | null;
                ConstructionFeatures?: {
                  __typename?: 'CommercialConstructionFeaturesType';
                  CombustibilityClass?: string | null;
                  ConstructionClass?: string | null;
                  FloorHeight?: number | null;
                  FloorHeightUnitOfMeasurement?: string | null;
                  LoadingDockPresent?: boolean | null;
                  NumberOfStoreys?: number | null;
                  NumberOfUnits?: number | null;
                  TotalFloorArea?: number | null;
                  TotalFloorAreaUnitOfMeasurement?: string | null;
                  YearBuilt?: number | null;
                  Basement?: {
                    __typename?: 'CommercialBasementType';
                    BasementArea?: number | null;
                    BasementAreaUnitOfMeasurement?: string | null;
                    BasementPresent?: boolean | null;
                  } | null;
                  BuildingUpdates?: {
                    __typename?: 'BuildingUpdatesType';
                    BuildingUpdate?: Array<number | null> | null;
                  } | null;
                  CommercialExteriorWallTypes?: {
                    __typename?: 'CommercialExteriorWallTypesType';
                    ExteriorWallType?: Array<string | null> | null;
                  } | null;
                  Elevator?: {
                    __typename?: 'ElevatorType';
                    ElevatorCount?: number | null;
                    ElevatorPresent?: boolean | null;
                  } | null;
                  Heating?: {
                    __typename?: 'CommercialHeatingType';
                    HeatingFuelSources?: {
                      __typename?: 'HeatingFuelSourcesType';
                      HeatingFuelSource?: Array<string | null> | null;
                    } | null;
                    HeatingTypes?: {
                      __typename?: 'HeatingTypesType';
                      HeatingType?: Array<string | null> | null;
                    } | null;
                  } | null;
                  Parking?: {
                    __typename?: 'CommercialParkingType';
                    ParkingPresent?: boolean | null;
                    ParkingTypes?: {
                      __typename?: 'CommercialParkingTypesType';
                      ParkingType?: Array<string | null> | null;
                    } | null;
                  } | null;
                  PlumbingTypes?: {
                    __typename?: 'CommercialPlumbingType';
                    PlumbingType?: Array<string | null> | null;
                  } | null;
                  RoofAttributes?: {
                    __typename?: 'RoofAttributesType';
                    RoofConstructions?: {
                      __typename?: 'RoofConstructionsType';
                      RoofConstruction?: Array<string | null> | null;
                    } | null;
                    RoofSurfaces?: {
                      __typename?: 'RoofSurfacesType';
                      RoofSurface?: Array<string | null> | null;
                    } | null;
                    RoofTypes?: {
                      __typename?: 'RoofTypesType';
                      RoofType?: Array<string | null> | null;
                    } | null;
                  } | null;
                  WaterHeater?: {
                    __typename?: 'CommercialWaterHeaterType';
                    WaterHeaterFuelType?: Array<string | null> | null;
                    WaterHeaterType?: Array<string | null> | null;
                  } | null;
                  Wiring?: {
                    __typename?: 'CommercialWiringType';
                    WiringPanelTypes?: {
                      __typename?: 'CommercialWiringPanelTypesType';
                      WiringPanelType?: Array<string | null> | null;
                    } | null;
                    WiringTypes?: {
                      __typename?: 'CommercialWiringTypesType';
                      WiringType?: Array<string | null> | null;
                    } | null;
                  } | null;
                } | null;
                Protection?: {
                  __typename?: 'ProtectionType';
                  FireAlarmPresent?: boolean | null;
                  FireAlarmType?: string | null;
                  PhysicalSecurityPresent?: boolean | null;
                  SecurityAlarmPresent?: boolean | null;
                  SecurityAlarmType?: string | null;
                  SprinklerPresent?: boolean | null;
                  SprinklerTypeCode?: string | null;
                  PhysicalSecurityTypes?: {
                    __typename?: 'PhysicalSecurityTypesType';
                    PhysicalSecurityType?: Array<string | null> | null;
                  } | null;
                } | null;
              }>;
            } | null;
            Industry?: {
              __typename?: 'BusinessIndustryType';
              IBCCode?: string | null;
              IBCIndustryDescription?: string | null;
              SICIndustryDescription?: string | null;
              SICCode?: string | null;
            } | null;
          } | null;
        } | null;
        optaCommercialPerilScoreData?: {
          __typename?: 'OptaCommercialPerilScoreDataType';
          Address?: {
            __typename?: 'OptaAddressType';
            StreetAddress?: string | null;
            StreetNumber?: string | null;
            StreetName?: string | null;
            StreetType?: string | null;
            StreetDirection?: string | null;
            UnitNumber?: string | null;
            Municipality?: string | null;
            Province?: string | null;
            PostalCode?: string | null;
            LegalDescription?: {
              __typename?: 'LegalDescriptionType';
              Quarter?: string | null;
              LegalSubdivision?: string | null;
              Section?: string | null;
              Township?: string | null;
              Range?: string | null;
              Meridian?: string | null;
            } | null;
          } | null;
          PropertyPerilScores?: {
            __typename?: 'PropertyCommercialPerilsType';
            Peril?: Array<{
              __typename?: 'PerilDetailsType';
              LossCostScore?: number | null;
              PerilType?: string | null;
              PredictedLossCost?: number | null;
            } | null> | null;
          } | null;
          CasualtyPerilScores?: {
            __typename?: 'CasualtyCommercialPerilsType';
            Peril?: Array<{
              __typename?: 'PerilDetailsType';
              LossCostScore?: number | null;
              PerilType?: string | null;
              PredictedLossCost?: number | null;
            } | null> | null;
          } | null;
          AllPerilsScore?: {
            __typename?: 'CommercialPerilScoreType';
            LossCostScore?: number | null;
            PredictedLossCost?: number | null;
          } | null;
        } | null;
      } | null;
      openingHours: Array<{
        __typename?: 'OpeningHoursType';
        day: number;
        open?: any | null;
        close?: any | null;
      }>;
    } | null;
  } | null> | null;
};

export type GetOptaCommercialPerilScoreDataMutationVariables = Exact<{
  token: Scalars['String'];
  answer: Scalars['ID'];
}>;

export type GetOptaCommercialPerilScoreDataMutation = {
  __typename?: 'Mutation';
  getOptaCommercialPerilScoreData?: {
    __typename?: 'GetOptaCommercialPerilScoreData';
    ok?: boolean | null;
    optaCommercialPerilScoreData?: {
      __typename?: 'OptaCommercialPerilScoreDataType';
      Address?: {
        __typename?: 'OptaAddressType';
        StreetAddress?: string | null;
        StreetNumber?: string | null;
        StreetName?: string | null;
        StreetType?: string | null;
        StreetDirection?: string | null;
        UnitNumber?: string | null;
        Municipality?: string | null;
        Province?: string | null;
        PostalCode?: string | null;
        LegalDescription?: {
          __typename?: 'LegalDescriptionType';
          Quarter?: string | null;
          LegalSubdivision?: string | null;
          Section?: string | null;
          Township?: string | null;
          Range?: string | null;
          Meridian?: string | null;
        } | null;
      } | null;
      PropertyPerilScores?: {
        __typename?: 'PropertyCommercialPerilsType';
        Peril?: Array<{
          __typename?: 'PerilDetailsType';
          LossCostScore?: number | null;
          PerilType?: string | null;
          PredictedLossCost?: number | null;
        } | null> | null;
      } | null;
      CasualtyPerilScores?: {
        __typename?: 'CasualtyCommercialPerilsType';
        Peril?: Array<{
          __typename?: 'PerilDetailsType';
          LossCostScore?: number | null;
          PerilType?: string | null;
          PredictedLossCost?: number | null;
        } | null> | null;
      } | null;
      AllPerilsScore?: {
        __typename?: 'CommercialPerilScoreType';
        LossCostScore?: number | null;
        PredictedLossCost?: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetOptaFloodScoreDataMutationVariables = Exact<{
  token: Scalars['String'];
  answer: Scalars['ID'];
}>;

export type GetOptaFloodScoreDataMutation = {
  __typename?: 'Mutation';
  getOptaFloodscoreData?: {
    __typename?: 'GetOptaFloodScoreData';
    ok?: boolean | null;
    optaFloodscoreData?: {
      __typename?: 'OptaFloodScoreDataType';
      Address?: {
        __typename?: 'OptaAddressType';
        StreetAddress?: string | null;
        StreetNumber?: string | null;
        StreetName?: string | null;
        StreetType?: string | null;
        StreetDirection?: string | null;
        UnitNumber?: string | null;
        Municipality?: string | null;
        Province?: string | null;
        PostalCode?: string | null;
        LegalDescription?: {
          __typename?: 'LegalDescriptionType';
          Quarter?: string | null;
          LegalSubdivision?: string | null;
          Section?: string | null;
          Township?: string | null;
          Range?: string | null;
          Meridian?: string | null;
        } | null;
      } | null;
      FloodScores?: {
        __typename?: 'FloodScoresType';
        FloodScore: Array<{
          __typename?: 'FloodScoreType';
          AverageAnnualLossScore?: number | null;
          Deductible?: number | null;
          Default?: boolean | null;
          FloodScore?: number | null;
          Limit?: number | null;
          RateFactor?: number | null;
          Zone?: string | null;
          ResidentialModifiers?: {
            __typename?: 'ResidentialConstructionFeaturesType';
            ArchitecturalStyleType?: string | null;
            AuxiliaryHeatingType?: string | null;
            BathroomCount?: string | null;
            CommercialIndicator?: string | null;
            ExteriorWallType?: string | null;
            FinishedBasement?: boolean | null;
            FoundationType?: string | null;
            GarageNumberOfCars?: number | null;
            GarageType?: string | null;
            KitchenCount?: string | null;
            OutbuildingPresent?: string | null;
            PlumbingType?: string | null;
            PrimaryHeatingType?: string | null;
            RoofType?: string | null;
            SquareFootage?: number | null;
            StoreyCount?: string | null;
            SwimmingPoolType?: string | null;
            YearBuilt?: string | null;
            InspectionDetails?: {
              __typename?: 'InspectionDetailsType';
              InspectionDate?: any | null;
              SponsoredInspection?: boolean | null;
            } | null;
          } | null;
          MultiResidentialModifiers?: {
            __typename?: 'MultiResidentialConstructionFeaturesType';
            BasementPresent?: boolean | null;
            ConstructionType?: string | null;
            FloorLevel?: number | null;
          } | null;
          AverageAnnualLossValues?: {
            __typename?: 'FloodValueType';
            Building?: number | null;
            Contents?: number | null;
            Total?: number | null;
          } | null;
          AdditionalDetails: Array<{
            __typename?: 'FloodDetailType';
            Key?: string | null;
            Value?: string | null;
          }>;
          Premium?: {
            __typename?: 'FloodValueType';
            Building?: number | null;
            Contents?: number | null;
            Total?: number | null;
          } | null;
        }>;
        FloodMap: Array<{
          __typename?: 'FloodMapType';
          FloodEvent?: string | null;
          Content?: {
            __typename?: 'ContentType';
            ContentURL?: string | null;
            Expiry?: any | null;
            MIMEType?: string | null;
          } | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type GetOptaFusDataMutationVariables = Exact<{
  token: Scalars['String'];
  answer: Scalars['ID'];
}>;

export type GetOptaFusDataMutation = {
  __typename?: 'Mutation';
  getOptaFusData?: {
    __typename?: 'GetOptaFusData';
    ok?: boolean | null;
    optaFusData?: {
      __typename?: 'OptaFusDataType';
      Address?: {
        __typename?: 'OptaAddressType';
        StreetAddress?: string | null;
        StreetNumber?: string | null;
        StreetName?: string | null;
        StreetType?: string | null;
        StreetDirection?: string | null;
        UnitNumber?: string | null;
        Municipality?: string | null;
        Province?: string | null;
        PostalCode?: string | null;
        LegalDescription?: {
          __typename?: 'LegalDescriptionType';
          Quarter?: string | null;
          LegalSubdivision?: string | null;
          Section?: string | null;
          Township?: string | null;
          Range?: string | null;
          Meridian?: string | null;
        } | null;
      } | null;
      Commercial?: {
        __typename?: 'FusProductCommercialType';
        SearchMessage?: string | null;
        CommercialGrade: Array<{
          __typename?: 'CommercialGradeType';
          PublicFireProtectionClassification?: number | null;
          WaterSystem?: string | null;
          RiskSCORDetail: Array<{
            __typename?: 'RiskSCORDetailType';
            RiskSCOR?: number | null;
            Type?: string | null;
            Adjustment: Array<{
              __typename?: 'RiskSCORAdjustmentType';
              Adjustment?: string | null;
              AdjustmentType?: string | null;
            }>;
          }>;
        }>;
        RespondingFirehall?: {
          __typename?: 'FirehallType';
          Address?: string | null;
          GoverningAgency?: string | null;
          GradingDate?: any | null;
          Name?: string | null;
          PumperFlowImperialGallonsPerMinute?: number | null;
          RoadDistanceKilometres?: number | null;
          SuperiorTankerServiceAccreditationDate?: any | null;
          FirefightersPerFireStation?: {
            __typename?: 'FirefightersPerFireStationType';
            FullTime?: number | null;
            Volunteer?: number | null;
          } | null;
          ProtectionGradeTerritories?: {
            __typename?: 'ProtectedGradeTerritoriesType';
            Commercial?: string | null;
            Dwelling?: string | null;
          } | null;
        } | null;
      } | null;
      Dwelling?: {
        __typename?: 'FusProductDwellingType';
        SearchMessage?: string | null;
        DwellingGrades?: {
          __typename?: 'DwellingGradesType';
          AllOtherAreasGrade: Array<{
            __typename?: 'DwellingProtectedGradeType';
            Grade?: string | null;
            WaterSystem?: string | null;
          }>;
          FirehallProtectedGrade: Array<{
            __typename?: 'DwellingProtectedGradeType';
            Grade?: string | null;
            WaterSystem?: string | null;
          }>;
          HydrantProtectedGrade: Array<{
            __typename?: 'DwellingProtectedGradeType';
            Grade?: string | null;
            WaterSystem?: string | null;
          }>;
        } | null;
        RespondingFirehall?: {
          __typename?: 'FirehallType';
          Address?: string | null;
          GoverningAgency?: string | null;
          GradingDate?: any | null;
          Name?: string | null;
          PumperFlowImperialGallonsPerMinute?: number | null;
          RoadDistanceKilometres?: number | null;
          SuperiorTankerServiceAccreditationDate?: any | null;
          FirefightersPerFireStation?: {
            __typename?: 'FirefightersPerFireStationType';
            FullTime?: number | null;
            Volunteer?: number | null;
          } | null;
          ProtectionGradeTerritories?: {
            __typename?: 'ProtectedGradeTerritoriesType';
            Commercial?: string | null;
            Dwelling?: string | null;
          } | null;
        } | null;
      } | null;
      EarthquakeCodes?: {
        __typename?: 'EarthquakeCodesType';
        SearchMessage?: string | null;
        EarthquakeCode: Array<{
          __typename?: 'EarthquakeCodeType';
          Description?: string | null;
          SystemName?: string | null;
          Zone?: string | null;
        }>;
      } | null;
      FusMaps?: {
        __typename?: 'FusMapsType';
        SearchMessage?: string | null;
        FusMap: Array<{
          __typename?: 'FusMapType';
          Business?: string | null;
          FusContent?: {
            __typename?: 'ContentType';
            ContentURL?: string | null;
            Expiry?: any | null;
            MIMEType?: string | null;
          } | null;
        }>;
      } | null;
      HydrantCounts?: {
        __typename?: 'HydrantCountsType';
        SearchMessage?: string | null;
        HydrantCount: Array<{
          __typename?: 'HydrantCountType';
          NumberOfHydrants: number;
          Ownership?: string | null;
          RadiusMetres?: string | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type GetOptaIclarifyCommercialConstructionFieldsMutationVariables =
  Exact<{
    token: Scalars['String'];
    answer: Scalars['ID'];
  }>;

export type GetOptaIclarifyCommercialConstructionFieldsMutation = {
  __typename?: 'Mutation';
  getOptaIclarifyCommercialConstructionData?: {
    __typename?: 'GetOptaIclarifyCommercialConstructionData';
    ok?: boolean | null;
    optaIclarifyCommercialConstructionData?: {
      __typename?: 'OptaIclarifyCommercialConstructionDataType';
      Address?: {
        __typename?: 'OptaAddressType';
        StreetAddress?: string | null;
        StreetNumber?: string | null;
        StreetName?: string | null;
        StreetType?: string | null;
        StreetDirection?: string | null;
        UnitNumber?: string | null;
        Municipality?: string | null;
        Province?: string | null;
        PostalCode?: string | null;
        LegalDescription?: {
          __typename?: 'LegalDescriptionType';
          Quarter?: string | null;
          LegalSubdivision?: string | null;
          Section?: string | null;
          Township?: string | null;
          Range?: string | null;
          Meridian?: string | null;
        } | null;
      } | null;
      Messages?: {
        __typename?: 'Messages';
        Message?: Array<string | null> | null;
      } | null;
      Property?: {
        __typename?: 'CommercialPropertyType';
        LotSize?: number | null;
        LotSizeUnitOfMeasurement?: string | null;
        PropertyType?: string | null;
        Buildings?: {
          __typename?: 'BuildingsType';
          Building: Array<{
            __typename?: 'BuildingType';
            StructureID?: string | null;
            ConstructionFeatures?: {
              __typename?: 'CommercialConstructionFeaturesType';
              CombustibilityClass?: string | null;
              ConstructionClass?: string | null;
              FloorHeight?: number | null;
              FloorHeightUnitOfMeasurement?: string | null;
              LoadingDockPresent?: boolean | null;
              NumberOfStoreys?: number | null;
              NumberOfUnits?: number | null;
              TotalFloorArea?: number | null;
              TotalFloorAreaUnitOfMeasurement?: string | null;
              YearBuilt?: number | null;
              Basement?: {
                __typename?: 'CommercialBasementType';
                BasementArea?: number | null;
                BasementAreaUnitOfMeasurement?: string | null;
                BasementPresent?: boolean | null;
              } | null;
              BuildingUpdates?: {
                __typename?: 'BuildingUpdatesType';
                BuildingUpdate?: Array<number | null> | null;
              } | null;
              CommercialExteriorWallTypes?: {
                __typename?: 'CommercialExteriorWallTypesType';
                ExteriorWallType?: Array<string | null> | null;
              } | null;
              Elevator?: {
                __typename?: 'ElevatorType';
                ElevatorCount?: number | null;
                ElevatorPresent?: boolean | null;
              } | null;
              Heating?: {
                __typename?: 'CommercialHeatingType';
                HeatingFuelSources?: {
                  __typename?: 'HeatingFuelSourcesType';
                  HeatingFuelSource?: Array<string | null> | null;
                } | null;
                HeatingTypes?: {
                  __typename?: 'HeatingTypesType';
                  HeatingType?: Array<string | null> | null;
                } | null;
              } | null;
              Parking?: {
                __typename?: 'CommercialParkingType';
                ParkingPresent?: boolean | null;
                ParkingTypes?: {
                  __typename?: 'CommercialParkingTypesType';
                  ParkingType?: Array<string | null> | null;
                } | null;
              } | null;
              PlumbingTypes?: {
                __typename?: 'CommercialPlumbingType';
                PlumbingType?: Array<string | null> | null;
              } | null;
              RoofAttributes?: {
                __typename?: 'RoofAttributesType';
                RoofConstructions?: {
                  __typename?: 'RoofConstructionsType';
                  RoofConstruction?: Array<string | null> | null;
                } | null;
                RoofSurfaces?: {
                  __typename?: 'RoofSurfacesType';
                  RoofSurface?: Array<string | null> | null;
                } | null;
                RoofTypes?: {
                  __typename?: 'RoofTypesType';
                  RoofType?: Array<string | null> | null;
                } | null;
              } | null;
              WaterHeater?: {
                __typename?: 'CommercialWaterHeaterType';
                WaterHeaterFuelType?: Array<string | null> | null;
                WaterHeaterType?: Array<string | null> | null;
              } | null;
              Wiring?: {
                __typename?: 'CommercialWiringType';
                WiringPanelTypes?: {
                  __typename?: 'CommercialWiringPanelTypesType';
                  WiringPanelType?: Array<string | null> | null;
                } | null;
                WiringTypes?: {
                  __typename?: 'CommercialWiringTypesType';
                  WiringType?: Array<string | null> | null;
                } | null;
              } | null;
            } | null;
            Protection?: {
              __typename?: 'ProtectionType';
              FireAlarmPresent?: boolean | null;
              FireAlarmType?: string | null;
              PhysicalSecurityPresent?: boolean | null;
              SecurityAlarmPresent?: boolean | null;
              SecurityAlarmType?: string | null;
              SprinklerPresent?: boolean | null;
              SprinklerTypeCode?: string | null;
              PhysicalSecurityTypes?: {
                __typename?: 'PhysicalSecurityTypesType';
                PhysicalSecurityType?: Array<string | null> | null;
              } | null;
            } | null;
          }>;
        } | null;
        Industry?: {
          __typename?: 'BusinessIndustryType';
          IBCCode?: string | null;
          IBCIndustryDescription?: string | null;
          SICIndustryDescription?: string | null;
          SICCode?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type QuoteQueryVariables = Exact<{
  uID: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
}>;

export type QuoteQuery = {
  __typename?: 'Query';
  quote?: {
    __typename?: 'QuoteType';
    id: string;
    uniqueID: any;
    friendlyId?: string | null;
    additionalInformation: string;
    producerId: string;
    QuoteStatus: string;
    language?: {
      __typename?: 'LanguageType';
      shortName: string;
      fullName: string;
    } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
    } | null;
    structuredData?: {
      __typename?: 'StructuredQuoteDataType';
      businessLegalName?: string | null;
      businessName?: string | null;
      businessTradeName?: string | null;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      revenue?: number | null;
      alreadyContactedBrokerName?: string | null;
      contactAddress?: {
        __typename?: 'AddressType';
        formattedAddress?: string | null;
      } | null;
    } | null;
    completedForms: Array<{
      __typename?: 'CompletedFormType';
      id: string;
      policy?: {
        __typename?: 'PolicyType';
        id: string;
        displayName: string;
        internalName: string;
      } | null;
      extensions?: Array<{
        __typename?: 'PolicyExtensionType';
        id: string;
        label: string;
        multiplier?: string | null;
        questionId?: string | null;
        value?: string | null;
      } | null> | null;
      answers: Array<{
        __typename?: 'AnswerInstanceType';
        id: string;
        apiName: string;
        displayName: string;
        label: string;
        helpText?: string | null;
        component: string;
        propsBlob?: any | null;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        value: string;
        date: any;
        method?: string | null;
        dataType: string;
        defaultValue?: string | null;
        subAnswers: Array<{
          __typename?: 'AnswerInstanceType';
          id: string;
          apiName: string;
          displayName: string;
          label: string;
          helpText?: string | null;
          component: string;
          propsBlob?: any | null;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          value: string;
          date: any;
          method?: string | null;
          dataType: string;
          defaultValue?: string | null;
          subAnswers: Array<{
            __typename?: 'AnswerInstanceType';
            id: string;
            apiName: string;
            displayName: string;
            label: string;
            helpText?: string | null;
            component: string;
            propsBlob?: any | null;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            value: string;
            date: any;
            method?: string | null;
            dataType: string;
            defaultValue?: string | null;
            subAnswers: Array<{
              __typename?: 'AnswerInstanceType';
              id: string;
              apiName: string;
              displayName: string;
              label: string;
              helpText?: string | null;
              component: string;
              propsBlob?: any | null;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              value: string;
              date: any;
              method?: string | null;
              dataType: string;
              defaultValue?: string | null;
              subAnswers: Array<{
                __typename?: 'AnswerInstanceType';
                id: string;
                apiName: string;
                displayName: string;
                label: string;
                helpText?: string | null;
                component: string;
                propsBlob?: any | null;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                value: string;
                date: any;
                method?: string | null;
                dataType: string;
                defaultValue?: string | null;
                subAnswers: Array<{
                  __typename?: 'AnswerInstanceType';
                  id: string;
                  apiName: string;
                  displayName: string;
                  label: string;
                  helpText?: string | null;
                  component: string;
                  propsBlob?: any | null;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  value: string;
                  date: any;
                  method?: string | null;
                  dataType: string;
                  defaultValue?: string | null;
                  subAnswers: Array<{
                    __typename?: 'AnswerInstanceType';
                    id: string;
                    apiName: string;
                    displayName: string;
                    label: string;
                    helpText?: string | null;
                    component: string;
                    propsBlob?: any | null;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    value: string;
                    date: any;
                    method?: string | null;
                    dataType: string;
                    defaultValue?: string | null;
                    subAnswers: Array<{
                      __typename?: 'AnswerInstanceType';
                      id: string;
                      apiName: string;
                      displayName: string;
                      label: string;
                      helpText?: string | null;
                      component: string;
                      propsBlob?: any | null;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      value: string;
                      date: any;
                      method?: string | null;
                      dataType: string;
                      defaultValue?: string | null;
                      subAnswers: Array<{
                        __typename?: 'AnswerInstanceType';
                        id: string;
                        apiName: string;
                        displayName: string;
                        label: string;
                        helpText?: string | null;
                        component: string;
                        propsBlob?: any | null;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        value: string;
                        date: any;
                        method?: string | null;
                        dataType: string;
                        defaultValue?: string | null;
                        subAnswers: Array<{
                          __typename?: 'AnswerInstanceType';
                          id: string;
                          apiName: string;
                          displayName: string;
                          label: string;
                          helpText?: string | null;
                          component: string;
                          propsBlob?: any | null;
                          required: boolean;
                          askOnRenewal: boolean;
                          prefillOnRenewal: boolean;
                          value: string;
                          date: any;
                          method?: string | null;
                          dataType: string;
                          defaultValue?: string | null;
                          details?: {
                            __typename?: 'AnswerInstanceDetailsType';
                            lat?: number | null;
                            lng?: number | null;
                            images: Array<{
                              __typename?: 'GoogleImageType';
                              description: string;
                              shortDescription: string;
                              category: CoreGoogleImageCategoryChoices;
                              data?: string | null;
                            }>;
                            google?: {
                              __typename?: 'GoogleDetailsType';
                              placeId?: string | null;
                              rating?: number | null;
                              numberOfRatings?: number | null;
                            } | null;
                            opta?: {
                              __typename?: 'OPTADetailsType';
                              optaFusData?: {
                                __typename?: 'OptaFusDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                Commercial?: {
                                  __typename?: 'FusProductCommercialType';
                                  SearchMessage?: string | null;
                                  CommercialGrade: Array<{
                                    __typename?: 'CommercialGradeType';
                                    PublicFireProtectionClassification?:
                                      | number
                                      | null;
                                    WaterSystem?: string | null;
                                    RiskSCORDetail: Array<{
                                      __typename?: 'RiskSCORDetailType';
                                      RiskSCOR?: number | null;
                                      Type?: string | null;
                                      Adjustment: Array<{
                                        __typename?: 'RiskSCORAdjustmentType';
                                        Adjustment?: string | null;
                                        AdjustmentType?: string | null;
                                      }>;
                                    }>;
                                  }>;
                                  RespondingFirehall?: {
                                    __typename?: 'FirehallType';
                                    Address?: string | null;
                                    GoverningAgency?: string | null;
                                    GradingDate?: any | null;
                                    Name?: string | null;
                                    PumperFlowImperialGallonsPerMinute?:
                                      | number
                                      | null;
                                    RoadDistanceKilometres?: number | null;
                                    SuperiorTankerServiceAccreditationDate?:
                                      | any
                                      | null;
                                    FirefightersPerFireStation?: {
                                      __typename?: 'FirefightersPerFireStationType';
                                      FullTime?: number | null;
                                      Volunteer?: number | null;
                                    } | null;
                                    ProtectionGradeTerritories?: {
                                      __typename?: 'ProtectedGradeTerritoriesType';
                                      Commercial?: string | null;
                                      Dwelling?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Dwelling?: {
                                  __typename?: 'FusProductDwellingType';
                                  SearchMessage?: string | null;
                                  DwellingGrades?: {
                                    __typename?: 'DwellingGradesType';
                                    AllOtherAreasGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                    FirehallProtectedGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                    HydrantProtectedGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                  } | null;
                                  RespondingFirehall?: {
                                    __typename?: 'FirehallType';
                                    Address?: string | null;
                                    GoverningAgency?: string | null;
                                    GradingDate?: any | null;
                                    Name?: string | null;
                                    PumperFlowImperialGallonsPerMinute?:
                                      | number
                                      | null;
                                    RoadDistanceKilometres?: number | null;
                                    SuperiorTankerServiceAccreditationDate?:
                                      | any
                                      | null;
                                    FirefightersPerFireStation?: {
                                      __typename?: 'FirefightersPerFireStationType';
                                      FullTime?: number | null;
                                      Volunteer?: number | null;
                                    } | null;
                                    ProtectionGradeTerritories?: {
                                      __typename?: 'ProtectedGradeTerritoriesType';
                                      Commercial?: string | null;
                                      Dwelling?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                EarthquakeCodes?: {
                                  __typename?: 'EarthquakeCodesType';
                                  SearchMessage?: string | null;
                                  EarthquakeCode: Array<{
                                    __typename?: 'EarthquakeCodeType';
                                    Description?: string | null;
                                    SystemName?: string | null;
                                    Zone?: string | null;
                                  }>;
                                } | null;
                                FusMaps?: {
                                  __typename?: 'FusMapsType';
                                  SearchMessage?: string | null;
                                  FusMap: Array<{
                                    __typename?: 'FusMapType';
                                    Business?: string | null;
                                    FusContent?: {
                                      __typename?: 'ContentType';
                                      ContentURL?: string | null;
                                      Expiry?: any | null;
                                      MIMEType?: string | null;
                                    } | null;
                                  }>;
                                } | null;
                                HydrantCounts?: {
                                  __typename?: 'HydrantCountsType';
                                  SearchMessage?: string | null;
                                  HydrantCount: Array<{
                                    __typename?: 'HydrantCountType';
                                    NumberOfHydrants: number;
                                    Ownership?: string | null;
                                    RadiusMetres?: string | null;
                                  }>;
                                } | null;
                              } | null;
                              optaFloodscoreData?: {
                                __typename?: 'OptaFloodScoreDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                FloodScores?: {
                                  __typename?: 'FloodScoresType';
                                  FloodScore: Array<{
                                    __typename?: 'FloodScoreType';
                                    AverageAnnualLossScore?: number | null;
                                    Deductible?: number | null;
                                    Default?: boolean | null;
                                    FloodScore?: number | null;
                                    Limit?: number | null;
                                    RateFactor?: number | null;
                                    Zone?: string | null;
                                    ResidentialModifiers?: {
                                      __typename?: 'ResidentialConstructionFeaturesType';
                                      ArchitecturalStyleType?: string | null;
                                      AuxiliaryHeatingType?: string | null;
                                      BathroomCount?: string | null;
                                      CommercialIndicator?: string | null;
                                      ExteriorWallType?: string | null;
                                      FinishedBasement?: boolean | null;
                                      FoundationType?: string | null;
                                      GarageNumberOfCars?: number | null;
                                      GarageType?: string | null;
                                      KitchenCount?: string | null;
                                      OutbuildingPresent?: string | null;
                                      PlumbingType?: string | null;
                                      PrimaryHeatingType?: string | null;
                                      RoofType?: string | null;
                                      SquareFootage?: number | null;
                                      StoreyCount?: string | null;
                                      SwimmingPoolType?: string | null;
                                      YearBuilt?: string | null;
                                      InspectionDetails?: {
                                        __typename?: 'InspectionDetailsType';
                                        InspectionDate?: any | null;
                                        SponsoredInspection?: boolean | null;
                                      } | null;
                                    } | null;
                                    MultiResidentialModifiers?: {
                                      __typename?: 'MultiResidentialConstructionFeaturesType';
                                      BasementPresent?: boolean | null;
                                      ConstructionType?: string | null;
                                      FloorLevel?: number | null;
                                    } | null;
                                    AverageAnnualLossValues?: {
                                      __typename?: 'FloodValueType';
                                      Building?: number | null;
                                      Contents?: number | null;
                                      Total?: number | null;
                                    } | null;
                                    AdditionalDetails: Array<{
                                      __typename?: 'FloodDetailType';
                                      Key?: string | null;
                                      Value?: string | null;
                                    }>;
                                    Premium?: {
                                      __typename?: 'FloodValueType';
                                      Building?: number | null;
                                      Contents?: number | null;
                                      Total?: number | null;
                                    } | null;
                                  }>;
                                  FloodMap: Array<{
                                    __typename?: 'FloodMapType';
                                    FloodEvent?: string | null;
                                    Content?: {
                                      __typename?: 'ContentType';
                                      ContentURL?: string | null;
                                      Expiry?: any | null;
                                      MIMEType?: string | null;
                                    } | null;
                                  }>;
                                } | null;
                              } | null;
                              optaIclarifyCommercialConstructionData?: {
                                __typename?: 'OptaIclarifyCommercialConstructionDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                Messages?: {
                                  __typename?: 'Messages';
                                  Message?: Array<string | null> | null;
                                } | null;
                                Property?: {
                                  __typename?: 'CommercialPropertyType';
                                  LotSize?: number | null;
                                  LotSizeUnitOfMeasurement?: string | null;
                                  PropertyType?: string | null;
                                  Buildings?: {
                                    __typename?: 'BuildingsType';
                                    Building: Array<{
                                      __typename?: 'BuildingType';
                                      StructureID?: string | null;
                                      ConstructionFeatures?: {
                                        __typename?: 'CommercialConstructionFeaturesType';
                                        CombustibilityClass?: string | null;
                                        ConstructionClass?: string | null;
                                        FloorHeight?: number | null;
                                        FloorHeightUnitOfMeasurement?:
                                          | string
                                          | null;
                                        LoadingDockPresent?: boolean | null;
                                        NumberOfStoreys?: number | null;
                                        NumberOfUnits?: number | null;
                                        TotalFloorArea?: number | null;
                                        TotalFloorAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        YearBuilt?: number | null;
                                        Basement?: {
                                          __typename?: 'CommercialBasementType';
                                          BasementArea?: number | null;
                                          BasementAreaUnitOfMeasurement?:
                                            | string
                                            | null;
                                          BasementPresent?: boolean | null;
                                        } | null;
                                        BuildingUpdates?: {
                                          __typename?: 'BuildingUpdatesType';
                                          BuildingUpdate?: Array<
                                            number | null
                                          > | null;
                                        } | null;
                                        CommercialExteriorWallTypes?: {
                                          __typename?: 'CommercialExteriorWallTypesType';
                                          ExteriorWallType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        Elevator?: {
                                          __typename?: 'ElevatorType';
                                          ElevatorCount?: number | null;
                                          ElevatorPresent?: boolean | null;
                                        } | null;
                                        Heating?: {
                                          __typename?: 'CommercialHeatingType';
                                          HeatingFuelSources?: {
                                            __typename?: 'HeatingFuelSourcesType';
                                            HeatingFuelSource?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          HeatingTypes?: {
                                            __typename?: 'HeatingTypesType';
                                            HeatingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        Parking?: {
                                          __typename?: 'CommercialParkingType';
                                          ParkingPresent?: boolean | null;
                                          ParkingTypes?: {
                                            __typename?: 'CommercialParkingTypesType';
                                            ParkingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        PlumbingTypes?: {
                                          __typename?: 'CommercialPlumbingType';
                                          PlumbingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofAttributes?: {
                                          __typename?: 'RoofAttributesType';
                                          RoofConstructions?: {
                                            __typename?: 'RoofConstructionsType';
                                            RoofConstruction?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofSurfaces?: {
                                            __typename?: 'RoofSurfacesType';
                                            RoofSurface?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofTypes?: {
                                            __typename?: 'RoofTypesType';
                                            RoofType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        WaterHeater?: {
                                          __typename?: 'CommercialWaterHeaterType';
                                          WaterHeaterFuelType?: Array<
                                            string | null
                                          > | null;
                                          WaterHeaterType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        Wiring?: {
                                          __typename?: 'CommercialWiringType';
                                          WiringPanelTypes?: {
                                            __typename?: 'CommercialWiringPanelTypesType';
                                            WiringPanelType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          WiringTypes?: {
                                            __typename?: 'CommercialWiringTypesType';
                                            WiringType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      Protection?: {
                                        __typename?: 'ProtectionType';
                                        FireAlarmPresent?: boolean | null;
                                        FireAlarmType?: string | null;
                                        PhysicalSecurityPresent?:
                                          | boolean
                                          | null;
                                        SecurityAlarmPresent?: boolean | null;
                                        SecurityAlarmType?: string | null;
                                        SprinklerPresent?: boolean | null;
                                        SprinklerTypeCode?: string | null;
                                        PhysicalSecurityTypes?: {
                                          __typename?: 'PhysicalSecurityTypesType';
                                          PhysicalSecurityType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    }>;
                                  } | null;
                                  Industry?: {
                                    __typename?: 'BusinessIndustryType';
                                    IBCCode?: string | null;
                                    IBCIndustryDescription?: string | null;
                                    SICIndustryDescription?: string | null;
                                    SICCode?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              optaCommercialPerilScoreData?: {
                                __typename?: 'OptaCommercialPerilScoreDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                PropertyPerilScores?: {
                                  __typename?: 'PropertyCommercialPerilsType';
                                  Peril?: Array<{
                                    __typename?: 'PerilDetailsType';
                                    LossCostScore?: number | null;
                                    PerilType?: string | null;
                                    PredictedLossCost?: number | null;
                                  } | null> | null;
                                } | null;
                                CasualtyPerilScores?: {
                                  __typename?: 'CasualtyCommercialPerilsType';
                                  Peril?: Array<{
                                    __typename?: 'PerilDetailsType';
                                    LossCostScore?: number | null;
                                    PerilType?: string | null;
                                    PredictedLossCost?: number | null;
                                  } | null> | null;
                                } | null;
                                AllPerilsScore?: {
                                  __typename?: 'CommercialPerilScoreType';
                                  LossCostScore?: number | null;
                                  PredictedLossCost?: number | null;
                                } | null;
                              } | null;
                            } | null;
                            openingHours: Array<{
                              __typename?: 'OpeningHoursType';
                              day: number;
                              open?: any | null;
                              close?: any | null;
                            }>;
                          } | null;
                        }>;
                        details?: {
                          __typename?: 'AnswerInstanceDetailsType';
                          lat?: number | null;
                          lng?: number | null;
                          images: Array<{
                            __typename?: 'GoogleImageType';
                            description: string;
                            shortDescription: string;
                            category: CoreGoogleImageCategoryChoices;
                            data?: string | null;
                          }>;
                          google?: {
                            __typename?: 'GoogleDetailsType';
                            placeId?: string | null;
                            rating?: number | null;
                            numberOfRatings?: number | null;
                          } | null;
                          opta?: {
                            __typename?: 'OPTADetailsType';
                            optaFusData?: {
                              __typename?: 'OptaFusDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Commercial?: {
                                __typename?: 'FusProductCommercialType';
                                SearchMessage?: string | null;
                                CommercialGrade: Array<{
                                  __typename?: 'CommercialGradeType';
                                  PublicFireProtectionClassification?:
                                    | number
                                    | null;
                                  WaterSystem?: string | null;
                                  RiskSCORDetail: Array<{
                                    __typename?: 'RiskSCORDetailType';
                                    RiskSCOR?: number | null;
                                    Type?: string | null;
                                    Adjustment: Array<{
                                      __typename?: 'RiskSCORAdjustmentType';
                                      Adjustment?: string | null;
                                      AdjustmentType?: string | null;
                                    }>;
                                  }>;
                                }>;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              Dwelling?: {
                                __typename?: 'FusProductDwellingType';
                                SearchMessage?: string | null;
                                DwellingGrades?: {
                                  __typename?: 'DwellingGradesType';
                                  AllOtherAreasGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  FirehallProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  HydrantProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                } | null;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              EarthquakeCodes?: {
                                __typename?: 'EarthquakeCodesType';
                                SearchMessage?: string | null;
                                EarthquakeCode: Array<{
                                  __typename?: 'EarthquakeCodeType';
                                  Description?: string | null;
                                  SystemName?: string | null;
                                  Zone?: string | null;
                                }>;
                              } | null;
                              FusMaps?: {
                                __typename?: 'FusMapsType';
                                SearchMessage?: string | null;
                                FusMap: Array<{
                                  __typename?: 'FusMapType';
                                  Business?: string | null;
                                  FusContent?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                              HydrantCounts?: {
                                __typename?: 'HydrantCountsType';
                                SearchMessage?: string | null;
                                HydrantCount: Array<{
                                  __typename?: 'HydrantCountType';
                                  NumberOfHydrants: number;
                                  Ownership?: string | null;
                                  RadiusMetres?: string | null;
                                }>;
                              } | null;
                            } | null;
                            optaFloodscoreData?: {
                              __typename?: 'OptaFloodScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              FloodScores?: {
                                __typename?: 'FloodScoresType';
                                FloodScore: Array<{
                                  __typename?: 'FloodScoreType';
                                  AverageAnnualLossScore?: number | null;
                                  Deductible?: number | null;
                                  Default?: boolean | null;
                                  FloodScore?: number | null;
                                  Limit?: number | null;
                                  RateFactor?: number | null;
                                  Zone?: string | null;
                                  ResidentialModifiers?: {
                                    __typename?: 'ResidentialConstructionFeaturesType';
                                    ArchitecturalStyleType?: string | null;
                                    AuxiliaryHeatingType?: string | null;
                                    BathroomCount?: string | null;
                                    CommercialIndicator?: string | null;
                                    ExteriorWallType?: string | null;
                                    FinishedBasement?: boolean | null;
                                    FoundationType?: string | null;
                                    GarageNumberOfCars?: number | null;
                                    GarageType?: string | null;
                                    KitchenCount?: string | null;
                                    OutbuildingPresent?: string | null;
                                    PlumbingType?: string | null;
                                    PrimaryHeatingType?: string | null;
                                    RoofType?: string | null;
                                    SquareFootage?: number | null;
                                    StoreyCount?: string | null;
                                    SwimmingPoolType?: string | null;
                                    YearBuilt?: string | null;
                                    InspectionDetails?: {
                                      __typename?: 'InspectionDetailsType';
                                      InspectionDate?: any | null;
                                      SponsoredInspection?: boolean | null;
                                    } | null;
                                  } | null;
                                  MultiResidentialModifiers?: {
                                    __typename?: 'MultiResidentialConstructionFeaturesType';
                                    BasementPresent?: boolean | null;
                                    ConstructionType?: string | null;
                                    FloorLevel?: number | null;
                                  } | null;
                                  AverageAnnualLossValues?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                  AdditionalDetails: Array<{
                                    __typename?: 'FloodDetailType';
                                    Key?: string | null;
                                    Value?: string | null;
                                  }>;
                                  Premium?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                }>;
                                FloodMap: Array<{
                                  __typename?: 'FloodMapType';
                                  FloodEvent?: string | null;
                                  Content?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            optaIclarifyCommercialConstructionData?: {
                              __typename?: 'OptaIclarifyCommercialConstructionDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Messages?: {
                                __typename?: 'Messages';
                                Message?: Array<string | null> | null;
                              } | null;
                              Property?: {
                                __typename?: 'CommercialPropertyType';
                                LotSize?: number | null;
                                LotSizeUnitOfMeasurement?: string | null;
                                PropertyType?: string | null;
                                Buildings?: {
                                  __typename?: 'BuildingsType';
                                  Building: Array<{
                                    __typename?: 'BuildingType';
                                    StructureID?: string | null;
                                    ConstructionFeatures?: {
                                      __typename?: 'CommercialConstructionFeaturesType';
                                      CombustibilityClass?: string | null;
                                      ConstructionClass?: string | null;
                                      FloorHeight?: number | null;
                                      FloorHeightUnitOfMeasurement?:
                                        | string
                                        | null;
                                      LoadingDockPresent?: boolean | null;
                                      NumberOfStoreys?: number | null;
                                      NumberOfUnits?: number | null;
                                      TotalFloorArea?: number | null;
                                      TotalFloorAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      YearBuilt?: number | null;
                                      Basement?: {
                                        __typename?: 'CommercialBasementType';
                                        BasementArea?: number | null;
                                        BasementAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        BasementPresent?: boolean | null;
                                      } | null;
                                      BuildingUpdates?: {
                                        __typename?: 'BuildingUpdatesType';
                                        BuildingUpdate?: Array<
                                          number | null
                                        > | null;
                                      } | null;
                                      CommercialExteriorWallTypes?: {
                                        __typename?: 'CommercialExteriorWallTypesType';
                                        ExteriorWallType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Elevator?: {
                                        __typename?: 'ElevatorType';
                                        ElevatorCount?: number | null;
                                        ElevatorPresent?: boolean | null;
                                      } | null;
                                      Heating?: {
                                        __typename?: 'CommercialHeatingType';
                                        HeatingFuelSources?: {
                                          __typename?: 'HeatingFuelSourcesType';
                                          HeatingFuelSource?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        HeatingTypes?: {
                                          __typename?: 'HeatingTypesType';
                                          HeatingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      Parking?: {
                                        __typename?: 'CommercialParkingType';
                                        ParkingPresent?: boolean | null;
                                        ParkingTypes?: {
                                          __typename?: 'CommercialParkingTypesType';
                                          ParkingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      PlumbingTypes?: {
                                        __typename?: 'CommercialPlumbingType';
                                        PlumbingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofAttributes?: {
                                        __typename?: 'RoofAttributesType';
                                        RoofConstructions?: {
                                          __typename?: 'RoofConstructionsType';
                                          RoofConstruction?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofSurfaces?: {
                                          __typename?: 'RoofSurfacesType';
                                          RoofSurface?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofTypes?: {
                                          __typename?: 'RoofTypesType';
                                          RoofType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      WaterHeater?: {
                                        __typename?: 'CommercialWaterHeaterType';
                                        WaterHeaterFuelType?: Array<
                                          string | null
                                        > | null;
                                        WaterHeaterType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Wiring?: {
                                        __typename?: 'CommercialWiringType';
                                        WiringPanelTypes?: {
                                          __typename?: 'CommercialWiringPanelTypesType';
                                          WiringPanelType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        WiringTypes?: {
                                          __typename?: 'CommercialWiringTypesType';
                                          WiringType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    Protection?: {
                                      __typename?: 'ProtectionType';
                                      FireAlarmPresent?: boolean | null;
                                      FireAlarmType?: string | null;
                                      PhysicalSecurityPresent?: boolean | null;
                                      SecurityAlarmPresent?: boolean | null;
                                      SecurityAlarmType?: string | null;
                                      SprinklerPresent?: boolean | null;
                                      SprinklerTypeCode?: string | null;
                                      PhysicalSecurityTypes?: {
                                        __typename?: 'PhysicalSecurityTypesType';
                                        PhysicalSecurityType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  }>;
                                } | null;
                                Industry?: {
                                  __typename?: 'BusinessIndustryType';
                                  IBCCode?: string | null;
                                  IBCIndustryDescription?: string | null;
                                  SICIndustryDescription?: string | null;
                                  SICCode?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            optaCommercialPerilScoreData?: {
                              __typename?: 'OptaCommercialPerilScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              PropertyPerilScores?: {
                                __typename?: 'PropertyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              CasualtyPerilScores?: {
                                __typename?: 'CasualtyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              AllPerilsScore?: {
                                __typename?: 'CommercialPerilScoreType';
                                LossCostScore?: number | null;
                                PredictedLossCost?: number | null;
                              } | null;
                            } | null;
                          } | null;
                          openingHours: Array<{
                            __typename?: 'OpeningHoursType';
                            day: number;
                            open?: any | null;
                            close?: any | null;
                          }>;
                        } | null;
                      }>;
                      details?: {
                        __typename?: 'AnswerInstanceDetailsType';
                        lat?: number | null;
                        lng?: number | null;
                        images: Array<{
                          __typename?: 'GoogleImageType';
                          description: string;
                          shortDescription: string;
                          category: CoreGoogleImageCategoryChoices;
                          data?: string | null;
                        }>;
                        google?: {
                          __typename?: 'GoogleDetailsType';
                          placeId?: string | null;
                          rating?: number | null;
                          numberOfRatings?: number | null;
                        } | null;
                        opta?: {
                          __typename?: 'OPTADetailsType';
                          optaFusData?: {
                            __typename?: 'OptaFusDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Commercial?: {
                              __typename?: 'FusProductCommercialType';
                              SearchMessage?: string | null;
                              CommercialGrade: Array<{
                                __typename?: 'CommercialGradeType';
                                PublicFireProtectionClassification?:
                                  | number
                                  | null;
                                WaterSystem?: string | null;
                                RiskSCORDetail: Array<{
                                  __typename?: 'RiskSCORDetailType';
                                  RiskSCOR?: number | null;
                                  Type?: string | null;
                                  Adjustment: Array<{
                                    __typename?: 'RiskSCORAdjustmentType';
                                    Adjustment?: string | null;
                                    AdjustmentType?: string | null;
                                  }>;
                                }>;
                              }>;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            Dwelling?: {
                              __typename?: 'FusProductDwellingType';
                              SearchMessage?: string | null;
                              DwellingGrades?: {
                                __typename?: 'DwellingGradesType';
                                AllOtherAreasGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                FirehallProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                HydrantProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                              } | null;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            EarthquakeCodes?: {
                              __typename?: 'EarthquakeCodesType';
                              SearchMessage?: string | null;
                              EarthquakeCode: Array<{
                                __typename?: 'EarthquakeCodeType';
                                Description?: string | null;
                                SystemName?: string | null;
                                Zone?: string | null;
                              }>;
                            } | null;
                            FusMaps?: {
                              __typename?: 'FusMapsType';
                              SearchMessage?: string | null;
                              FusMap: Array<{
                                __typename?: 'FusMapType';
                                Business?: string | null;
                                FusContent?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                            HydrantCounts?: {
                              __typename?: 'HydrantCountsType';
                              SearchMessage?: string | null;
                              HydrantCount: Array<{
                                __typename?: 'HydrantCountType';
                                NumberOfHydrants: number;
                                Ownership?: string | null;
                                RadiusMetres?: string | null;
                              }>;
                            } | null;
                          } | null;
                          optaFloodscoreData?: {
                            __typename?: 'OptaFloodScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            FloodScores?: {
                              __typename?: 'FloodScoresType';
                              FloodScore: Array<{
                                __typename?: 'FloodScoreType';
                                AverageAnnualLossScore?: number | null;
                                Deductible?: number | null;
                                Default?: boolean | null;
                                FloodScore?: number | null;
                                Limit?: number | null;
                                RateFactor?: number | null;
                                Zone?: string | null;
                                ResidentialModifiers?: {
                                  __typename?: 'ResidentialConstructionFeaturesType';
                                  ArchitecturalStyleType?: string | null;
                                  AuxiliaryHeatingType?: string | null;
                                  BathroomCount?: string | null;
                                  CommercialIndicator?: string | null;
                                  ExteriorWallType?: string | null;
                                  FinishedBasement?: boolean | null;
                                  FoundationType?: string | null;
                                  GarageNumberOfCars?: number | null;
                                  GarageType?: string | null;
                                  KitchenCount?: string | null;
                                  OutbuildingPresent?: string | null;
                                  PlumbingType?: string | null;
                                  PrimaryHeatingType?: string | null;
                                  RoofType?: string | null;
                                  SquareFootage?: number | null;
                                  StoreyCount?: string | null;
                                  SwimmingPoolType?: string | null;
                                  YearBuilt?: string | null;
                                  InspectionDetails?: {
                                    __typename?: 'InspectionDetailsType';
                                    InspectionDate?: any | null;
                                    SponsoredInspection?: boolean | null;
                                  } | null;
                                } | null;
                                MultiResidentialModifiers?: {
                                  __typename?: 'MultiResidentialConstructionFeaturesType';
                                  BasementPresent?: boolean | null;
                                  ConstructionType?: string | null;
                                  FloorLevel?: number | null;
                                } | null;
                                AverageAnnualLossValues?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                                AdditionalDetails: Array<{
                                  __typename?: 'FloodDetailType';
                                  Key?: string | null;
                                  Value?: string | null;
                                }>;
                                Premium?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                              }>;
                              FloodMap: Array<{
                                __typename?: 'FloodMapType';
                                FloodEvent?: string | null;
                                Content?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          optaIclarifyCommercialConstructionData?: {
                            __typename?: 'OptaIclarifyCommercialConstructionDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Messages?: {
                              __typename?: 'Messages';
                              Message?: Array<string | null> | null;
                            } | null;
                            Property?: {
                              __typename?: 'CommercialPropertyType';
                              LotSize?: number | null;
                              LotSizeUnitOfMeasurement?: string | null;
                              PropertyType?: string | null;
                              Buildings?: {
                                __typename?: 'BuildingsType';
                                Building: Array<{
                                  __typename?: 'BuildingType';
                                  StructureID?: string | null;
                                  ConstructionFeatures?: {
                                    __typename?: 'CommercialConstructionFeaturesType';
                                    CombustibilityClass?: string | null;
                                    ConstructionClass?: string | null;
                                    FloorHeight?: number | null;
                                    FloorHeightUnitOfMeasurement?:
                                      | string
                                      | null;
                                    LoadingDockPresent?: boolean | null;
                                    NumberOfStoreys?: number | null;
                                    NumberOfUnits?: number | null;
                                    TotalFloorArea?: number | null;
                                    TotalFloorAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    YearBuilt?: number | null;
                                    Basement?: {
                                      __typename?: 'CommercialBasementType';
                                      BasementArea?: number | null;
                                      BasementAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      BasementPresent?: boolean | null;
                                    } | null;
                                    BuildingUpdates?: {
                                      __typename?: 'BuildingUpdatesType';
                                      BuildingUpdate?: Array<
                                        number | null
                                      > | null;
                                    } | null;
                                    CommercialExteriorWallTypes?: {
                                      __typename?: 'CommercialExteriorWallTypesType';
                                      ExteriorWallType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Elevator?: {
                                      __typename?: 'ElevatorType';
                                      ElevatorCount?: number | null;
                                      ElevatorPresent?: boolean | null;
                                    } | null;
                                    Heating?: {
                                      __typename?: 'CommercialHeatingType';
                                      HeatingFuelSources?: {
                                        __typename?: 'HeatingFuelSourcesType';
                                        HeatingFuelSource?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      HeatingTypes?: {
                                        __typename?: 'HeatingTypesType';
                                        HeatingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    Parking?: {
                                      __typename?: 'CommercialParkingType';
                                      ParkingPresent?: boolean | null;
                                      ParkingTypes?: {
                                        __typename?: 'CommercialParkingTypesType';
                                        ParkingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    PlumbingTypes?: {
                                      __typename?: 'CommercialPlumbingType';
                                      PlumbingType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofAttributes?: {
                                      __typename?: 'RoofAttributesType';
                                      RoofConstructions?: {
                                        __typename?: 'RoofConstructionsType';
                                        RoofConstruction?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofSurfaces?: {
                                        __typename?: 'RoofSurfacesType';
                                        RoofSurface?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofTypes?: {
                                        __typename?: 'RoofTypesType';
                                        RoofType?: Array<string | null> | null;
                                      } | null;
                                    } | null;
                                    WaterHeater?: {
                                      __typename?: 'CommercialWaterHeaterType';
                                      WaterHeaterFuelType?: Array<
                                        string | null
                                      > | null;
                                      WaterHeaterType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Wiring?: {
                                      __typename?: 'CommercialWiringType';
                                      WiringPanelTypes?: {
                                        __typename?: 'CommercialWiringPanelTypesType';
                                        WiringPanelType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      WiringTypes?: {
                                        __typename?: 'CommercialWiringTypesType';
                                        WiringType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  Protection?: {
                                    __typename?: 'ProtectionType';
                                    FireAlarmPresent?: boolean | null;
                                    FireAlarmType?: string | null;
                                    PhysicalSecurityPresent?: boolean | null;
                                    SecurityAlarmPresent?: boolean | null;
                                    SecurityAlarmType?: string | null;
                                    SprinklerPresent?: boolean | null;
                                    SprinklerTypeCode?: string | null;
                                    PhysicalSecurityTypes?: {
                                      __typename?: 'PhysicalSecurityTypesType';
                                      PhysicalSecurityType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                  } | null;
                                }>;
                              } | null;
                              Industry?: {
                                __typename?: 'BusinessIndustryType';
                                IBCCode?: string | null;
                                IBCIndustryDescription?: string | null;
                                SICIndustryDescription?: string | null;
                                SICCode?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          optaCommercialPerilScoreData?: {
                            __typename?: 'OptaCommercialPerilScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            PropertyPerilScores?: {
                              __typename?: 'PropertyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            CasualtyPerilScores?: {
                              __typename?: 'CasualtyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            AllPerilsScore?: {
                              __typename?: 'CommercialPerilScoreType';
                              LossCostScore?: number | null;
                              PredictedLossCost?: number | null;
                            } | null;
                          } | null;
                        } | null;
                        openingHours: Array<{
                          __typename?: 'OpeningHoursType';
                          day: number;
                          open?: any | null;
                          close?: any | null;
                        }>;
                      } | null;
                    }>;
                    details?: {
                      __typename?: 'AnswerInstanceDetailsType';
                      lat?: number | null;
                      lng?: number | null;
                      images: Array<{
                        __typename?: 'GoogleImageType';
                        description: string;
                        shortDescription: string;
                        category: CoreGoogleImageCategoryChoices;
                        data?: string | null;
                      }>;
                      google?: {
                        __typename?: 'GoogleDetailsType';
                        placeId?: string | null;
                        rating?: number | null;
                        numberOfRatings?: number | null;
                      } | null;
                      opta?: {
                        __typename?: 'OPTADetailsType';
                        optaFusData?: {
                          __typename?: 'OptaFusDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Commercial?: {
                            __typename?: 'FusProductCommercialType';
                            SearchMessage?: string | null;
                            CommercialGrade: Array<{
                              __typename?: 'CommercialGradeType';
                              PublicFireProtectionClassification?:
                                | number
                                | null;
                              WaterSystem?: string | null;
                              RiskSCORDetail: Array<{
                                __typename?: 'RiskSCORDetailType';
                                RiskSCOR?: number | null;
                                Type?: string | null;
                                Adjustment: Array<{
                                  __typename?: 'RiskSCORAdjustmentType';
                                  Adjustment?: string | null;
                                  AdjustmentType?: string | null;
                                }>;
                              }>;
                            }>;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          Dwelling?: {
                            __typename?: 'FusProductDwellingType';
                            SearchMessage?: string | null;
                            DwellingGrades?: {
                              __typename?: 'DwellingGradesType';
                              AllOtherAreasGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              FirehallProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              HydrantProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                            } | null;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          EarthquakeCodes?: {
                            __typename?: 'EarthquakeCodesType';
                            SearchMessage?: string | null;
                            EarthquakeCode: Array<{
                              __typename?: 'EarthquakeCodeType';
                              Description?: string | null;
                              SystemName?: string | null;
                              Zone?: string | null;
                            }>;
                          } | null;
                          FusMaps?: {
                            __typename?: 'FusMapsType';
                            SearchMessage?: string | null;
                            FusMap: Array<{
                              __typename?: 'FusMapType';
                              Business?: string | null;
                              FusContent?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                          HydrantCounts?: {
                            __typename?: 'HydrantCountsType';
                            SearchMessage?: string | null;
                            HydrantCount: Array<{
                              __typename?: 'HydrantCountType';
                              NumberOfHydrants: number;
                              Ownership?: string | null;
                              RadiusMetres?: string | null;
                            }>;
                          } | null;
                        } | null;
                        optaFloodscoreData?: {
                          __typename?: 'OptaFloodScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          FloodScores?: {
                            __typename?: 'FloodScoresType';
                            FloodScore: Array<{
                              __typename?: 'FloodScoreType';
                              AverageAnnualLossScore?: number | null;
                              Deductible?: number | null;
                              Default?: boolean | null;
                              FloodScore?: number | null;
                              Limit?: number | null;
                              RateFactor?: number | null;
                              Zone?: string | null;
                              ResidentialModifiers?: {
                                __typename?: 'ResidentialConstructionFeaturesType';
                                ArchitecturalStyleType?: string | null;
                                AuxiliaryHeatingType?: string | null;
                                BathroomCount?: string | null;
                                CommercialIndicator?: string | null;
                                ExteriorWallType?: string | null;
                                FinishedBasement?: boolean | null;
                                FoundationType?: string | null;
                                GarageNumberOfCars?: number | null;
                                GarageType?: string | null;
                                KitchenCount?: string | null;
                                OutbuildingPresent?: string | null;
                                PlumbingType?: string | null;
                                PrimaryHeatingType?: string | null;
                                RoofType?: string | null;
                                SquareFootage?: number | null;
                                StoreyCount?: string | null;
                                SwimmingPoolType?: string | null;
                                YearBuilt?: string | null;
                                InspectionDetails?: {
                                  __typename?: 'InspectionDetailsType';
                                  InspectionDate?: any | null;
                                  SponsoredInspection?: boolean | null;
                                } | null;
                              } | null;
                              MultiResidentialModifiers?: {
                                __typename?: 'MultiResidentialConstructionFeaturesType';
                                BasementPresent?: boolean | null;
                                ConstructionType?: string | null;
                                FloorLevel?: number | null;
                              } | null;
                              AverageAnnualLossValues?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                              AdditionalDetails: Array<{
                                __typename?: 'FloodDetailType';
                                Key?: string | null;
                                Value?: string | null;
                              }>;
                              Premium?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                            }>;
                            FloodMap: Array<{
                              __typename?: 'FloodMapType';
                              FloodEvent?: string | null;
                              Content?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        optaIclarifyCommercialConstructionData?: {
                          __typename?: 'OptaIclarifyCommercialConstructionDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Messages?: {
                            __typename?: 'Messages';
                            Message?: Array<string | null> | null;
                          } | null;
                          Property?: {
                            __typename?: 'CommercialPropertyType';
                            LotSize?: number | null;
                            LotSizeUnitOfMeasurement?: string | null;
                            PropertyType?: string | null;
                            Buildings?: {
                              __typename?: 'BuildingsType';
                              Building: Array<{
                                __typename?: 'BuildingType';
                                StructureID?: string | null;
                                ConstructionFeatures?: {
                                  __typename?: 'CommercialConstructionFeaturesType';
                                  CombustibilityClass?: string | null;
                                  ConstructionClass?: string | null;
                                  FloorHeight?: number | null;
                                  FloorHeightUnitOfMeasurement?: string | null;
                                  LoadingDockPresent?: boolean | null;
                                  NumberOfStoreys?: number | null;
                                  NumberOfUnits?: number | null;
                                  TotalFloorArea?: number | null;
                                  TotalFloorAreaUnitOfMeasurement?:
                                    | string
                                    | null;
                                  YearBuilt?: number | null;
                                  Basement?: {
                                    __typename?: 'CommercialBasementType';
                                    BasementArea?: number | null;
                                    BasementAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    BasementPresent?: boolean | null;
                                  } | null;
                                  BuildingUpdates?: {
                                    __typename?: 'BuildingUpdatesType';
                                    BuildingUpdate?: Array<
                                      number | null
                                    > | null;
                                  } | null;
                                  CommercialExteriorWallTypes?: {
                                    __typename?: 'CommercialExteriorWallTypesType';
                                    ExteriorWallType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Elevator?: {
                                    __typename?: 'ElevatorType';
                                    ElevatorCount?: number | null;
                                    ElevatorPresent?: boolean | null;
                                  } | null;
                                  Heating?: {
                                    __typename?: 'CommercialHeatingType';
                                    HeatingFuelSources?: {
                                      __typename?: 'HeatingFuelSourcesType';
                                      HeatingFuelSource?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    HeatingTypes?: {
                                      __typename?: 'HeatingTypesType';
                                      HeatingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  Parking?: {
                                    __typename?: 'CommercialParkingType';
                                    ParkingPresent?: boolean | null;
                                    ParkingTypes?: {
                                      __typename?: 'CommercialParkingTypesType';
                                      ParkingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  PlumbingTypes?: {
                                    __typename?: 'CommercialPlumbingType';
                                    PlumbingType?: Array<string | null> | null;
                                  } | null;
                                  RoofAttributes?: {
                                    __typename?: 'RoofAttributesType';
                                    RoofConstructions?: {
                                      __typename?: 'RoofConstructionsType';
                                      RoofConstruction?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofSurfaces?: {
                                      __typename?: 'RoofSurfacesType';
                                      RoofSurface?: Array<string | null> | null;
                                    } | null;
                                    RoofTypes?: {
                                      __typename?: 'RoofTypesType';
                                      RoofType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  WaterHeater?: {
                                    __typename?: 'CommercialWaterHeaterType';
                                    WaterHeaterFuelType?: Array<
                                      string | null
                                    > | null;
                                    WaterHeaterType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Wiring?: {
                                    __typename?: 'CommercialWiringType';
                                    WiringPanelTypes?: {
                                      __typename?: 'CommercialWiringPanelTypesType';
                                      WiringPanelType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    WiringTypes?: {
                                      __typename?: 'CommercialWiringTypesType';
                                      WiringType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Protection?: {
                                  __typename?: 'ProtectionType';
                                  FireAlarmPresent?: boolean | null;
                                  FireAlarmType?: string | null;
                                  PhysicalSecurityPresent?: boolean | null;
                                  SecurityAlarmPresent?: boolean | null;
                                  SecurityAlarmType?: string | null;
                                  SprinklerPresent?: boolean | null;
                                  SprinklerTypeCode?: string | null;
                                  PhysicalSecurityTypes?: {
                                    __typename?: 'PhysicalSecurityTypesType';
                                    PhysicalSecurityType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                } | null;
                              }>;
                            } | null;
                            Industry?: {
                              __typename?: 'BusinessIndustryType';
                              IBCCode?: string | null;
                              IBCIndustryDescription?: string | null;
                              SICIndustryDescription?: string | null;
                              SICCode?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        optaCommercialPerilScoreData?: {
                          __typename?: 'OptaCommercialPerilScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          PropertyPerilScores?: {
                            __typename?: 'PropertyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          CasualtyPerilScores?: {
                            __typename?: 'CasualtyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          AllPerilsScore?: {
                            __typename?: 'CommercialPerilScoreType';
                            LossCostScore?: number | null;
                            PredictedLossCost?: number | null;
                          } | null;
                        } | null;
                      } | null;
                      openingHours: Array<{
                        __typename?: 'OpeningHoursType';
                        day: number;
                        open?: any | null;
                        close?: any | null;
                      }>;
                    } | null;
                  }>;
                  details?: {
                    __typename?: 'AnswerInstanceDetailsType';
                    lat?: number | null;
                    lng?: number | null;
                    images: Array<{
                      __typename?: 'GoogleImageType';
                      description: string;
                      shortDescription: string;
                      category: CoreGoogleImageCategoryChoices;
                      data?: string | null;
                    }>;
                    google?: {
                      __typename?: 'GoogleDetailsType';
                      placeId?: string | null;
                      rating?: number | null;
                      numberOfRatings?: number | null;
                    } | null;
                    opta?: {
                      __typename?: 'OPTADetailsType';
                      optaFusData?: {
                        __typename?: 'OptaFusDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Commercial?: {
                          __typename?: 'FusProductCommercialType';
                          SearchMessage?: string | null;
                          CommercialGrade: Array<{
                            __typename?: 'CommercialGradeType';
                            PublicFireProtectionClassification?: number | null;
                            WaterSystem?: string | null;
                            RiskSCORDetail: Array<{
                              __typename?: 'RiskSCORDetailType';
                              RiskSCOR?: number | null;
                              Type?: string | null;
                              Adjustment: Array<{
                                __typename?: 'RiskSCORAdjustmentType';
                                Adjustment?: string | null;
                                AdjustmentType?: string | null;
                              }>;
                            }>;
                          }>;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        Dwelling?: {
                          __typename?: 'FusProductDwellingType';
                          SearchMessage?: string | null;
                          DwellingGrades?: {
                            __typename?: 'DwellingGradesType';
                            AllOtherAreasGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            FirehallProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            HydrantProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                          } | null;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        EarthquakeCodes?: {
                          __typename?: 'EarthquakeCodesType';
                          SearchMessage?: string | null;
                          EarthquakeCode: Array<{
                            __typename?: 'EarthquakeCodeType';
                            Description?: string | null;
                            SystemName?: string | null;
                            Zone?: string | null;
                          }>;
                        } | null;
                        FusMaps?: {
                          __typename?: 'FusMapsType';
                          SearchMessage?: string | null;
                          FusMap: Array<{
                            __typename?: 'FusMapType';
                            Business?: string | null;
                            FusContent?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                        HydrantCounts?: {
                          __typename?: 'HydrantCountsType';
                          SearchMessage?: string | null;
                          HydrantCount: Array<{
                            __typename?: 'HydrantCountType';
                            NumberOfHydrants: number;
                            Ownership?: string | null;
                            RadiusMetres?: string | null;
                          }>;
                        } | null;
                      } | null;
                      optaFloodscoreData?: {
                        __typename?: 'OptaFloodScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        FloodScores?: {
                          __typename?: 'FloodScoresType';
                          FloodScore: Array<{
                            __typename?: 'FloodScoreType';
                            AverageAnnualLossScore?: number | null;
                            Deductible?: number | null;
                            Default?: boolean | null;
                            FloodScore?: number | null;
                            Limit?: number | null;
                            RateFactor?: number | null;
                            Zone?: string | null;
                            ResidentialModifiers?: {
                              __typename?: 'ResidentialConstructionFeaturesType';
                              ArchitecturalStyleType?: string | null;
                              AuxiliaryHeatingType?: string | null;
                              BathroomCount?: string | null;
                              CommercialIndicator?: string | null;
                              ExteriorWallType?: string | null;
                              FinishedBasement?: boolean | null;
                              FoundationType?: string | null;
                              GarageNumberOfCars?: number | null;
                              GarageType?: string | null;
                              KitchenCount?: string | null;
                              OutbuildingPresent?: string | null;
                              PlumbingType?: string | null;
                              PrimaryHeatingType?: string | null;
                              RoofType?: string | null;
                              SquareFootage?: number | null;
                              StoreyCount?: string | null;
                              SwimmingPoolType?: string | null;
                              YearBuilt?: string | null;
                              InspectionDetails?: {
                                __typename?: 'InspectionDetailsType';
                                InspectionDate?: any | null;
                                SponsoredInspection?: boolean | null;
                              } | null;
                            } | null;
                            MultiResidentialModifiers?: {
                              __typename?: 'MultiResidentialConstructionFeaturesType';
                              BasementPresent?: boolean | null;
                              ConstructionType?: string | null;
                              FloorLevel?: number | null;
                            } | null;
                            AverageAnnualLossValues?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                            AdditionalDetails: Array<{
                              __typename?: 'FloodDetailType';
                              Key?: string | null;
                              Value?: string | null;
                            }>;
                            Premium?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                          }>;
                          FloodMap: Array<{
                            __typename?: 'FloodMapType';
                            FloodEvent?: string | null;
                            Content?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      optaIclarifyCommercialConstructionData?: {
                        __typename?: 'OptaIclarifyCommercialConstructionDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Messages?: {
                          __typename?: 'Messages';
                          Message?: Array<string | null> | null;
                        } | null;
                        Property?: {
                          __typename?: 'CommercialPropertyType';
                          LotSize?: number | null;
                          LotSizeUnitOfMeasurement?: string | null;
                          PropertyType?: string | null;
                          Buildings?: {
                            __typename?: 'BuildingsType';
                            Building: Array<{
                              __typename?: 'BuildingType';
                              StructureID?: string | null;
                              ConstructionFeatures?: {
                                __typename?: 'CommercialConstructionFeaturesType';
                                CombustibilityClass?: string | null;
                                ConstructionClass?: string | null;
                                FloorHeight?: number | null;
                                FloorHeightUnitOfMeasurement?: string | null;
                                LoadingDockPresent?: boolean | null;
                                NumberOfStoreys?: number | null;
                                NumberOfUnits?: number | null;
                                TotalFloorArea?: number | null;
                                TotalFloorAreaUnitOfMeasurement?: string | null;
                                YearBuilt?: number | null;
                                Basement?: {
                                  __typename?: 'CommercialBasementType';
                                  BasementArea?: number | null;
                                  BasementAreaUnitOfMeasurement?: string | null;
                                  BasementPresent?: boolean | null;
                                } | null;
                                BuildingUpdates?: {
                                  __typename?: 'BuildingUpdatesType';
                                  BuildingUpdate?: Array<number | null> | null;
                                } | null;
                                CommercialExteriorWallTypes?: {
                                  __typename?: 'CommercialExteriorWallTypesType';
                                  ExteriorWallType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                Elevator?: {
                                  __typename?: 'ElevatorType';
                                  ElevatorCount?: number | null;
                                  ElevatorPresent?: boolean | null;
                                } | null;
                                Heating?: {
                                  __typename?: 'CommercialHeatingType';
                                  HeatingFuelSources?: {
                                    __typename?: 'HeatingFuelSourcesType';
                                    HeatingFuelSource?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  HeatingTypes?: {
                                    __typename?: 'HeatingTypesType';
                                    HeatingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                Parking?: {
                                  __typename?: 'CommercialParkingType';
                                  ParkingPresent?: boolean | null;
                                  ParkingTypes?: {
                                    __typename?: 'CommercialParkingTypesType';
                                    ParkingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                PlumbingTypes?: {
                                  __typename?: 'CommercialPlumbingType';
                                  PlumbingType?: Array<string | null> | null;
                                } | null;
                                RoofAttributes?: {
                                  __typename?: 'RoofAttributesType';
                                  RoofConstructions?: {
                                    __typename?: 'RoofConstructionsType';
                                    RoofConstruction?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  RoofSurfaces?: {
                                    __typename?: 'RoofSurfacesType';
                                    RoofSurface?: Array<string | null> | null;
                                  } | null;
                                  RoofTypes?: {
                                    __typename?: 'RoofTypesType';
                                    RoofType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                WaterHeater?: {
                                  __typename?: 'CommercialWaterHeaterType';
                                  WaterHeaterFuelType?: Array<
                                    string | null
                                  > | null;
                                  WaterHeaterType?: Array<string | null> | null;
                                } | null;
                                Wiring?: {
                                  __typename?: 'CommercialWiringType';
                                  WiringPanelTypes?: {
                                    __typename?: 'CommercialWiringPanelTypesType';
                                    WiringPanelType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  WiringTypes?: {
                                    __typename?: 'CommercialWiringTypesType';
                                    WiringType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                              } | null;
                              Protection?: {
                                __typename?: 'ProtectionType';
                                FireAlarmPresent?: boolean | null;
                                FireAlarmType?: string | null;
                                PhysicalSecurityPresent?: boolean | null;
                                SecurityAlarmPresent?: boolean | null;
                                SecurityAlarmType?: string | null;
                                SprinklerPresent?: boolean | null;
                                SprinklerTypeCode?: string | null;
                                PhysicalSecurityTypes?: {
                                  __typename?: 'PhysicalSecurityTypesType';
                                  PhysicalSecurityType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                              } | null;
                            }>;
                          } | null;
                          Industry?: {
                            __typename?: 'BusinessIndustryType';
                            IBCCode?: string | null;
                            IBCIndustryDescription?: string | null;
                            SICIndustryDescription?: string | null;
                            SICCode?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      optaCommercialPerilScoreData?: {
                        __typename?: 'OptaCommercialPerilScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        PropertyPerilScores?: {
                          __typename?: 'PropertyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        CasualtyPerilScores?: {
                          __typename?: 'CasualtyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        AllPerilsScore?: {
                          __typename?: 'CommercialPerilScoreType';
                          LossCostScore?: number | null;
                          PredictedLossCost?: number | null;
                        } | null;
                      } | null;
                    } | null;
                    openingHours: Array<{
                      __typename?: 'OpeningHoursType';
                      day: number;
                      open?: any | null;
                      close?: any | null;
                    }>;
                  } | null;
                }>;
                details?: {
                  __typename?: 'AnswerInstanceDetailsType';
                  lat?: number | null;
                  lng?: number | null;
                  images: Array<{
                    __typename?: 'GoogleImageType';
                    description: string;
                    shortDescription: string;
                    category: CoreGoogleImageCategoryChoices;
                    data?: string | null;
                  }>;
                  google?: {
                    __typename?: 'GoogleDetailsType';
                    placeId?: string | null;
                    rating?: number | null;
                    numberOfRatings?: number | null;
                  } | null;
                  opta?: {
                    __typename?: 'OPTADetailsType';
                    optaFusData?: {
                      __typename?: 'OptaFusDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Commercial?: {
                        __typename?: 'FusProductCommercialType';
                        SearchMessage?: string | null;
                        CommercialGrade: Array<{
                          __typename?: 'CommercialGradeType';
                          PublicFireProtectionClassification?: number | null;
                          WaterSystem?: string | null;
                          RiskSCORDetail: Array<{
                            __typename?: 'RiskSCORDetailType';
                            RiskSCOR?: number | null;
                            Type?: string | null;
                            Adjustment: Array<{
                              __typename?: 'RiskSCORAdjustmentType';
                              Adjustment?: string | null;
                              AdjustmentType?: string | null;
                            }>;
                          }>;
                        }>;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      Dwelling?: {
                        __typename?: 'FusProductDwellingType';
                        SearchMessage?: string | null;
                        DwellingGrades?: {
                          __typename?: 'DwellingGradesType';
                          AllOtherAreasGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          FirehallProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          HydrantProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                        } | null;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      EarthquakeCodes?: {
                        __typename?: 'EarthquakeCodesType';
                        SearchMessage?: string | null;
                        EarthquakeCode: Array<{
                          __typename?: 'EarthquakeCodeType';
                          Description?: string | null;
                          SystemName?: string | null;
                          Zone?: string | null;
                        }>;
                      } | null;
                      FusMaps?: {
                        __typename?: 'FusMapsType';
                        SearchMessage?: string | null;
                        FusMap: Array<{
                          __typename?: 'FusMapType';
                          Business?: string | null;
                          FusContent?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                      HydrantCounts?: {
                        __typename?: 'HydrantCountsType';
                        SearchMessage?: string | null;
                        HydrantCount: Array<{
                          __typename?: 'HydrantCountType';
                          NumberOfHydrants: number;
                          Ownership?: string | null;
                          RadiusMetres?: string | null;
                        }>;
                      } | null;
                    } | null;
                    optaFloodscoreData?: {
                      __typename?: 'OptaFloodScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      FloodScores?: {
                        __typename?: 'FloodScoresType';
                        FloodScore: Array<{
                          __typename?: 'FloodScoreType';
                          AverageAnnualLossScore?: number | null;
                          Deductible?: number | null;
                          Default?: boolean | null;
                          FloodScore?: number | null;
                          Limit?: number | null;
                          RateFactor?: number | null;
                          Zone?: string | null;
                          ResidentialModifiers?: {
                            __typename?: 'ResidentialConstructionFeaturesType';
                            ArchitecturalStyleType?: string | null;
                            AuxiliaryHeatingType?: string | null;
                            BathroomCount?: string | null;
                            CommercialIndicator?: string | null;
                            ExteriorWallType?: string | null;
                            FinishedBasement?: boolean | null;
                            FoundationType?: string | null;
                            GarageNumberOfCars?: number | null;
                            GarageType?: string | null;
                            KitchenCount?: string | null;
                            OutbuildingPresent?: string | null;
                            PlumbingType?: string | null;
                            PrimaryHeatingType?: string | null;
                            RoofType?: string | null;
                            SquareFootage?: number | null;
                            StoreyCount?: string | null;
                            SwimmingPoolType?: string | null;
                            YearBuilt?: string | null;
                            InspectionDetails?: {
                              __typename?: 'InspectionDetailsType';
                              InspectionDate?: any | null;
                              SponsoredInspection?: boolean | null;
                            } | null;
                          } | null;
                          MultiResidentialModifiers?: {
                            __typename?: 'MultiResidentialConstructionFeaturesType';
                            BasementPresent?: boolean | null;
                            ConstructionType?: string | null;
                            FloorLevel?: number | null;
                          } | null;
                          AverageAnnualLossValues?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                          AdditionalDetails: Array<{
                            __typename?: 'FloodDetailType';
                            Key?: string | null;
                            Value?: string | null;
                          }>;
                          Premium?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                        }>;
                        FloodMap: Array<{
                          __typename?: 'FloodMapType';
                          FloodEvent?: string | null;
                          Content?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    optaIclarifyCommercialConstructionData?: {
                      __typename?: 'OptaIclarifyCommercialConstructionDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Messages?: {
                        __typename?: 'Messages';
                        Message?: Array<string | null> | null;
                      } | null;
                      Property?: {
                        __typename?: 'CommercialPropertyType';
                        LotSize?: number | null;
                        LotSizeUnitOfMeasurement?: string | null;
                        PropertyType?: string | null;
                        Buildings?: {
                          __typename?: 'BuildingsType';
                          Building: Array<{
                            __typename?: 'BuildingType';
                            StructureID?: string | null;
                            ConstructionFeatures?: {
                              __typename?: 'CommercialConstructionFeaturesType';
                              CombustibilityClass?: string | null;
                              ConstructionClass?: string | null;
                              FloorHeight?: number | null;
                              FloorHeightUnitOfMeasurement?: string | null;
                              LoadingDockPresent?: boolean | null;
                              NumberOfStoreys?: number | null;
                              NumberOfUnits?: number | null;
                              TotalFloorArea?: number | null;
                              TotalFloorAreaUnitOfMeasurement?: string | null;
                              YearBuilt?: number | null;
                              Basement?: {
                                __typename?: 'CommercialBasementType';
                                BasementArea?: number | null;
                                BasementAreaUnitOfMeasurement?: string | null;
                                BasementPresent?: boolean | null;
                              } | null;
                              BuildingUpdates?: {
                                __typename?: 'BuildingUpdatesType';
                                BuildingUpdate?: Array<number | null> | null;
                              } | null;
                              CommercialExteriorWallTypes?: {
                                __typename?: 'CommercialExteriorWallTypesType';
                                ExteriorWallType?: Array<string | null> | null;
                              } | null;
                              Elevator?: {
                                __typename?: 'ElevatorType';
                                ElevatorCount?: number | null;
                                ElevatorPresent?: boolean | null;
                              } | null;
                              Heating?: {
                                __typename?: 'CommercialHeatingType';
                                HeatingFuelSources?: {
                                  __typename?: 'HeatingFuelSourcesType';
                                  HeatingFuelSource?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                HeatingTypes?: {
                                  __typename?: 'HeatingTypesType';
                                  HeatingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              Parking?: {
                                __typename?: 'CommercialParkingType';
                                ParkingPresent?: boolean | null;
                                ParkingTypes?: {
                                  __typename?: 'CommercialParkingTypesType';
                                  ParkingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              PlumbingTypes?: {
                                __typename?: 'CommercialPlumbingType';
                                PlumbingType?: Array<string | null> | null;
                              } | null;
                              RoofAttributes?: {
                                __typename?: 'RoofAttributesType';
                                RoofConstructions?: {
                                  __typename?: 'RoofConstructionsType';
                                  RoofConstruction?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                RoofSurfaces?: {
                                  __typename?: 'RoofSurfacesType';
                                  RoofSurface?: Array<string | null> | null;
                                } | null;
                                RoofTypes?: {
                                  __typename?: 'RoofTypesType';
                                  RoofType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              WaterHeater?: {
                                __typename?: 'CommercialWaterHeaterType';
                                WaterHeaterFuelType?: Array<
                                  string | null
                                > | null;
                                WaterHeaterType?: Array<string | null> | null;
                              } | null;
                              Wiring?: {
                                __typename?: 'CommercialWiringType';
                                WiringPanelTypes?: {
                                  __typename?: 'CommercialWiringPanelTypesType';
                                  WiringPanelType?: Array<string | null> | null;
                                } | null;
                                WiringTypes?: {
                                  __typename?: 'CommercialWiringTypesType';
                                  WiringType?: Array<string | null> | null;
                                } | null;
                              } | null;
                            } | null;
                            Protection?: {
                              __typename?: 'ProtectionType';
                              FireAlarmPresent?: boolean | null;
                              FireAlarmType?: string | null;
                              PhysicalSecurityPresent?: boolean | null;
                              SecurityAlarmPresent?: boolean | null;
                              SecurityAlarmType?: string | null;
                              SprinklerPresent?: boolean | null;
                              SprinklerTypeCode?: string | null;
                              PhysicalSecurityTypes?: {
                                __typename?: 'PhysicalSecurityTypesType';
                                PhysicalSecurityType?: Array<
                                  string | null
                                > | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                        Industry?: {
                          __typename?: 'BusinessIndustryType';
                          IBCCode?: string | null;
                          IBCIndustryDescription?: string | null;
                          SICIndustryDescription?: string | null;
                          SICCode?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    optaCommercialPerilScoreData?: {
                      __typename?: 'OptaCommercialPerilScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      PropertyPerilScores?: {
                        __typename?: 'PropertyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      CasualtyPerilScores?: {
                        __typename?: 'CasualtyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      AllPerilsScore?: {
                        __typename?: 'CommercialPerilScoreType';
                        LossCostScore?: number | null;
                        PredictedLossCost?: number | null;
                      } | null;
                    } | null;
                  } | null;
                  openingHours: Array<{
                    __typename?: 'OpeningHoursType';
                    day: number;
                    open?: any | null;
                    close?: any | null;
                  }>;
                } | null;
              }>;
              details?: {
                __typename?: 'AnswerInstanceDetailsType';
                lat?: number | null;
                lng?: number | null;
                images: Array<{
                  __typename?: 'GoogleImageType';
                  description: string;
                  shortDescription: string;
                  category: CoreGoogleImageCategoryChoices;
                  data?: string | null;
                }>;
                google?: {
                  __typename?: 'GoogleDetailsType';
                  placeId?: string | null;
                  rating?: number | null;
                  numberOfRatings?: number | null;
                } | null;
                opta?: {
                  __typename?: 'OPTADetailsType';
                  optaFusData?: {
                    __typename?: 'OptaFusDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Commercial?: {
                      __typename?: 'FusProductCommercialType';
                      SearchMessage?: string | null;
                      CommercialGrade: Array<{
                        __typename?: 'CommercialGradeType';
                        PublicFireProtectionClassification?: number | null;
                        WaterSystem?: string | null;
                        RiskSCORDetail: Array<{
                          __typename?: 'RiskSCORDetailType';
                          RiskSCOR?: number | null;
                          Type?: string | null;
                          Adjustment: Array<{
                            __typename?: 'RiskSCORAdjustmentType';
                            Adjustment?: string | null;
                            AdjustmentType?: string | null;
                          }>;
                        }>;
                      }>;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    Dwelling?: {
                      __typename?: 'FusProductDwellingType';
                      SearchMessage?: string | null;
                      DwellingGrades?: {
                        __typename?: 'DwellingGradesType';
                        AllOtherAreasGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        FirehallProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        HydrantProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                      } | null;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    EarthquakeCodes?: {
                      __typename?: 'EarthquakeCodesType';
                      SearchMessage?: string | null;
                      EarthquakeCode: Array<{
                        __typename?: 'EarthquakeCodeType';
                        Description?: string | null;
                        SystemName?: string | null;
                        Zone?: string | null;
                      }>;
                    } | null;
                    FusMaps?: {
                      __typename?: 'FusMapsType';
                      SearchMessage?: string | null;
                      FusMap: Array<{
                        __typename?: 'FusMapType';
                        Business?: string | null;
                        FusContent?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                    HydrantCounts?: {
                      __typename?: 'HydrantCountsType';
                      SearchMessage?: string | null;
                      HydrantCount: Array<{
                        __typename?: 'HydrantCountType';
                        NumberOfHydrants: number;
                        Ownership?: string | null;
                        RadiusMetres?: string | null;
                      }>;
                    } | null;
                  } | null;
                  optaFloodscoreData?: {
                    __typename?: 'OptaFloodScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    FloodScores?: {
                      __typename?: 'FloodScoresType';
                      FloodScore: Array<{
                        __typename?: 'FloodScoreType';
                        AverageAnnualLossScore?: number | null;
                        Deductible?: number | null;
                        Default?: boolean | null;
                        FloodScore?: number | null;
                        Limit?: number | null;
                        RateFactor?: number | null;
                        Zone?: string | null;
                        ResidentialModifiers?: {
                          __typename?: 'ResidentialConstructionFeaturesType';
                          ArchitecturalStyleType?: string | null;
                          AuxiliaryHeatingType?: string | null;
                          BathroomCount?: string | null;
                          CommercialIndicator?: string | null;
                          ExteriorWallType?: string | null;
                          FinishedBasement?: boolean | null;
                          FoundationType?: string | null;
                          GarageNumberOfCars?: number | null;
                          GarageType?: string | null;
                          KitchenCount?: string | null;
                          OutbuildingPresent?: string | null;
                          PlumbingType?: string | null;
                          PrimaryHeatingType?: string | null;
                          RoofType?: string | null;
                          SquareFootage?: number | null;
                          StoreyCount?: string | null;
                          SwimmingPoolType?: string | null;
                          YearBuilt?: string | null;
                          InspectionDetails?: {
                            __typename?: 'InspectionDetailsType';
                            InspectionDate?: any | null;
                            SponsoredInspection?: boolean | null;
                          } | null;
                        } | null;
                        MultiResidentialModifiers?: {
                          __typename?: 'MultiResidentialConstructionFeaturesType';
                          BasementPresent?: boolean | null;
                          ConstructionType?: string | null;
                          FloorLevel?: number | null;
                        } | null;
                        AverageAnnualLossValues?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                        AdditionalDetails: Array<{
                          __typename?: 'FloodDetailType';
                          Key?: string | null;
                          Value?: string | null;
                        }>;
                        Premium?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                      }>;
                      FloodMap: Array<{
                        __typename?: 'FloodMapType';
                        FloodEvent?: string | null;
                        Content?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  optaIclarifyCommercialConstructionData?: {
                    __typename?: 'OptaIclarifyCommercialConstructionDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Messages?: {
                      __typename?: 'Messages';
                      Message?: Array<string | null> | null;
                    } | null;
                    Property?: {
                      __typename?: 'CommercialPropertyType';
                      LotSize?: number | null;
                      LotSizeUnitOfMeasurement?: string | null;
                      PropertyType?: string | null;
                      Buildings?: {
                        __typename?: 'BuildingsType';
                        Building: Array<{
                          __typename?: 'BuildingType';
                          StructureID?: string | null;
                          ConstructionFeatures?: {
                            __typename?: 'CommercialConstructionFeaturesType';
                            CombustibilityClass?: string | null;
                            ConstructionClass?: string | null;
                            FloorHeight?: number | null;
                            FloorHeightUnitOfMeasurement?: string | null;
                            LoadingDockPresent?: boolean | null;
                            NumberOfStoreys?: number | null;
                            NumberOfUnits?: number | null;
                            TotalFloorArea?: number | null;
                            TotalFloorAreaUnitOfMeasurement?: string | null;
                            YearBuilt?: number | null;
                            Basement?: {
                              __typename?: 'CommercialBasementType';
                              BasementArea?: number | null;
                              BasementAreaUnitOfMeasurement?: string | null;
                              BasementPresent?: boolean | null;
                            } | null;
                            BuildingUpdates?: {
                              __typename?: 'BuildingUpdatesType';
                              BuildingUpdate?: Array<number | null> | null;
                            } | null;
                            CommercialExteriorWallTypes?: {
                              __typename?: 'CommercialExteriorWallTypesType';
                              ExteriorWallType?: Array<string | null> | null;
                            } | null;
                            Elevator?: {
                              __typename?: 'ElevatorType';
                              ElevatorCount?: number | null;
                              ElevatorPresent?: boolean | null;
                            } | null;
                            Heating?: {
                              __typename?: 'CommercialHeatingType';
                              HeatingFuelSources?: {
                                __typename?: 'HeatingFuelSourcesType';
                                HeatingFuelSource?: Array<string | null> | null;
                              } | null;
                              HeatingTypes?: {
                                __typename?: 'HeatingTypesType';
                                HeatingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            Parking?: {
                              __typename?: 'CommercialParkingType';
                              ParkingPresent?: boolean | null;
                              ParkingTypes?: {
                                __typename?: 'CommercialParkingTypesType';
                                ParkingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            PlumbingTypes?: {
                              __typename?: 'CommercialPlumbingType';
                              PlumbingType?: Array<string | null> | null;
                            } | null;
                            RoofAttributes?: {
                              __typename?: 'RoofAttributesType';
                              RoofConstructions?: {
                                __typename?: 'RoofConstructionsType';
                                RoofConstruction?: Array<string | null> | null;
                              } | null;
                              RoofSurfaces?: {
                                __typename?: 'RoofSurfacesType';
                                RoofSurface?: Array<string | null> | null;
                              } | null;
                              RoofTypes?: {
                                __typename?: 'RoofTypesType';
                                RoofType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            WaterHeater?: {
                              __typename?: 'CommercialWaterHeaterType';
                              WaterHeaterFuelType?: Array<string | null> | null;
                              WaterHeaterType?: Array<string | null> | null;
                            } | null;
                            Wiring?: {
                              __typename?: 'CommercialWiringType';
                              WiringPanelTypes?: {
                                __typename?: 'CommercialWiringPanelTypesType';
                                WiringPanelType?: Array<string | null> | null;
                              } | null;
                              WiringTypes?: {
                                __typename?: 'CommercialWiringTypesType';
                                WiringType?: Array<string | null> | null;
                              } | null;
                            } | null;
                          } | null;
                          Protection?: {
                            __typename?: 'ProtectionType';
                            FireAlarmPresent?: boolean | null;
                            FireAlarmType?: string | null;
                            PhysicalSecurityPresent?: boolean | null;
                            SecurityAlarmPresent?: boolean | null;
                            SecurityAlarmType?: string | null;
                            SprinklerPresent?: boolean | null;
                            SprinklerTypeCode?: string | null;
                            PhysicalSecurityTypes?: {
                              __typename?: 'PhysicalSecurityTypesType';
                              PhysicalSecurityType?: Array<
                                string | null
                              > | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                      Industry?: {
                        __typename?: 'BusinessIndustryType';
                        IBCCode?: string | null;
                        IBCIndustryDescription?: string | null;
                        SICIndustryDescription?: string | null;
                        SICCode?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  optaCommercialPerilScoreData?: {
                    __typename?: 'OptaCommercialPerilScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    PropertyPerilScores?: {
                      __typename?: 'PropertyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    CasualtyPerilScores?: {
                      __typename?: 'CasualtyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    AllPerilsScore?: {
                      __typename?: 'CommercialPerilScoreType';
                      LossCostScore?: number | null;
                      PredictedLossCost?: number | null;
                    } | null;
                  } | null;
                } | null;
                openingHours: Array<{
                  __typename?: 'OpeningHoursType';
                  day: number;
                  open?: any | null;
                  close?: any | null;
                }>;
              } | null;
            }>;
            details?: {
              __typename?: 'AnswerInstanceDetailsType';
              lat?: number | null;
              lng?: number | null;
              images: Array<{
                __typename?: 'GoogleImageType';
                description: string;
                shortDescription: string;
                category: CoreGoogleImageCategoryChoices;
                data?: string | null;
              }>;
              google?: {
                __typename?: 'GoogleDetailsType';
                placeId?: string | null;
                rating?: number | null;
                numberOfRatings?: number | null;
              } | null;
              opta?: {
                __typename?: 'OPTADetailsType';
                optaFusData?: {
                  __typename?: 'OptaFusDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Commercial?: {
                    __typename?: 'FusProductCommercialType';
                    SearchMessage?: string | null;
                    CommercialGrade: Array<{
                      __typename?: 'CommercialGradeType';
                      PublicFireProtectionClassification?: number | null;
                      WaterSystem?: string | null;
                      RiskSCORDetail: Array<{
                        __typename?: 'RiskSCORDetailType';
                        RiskSCOR?: number | null;
                        Type?: string | null;
                        Adjustment: Array<{
                          __typename?: 'RiskSCORAdjustmentType';
                          Adjustment?: string | null;
                          AdjustmentType?: string | null;
                        }>;
                      }>;
                    }>;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  Dwelling?: {
                    __typename?: 'FusProductDwellingType';
                    SearchMessage?: string | null;
                    DwellingGrades?: {
                      __typename?: 'DwellingGradesType';
                      AllOtherAreasGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      FirehallProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      HydrantProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                    } | null;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  EarthquakeCodes?: {
                    __typename?: 'EarthquakeCodesType';
                    SearchMessage?: string | null;
                    EarthquakeCode: Array<{
                      __typename?: 'EarthquakeCodeType';
                      Description?: string | null;
                      SystemName?: string | null;
                      Zone?: string | null;
                    }>;
                  } | null;
                  FusMaps?: {
                    __typename?: 'FusMapsType';
                    SearchMessage?: string | null;
                    FusMap: Array<{
                      __typename?: 'FusMapType';
                      Business?: string | null;
                      FusContent?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                  HydrantCounts?: {
                    __typename?: 'HydrantCountsType';
                    SearchMessage?: string | null;
                    HydrantCount: Array<{
                      __typename?: 'HydrantCountType';
                      NumberOfHydrants: number;
                      Ownership?: string | null;
                      RadiusMetres?: string | null;
                    }>;
                  } | null;
                } | null;
                optaFloodscoreData?: {
                  __typename?: 'OptaFloodScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  FloodScores?: {
                    __typename?: 'FloodScoresType';
                    FloodScore: Array<{
                      __typename?: 'FloodScoreType';
                      AverageAnnualLossScore?: number | null;
                      Deductible?: number | null;
                      Default?: boolean | null;
                      FloodScore?: number | null;
                      Limit?: number | null;
                      RateFactor?: number | null;
                      Zone?: string | null;
                      ResidentialModifiers?: {
                        __typename?: 'ResidentialConstructionFeaturesType';
                        ArchitecturalStyleType?: string | null;
                        AuxiliaryHeatingType?: string | null;
                        BathroomCount?: string | null;
                        CommercialIndicator?: string | null;
                        ExteriorWallType?: string | null;
                        FinishedBasement?: boolean | null;
                        FoundationType?: string | null;
                        GarageNumberOfCars?: number | null;
                        GarageType?: string | null;
                        KitchenCount?: string | null;
                        OutbuildingPresent?: string | null;
                        PlumbingType?: string | null;
                        PrimaryHeatingType?: string | null;
                        RoofType?: string | null;
                        SquareFootage?: number | null;
                        StoreyCount?: string | null;
                        SwimmingPoolType?: string | null;
                        YearBuilt?: string | null;
                        InspectionDetails?: {
                          __typename?: 'InspectionDetailsType';
                          InspectionDate?: any | null;
                          SponsoredInspection?: boolean | null;
                        } | null;
                      } | null;
                      MultiResidentialModifiers?: {
                        __typename?: 'MultiResidentialConstructionFeaturesType';
                        BasementPresent?: boolean | null;
                        ConstructionType?: string | null;
                        FloorLevel?: number | null;
                      } | null;
                      AverageAnnualLossValues?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                      AdditionalDetails: Array<{
                        __typename?: 'FloodDetailType';
                        Key?: string | null;
                        Value?: string | null;
                      }>;
                      Premium?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                    }>;
                    FloodMap: Array<{
                      __typename?: 'FloodMapType';
                      FloodEvent?: string | null;
                      Content?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                } | null;
                optaIclarifyCommercialConstructionData?: {
                  __typename?: 'OptaIclarifyCommercialConstructionDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Messages?: {
                    __typename?: 'Messages';
                    Message?: Array<string | null> | null;
                  } | null;
                  Property?: {
                    __typename?: 'CommercialPropertyType';
                    LotSize?: number | null;
                    LotSizeUnitOfMeasurement?: string | null;
                    PropertyType?: string | null;
                    Buildings?: {
                      __typename?: 'BuildingsType';
                      Building: Array<{
                        __typename?: 'BuildingType';
                        StructureID?: string | null;
                        ConstructionFeatures?: {
                          __typename?: 'CommercialConstructionFeaturesType';
                          CombustibilityClass?: string | null;
                          ConstructionClass?: string | null;
                          FloorHeight?: number | null;
                          FloorHeightUnitOfMeasurement?: string | null;
                          LoadingDockPresent?: boolean | null;
                          NumberOfStoreys?: number | null;
                          NumberOfUnits?: number | null;
                          TotalFloorArea?: number | null;
                          TotalFloorAreaUnitOfMeasurement?: string | null;
                          YearBuilt?: number | null;
                          Basement?: {
                            __typename?: 'CommercialBasementType';
                            BasementArea?: number | null;
                            BasementAreaUnitOfMeasurement?: string | null;
                            BasementPresent?: boolean | null;
                          } | null;
                          BuildingUpdates?: {
                            __typename?: 'BuildingUpdatesType';
                            BuildingUpdate?: Array<number | null> | null;
                          } | null;
                          CommercialExteriorWallTypes?: {
                            __typename?: 'CommercialExteriorWallTypesType';
                            ExteriorWallType?: Array<string | null> | null;
                          } | null;
                          Elevator?: {
                            __typename?: 'ElevatorType';
                            ElevatorCount?: number | null;
                            ElevatorPresent?: boolean | null;
                          } | null;
                          Heating?: {
                            __typename?: 'CommercialHeatingType';
                            HeatingFuelSources?: {
                              __typename?: 'HeatingFuelSourcesType';
                              HeatingFuelSource?: Array<string | null> | null;
                            } | null;
                            HeatingTypes?: {
                              __typename?: 'HeatingTypesType';
                              HeatingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          Parking?: {
                            __typename?: 'CommercialParkingType';
                            ParkingPresent?: boolean | null;
                            ParkingTypes?: {
                              __typename?: 'CommercialParkingTypesType';
                              ParkingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          PlumbingTypes?: {
                            __typename?: 'CommercialPlumbingType';
                            PlumbingType?: Array<string | null> | null;
                          } | null;
                          RoofAttributes?: {
                            __typename?: 'RoofAttributesType';
                            RoofConstructions?: {
                              __typename?: 'RoofConstructionsType';
                              RoofConstruction?: Array<string | null> | null;
                            } | null;
                            RoofSurfaces?: {
                              __typename?: 'RoofSurfacesType';
                              RoofSurface?: Array<string | null> | null;
                            } | null;
                            RoofTypes?: {
                              __typename?: 'RoofTypesType';
                              RoofType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          WaterHeater?: {
                            __typename?: 'CommercialWaterHeaterType';
                            WaterHeaterFuelType?: Array<string | null> | null;
                            WaterHeaterType?: Array<string | null> | null;
                          } | null;
                          Wiring?: {
                            __typename?: 'CommercialWiringType';
                            WiringPanelTypes?: {
                              __typename?: 'CommercialWiringPanelTypesType';
                              WiringPanelType?: Array<string | null> | null;
                            } | null;
                            WiringTypes?: {
                              __typename?: 'CommercialWiringTypesType';
                              WiringType?: Array<string | null> | null;
                            } | null;
                          } | null;
                        } | null;
                        Protection?: {
                          __typename?: 'ProtectionType';
                          FireAlarmPresent?: boolean | null;
                          FireAlarmType?: string | null;
                          PhysicalSecurityPresent?: boolean | null;
                          SecurityAlarmPresent?: boolean | null;
                          SecurityAlarmType?: string | null;
                          SprinklerPresent?: boolean | null;
                          SprinklerTypeCode?: string | null;
                          PhysicalSecurityTypes?: {
                            __typename?: 'PhysicalSecurityTypesType';
                            PhysicalSecurityType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                    Industry?: {
                      __typename?: 'BusinessIndustryType';
                      IBCCode?: string | null;
                      IBCIndustryDescription?: string | null;
                      SICIndustryDescription?: string | null;
                      SICCode?: string | null;
                    } | null;
                  } | null;
                } | null;
                optaCommercialPerilScoreData?: {
                  __typename?: 'OptaCommercialPerilScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  PropertyPerilScores?: {
                    __typename?: 'PropertyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  CasualtyPerilScores?: {
                    __typename?: 'CasualtyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  AllPerilsScore?: {
                    __typename?: 'CommercialPerilScoreType';
                    LossCostScore?: number | null;
                    PredictedLossCost?: number | null;
                  } | null;
                } | null;
              } | null;
              openingHours: Array<{
                __typename?: 'OpeningHoursType';
                day: number;
                open?: any | null;
                close?: any | null;
              }>;
            } | null;
          }>;
          details?: {
            __typename?: 'AnswerInstanceDetailsType';
            lat?: number | null;
            lng?: number | null;
            images: Array<{
              __typename?: 'GoogleImageType';
              description: string;
              shortDescription: string;
              category: CoreGoogleImageCategoryChoices;
              data?: string | null;
            }>;
            google?: {
              __typename?: 'GoogleDetailsType';
              placeId?: string | null;
              rating?: number | null;
              numberOfRatings?: number | null;
            } | null;
            opta?: {
              __typename?: 'OPTADetailsType';
              optaFusData?: {
                __typename?: 'OptaFusDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                Commercial?: {
                  __typename?: 'FusProductCommercialType';
                  SearchMessage?: string | null;
                  CommercialGrade: Array<{
                    __typename?: 'CommercialGradeType';
                    PublicFireProtectionClassification?: number | null;
                    WaterSystem?: string | null;
                    RiskSCORDetail: Array<{
                      __typename?: 'RiskSCORDetailType';
                      RiskSCOR?: number | null;
                      Type?: string | null;
                      Adjustment: Array<{
                        __typename?: 'RiskSCORAdjustmentType';
                        Adjustment?: string | null;
                        AdjustmentType?: string | null;
                      }>;
                    }>;
                  }>;
                  RespondingFirehall?: {
                    __typename?: 'FirehallType';
                    Address?: string | null;
                    GoverningAgency?: string | null;
                    GradingDate?: any | null;
                    Name?: string | null;
                    PumperFlowImperialGallonsPerMinute?: number | null;
                    RoadDistanceKilometres?: number | null;
                    SuperiorTankerServiceAccreditationDate?: any | null;
                    FirefightersPerFireStation?: {
                      __typename?: 'FirefightersPerFireStationType';
                      FullTime?: number | null;
                      Volunteer?: number | null;
                    } | null;
                    ProtectionGradeTerritories?: {
                      __typename?: 'ProtectedGradeTerritoriesType';
                      Commercial?: string | null;
                      Dwelling?: string | null;
                    } | null;
                  } | null;
                } | null;
                Dwelling?: {
                  __typename?: 'FusProductDwellingType';
                  SearchMessage?: string | null;
                  DwellingGrades?: {
                    __typename?: 'DwellingGradesType';
                    AllOtherAreasGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                    FirehallProtectedGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                    HydrantProtectedGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                  } | null;
                  RespondingFirehall?: {
                    __typename?: 'FirehallType';
                    Address?: string | null;
                    GoverningAgency?: string | null;
                    GradingDate?: any | null;
                    Name?: string | null;
                    PumperFlowImperialGallonsPerMinute?: number | null;
                    RoadDistanceKilometres?: number | null;
                    SuperiorTankerServiceAccreditationDate?: any | null;
                    FirefightersPerFireStation?: {
                      __typename?: 'FirefightersPerFireStationType';
                      FullTime?: number | null;
                      Volunteer?: number | null;
                    } | null;
                    ProtectionGradeTerritories?: {
                      __typename?: 'ProtectedGradeTerritoriesType';
                      Commercial?: string | null;
                      Dwelling?: string | null;
                    } | null;
                  } | null;
                } | null;
                EarthquakeCodes?: {
                  __typename?: 'EarthquakeCodesType';
                  SearchMessage?: string | null;
                  EarthquakeCode: Array<{
                    __typename?: 'EarthquakeCodeType';
                    Description?: string | null;
                    SystemName?: string | null;
                    Zone?: string | null;
                  }>;
                } | null;
                FusMaps?: {
                  __typename?: 'FusMapsType';
                  SearchMessage?: string | null;
                  FusMap: Array<{
                    __typename?: 'FusMapType';
                    Business?: string | null;
                    FusContent?: {
                      __typename?: 'ContentType';
                      ContentURL?: string | null;
                      Expiry?: any | null;
                      MIMEType?: string | null;
                    } | null;
                  }>;
                } | null;
                HydrantCounts?: {
                  __typename?: 'HydrantCountsType';
                  SearchMessage?: string | null;
                  HydrantCount: Array<{
                    __typename?: 'HydrantCountType';
                    NumberOfHydrants: number;
                    Ownership?: string | null;
                    RadiusMetres?: string | null;
                  }>;
                } | null;
              } | null;
              optaFloodscoreData?: {
                __typename?: 'OptaFloodScoreDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                FloodScores?: {
                  __typename?: 'FloodScoresType';
                  FloodScore: Array<{
                    __typename?: 'FloodScoreType';
                    AverageAnnualLossScore?: number | null;
                    Deductible?: number | null;
                    Default?: boolean | null;
                    FloodScore?: number | null;
                    Limit?: number | null;
                    RateFactor?: number | null;
                    Zone?: string | null;
                    ResidentialModifiers?: {
                      __typename?: 'ResidentialConstructionFeaturesType';
                      ArchitecturalStyleType?: string | null;
                      AuxiliaryHeatingType?: string | null;
                      BathroomCount?: string | null;
                      CommercialIndicator?: string | null;
                      ExteriorWallType?: string | null;
                      FinishedBasement?: boolean | null;
                      FoundationType?: string | null;
                      GarageNumberOfCars?: number | null;
                      GarageType?: string | null;
                      KitchenCount?: string | null;
                      OutbuildingPresent?: string | null;
                      PlumbingType?: string | null;
                      PrimaryHeatingType?: string | null;
                      RoofType?: string | null;
                      SquareFootage?: number | null;
                      StoreyCount?: string | null;
                      SwimmingPoolType?: string | null;
                      YearBuilt?: string | null;
                      InspectionDetails?: {
                        __typename?: 'InspectionDetailsType';
                        InspectionDate?: any | null;
                        SponsoredInspection?: boolean | null;
                      } | null;
                    } | null;
                    MultiResidentialModifiers?: {
                      __typename?: 'MultiResidentialConstructionFeaturesType';
                      BasementPresent?: boolean | null;
                      ConstructionType?: string | null;
                      FloorLevel?: number | null;
                    } | null;
                    AverageAnnualLossValues?: {
                      __typename?: 'FloodValueType';
                      Building?: number | null;
                      Contents?: number | null;
                      Total?: number | null;
                    } | null;
                    AdditionalDetails: Array<{
                      __typename?: 'FloodDetailType';
                      Key?: string | null;
                      Value?: string | null;
                    }>;
                    Premium?: {
                      __typename?: 'FloodValueType';
                      Building?: number | null;
                      Contents?: number | null;
                      Total?: number | null;
                    } | null;
                  }>;
                  FloodMap: Array<{
                    __typename?: 'FloodMapType';
                    FloodEvent?: string | null;
                    Content?: {
                      __typename?: 'ContentType';
                      ContentURL?: string | null;
                      Expiry?: any | null;
                      MIMEType?: string | null;
                    } | null;
                  }>;
                } | null;
              } | null;
              optaIclarifyCommercialConstructionData?: {
                __typename?: 'OptaIclarifyCommercialConstructionDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                Messages?: {
                  __typename?: 'Messages';
                  Message?: Array<string | null> | null;
                } | null;
                Property?: {
                  __typename?: 'CommercialPropertyType';
                  LotSize?: number | null;
                  LotSizeUnitOfMeasurement?: string | null;
                  PropertyType?: string | null;
                  Buildings?: {
                    __typename?: 'BuildingsType';
                    Building: Array<{
                      __typename?: 'BuildingType';
                      StructureID?: string | null;
                      ConstructionFeatures?: {
                        __typename?: 'CommercialConstructionFeaturesType';
                        CombustibilityClass?: string | null;
                        ConstructionClass?: string | null;
                        FloorHeight?: number | null;
                        FloorHeightUnitOfMeasurement?: string | null;
                        LoadingDockPresent?: boolean | null;
                        NumberOfStoreys?: number | null;
                        NumberOfUnits?: number | null;
                        TotalFloorArea?: number | null;
                        TotalFloorAreaUnitOfMeasurement?: string | null;
                        YearBuilt?: number | null;
                        Basement?: {
                          __typename?: 'CommercialBasementType';
                          BasementArea?: number | null;
                          BasementAreaUnitOfMeasurement?: string | null;
                          BasementPresent?: boolean | null;
                        } | null;
                        BuildingUpdates?: {
                          __typename?: 'BuildingUpdatesType';
                          BuildingUpdate?: Array<number | null> | null;
                        } | null;
                        CommercialExteriorWallTypes?: {
                          __typename?: 'CommercialExteriorWallTypesType';
                          ExteriorWallType?: Array<string | null> | null;
                        } | null;
                        Elevator?: {
                          __typename?: 'ElevatorType';
                          ElevatorCount?: number | null;
                          ElevatorPresent?: boolean | null;
                        } | null;
                        Heating?: {
                          __typename?: 'CommercialHeatingType';
                          HeatingFuelSources?: {
                            __typename?: 'HeatingFuelSourcesType';
                            HeatingFuelSource?: Array<string | null> | null;
                          } | null;
                          HeatingTypes?: {
                            __typename?: 'HeatingTypesType';
                            HeatingType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        Parking?: {
                          __typename?: 'CommercialParkingType';
                          ParkingPresent?: boolean | null;
                          ParkingTypes?: {
                            __typename?: 'CommercialParkingTypesType';
                            ParkingType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        PlumbingTypes?: {
                          __typename?: 'CommercialPlumbingType';
                          PlumbingType?: Array<string | null> | null;
                        } | null;
                        RoofAttributes?: {
                          __typename?: 'RoofAttributesType';
                          RoofConstructions?: {
                            __typename?: 'RoofConstructionsType';
                            RoofConstruction?: Array<string | null> | null;
                          } | null;
                          RoofSurfaces?: {
                            __typename?: 'RoofSurfacesType';
                            RoofSurface?: Array<string | null> | null;
                          } | null;
                          RoofTypes?: {
                            __typename?: 'RoofTypesType';
                            RoofType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        WaterHeater?: {
                          __typename?: 'CommercialWaterHeaterType';
                          WaterHeaterFuelType?: Array<string | null> | null;
                          WaterHeaterType?: Array<string | null> | null;
                        } | null;
                        Wiring?: {
                          __typename?: 'CommercialWiringType';
                          WiringPanelTypes?: {
                            __typename?: 'CommercialWiringPanelTypesType';
                            WiringPanelType?: Array<string | null> | null;
                          } | null;
                          WiringTypes?: {
                            __typename?: 'CommercialWiringTypesType';
                            WiringType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      } | null;
                      Protection?: {
                        __typename?: 'ProtectionType';
                        FireAlarmPresent?: boolean | null;
                        FireAlarmType?: string | null;
                        PhysicalSecurityPresent?: boolean | null;
                        SecurityAlarmPresent?: boolean | null;
                        SecurityAlarmType?: string | null;
                        SprinklerPresent?: boolean | null;
                        SprinklerTypeCode?: string | null;
                        PhysicalSecurityTypes?: {
                          __typename?: 'PhysicalSecurityTypesType';
                          PhysicalSecurityType?: Array<string | null> | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                  Industry?: {
                    __typename?: 'BusinessIndustryType';
                    IBCCode?: string | null;
                    IBCIndustryDescription?: string | null;
                    SICIndustryDescription?: string | null;
                    SICCode?: string | null;
                  } | null;
                } | null;
              } | null;
              optaCommercialPerilScoreData?: {
                __typename?: 'OptaCommercialPerilScoreDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                PropertyPerilScores?: {
                  __typename?: 'PropertyCommercialPerilsType';
                  Peril?: Array<{
                    __typename?: 'PerilDetailsType';
                    LossCostScore?: number | null;
                    PerilType?: string | null;
                    PredictedLossCost?: number | null;
                  } | null> | null;
                } | null;
                CasualtyPerilScores?: {
                  __typename?: 'CasualtyCommercialPerilsType';
                  Peril?: Array<{
                    __typename?: 'PerilDetailsType';
                    LossCostScore?: number | null;
                    PerilType?: string | null;
                    PredictedLossCost?: number | null;
                  } | null> | null;
                } | null;
                AllPerilsScore?: {
                  __typename?: 'CommercialPerilScoreType';
                  LossCostScore?: number | null;
                  PredictedLossCost?: number | null;
                } | null;
              } | null;
            } | null;
            openingHours: Array<{
              __typename?: 'OpeningHoursType';
              day: number;
              open?: any | null;
              close?: any | null;
            }>;
          } | null;
        }>;
        details?: {
          __typename?: 'AnswerInstanceDetailsType';
          lat?: number | null;
          lng?: number | null;
          images: Array<{
            __typename?: 'GoogleImageType';
            description: string;
            shortDescription: string;
            category: CoreGoogleImageCategoryChoices;
            data?: string | null;
          }>;
          google?: {
            __typename?: 'GoogleDetailsType';
            placeId?: string | null;
            rating?: number | null;
            numberOfRatings?: number | null;
          } | null;
          opta?: {
            __typename?: 'OPTADetailsType';
            optaFusData?: {
              __typename?: 'OptaFusDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              Commercial?: {
                __typename?: 'FusProductCommercialType';
                SearchMessage?: string | null;
                CommercialGrade: Array<{
                  __typename?: 'CommercialGradeType';
                  PublicFireProtectionClassification?: number | null;
                  WaterSystem?: string | null;
                  RiskSCORDetail: Array<{
                    __typename?: 'RiskSCORDetailType';
                    RiskSCOR?: number | null;
                    Type?: string | null;
                    Adjustment: Array<{
                      __typename?: 'RiskSCORAdjustmentType';
                      Adjustment?: string | null;
                      AdjustmentType?: string | null;
                    }>;
                  }>;
                }>;
                RespondingFirehall?: {
                  __typename?: 'FirehallType';
                  Address?: string | null;
                  GoverningAgency?: string | null;
                  GradingDate?: any | null;
                  Name?: string | null;
                  PumperFlowImperialGallonsPerMinute?: number | null;
                  RoadDistanceKilometres?: number | null;
                  SuperiorTankerServiceAccreditationDate?: any | null;
                  FirefightersPerFireStation?: {
                    __typename?: 'FirefightersPerFireStationType';
                    FullTime?: number | null;
                    Volunteer?: number | null;
                  } | null;
                  ProtectionGradeTerritories?: {
                    __typename?: 'ProtectedGradeTerritoriesType';
                    Commercial?: string | null;
                    Dwelling?: string | null;
                  } | null;
                } | null;
              } | null;
              Dwelling?: {
                __typename?: 'FusProductDwellingType';
                SearchMessage?: string | null;
                DwellingGrades?: {
                  __typename?: 'DwellingGradesType';
                  AllOtherAreasGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                  FirehallProtectedGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                  HydrantProtectedGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                } | null;
                RespondingFirehall?: {
                  __typename?: 'FirehallType';
                  Address?: string | null;
                  GoverningAgency?: string | null;
                  GradingDate?: any | null;
                  Name?: string | null;
                  PumperFlowImperialGallonsPerMinute?: number | null;
                  RoadDistanceKilometres?: number | null;
                  SuperiorTankerServiceAccreditationDate?: any | null;
                  FirefightersPerFireStation?: {
                    __typename?: 'FirefightersPerFireStationType';
                    FullTime?: number | null;
                    Volunteer?: number | null;
                  } | null;
                  ProtectionGradeTerritories?: {
                    __typename?: 'ProtectedGradeTerritoriesType';
                    Commercial?: string | null;
                    Dwelling?: string | null;
                  } | null;
                } | null;
              } | null;
              EarthquakeCodes?: {
                __typename?: 'EarthquakeCodesType';
                SearchMessage?: string | null;
                EarthquakeCode: Array<{
                  __typename?: 'EarthquakeCodeType';
                  Description?: string | null;
                  SystemName?: string | null;
                  Zone?: string | null;
                }>;
              } | null;
              FusMaps?: {
                __typename?: 'FusMapsType';
                SearchMessage?: string | null;
                FusMap: Array<{
                  __typename?: 'FusMapType';
                  Business?: string | null;
                  FusContent?: {
                    __typename?: 'ContentType';
                    ContentURL?: string | null;
                    Expiry?: any | null;
                    MIMEType?: string | null;
                  } | null;
                }>;
              } | null;
              HydrantCounts?: {
                __typename?: 'HydrantCountsType';
                SearchMessage?: string | null;
                HydrantCount: Array<{
                  __typename?: 'HydrantCountType';
                  NumberOfHydrants: number;
                  Ownership?: string | null;
                  RadiusMetres?: string | null;
                }>;
              } | null;
            } | null;
            optaFloodscoreData?: {
              __typename?: 'OptaFloodScoreDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              FloodScores?: {
                __typename?: 'FloodScoresType';
                FloodScore: Array<{
                  __typename?: 'FloodScoreType';
                  AverageAnnualLossScore?: number | null;
                  Deductible?: number | null;
                  Default?: boolean | null;
                  FloodScore?: number | null;
                  Limit?: number | null;
                  RateFactor?: number | null;
                  Zone?: string | null;
                  ResidentialModifiers?: {
                    __typename?: 'ResidentialConstructionFeaturesType';
                    ArchitecturalStyleType?: string | null;
                    AuxiliaryHeatingType?: string | null;
                    BathroomCount?: string | null;
                    CommercialIndicator?: string | null;
                    ExteriorWallType?: string | null;
                    FinishedBasement?: boolean | null;
                    FoundationType?: string | null;
                    GarageNumberOfCars?: number | null;
                    GarageType?: string | null;
                    KitchenCount?: string | null;
                    OutbuildingPresent?: string | null;
                    PlumbingType?: string | null;
                    PrimaryHeatingType?: string | null;
                    RoofType?: string | null;
                    SquareFootage?: number | null;
                    StoreyCount?: string | null;
                    SwimmingPoolType?: string | null;
                    YearBuilt?: string | null;
                    InspectionDetails?: {
                      __typename?: 'InspectionDetailsType';
                      InspectionDate?: any | null;
                      SponsoredInspection?: boolean | null;
                    } | null;
                  } | null;
                  MultiResidentialModifiers?: {
                    __typename?: 'MultiResidentialConstructionFeaturesType';
                    BasementPresent?: boolean | null;
                    ConstructionType?: string | null;
                    FloorLevel?: number | null;
                  } | null;
                  AverageAnnualLossValues?: {
                    __typename?: 'FloodValueType';
                    Building?: number | null;
                    Contents?: number | null;
                    Total?: number | null;
                  } | null;
                  AdditionalDetails: Array<{
                    __typename?: 'FloodDetailType';
                    Key?: string | null;
                    Value?: string | null;
                  }>;
                  Premium?: {
                    __typename?: 'FloodValueType';
                    Building?: number | null;
                    Contents?: number | null;
                    Total?: number | null;
                  } | null;
                }>;
                FloodMap: Array<{
                  __typename?: 'FloodMapType';
                  FloodEvent?: string | null;
                  Content?: {
                    __typename?: 'ContentType';
                    ContentURL?: string | null;
                    Expiry?: any | null;
                    MIMEType?: string | null;
                  } | null;
                }>;
              } | null;
            } | null;
            optaIclarifyCommercialConstructionData?: {
              __typename?: 'OptaIclarifyCommercialConstructionDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              Messages?: {
                __typename?: 'Messages';
                Message?: Array<string | null> | null;
              } | null;
              Property?: {
                __typename?: 'CommercialPropertyType';
                LotSize?: number | null;
                LotSizeUnitOfMeasurement?: string | null;
                PropertyType?: string | null;
                Buildings?: {
                  __typename?: 'BuildingsType';
                  Building: Array<{
                    __typename?: 'BuildingType';
                    StructureID?: string | null;
                    ConstructionFeatures?: {
                      __typename?: 'CommercialConstructionFeaturesType';
                      CombustibilityClass?: string | null;
                      ConstructionClass?: string | null;
                      FloorHeight?: number | null;
                      FloorHeightUnitOfMeasurement?: string | null;
                      LoadingDockPresent?: boolean | null;
                      NumberOfStoreys?: number | null;
                      NumberOfUnits?: number | null;
                      TotalFloorArea?: number | null;
                      TotalFloorAreaUnitOfMeasurement?: string | null;
                      YearBuilt?: number | null;
                      Basement?: {
                        __typename?: 'CommercialBasementType';
                        BasementArea?: number | null;
                        BasementAreaUnitOfMeasurement?: string | null;
                        BasementPresent?: boolean | null;
                      } | null;
                      BuildingUpdates?: {
                        __typename?: 'BuildingUpdatesType';
                        BuildingUpdate?: Array<number | null> | null;
                      } | null;
                      CommercialExteriorWallTypes?: {
                        __typename?: 'CommercialExteriorWallTypesType';
                        ExteriorWallType?: Array<string | null> | null;
                      } | null;
                      Elevator?: {
                        __typename?: 'ElevatorType';
                        ElevatorCount?: number | null;
                        ElevatorPresent?: boolean | null;
                      } | null;
                      Heating?: {
                        __typename?: 'CommercialHeatingType';
                        HeatingFuelSources?: {
                          __typename?: 'HeatingFuelSourcesType';
                          HeatingFuelSource?: Array<string | null> | null;
                        } | null;
                        HeatingTypes?: {
                          __typename?: 'HeatingTypesType';
                          HeatingType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      Parking?: {
                        __typename?: 'CommercialParkingType';
                        ParkingPresent?: boolean | null;
                        ParkingTypes?: {
                          __typename?: 'CommercialParkingTypesType';
                          ParkingType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      PlumbingTypes?: {
                        __typename?: 'CommercialPlumbingType';
                        PlumbingType?: Array<string | null> | null;
                      } | null;
                      RoofAttributes?: {
                        __typename?: 'RoofAttributesType';
                        RoofConstructions?: {
                          __typename?: 'RoofConstructionsType';
                          RoofConstruction?: Array<string | null> | null;
                        } | null;
                        RoofSurfaces?: {
                          __typename?: 'RoofSurfacesType';
                          RoofSurface?: Array<string | null> | null;
                        } | null;
                        RoofTypes?: {
                          __typename?: 'RoofTypesType';
                          RoofType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      WaterHeater?: {
                        __typename?: 'CommercialWaterHeaterType';
                        WaterHeaterFuelType?: Array<string | null> | null;
                        WaterHeaterType?: Array<string | null> | null;
                      } | null;
                      Wiring?: {
                        __typename?: 'CommercialWiringType';
                        WiringPanelTypes?: {
                          __typename?: 'CommercialWiringPanelTypesType';
                          WiringPanelType?: Array<string | null> | null;
                        } | null;
                        WiringTypes?: {
                          __typename?: 'CommercialWiringTypesType';
                          WiringType?: Array<string | null> | null;
                        } | null;
                      } | null;
                    } | null;
                    Protection?: {
                      __typename?: 'ProtectionType';
                      FireAlarmPresent?: boolean | null;
                      FireAlarmType?: string | null;
                      PhysicalSecurityPresent?: boolean | null;
                      SecurityAlarmPresent?: boolean | null;
                      SecurityAlarmType?: string | null;
                      SprinklerPresent?: boolean | null;
                      SprinklerTypeCode?: string | null;
                      PhysicalSecurityTypes?: {
                        __typename?: 'PhysicalSecurityTypesType';
                        PhysicalSecurityType?: Array<string | null> | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
                Industry?: {
                  __typename?: 'BusinessIndustryType';
                  IBCCode?: string | null;
                  IBCIndustryDescription?: string | null;
                  SICIndustryDescription?: string | null;
                  SICCode?: string | null;
                } | null;
              } | null;
            } | null;
            optaCommercialPerilScoreData?: {
              __typename?: 'OptaCommercialPerilScoreDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              PropertyPerilScores?: {
                __typename?: 'PropertyCommercialPerilsType';
                Peril?: Array<{
                  __typename?: 'PerilDetailsType';
                  LossCostScore?: number | null;
                  PerilType?: string | null;
                  PredictedLossCost?: number | null;
                } | null> | null;
              } | null;
              CasualtyPerilScores?: {
                __typename?: 'CasualtyCommercialPerilsType';
                Peril?: Array<{
                  __typename?: 'PerilDetailsType';
                  LossCostScore?: number | null;
                  PerilType?: string | null;
                  PredictedLossCost?: number | null;
                } | null> | null;
              } | null;
              AllPerilsScore?: {
                __typename?: 'CommercialPerilScoreType';
                LossCostScore?: number | null;
                PredictedLossCost?: number | null;
              } | null;
            } | null;
          } | null;
          openingHours: Array<{
            __typename?: 'OpeningHoursType';
            day: number;
            open?: any | null;
            close?: any | null;
          }>;
        } | null;
      }>;
    }>;
    signature?: {
      __typename?: 'SignatureType';
      signatureData?: string | null;
      signatureTime?: any | null;
    } | null;
    bindings: Array<{
      __typename?: 'BindingType';
      id: string;
      label: string;
      bound: boolean;
      unboundReason: string;
    }>;
  } | null;
};

export type ArchivedQuoteQueryVariables = Exact<{
  archiveID: Scalars['String'];
  token: Scalars['String'];
}>;

export type ArchivedQuoteQuery = {
  __typename?: 'Query';
  archivedQuote?: {
    __typename?: 'ArchivedQuoteType';
    id: string;
    uniqueID: any;
    friendlyId?: string | null;
    additionalInformation: string;
    producerId: string;
    ArchivedQuoteStatus: string;
    language?: {
      __typename?: 'LanguageType';
      shortName: string;
      fullName: string;
    } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
    } | null;
    structuredData?: {
      __typename?: 'StructuredQuoteDataType';
      businessLegalName?: string | null;
      businessName?: string | null;
      businessTradeName?: string | null;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      revenue?: number | null;
      alreadyContactedBrokerName?: string | null;
      contactAddress?: {
        __typename?: 'AddressType';
        formattedAddress?: string | null;
      } | null;
    } | null;
    completedForms: Array<{
      __typename?: 'CompletedFormType';
      id: string;
      policy?: {
        __typename?: 'PolicyType';
        id: string;
        displayName: string;
        internalName: string;
      } | null;
      extensions?: Array<{
        __typename?: 'PolicyExtensionType';
        id: string;
        label: string;
        multiplier?: string | null;
        questionId?: string | null;
        value?: string | null;
      } | null> | null;
      answers: Array<{
        __typename?: 'AnswerInstanceType';
        id: string;
        apiName: string;
        displayName: string;
        label: string;
        helpText?: string | null;
        component: string;
        propsBlob?: any | null;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        value: string;
        date: any;
        method?: string | null;
        dataType: string;
        defaultValue?: string | null;
        subAnswers: Array<{
          __typename?: 'AnswerInstanceType';
          id: string;
          apiName: string;
          displayName: string;
          label: string;
          helpText?: string | null;
          component: string;
          propsBlob?: any | null;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          value: string;
          date: any;
          method?: string | null;
          dataType: string;
          defaultValue?: string | null;
          subAnswers: Array<{
            __typename?: 'AnswerInstanceType';
            id: string;
            apiName: string;
            displayName: string;
            label: string;
            helpText?: string | null;
            component: string;
            propsBlob?: any | null;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            value: string;
            date: any;
            method?: string | null;
            dataType: string;
            defaultValue?: string | null;
            subAnswers: Array<{
              __typename?: 'AnswerInstanceType';
              id: string;
              apiName: string;
              displayName: string;
              label: string;
              helpText?: string | null;
              component: string;
              propsBlob?: any | null;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              value: string;
              date: any;
              method?: string | null;
              dataType: string;
              defaultValue?: string | null;
              subAnswers: Array<{
                __typename?: 'AnswerInstanceType';
                id: string;
                apiName: string;
                displayName: string;
                label: string;
                helpText?: string | null;
                component: string;
                propsBlob?: any | null;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                value: string;
                date: any;
                method?: string | null;
                dataType: string;
                defaultValue?: string | null;
                subAnswers: Array<{
                  __typename?: 'AnswerInstanceType';
                  id: string;
                  apiName: string;
                  displayName: string;
                  label: string;
                  helpText?: string | null;
                  component: string;
                  propsBlob?: any | null;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  value: string;
                  date: any;
                  method?: string | null;
                  dataType: string;
                  defaultValue?: string | null;
                  subAnswers: Array<{
                    __typename?: 'AnswerInstanceType';
                    id: string;
                    apiName: string;
                    displayName: string;
                    label: string;
                    helpText?: string | null;
                    component: string;
                    propsBlob?: any | null;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    value: string;
                    date: any;
                    method?: string | null;
                    dataType: string;
                    defaultValue?: string | null;
                    subAnswers: Array<{
                      __typename?: 'AnswerInstanceType';
                      id: string;
                      apiName: string;
                      displayName: string;
                      label: string;
                      helpText?: string | null;
                      component: string;
                      propsBlob?: any | null;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      value: string;
                      date: any;
                      method?: string | null;
                      dataType: string;
                      defaultValue?: string | null;
                      subAnswers: Array<{
                        __typename?: 'AnswerInstanceType';
                        id: string;
                        apiName: string;
                        displayName: string;
                        label: string;
                        helpText?: string | null;
                        component: string;
                        propsBlob?: any | null;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        value: string;
                        date: any;
                        method?: string | null;
                        dataType: string;
                        defaultValue?: string | null;
                        subAnswers: Array<{
                          __typename?: 'AnswerInstanceType';
                          id: string;
                          apiName: string;
                          displayName: string;
                          label: string;
                          helpText?: string | null;
                          component: string;
                          propsBlob?: any | null;
                          required: boolean;
                          askOnRenewal: boolean;
                          prefillOnRenewal: boolean;
                          value: string;
                          date: any;
                          method?: string | null;
                          dataType: string;
                          defaultValue?: string | null;
                          details?: {
                            __typename?: 'AnswerInstanceDetailsType';
                            lat?: number | null;
                            lng?: number | null;
                            images: Array<{
                              __typename?: 'GoogleImageType';
                              description: string;
                              shortDescription: string;
                              category: CoreGoogleImageCategoryChoices;
                              data?: string | null;
                            }>;
                            google?: {
                              __typename?: 'GoogleDetailsType';
                              placeId?: string | null;
                              rating?: number | null;
                              numberOfRatings?: number | null;
                            } | null;
                            opta?: {
                              __typename?: 'OPTADetailsType';
                              optaFusData?: {
                                __typename?: 'OptaFusDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                Commercial?: {
                                  __typename?: 'FusProductCommercialType';
                                  SearchMessage?: string | null;
                                  CommercialGrade: Array<{
                                    __typename?: 'CommercialGradeType';
                                    PublicFireProtectionClassification?:
                                      | number
                                      | null;
                                    WaterSystem?: string | null;
                                    RiskSCORDetail: Array<{
                                      __typename?: 'RiskSCORDetailType';
                                      RiskSCOR?: number | null;
                                      Type?: string | null;
                                      Adjustment: Array<{
                                        __typename?: 'RiskSCORAdjustmentType';
                                        Adjustment?: string | null;
                                        AdjustmentType?: string | null;
                                      }>;
                                    }>;
                                  }>;
                                  RespondingFirehall?: {
                                    __typename?: 'FirehallType';
                                    Address?: string | null;
                                    GoverningAgency?: string | null;
                                    GradingDate?: any | null;
                                    Name?: string | null;
                                    PumperFlowImperialGallonsPerMinute?:
                                      | number
                                      | null;
                                    RoadDistanceKilometres?: number | null;
                                    SuperiorTankerServiceAccreditationDate?:
                                      | any
                                      | null;
                                    FirefightersPerFireStation?: {
                                      __typename?: 'FirefightersPerFireStationType';
                                      FullTime?: number | null;
                                      Volunteer?: number | null;
                                    } | null;
                                    ProtectionGradeTerritories?: {
                                      __typename?: 'ProtectedGradeTerritoriesType';
                                      Commercial?: string | null;
                                      Dwelling?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Dwelling?: {
                                  __typename?: 'FusProductDwellingType';
                                  SearchMessage?: string | null;
                                  DwellingGrades?: {
                                    __typename?: 'DwellingGradesType';
                                    AllOtherAreasGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                    FirehallProtectedGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                    HydrantProtectedGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                  } | null;
                                  RespondingFirehall?: {
                                    __typename?: 'FirehallType';
                                    Address?: string | null;
                                    GoverningAgency?: string | null;
                                    GradingDate?: any | null;
                                    Name?: string | null;
                                    PumperFlowImperialGallonsPerMinute?:
                                      | number
                                      | null;
                                    RoadDistanceKilometres?: number | null;
                                    SuperiorTankerServiceAccreditationDate?:
                                      | any
                                      | null;
                                    FirefightersPerFireStation?: {
                                      __typename?: 'FirefightersPerFireStationType';
                                      FullTime?: number | null;
                                      Volunteer?: number | null;
                                    } | null;
                                    ProtectionGradeTerritories?: {
                                      __typename?: 'ProtectedGradeTerritoriesType';
                                      Commercial?: string | null;
                                      Dwelling?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                EarthquakeCodes?: {
                                  __typename?: 'EarthquakeCodesType';
                                  SearchMessage?: string | null;
                                  EarthquakeCode: Array<{
                                    __typename?: 'EarthquakeCodeType';
                                    Description?: string | null;
                                    SystemName?: string | null;
                                    Zone?: string | null;
                                  }>;
                                } | null;
                                FusMaps?: {
                                  __typename?: 'FusMapsType';
                                  SearchMessage?: string | null;
                                  FusMap: Array<{
                                    __typename?: 'FusMapType';
                                    Business?: string | null;
                                    FusContent?: {
                                      __typename?: 'ContentType';
                                      ContentURL?: string | null;
                                      Expiry?: any | null;
                                      MIMEType?: string | null;
                                    } | null;
                                  }>;
                                } | null;
                                HydrantCounts?: {
                                  __typename?: 'HydrantCountsType';
                                  SearchMessage?: string | null;
                                  HydrantCount: Array<{
                                    __typename?: 'HydrantCountType';
                                    NumberOfHydrants: number;
                                    Ownership?: string | null;
                                    RadiusMetres?: string | null;
                                  }>;
                                } | null;
                              } | null;
                              optaFloodscoreData?: {
                                __typename?: 'OptaFloodScoreDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                FloodScores?: {
                                  __typename?: 'FloodScoresType';
                                  FloodScore: Array<{
                                    __typename?: 'FloodScoreType';
                                    AverageAnnualLossScore?: number | null;
                                    Deductible?: number | null;
                                    Default?: boolean | null;
                                    FloodScore?: number | null;
                                    Limit?: number | null;
                                    RateFactor?: number | null;
                                    Zone?: string | null;
                                    ResidentialModifiers?: {
                                      __typename?: 'ResidentialConstructionFeaturesType';
                                      ArchitecturalStyleType?: string | null;
                                      AuxiliaryHeatingType?: string | null;
                                      BathroomCount?: string | null;
                                      CommercialIndicator?: string | null;
                                      ExteriorWallType?: string | null;
                                      FinishedBasement?: boolean | null;
                                      FoundationType?: string | null;
                                      GarageNumberOfCars?: number | null;
                                      GarageType?: string | null;
                                      KitchenCount?: string | null;
                                      OutbuildingPresent?: string | null;
                                      PlumbingType?: string | null;
                                      PrimaryHeatingType?: string | null;
                                      RoofType?: string | null;
                                      SquareFootage?: number | null;
                                      StoreyCount?: string | null;
                                      SwimmingPoolType?: string | null;
                                      YearBuilt?: string | null;
                                      InspectionDetails?: {
                                        __typename?: 'InspectionDetailsType';
                                        InspectionDate?: any | null;
                                        SponsoredInspection?: boolean | null;
                                      } | null;
                                    } | null;
                                    MultiResidentialModifiers?: {
                                      __typename?: 'MultiResidentialConstructionFeaturesType';
                                      BasementPresent?: boolean | null;
                                      ConstructionType?: string | null;
                                      FloorLevel?: number | null;
                                    } | null;
                                    AverageAnnualLossValues?: {
                                      __typename?: 'FloodValueType';
                                      Building?: number | null;
                                      Contents?: number | null;
                                      Total?: number | null;
                                    } | null;
                                    AdditionalDetails: Array<{
                                      __typename?: 'FloodDetailType';
                                      Key?: string | null;
                                      Value?: string | null;
                                    }>;
                                    Premium?: {
                                      __typename?: 'FloodValueType';
                                      Building?: number | null;
                                      Contents?: number | null;
                                      Total?: number | null;
                                    } | null;
                                  }>;
                                  FloodMap: Array<{
                                    __typename?: 'FloodMapType';
                                    FloodEvent?: string | null;
                                    Content?: {
                                      __typename?: 'ContentType';
                                      ContentURL?: string | null;
                                      Expiry?: any | null;
                                      MIMEType?: string | null;
                                    } | null;
                                  }>;
                                } | null;
                              } | null;
                              optaIclarifyCommercialConstructionData?: {
                                __typename?: 'OptaIclarifyCommercialConstructionDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                Messages?: {
                                  __typename?: 'Messages';
                                  Message?: Array<string | null> | null;
                                } | null;
                                Property?: {
                                  __typename?: 'CommercialPropertyType';
                                  LotSize?: number | null;
                                  LotSizeUnitOfMeasurement?: string | null;
                                  PropertyType?: string | null;
                                  Buildings?: {
                                    __typename?: 'BuildingsType';
                                    Building: Array<{
                                      __typename?: 'BuildingType';
                                      StructureID?: string | null;
                                      ConstructionFeatures?: {
                                        __typename?: 'CommercialConstructionFeaturesType';
                                        CombustibilityClass?: string | null;
                                        ConstructionClass?: string | null;
                                        FloorHeight?: number | null;
                                        FloorHeightUnitOfMeasurement?:
                                          | string
                                          | null;
                                        LoadingDockPresent?: boolean | null;
                                        NumberOfStoreys?: number | null;
                                        NumberOfUnits?: number | null;
                                        TotalFloorArea?: number | null;
                                        TotalFloorAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        YearBuilt?: number | null;
                                        Basement?: {
                                          __typename?: 'CommercialBasementType';
                                          BasementArea?: number | null;
                                          BasementAreaUnitOfMeasurement?:
                                            | string
                                            | null;
                                          BasementPresent?: boolean | null;
                                        } | null;
                                        BuildingUpdates?: {
                                          __typename?: 'BuildingUpdatesType';
                                          BuildingUpdate?: Array<
                                            number | null
                                          > | null;
                                        } | null;
                                        CommercialExteriorWallTypes?: {
                                          __typename?: 'CommercialExteriorWallTypesType';
                                          ExteriorWallType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        Elevator?: {
                                          __typename?: 'ElevatorType';
                                          ElevatorCount?: number | null;
                                          ElevatorPresent?: boolean | null;
                                        } | null;
                                        Heating?: {
                                          __typename?: 'CommercialHeatingType';
                                          HeatingFuelSources?: {
                                            __typename?: 'HeatingFuelSourcesType';
                                            HeatingFuelSource?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          HeatingTypes?: {
                                            __typename?: 'HeatingTypesType';
                                            HeatingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        Parking?: {
                                          __typename?: 'CommercialParkingType';
                                          ParkingPresent?: boolean | null;
                                          ParkingTypes?: {
                                            __typename?: 'CommercialParkingTypesType';
                                            ParkingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        PlumbingTypes?: {
                                          __typename?: 'CommercialPlumbingType';
                                          PlumbingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofAttributes?: {
                                          __typename?: 'RoofAttributesType';
                                          RoofConstructions?: {
                                            __typename?: 'RoofConstructionsType';
                                            RoofConstruction?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofSurfaces?: {
                                            __typename?: 'RoofSurfacesType';
                                            RoofSurface?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofTypes?: {
                                            __typename?: 'RoofTypesType';
                                            RoofType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        WaterHeater?: {
                                          __typename?: 'CommercialWaterHeaterType';
                                          WaterHeaterFuelType?: Array<
                                            string | null
                                          > | null;
                                          WaterHeaterType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        Wiring?: {
                                          __typename?: 'CommercialWiringType';
                                          WiringPanelTypes?: {
                                            __typename?: 'CommercialWiringPanelTypesType';
                                            WiringPanelType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          WiringTypes?: {
                                            __typename?: 'CommercialWiringTypesType';
                                            WiringType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      Protection?: {
                                        __typename?: 'ProtectionType';
                                        FireAlarmPresent?: boolean | null;
                                        FireAlarmType?: string | null;
                                        PhysicalSecurityPresent?:
                                          | boolean
                                          | null;
                                        SecurityAlarmPresent?: boolean | null;
                                        SecurityAlarmType?: string | null;
                                        SprinklerPresent?: boolean | null;
                                        SprinklerTypeCode?: string | null;
                                        PhysicalSecurityTypes?: {
                                          __typename?: 'PhysicalSecurityTypesType';
                                          PhysicalSecurityType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    }>;
                                  } | null;
                                  Industry?: {
                                    __typename?: 'BusinessIndustryType';
                                    IBCCode?: string | null;
                                    IBCIndustryDescription?: string | null;
                                    SICIndustryDescription?: string | null;
                                    SICCode?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              optaCommercialPerilScoreData?: {
                                __typename?: 'OptaCommercialPerilScoreDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                PropertyPerilScores?: {
                                  __typename?: 'PropertyCommercialPerilsType';
                                  Peril?: Array<{
                                    __typename?: 'PerilDetailsType';
                                    LossCostScore?: number | null;
                                    PerilType?: string | null;
                                    PredictedLossCost?: number | null;
                                  } | null> | null;
                                } | null;
                                CasualtyPerilScores?: {
                                  __typename?: 'CasualtyCommercialPerilsType';
                                  Peril?: Array<{
                                    __typename?: 'PerilDetailsType';
                                    LossCostScore?: number | null;
                                    PerilType?: string | null;
                                    PredictedLossCost?: number | null;
                                  } | null> | null;
                                } | null;
                                AllPerilsScore?: {
                                  __typename?: 'CommercialPerilScoreType';
                                  LossCostScore?: number | null;
                                  PredictedLossCost?: number | null;
                                } | null;
                              } | null;
                            } | null;
                            openingHours: Array<{
                              __typename?: 'OpeningHoursType';
                              day: number;
                              open?: any | null;
                              close?: any | null;
                            }>;
                          } | null;
                        }>;
                        details?: {
                          __typename?: 'AnswerInstanceDetailsType';
                          lat?: number | null;
                          lng?: number | null;
                          images: Array<{
                            __typename?: 'GoogleImageType';
                            description: string;
                            shortDescription: string;
                            category: CoreGoogleImageCategoryChoices;
                            data?: string | null;
                          }>;
                          google?: {
                            __typename?: 'GoogleDetailsType';
                            placeId?: string | null;
                            rating?: number | null;
                            numberOfRatings?: number | null;
                          } | null;
                          opta?: {
                            __typename?: 'OPTADetailsType';
                            optaFusData?: {
                              __typename?: 'OptaFusDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Commercial?: {
                                __typename?: 'FusProductCommercialType';
                                SearchMessage?: string | null;
                                CommercialGrade: Array<{
                                  __typename?: 'CommercialGradeType';
                                  PublicFireProtectionClassification?:
                                    | number
                                    | null;
                                  WaterSystem?: string | null;
                                  RiskSCORDetail: Array<{
                                    __typename?: 'RiskSCORDetailType';
                                    RiskSCOR?: number | null;
                                    Type?: string | null;
                                    Adjustment: Array<{
                                      __typename?: 'RiskSCORAdjustmentType';
                                      Adjustment?: string | null;
                                      AdjustmentType?: string | null;
                                    }>;
                                  }>;
                                }>;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              Dwelling?: {
                                __typename?: 'FusProductDwellingType';
                                SearchMessage?: string | null;
                                DwellingGrades?: {
                                  __typename?: 'DwellingGradesType';
                                  AllOtherAreasGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  FirehallProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  HydrantProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                } | null;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              EarthquakeCodes?: {
                                __typename?: 'EarthquakeCodesType';
                                SearchMessage?: string | null;
                                EarthquakeCode: Array<{
                                  __typename?: 'EarthquakeCodeType';
                                  Description?: string | null;
                                  SystemName?: string | null;
                                  Zone?: string | null;
                                }>;
                              } | null;
                              FusMaps?: {
                                __typename?: 'FusMapsType';
                                SearchMessage?: string | null;
                                FusMap: Array<{
                                  __typename?: 'FusMapType';
                                  Business?: string | null;
                                  FusContent?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                              HydrantCounts?: {
                                __typename?: 'HydrantCountsType';
                                SearchMessage?: string | null;
                                HydrantCount: Array<{
                                  __typename?: 'HydrantCountType';
                                  NumberOfHydrants: number;
                                  Ownership?: string | null;
                                  RadiusMetres?: string | null;
                                }>;
                              } | null;
                            } | null;
                            optaFloodscoreData?: {
                              __typename?: 'OptaFloodScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              FloodScores?: {
                                __typename?: 'FloodScoresType';
                                FloodScore: Array<{
                                  __typename?: 'FloodScoreType';
                                  AverageAnnualLossScore?: number | null;
                                  Deductible?: number | null;
                                  Default?: boolean | null;
                                  FloodScore?: number | null;
                                  Limit?: number | null;
                                  RateFactor?: number | null;
                                  Zone?: string | null;
                                  ResidentialModifiers?: {
                                    __typename?: 'ResidentialConstructionFeaturesType';
                                    ArchitecturalStyleType?: string | null;
                                    AuxiliaryHeatingType?: string | null;
                                    BathroomCount?: string | null;
                                    CommercialIndicator?: string | null;
                                    ExteriorWallType?: string | null;
                                    FinishedBasement?: boolean | null;
                                    FoundationType?: string | null;
                                    GarageNumberOfCars?: number | null;
                                    GarageType?: string | null;
                                    KitchenCount?: string | null;
                                    OutbuildingPresent?: string | null;
                                    PlumbingType?: string | null;
                                    PrimaryHeatingType?: string | null;
                                    RoofType?: string | null;
                                    SquareFootage?: number | null;
                                    StoreyCount?: string | null;
                                    SwimmingPoolType?: string | null;
                                    YearBuilt?: string | null;
                                    InspectionDetails?: {
                                      __typename?: 'InspectionDetailsType';
                                      InspectionDate?: any | null;
                                      SponsoredInspection?: boolean | null;
                                    } | null;
                                  } | null;
                                  MultiResidentialModifiers?: {
                                    __typename?: 'MultiResidentialConstructionFeaturesType';
                                    BasementPresent?: boolean | null;
                                    ConstructionType?: string | null;
                                    FloorLevel?: number | null;
                                  } | null;
                                  AverageAnnualLossValues?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                  AdditionalDetails: Array<{
                                    __typename?: 'FloodDetailType';
                                    Key?: string | null;
                                    Value?: string | null;
                                  }>;
                                  Premium?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                }>;
                                FloodMap: Array<{
                                  __typename?: 'FloodMapType';
                                  FloodEvent?: string | null;
                                  Content?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            optaIclarifyCommercialConstructionData?: {
                              __typename?: 'OptaIclarifyCommercialConstructionDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Messages?: {
                                __typename?: 'Messages';
                                Message?: Array<string | null> | null;
                              } | null;
                              Property?: {
                                __typename?: 'CommercialPropertyType';
                                LotSize?: number | null;
                                LotSizeUnitOfMeasurement?: string | null;
                                PropertyType?: string | null;
                                Buildings?: {
                                  __typename?: 'BuildingsType';
                                  Building: Array<{
                                    __typename?: 'BuildingType';
                                    StructureID?: string | null;
                                    ConstructionFeatures?: {
                                      __typename?: 'CommercialConstructionFeaturesType';
                                      CombustibilityClass?: string | null;
                                      ConstructionClass?: string | null;
                                      FloorHeight?: number | null;
                                      FloorHeightUnitOfMeasurement?:
                                        | string
                                        | null;
                                      LoadingDockPresent?: boolean | null;
                                      NumberOfStoreys?: number | null;
                                      NumberOfUnits?: number | null;
                                      TotalFloorArea?: number | null;
                                      TotalFloorAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      YearBuilt?: number | null;
                                      Basement?: {
                                        __typename?: 'CommercialBasementType';
                                        BasementArea?: number | null;
                                        BasementAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        BasementPresent?: boolean | null;
                                      } | null;
                                      BuildingUpdates?: {
                                        __typename?: 'BuildingUpdatesType';
                                        BuildingUpdate?: Array<
                                          number | null
                                        > | null;
                                      } | null;
                                      CommercialExteriorWallTypes?: {
                                        __typename?: 'CommercialExteriorWallTypesType';
                                        ExteriorWallType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Elevator?: {
                                        __typename?: 'ElevatorType';
                                        ElevatorCount?: number | null;
                                        ElevatorPresent?: boolean | null;
                                      } | null;
                                      Heating?: {
                                        __typename?: 'CommercialHeatingType';
                                        HeatingFuelSources?: {
                                          __typename?: 'HeatingFuelSourcesType';
                                          HeatingFuelSource?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        HeatingTypes?: {
                                          __typename?: 'HeatingTypesType';
                                          HeatingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      Parking?: {
                                        __typename?: 'CommercialParkingType';
                                        ParkingPresent?: boolean | null;
                                        ParkingTypes?: {
                                          __typename?: 'CommercialParkingTypesType';
                                          ParkingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      PlumbingTypes?: {
                                        __typename?: 'CommercialPlumbingType';
                                        PlumbingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofAttributes?: {
                                        __typename?: 'RoofAttributesType';
                                        RoofConstructions?: {
                                          __typename?: 'RoofConstructionsType';
                                          RoofConstruction?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofSurfaces?: {
                                          __typename?: 'RoofSurfacesType';
                                          RoofSurface?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofTypes?: {
                                          __typename?: 'RoofTypesType';
                                          RoofType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      WaterHeater?: {
                                        __typename?: 'CommercialWaterHeaterType';
                                        WaterHeaterFuelType?: Array<
                                          string | null
                                        > | null;
                                        WaterHeaterType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Wiring?: {
                                        __typename?: 'CommercialWiringType';
                                        WiringPanelTypes?: {
                                          __typename?: 'CommercialWiringPanelTypesType';
                                          WiringPanelType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        WiringTypes?: {
                                          __typename?: 'CommercialWiringTypesType';
                                          WiringType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    Protection?: {
                                      __typename?: 'ProtectionType';
                                      FireAlarmPresent?: boolean | null;
                                      FireAlarmType?: string | null;
                                      PhysicalSecurityPresent?: boolean | null;
                                      SecurityAlarmPresent?: boolean | null;
                                      SecurityAlarmType?: string | null;
                                      SprinklerPresent?: boolean | null;
                                      SprinklerTypeCode?: string | null;
                                      PhysicalSecurityTypes?: {
                                        __typename?: 'PhysicalSecurityTypesType';
                                        PhysicalSecurityType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  }>;
                                } | null;
                                Industry?: {
                                  __typename?: 'BusinessIndustryType';
                                  IBCCode?: string | null;
                                  IBCIndustryDescription?: string | null;
                                  SICIndustryDescription?: string | null;
                                  SICCode?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            optaCommercialPerilScoreData?: {
                              __typename?: 'OptaCommercialPerilScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              PropertyPerilScores?: {
                                __typename?: 'PropertyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              CasualtyPerilScores?: {
                                __typename?: 'CasualtyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              AllPerilsScore?: {
                                __typename?: 'CommercialPerilScoreType';
                                LossCostScore?: number | null;
                                PredictedLossCost?: number | null;
                              } | null;
                            } | null;
                          } | null;
                          openingHours: Array<{
                            __typename?: 'OpeningHoursType';
                            day: number;
                            open?: any | null;
                            close?: any | null;
                          }>;
                        } | null;
                      }>;
                      details?: {
                        __typename?: 'AnswerInstanceDetailsType';
                        lat?: number | null;
                        lng?: number | null;
                        images: Array<{
                          __typename?: 'GoogleImageType';
                          description: string;
                          shortDescription: string;
                          category: CoreGoogleImageCategoryChoices;
                          data?: string | null;
                        }>;
                        google?: {
                          __typename?: 'GoogleDetailsType';
                          placeId?: string | null;
                          rating?: number | null;
                          numberOfRatings?: number | null;
                        } | null;
                        opta?: {
                          __typename?: 'OPTADetailsType';
                          optaFusData?: {
                            __typename?: 'OptaFusDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Commercial?: {
                              __typename?: 'FusProductCommercialType';
                              SearchMessage?: string | null;
                              CommercialGrade: Array<{
                                __typename?: 'CommercialGradeType';
                                PublicFireProtectionClassification?:
                                  | number
                                  | null;
                                WaterSystem?: string | null;
                                RiskSCORDetail: Array<{
                                  __typename?: 'RiskSCORDetailType';
                                  RiskSCOR?: number | null;
                                  Type?: string | null;
                                  Adjustment: Array<{
                                    __typename?: 'RiskSCORAdjustmentType';
                                    Adjustment?: string | null;
                                    AdjustmentType?: string | null;
                                  }>;
                                }>;
                              }>;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            Dwelling?: {
                              __typename?: 'FusProductDwellingType';
                              SearchMessage?: string | null;
                              DwellingGrades?: {
                                __typename?: 'DwellingGradesType';
                                AllOtherAreasGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                FirehallProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                HydrantProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                              } | null;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            EarthquakeCodes?: {
                              __typename?: 'EarthquakeCodesType';
                              SearchMessage?: string | null;
                              EarthquakeCode: Array<{
                                __typename?: 'EarthquakeCodeType';
                                Description?: string | null;
                                SystemName?: string | null;
                                Zone?: string | null;
                              }>;
                            } | null;
                            FusMaps?: {
                              __typename?: 'FusMapsType';
                              SearchMessage?: string | null;
                              FusMap: Array<{
                                __typename?: 'FusMapType';
                                Business?: string | null;
                                FusContent?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                            HydrantCounts?: {
                              __typename?: 'HydrantCountsType';
                              SearchMessage?: string | null;
                              HydrantCount: Array<{
                                __typename?: 'HydrantCountType';
                                NumberOfHydrants: number;
                                Ownership?: string | null;
                                RadiusMetres?: string | null;
                              }>;
                            } | null;
                          } | null;
                          optaFloodscoreData?: {
                            __typename?: 'OptaFloodScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            FloodScores?: {
                              __typename?: 'FloodScoresType';
                              FloodScore: Array<{
                                __typename?: 'FloodScoreType';
                                AverageAnnualLossScore?: number | null;
                                Deductible?: number | null;
                                Default?: boolean | null;
                                FloodScore?: number | null;
                                Limit?: number | null;
                                RateFactor?: number | null;
                                Zone?: string | null;
                                ResidentialModifiers?: {
                                  __typename?: 'ResidentialConstructionFeaturesType';
                                  ArchitecturalStyleType?: string | null;
                                  AuxiliaryHeatingType?: string | null;
                                  BathroomCount?: string | null;
                                  CommercialIndicator?: string | null;
                                  ExteriorWallType?: string | null;
                                  FinishedBasement?: boolean | null;
                                  FoundationType?: string | null;
                                  GarageNumberOfCars?: number | null;
                                  GarageType?: string | null;
                                  KitchenCount?: string | null;
                                  OutbuildingPresent?: string | null;
                                  PlumbingType?: string | null;
                                  PrimaryHeatingType?: string | null;
                                  RoofType?: string | null;
                                  SquareFootage?: number | null;
                                  StoreyCount?: string | null;
                                  SwimmingPoolType?: string | null;
                                  YearBuilt?: string | null;
                                  InspectionDetails?: {
                                    __typename?: 'InspectionDetailsType';
                                    InspectionDate?: any | null;
                                    SponsoredInspection?: boolean | null;
                                  } | null;
                                } | null;
                                MultiResidentialModifiers?: {
                                  __typename?: 'MultiResidentialConstructionFeaturesType';
                                  BasementPresent?: boolean | null;
                                  ConstructionType?: string | null;
                                  FloorLevel?: number | null;
                                } | null;
                                AverageAnnualLossValues?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                                AdditionalDetails: Array<{
                                  __typename?: 'FloodDetailType';
                                  Key?: string | null;
                                  Value?: string | null;
                                }>;
                                Premium?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                              }>;
                              FloodMap: Array<{
                                __typename?: 'FloodMapType';
                                FloodEvent?: string | null;
                                Content?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          optaIclarifyCommercialConstructionData?: {
                            __typename?: 'OptaIclarifyCommercialConstructionDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Messages?: {
                              __typename?: 'Messages';
                              Message?: Array<string | null> | null;
                            } | null;
                            Property?: {
                              __typename?: 'CommercialPropertyType';
                              LotSize?: number | null;
                              LotSizeUnitOfMeasurement?: string | null;
                              PropertyType?: string | null;
                              Buildings?: {
                                __typename?: 'BuildingsType';
                                Building: Array<{
                                  __typename?: 'BuildingType';
                                  StructureID?: string | null;
                                  ConstructionFeatures?: {
                                    __typename?: 'CommercialConstructionFeaturesType';
                                    CombustibilityClass?: string | null;
                                    ConstructionClass?: string | null;
                                    FloorHeight?: number | null;
                                    FloorHeightUnitOfMeasurement?:
                                      | string
                                      | null;
                                    LoadingDockPresent?: boolean | null;
                                    NumberOfStoreys?: number | null;
                                    NumberOfUnits?: number | null;
                                    TotalFloorArea?: number | null;
                                    TotalFloorAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    YearBuilt?: number | null;
                                    Basement?: {
                                      __typename?: 'CommercialBasementType';
                                      BasementArea?: number | null;
                                      BasementAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      BasementPresent?: boolean | null;
                                    } | null;
                                    BuildingUpdates?: {
                                      __typename?: 'BuildingUpdatesType';
                                      BuildingUpdate?: Array<
                                        number | null
                                      > | null;
                                    } | null;
                                    CommercialExteriorWallTypes?: {
                                      __typename?: 'CommercialExteriorWallTypesType';
                                      ExteriorWallType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Elevator?: {
                                      __typename?: 'ElevatorType';
                                      ElevatorCount?: number | null;
                                      ElevatorPresent?: boolean | null;
                                    } | null;
                                    Heating?: {
                                      __typename?: 'CommercialHeatingType';
                                      HeatingFuelSources?: {
                                        __typename?: 'HeatingFuelSourcesType';
                                        HeatingFuelSource?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      HeatingTypes?: {
                                        __typename?: 'HeatingTypesType';
                                        HeatingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    Parking?: {
                                      __typename?: 'CommercialParkingType';
                                      ParkingPresent?: boolean | null;
                                      ParkingTypes?: {
                                        __typename?: 'CommercialParkingTypesType';
                                        ParkingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    PlumbingTypes?: {
                                      __typename?: 'CommercialPlumbingType';
                                      PlumbingType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofAttributes?: {
                                      __typename?: 'RoofAttributesType';
                                      RoofConstructions?: {
                                        __typename?: 'RoofConstructionsType';
                                        RoofConstruction?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofSurfaces?: {
                                        __typename?: 'RoofSurfacesType';
                                        RoofSurface?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofTypes?: {
                                        __typename?: 'RoofTypesType';
                                        RoofType?: Array<string | null> | null;
                                      } | null;
                                    } | null;
                                    WaterHeater?: {
                                      __typename?: 'CommercialWaterHeaterType';
                                      WaterHeaterFuelType?: Array<
                                        string | null
                                      > | null;
                                      WaterHeaterType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Wiring?: {
                                      __typename?: 'CommercialWiringType';
                                      WiringPanelTypes?: {
                                        __typename?: 'CommercialWiringPanelTypesType';
                                        WiringPanelType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      WiringTypes?: {
                                        __typename?: 'CommercialWiringTypesType';
                                        WiringType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  Protection?: {
                                    __typename?: 'ProtectionType';
                                    FireAlarmPresent?: boolean | null;
                                    FireAlarmType?: string | null;
                                    PhysicalSecurityPresent?: boolean | null;
                                    SecurityAlarmPresent?: boolean | null;
                                    SecurityAlarmType?: string | null;
                                    SprinklerPresent?: boolean | null;
                                    SprinklerTypeCode?: string | null;
                                    PhysicalSecurityTypes?: {
                                      __typename?: 'PhysicalSecurityTypesType';
                                      PhysicalSecurityType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                  } | null;
                                }>;
                              } | null;
                              Industry?: {
                                __typename?: 'BusinessIndustryType';
                                IBCCode?: string | null;
                                IBCIndustryDescription?: string | null;
                                SICIndustryDescription?: string | null;
                                SICCode?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          optaCommercialPerilScoreData?: {
                            __typename?: 'OptaCommercialPerilScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            PropertyPerilScores?: {
                              __typename?: 'PropertyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            CasualtyPerilScores?: {
                              __typename?: 'CasualtyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            AllPerilsScore?: {
                              __typename?: 'CommercialPerilScoreType';
                              LossCostScore?: number | null;
                              PredictedLossCost?: number | null;
                            } | null;
                          } | null;
                        } | null;
                        openingHours: Array<{
                          __typename?: 'OpeningHoursType';
                          day: number;
                          open?: any | null;
                          close?: any | null;
                        }>;
                      } | null;
                    }>;
                    details?: {
                      __typename?: 'AnswerInstanceDetailsType';
                      lat?: number | null;
                      lng?: number | null;
                      images: Array<{
                        __typename?: 'GoogleImageType';
                        description: string;
                        shortDescription: string;
                        category: CoreGoogleImageCategoryChoices;
                        data?: string | null;
                      }>;
                      google?: {
                        __typename?: 'GoogleDetailsType';
                        placeId?: string | null;
                        rating?: number | null;
                        numberOfRatings?: number | null;
                      } | null;
                      opta?: {
                        __typename?: 'OPTADetailsType';
                        optaFusData?: {
                          __typename?: 'OptaFusDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Commercial?: {
                            __typename?: 'FusProductCommercialType';
                            SearchMessage?: string | null;
                            CommercialGrade: Array<{
                              __typename?: 'CommercialGradeType';
                              PublicFireProtectionClassification?:
                                | number
                                | null;
                              WaterSystem?: string | null;
                              RiskSCORDetail: Array<{
                                __typename?: 'RiskSCORDetailType';
                                RiskSCOR?: number | null;
                                Type?: string | null;
                                Adjustment: Array<{
                                  __typename?: 'RiskSCORAdjustmentType';
                                  Adjustment?: string | null;
                                  AdjustmentType?: string | null;
                                }>;
                              }>;
                            }>;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          Dwelling?: {
                            __typename?: 'FusProductDwellingType';
                            SearchMessage?: string | null;
                            DwellingGrades?: {
                              __typename?: 'DwellingGradesType';
                              AllOtherAreasGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              FirehallProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              HydrantProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                            } | null;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          EarthquakeCodes?: {
                            __typename?: 'EarthquakeCodesType';
                            SearchMessage?: string | null;
                            EarthquakeCode: Array<{
                              __typename?: 'EarthquakeCodeType';
                              Description?: string | null;
                              SystemName?: string | null;
                              Zone?: string | null;
                            }>;
                          } | null;
                          FusMaps?: {
                            __typename?: 'FusMapsType';
                            SearchMessage?: string | null;
                            FusMap: Array<{
                              __typename?: 'FusMapType';
                              Business?: string | null;
                              FusContent?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                          HydrantCounts?: {
                            __typename?: 'HydrantCountsType';
                            SearchMessage?: string | null;
                            HydrantCount: Array<{
                              __typename?: 'HydrantCountType';
                              NumberOfHydrants: number;
                              Ownership?: string | null;
                              RadiusMetres?: string | null;
                            }>;
                          } | null;
                        } | null;
                        optaFloodscoreData?: {
                          __typename?: 'OptaFloodScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          FloodScores?: {
                            __typename?: 'FloodScoresType';
                            FloodScore: Array<{
                              __typename?: 'FloodScoreType';
                              AverageAnnualLossScore?: number | null;
                              Deductible?: number | null;
                              Default?: boolean | null;
                              FloodScore?: number | null;
                              Limit?: number | null;
                              RateFactor?: number | null;
                              Zone?: string | null;
                              ResidentialModifiers?: {
                                __typename?: 'ResidentialConstructionFeaturesType';
                                ArchitecturalStyleType?: string | null;
                                AuxiliaryHeatingType?: string | null;
                                BathroomCount?: string | null;
                                CommercialIndicator?: string | null;
                                ExteriorWallType?: string | null;
                                FinishedBasement?: boolean | null;
                                FoundationType?: string | null;
                                GarageNumberOfCars?: number | null;
                                GarageType?: string | null;
                                KitchenCount?: string | null;
                                OutbuildingPresent?: string | null;
                                PlumbingType?: string | null;
                                PrimaryHeatingType?: string | null;
                                RoofType?: string | null;
                                SquareFootage?: number | null;
                                StoreyCount?: string | null;
                                SwimmingPoolType?: string | null;
                                YearBuilt?: string | null;
                                InspectionDetails?: {
                                  __typename?: 'InspectionDetailsType';
                                  InspectionDate?: any | null;
                                  SponsoredInspection?: boolean | null;
                                } | null;
                              } | null;
                              MultiResidentialModifiers?: {
                                __typename?: 'MultiResidentialConstructionFeaturesType';
                                BasementPresent?: boolean | null;
                                ConstructionType?: string | null;
                                FloorLevel?: number | null;
                              } | null;
                              AverageAnnualLossValues?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                              AdditionalDetails: Array<{
                                __typename?: 'FloodDetailType';
                                Key?: string | null;
                                Value?: string | null;
                              }>;
                              Premium?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                            }>;
                            FloodMap: Array<{
                              __typename?: 'FloodMapType';
                              FloodEvent?: string | null;
                              Content?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        optaIclarifyCommercialConstructionData?: {
                          __typename?: 'OptaIclarifyCommercialConstructionDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Messages?: {
                            __typename?: 'Messages';
                            Message?: Array<string | null> | null;
                          } | null;
                          Property?: {
                            __typename?: 'CommercialPropertyType';
                            LotSize?: number | null;
                            LotSizeUnitOfMeasurement?: string | null;
                            PropertyType?: string | null;
                            Buildings?: {
                              __typename?: 'BuildingsType';
                              Building: Array<{
                                __typename?: 'BuildingType';
                                StructureID?: string | null;
                                ConstructionFeatures?: {
                                  __typename?: 'CommercialConstructionFeaturesType';
                                  CombustibilityClass?: string | null;
                                  ConstructionClass?: string | null;
                                  FloorHeight?: number | null;
                                  FloorHeightUnitOfMeasurement?: string | null;
                                  LoadingDockPresent?: boolean | null;
                                  NumberOfStoreys?: number | null;
                                  NumberOfUnits?: number | null;
                                  TotalFloorArea?: number | null;
                                  TotalFloorAreaUnitOfMeasurement?:
                                    | string
                                    | null;
                                  YearBuilt?: number | null;
                                  Basement?: {
                                    __typename?: 'CommercialBasementType';
                                    BasementArea?: number | null;
                                    BasementAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    BasementPresent?: boolean | null;
                                  } | null;
                                  BuildingUpdates?: {
                                    __typename?: 'BuildingUpdatesType';
                                    BuildingUpdate?: Array<
                                      number | null
                                    > | null;
                                  } | null;
                                  CommercialExteriorWallTypes?: {
                                    __typename?: 'CommercialExteriorWallTypesType';
                                    ExteriorWallType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Elevator?: {
                                    __typename?: 'ElevatorType';
                                    ElevatorCount?: number | null;
                                    ElevatorPresent?: boolean | null;
                                  } | null;
                                  Heating?: {
                                    __typename?: 'CommercialHeatingType';
                                    HeatingFuelSources?: {
                                      __typename?: 'HeatingFuelSourcesType';
                                      HeatingFuelSource?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    HeatingTypes?: {
                                      __typename?: 'HeatingTypesType';
                                      HeatingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  Parking?: {
                                    __typename?: 'CommercialParkingType';
                                    ParkingPresent?: boolean | null;
                                    ParkingTypes?: {
                                      __typename?: 'CommercialParkingTypesType';
                                      ParkingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  PlumbingTypes?: {
                                    __typename?: 'CommercialPlumbingType';
                                    PlumbingType?: Array<string | null> | null;
                                  } | null;
                                  RoofAttributes?: {
                                    __typename?: 'RoofAttributesType';
                                    RoofConstructions?: {
                                      __typename?: 'RoofConstructionsType';
                                      RoofConstruction?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofSurfaces?: {
                                      __typename?: 'RoofSurfacesType';
                                      RoofSurface?: Array<string | null> | null;
                                    } | null;
                                    RoofTypes?: {
                                      __typename?: 'RoofTypesType';
                                      RoofType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  WaterHeater?: {
                                    __typename?: 'CommercialWaterHeaterType';
                                    WaterHeaterFuelType?: Array<
                                      string | null
                                    > | null;
                                    WaterHeaterType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Wiring?: {
                                    __typename?: 'CommercialWiringType';
                                    WiringPanelTypes?: {
                                      __typename?: 'CommercialWiringPanelTypesType';
                                      WiringPanelType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    WiringTypes?: {
                                      __typename?: 'CommercialWiringTypesType';
                                      WiringType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Protection?: {
                                  __typename?: 'ProtectionType';
                                  FireAlarmPresent?: boolean | null;
                                  FireAlarmType?: string | null;
                                  PhysicalSecurityPresent?: boolean | null;
                                  SecurityAlarmPresent?: boolean | null;
                                  SecurityAlarmType?: string | null;
                                  SprinklerPresent?: boolean | null;
                                  SprinklerTypeCode?: string | null;
                                  PhysicalSecurityTypes?: {
                                    __typename?: 'PhysicalSecurityTypesType';
                                    PhysicalSecurityType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                } | null;
                              }>;
                            } | null;
                            Industry?: {
                              __typename?: 'BusinessIndustryType';
                              IBCCode?: string | null;
                              IBCIndustryDescription?: string | null;
                              SICIndustryDescription?: string | null;
                              SICCode?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        optaCommercialPerilScoreData?: {
                          __typename?: 'OptaCommercialPerilScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          PropertyPerilScores?: {
                            __typename?: 'PropertyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          CasualtyPerilScores?: {
                            __typename?: 'CasualtyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          AllPerilsScore?: {
                            __typename?: 'CommercialPerilScoreType';
                            LossCostScore?: number | null;
                            PredictedLossCost?: number | null;
                          } | null;
                        } | null;
                      } | null;
                      openingHours: Array<{
                        __typename?: 'OpeningHoursType';
                        day: number;
                        open?: any | null;
                        close?: any | null;
                      }>;
                    } | null;
                  }>;
                  details?: {
                    __typename?: 'AnswerInstanceDetailsType';
                    lat?: number | null;
                    lng?: number | null;
                    images: Array<{
                      __typename?: 'GoogleImageType';
                      description: string;
                      shortDescription: string;
                      category: CoreGoogleImageCategoryChoices;
                      data?: string | null;
                    }>;
                    google?: {
                      __typename?: 'GoogleDetailsType';
                      placeId?: string | null;
                      rating?: number | null;
                      numberOfRatings?: number | null;
                    } | null;
                    opta?: {
                      __typename?: 'OPTADetailsType';
                      optaFusData?: {
                        __typename?: 'OptaFusDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Commercial?: {
                          __typename?: 'FusProductCommercialType';
                          SearchMessage?: string | null;
                          CommercialGrade: Array<{
                            __typename?: 'CommercialGradeType';
                            PublicFireProtectionClassification?: number | null;
                            WaterSystem?: string | null;
                            RiskSCORDetail: Array<{
                              __typename?: 'RiskSCORDetailType';
                              RiskSCOR?: number | null;
                              Type?: string | null;
                              Adjustment: Array<{
                                __typename?: 'RiskSCORAdjustmentType';
                                Adjustment?: string | null;
                                AdjustmentType?: string | null;
                              }>;
                            }>;
                          }>;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        Dwelling?: {
                          __typename?: 'FusProductDwellingType';
                          SearchMessage?: string | null;
                          DwellingGrades?: {
                            __typename?: 'DwellingGradesType';
                            AllOtherAreasGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            FirehallProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            HydrantProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                          } | null;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        EarthquakeCodes?: {
                          __typename?: 'EarthquakeCodesType';
                          SearchMessage?: string | null;
                          EarthquakeCode: Array<{
                            __typename?: 'EarthquakeCodeType';
                            Description?: string | null;
                            SystemName?: string | null;
                            Zone?: string | null;
                          }>;
                        } | null;
                        FusMaps?: {
                          __typename?: 'FusMapsType';
                          SearchMessage?: string | null;
                          FusMap: Array<{
                            __typename?: 'FusMapType';
                            Business?: string | null;
                            FusContent?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                        HydrantCounts?: {
                          __typename?: 'HydrantCountsType';
                          SearchMessage?: string | null;
                          HydrantCount: Array<{
                            __typename?: 'HydrantCountType';
                            NumberOfHydrants: number;
                            Ownership?: string | null;
                            RadiusMetres?: string | null;
                          }>;
                        } | null;
                      } | null;
                      optaFloodscoreData?: {
                        __typename?: 'OptaFloodScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        FloodScores?: {
                          __typename?: 'FloodScoresType';
                          FloodScore: Array<{
                            __typename?: 'FloodScoreType';
                            AverageAnnualLossScore?: number | null;
                            Deductible?: number | null;
                            Default?: boolean | null;
                            FloodScore?: number | null;
                            Limit?: number | null;
                            RateFactor?: number | null;
                            Zone?: string | null;
                            ResidentialModifiers?: {
                              __typename?: 'ResidentialConstructionFeaturesType';
                              ArchitecturalStyleType?: string | null;
                              AuxiliaryHeatingType?: string | null;
                              BathroomCount?: string | null;
                              CommercialIndicator?: string | null;
                              ExteriorWallType?: string | null;
                              FinishedBasement?: boolean | null;
                              FoundationType?: string | null;
                              GarageNumberOfCars?: number | null;
                              GarageType?: string | null;
                              KitchenCount?: string | null;
                              OutbuildingPresent?: string | null;
                              PlumbingType?: string | null;
                              PrimaryHeatingType?: string | null;
                              RoofType?: string | null;
                              SquareFootage?: number | null;
                              StoreyCount?: string | null;
                              SwimmingPoolType?: string | null;
                              YearBuilt?: string | null;
                              InspectionDetails?: {
                                __typename?: 'InspectionDetailsType';
                                InspectionDate?: any | null;
                                SponsoredInspection?: boolean | null;
                              } | null;
                            } | null;
                            MultiResidentialModifiers?: {
                              __typename?: 'MultiResidentialConstructionFeaturesType';
                              BasementPresent?: boolean | null;
                              ConstructionType?: string | null;
                              FloorLevel?: number | null;
                            } | null;
                            AverageAnnualLossValues?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                            AdditionalDetails: Array<{
                              __typename?: 'FloodDetailType';
                              Key?: string | null;
                              Value?: string | null;
                            }>;
                            Premium?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                          }>;
                          FloodMap: Array<{
                            __typename?: 'FloodMapType';
                            FloodEvent?: string | null;
                            Content?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      optaIclarifyCommercialConstructionData?: {
                        __typename?: 'OptaIclarifyCommercialConstructionDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Messages?: {
                          __typename?: 'Messages';
                          Message?: Array<string | null> | null;
                        } | null;
                        Property?: {
                          __typename?: 'CommercialPropertyType';
                          LotSize?: number | null;
                          LotSizeUnitOfMeasurement?: string | null;
                          PropertyType?: string | null;
                          Buildings?: {
                            __typename?: 'BuildingsType';
                            Building: Array<{
                              __typename?: 'BuildingType';
                              StructureID?: string | null;
                              ConstructionFeatures?: {
                                __typename?: 'CommercialConstructionFeaturesType';
                                CombustibilityClass?: string | null;
                                ConstructionClass?: string | null;
                                FloorHeight?: number | null;
                                FloorHeightUnitOfMeasurement?: string | null;
                                LoadingDockPresent?: boolean | null;
                                NumberOfStoreys?: number | null;
                                NumberOfUnits?: number | null;
                                TotalFloorArea?: number | null;
                                TotalFloorAreaUnitOfMeasurement?: string | null;
                                YearBuilt?: number | null;
                                Basement?: {
                                  __typename?: 'CommercialBasementType';
                                  BasementArea?: number | null;
                                  BasementAreaUnitOfMeasurement?: string | null;
                                  BasementPresent?: boolean | null;
                                } | null;
                                BuildingUpdates?: {
                                  __typename?: 'BuildingUpdatesType';
                                  BuildingUpdate?: Array<number | null> | null;
                                } | null;
                                CommercialExteriorWallTypes?: {
                                  __typename?: 'CommercialExteriorWallTypesType';
                                  ExteriorWallType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                Elevator?: {
                                  __typename?: 'ElevatorType';
                                  ElevatorCount?: number | null;
                                  ElevatorPresent?: boolean | null;
                                } | null;
                                Heating?: {
                                  __typename?: 'CommercialHeatingType';
                                  HeatingFuelSources?: {
                                    __typename?: 'HeatingFuelSourcesType';
                                    HeatingFuelSource?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  HeatingTypes?: {
                                    __typename?: 'HeatingTypesType';
                                    HeatingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                Parking?: {
                                  __typename?: 'CommercialParkingType';
                                  ParkingPresent?: boolean | null;
                                  ParkingTypes?: {
                                    __typename?: 'CommercialParkingTypesType';
                                    ParkingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                PlumbingTypes?: {
                                  __typename?: 'CommercialPlumbingType';
                                  PlumbingType?: Array<string | null> | null;
                                } | null;
                                RoofAttributes?: {
                                  __typename?: 'RoofAttributesType';
                                  RoofConstructions?: {
                                    __typename?: 'RoofConstructionsType';
                                    RoofConstruction?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  RoofSurfaces?: {
                                    __typename?: 'RoofSurfacesType';
                                    RoofSurface?: Array<string | null> | null;
                                  } | null;
                                  RoofTypes?: {
                                    __typename?: 'RoofTypesType';
                                    RoofType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                WaterHeater?: {
                                  __typename?: 'CommercialWaterHeaterType';
                                  WaterHeaterFuelType?: Array<
                                    string | null
                                  > | null;
                                  WaterHeaterType?: Array<string | null> | null;
                                } | null;
                                Wiring?: {
                                  __typename?: 'CommercialWiringType';
                                  WiringPanelTypes?: {
                                    __typename?: 'CommercialWiringPanelTypesType';
                                    WiringPanelType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  WiringTypes?: {
                                    __typename?: 'CommercialWiringTypesType';
                                    WiringType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                              } | null;
                              Protection?: {
                                __typename?: 'ProtectionType';
                                FireAlarmPresent?: boolean | null;
                                FireAlarmType?: string | null;
                                PhysicalSecurityPresent?: boolean | null;
                                SecurityAlarmPresent?: boolean | null;
                                SecurityAlarmType?: string | null;
                                SprinklerPresent?: boolean | null;
                                SprinklerTypeCode?: string | null;
                                PhysicalSecurityTypes?: {
                                  __typename?: 'PhysicalSecurityTypesType';
                                  PhysicalSecurityType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                              } | null;
                            }>;
                          } | null;
                          Industry?: {
                            __typename?: 'BusinessIndustryType';
                            IBCCode?: string | null;
                            IBCIndustryDescription?: string | null;
                            SICIndustryDescription?: string | null;
                            SICCode?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      optaCommercialPerilScoreData?: {
                        __typename?: 'OptaCommercialPerilScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        PropertyPerilScores?: {
                          __typename?: 'PropertyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        CasualtyPerilScores?: {
                          __typename?: 'CasualtyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        AllPerilsScore?: {
                          __typename?: 'CommercialPerilScoreType';
                          LossCostScore?: number | null;
                          PredictedLossCost?: number | null;
                        } | null;
                      } | null;
                    } | null;
                    openingHours: Array<{
                      __typename?: 'OpeningHoursType';
                      day: number;
                      open?: any | null;
                      close?: any | null;
                    }>;
                  } | null;
                }>;
                details?: {
                  __typename?: 'AnswerInstanceDetailsType';
                  lat?: number | null;
                  lng?: number | null;
                  images: Array<{
                    __typename?: 'GoogleImageType';
                    description: string;
                    shortDescription: string;
                    category: CoreGoogleImageCategoryChoices;
                    data?: string | null;
                  }>;
                  google?: {
                    __typename?: 'GoogleDetailsType';
                    placeId?: string | null;
                    rating?: number | null;
                    numberOfRatings?: number | null;
                  } | null;
                  opta?: {
                    __typename?: 'OPTADetailsType';
                    optaFusData?: {
                      __typename?: 'OptaFusDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Commercial?: {
                        __typename?: 'FusProductCommercialType';
                        SearchMessage?: string | null;
                        CommercialGrade: Array<{
                          __typename?: 'CommercialGradeType';
                          PublicFireProtectionClassification?: number | null;
                          WaterSystem?: string | null;
                          RiskSCORDetail: Array<{
                            __typename?: 'RiskSCORDetailType';
                            RiskSCOR?: number | null;
                            Type?: string | null;
                            Adjustment: Array<{
                              __typename?: 'RiskSCORAdjustmentType';
                              Adjustment?: string | null;
                              AdjustmentType?: string | null;
                            }>;
                          }>;
                        }>;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      Dwelling?: {
                        __typename?: 'FusProductDwellingType';
                        SearchMessage?: string | null;
                        DwellingGrades?: {
                          __typename?: 'DwellingGradesType';
                          AllOtherAreasGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          FirehallProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          HydrantProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                        } | null;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      EarthquakeCodes?: {
                        __typename?: 'EarthquakeCodesType';
                        SearchMessage?: string | null;
                        EarthquakeCode: Array<{
                          __typename?: 'EarthquakeCodeType';
                          Description?: string | null;
                          SystemName?: string | null;
                          Zone?: string | null;
                        }>;
                      } | null;
                      FusMaps?: {
                        __typename?: 'FusMapsType';
                        SearchMessage?: string | null;
                        FusMap: Array<{
                          __typename?: 'FusMapType';
                          Business?: string | null;
                          FusContent?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                      HydrantCounts?: {
                        __typename?: 'HydrantCountsType';
                        SearchMessage?: string | null;
                        HydrantCount: Array<{
                          __typename?: 'HydrantCountType';
                          NumberOfHydrants: number;
                          Ownership?: string | null;
                          RadiusMetres?: string | null;
                        }>;
                      } | null;
                    } | null;
                    optaFloodscoreData?: {
                      __typename?: 'OptaFloodScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      FloodScores?: {
                        __typename?: 'FloodScoresType';
                        FloodScore: Array<{
                          __typename?: 'FloodScoreType';
                          AverageAnnualLossScore?: number | null;
                          Deductible?: number | null;
                          Default?: boolean | null;
                          FloodScore?: number | null;
                          Limit?: number | null;
                          RateFactor?: number | null;
                          Zone?: string | null;
                          ResidentialModifiers?: {
                            __typename?: 'ResidentialConstructionFeaturesType';
                            ArchitecturalStyleType?: string | null;
                            AuxiliaryHeatingType?: string | null;
                            BathroomCount?: string | null;
                            CommercialIndicator?: string | null;
                            ExteriorWallType?: string | null;
                            FinishedBasement?: boolean | null;
                            FoundationType?: string | null;
                            GarageNumberOfCars?: number | null;
                            GarageType?: string | null;
                            KitchenCount?: string | null;
                            OutbuildingPresent?: string | null;
                            PlumbingType?: string | null;
                            PrimaryHeatingType?: string | null;
                            RoofType?: string | null;
                            SquareFootage?: number | null;
                            StoreyCount?: string | null;
                            SwimmingPoolType?: string | null;
                            YearBuilt?: string | null;
                            InspectionDetails?: {
                              __typename?: 'InspectionDetailsType';
                              InspectionDate?: any | null;
                              SponsoredInspection?: boolean | null;
                            } | null;
                          } | null;
                          MultiResidentialModifiers?: {
                            __typename?: 'MultiResidentialConstructionFeaturesType';
                            BasementPresent?: boolean | null;
                            ConstructionType?: string | null;
                            FloorLevel?: number | null;
                          } | null;
                          AverageAnnualLossValues?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                          AdditionalDetails: Array<{
                            __typename?: 'FloodDetailType';
                            Key?: string | null;
                            Value?: string | null;
                          }>;
                          Premium?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                        }>;
                        FloodMap: Array<{
                          __typename?: 'FloodMapType';
                          FloodEvent?: string | null;
                          Content?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    optaIclarifyCommercialConstructionData?: {
                      __typename?: 'OptaIclarifyCommercialConstructionDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Messages?: {
                        __typename?: 'Messages';
                        Message?: Array<string | null> | null;
                      } | null;
                      Property?: {
                        __typename?: 'CommercialPropertyType';
                        LotSize?: number | null;
                        LotSizeUnitOfMeasurement?: string | null;
                        PropertyType?: string | null;
                        Buildings?: {
                          __typename?: 'BuildingsType';
                          Building: Array<{
                            __typename?: 'BuildingType';
                            StructureID?: string | null;
                            ConstructionFeatures?: {
                              __typename?: 'CommercialConstructionFeaturesType';
                              CombustibilityClass?: string | null;
                              ConstructionClass?: string | null;
                              FloorHeight?: number | null;
                              FloorHeightUnitOfMeasurement?: string | null;
                              LoadingDockPresent?: boolean | null;
                              NumberOfStoreys?: number | null;
                              NumberOfUnits?: number | null;
                              TotalFloorArea?: number | null;
                              TotalFloorAreaUnitOfMeasurement?: string | null;
                              YearBuilt?: number | null;
                              Basement?: {
                                __typename?: 'CommercialBasementType';
                                BasementArea?: number | null;
                                BasementAreaUnitOfMeasurement?: string | null;
                                BasementPresent?: boolean | null;
                              } | null;
                              BuildingUpdates?: {
                                __typename?: 'BuildingUpdatesType';
                                BuildingUpdate?: Array<number | null> | null;
                              } | null;
                              CommercialExteriorWallTypes?: {
                                __typename?: 'CommercialExteriorWallTypesType';
                                ExteriorWallType?: Array<string | null> | null;
                              } | null;
                              Elevator?: {
                                __typename?: 'ElevatorType';
                                ElevatorCount?: number | null;
                                ElevatorPresent?: boolean | null;
                              } | null;
                              Heating?: {
                                __typename?: 'CommercialHeatingType';
                                HeatingFuelSources?: {
                                  __typename?: 'HeatingFuelSourcesType';
                                  HeatingFuelSource?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                HeatingTypes?: {
                                  __typename?: 'HeatingTypesType';
                                  HeatingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              Parking?: {
                                __typename?: 'CommercialParkingType';
                                ParkingPresent?: boolean | null;
                                ParkingTypes?: {
                                  __typename?: 'CommercialParkingTypesType';
                                  ParkingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              PlumbingTypes?: {
                                __typename?: 'CommercialPlumbingType';
                                PlumbingType?: Array<string | null> | null;
                              } | null;
                              RoofAttributes?: {
                                __typename?: 'RoofAttributesType';
                                RoofConstructions?: {
                                  __typename?: 'RoofConstructionsType';
                                  RoofConstruction?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                RoofSurfaces?: {
                                  __typename?: 'RoofSurfacesType';
                                  RoofSurface?: Array<string | null> | null;
                                } | null;
                                RoofTypes?: {
                                  __typename?: 'RoofTypesType';
                                  RoofType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              WaterHeater?: {
                                __typename?: 'CommercialWaterHeaterType';
                                WaterHeaterFuelType?: Array<
                                  string | null
                                > | null;
                                WaterHeaterType?: Array<string | null> | null;
                              } | null;
                              Wiring?: {
                                __typename?: 'CommercialWiringType';
                                WiringPanelTypes?: {
                                  __typename?: 'CommercialWiringPanelTypesType';
                                  WiringPanelType?: Array<string | null> | null;
                                } | null;
                                WiringTypes?: {
                                  __typename?: 'CommercialWiringTypesType';
                                  WiringType?: Array<string | null> | null;
                                } | null;
                              } | null;
                            } | null;
                            Protection?: {
                              __typename?: 'ProtectionType';
                              FireAlarmPresent?: boolean | null;
                              FireAlarmType?: string | null;
                              PhysicalSecurityPresent?: boolean | null;
                              SecurityAlarmPresent?: boolean | null;
                              SecurityAlarmType?: string | null;
                              SprinklerPresent?: boolean | null;
                              SprinklerTypeCode?: string | null;
                              PhysicalSecurityTypes?: {
                                __typename?: 'PhysicalSecurityTypesType';
                                PhysicalSecurityType?: Array<
                                  string | null
                                > | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                        Industry?: {
                          __typename?: 'BusinessIndustryType';
                          IBCCode?: string | null;
                          IBCIndustryDescription?: string | null;
                          SICIndustryDescription?: string | null;
                          SICCode?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    optaCommercialPerilScoreData?: {
                      __typename?: 'OptaCommercialPerilScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      PropertyPerilScores?: {
                        __typename?: 'PropertyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      CasualtyPerilScores?: {
                        __typename?: 'CasualtyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      AllPerilsScore?: {
                        __typename?: 'CommercialPerilScoreType';
                        LossCostScore?: number | null;
                        PredictedLossCost?: number | null;
                      } | null;
                    } | null;
                  } | null;
                  openingHours: Array<{
                    __typename?: 'OpeningHoursType';
                    day: number;
                    open?: any | null;
                    close?: any | null;
                  }>;
                } | null;
              }>;
              details?: {
                __typename?: 'AnswerInstanceDetailsType';
                lat?: number | null;
                lng?: number | null;
                images: Array<{
                  __typename?: 'GoogleImageType';
                  description: string;
                  shortDescription: string;
                  category: CoreGoogleImageCategoryChoices;
                  data?: string | null;
                }>;
                google?: {
                  __typename?: 'GoogleDetailsType';
                  placeId?: string | null;
                  rating?: number | null;
                  numberOfRatings?: number | null;
                } | null;
                opta?: {
                  __typename?: 'OPTADetailsType';
                  optaFusData?: {
                    __typename?: 'OptaFusDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Commercial?: {
                      __typename?: 'FusProductCommercialType';
                      SearchMessage?: string | null;
                      CommercialGrade: Array<{
                        __typename?: 'CommercialGradeType';
                        PublicFireProtectionClassification?: number | null;
                        WaterSystem?: string | null;
                        RiskSCORDetail: Array<{
                          __typename?: 'RiskSCORDetailType';
                          RiskSCOR?: number | null;
                          Type?: string | null;
                          Adjustment: Array<{
                            __typename?: 'RiskSCORAdjustmentType';
                            Adjustment?: string | null;
                            AdjustmentType?: string | null;
                          }>;
                        }>;
                      }>;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    Dwelling?: {
                      __typename?: 'FusProductDwellingType';
                      SearchMessage?: string | null;
                      DwellingGrades?: {
                        __typename?: 'DwellingGradesType';
                        AllOtherAreasGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        FirehallProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        HydrantProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                      } | null;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    EarthquakeCodes?: {
                      __typename?: 'EarthquakeCodesType';
                      SearchMessage?: string | null;
                      EarthquakeCode: Array<{
                        __typename?: 'EarthquakeCodeType';
                        Description?: string | null;
                        SystemName?: string | null;
                        Zone?: string | null;
                      }>;
                    } | null;
                    FusMaps?: {
                      __typename?: 'FusMapsType';
                      SearchMessage?: string | null;
                      FusMap: Array<{
                        __typename?: 'FusMapType';
                        Business?: string | null;
                        FusContent?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                    HydrantCounts?: {
                      __typename?: 'HydrantCountsType';
                      SearchMessage?: string | null;
                      HydrantCount: Array<{
                        __typename?: 'HydrantCountType';
                        NumberOfHydrants: number;
                        Ownership?: string | null;
                        RadiusMetres?: string | null;
                      }>;
                    } | null;
                  } | null;
                  optaFloodscoreData?: {
                    __typename?: 'OptaFloodScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    FloodScores?: {
                      __typename?: 'FloodScoresType';
                      FloodScore: Array<{
                        __typename?: 'FloodScoreType';
                        AverageAnnualLossScore?: number | null;
                        Deductible?: number | null;
                        Default?: boolean | null;
                        FloodScore?: number | null;
                        Limit?: number | null;
                        RateFactor?: number | null;
                        Zone?: string | null;
                        ResidentialModifiers?: {
                          __typename?: 'ResidentialConstructionFeaturesType';
                          ArchitecturalStyleType?: string | null;
                          AuxiliaryHeatingType?: string | null;
                          BathroomCount?: string | null;
                          CommercialIndicator?: string | null;
                          ExteriorWallType?: string | null;
                          FinishedBasement?: boolean | null;
                          FoundationType?: string | null;
                          GarageNumberOfCars?: number | null;
                          GarageType?: string | null;
                          KitchenCount?: string | null;
                          OutbuildingPresent?: string | null;
                          PlumbingType?: string | null;
                          PrimaryHeatingType?: string | null;
                          RoofType?: string | null;
                          SquareFootage?: number | null;
                          StoreyCount?: string | null;
                          SwimmingPoolType?: string | null;
                          YearBuilt?: string | null;
                          InspectionDetails?: {
                            __typename?: 'InspectionDetailsType';
                            InspectionDate?: any | null;
                            SponsoredInspection?: boolean | null;
                          } | null;
                        } | null;
                        MultiResidentialModifiers?: {
                          __typename?: 'MultiResidentialConstructionFeaturesType';
                          BasementPresent?: boolean | null;
                          ConstructionType?: string | null;
                          FloorLevel?: number | null;
                        } | null;
                        AverageAnnualLossValues?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                        AdditionalDetails: Array<{
                          __typename?: 'FloodDetailType';
                          Key?: string | null;
                          Value?: string | null;
                        }>;
                        Premium?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                      }>;
                      FloodMap: Array<{
                        __typename?: 'FloodMapType';
                        FloodEvent?: string | null;
                        Content?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  optaIclarifyCommercialConstructionData?: {
                    __typename?: 'OptaIclarifyCommercialConstructionDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Messages?: {
                      __typename?: 'Messages';
                      Message?: Array<string | null> | null;
                    } | null;
                    Property?: {
                      __typename?: 'CommercialPropertyType';
                      LotSize?: number | null;
                      LotSizeUnitOfMeasurement?: string | null;
                      PropertyType?: string | null;
                      Buildings?: {
                        __typename?: 'BuildingsType';
                        Building: Array<{
                          __typename?: 'BuildingType';
                          StructureID?: string | null;
                          ConstructionFeatures?: {
                            __typename?: 'CommercialConstructionFeaturesType';
                            CombustibilityClass?: string | null;
                            ConstructionClass?: string | null;
                            FloorHeight?: number | null;
                            FloorHeightUnitOfMeasurement?: string | null;
                            LoadingDockPresent?: boolean | null;
                            NumberOfStoreys?: number | null;
                            NumberOfUnits?: number | null;
                            TotalFloorArea?: number | null;
                            TotalFloorAreaUnitOfMeasurement?: string | null;
                            YearBuilt?: number | null;
                            Basement?: {
                              __typename?: 'CommercialBasementType';
                              BasementArea?: number | null;
                              BasementAreaUnitOfMeasurement?: string | null;
                              BasementPresent?: boolean | null;
                            } | null;
                            BuildingUpdates?: {
                              __typename?: 'BuildingUpdatesType';
                              BuildingUpdate?: Array<number | null> | null;
                            } | null;
                            CommercialExteriorWallTypes?: {
                              __typename?: 'CommercialExteriorWallTypesType';
                              ExteriorWallType?: Array<string | null> | null;
                            } | null;
                            Elevator?: {
                              __typename?: 'ElevatorType';
                              ElevatorCount?: number | null;
                              ElevatorPresent?: boolean | null;
                            } | null;
                            Heating?: {
                              __typename?: 'CommercialHeatingType';
                              HeatingFuelSources?: {
                                __typename?: 'HeatingFuelSourcesType';
                                HeatingFuelSource?: Array<string | null> | null;
                              } | null;
                              HeatingTypes?: {
                                __typename?: 'HeatingTypesType';
                                HeatingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            Parking?: {
                              __typename?: 'CommercialParkingType';
                              ParkingPresent?: boolean | null;
                              ParkingTypes?: {
                                __typename?: 'CommercialParkingTypesType';
                                ParkingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            PlumbingTypes?: {
                              __typename?: 'CommercialPlumbingType';
                              PlumbingType?: Array<string | null> | null;
                            } | null;
                            RoofAttributes?: {
                              __typename?: 'RoofAttributesType';
                              RoofConstructions?: {
                                __typename?: 'RoofConstructionsType';
                                RoofConstruction?: Array<string | null> | null;
                              } | null;
                              RoofSurfaces?: {
                                __typename?: 'RoofSurfacesType';
                                RoofSurface?: Array<string | null> | null;
                              } | null;
                              RoofTypes?: {
                                __typename?: 'RoofTypesType';
                                RoofType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            WaterHeater?: {
                              __typename?: 'CommercialWaterHeaterType';
                              WaterHeaterFuelType?: Array<string | null> | null;
                              WaterHeaterType?: Array<string | null> | null;
                            } | null;
                            Wiring?: {
                              __typename?: 'CommercialWiringType';
                              WiringPanelTypes?: {
                                __typename?: 'CommercialWiringPanelTypesType';
                                WiringPanelType?: Array<string | null> | null;
                              } | null;
                              WiringTypes?: {
                                __typename?: 'CommercialWiringTypesType';
                                WiringType?: Array<string | null> | null;
                              } | null;
                            } | null;
                          } | null;
                          Protection?: {
                            __typename?: 'ProtectionType';
                            FireAlarmPresent?: boolean | null;
                            FireAlarmType?: string | null;
                            PhysicalSecurityPresent?: boolean | null;
                            SecurityAlarmPresent?: boolean | null;
                            SecurityAlarmType?: string | null;
                            SprinklerPresent?: boolean | null;
                            SprinklerTypeCode?: string | null;
                            PhysicalSecurityTypes?: {
                              __typename?: 'PhysicalSecurityTypesType';
                              PhysicalSecurityType?: Array<
                                string | null
                              > | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                      Industry?: {
                        __typename?: 'BusinessIndustryType';
                        IBCCode?: string | null;
                        IBCIndustryDescription?: string | null;
                        SICIndustryDescription?: string | null;
                        SICCode?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  optaCommercialPerilScoreData?: {
                    __typename?: 'OptaCommercialPerilScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    PropertyPerilScores?: {
                      __typename?: 'PropertyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    CasualtyPerilScores?: {
                      __typename?: 'CasualtyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    AllPerilsScore?: {
                      __typename?: 'CommercialPerilScoreType';
                      LossCostScore?: number | null;
                      PredictedLossCost?: number | null;
                    } | null;
                  } | null;
                } | null;
                openingHours: Array<{
                  __typename?: 'OpeningHoursType';
                  day: number;
                  open?: any | null;
                  close?: any | null;
                }>;
              } | null;
            }>;
            details?: {
              __typename?: 'AnswerInstanceDetailsType';
              lat?: number | null;
              lng?: number | null;
              images: Array<{
                __typename?: 'GoogleImageType';
                description: string;
                shortDescription: string;
                category: CoreGoogleImageCategoryChoices;
                data?: string | null;
              }>;
              google?: {
                __typename?: 'GoogleDetailsType';
                placeId?: string | null;
                rating?: number | null;
                numberOfRatings?: number | null;
              } | null;
              opta?: {
                __typename?: 'OPTADetailsType';
                optaFusData?: {
                  __typename?: 'OptaFusDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Commercial?: {
                    __typename?: 'FusProductCommercialType';
                    SearchMessage?: string | null;
                    CommercialGrade: Array<{
                      __typename?: 'CommercialGradeType';
                      PublicFireProtectionClassification?: number | null;
                      WaterSystem?: string | null;
                      RiskSCORDetail: Array<{
                        __typename?: 'RiskSCORDetailType';
                        RiskSCOR?: number | null;
                        Type?: string | null;
                        Adjustment: Array<{
                          __typename?: 'RiskSCORAdjustmentType';
                          Adjustment?: string | null;
                          AdjustmentType?: string | null;
                        }>;
                      }>;
                    }>;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  Dwelling?: {
                    __typename?: 'FusProductDwellingType';
                    SearchMessage?: string | null;
                    DwellingGrades?: {
                      __typename?: 'DwellingGradesType';
                      AllOtherAreasGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      FirehallProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      HydrantProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                    } | null;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  EarthquakeCodes?: {
                    __typename?: 'EarthquakeCodesType';
                    SearchMessage?: string | null;
                    EarthquakeCode: Array<{
                      __typename?: 'EarthquakeCodeType';
                      Description?: string | null;
                      SystemName?: string | null;
                      Zone?: string | null;
                    }>;
                  } | null;
                  FusMaps?: {
                    __typename?: 'FusMapsType';
                    SearchMessage?: string | null;
                    FusMap: Array<{
                      __typename?: 'FusMapType';
                      Business?: string | null;
                      FusContent?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                  HydrantCounts?: {
                    __typename?: 'HydrantCountsType';
                    SearchMessage?: string | null;
                    HydrantCount: Array<{
                      __typename?: 'HydrantCountType';
                      NumberOfHydrants: number;
                      Ownership?: string | null;
                      RadiusMetres?: string | null;
                    }>;
                  } | null;
                } | null;
                optaFloodscoreData?: {
                  __typename?: 'OptaFloodScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  FloodScores?: {
                    __typename?: 'FloodScoresType';
                    FloodScore: Array<{
                      __typename?: 'FloodScoreType';
                      AverageAnnualLossScore?: number | null;
                      Deductible?: number | null;
                      Default?: boolean | null;
                      FloodScore?: number | null;
                      Limit?: number | null;
                      RateFactor?: number | null;
                      Zone?: string | null;
                      ResidentialModifiers?: {
                        __typename?: 'ResidentialConstructionFeaturesType';
                        ArchitecturalStyleType?: string | null;
                        AuxiliaryHeatingType?: string | null;
                        BathroomCount?: string | null;
                        CommercialIndicator?: string | null;
                        ExteriorWallType?: string | null;
                        FinishedBasement?: boolean | null;
                        FoundationType?: string | null;
                        GarageNumberOfCars?: number | null;
                        GarageType?: string | null;
                        KitchenCount?: string | null;
                        OutbuildingPresent?: string | null;
                        PlumbingType?: string | null;
                        PrimaryHeatingType?: string | null;
                        RoofType?: string | null;
                        SquareFootage?: number | null;
                        StoreyCount?: string | null;
                        SwimmingPoolType?: string | null;
                        YearBuilt?: string | null;
                        InspectionDetails?: {
                          __typename?: 'InspectionDetailsType';
                          InspectionDate?: any | null;
                          SponsoredInspection?: boolean | null;
                        } | null;
                      } | null;
                      MultiResidentialModifiers?: {
                        __typename?: 'MultiResidentialConstructionFeaturesType';
                        BasementPresent?: boolean | null;
                        ConstructionType?: string | null;
                        FloorLevel?: number | null;
                      } | null;
                      AverageAnnualLossValues?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                      AdditionalDetails: Array<{
                        __typename?: 'FloodDetailType';
                        Key?: string | null;
                        Value?: string | null;
                      }>;
                      Premium?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                    }>;
                    FloodMap: Array<{
                      __typename?: 'FloodMapType';
                      FloodEvent?: string | null;
                      Content?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                } | null;
                optaIclarifyCommercialConstructionData?: {
                  __typename?: 'OptaIclarifyCommercialConstructionDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Messages?: {
                    __typename?: 'Messages';
                    Message?: Array<string | null> | null;
                  } | null;
                  Property?: {
                    __typename?: 'CommercialPropertyType';
                    LotSize?: number | null;
                    LotSizeUnitOfMeasurement?: string | null;
                    PropertyType?: string | null;
                    Buildings?: {
                      __typename?: 'BuildingsType';
                      Building: Array<{
                        __typename?: 'BuildingType';
                        StructureID?: string | null;
                        ConstructionFeatures?: {
                          __typename?: 'CommercialConstructionFeaturesType';
                          CombustibilityClass?: string | null;
                          ConstructionClass?: string | null;
                          FloorHeight?: number | null;
                          FloorHeightUnitOfMeasurement?: string | null;
                          LoadingDockPresent?: boolean | null;
                          NumberOfStoreys?: number | null;
                          NumberOfUnits?: number | null;
                          TotalFloorArea?: number | null;
                          TotalFloorAreaUnitOfMeasurement?: string | null;
                          YearBuilt?: number | null;
                          Basement?: {
                            __typename?: 'CommercialBasementType';
                            BasementArea?: number | null;
                            BasementAreaUnitOfMeasurement?: string | null;
                            BasementPresent?: boolean | null;
                          } | null;
                          BuildingUpdates?: {
                            __typename?: 'BuildingUpdatesType';
                            BuildingUpdate?: Array<number | null> | null;
                          } | null;
                          CommercialExteriorWallTypes?: {
                            __typename?: 'CommercialExteriorWallTypesType';
                            ExteriorWallType?: Array<string | null> | null;
                          } | null;
                          Elevator?: {
                            __typename?: 'ElevatorType';
                            ElevatorCount?: number | null;
                            ElevatorPresent?: boolean | null;
                          } | null;
                          Heating?: {
                            __typename?: 'CommercialHeatingType';
                            HeatingFuelSources?: {
                              __typename?: 'HeatingFuelSourcesType';
                              HeatingFuelSource?: Array<string | null> | null;
                            } | null;
                            HeatingTypes?: {
                              __typename?: 'HeatingTypesType';
                              HeatingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          Parking?: {
                            __typename?: 'CommercialParkingType';
                            ParkingPresent?: boolean | null;
                            ParkingTypes?: {
                              __typename?: 'CommercialParkingTypesType';
                              ParkingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          PlumbingTypes?: {
                            __typename?: 'CommercialPlumbingType';
                            PlumbingType?: Array<string | null> | null;
                          } | null;
                          RoofAttributes?: {
                            __typename?: 'RoofAttributesType';
                            RoofConstructions?: {
                              __typename?: 'RoofConstructionsType';
                              RoofConstruction?: Array<string | null> | null;
                            } | null;
                            RoofSurfaces?: {
                              __typename?: 'RoofSurfacesType';
                              RoofSurface?: Array<string | null> | null;
                            } | null;
                            RoofTypes?: {
                              __typename?: 'RoofTypesType';
                              RoofType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          WaterHeater?: {
                            __typename?: 'CommercialWaterHeaterType';
                            WaterHeaterFuelType?: Array<string | null> | null;
                            WaterHeaterType?: Array<string | null> | null;
                          } | null;
                          Wiring?: {
                            __typename?: 'CommercialWiringType';
                            WiringPanelTypes?: {
                              __typename?: 'CommercialWiringPanelTypesType';
                              WiringPanelType?: Array<string | null> | null;
                            } | null;
                            WiringTypes?: {
                              __typename?: 'CommercialWiringTypesType';
                              WiringType?: Array<string | null> | null;
                            } | null;
                          } | null;
                        } | null;
                        Protection?: {
                          __typename?: 'ProtectionType';
                          FireAlarmPresent?: boolean | null;
                          FireAlarmType?: string | null;
                          PhysicalSecurityPresent?: boolean | null;
                          SecurityAlarmPresent?: boolean | null;
                          SecurityAlarmType?: string | null;
                          SprinklerPresent?: boolean | null;
                          SprinklerTypeCode?: string | null;
                          PhysicalSecurityTypes?: {
                            __typename?: 'PhysicalSecurityTypesType';
                            PhysicalSecurityType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                    Industry?: {
                      __typename?: 'BusinessIndustryType';
                      IBCCode?: string | null;
                      IBCIndustryDescription?: string | null;
                      SICIndustryDescription?: string | null;
                      SICCode?: string | null;
                    } | null;
                  } | null;
                } | null;
                optaCommercialPerilScoreData?: {
                  __typename?: 'OptaCommercialPerilScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  PropertyPerilScores?: {
                    __typename?: 'PropertyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  CasualtyPerilScores?: {
                    __typename?: 'CasualtyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  AllPerilsScore?: {
                    __typename?: 'CommercialPerilScoreType';
                    LossCostScore?: number | null;
                    PredictedLossCost?: number | null;
                  } | null;
                } | null;
              } | null;
              openingHours: Array<{
                __typename?: 'OpeningHoursType';
                day: number;
                open?: any | null;
                close?: any | null;
              }>;
            } | null;
          }>;
          details?: {
            __typename?: 'AnswerInstanceDetailsType';
            lat?: number | null;
            lng?: number | null;
            images: Array<{
              __typename?: 'GoogleImageType';
              description: string;
              shortDescription: string;
              category: CoreGoogleImageCategoryChoices;
              data?: string | null;
            }>;
            google?: {
              __typename?: 'GoogleDetailsType';
              placeId?: string | null;
              rating?: number | null;
              numberOfRatings?: number | null;
            } | null;
            opta?: {
              __typename?: 'OPTADetailsType';
              optaFusData?: {
                __typename?: 'OptaFusDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                Commercial?: {
                  __typename?: 'FusProductCommercialType';
                  SearchMessage?: string | null;
                  CommercialGrade: Array<{
                    __typename?: 'CommercialGradeType';
                    PublicFireProtectionClassification?: number | null;
                    WaterSystem?: string | null;
                    RiskSCORDetail: Array<{
                      __typename?: 'RiskSCORDetailType';
                      RiskSCOR?: number | null;
                      Type?: string | null;
                      Adjustment: Array<{
                        __typename?: 'RiskSCORAdjustmentType';
                        Adjustment?: string | null;
                        AdjustmentType?: string | null;
                      }>;
                    }>;
                  }>;
                  RespondingFirehall?: {
                    __typename?: 'FirehallType';
                    Address?: string | null;
                    GoverningAgency?: string | null;
                    GradingDate?: any | null;
                    Name?: string | null;
                    PumperFlowImperialGallonsPerMinute?: number | null;
                    RoadDistanceKilometres?: number | null;
                    SuperiorTankerServiceAccreditationDate?: any | null;
                    FirefightersPerFireStation?: {
                      __typename?: 'FirefightersPerFireStationType';
                      FullTime?: number | null;
                      Volunteer?: number | null;
                    } | null;
                    ProtectionGradeTerritories?: {
                      __typename?: 'ProtectedGradeTerritoriesType';
                      Commercial?: string | null;
                      Dwelling?: string | null;
                    } | null;
                  } | null;
                } | null;
                Dwelling?: {
                  __typename?: 'FusProductDwellingType';
                  SearchMessage?: string | null;
                  DwellingGrades?: {
                    __typename?: 'DwellingGradesType';
                    AllOtherAreasGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                    FirehallProtectedGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                    HydrantProtectedGrade: Array<{
                      __typename?: 'DwellingProtectedGradeType';
                      Grade?: string | null;
                      WaterSystem?: string | null;
                    }>;
                  } | null;
                  RespondingFirehall?: {
                    __typename?: 'FirehallType';
                    Address?: string | null;
                    GoverningAgency?: string | null;
                    GradingDate?: any | null;
                    Name?: string | null;
                    PumperFlowImperialGallonsPerMinute?: number | null;
                    RoadDistanceKilometres?: number | null;
                    SuperiorTankerServiceAccreditationDate?: any | null;
                    FirefightersPerFireStation?: {
                      __typename?: 'FirefightersPerFireStationType';
                      FullTime?: number | null;
                      Volunteer?: number | null;
                    } | null;
                    ProtectionGradeTerritories?: {
                      __typename?: 'ProtectedGradeTerritoriesType';
                      Commercial?: string | null;
                      Dwelling?: string | null;
                    } | null;
                  } | null;
                } | null;
                EarthquakeCodes?: {
                  __typename?: 'EarthquakeCodesType';
                  SearchMessage?: string | null;
                  EarthquakeCode: Array<{
                    __typename?: 'EarthquakeCodeType';
                    Description?: string | null;
                    SystemName?: string | null;
                    Zone?: string | null;
                  }>;
                } | null;
                FusMaps?: {
                  __typename?: 'FusMapsType';
                  SearchMessage?: string | null;
                  FusMap: Array<{
                    __typename?: 'FusMapType';
                    Business?: string | null;
                    FusContent?: {
                      __typename?: 'ContentType';
                      ContentURL?: string | null;
                      Expiry?: any | null;
                      MIMEType?: string | null;
                    } | null;
                  }>;
                } | null;
                HydrantCounts?: {
                  __typename?: 'HydrantCountsType';
                  SearchMessage?: string | null;
                  HydrantCount: Array<{
                    __typename?: 'HydrantCountType';
                    NumberOfHydrants: number;
                    Ownership?: string | null;
                    RadiusMetres?: string | null;
                  }>;
                } | null;
              } | null;
              optaFloodscoreData?: {
                __typename?: 'OptaFloodScoreDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                FloodScores?: {
                  __typename?: 'FloodScoresType';
                  FloodScore: Array<{
                    __typename?: 'FloodScoreType';
                    AverageAnnualLossScore?: number | null;
                    Deductible?: number | null;
                    Default?: boolean | null;
                    FloodScore?: number | null;
                    Limit?: number | null;
                    RateFactor?: number | null;
                    Zone?: string | null;
                    ResidentialModifiers?: {
                      __typename?: 'ResidentialConstructionFeaturesType';
                      ArchitecturalStyleType?: string | null;
                      AuxiliaryHeatingType?: string | null;
                      BathroomCount?: string | null;
                      CommercialIndicator?: string | null;
                      ExteriorWallType?: string | null;
                      FinishedBasement?: boolean | null;
                      FoundationType?: string | null;
                      GarageNumberOfCars?: number | null;
                      GarageType?: string | null;
                      KitchenCount?: string | null;
                      OutbuildingPresent?: string | null;
                      PlumbingType?: string | null;
                      PrimaryHeatingType?: string | null;
                      RoofType?: string | null;
                      SquareFootage?: number | null;
                      StoreyCount?: string | null;
                      SwimmingPoolType?: string | null;
                      YearBuilt?: string | null;
                      InspectionDetails?: {
                        __typename?: 'InspectionDetailsType';
                        InspectionDate?: any | null;
                        SponsoredInspection?: boolean | null;
                      } | null;
                    } | null;
                    MultiResidentialModifiers?: {
                      __typename?: 'MultiResidentialConstructionFeaturesType';
                      BasementPresent?: boolean | null;
                      ConstructionType?: string | null;
                      FloorLevel?: number | null;
                    } | null;
                    AverageAnnualLossValues?: {
                      __typename?: 'FloodValueType';
                      Building?: number | null;
                      Contents?: number | null;
                      Total?: number | null;
                    } | null;
                    AdditionalDetails: Array<{
                      __typename?: 'FloodDetailType';
                      Key?: string | null;
                      Value?: string | null;
                    }>;
                    Premium?: {
                      __typename?: 'FloodValueType';
                      Building?: number | null;
                      Contents?: number | null;
                      Total?: number | null;
                    } | null;
                  }>;
                  FloodMap: Array<{
                    __typename?: 'FloodMapType';
                    FloodEvent?: string | null;
                    Content?: {
                      __typename?: 'ContentType';
                      ContentURL?: string | null;
                      Expiry?: any | null;
                      MIMEType?: string | null;
                    } | null;
                  }>;
                } | null;
              } | null;
              optaIclarifyCommercialConstructionData?: {
                __typename?: 'OptaIclarifyCommercialConstructionDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                Messages?: {
                  __typename?: 'Messages';
                  Message?: Array<string | null> | null;
                } | null;
                Property?: {
                  __typename?: 'CommercialPropertyType';
                  LotSize?: number | null;
                  LotSizeUnitOfMeasurement?: string | null;
                  PropertyType?: string | null;
                  Buildings?: {
                    __typename?: 'BuildingsType';
                    Building: Array<{
                      __typename?: 'BuildingType';
                      StructureID?: string | null;
                      ConstructionFeatures?: {
                        __typename?: 'CommercialConstructionFeaturesType';
                        CombustibilityClass?: string | null;
                        ConstructionClass?: string | null;
                        FloorHeight?: number | null;
                        FloorHeightUnitOfMeasurement?: string | null;
                        LoadingDockPresent?: boolean | null;
                        NumberOfStoreys?: number | null;
                        NumberOfUnits?: number | null;
                        TotalFloorArea?: number | null;
                        TotalFloorAreaUnitOfMeasurement?: string | null;
                        YearBuilt?: number | null;
                        Basement?: {
                          __typename?: 'CommercialBasementType';
                          BasementArea?: number | null;
                          BasementAreaUnitOfMeasurement?: string | null;
                          BasementPresent?: boolean | null;
                        } | null;
                        BuildingUpdates?: {
                          __typename?: 'BuildingUpdatesType';
                          BuildingUpdate?: Array<number | null> | null;
                        } | null;
                        CommercialExteriorWallTypes?: {
                          __typename?: 'CommercialExteriorWallTypesType';
                          ExteriorWallType?: Array<string | null> | null;
                        } | null;
                        Elevator?: {
                          __typename?: 'ElevatorType';
                          ElevatorCount?: number | null;
                          ElevatorPresent?: boolean | null;
                        } | null;
                        Heating?: {
                          __typename?: 'CommercialHeatingType';
                          HeatingFuelSources?: {
                            __typename?: 'HeatingFuelSourcesType';
                            HeatingFuelSource?: Array<string | null> | null;
                          } | null;
                          HeatingTypes?: {
                            __typename?: 'HeatingTypesType';
                            HeatingType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        Parking?: {
                          __typename?: 'CommercialParkingType';
                          ParkingPresent?: boolean | null;
                          ParkingTypes?: {
                            __typename?: 'CommercialParkingTypesType';
                            ParkingType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        PlumbingTypes?: {
                          __typename?: 'CommercialPlumbingType';
                          PlumbingType?: Array<string | null> | null;
                        } | null;
                        RoofAttributes?: {
                          __typename?: 'RoofAttributesType';
                          RoofConstructions?: {
                            __typename?: 'RoofConstructionsType';
                            RoofConstruction?: Array<string | null> | null;
                          } | null;
                          RoofSurfaces?: {
                            __typename?: 'RoofSurfacesType';
                            RoofSurface?: Array<string | null> | null;
                          } | null;
                          RoofTypes?: {
                            __typename?: 'RoofTypesType';
                            RoofType?: Array<string | null> | null;
                          } | null;
                        } | null;
                        WaterHeater?: {
                          __typename?: 'CommercialWaterHeaterType';
                          WaterHeaterFuelType?: Array<string | null> | null;
                          WaterHeaterType?: Array<string | null> | null;
                        } | null;
                        Wiring?: {
                          __typename?: 'CommercialWiringType';
                          WiringPanelTypes?: {
                            __typename?: 'CommercialWiringPanelTypesType';
                            WiringPanelType?: Array<string | null> | null;
                          } | null;
                          WiringTypes?: {
                            __typename?: 'CommercialWiringTypesType';
                            WiringType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      } | null;
                      Protection?: {
                        __typename?: 'ProtectionType';
                        FireAlarmPresent?: boolean | null;
                        FireAlarmType?: string | null;
                        PhysicalSecurityPresent?: boolean | null;
                        SecurityAlarmPresent?: boolean | null;
                        SecurityAlarmType?: string | null;
                        SprinklerPresent?: boolean | null;
                        SprinklerTypeCode?: string | null;
                        PhysicalSecurityTypes?: {
                          __typename?: 'PhysicalSecurityTypesType';
                          PhysicalSecurityType?: Array<string | null> | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                  Industry?: {
                    __typename?: 'BusinessIndustryType';
                    IBCCode?: string | null;
                    IBCIndustryDescription?: string | null;
                    SICIndustryDescription?: string | null;
                    SICCode?: string | null;
                  } | null;
                } | null;
              } | null;
              optaCommercialPerilScoreData?: {
                __typename?: 'OptaCommercialPerilScoreDataType';
                Address?: {
                  __typename?: 'OptaAddressType';
                  StreetAddress?: string | null;
                  StreetNumber?: string | null;
                  StreetName?: string | null;
                  StreetType?: string | null;
                  StreetDirection?: string | null;
                  UnitNumber?: string | null;
                  Municipality?: string | null;
                  Province?: string | null;
                  PostalCode?: string | null;
                  LegalDescription?: {
                    __typename?: 'LegalDescriptionType';
                    Quarter?: string | null;
                    LegalSubdivision?: string | null;
                    Section?: string | null;
                    Township?: string | null;
                    Range?: string | null;
                    Meridian?: string | null;
                  } | null;
                } | null;
                PropertyPerilScores?: {
                  __typename?: 'PropertyCommercialPerilsType';
                  Peril?: Array<{
                    __typename?: 'PerilDetailsType';
                    LossCostScore?: number | null;
                    PerilType?: string | null;
                    PredictedLossCost?: number | null;
                  } | null> | null;
                } | null;
                CasualtyPerilScores?: {
                  __typename?: 'CasualtyCommercialPerilsType';
                  Peril?: Array<{
                    __typename?: 'PerilDetailsType';
                    LossCostScore?: number | null;
                    PerilType?: string | null;
                    PredictedLossCost?: number | null;
                  } | null> | null;
                } | null;
                AllPerilsScore?: {
                  __typename?: 'CommercialPerilScoreType';
                  LossCostScore?: number | null;
                  PredictedLossCost?: number | null;
                } | null;
              } | null;
            } | null;
            openingHours: Array<{
              __typename?: 'OpeningHoursType';
              day: number;
              open?: any | null;
              close?: any | null;
            }>;
          } | null;
        }>;
        details?: {
          __typename?: 'AnswerInstanceDetailsType';
          lat?: number | null;
          lng?: number | null;
          images: Array<{
            __typename?: 'GoogleImageType';
            description: string;
            shortDescription: string;
            category: CoreGoogleImageCategoryChoices;
            data?: string | null;
          }>;
          google?: {
            __typename?: 'GoogleDetailsType';
            placeId?: string | null;
            rating?: number | null;
            numberOfRatings?: number | null;
          } | null;
          opta?: {
            __typename?: 'OPTADetailsType';
            optaFusData?: {
              __typename?: 'OptaFusDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              Commercial?: {
                __typename?: 'FusProductCommercialType';
                SearchMessage?: string | null;
                CommercialGrade: Array<{
                  __typename?: 'CommercialGradeType';
                  PublicFireProtectionClassification?: number | null;
                  WaterSystem?: string | null;
                  RiskSCORDetail: Array<{
                    __typename?: 'RiskSCORDetailType';
                    RiskSCOR?: number | null;
                    Type?: string | null;
                    Adjustment: Array<{
                      __typename?: 'RiskSCORAdjustmentType';
                      Adjustment?: string | null;
                      AdjustmentType?: string | null;
                    }>;
                  }>;
                }>;
                RespondingFirehall?: {
                  __typename?: 'FirehallType';
                  Address?: string | null;
                  GoverningAgency?: string | null;
                  GradingDate?: any | null;
                  Name?: string | null;
                  PumperFlowImperialGallonsPerMinute?: number | null;
                  RoadDistanceKilometres?: number | null;
                  SuperiorTankerServiceAccreditationDate?: any | null;
                  FirefightersPerFireStation?: {
                    __typename?: 'FirefightersPerFireStationType';
                    FullTime?: number | null;
                    Volunteer?: number | null;
                  } | null;
                  ProtectionGradeTerritories?: {
                    __typename?: 'ProtectedGradeTerritoriesType';
                    Commercial?: string | null;
                    Dwelling?: string | null;
                  } | null;
                } | null;
              } | null;
              Dwelling?: {
                __typename?: 'FusProductDwellingType';
                SearchMessage?: string | null;
                DwellingGrades?: {
                  __typename?: 'DwellingGradesType';
                  AllOtherAreasGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                  FirehallProtectedGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                  HydrantProtectedGrade: Array<{
                    __typename?: 'DwellingProtectedGradeType';
                    Grade?: string | null;
                    WaterSystem?: string | null;
                  }>;
                } | null;
                RespondingFirehall?: {
                  __typename?: 'FirehallType';
                  Address?: string | null;
                  GoverningAgency?: string | null;
                  GradingDate?: any | null;
                  Name?: string | null;
                  PumperFlowImperialGallonsPerMinute?: number | null;
                  RoadDistanceKilometres?: number | null;
                  SuperiorTankerServiceAccreditationDate?: any | null;
                  FirefightersPerFireStation?: {
                    __typename?: 'FirefightersPerFireStationType';
                    FullTime?: number | null;
                    Volunteer?: number | null;
                  } | null;
                  ProtectionGradeTerritories?: {
                    __typename?: 'ProtectedGradeTerritoriesType';
                    Commercial?: string | null;
                    Dwelling?: string | null;
                  } | null;
                } | null;
              } | null;
              EarthquakeCodes?: {
                __typename?: 'EarthquakeCodesType';
                SearchMessage?: string | null;
                EarthquakeCode: Array<{
                  __typename?: 'EarthquakeCodeType';
                  Description?: string | null;
                  SystemName?: string | null;
                  Zone?: string | null;
                }>;
              } | null;
              FusMaps?: {
                __typename?: 'FusMapsType';
                SearchMessage?: string | null;
                FusMap: Array<{
                  __typename?: 'FusMapType';
                  Business?: string | null;
                  FusContent?: {
                    __typename?: 'ContentType';
                    ContentURL?: string | null;
                    Expiry?: any | null;
                    MIMEType?: string | null;
                  } | null;
                }>;
              } | null;
              HydrantCounts?: {
                __typename?: 'HydrantCountsType';
                SearchMessage?: string | null;
                HydrantCount: Array<{
                  __typename?: 'HydrantCountType';
                  NumberOfHydrants: number;
                  Ownership?: string | null;
                  RadiusMetres?: string | null;
                }>;
              } | null;
            } | null;
            optaFloodscoreData?: {
              __typename?: 'OptaFloodScoreDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              FloodScores?: {
                __typename?: 'FloodScoresType';
                FloodScore: Array<{
                  __typename?: 'FloodScoreType';
                  AverageAnnualLossScore?: number | null;
                  Deductible?: number | null;
                  Default?: boolean | null;
                  FloodScore?: number | null;
                  Limit?: number | null;
                  RateFactor?: number | null;
                  Zone?: string | null;
                  ResidentialModifiers?: {
                    __typename?: 'ResidentialConstructionFeaturesType';
                    ArchitecturalStyleType?: string | null;
                    AuxiliaryHeatingType?: string | null;
                    BathroomCount?: string | null;
                    CommercialIndicator?: string | null;
                    ExteriorWallType?: string | null;
                    FinishedBasement?: boolean | null;
                    FoundationType?: string | null;
                    GarageNumberOfCars?: number | null;
                    GarageType?: string | null;
                    KitchenCount?: string | null;
                    OutbuildingPresent?: string | null;
                    PlumbingType?: string | null;
                    PrimaryHeatingType?: string | null;
                    RoofType?: string | null;
                    SquareFootage?: number | null;
                    StoreyCount?: string | null;
                    SwimmingPoolType?: string | null;
                    YearBuilt?: string | null;
                    InspectionDetails?: {
                      __typename?: 'InspectionDetailsType';
                      InspectionDate?: any | null;
                      SponsoredInspection?: boolean | null;
                    } | null;
                  } | null;
                  MultiResidentialModifiers?: {
                    __typename?: 'MultiResidentialConstructionFeaturesType';
                    BasementPresent?: boolean | null;
                    ConstructionType?: string | null;
                    FloorLevel?: number | null;
                  } | null;
                  AverageAnnualLossValues?: {
                    __typename?: 'FloodValueType';
                    Building?: number | null;
                    Contents?: number | null;
                    Total?: number | null;
                  } | null;
                  AdditionalDetails: Array<{
                    __typename?: 'FloodDetailType';
                    Key?: string | null;
                    Value?: string | null;
                  }>;
                  Premium?: {
                    __typename?: 'FloodValueType';
                    Building?: number | null;
                    Contents?: number | null;
                    Total?: number | null;
                  } | null;
                }>;
                FloodMap: Array<{
                  __typename?: 'FloodMapType';
                  FloodEvent?: string | null;
                  Content?: {
                    __typename?: 'ContentType';
                    ContentURL?: string | null;
                    Expiry?: any | null;
                    MIMEType?: string | null;
                  } | null;
                }>;
              } | null;
            } | null;
            optaIclarifyCommercialConstructionData?: {
              __typename?: 'OptaIclarifyCommercialConstructionDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              Messages?: {
                __typename?: 'Messages';
                Message?: Array<string | null> | null;
              } | null;
              Property?: {
                __typename?: 'CommercialPropertyType';
                LotSize?: number | null;
                LotSizeUnitOfMeasurement?: string | null;
                PropertyType?: string | null;
                Buildings?: {
                  __typename?: 'BuildingsType';
                  Building: Array<{
                    __typename?: 'BuildingType';
                    StructureID?: string | null;
                    ConstructionFeatures?: {
                      __typename?: 'CommercialConstructionFeaturesType';
                      CombustibilityClass?: string | null;
                      ConstructionClass?: string | null;
                      FloorHeight?: number | null;
                      FloorHeightUnitOfMeasurement?: string | null;
                      LoadingDockPresent?: boolean | null;
                      NumberOfStoreys?: number | null;
                      NumberOfUnits?: number | null;
                      TotalFloorArea?: number | null;
                      TotalFloorAreaUnitOfMeasurement?: string | null;
                      YearBuilt?: number | null;
                      Basement?: {
                        __typename?: 'CommercialBasementType';
                        BasementArea?: number | null;
                        BasementAreaUnitOfMeasurement?: string | null;
                        BasementPresent?: boolean | null;
                      } | null;
                      BuildingUpdates?: {
                        __typename?: 'BuildingUpdatesType';
                        BuildingUpdate?: Array<number | null> | null;
                      } | null;
                      CommercialExteriorWallTypes?: {
                        __typename?: 'CommercialExteriorWallTypesType';
                        ExteriorWallType?: Array<string | null> | null;
                      } | null;
                      Elevator?: {
                        __typename?: 'ElevatorType';
                        ElevatorCount?: number | null;
                        ElevatorPresent?: boolean | null;
                      } | null;
                      Heating?: {
                        __typename?: 'CommercialHeatingType';
                        HeatingFuelSources?: {
                          __typename?: 'HeatingFuelSourcesType';
                          HeatingFuelSource?: Array<string | null> | null;
                        } | null;
                        HeatingTypes?: {
                          __typename?: 'HeatingTypesType';
                          HeatingType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      Parking?: {
                        __typename?: 'CommercialParkingType';
                        ParkingPresent?: boolean | null;
                        ParkingTypes?: {
                          __typename?: 'CommercialParkingTypesType';
                          ParkingType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      PlumbingTypes?: {
                        __typename?: 'CommercialPlumbingType';
                        PlumbingType?: Array<string | null> | null;
                      } | null;
                      RoofAttributes?: {
                        __typename?: 'RoofAttributesType';
                        RoofConstructions?: {
                          __typename?: 'RoofConstructionsType';
                          RoofConstruction?: Array<string | null> | null;
                        } | null;
                        RoofSurfaces?: {
                          __typename?: 'RoofSurfacesType';
                          RoofSurface?: Array<string | null> | null;
                        } | null;
                        RoofTypes?: {
                          __typename?: 'RoofTypesType';
                          RoofType?: Array<string | null> | null;
                        } | null;
                      } | null;
                      WaterHeater?: {
                        __typename?: 'CommercialWaterHeaterType';
                        WaterHeaterFuelType?: Array<string | null> | null;
                        WaterHeaterType?: Array<string | null> | null;
                      } | null;
                      Wiring?: {
                        __typename?: 'CommercialWiringType';
                        WiringPanelTypes?: {
                          __typename?: 'CommercialWiringPanelTypesType';
                          WiringPanelType?: Array<string | null> | null;
                        } | null;
                        WiringTypes?: {
                          __typename?: 'CommercialWiringTypesType';
                          WiringType?: Array<string | null> | null;
                        } | null;
                      } | null;
                    } | null;
                    Protection?: {
                      __typename?: 'ProtectionType';
                      FireAlarmPresent?: boolean | null;
                      FireAlarmType?: string | null;
                      PhysicalSecurityPresent?: boolean | null;
                      SecurityAlarmPresent?: boolean | null;
                      SecurityAlarmType?: string | null;
                      SprinklerPresent?: boolean | null;
                      SprinklerTypeCode?: string | null;
                      PhysicalSecurityTypes?: {
                        __typename?: 'PhysicalSecurityTypesType';
                        PhysicalSecurityType?: Array<string | null> | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
                Industry?: {
                  __typename?: 'BusinessIndustryType';
                  IBCCode?: string | null;
                  IBCIndustryDescription?: string | null;
                  SICIndustryDescription?: string | null;
                  SICCode?: string | null;
                } | null;
              } | null;
            } | null;
            optaCommercialPerilScoreData?: {
              __typename?: 'OptaCommercialPerilScoreDataType';
              Address?: {
                __typename?: 'OptaAddressType';
                StreetAddress?: string | null;
                StreetNumber?: string | null;
                StreetName?: string | null;
                StreetType?: string | null;
                StreetDirection?: string | null;
                UnitNumber?: string | null;
                Municipality?: string | null;
                Province?: string | null;
                PostalCode?: string | null;
                LegalDescription?: {
                  __typename?: 'LegalDescriptionType';
                  Quarter?: string | null;
                  LegalSubdivision?: string | null;
                  Section?: string | null;
                  Township?: string | null;
                  Range?: string | null;
                  Meridian?: string | null;
                } | null;
              } | null;
              PropertyPerilScores?: {
                __typename?: 'PropertyCommercialPerilsType';
                Peril?: Array<{
                  __typename?: 'PerilDetailsType';
                  LossCostScore?: number | null;
                  PerilType?: string | null;
                  PredictedLossCost?: number | null;
                } | null> | null;
              } | null;
              CasualtyPerilScores?: {
                __typename?: 'CasualtyCommercialPerilsType';
                Peril?: Array<{
                  __typename?: 'PerilDetailsType';
                  LossCostScore?: number | null;
                  PerilType?: string | null;
                  PredictedLossCost?: number | null;
                } | null> | null;
              } | null;
              AllPerilsScore?: {
                __typename?: 'CommercialPerilScoreType';
                LossCostScore?: number | null;
                PredictedLossCost?: number | null;
              } | null;
            } | null;
          } | null;
          openingHours: Array<{
            __typename?: 'OpeningHoursType';
            day: number;
            open?: any | null;
            close?: any | null;
          }>;
        } | null;
      }>;
    }>;
    signature?: {
      __typename?: 'SignatureType';
      signatureData?: string | null;
      signatureTime?: any | null;
    } | null;
    bindings: Array<{
      __typename?: 'BindingType';
      label: string;
      bound: boolean;
      unboundReason: string;
    }>;
  } | null;
};

export type QuoteVersionsQueryVariables = Exact<{
  token: Scalars['String'];
  uniqueID: Scalars['String'];
}>;

export type QuoteVersionsQuery = {
  __typename?: 'Query';
  quoteVersions?: Array<{
    __typename?: 'QuoteVersionData';
    quoteId?: string | null;
    dateAdded?: string | null;
  } | null> | null;
};

export type QuoteFromHashQueryVariables = Exact<{
  hash: Scalars['String'];
  delete?: InputMaybe<Scalars['Boolean']>;
}>;

export type QuoteFromHashQuery = {
  __typename?: 'Query';
  quoteFromHash?:
    | {
        __typename?: 'ArchivedQuoteType';
        id: string;
        uniqueID: any;
        friendlyId?: string | null;
        additionalInformation: string;
        producerId: string;
        ArchivedQuoteStatus: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
        businessLine?: {
          __typename?: 'BusinessTypeType';
          id: string;
          displayName: string;
        } | null;
        structuredData?: {
          __typename?: 'StructuredQuoteDataType';
          businessLegalName?: string | null;
          businessName?: string | null;
          businessTradeName?: string | null;
          contactName?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          revenue?: number | null;
          alreadyContactedBrokerName?: string | null;
          contactAddress?: {
            __typename?: 'AddressType';
            formattedAddress?: string | null;
          } | null;
        } | null;
        completedForms: Array<{
          __typename?: 'CompletedFormType';
          id: string;
          policy?: {
            __typename?: 'PolicyType';
            id: string;
            displayName: string;
            internalName: string;
          } | null;
          extensions?: Array<{
            __typename?: 'PolicyExtensionType';
            id: string;
            label: string;
            multiplier?: string | null;
            questionId?: string | null;
            value?: string | null;
          } | null> | null;
          answers: Array<{
            __typename?: 'AnswerInstanceType';
            id: string;
            apiName: string;
            displayName: string;
            label: string;
            helpText?: string | null;
            component: string;
            propsBlob?: any | null;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            value: string;
            date: any;
            method?: string | null;
            dataType: string;
            defaultValue?: string | null;
            subAnswers: Array<{
              __typename?: 'AnswerInstanceType';
              id: string;
              apiName: string;
              displayName: string;
              label: string;
              helpText?: string | null;
              component: string;
              propsBlob?: any | null;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              value: string;
              date: any;
              method?: string | null;
              dataType: string;
              defaultValue?: string | null;
              subAnswers: Array<{
                __typename?: 'AnswerInstanceType';
                id: string;
                apiName: string;
                displayName: string;
                label: string;
                helpText?: string | null;
                component: string;
                propsBlob?: any | null;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                value: string;
                date: any;
                method?: string | null;
                dataType: string;
                defaultValue?: string | null;
                subAnswers: Array<{
                  __typename?: 'AnswerInstanceType';
                  id: string;
                  apiName: string;
                  displayName: string;
                  label: string;
                  helpText?: string | null;
                  component: string;
                  propsBlob?: any | null;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  value: string;
                  date: any;
                  method?: string | null;
                  dataType: string;
                  defaultValue?: string | null;
                  subAnswers: Array<{
                    __typename?: 'AnswerInstanceType';
                    id: string;
                    apiName: string;
                    displayName: string;
                    label: string;
                    helpText?: string | null;
                    component: string;
                    propsBlob?: any | null;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    value: string;
                    date: any;
                    method?: string | null;
                    dataType: string;
                    defaultValue?: string | null;
                    subAnswers: Array<{
                      __typename?: 'AnswerInstanceType';
                      id: string;
                      apiName: string;
                      displayName: string;
                      label: string;
                      helpText?: string | null;
                      component: string;
                      propsBlob?: any | null;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      value: string;
                      date: any;
                      method?: string | null;
                      dataType: string;
                      defaultValue?: string | null;
                      subAnswers: Array<{
                        __typename?: 'AnswerInstanceType';
                        id: string;
                        apiName: string;
                        displayName: string;
                        label: string;
                        helpText?: string | null;
                        component: string;
                        propsBlob?: any | null;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        value: string;
                        date: any;
                        method?: string | null;
                        dataType: string;
                        defaultValue?: string | null;
                        subAnswers: Array<{
                          __typename?: 'AnswerInstanceType';
                          id: string;
                          apiName: string;
                          displayName: string;
                          label: string;
                          helpText?: string | null;
                          component: string;
                          propsBlob?: any | null;
                          required: boolean;
                          askOnRenewal: boolean;
                          prefillOnRenewal: boolean;
                          value: string;
                          date: any;
                          method?: string | null;
                          dataType: string;
                          defaultValue?: string | null;
                          subAnswers: Array<{
                            __typename?: 'AnswerInstanceType';
                            id: string;
                            apiName: string;
                            displayName: string;
                            label: string;
                            helpText?: string | null;
                            component: string;
                            propsBlob?: any | null;
                            required: boolean;
                            askOnRenewal: boolean;
                            prefillOnRenewal: boolean;
                            value: string;
                            date: any;
                            method?: string | null;
                            dataType: string;
                            defaultValue?: string | null;
                            subAnswers: Array<{
                              __typename?: 'AnswerInstanceType';
                              id: string;
                              apiName: string;
                              displayName: string;
                              label: string;
                              helpText?: string | null;
                              component: string;
                              propsBlob?: any | null;
                              required: boolean;
                              askOnRenewal: boolean;
                              prefillOnRenewal: boolean;
                              value: string;
                              date: any;
                              method?: string | null;
                              dataType: string;
                              defaultValue?: string | null;
                              details?: {
                                __typename?: 'AnswerInstanceDetailsType';
                                lat?: number | null;
                                lng?: number | null;
                                images: Array<{
                                  __typename?: 'GoogleImageType';
                                  description: string;
                                  shortDescription: string;
                                  category: CoreGoogleImageCategoryChoices;
                                  data?: string | null;
                                }>;
                                google?: {
                                  __typename?: 'GoogleDetailsType';
                                  placeId?: string | null;
                                  rating?: number | null;
                                  numberOfRatings?: number | null;
                                } | null;
                                opta?: {
                                  __typename?: 'OPTADetailsType';
                                  optaFusData?: {
                                    __typename?: 'OptaFusDataType';
                                    Address?: {
                                      __typename?: 'OptaAddressType';
                                      StreetAddress?: string | null;
                                      StreetNumber?: string | null;
                                      StreetName?: string | null;
                                      StreetType?: string | null;
                                      StreetDirection?: string | null;
                                      UnitNumber?: string | null;
                                      Municipality?: string | null;
                                      Province?: string | null;
                                      PostalCode?: string | null;
                                      LegalDescription?: {
                                        __typename?: 'LegalDescriptionType';
                                        Quarter?: string | null;
                                        LegalSubdivision?: string | null;
                                        Section?: string | null;
                                        Township?: string | null;
                                        Range?: string | null;
                                        Meridian?: string | null;
                                      } | null;
                                    } | null;
                                    Commercial?: {
                                      __typename?: 'FusProductCommercialType';
                                      SearchMessage?: string | null;
                                      CommercialGrade: Array<{
                                        __typename?: 'CommercialGradeType';
                                        PublicFireProtectionClassification?:
                                          | number
                                          | null;
                                        WaterSystem?: string | null;
                                        RiskSCORDetail: Array<{
                                          __typename?: 'RiskSCORDetailType';
                                          RiskSCOR?: number | null;
                                          Type?: string | null;
                                          Adjustment: Array<{
                                            __typename?: 'RiskSCORAdjustmentType';
                                            Adjustment?: string | null;
                                            AdjustmentType?: string | null;
                                          }>;
                                        }>;
                                      }>;
                                      RespondingFirehall?: {
                                        __typename?: 'FirehallType';
                                        Address?: string | null;
                                        GoverningAgency?: string | null;
                                        GradingDate?: any | null;
                                        Name?: string | null;
                                        PumperFlowImperialGallonsPerMinute?:
                                          | number
                                          | null;
                                        RoadDistanceKilometres?: number | null;
                                        SuperiorTankerServiceAccreditationDate?:
                                          | any
                                          | null;
                                        FirefightersPerFireStation?: {
                                          __typename?: 'FirefightersPerFireStationType';
                                          FullTime?: number | null;
                                          Volunteer?: number | null;
                                        } | null;
                                        ProtectionGradeTerritories?: {
                                          __typename?: 'ProtectedGradeTerritoriesType';
                                          Commercial?: string | null;
                                          Dwelling?: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    Dwelling?: {
                                      __typename?: 'FusProductDwellingType';
                                      SearchMessage?: string | null;
                                      DwellingGrades?: {
                                        __typename?: 'DwellingGradesType';
                                        AllOtherAreasGrade: Array<{
                                          __typename?: 'DwellingProtectedGradeType';
                                          Grade?: string | null;
                                          WaterSystem?: string | null;
                                        }>;
                                        FirehallProtectedGrade: Array<{
                                          __typename?: 'DwellingProtectedGradeType';
                                          Grade?: string | null;
                                          WaterSystem?: string | null;
                                        }>;
                                        HydrantProtectedGrade: Array<{
                                          __typename?: 'DwellingProtectedGradeType';
                                          Grade?: string | null;
                                          WaterSystem?: string | null;
                                        }>;
                                      } | null;
                                      RespondingFirehall?: {
                                        __typename?: 'FirehallType';
                                        Address?: string | null;
                                        GoverningAgency?: string | null;
                                        GradingDate?: any | null;
                                        Name?: string | null;
                                        PumperFlowImperialGallonsPerMinute?:
                                          | number
                                          | null;
                                        RoadDistanceKilometres?: number | null;
                                        SuperiorTankerServiceAccreditationDate?:
                                          | any
                                          | null;
                                        FirefightersPerFireStation?: {
                                          __typename?: 'FirefightersPerFireStationType';
                                          FullTime?: number | null;
                                          Volunteer?: number | null;
                                        } | null;
                                        ProtectionGradeTerritories?: {
                                          __typename?: 'ProtectedGradeTerritoriesType';
                                          Commercial?: string | null;
                                          Dwelling?: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    EarthquakeCodes?: {
                                      __typename?: 'EarthquakeCodesType';
                                      SearchMessage?: string | null;
                                      EarthquakeCode: Array<{
                                        __typename?: 'EarthquakeCodeType';
                                        Description?: string | null;
                                        SystemName?: string | null;
                                        Zone?: string | null;
                                      }>;
                                    } | null;
                                    FusMaps?: {
                                      __typename?: 'FusMapsType';
                                      SearchMessage?: string | null;
                                      FusMap: Array<{
                                        __typename?: 'FusMapType';
                                        Business?: string | null;
                                        FusContent?: {
                                          __typename?: 'ContentType';
                                          ContentURL?: string | null;
                                          Expiry?: any | null;
                                          MIMEType?: string | null;
                                        } | null;
                                      }>;
                                    } | null;
                                    HydrantCounts?: {
                                      __typename?: 'HydrantCountsType';
                                      SearchMessage?: string | null;
                                      HydrantCount: Array<{
                                        __typename?: 'HydrantCountType';
                                        NumberOfHydrants: number;
                                        Ownership?: string | null;
                                        RadiusMetres?: string | null;
                                      }>;
                                    } | null;
                                  } | null;
                                  optaFloodscoreData?: {
                                    __typename?: 'OptaFloodScoreDataType';
                                    Address?: {
                                      __typename?: 'OptaAddressType';
                                      StreetAddress?: string | null;
                                      StreetNumber?: string | null;
                                      StreetName?: string | null;
                                      StreetType?: string | null;
                                      StreetDirection?: string | null;
                                      UnitNumber?: string | null;
                                      Municipality?: string | null;
                                      Province?: string | null;
                                      PostalCode?: string | null;
                                      LegalDescription?: {
                                        __typename?: 'LegalDescriptionType';
                                        Quarter?: string | null;
                                        LegalSubdivision?: string | null;
                                        Section?: string | null;
                                        Township?: string | null;
                                        Range?: string | null;
                                        Meridian?: string | null;
                                      } | null;
                                    } | null;
                                    FloodScores?: {
                                      __typename?: 'FloodScoresType';
                                      FloodScore: Array<{
                                        __typename?: 'FloodScoreType';
                                        AverageAnnualLossScore?: number | null;
                                        Deductible?: number | null;
                                        Default?: boolean | null;
                                        FloodScore?: number | null;
                                        Limit?: number | null;
                                        RateFactor?: number | null;
                                        Zone?: string | null;
                                        ResidentialModifiers?: {
                                          __typename?: 'ResidentialConstructionFeaturesType';
                                          ArchitecturalStyleType?:
                                            | string
                                            | null;
                                          AuxiliaryHeatingType?: string | null;
                                          BathroomCount?: string | null;
                                          CommercialIndicator?: string | null;
                                          ExteriorWallType?: string | null;
                                          FinishedBasement?: boolean | null;
                                          FoundationType?: string | null;
                                          GarageNumberOfCars?: number | null;
                                          GarageType?: string | null;
                                          KitchenCount?: string | null;
                                          OutbuildingPresent?: string | null;
                                          PlumbingType?: string | null;
                                          PrimaryHeatingType?: string | null;
                                          RoofType?: string | null;
                                          SquareFootage?: number | null;
                                          StoreyCount?: string | null;
                                          SwimmingPoolType?: string | null;
                                          YearBuilt?: string | null;
                                          InspectionDetails?: {
                                            __typename?: 'InspectionDetailsType';
                                            InspectionDate?: any | null;
                                            SponsoredInspection?:
                                              | boolean
                                              | null;
                                          } | null;
                                        } | null;
                                        MultiResidentialModifiers?: {
                                          __typename?: 'MultiResidentialConstructionFeaturesType';
                                          BasementPresent?: boolean | null;
                                          ConstructionType?: string | null;
                                          FloorLevel?: number | null;
                                        } | null;
                                        AverageAnnualLossValues?: {
                                          __typename?: 'FloodValueType';
                                          Building?: number | null;
                                          Contents?: number | null;
                                          Total?: number | null;
                                        } | null;
                                        AdditionalDetails: Array<{
                                          __typename?: 'FloodDetailType';
                                          Key?: string | null;
                                          Value?: string | null;
                                        }>;
                                        Premium?: {
                                          __typename?: 'FloodValueType';
                                          Building?: number | null;
                                          Contents?: number | null;
                                          Total?: number | null;
                                        } | null;
                                      }>;
                                      FloodMap: Array<{
                                        __typename?: 'FloodMapType';
                                        FloodEvent?: string | null;
                                        Content?: {
                                          __typename?: 'ContentType';
                                          ContentURL?: string | null;
                                          Expiry?: any | null;
                                          MIMEType?: string | null;
                                        } | null;
                                      }>;
                                    } | null;
                                  } | null;
                                  optaIclarifyCommercialConstructionData?: {
                                    __typename?: 'OptaIclarifyCommercialConstructionDataType';
                                    Address?: {
                                      __typename?: 'OptaAddressType';
                                      StreetAddress?: string | null;
                                      StreetNumber?: string | null;
                                      StreetName?: string | null;
                                      StreetType?: string | null;
                                      StreetDirection?: string | null;
                                      UnitNumber?: string | null;
                                      Municipality?: string | null;
                                      Province?: string | null;
                                      PostalCode?: string | null;
                                      LegalDescription?: {
                                        __typename?: 'LegalDescriptionType';
                                        Quarter?: string | null;
                                        LegalSubdivision?: string | null;
                                        Section?: string | null;
                                        Township?: string | null;
                                        Range?: string | null;
                                        Meridian?: string | null;
                                      } | null;
                                    } | null;
                                    Messages?: {
                                      __typename?: 'Messages';
                                      Message?: Array<string | null> | null;
                                    } | null;
                                    Property?: {
                                      __typename?: 'CommercialPropertyType';
                                      LotSize?: number | null;
                                      LotSizeUnitOfMeasurement?: string | null;
                                      PropertyType?: string | null;
                                      Buildings?: {
                                        __typename?: 'BuildingsType';
                                        Building: Array<{
                                          __typename?: 'BuildingType';
                                          StructureID?: string | null;
                                          ConstructionFeatures?: {
                                            __typename?: 'CommercialConstructionFeaturesType';
                                            CombustibilityClass?: string | null;
                                            ConstructionClass?: string | null;
                                            FloorHeight?: number | null;
                                            FloorHeightUnitOfMeasurement?:
                                              | string
                                              | null;
                                            LoadingDockPresent?: boolean | null;
                                            NumberOfStoreys?: number | null;
                                            NumberOfUnits?: number | null;
                                            TotalFloorArea?: number | null;
                                            TotalFloorAreaUnitOfMeasurement?:
                                              | string
                                              | null;
                                            YearBuilt?: number | null;
                                            Basement?: {
                                              __typename?: 'CommercialBasementType';
                                              BasementArea?: number | null;
                                              BasementAreaUnitOfMeasurement?:
                                                | string
                                                | null;
                                              BasementPresent?: boolean | null;
                                            } | null;
                                            BuildingUpdates?: {
                                              __typename?: 'BuildingUpdatesType';
                                              BuildingUpdate?: Array<
                                                number | null
                                              > | null;
                                            } | null;
                                            CommercialExteriorWallTypes?: {
                                              __typename?: 'CommercialExteriorWallTypesType';
                                              ExteriorWallType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            Elevator?: {
                                              __typename?: 'ElevatorType';
                                              ElevatorCount?: number | null;
                                              ElevatorPresent?: boolean | null;
                                            } | null;
                                            Heating?: {
                                              __typename?: 'CommercialHeatingType';
                                              HeatingFuelSources?: {
                                                __typename?: 'HeatingFuelSourcesType';
                                                HeatingFuelSource?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                              HeatingTypes?: {
                                                __typename?: 'HeatingTypesType';
                                                HeatingType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                            } | null;
                                            Parking?: {
                                              __typename?: 'CommercialParkingType';
                                              ParkingPresent?: boolean | null;
                                              ParkingTypes?: {
                                                __typename?: 'CommercialParkingTypesType';
                                                ParkingType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                            } | null;
                                            PlumbingTypes?: {
                                              __typename?: 'CommercialPlumbingType';
                                              PlumbingType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            RoofAttributes?: {
                                              __typename?: 'RoofAttributesType';
                                              RoofConstructions?: {
                                                __typename?: 'RoofConstructionsType';
                                                RoofConstruction?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                              RoofSurfaces?: {
                                                __typename?: 'RoofSurfacesType';
                                                RoofSurface?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                              RoofTypes?: {
                                                __typename?: 'RoofTypesType';
                                                RoofType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                            } | null;
                                            WaterHeater?: {
                                              __typename?: 'CommercialWaterHeaterType';
                                              WaterHeaterFuelType?: Array<
                                                string | null
                                              > | null;
                                              WaterHeaterType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            Wiring?: {
                                              __typename?: 'CommercialWiringType';
                                              WiringPanelTypes?: {
                                                __typename?: 'CommercialWiringPanelTypesType';
                                                WiringPanelType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                              WiringTypes?: {
                                                __typename?: 'CommercialWiringTypesType';
                                                WiringType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                            } | null;
                                          } | null;
                                          Protection?: {
                                            __typename?: 'ProtectionType';
                                            FireAlarmPresent?: boolean | null;
                                            FireAlarmType?: string | null;
                                            PhysicalSecurityPresent?:
                                              | boolean
                                              | null;
                                            SecurityAlarmPresent?:
                                              | boolean
                                              | null;
                                            SecurityAlarmType?: string | null;
                                            SprinklerPresent?: boolean | null;
                                            SprinklerTypeCode?: string | null;
                                            PhysicalSecurityTypes?: {
                                              __typename?: 'PhysicalSecurityTypesType';
                                              PhysicalSecurityType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                        }>;
                                      } | null;
                                      Industry?: {
                                        __typename?: 'BusinessIndustryType';
                                        IBCCode?: string | null;
                                        IBCIndustryDescription?: string | null;
                                        SICIndustryDescription?: string | null;
                                        SICCode?: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  optaCommercialPerilScoreData?: {
                                    __typename?: 'OptaCommercialPerilScoreDataType';
                                    Address?: {
                                      __typename?: 'OptaAddressType';
                                      StreetAddress?: string | null;
                                      StreetNumber?: string | null;
                                      StreetName?: string | null;
                                      StreetType?: string | null;
                                      StreetDirection?: string | null;
                                      UnitNumber?: string | null;
                                      Municipality?: string | null;
                                      Province?: string | null;
                                      PostalCode?: string | null;
                                      LegalDescription?: {
                                        __typename?: 'LegalDescriptionType';
                                        Quarter?: string | null;
                                        LegalSubdivision?: string | null;
                                        Section?: string | null;
                                        Township?: string | null;
                                        Range?: string | null;
                                        Meridian?: string | null;
                                      } | null;
                                    } | null;
                                    PropertyPerilScores?: {
                                      __typename?: 'PropertyCommercialPerilsType';
                                      Peril?: Array<{
                                        __typename?: 'PerilDetailsType';
                                        LossCostScore?: number | null;
                                        PerilType?: string | null;
                                        PredictedLossCost?: number | null;
                                      } | null> | null;
                                    } | null;
                                    CasualtyPerilScores?: {
                                      __typename?: 'CasualtyCommercialPerilsType';
                                      Peril?: Array<{
                                        __typename?: 'PerilDetailsType';
                                        LossCostScore?: number | null;
                                        PerilType?: string | null;
                                        PredictedLossCost?: number | null;
                                      } | null> | null;
                                    } | null;
                                    AllPerilsScore?: {
                                      __typename?: 'CommercialPerilScoreType';
                                      LossCostScore?: number | null;
                                      PredictedLossCost?: number | null;
                                    } | null;
                                  } | null;
                                } | null;
                                openingHours: Array<{
                                  __typename?: 'OpeningHoursType';
                                  day: number;
                                  open?: any | null;
                                  close?: any | null;
                                }>;
                              } | null;
                            }>;
                            details?: {
                              __typename?: 'AnswerInstanceDetailsType';
                              lat?: number | null;
                              lng?: number | null;
                              images: Array<{
                                __typename?: 'GoogleImageType';
                                description: string;
                                shortDescription: string;
                                category: CoreGoogleImageCategoryChoices;
                                data?: string | null;
                              }>;
                              google?: {
                                __typename?: 'GoogleDetailsType';
                                placeId?: string | null;
                                rating?: number | null;
                                numberOfRatings?: number | null;
                              } | null;
                              opta?: {
                                __typename?: 'OPTADetailsType';
                                optaFusData?: {
                                  __typename?: 'OptaFusDataType';
                                  Address?: {
                                    __typename?: 'OptaAddressType';
                                    StreetAddress?: string | null;
                                    StreetNumber?: string | null;
                                    StreetName?: string | null;
                                    StreetType?: string | null;
                                    StreetDirection?: string | null;
                                    UnitNumber?: string | null;
                                    Municipality?: string | null;
                                    Province?: string | null;
                                    PostalCode?: string | null;
                                    LegalDescription?: {
                                      __typename?: 'LegalDescriptionType';
                                      Quarter?: string | null;
                                      LegalSubdivision?: string | null;
                                      Section?: string | null;
                                      Township?: string | null;
                                      Range?: string | null;
                                      Meridian?: string | null;
                                    } | null;
                                  } | null;
                                  Commercial?: {
                                    __typename?: 'FusProductCommercialType';
                                    SearchMessage?: string | null;
                                    CommercialGrade: Array<{
                                      __typename?: 'CommercialGradeType';
                                      PublicFireProtectionClassification?:
                                        | number
                                        | null;
                                      WaterSystem?: string | null;
                                      RiskSCORDetail: Array<{
                                        __typename?: 'RiskSCORDetailType';
                                        RiskSCOR?: number | null;
                                        Type?: string | null;
                                        Adjustment: Array<{
                                          __typename?: 'RiskSCORAdjustmentType';
                                          Adjustment?: string | null;
                                          AdjustmentType?: string | null;
                                        }>;
                                      }>;
                                    }>;
                                    RespondingFirehall?: {
                                      __typename?: 'FirehallType';
                                      Address?: string | null;
                                      GoverningAgency?: string | null;
                                      GradingDate?: any | null;
                                      Name?: string | null;
                                      PumperFlowImperialGallonsPerMinute?:
                                        | number
                                        | null;
                                      RoadDistanceKilometres?: number | null;
                                      SuperiorTankerServiceAccreditationDate?:
                                        | any
                                        | null;
                                      FirefightersPerFireStation?: {
                                        __typename?: 'FirefightersPerFireStationType';
                                        FullTime?: number | null;
                                        Volunteer?: number | null;
                                      } | null;
                                      ProtectionGradeTerritories?: {
                                        __typename?: 'ProtectedGradeTerritoriesType';
                                        Commercial?: string | null;
                                        Dwelling?: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  Dwelling?: {
                                    __typename?: 'FusProductDwellingType';
                                    SearchMessage?: string | null;
                                    DwellingGrades?: {
                                      __typename?: 'DwellingGradesType';
                                      AllOtherAreasGrade: Array<{
                                        __typename?: 'DwellingProtectedGradeType';
                                        Grade?: string | null;
                                        WaterSystem?: string | null;
                                      }>;
                                      FirehallProtectedGrade: Array<{
                                        __typename?: 'DwellingProtectedGradeType';
                                        Grade?: string | null;
                                        WaterSystem?: string | null;
                                      }>;
                                      HydrantProtectedGrade: Array<{
                                        __typename?: 'DwellingProtectedGradeType';
                                        Grade?: string | null;
                                        WaterSystem?: string | null;
                                      }>;
                                    } | null;
                                    RespondingFirehall?: {
                                      __typename?: 'FirehallType';
                                      Address?: string | null;
                                      GoverningAgency?: string | null;
                                      GradingDate?: any | null;
                                      Name?: string | null;
                                      PumperFlowImperialGallonsPerMinute?:
                                        | number
                                        | null;
                                      RoadDistanceKilometres?: number | null;
                                      SuperiorTankerServiceAccreditationDate?:
                                        | any
                                        | null;
                                      FirefightersPerFireStation?: {
                                        __typename?: 'FirefightersPerFireStationType';
                                        FullTime?: number | null;
                                        Volunteer?: number | null;
                                      } | null;
                                      ProtectionGradeTerritories?: {
                                        __typename?: 'ProtectedGradeTerritoriesType';
                                        Commercial?: string | null;
                                        Dwelling?: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  EarthquakeCodes?: {
                                    __typename?: 'EarthquakeCodesType';
                                    SearchMessage?: string | null;
                                    EarthquakeCode: Array<{
                                      __typename?: 'EarthquakeCodeType';
                                      Description?: string | null;
                                      SystemName?: string | null;
                                      Zone?: string | null;
                                    }>;
                                  } | null;
                                  FusMaps?: {
                                    __typename?: 'FusMapsType';
                                    SearchMessage?: string | null;
                                    FusMap: Array<{
                                      __typename?: 'FusMapType';
                                      Business?: string | null;
                                      FusContent?: {
                                        __typename?: 'ContentType';
                                        ContentURL?: string | null;
                                        Expiry?: any | null;
                                        MIMEType?: string | null;
                                      } | null;
                                    }>;
                                  } | null;
                                  HydrantCounts?: {
                                    __typename?: 'HydrantCountsType';
                                    SearchMessage?: string | null;
                                    HydrantCount: Array<{
                                      __typename?: 'HydrantCountType';
                                      NumberOfHydrants: number;
                                      Ownership?: string | null;
                                      RadiusMetres?: string | null;
                                    }>;
                                  } | null;
                                } | null;
                                optaFloodscoreData?: {
                                  __typename?: 'OptaFloodScoreDataType';
                                  Address?: {
                                    __typename?: 'OptaAddressType';
                                    StreetAddress?: string | null;
                                    StreetNumber?: string | null;
                                    StreetName?: string | null;
                                    StreetType?: string | null;
                                    StreetDirection?: string | null;
                                    UnitNumber?: string | null;
                                    Municipality?: string | null;
                                    Province?: string | null;
                                    PostalCode?: string | null;
                                    LegalDescription?: {
                                      __typename?: 'LegalDescriptionType';
                                      Quarter?: string | null;
                                      LegalSubdivision?: string | null;
                                      Section?: string | null;
                                      Township?: string | null;
                                      Range?: string | null;
                                      Meridian?: string | null;
                                    } | null;
                                  } | null;
                                  FloodScores?: {
                                    __typename?: 'FloodScoresType';
                                    FloodScore: Array<{
                                      __typename?: 'FloodScoreType';
                                      AverageAnnualLossScore?: number | null;
                                      Deductible?: number | null;
                                      Default?: boolean | null;
                                      FloodScore?: number | null;
                                      Limit?: number | null;
                                      RateFactor?: number | null;
                                      Zone?: string | null;
                                      ResidentialModifiers?: {
                                        __typename?: 'ResidentialConstructionFeaturesType';
                                        ArchitecturalStyleType?: string | null;
                                        AuxiliaryHeatingType?: string | null;
                                        BathroomCount?: string | null;
                                        CommercialIndicator?: string | null;
                                        ExteriorWallType?: string | null;
                                        FinishedBasement?: boolean | null;
                                        FoundationType?: string | null;
                                        GarageNumberOfCars?: number | null;
                                        GarageType?: string | null;
                                        KitchenCount?: string | null;
                                        OutbuildingPresent?: string | null;
                                        PlumbingType?: string | null;
                                        PrimaryHeatingType?: string | null;
                                        RoofType?: string | null;
                                        SquareFootage?: number | null;
                                        StoreyCount?: string | null;
                                        SwimmingPoolType?: string | null;
                                        YearBuilt?: string | null;
                                        InspectionDetails?: {
                                          __typename?: 'InspectionDetailsType';
                                          InspectionDate?: any | null;
                                          SponsoredInspection?: boolean | null;
                                        } | null;
                                      } | null;
                                      MultiResidentialModifiers?: {
                                        __typename?: 'MultiResidentialConstructionFeaturesType';
                                        BasementPresent?: boolean | null;
                                        ConstructionType?: string | null;
                                        FloorLevel?: number | null;
                                      } | null;
                                      AverageAnnualLossValues?: {
                                        __typename?: 'FloodValueType';
                                        Building?: number | null;
                                        Contents?: number | null;
                                        Total?: number | null;
                                      } | null;
                                      AdditionalDetails: Array<{
                                        __typename?: 'FloodDetailType';
                                        Key?: string | null;
                                        Value?: string | null;
                                      }>;
                                      Premium?: {
                                        __typename?: 'FloodValueType';
                                        Building?: number | null;
                                        Contents?: number | null;
                                        Total?: number | null;
                                      } | null;
                                    }>;
                                    FloodMap: Array<{
                                      __typename?: 'FloodMapType';
                                      FloodEvent?: string | null;
                                      Content?: {
                                        __typename?: 'ContentType';
                                        ContentURL?: string | null;
                                        Expiry?: any | null;
                                        MIMEType?: string | null;
                                      } | null;
                                    }>;
                                  } | null;
                                } | null;
                                optaIclarifyCommercialConstructionData?: {
                                  __typename?: 'OptaIclarifyCommercialConstructionDataType';
                                  Address?: {
                                    __typename?: 'OptaAddressType';
                                    StreetAddress?: string | null;
                                    StreetNumber?: string | null;
                                    StreetName?: string | null;
                                    StreetType?: string | null;
                                    StreetDirection?: string | null;
                                    UnitNumber?: string | null;
                                    Municipality?: string | null;
                                    Province?: string | null;
                                    PostalCode?: string | null;
                                    LegalDescription?: {
                                      __typename?: 'LegalDescriptionType';
                                      Quarter?: string | null;
                                      LegalSubdivision?: string | null;
                                      Section?: string | null;
                                      Township?: string | null;
                                      Range?: string | null;
                                      Meridian?: string | null;
                                    } | null;
                                  } | null;
                                  Messages?: {
                                    __typename?: 'Messages';
                                    Message?: Array<string | null> | null;
                                  } | null;
                                  Property?: {
                                    __typename?: 'CommercialPropertyType';
                                    LotSize?: number | null;
                                    LotSizeUnitOfMeasurement?: string | null;
                                    PropertyType?: string | null;
                                    Buildings?: {
                                      __typename?: 'BuildingsType';
                                      Building: Array<{
                                        __typename?: 'BuildingType';
                                        StructureID?: string | null;
                                        ConstructionFeatures?: {
                                          __typename?: 'CommercialConstructionFeaturesType';
                                          CombustibilityClass?: string | null;
                                          ConstructionClass?: string | null;
                                          FloorHeight?: number | null;
                                          FloorHeightUnitOfMeasurement?:
                                            | string
                                            | null;
                                          LoadingDockPresent?: boolean | null;
                                          NumberOfStoreys?: number | null;
                                          NumberOfUnits?: number | null;
                                          TotalFloorArea?: number | null;
                                          TotalFloorAreaUnitOfMeasurement?:
                                            | string
                                            | null;
                                          YearBuilt?: number | null;
                                          Basement?: {
                                            __typename?: 'CommercialBasementType';
                                            BasementArea?: number | null;
                                            BasementAreaUnitOfMeasurement?:
                                              | string
                                              | null;
                                            BasementPresent?: boolean | null;
                                          } | null;
                                          BuildingUpdates?: {
                                            __typename?: 'BuildingUpdatesType';
                                            BuildingUpdate?: Array<
                                              number | null
                                            > | null;
                                          } | null;
                                          CommercialExteriorWallTypes?: {
                                            __typename?: 'CommercialExteriorWallTypesType';
                                            ExteriorWallType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          Elevator?: {
                                            __typename?: 'ElevatorType';
                                            ElevatorCount?: number | null;
                                            ElevatorPresent?: boolean | null;
                                          } | null;
                                          Heating?: {
                                            __typename?: 'CommercialHeatingType';
                                            HeatingFuelSources?: {
                                              __typename?: 'HeatingFuelSourcesType';
                                              HeatingFuelSource?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            HeatingTypes?: {
                                              __typename?: 'HeatingTypesType';
                                              HeatingType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                          Parking?: {
                                            __typename?: 'CommercialParkingType';
                                            ParkingPresent?: boolean | null;
                                            ParkingTypes?: {
                                              __typename?: 'CommercialParkingTypesType';
                                              ParkingType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                          PlumbingTypes?: {
                                            __typename?: 'CommercialPlumbingType';
                                            PlumbingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofAttributes?: {
                                            __typename?: 'RoofAttributesType';
                                            RoofConstructions?: {
                                              __typename?: 'RoofConstructionsType';
                                              RoofConstruction?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            RoofSurfaces?: {
                                              __typename?: 'RoofSurfacesType';
                                              RoofSurface?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            RoofTypes?: {
                                              __typename?: 'RoofTypesType';
                                              RoofType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                          WaterHeater?: {
                                            __typename?: 'CommercialWaterHeaterType';
                                            WaterHeaterFuelType?: Array<
                                              string | null
                                            > | null;
                                            WaterHeaterType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          Wiring?: {
                                            __typename?: 'CommercialWiringType';
                                            WiringPanelTypes?: {
                                              __typename?: 'CommercialWiringPanelTypesType';
                                              WiringPanelType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            WiringTypes?: {
                                              __typename?: 'CommercialWiringTypesType';
                                              WiringType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                        } | null;
                                        Protection?: {
                                          __typename?: 'ProtectionType';
                                          FireAlarmPresent?: boolean | null;
                                          FireAlarmType?: string | null;
                                          PhysicalSecurityPresent?:
                                            | boolean
                                            | null;
                                          SecurityAlarmPresent?: boolean | null;
                                          SecurityAlarmType?: string | null;
                                          SprinklerPresent?: boolean | null;
                                          SprinklerTypeCode?: string | null;
                                          PhysicalSecurityTypes?: {
                                            __typename?: 'PhysicalSecurityTypesType';
                                            PhysicalSecurityType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                      }>;
                                    } | null;
                                    Industry?: {
                                      __typename?: 'BusinessIndustryType';
                                      IBCCode?: string | null;
                                      IBCIndustryDescription?: string | null;
                                      SICIndustryDescription?: string | null;
                                      SICCode?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                optaCommercialPerilScoreData?: {
                                  __typename?: 'OptaCommercialPerilScoreDataType';
                                  Address?: {
                                    __typename?: 'OptaAddressType';
                                    StreetAddress?: string | null;
                                    StreetNumber?: string | null;
                                    StreetName?: string | null;
                                    StreetType?: string | null;
                                    StreetDirection?: string | null;
                                    UnitNumber?: string | null;
                                    Municipality?: string | null;
                                    Province?: string | null;
                                    PostalCode?: string | null;
                                    LegalDescription?: {
                                      __typename?: 'LegalDescriptionType';
                                      Quarter?: string | null;
                                      LegalSubdivision?: string | null;
                                      Section?: string | null;
                                      Township?: string | null;
                                      Range?: string | null;
                                      Meridian?: string | null;
                                    } | null;
                                  } | null;
                                  PropertyPerilScores?: {
                                    __typename?: 'PropertyCommercialPerilsType';
                                    Peril?: Array<{
                                      __typename?: 'PerilDetailsType';
                                      LossCostScore?: number | null;
                                      PerilType?: string | null;
                                      PredictedLossCost?: number | null;
                                    } | null> | null;
                                  } | null;
                                  CasualtyPerilScores?: {
                                    __typename?: 'CasualtyCommercialPerilsType';
                                    Peril?: Array<{
                                      __typename?: 'PerilDetailsType';
                                      LossCostScore?: number | null;
                                      PerilType?: string | null;
                                      PredictedLossCost?: number | null;
                                    } | null> | null;
                                  } | null;
                                  AllPerilsScore?: {
                                    __typename?: 'CommercialPerilScoreType';
                                    LossCostScore?: number | null;
                                    PredictedLossCost?: number | null;
                                  } | null;
                                } | null;
                              } | null;
                              openingHours: Array<{
                                __typename?: 'OpeningHoursType';
                                day: number;
                                open?: any | null;
                                close?: any | null;
                              }>;
                            } | null;
                          }>;
                          details?: {
                            __typename?: 'AnswerInstanceDetailsType';
                            lat?: number | null;
                            lng?: number | null;
                            images: Array<{
                              __typename?: 'GoogleImageType';
                              description: string;
                              shortDescription: string;
                              category: CoreGoogleImageCategoryChoices;
                              data?: string | null;
                            }>;
                            google?: {
                              __typename?: 'GoogleDetailsType';
                              placeId?: string | null;
                              rating?: number | null;
                              numberOfRatings?: number | null;
                            } | null;
                            opta?: {
                              __typename?: 'OPTADetailsType';
                              optaFusData?: {
                                __typename?: 'OptaFusDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                Commercial?: {
                                  __typename?: 'FusProductCommercialType';
                                  SearchMessage?: string | null;
                                  CommercialGrade: Array<{
                                    __typename?: 'CommercialGradeType';
                                    PublicFireProtectionClassification?:
                                      | number
                                      | null;
                                    WaterSystem?: string | null;
                                    RiskSCORDetail: Array<{
                                      __typename?: 'RiskSCORDetailType';
                                      RiskSCOR?: number | null;
                                      Type?: string | null;
                                      Adjustment: Array<{
                                        __typename?: 'RiskSCORAdjustmentType';
                                        Adjustment?: string | null;
                                        AdjustmentType?: string | null;
                                      }>;
                                    }>;
                                  }>;
                                  RespondingFirehall?: {
                                    __typename?: 'FirehallType';
                                    Address?: string | null;
                                    GoverningAgency?: string | null;
                                    GradingDate?: any | null;
                                    Name?: string | null;
                                    PumperFlowImperialGallonsPerMinute?:
                                      | number
                                      | null;
                                    RoadDistanceKilometres?: number | null;
                                    SuperiorTankerServiceAccreditationDate?:
                                      | any
                                      | null;
                                    FirefightersPerFireStation?: {
                                      __typename?: 'FirefightersPerFireStationType';
                                      FullTime?: number | null;
                                      Volunteer?: number | null;
                                    } | null;
                                    ProtectionGradeTerritories?: {
                                      __typename?: 'ProtectedGradeTerritoriesType';
                                      Commercial?: string | null;
                                      Dwelling?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Dwelling?: {
                                  __typename?: 'FusProductDwellingType';
                                  SearchMessage?: string | null;
                                  DwellingGrades?: {
                                    __typename?: 'DwellingGradesType';
                                    AllOtherAreasGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                    FirehallProtectedGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                    HydrantProtectedGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                  } | null;
                                  RespondingFirehall?: {
                                    __typename?: 'FirehallType';
                                    Address?: string | null;
                                    GoverningAgency?: string | null;
                                    GradingDate?: any | null;
                                    Name?: string | null;
                                    PumperFlowImperialGallonsPerMinute?:
                                      | number
                                      | null;
                                    RoadDistanceKilometres?: number | null;
                                    SuperiorTankerServiceAccreditationDate?:
                                      | any
                                      | null;
                                    FirefightersPerFireStation?: {
                                      __typename?: 'FirefightersPerFireStationType';
                                      FullTime?: number | null;
                                      Volunteer?: number | null;
                                    } | null;
                                    ProtectionGradeTerritories?: {
                                      __typename?: 'ProtectedGradeTerritoriesType';
                                      Commercial?: string | null;
                                      Dwelling?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                EarthquakeCodes?: {
                                  __typename?: 'EarthquakeCodesType';
                                  SearchMessage?: string | null;
                                  EarthquakeCode: Array<{
                                    __typename?: 'EarthquakeCodeType';
                                    Description?: string | null;
                                    SystemName?: string | null;
                                    Zone?: string | null;
                                  }>;
                                } | null;
                                FusMaps?: {
                                  __typename?: 'FusMapsType';
                                  SearchMessage?: string | null;
                                  FusMap: Array<{
                                    __typename?: 'FusMapType';
                                    Business?: string | null;
                                    FusContent?: {
                                      __typename?: 'ContentType';
                                      ContentURL?: string | null;
                                      Expiry?: any | null;
                                      MIMEType?: string | null;
                                    } | null;
                                  }>;
                                } | null;
                                HydrantCounts?: {
                                  __typename?: 'HydrantCountsType';
                                  SearchMessage?: string | null;
                                  HydrantCount: Array<{
                                    __typename?: 'HydrantCountType';
                                    NumberOfHydrants: number;
                                    Ownership?: string | null;
                                    RadiusMetres?: string | null;
                                  }>;
                                } | null;
                              } | null;
                              optaFloodscoreData?: {
                                __typename?: 'OptaFloodScoreDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                FloodScores?: {
                                  __typename?: 'FloodScoresType';
                                  FloodScore: Array<{
                                    __typename?: 'FloodScoreType';
                                    AverageAnnualLossScore?: number | null;
                                    Deductible?: number | null;
                                    Default?: boolean | null;
                                    FloodScore?: number | null;
                                    Limit?: number | null;
                                    RateFactor?: number | null;
                                    Zone?: string | null;
                                    ResidentialModifiers?: {
                                      __typename?: 'ResidentialConstructionFeaturesType';
                                      ArchitecturalStyleType?: string | null;
                                      AuxiliaryHeatingType?: string | null;
                                      BathroomCount?: string | null;
                                      CommercialIndicator?: string | null;
                                      ExteriorWallType?: string | null;
                                      FinishedBasement?: boolean | null;
                                      FoundationType?: string | null;
                                      GarageNumberOfCars?: number | null;
                                      GarageType?: string | null;
                                      KitchenCount?: string | null;
                                      OutbuildingPresent?: string | null;
                                      PlumbingType?: string | null;
                                      PrimaryHeatingType?: string | null;
                                      RoofType?: string | null;
                                      SquareFootage?: number | null;
                                      StoreyCount?: string | null;
                                      SwimmingPoolType?: string | null;
                                      YearBuilt?: string | null;
                                      InspectionDetails?: {
                                        __typename?: 'InspectionDetailsType';
                                        InspectionDate?: any | null;
                                        SponsoredInspection?: boolean | null;
                                      } | null;
                                    } | null;
                                    MultiResidentialModifiers?: {
                                      __typename?: 'MultiResidentialConstructionFeaturesType';
                                      BasementPresent?: boolean | null;
                                      ConstructionType?: string | null;
                                      FloorLevel?: number | null;
                                    } | null;
                                    AverageAnnualLossValues?: {
                                      __typename?: 'FloodValueType';
                                      Building?: number | null;
                                      Contents?: number | null;
                                      Total?: number | null;
                                    } | null;
                                    AdditionalDetails: Array<{
                                      __typename?: 'FloodDetailType';
                                      Key?: string | null;
                                      Value?: string | null;
                                    }>;
                                    Premium?: {
                                      __typename?: 'FloodValueType';
                                      Building?: number | null;
                                      Contents?: number | null;
                                      Total?: number | null;
                                    } | null;
                                  }>;
                                  FloodMap: Array<{
                                    __typename?: 'FloodMapType';
                                    FloodEvent?: string | null;
                                    Content?: {
                                      __typename?: 'ContentType';
                                      ContentURL?: string | null;
                                      Expiry?: any | null;
                                      MIMEType?: string | null;
                                    } | null;
                                  }>;
                                } | null;
                              } | null;
                              optaIclarifyCommercialConstructionData?: {
                                __typename?: 'OptaIclarifyCommercialConstructionDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                Messages?: {
                                  __typename?: 'Messages';
                                  Message?: Array<string | null> | null;
                                } | null;
                                Property?: {
                                  __typename?: 'CommercialPropertyType';
                                  LotSize?: number | null;
                                  LotSizeUnitOfMeasurement?: string | null;
                                  PropertyType?: string | null;
                                  Buildings?: {
                                    __typename?: 'BuildingsType';
                                    Building: Array<{
                                      __typename?: 'BuildingType';
                                      StructureID?: string | null;
                                      ConstructionFeatures?: {
                                        __typename?: 'CommercialConstructionFeaturesType';
                                        CombustibilityClass?: string | null;
                                        ConstructionClass?: string | null;
                                        FloorHeight?: number | null;
                                        FloorHeightUnitOfMeasurement?:
                                          | string
                                          | null;
                                        LoadingDockPresent?: boolean | null;
                                        NumberOfStoreys?: number | null;
                                        NumberOfUnits?: number | null;
                                        TotalFloorArea?: number | null;
                                        TotalFloorAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        YearBuilt?: number | null;
                                        Basement?: {
                                          __typename?: 'CommercialBasementType';
                                          BasementArea?: number | null;
                                          BasementAreaUnitOfMeasurement?:
                                            | string
                                            | null;
                                          BasementPresent?: boolean | null;
                                        } | null;
                                        BuildingUpdates?: {
                                          __typename?: 'BuildingUpdatesType';
                                          BuildingUpdate?: Array<
                                            number | null
                                          > | null;
                                        } | null;
                                        CommercialExteriorWallTypes?: {
                                          __typename?: 'CommercialExteriorWallTypesType';
                                          ExteriorWallType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        Elevator?: {
                                          __typename?: 'ElevatorType';
                                          ElevatorCount?: number | null;
                                          ElevatorPresent?: boolean | null;
                                        } | null;
                                        Heating?: {
                                          __typename?: 'CommercialHeatingType';
                                          HeatingFuelSources?: {
                                            __typename?: 'HeatingFuelSourcesType';
                                            HeatingFuelSource?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          HeatingTypes?: {
                                            __typename?: 'HeatingTypesType';
                                            HeatingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        Parking?: {
                                          __typename?: 'CommercialParkingType';
                                          ParkingPresent?: boolean | null;
                                          ParkingTypes?: {
                                            __typename?: 'CommercialParkingTypesType';
                                            ParkingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        PlumbingTypes?: {
                                          __typename?: 'CommercialPlumbingType';
                                          PlumbingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofAttributes?: {
                                          __typename?: 'RoofAttributesType';
                                          RoofConstructions?: {
                                            __typename?: 'RoofConstructionsType';
                                            RoofConstruction?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofSurfaces?: {
                                            __typename?: 'RoofSurfacesType';
                                            RoofSurface?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofTypes?: {
                                            __typename?: 'RoofTypesType';
                                            RoofType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        WaterHeater?: {
                                          __typename?: 'CommercialWaterHeaterType';
                                          WaterHeaterFuelType?: Array<
                                            string | null
                                          > | null;
                                          WaterHeaterType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        Wiring?: {
                                          __typename?: 'CommercialWiringType';
                                          WiringPanelTypes?: {
                                            __typename?: 'CommercialWiringPanelTypesType';
                                            WiringPanelType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          WiringTypes?: {
                                            __typename?: 'CommercialWiringTypesType';
                                            WiringType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      Protection?: {
                                        __typename?: 'ProtectionType';
                                        FireAlarmPresent?: boolean | null;
                                        FireAlarmType?: string | null;
                                        PhysicalSecurityPresent?:
                                          | boolean
                                          | null;
                                        SecurityAlarmPresent?: boolean | null;
                                        SecurityAlarmType?: string | null;
                                        SprinklerPresent?: boolean | null;
                                        SprinklerTypeCode?: string | null;
                                        PhysicalSecurityTypes?: {
                                          __typename?: 'PhysicalSecurityTypesType';
                                          PhysicalSecurityType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    }>;
                                  } | null;
                                  Industry?: {
                                    __typename?: 'BusinessIndustryType';
                                    IBCCode?: string | null;
                                    IBCIndustryDescription?: string | null;
                                    SICIndustryDescription?: string | null;
                                    SICCode?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              optaCommercialPerilScoreData?: {
                                __typename?: 'OptaCommercialPerilScoreDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                PropertyPerilScores?: {
                                  __typename?: 'PropertyCommercialPerilsType';
                                  Peril?: Array<{
                                    __typename?: 'PerilDetailsType';
                                    LossCostScore?: number | null;
                                    PerilType?: string | null;
                                    PredictedLossCost?: number | null;
                                  } | null> | null;
                                } | null;
                                CasualtyPerilScores?: {
                                  __typename?: 'CasualtyCommercialPerilsType';
                                  Peril?: Array<{
                                    __typename?: 'PerilDetailsType';
                                    LossCostScore?: number | null;
                                    PerilType?: string | null;
                                    PredictedLossCost?: number | null;
                                  } | null> | null;
                                } | null;
                                AllPerilsScore?: {
                                  __typename?: 'CommercialPerilScoreType';
                                  LossCostScore?: number | null;
                                  PredictedLossCost?: number | null;
                                } | null;
                              } | null;
                            } | null;
                            openingHours: Array<{
                              __typename?: 'OpeningHoursType';
                              day: number;
                              open?: any | null;
                              close?: any | null;
                            }>;
                          } | null;
                        }>;
                        details?: {
                          __typename?: 'AnswerInstanceDetailsType';
                          lat?: number | null;
                          lng?: number | null;
                          images: Array<{
                            __typename?: 'GoogleImageType';
                            description: string;
                            shortDescription: string;
                            category: CoreGoogleImageCategoryChoices;
                            data?: string | null;
                          }>;
                          google?: {
                            __typename?: 'GoogleDetailsType';
                            placeId?: string | null;
                            rating?: number | null;
                            numberOfRatings?: number | null;
                          } | null;
                          opta?: {
                            __typename?: 'OPTADetailsType';
                            optaFusData?: {
                              __typename?: 'OptaFusDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Commercial?: {
                                __typename?: 'FusProductCommercialType';
                                SearchMessage?: string | null;
                                CommercialGrade: Array<{
                                  __typename?: 'CommercialGradeType';
                                  PublicFireProtectionClassification?:
                                    | number
                                    | null;
                                  WaterSystem?: string | null;
                                  RiskSCORDetail: Array<{
                                    __typename?: 'RiskSCORDetailType';
                                    RiskSCOR?: number | null;
                                    Type?: string | null;
                                    Adjustment: Array<{
                                      __typename?: 'RiskSCORAdjustmentType';
                                      Adjustment?: string | null;
                                      AdjustmentType?: string | null;
                                    }>;
                                  }>;
                                }>;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              Dwelling?: {
                                __typename?: 'FusProductDwellingType';
                                SearchMessage?: string | null;
                                DwellingGrades?: {
                                  __typename?: 'DwellingGradesType';
                                  AllOtherAreasGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  FirehallProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  HydrantProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                } | null;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              EarthquakeCodes?: {
                                __typename?: 'EarthquakeCodesType';
                                SearchMessage?: string | null;
                                EarthquakeCode: Array<{
                                  __typename?: 'EarthquakeCodeType';
                                  Description?: string | null;
                                  SystemName?: string | null;
                                  Zone?: string | null;
                                }>;
                              } | null;
                              FusMaps?: {
                                __typename?: 'FusMapsType';
                                SearchMessage?: string | null;
                                FusMap: Array<{
                                  __typename?: 'FusMapType';
                                  Business?: string | null;
                                  FusContent?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                              HydrantCounts?: {
                                __typename?: 'HydrantCountsType';
                                SearchMessage?: string | null;
                                HydrantCount: Array<{
                                  __typename?: 'HydrantCountType';
                                  NumberOfHydrants: number;
                                  Ownership?: string | null;
                                  RadiusMetres?: string | null;
                                }>;
                              } | null;
                            } | null;
                            optaFloodscoreData?: {
                              __typename?: 'OptaFloodScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              FloodScores?: {
                                __typename?: 'FloodScoresType';
                                FloodScore: Array<{
                                  __typename?: 'FloodScoreType';
                                  AverageAnnualLossScore?: number | null;
                                  Deductible?: number | null;
                                  Default?: boolean | null;
                                  FloodScore?: number | null;
                                  Limit?: number | null;
                                  RateFactor?: number | null;
                                  Zone?: string | null;
                                  ResidentialModifiers?: {
                                    __typename?: 'ResidentialConstructionFeaturesType';
                                    ArchitecturalStyleType?: string | null;
                                    AuxiliaryHeatingType?: string | null;
                                    BathroomCount?: string | null;
                                    CommercialIndicator?: string | null;
                                    ExteriorWallType?: string | null;
                                    FinishedBasement?: boolean | null;
                                    FoundationType?: string | null;
                                    GarageNumberOfCars?: number | null;
                                    GarageType?: string | null;
                                    KitchenCount?: string | null;
                                    OutbuildingPresent?: string | null;
                                    PlumbingType?: string | null;
                                    PrimaryHeatingType?: string | null;
                                    RoofType?: string | null;
                                    SquareFootage?: number | null;
                                    StoreyCount?: string | null;
                                    SwimmingPoolType?: string | null;
                                    YearBuilt?: string | null;
                                    InspectionDetails?: {
                                      __typename?: 'InspectionDetailsType';
                                      InspectionDate?: any | null;
                                      SponsoredInspection?: boolean | null;
                                    } | null;
                                  } | null;
                                  MultiResidentialModifiers?: {
                                    __typename?: 'MultiResidentialConstructionFeaturesType';
                                    BasementPresent?: boolean | null;
                                    ConstructionType?: string | null;
                                    FloorLevel?: number | null;
                                  } | null;
                                  AverageAnnualLossValues?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                  AdditionalDetails: Array<{
                                    __typename?: 'FloodDetailType';
                                    Key?: string | null;
                                    Value?: string | null;
                                  }>;
                                  Premium?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                }>;
                                FloodMap: Array<{
                                  __typename?: 'FloodMapType';
                                  FloodEvent?: string | null;
                                  Content?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            optaIclarifyCommercialConstructionData?: {
                              __typename?: 'OptaIclarifyCommercialConstructionDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Messages?: {
                                __typename?: 'Messages';
                                Message?: Array<string | null> | null;
                              } | null;
                              Property?: {
                                __typename?: 'CommercialPropertyType';
                                LotSize?: number | null;
                                LotSizeUnitOfMeasurement?: string | null;
                                PropertyType?: string | null;
                                Buildings?: {
                                  __typename?: 'BuildingsType';
                                  Building: Array<{
                                    __typename?: 'BuildingType';
                                    StructureID?: string | null;
                                    ConstructionFeatures?: {
                                      __typename?: 'CommercialConstructionFeaturesType';
                                      CombustibilityClass?: string | null;
                                      ConstructionClass?: string | null;
                                      FloorHeight?: number | null;
                                      FloorHeightUnitOfMeasurement?:
                                        | string
                                        | null;
                                      LoadingDockPresent?: boolean | null;
                                      NumberOfStoreys?: number | null;
                                      NumberOfUnits?: number | null;
                                      TotalFloorArea?: number | null;
                                      TotalFloorAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      YearBuilt?: number | null;
                                      Basement?: {
                                        __typename?: 'CommercialBasementType';
                                        BasementArea?: number | null;
                                        BasementAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        BasementPresent?: boolean | null;
                                      } | null;
                                      BuildingUpdates?: {
                                        __typename?: 'BuildingUpdatesType';
                                        BuildingUpdate?: Array<
                                          number | null
                                        > | null;
                                      } | null;
                                      CommercialExteriorWallTypes?: {
                                        __typename?: 'CommercialExteriorWallTypesType';
                                        ExteriorWallType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Elevator?: {
                                        __typename?: 'ElevatorType';
                                        ElevatorCount?: number | null;
                                        ElevatorPresent?: boolean | null;
                                      } | null;
                                      Heating?: {
                                        __typename?: 'CommercialHeatingType';
                                        HeatingFuelSources?: {
                                          __typename?: 'HeatingFuelSourcesType';
                                          HeatingFuelSource?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        HeatingTypes?: {
                                          __typename?: 'HeatingTypesType';
                                          HeatingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      Parking?: {
                                        __typename?: 'CommercialParkingType';
                                        ParkingPresent?: boolean | null;
                                        ParkingTypes?: {
                                          __typename?: 'CommercialParkingTypesType';
                                          ParkingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      PlumbingTypes?: {
                                        __typename?: 'CommercialPlumbingType';
                                        PlumbingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofAttributes?: {
                                        __typename?: 'RoofAttributesType';
                                        RoofConstructions?: {
                                          __typename?: 'RoofConstructionsType';
                                          RoofConstruction?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofSurfaces?: {
                                          __typename?: 'RoofSurfacesType';
                                          RoofSurface?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofTypes?: {
                                          __typename?: 'RoofTypesType';
                                          RoofType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      WaterHeater?: {
                                        __typename?: 'CommercialWaterHeaterType';
                                        WaterHeaterFuelType?: Array<
                                          string | null
                                        > | null;
                                        WaterHeaterType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Wiring?: {
                                        __typename?: 'CommercialWiringType';
                                        WiringPanelTypes?: {
                                          __typename?: 'CommercialWiringPanelTypesType';
                                          WiringPanelType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        WiringTypes?: {
                                          __typename?: 'CommercialWiringTypesType';
                                          WiringType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    Protection?: {
                                      __typename?: 'ProtectionType';
                                      FireAlarmPresent?: boolean | null;
                                      FireAlarmType?: string | null;
                                      PhysicalSecurityPresent?: boolean | null;
                                      SecurityAlarmPresent?: boolean | null;
                                      SecurityAlarmType?: string | null;
                                      SprinklerPresent?: boolean | null;
                                      SprinklerTypeCode?: string | null;
                                      PhysicalSecurityTypes?: {
                                        __typename?: 'PhysicalSecurityTypesType';
                                        PhysicalSecurityType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  }>;
                                } | null;
                                Industry?: {
                                  __typename?: 'BusinessIndustryType';
                                  IBCCode?: string | null;
                                  IBCIndustryDescription?: string | null;
                                  SICIndustryDescription?: string | null;
                                  SICCode?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            optaCommercialPerilScoreData?: {
                              __typename?: 'OptaCommercialPerilScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              PropertyPerilScores?: {
                                __typename?: 'PropertyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              CasualtyPerilScores?: {
                                __typename?: 'CasualtyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              AllPerilsScore?: {
                                __typename?: 'CommercialPerilScoreType';
                                LossCostScore?: number | null;
                                PredictedLossCost?: number | null;
                              } | null;
                            } | null;
                          } | null;
                          openingHours: Array<{
                            __typename?: 'OpeningHoursType';
                            day: number;
                            open?: any | null;
                            close?: any | null;
                          }>;
                        } | null;
                      }>;
                      details?: {
                        __typename?: 'AnswerInstanceDetailsType';
                        lat?: number | null;
                        lng?: number | null;
                        images: Array<{
                          __typename?: 'GoogleImageType';
                          description: string;
                          shortDescription: string;
                          category: CoreGoogleImageCategoryChoices;
                          data?: string | null;
                        }>;
                        google?: {
                          __typename?: 'GoogleDetailsType';
                          placeId?: string | null;
                          rating?: number | null;
                          numberOfRatings?: number | null;
                        } | null;
                        opta?: {
                          __typename?: 'OPTADetailsType';
                          optaFusData?: {
                            __typename?: 'OptaFusDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Commercial?: {
                              __typename?: 'FusProductCommercialType';
                              SearchMessage?: string | null;
                              CommercialGrade: Array<{
                                __typename?: 'CommercialGradeType';
                                PublicFireProtectionClassification?:
                                  | number
                                  | null;
                                WaterSystem?: string | null;
                                RiskSCORDetail: Array<{
                                  __typename?: 'RiskSCORDetailType';
                                  RiskSCOR?: number | null;
                                  Type?: string | null;
                                  Adjustment: Array<{
                                    __typename?: 'RiskSCORAdjustmentType';
                                    Adjustment?: string | null;
                                    AdjustmentType?: string | null;
                                  }>;
                                }>;
                              }>;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            Dwelling?: {
                              __typename?: 'FusProductDwellingType';
                              SearchMessage?: string | null;
                              DwellingGrades?: {
                                __typename?: 'DwellingGradesType';
                                AllOtherAreasGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                FirehallProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                HydrantProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                              } | null;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            EarthquakeCodes?: {
                              __typename?: 'EarthquakeCodesType';
                              SearchMessage?: string | null;
                              EarthquakeCode: Array<{
                                __typename?: 'EarthquakeCodeType';
                                Description?: string | null;
                                SystemName?: string | null;
                                Zone?: string | null;
                              }>;
                            } | null;
                            FusMaps?: {
                              __typename?: 'FusMapsType';
                              SearchMessage?: string | null;
                              FusMap: Array<{
                                __typename?: 'FusMapType';
                                Business?: string | null;
                                FusContent?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                            HydrantCounts?: {
                              __typename?: 'HydrantCountsType';
                              SearchMessage?: string | null;
                              HydrantCount: Array<{
                                __typename?: 'HydrantCountType';
                                NumberOfHydrants: number;
                                Ownership?: string | null;
                                RadiusMetres?: string | null;
                              }>;
                            } | null;
                          } | null;
                          optaFloodscoreData?: {
                            __typename?: 'OptaFloodScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            FloodScores?: {
                              __typename?: 'FloodScoresType';
                              FloodScore: Array<{
                                __typename?: 'FloodScoreType';
                                AverageAnnualLossScore?: number | null;
                                Deductible?: number | null;
                                Default?: boolean | null;
                                FloodScore?: number | null;
                                Limit?: number | null;
                                RateFactor?: number | null;
                                Zone?: string | null;
                                ResidentialModifiers?: {
                                  __typename?: 'ResidentialConstructionFeaturesType';
                                  ArchitecturalStyleType?: string | null;
                                  AuxiliaryHeatingType?: string | null;
                                  BathroomCount?: string | null;
                                  CommercialIndicator?: string | null;
                                  ExteriorWallType?: string | null;
                                  FinishedBasement?: boolean | null;
                                  FoundationType?: string | null;
                                  GarageNumberOfCars?: number | null;
                                  GarageType?: string | null;
                                  KitchenCount?: string | null;
                                  OutbuildingPresent?: string | null;
                                  PlumbingType?: string | null;
                                  PrimaryHeatingType?: string | null;
                                  RoofType?: string | null;
                                  SquareFootage?: number | null;
                                  StoreyCount?: string | null;
                                  SwimmingPoolType?: string | null;
                                  YearBuilt?: string | null;
                                  InspectionDetails?: {
                                    __typename?: 'InspectionDetailsType';
                                    InspectionDate?: any | null;
                                    SponsoredInspection?: boolean | null;
                                  } | null;
                                } | null;
                                MultiResidentialModifiers?: {
                                  __typename?: 'MultiResidentialConstructionFeaturesType';
                                  BasementPresent?: boolean | null;
                                  ConstructionType?: string | null;
                                  FloorLevel?: number | null;
                                } | null;
                                AverageAnnualLossValues?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                                AdditionalDetails: Array<{
                                  __typename?: 'FloodDetailType';
                                  Key?: string | null;
                                  Value?: string | null;
                                }>;
                                Premium?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                              }>;
                              FloodMap: Array<{
                                __typename?: 'FloodMapType';
                                FloodEvent?: string | null;
                                Content?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          optaIclarifyCommercialConstructionData?: {
                            __typename?: 'OptaIclarifyCommercialConstructionDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Messages?: {
                              __typename?: 'Messages';
                              Message?: Array<string | null> | null;
                            } | null;
                            Property?: {
                              __typename?: 'CommercialPropertyType';
                              LotSize?: number | null;
                              LotSizeUnitOfMeasurement?: string | null;
                              PropertyType?: string | null;
                              Buildings?: {
                                __typename?: 'BuildingsType';
                                Building: Array<{
                                  __typename?: 'BuildingType';
                                  StructureID?: string | null;
                                  ConstructionFeatures?: {
                                    __typename?: 'CommercialConstructionFeaturesType';
                                    CombustibilityClass?: string | null;
                                    ConstructionClass?: string | null;
                                    FloorHeight?: number | null;
                                    FloorHeightUnitOfMeasurement?:
                                      | string
                                      | null;
                                    LoadingDockPresent?: boolean | null;
                                    NumberOfStoreys?: number | null;
                                    NumberOfUnits?: number | null;
                                    TotalFloorArea?: number | null;
                                    TotalFloorAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    YearBuilt?: number | null;
                                    Basement?: {
                                      __typename?: 'CommercialBasementType';
                                      BasementArea?: number | null;
                                      BasementAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      BasementPresent?: boolean | null;
                                    } | null;
                                    BuildingUpdates?: {
                                      __typename?: 'BuildingUpdatesType';
                                      BuildingUpdate?: Array<
                                        number | null
                                      > | null;
                                    } | null;
                                    CommercialExteriorWallTypes?: {
                                      __typename?: 'CommercialExteriorWallTypesType';
                                      ExteriorWallType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Elevator?: {
                                      __typename?: 'ElevatorType';
                                      ElevatorCount?: number | null;
                                      ElevatorPresent?: boolean | null;
                                    } | null;
                                    Heating?: {
                                      __typename?: 'CommercialHeatingType';
                                      HeatingFuelSources?: {
                                        __typename?: 'HeatingFuelSourcesType';
                                        HeatingFuelSource?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      HeatingTypes?: {
                                        __typename?: 'HeatingTypesType';
                                        HeatingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    Parking?: {
                                      __typename?: 'CommercialParkingType';
                                      ParkingPresent?: boolean | null;
                                      ParkingTypes?: {
                                        __typename?: 'CommercialParkingTypesType';
                                        ParkingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    PlumbingTypes?: {
                                      __typename?: 'CommercialPlumbingType';
                                      PlumbingType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofAttributes?: {
                                      __typename?: 'RoofAttributesType';
                                      RoofConstructions?: {
                                        __typename?: 'RoofConstructionsType';
                                        RoofConstruction?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofSurfaces?: {
                                        __typename?: 'RoofSurfacesType';
                                        RoofSurface?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofTypes?: {
                                        __typename?: 'RoofTypesType';
                                        RoofType?: Array<string | null> | null;
                                      } | null;
                                    } | null;
                                    WaterHeater?: {
                                      __typename?: 'CommercialWaterHeaterType';
                                      WaterHeaterFuelType?: Array<
                                        string | null
                                      > | null;
                                      WaterHeaterType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Wiring?: {
                                      __typename?: 'CommercialWiringType';
                                      WiringPanelTypes?: {
                                        __typename?: 'CommercialWiringPanelTypesType';
                                        WiringPanelType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      WiringTypes?: {
                                        __typename?: 'CommercialWiringTypesType';
                                        WiringType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  Protection?: {
                                    __typename?: 'ProtectionType';
                                    FireAlarmPresent?: boolean | null;
                                    FireAlarmType?: string | null;
                                    PhysicalSecurityPresent?: boolean | null;
                                    SecurityAlarmPresent?: boolean | null;
                                    SecurityAlarmType?: string | null;
                                    SprinklerPresent?: boolean | null;
                                    SprinklerTypeCode?: string | null;
                                    PhysicalSecurityTypes?: {
                                      __typename?: 'PhysicalSecurityTypesType';
                                      PhysicalSecurityType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                  } | null;
                                }>;
                              } | null;
                              Industry?: {
                                __typename?: 'BusinessIndustryType';
                                IBCCode?: string | null;
                                IBCIndustryDescription?: string | null;
                                SICIndustryDescription?: string | null;
                                SICCode?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          optaCommercialPerilScoreData?: {
                            __typename?: 'OptaCommercialPerilScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            PropertyPerilScores?: {
                              __typename?: 'PropertyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            CasualtyPerilScores?: {
                              __typename?: 'CasualtyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            AllPerilsScore?: {
                              __typename?: 'CommercialPerilScoreType';
                              LossCostScore?: number | null;
                              PredictedLossCost?: number | null;
                            } | null;
                          } | null;
                        } | null;
                        openingHours: Array<{
                          __typename?: 'OpeningHoursType';
                          day: number;
                          open?: any | null;
                          close?: any | null;
                        }>;
                      } | null;
                    }>;
                    details?: {
                      __typename?: 'AnswerInstanceDetailsType';
                      lat?: number | null;
                      lng?: number | null;
                      images: Array<{
                        __typename?: 'GoogleImageType';
                        description: string;
                        shortDescription: string;
                        category: CoreGoogleImageCategoryChoices;
                        data?: string | null;
                      }>;
                      google?: {
                        __typename?: 'GoogleDetailsType';
                        placeId?: string | null;
                        rating?: number | null;
                        numberOfRatings?: number | null;
                      } | null;
                      opta?: {
                        __typename?: 'OPTADetailsType';
                        optaFusData?: {
                          __typename?: 'OptaFusDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Commercial?: {
                            __typename?: 'FusProductCommercialType';
                            SearchMessage?: string | null;
                            CommercialGrade: Array<{
                              __typename?: 'CommercialGradeType';
                              PublicFireProtectionClassification?:
                                | number
                                | null;
                              WaterSystem?: string | null;
                              RiskSCORDetail: Array<{
                                __typename?: 'RiskSCORDetailType';
                                RiskSCOR?: number | null;
                                Type?: string | null;
                                Adjustment: Array<{
                                  __typename?: 'RiskSCORAdjustmentType';
                                  Adjustment?: string | null;
                                  AdjustmentType?: string | null;
                                }>;
                              }>;
                            }>;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          Dwelling?: {
                            __typename?: 'FusProductDwellingType';
                            SearchMessage?: string | null;
                            DwellingGrades?: {
                              __typename?: 'DwellingGradesType';
                              AllOtherAreasGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              FirehallProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              HydrantProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                            } | null;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          EarthquakeCodes?: {
                            __typename?: 'EarthquakeCodesType';
                            SearchMessage?: string | null;
                            EarthquakeCode: Array<{
                              __typename?: 'EarthquakeCodeType';
                              Description?: string | null;
                              SystemName?: string | null;
                              Zone?: string | null;
                            }>;
                          } | null;
                          FusMaps?: {
                            __typename?: 'FusMapsType';
                            SearchMessage?: string | null;
                            FusMap: Array<{
                              __typename?: 'FusMapType';
                              Business?: string | null;
                              FusContent?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                          HydrantCounts?: {
                            __typename?: 'HydrantCountsType';
                            SearchMessage?: string | null;
                            HydrantCount: Array<{
                              __typename?: 'HydrantCountType';
                              NumberOfHydrants: number;
                              Ownership?: string | null;
                              RadiusMetres?: string | null;
                            }>;
                          } | null;
                        } | null;
                        optaFloodscoreData?: {
                          __typename?: 'OptaFloodScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          FloodScores?: {
                            __typename?: 'FloodScoresType';
                            FloodScore: Array<{
                              __typename?: 'FloodScoreType';
                              AverageAnnualLossScore?: number | null;
                              Deductible?: number | null;
                              Default?: boolean | null;
                              FloodScore?: number | null;
                              Limit?: number | null;
                              RateFactor?: number | null;
                              Zone?: string | null;
                              ResidentialModifiers?: {
                                __typename?: 'ResidentialConstructionFeaturesType';
                                ArchitecturalStyleType?: string | null;
                                AuxiliaryHeatingType?: string | null;
                                BathroomCount?: string | null;
                                CommercialIndicator?: string | null;
                                ExteriorWallType?: string | null;
                                FinishedBasement?: boolean | null;
                                FoundationType?: string | null;
                                GarageNumberOfCars?: number | null;
                                GarageType?: string | null;
                                KitchenCount?: string | null;
                                OutbuildingPresent?: string | null;
                                PlumbingType?: string | null;
                                PrimaryHeatingType?: string | null;
                                RoofType?: string | null;
                                SquareFootage?: number | null;
                                StoreyCount?: string | null;
                                SwimmingPoolType?: string | null;
                                YearBuilt?: string | null;
                                InspectionDetails?: {
                                  __typename?: 'InspectionDetailsType';
                                  InspectionDate?: any | null;
                                  SponsoredInspection?: boolean | null;
                                } | null;
                              } | null;
                              MultiResidentialModifiers?: {
                                __typename?: 'MultiResidentialConstructionFeaturesType';
                                BasementPresent?: boolean | null;
                                ConstructionType?: string | null;
                                FloorLevel?: number | null;
                              } | null;
                              AverageAnnualLossValues?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                              AdditionalDetails: Array<{
                                __typename?: 'FloodDetailType';
                                Key?: string | null;
                                Value?: string | null;
                              }>;
                              Premium?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                            }>;
                            FloodMap: Array<{
                              __typename?: 'FloodMapType';
                              FloodEvent?: string | null;
                              Content?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        optaIclarifyCommercialConstructionData?: {
                          __typename?: 'OptaIclarifyCommercialConstructionDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Messages?: {
                            __typename?: 'Messages';
                            Message?: Array<string | null> | null;
                          } | null;
                          Property?: {
                            __typename?: 'CommercialPropertyType';
                            LotSize?: number | null;
                            LotSizeUnitOfMeasurement?: string | null;
                            PropertyType?: string | null;
                            Buildings?: {
                              __typename?: 'BuildingsType';
                              Building: Array<{
                                __typename?: 'BuildingType';
                                StructureID?: string | null;
                                ConstructionFeatures?: {
                                  __typename?: 'CommercialConstructionFeaturesType';
                                  CombustibilityClass?: string | null;
                                  ConstructionClass?: string | null;
                                  FloorHeight?: number | null;
                                  FloorHeightUnitOfMeasurement?: string | null;
                                  LoadingDockPresent?: boolean | null;
                                  NumberOfStoreys?: number | null;
                                  NumberOfUnits?: number | null;
                                  TotalFloorArea?: number | null;
                                  TotalFloorAreaUnitOfMeasurement?:
                                    | string
                                    | null;
                                  YearBuilt?: number | null;
                                  Basement?: {
                                    __typename?: 'CommercialBasementType';
                                    BasementArea?: number | null;
                                    BasementAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    BasementPresent?: boolean | null;
                                  } | null;
                                  BuildingUpdates?: {
                                    __typename?: 'BuildingUpdatesType';
                                    BuildingUpdate?: Array<
                                      number | null
                                    > | null;
                                  } | null;
                                  CommercialExteriorWallTypes?: {
                                    __typename?: 'CommercialExteriorWallTypesType';
                                    ExteriorWallType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Elevator?: {
                                    __typename?: 'ElevatorType';
                                    ElevatorCount?: number | null;
                                    ElevatorPresent?: boolean | null;
                                  } | null;
                                  Heating?: {
                                    __typename?: 'CommercialHeatingType';
                                    HeatingFuelSources?: {
                                      __typename?: 'HeatingFuelSourcesType';
                                      HeatingFuelSource?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    HeatingTypes?: {
                                      __typename?: 'HeatingTypesType';
                                      HeatingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  Parking?: {
                                    __typename?: 'CommercialParkingType';
                                    ParkingPresent?: boolean | null;
                                    ParkingTypes?: {
                                      __typename?: 'CommercialParkingTypesType';
                                      ParkingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  PlumbingTypes?: {
                                    __typename?: 'CommercialPlumbingType';
                                    PlumbingType?: Array<string | null> | null;
                                  } | null;
                                  RoofAttributes?: {
                                    __typename?: 'RoofAttributesType';
                                    RoofConstructions?: {
                                      __typename?: 'RoofConstructionsType';
                                      RoofConstruction?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofSurfaces?: {
                                      __typename?: 'RoofSurfacesType';
                                      RoofSurface?: Array<string | null> | null;
                                    } | null;
                                    RoofTypes?: {
                                      __typename?: 'RoofTypesType';
                                      RoofType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  WaterHeater?: {
                                    __typename?: 'CommercialWaterHeaterType';
                                    WaterHeaterFuelType?: Array<
                                      string | null
                                    > | null;
                                    WaterHeaterType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Wiring?: {
                                    __typename?: 'CommercialWiringType';
                                    WiringPanelTypes?: {
                                      __typename?: 'CommercialWiringPanelTypesType';
                                      WiringPanelType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    WiringTypes?: {
                                      __typename?: 'CommercialWiringTypesType';
                                      WiringType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Protection?: {
                                  __typename?: 'ProtectionType';
                                  FireAlarmPresent?: boolean | null;
                                  FireAlarmType?: string | null;
                                  PhysicalSecurityPresent?: boolean | null;
                                  SecurityAlarmPresent?: boolean | null;
                                  SecurityAlarmType?: string | null;
                                  SprinklerPresent?: boolean | null;
                                  SprinklerTypeCode?: string | null;
                                  PhysicalSecurityTypes?: {
                                    __typename?: 'PhysicalSecurityTypesType';
                                    PhysicalSecurityType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                } | null;
                              }>;
                            } | null;
                            Industry?: {
                              __typename?: 'BusinessIndustryType';
                              IBCCode?: string | null;
                              IBCIndustryDescription?: string | null;
                              SICIndustryDescription?: string | null;
                              SICCode?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        optaCommercialPerilScoreData?: {
                          __typename?: 'OptaCommercialPerilScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          PropertyPerilScores?: {
                            __typename?: 'PropertyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          CasualtyPerilScores?: {
                            __typename?: 'CasualtyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          AllPerilsScore?: {
                            __typename?: 'CommercialPerilScoreType';
                            LossCostScore?: number | null;
                            PredictedLossCost?: number | null;
                          } | null;
                        } | null;
                      } | null;
                      openingHours: Array<{
                        __typename?: 'OpeningHoursType';
                        day: number;
                        open?: any | null;
                        close?: any | null;
                      }>;
                    } | null;
                  }>;
                  details?: {
                    __typename?: 'AnswerInstanceDetailsType';
                    lat?: number | null;
                    lng?: number | null;
                    images: Array<{
                      __typename?: 'GoogleImageType';
                      description: string;
                      shortDescription: string;
                      category: CoreGoogleImageCategoryChoices;
                      data?: string | null;
                    }>;
                    google?: {
                      __typename?: 'GoogleDetailsType';
                      placeId?: string | null;
                      rating?: number | null;
                      numberOfRatings?: number | null;
                    } | null;
                    opta?: {
                      __typename?: 'OPTADetailsType';
                      optaFusData?: {
                        __typename?: 'OptaFusDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Commercial?: {
                          __typename?: 'FusProductCommercialType';
                          SearchMessage?: string | null;
                          CommercialGrade: Array<{
                            __typename?: 'CommercialGradeType';
                            PublicFireProtectionClassification?: number | null;
                            WaterSystem?: string | null;
                            RiskSCORDetail: Array<{
                              __typename?: 'RiskSCORDetailType';
                              RiskSCOR?: number | null;
                              Type?: string | null;
                              Adjustment: Array<{
                                __typename?: 'RiskSCORAdjustmentType';
                                Adjustment?: string | null;
                                AdjustmentType?: string | null;
                              }>;
                            }>;
                          }>;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        Dwelling?: {
                          __typename?: 'FusProductDwellingType';
                          SearchMessage?: string | null;
                          DwellingGrades?: {
                            __typename?: 'DwellingGradesType';
                            AllOtherAreasGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            FirehallProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            HydrantProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                          } | null;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        EarthquakeCodes?: {
                          __typename?: 'EarthquakeCodesType';
                          SearchMessage?: string | null;
                          EarthquakeCode: Array<{
                            __typename?: 'EarthquakeCodeType';
                            Description?: string | null;
                            SystemName?: string | null;
                            Zone?: string | null;
                          }>;
                        } | null;
                        FusMaps?: {
                          __typename?: 'FusMapsType';
                          SearchMessage?: string | null;
                          FusMap: Array<{
                            __typename?: 'FusMapType';
                            Business?: string | null;
                            FusContent?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                        HydrantCounts?: {
                          __typename?: 'HydrantCountsType';
                          SearchMessage?: string | null;
                          HydrantCount: Array<{
                            __typename?: 'HydrantCountType';
                            NumberOfHydrants: number;
                            Ownership?: string | null;
                            RadiusMetres?: string | null;
                          }>;
                        } | null;
                      } | null;
                      optaFloodscoreData?: {
                        __typename?: 'OptaFloodScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        FloodScores?: {
                          __typename?: 'FloodScoresType';
                          FloodScore: Array<{
                            __typename?: 'FloodScoreType';
                            AverageAnnualLossScore?: number | null;
                            Deductible?: number | null;
                            Default?: boolean | null;
                            FloodScore?: number | null;
                            Limit?: number | null;
                            RateFactor?: number | null;
                            Zone?: string | null;
                            ResidentialModifiers?: {
                              __typename?: 'ResidentialConstructionFeaturesType';
                              ArchitecturalStyleType?: string | null;
                              AuxiliaryHeatingType?: string | null;
                              BathroomCount?: string | null;
                              CommercialIndicator?: string | null;
                              ExteriorWallType?: string | null;
                              FinishedBasement?: boolean | null;
                              FoundationType?: string | null;
                              GarageNumberOfCars?: number | null;
                              GarageType?: string | null;
                              KitchenCount?: string | null;
                              OutbuildingPresent?: string | null;
                              PlumbingType?: string | null;
                              PrimaryHeatingType?: string | null;
                              RoofType?: string | null;
                              SquareFootage?: number | null;
                              StoreyCount?: string | null;
                              SwimmingPoolType?: string | null;
                              YearBuilt?: string | null;
                              InspectionDetails?: {
                                __typename?: 'InspectionDetailsType';
                                InspectionDate?: any | null;
                                SponsoredInspection?: boolean | null;
                              } | null;
                            } | null;
                            MultiResidentialModifiers?: {
                              __typename?: 'MultiResidentialConstructionFeaturesType';
                              BasementPresent?: boolean | null;
                              ConstructionType?: string | null;
                              FloorLevel?: number | null;
                            } | null;
                            AverageAnnualLossValues?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                            AdditionalDetails: Array<{
                              __typename?: 'FloodDetailType';
                              Key?: string | null;
                              Value?: string | null;
                            }>;
                            Premium?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                          }>;
                          FloodMap: Array<{
                            __typename?: 'FloodMapType';
                            FloodEvent?: string | null;
                            Content?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      optaIclarifyCommercialConstructionData?: {
                        __typename?: 'OptaIclarifyCommercialConstructionDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Messages?: {
                          __typename?: 'Messages';
                          Message?: Array<string | null> | null;
                        } | null;
                        Property?: {
                          __typename?: 'CommercialPropertyType';
                          LotSize?: number | null;
                          LotSizeUnitOfMeasurement?: string | null;
                          PropertyType?: string | null;
                          Buildings?: {
                            __typename?: 'BuildingsType';
                            Building: Array<{
                              __typename?: 'BuildingType';
                              StructureID?: string | null;
                              ConstructionFeatures?: {
                                __typename?: 'CommercialConstructionFeaturesType';
                                CombustibilityClass?: string | null;
                                ConstructionClass?: string | null;
                                FloorHeight?: number | null;
                                FloorHeightUnitOfMeasurement?: string | null;
                                LoadingDockPresent?: boolean | null;
                                NumberOfStoreys?: number | null;
                                NumberOfUnits?: number | null;
                                TotalFloorArea?: number | null;
                                TotalFloorAreaUnitOfMeasurement?: string | null;
                                YearBuilt?: number | null;
                                Basement?: {
                                  __typename?: 'CommercialBasementType';
                                  BasementArea?: number | null;
                                  BasementAreaUnitOfMeasurement?: string | null;
                                  BasementPresent?: boolean | null;
                                } | null;
                                BuildingUpdates?: {
                                  __typename?: 'BuildingUpdatesType';
                                  BuildingUpdate?: Array<number | null> | null;
                                } | null;
                                CommercialExteriorWallTypes?: {
                                  __typename?: 'CommercialExteriorWallTypesType';
                                  ExteriorWallType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                Elevator?: {
                                  __typename?: 'ElevatorType';
                                  ElevatorCount?: number | null;
                                  ElevatorPresent?: boolean | null;
                                } | null;
                                Heating?: {
                                  __typename?: 'CommercialHeatingType';
                                  HeatingFuelSources?: {
                                    __typename?: 'HeatingFuelSourcesType';
                                    HeatingFuelSource?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  HeatingTypes?: {
                                    __typename?: 'HeatingTypesType';
                                    HeatingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                Parking?: {
                                  __typename?: 'CommercialParkingType';
                                  ParkingPresent?: boolean | null;
                                  ParkingTypes?: {
                                    __typename?: 'CommercialParkingTypesType';
                                    ParkingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                PlumbingTypes?: {
                                  __typename?: 'CommercialPlumbingType';
                                  PlumbingType?: Array<string | null> | null;
                                } | null;
                                RoofAttributes?: {
                                  __typename?: 'RoofAttributesType';
                                  RoofConstructions?: {
                                    __typename?: 'RoofConstructionsType';
                                    RoofConstruction?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  RoofSurfaces?: {
                                    __typename?: 'RoofSurfacesType';
                                    RoofSurface?: Array<string | null> | null;
                                  } | null;
                                  RoofTypes?: {
                                    __typename?: 'RoofTypesType';
                                    RoofType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                WaterHeater?: {
                                  __typename?: 'CommercialWaterHeaterType';
                                  WaterHeaterFuelType?: Array<
                                    string | null
                                  > | null;
                                  WaterHeaterType?: Array<string | null> | null;
                                } | null;
                                Wiring?: {
                                  __typename?: 'CommercialWiringType';
                                  WiringPanelTypes?: {
                                    __typename?: 'CommercialWiringPanelTypesType';
                                    WiringPanelType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  WiringTypes?: {
                                    __typename?: 'CommercialWiringTypesType';
                                    WiringType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                              } | null;
                              Protection?: {
                                __typename?: 'ProtectionType';
                                FireAlarmPresent?: boolean | null;
                                FireAlarmType?: string | null;
                                PhysicalSecurityPresent?: boolean | null;
                                SecurityAlarmPresent?: boolean | null;
                                SecurityAlarmType?: string | null;
                                SprinklerPresent?: boolean | null;
                                SprinklerTypeCode?: string | null;
                                PhysicalSecurityTypes?: {
                                  __typename?: 'PhysicalSecurityTypesType';
                                  PhysicalSecurityType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                              } | null;
                            }>;
                          } | null;
                          Industry?: {
                            __typename?: 'BusinessIndustryType';
                            IBCCode?: string | null;
                            IBCIndustryDescription?: string | null;
                            SICIndustryDescription?: string | null;
                            SICCode?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      optaCommercialPerilScoreData?: {
                        __typename?: 'OptaCommercialPerilScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        PropertyPerilScores?: {
                          __typename?: 'PropertyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        CasualtyPerilScores?: {
                          __typename?: 'CasualtyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        AllPerilsScore?: {
                          __typename?: 'CommercialPerilScoreType';
                          LossCostScore?: number | null;
                          PredictedLossCost?: number | null;
                        } | null;
                      } | null;
                    } | null;
                    openingHours: Array<{
                      __typename?: 'OpeningHoursType';
                      day: number;
                      open?: any | null;
                      close?: any | null;
                    }>;
                  } | null;
                }>;
                details?: {
                  __typename?: 'AnswerInstanceDetailsType';
                  lat?: number | null;
                  lng?: number | null;
                  images: Array<{
                    __typename?: 'GoogleImageType';
                    description: string;
                    shortDescription: string;
                    category: CoreGoogleImageCategoryChoices;
                    data?: string | null;
                  }>;
                  google?: {
                    __typename?: 'GoogleDetailsType';
                    placeId?: string | null;
                    rating?: number | null;
                    numberOfRatings?: number | null;
                  } | null;
                  opta?: {
                    __typename?: 'OPTADetailsType';
                    optaFusData?: {
                      __typename?: 'OptaFusDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Commercial?: {
                        __typename?: 'FusProductCommercialType';
                        SearchMessage?: string | null;
                        CommercialGrade: Array<{
                          __typename?: 'CommercialGradeType';
                          PublicFireProtectionClassification?: number | null;
                          WaterSystem?: string | null;
                          RiskSCORDetail: Array<{
                            __typename?: 'RiskSCORDetailType';
                            RiskSCOR?: number | null;
                            Type?: string | null;
                            Adjustment: Array<{
                              __typename?: 'RiskSCORAdjustmentType';
                              Adjustment?: string | null;
                              AdjustmentType?: string | null;
                            }>;
                          }>;
                        }>;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      Dwelling?: {
                        __typename?: 'FusProductDwellingType';
                        SearchMessage?: string | null;
                        DwellingGrades?: {
                          __typename?: 'DwellingGradesType';
                          AllOtherAreasGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          FirehallProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          HydrantProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                        } | null;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      EarthquakeCodes?: {
                        __typename?: 'EarthquakeCodesType';
                        SearchMessage?: string | null;
                        EarthquakeCode: Array<{
                          __typename?: 'EarthquakeCodeType';
                          Description?: string | null;
                          SystemName?: string | null;
                          Zone?: string | null;
                        }>;
                      } | null;
                      FusMaps?: {
                        __typename?: 'FusMapsType';
                        SearchMessage?: string | null;
                        FusMap: Array<{
                          __typename?: 'FusMapType';
                          Business?: string | null;
                          FusContent?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                      HydrantCounts?: {
                        __typename?: 'HydrantCountsType';
                        SearchMessage?: string | null;
                        HydrantCount: Array<{
                          __typename?: 'HydrantCountType';
                          NumberOfHydrants: number;
                          Ownership?: string | null;
                          RadiusMetres?: string | null;
                        }>;
                      } | null;
                    } | null;
                    optaFloodscoreData?: {
                      __typename?: 'OptaFloodScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      FloodScores?: {
                        __typename?: 'FloodScoresType';
                        FloodScore: Array<{
                          __typename?: 'FloodScoreType';
                          AverageAnnualLossScore?: number | null;
                          Deductible?: number | null;
                          Default?: boolean | null;
                          FloodScore?: number | null;
                          Limit?: number | null;
                          RateFactor?: number | null;
                          Zone?: string | null;
                          ResidentialModifiers?: {
                            __typename?: 'ResidentialConstructionFeaturesType';
                            ArchitecturalStyleType?: string | null;
                            AuxiliaryHeatingType?: string | null;
                            BathroomCount?: string | null;
                            CommercialIndicator?: string | null;
                            ExteriorWallType?: string | null;
                            FinishedBasement?: boolean | null;
                            FoundationType?: string | null;
                            GarageNumberOfCars?: number | null;
                            GarageType?: string | null;
                            KitchenCount?: string | null;
                            OutbuildingPresent?: string | null;
                            PlumbingType?: string | null;
                            PrimaryHeatingType?: string | null;
                            RoofType?: string | null;
                            SquareFootage?: number | null;
                            StoreyCount?: string | null;
                            SwimmingPoolType?: string | null;
                            YearBuilt?: string | null;
                            InspectionDetails?: {
                              __typename?: 'InspectionDetailsType';
                              InspectionDate?: any | null;
                              SponsoredInspection?: boolean | null;
                            } | null;
                          } | null;
                          MultiResidentialModifiers?: {
                            __typename?: 'MultiResidentialConstructionFeaturesType';
                            BasementPresent?: boolean | null;
                            ConstructionType?: string | null;
                            FloorLevel?: number | null;
                          } | null;
                          AverageAnnualLossValues?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                          AdditionalDetails: Array<{
                            __typename?: 'FloodDetailType';
                            Key?: string | null;
                            Value?: string | null;
                          }>;
                          Premium?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                        }>;
                        FloodMap: Array<{
                          __typename?: 'FloodMapType';
                          FloodEvent?: string | null;
                          Content?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    optaIclarifyCommercialConstructionData?: {
                      __typename?: 'OptaIclarifyCommercialConstructionDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Messages?: {
                        __typename?: 'Messages';
                        Message?: Array<string | null> | null;
                      } | null;
                      Property?: {
                        __typename?: 'CommercialPropertyType';
                        LotSize?: number | null;
                        LotSizeUnitOfMeasurement?: string | null;
                        PropertyType?: string | null;
                        Buildings?: {
                          __typename?: 'BuildingsType';
                          Building: Array<{
                            __typename?: 'BuildingType';
                            StructureID?: string | null;
                            ConstructionFeatures?: {
                              __typename?: 'CommercialConstructionFeaturesType';
                              CombustibilityClass?: string | null;
                              ConstructionClass?: string | null;
                              FloorHeight?: number | null;
                              FloorHeightUnitOfMeasurement?: string | null;
                              LoadingDockPresent?: boolean | null;
                              NumberOfStoreys?: number | null;
                              NumberOfUnits?: number | null;
                              TotalFloorArea?: number | null;
                              TotalFloorAreaUnitOfMeasurement?: string | null;
                              YearBuilt?: number | null;
                              Basement?: {
                                __typename?: 'CommercialBasementType';
                                BasementArea?: number | null;
                                BasementAreaUnitOfMeasurement?: string | null;
                                BasementPresent?: boolean | null;
                              } | null;
                              BuildingUpdates?: {
                                __typename?: 'BuildingUpdatesType';
                                BuildingUpdate?: Array<number | null> | null;
                              } | null;
                              CommercialExteriorWallTypes?: {
                                __typename?: 'CommercialExteriorWallTypesType';
                                ExteriorWallType?: Array<string | null> | null;
                              } | null;
                              Elevator?: {
                                __typename?: 'ElevatorType';
                                ElevatorCount?: number | null;
                                ElevatorPresent?: boolean | null;
                              } | null;
                              Heating?: {
                                __typename?: 'CommercialHeatingType';
                                HeatingFuelSources?: {
                                  __typename?: 'HeatingFuelSourcesType';
                                  HeatingFuelSource?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                HeatingTypes?: {
                                  __typename?: 'HeatingTypesType';
                                  HeatingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              Parking?: {
                                __typename?: 'CommercialParkingType';
                                ParkingPresent?: boolean | null;
                                ParkingTypes?: {
                                  __typename?: 'CommercialParkingTypesType';
                                  ParkingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              PlumbingTypes?: {
                                __typename?: 'CommercialPlumbingType';
                                PlumbingType?: Array<string | null> | null;
                              } | null;
                              RoofAttributes?: {
                                __typename?: 'RoofAttributesType';
                                RoofConstructions?: {
                                  __typename?: 'RoofConstructionsType';
                                  RoofConstruction?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                RoofSurfaces?: {
                                  __typename?: 'RoofSurfacesType';
                                  RoofSurface?: Array<string | null> | null;
                                } | null;
                                RoofTypes?: {
                                  __typename?: 'RoofTypesType';
                                  RoofType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              WaterHeater?: {
                                __typename?: 'CommercialWaterHeaterType';
                                WaterHeaterFuelType?: Array<
                                  string | null
                                > | null;
                                WaterHeaterType?: Array<string | null> | null;
                              } | null;
                              Wiring?: {
                                __typename?: 'CommercialWiringType';
                                WiringPanelTypes?: {
                                  __typename?: 'CommercialWiringPanelTypesType';
                                  WiringPanelType?: Array<string | null> | null;
                                } | null;
                                WiringTypes?: {
                                  __typename?: 'CommercialWiringTypesType';
                                  WiringType?: Array<string | null> | null;
                                } | null;
                              } | null;
                            } | null;
                            Protection?: {
                              __typename?: 'ProtectionType';
                              FireAlarmPresent?: boolean | null;
                              FireAlarmType?: string | null;
                              PhysicalSecurityPresent?: boolean | null;
                              SecurityAlarmPresent?: boolean | null;
                              SecurityAlarmType?: string | null;
                              SprinklerPresent?: boolean | null;
                              SprinklerTypeCode?: string | null;
                              PhysicalSecurityTypes?: {
                                __typename?: 'PhysicalSecurityTypesType';
                                PhysicalSecurityType?: Array<
                                  string | null
                                > | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                        Industry?: {
                          __typename?: 'BusinessIndustryType';
                          IBCCode?: string | null;
                          IBCIndustryDescription?: string | null;
                          SICIndustryDescription?: string | null;
                          SICCode?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    optaCommercialPerilScoreData?: {
                      __typename?: 'OptaCommercialPerilScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      PropertyPerilScores?: {
                        __typename?: 'PropertyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      CasualtyPerilScores?: {
                        __typename?: 'CasualtyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      AllPerilsScore?: {
                        __typename?: 'CommercialPerilScoreType';
                        LossCostScore?: number | null;
                        PredictedLossCost?: number | null;
                      } | null;
                    } | null;
                  } | null;
                  openingHours: Array<{
                    __typename?: 'OpeningHoursType';
                    day: number;
                    open?: any | null;
                    close?: any | null;
                  }>;
                } | null;
              }>;
              details?: {
                __typename?: 'AnswerInstanceDetailsType';
                lat?: number | null;
                lng?: number | null;
                images: Array<{
                  __typename?: 'GoogleImageType';
                  description: string;
                  shortDescription: string;
                  category: CoreGoogleImageCategoryChoices;
                  data?: string | null;
                }>;
                google?: {
                  __typename?: 'GoogleDetailsType';
                  placeId?: string | null;
                  rating?: number | null;
                  numberOfRatings?: number | null;
                } | null;
                opta?: {
                  __typename?: 'OPTADetailsType';
                  optaFusData?: {
                    __typename?: 'OptaFusDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Commercial?: {
                      __typename?: 'FusProductCommercialType';
                      SearchMessage?: string | null;
                      CommercialGrade: Array<{
                        __typename?: 'CommercialGradeType';
                        PublicFireProtectionClassification?: number | null;
                        WaterSystem?: string | null;
                        RiskSCORDetail: Array<{
                          __typename?: 'RiskSCORDetailType';
                          RiskSCOR?: number | null;
                          Type?: string | null;
                          Adjustment: Array<{
                            __typename?: 'RiskSCORAdjustmentType';
                            Adjustment?: string | null;
                            AdjustmentType?: string | null;
                          }>;
                        }>;
                      }>;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    Dwelling?: {
                      __typename?: 'FusProductDwellingType';
                      SearchMessage?: string | null;
                      DwellingGrades?: {
                        __typename?: 'DwellingGradesType';
                        AllOtherAreasGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        FirehallProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        HydrantProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                      } | null;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    EarthquakeCodes?: {
                      __typename?: 'EarthquakeCodesType';
                      SearchMessage?: string | null;
                      EarthquakeCode: Array<{
                        __typename?: 'EarthquakeCodeType';
                        Description?: string | null;
                        SystemName?: string | null;
                        Zone?: string | null;
                      }>;
                    } | null;
                    FusMaps?: {
                      __typename?: 'FusMapsType';
                      SearchMessage?: string | null;
                      FusMap: Array<{
                        __typename?: 'FusMapType';
                        Business?: string | null;
                        FusContent?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                    HydrantCounts?: {
                      __typename?: 'HydrantCountsType';
                      SearchMessage?: string | null;
                      HydrantCount: Array<{
                        __typename?: 'HydrantCountType';
                        NumberOfHydrants: number;
                        Ownership?: string | null;
                        RadiusMetres?: string | null;
                      }>;
                    } | null;
                  } | null;
                  optaFloodscoreData?: {
                    __typename?: 'OptaFloodScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    FloodScores?: {
                      __typename?: 'FloodScoresType';
                      FloodScore: Array<{
                        __typename?: 'FloodScoreType';
                        AverageAnnualLossScore?: number | null;
                        Deductible?: number | null;
                        Default?: boolean | null;
                        FloodScore?: number | null;
                        Limit?: number | null;
                        RateFactor?: number | null;
                        Zone?: string | null;
                        ResidentialModifiers?: {
                          __typename?: 'ResidentialConstructionFeaturesType';
                          ArchitecturalStyleType?: string | null;
                          AuxiliaryHeatingType?: string | null;
                          BathroomCount?: string | null;
                          CommercialIndicator?: string | null;
                          ExteriorWallType?: string | null;
                          FinishedBasement?: boolean | null;
                          FoundationType?: string | null;
                          GarageNumberOfCars?: number | null;
                          GarageType?: string | null;
                          KitchenCount?: string | null;
                          OutbuildingPresent?: string | null;
                          PlumbingType?: string | null;
                          PrimaryHeatingType?: string | null;
                          RoofType?: string | null;
                          SquareFootage?: number | null;
                          StoreyCount?: string | null;
                          SwimmingPoolType?: string | null;
                          YearBuilt?: string | null;
                          InspectionDetails?: {
                            __typename?: 'InspectionDetailsType';
                            InspectionDate?: any | null;
                            SponsoredInspection?: boolean | null;
                          } | null;
                        } | null;
                        MultiResidentialModifiers?: {
                          __typename?: 'MultiResidentialConstructionFeaturesType';
                          BasementPresent?: boolean | null;
                          ConstructionType?: string | null;
                          FloorLevel?: number | null;
                        } | null;
                        AverageAnnualLossValues?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                        AdditionalDetails: Array<{
                          __typename?: 'FloodDetailType';
                          Key?: string | null;
                          Value?: string | null;
                        }>;
                        Premium?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                      }>;
                      FloodMap: Array<{
                        __typename?: 'FloodMapType';
                        FloodEvent?: string | null;
                        Content?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  optaIclarifyCommercialConstructionData?: {
                    __typename?: 'OptaIclarifyCommercialConstructionDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Messages?: {
                      __typename?: 'Messages';
                      Message?: Array<string | null> | null;
                    } | null;
                    Property?: {
                      __typename?: 'CommercialPropertyType';
                      LotSize?: number | null;
                      LotSizeUnitOfMeasurement?: string | null;
                      PropertyType?: string | null;
                      Buildings?: {
                        __typename?: 'BuildingsType';
                        Building: Array<{
                          __typename?: 'BuildingType';
                          StructureID?: string | null;
                          ConstructionFeatures?: {
                            __typename?: 'CommercialConstructionFeaturesType';
                            CombustibilityClass?: string | null;
                            ConstructionClass?: string | null;
                            FloorHeight?: number | null;
                            FloorHeightUnitOfMeasurement?: string | null;
                            LoadingDockPresent?: boolean | null;
                            NumberOfStoreys?: number | null;
                            NumberOfUnits?: number | null;
                            TotalFloorArea?: number | null;
                            TotalFloorAreaUnitOfMeasurement?: string | null;
                            YearBuilt?: number | null;
                            Basement?: {
                              __typename?: 'CommercialBasementType';
                              BasementArea?: number | null;
                              BasementAreaUnitOfMeasurement?: string | null;
                              BasementPresent?: boolean | null;
                            } | null;
                            BuildingUpdates?: {
                              __typename?: 'BuildingUpdatesType';
                              BuildingUpdate?: Array<number | null> | null;
                            } | null;
                            CommercialExteriorWallTypes?: {
                              __typename?: 'CommercialExteriorWallTypesType';
                              ExteriorWallType?: Array<string | null> | null;
                            } | null;
                            Elevator?: {
                              __typename?: 'ElevatorType';
                              ElevatorCount?: number | null;
                              ElevatorPresent?: boolean | null;
                            } | null;
                            Heating?: {
                              __typename?: 'CommercialHeatingType';
                              HeatingFuelSources?: {
                                __typename?: 'HeatingFuelSourcesType';
                                HeatingFuelSource?: Array<string | null> | null;
                              } | null;
                              HeatingTypes?: {
                                __typename?: 'HeatingTypesType';
                                HeatingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            Parking?: {
                              __typename?: 'CommercialParkingType';
                              ParkingPresent?: boolean | null;
                              ParkingTypes?: {
                                __typename?: 'CommercialParkingTypesType';
                                ParkingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            PlumbingTypes?: {
                              __typename?: 'CommercialPlumbingType';
                              PlumbingType?: Array<string | null> | null;
                            } | null;
                            RoofAttributes?: {
                              __typename?: 'RoofAttributesType';
                              RoofConstructions?: {
                                __typename?: 'RoofConstructionsType';
                                RoofConstruction?: Array<string | null> | null;
                              } | null;
                              RoofSurfaces?: {
                                __typename?: 'RoofSurfacesType';
                                RoofSurface?: Array<string | null> | null;
                              } | null;
                              RoofTypes?: {
                                __typename?: 'RoofTypesType';
                                RoofType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            WaterHeater?: {
                              __typename?: 'CommercialWaterHeaterType';
                              WaterHeaterFuelType?: Array<string | null> | null;
                              WaterHeaterType?: Array<string | null> | null;
                            } | null;
                            Wiring?: {
                              __typename?: 'CommercialWiringType';
                              WiringPanelTypes?: {
                                __typename?: 'CommercialWiringPanelTypesType';
                                WiringPanelType?: Array<string | null> | null;
                              } | null;
                              WiringTypes?: {
                                __typename?: 'CommercialWiringTypesType';
                                WiringType?: Array<string | null> | null;
                              } | null;
                            } | null;
                          } | null;
                          Protection?: {
                            __typename?: 'ProtectionType';
                            FireAlarmPresent?: boolean | null;
                            FireAlarmType?: string | null;
                            PhysicalSecurityPresent?: boolean | null;
                            SecurityAlarmPresent?: boolean | null;
                            SecurityAlarmType?: string | null;
                            SprinklerPresent?: boolean | null;
                            SprinklerTypeCode?: string | null;
                            PhysicalSecurityTypes?: {
                              __typename?: 'PhysicalSecurityTypesType';
                              PhysicalSecurityType?: Array<
                                string | null
                              > | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                      Industry?: {
                        __typename?: 'BusinessIndustryType';
                        IBCCode?: string | null;
                        IBCIndustryDescription?: string | null;
                        SICIndustryDescription?: string | null;
                        SICCode?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  optaCommercialPerilScoreData?: {
                    __typename?: 'OptaCommercialPerilScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    PropertyPerilScores?: {
                      __typename?: 'PropertyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    CasualtyPerilScores?: {
                      __typename?: 'CasualtyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    AllPerilsScore?: {
                      __typename?: 'CommercialPerilScoreType';
                      LossCostScore?: number | null;
                      PredictedLossCost?: number | null;
                    } | null;
                  } | null;
                } | null;
                openingHours: Array<{
                  __typename?: 'OpeningHoursType';
                  day: number;
                  open?: any | null;
                  close?: any | null;
                }>;
              } | null;
            }>;
            details?: {
              __typename?: 'AnswerInstanceDetailsType';
              lat?: number | null;
              lng?: number | null;
              images: Array<{
                __typename?: 'GoogleImageType';
                description: string;
                shortDescription: string;
                category: CoreGoogleImageCategoryChoices;
                data?: string | null;
              }>;
              google?: {
                __typename?: 'GoogleDetailsType';
                placeId?: string | null;
                rating?: number | null;
                numberOfRatings?: number | null;
              } | null;
              opta?: {
                __typename?: 'OPTADetailsType';
                optaFusData?: {
                  __typename?: 'OptaFusDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Commercial?: {
                    __typename?: 'FusProductCommercialType';
                    SearchMessage?: string | null;
                    CommercialGrade: Array<{
                      __typename?: 'CommercialGradeType';
                      PublicFireProtectionClassification?: number | null;
                      WaterSystem?: string | null;
                      RiskSCORDetail: Array<{
                        __typename?: 'RiskSCORDetailType';
                        RiskSCOR?: number | null;
                        Type?: string | null;
                        Adjustment: Array<{
                          __typename?: 'RiskSCORAdjustmentType';
                          Adjustment?: string | null;
                          AdjustmentType?: string | null;
                        }>;
                      }>;
                    }>;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  Dwelling?: {
                    __typename?: 'FusProductDwellingType';
                    SearchMessage?: string | null;
                    DwellingGrades?: {
                      __typename?: 'DwellingGradesType';
                      AllOtherAreasGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      FirehallProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      HydrantProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                    } | null;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  EarthquakeCodes?: {
                    __typename?: 'EarthquakeCodesType';
                    SearchMessage?: string | null;
                    EarthquakeCode: Array<{
                      __typename?: 'EarthquakeCodeType';
                      Description?: string | null;
                      SystemName?: string | null;
                      Zone?: string | null;
                    }>;
                  } | null;
                  FusMaps?: {
                    __typename?: 'FusMapsType';
                    SearchMessage?: string | null;
                    FusMap: Array<{
                      __typename?: 'FusMapType';
                      Business?: string | null;
                      FusContent?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                  HydrantCounts?: {
                    __typename?: 'HydrantCountsType';
                    SearchMessage?: string | null;
                    HydrantCount: Array<{
                      __typename?: 'HydrantCountType';
                      NumberOfHydrants: number;
                      Ownership?: string | null;
                      RadiusMetres?: string | null;
                    }>;
                  } | null;
                } | null;
                optaFloodscoreData?: {
                  __typename?: 'OptaFloodScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  FloodScores?: {
                    __typename?: 'FloodScoresType';
                    FloodScore: Array<{
                      __typename?: 'FloodScoreType';
                      AverageAnnualLossScore?: number | null;
                      Deductible?: number | null;
                      Default?: boolean | null;
                      FloodScore?: number | null;
                      Limit?: number | null;
                      RateFactor?: number | null;
                      Zone?: string | null;
                      ResidentialModifiers?: {
                        __typename?: 'ResidentialConstructionFeaturesType';
                        ArchitecturalStyleType?: string | null;
                        AuxiliaryHeatingType?: string | null;
                        BathroomCount?: string | null;
                        CommercialIndicator?: string | null;
                        ExteriorWallType?: string | null;
                        FinishedBasement?: boolean | null;
                        FoundationType?: string | null;
                        GarageNumberOfCars?: number | null;
                        GarageType?: string | null;
                        KitchenCount?: string | null;
                        OutbuildingPresent?: string | null;
                        PlumbingType?: string | null;
                        PrimaryHeatingType?: string | null;
                        RoofType?: string | null;
                        SquareFootage?: number | null;
                        StoreyCount?: string | null;
                        SwimmingPoolType?: string | null;
                        YearBuilt?: string | null;
                        InspectionDetails?: {
                          __typename?: 'InspectionDetailsType';
                          InspectionDate?: any | null;
                          SponsoredInspection?: boolean | null;
                        } | null;
                      } | null;
                      MultiResidentialModifiers?: {
                        __typename?: 'MultiResidentialConstructionFeaturesType';
                        BasementPresent?: boolean | null;
                        ConstructionType?: string | null;
                        FloorLevel?: number | null;
                      } | null;
                      AverageAnnualLossValues?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                      AdditionalDetails: Array<{
                        __typename?: 'FloodDetailType';
                        Key?: string | null;
                        Value?: string | null;
                      }>;
                      Premium?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                    }>;
                    FloodMap: Array<{
                      __typename?: 'FloodMapType';
                      FloodEvent?: string | null;
                      Content?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                } | null;
                optaIclarifyCommercialConstructionData?: {
                  __typename?: 'OptaIclarifyCommercialConstructionDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Messages?: {
                    __typename?: 'Messages';
                    Message?: Array<string | null> | null;
                  } | null;
                  Property?: {
                    __typename?: 'CommercialPropertyType';
                    LotSize?: number | null;
                    LotSizeUnitOfMeasurement?: string | null;
                    PropertyType?: string | null;
                    Buildings?: {
                      __typename?: 'BuildingsType';
                      Building: Array<{
                        __typename?: 'BuildingType';
                        StructureID?: string | null;
                        ConstructionFeatures?: {
                          __typename?: 'CommercialConstructionFeaturesType';
                          CombustibilityClass?: string | null;
                          ConstructionClass?: string | null;
                          FloorHeight?: number | null;
                          FloorHeightUnitOfMeasurement?: string | null;
                          LoadingDockPresent?: boolean | null;
                          NumberOfStoreys?: number | null;
                          NumberOfUnits?: number | null;
                          TotalFloorArea?: number | null;
                          TotalFloorAreaUnitOfMeasurement?: string | null;
                          YearBuilt?: number | null;
                          Basement?: {
                            __typename?: 'CommercialBasementType';
                            BasementArea?: number | null;
                            BasementAreaUnitOfMeasurement?: string | null;
                            BasementPresent?: boolean | null;
                          } | null;
                          BuildingUpdates?: {
                            __typename?: 'BuildingUpdatesType';
                            BuildingUpdate?: Array<number | null> | null;
                          } | null;
                          CommercialExteriorWallTypes?: {
                            __typename?: 'CommercialExteriorWallTypesType';
                            ExteriorWallType?: Array<string | null> | null;
                          } | null;
                          Elevator?: {
                            __typename?: 'ElevatorType';
                            ElevatorCount?: number | null;
                            ElevatorPresent?: boolean | null;
                          } | null;
                          Heating?: {
                            __typename?: 'CommercialHeatingType';
                            HeatingFuelSources?: {
                              __typename?: 'HeatingFuelSourcesType';
                              HeatingFuelSource?: Array<string | null> | null;
                            } | null;
                            HeatingTypes?: {
                              __typename?: 'HeatingTypesType';
                              HeatingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          Parking?: {
                            __typename?: 'CommercialParkingType';
                            ParkingPresent?: boolean | null;
                            ParkingTypes?: {
                              __typename?: 'CommercialParkingTypesType';
                              ParkingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          PlumbingTypes?: {
                            __typename?: 'CommercialPlumbingType';
                            PlumbingType?: Array<string | null> | null;
                          } | null;
                          RoofAttributes?: {
                            __typename?: 'RoofAttributesType';
                            RoofConstructions?: {
                              __typename?: 'RoofConstructionsType';
                              RoofConstruction?: Array<string | null> | null;
                            } | null;
                            RoofSurfaces?: {
                              __typename?: 'RoofSurfacesType';
                              RoofSurface?: Array<string | null> | null;
                            } | null;
                            RoofTypes?: {
                              __typename?: 'RoofTypesType';
                              RoofType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          WaterHeater?: {
                            __typename?: 'CommercialWaterHeaterType';
                            WaterHeaterFuelType?: Array<string | null> | null;
                            WaterHeaterType?: Array<string | null> | null;
                          } | null;
                          Wiring?: {
                            __typename?: 'CommercialWiringType';
                            WiringPanelTypes?: {
                              __typename?: 'CommercialWiringPanelTypesType';
                              WiringPanelType?: Array<string | null> | null;
                            } | null;
                            WiringTypes?: {
                              __typename?: 'CommercialWiringTypesType';
                              WiringType?: Array<string | null> | null;
                            } | null;
                          } | null;
                        } | null;
                        Protection?: {
                          __typename?: 'ProtectionType';
                          FireAlarmPresent?: boolean | null;
                          FireAlarmType?: string | null;
                          PhysicalSecurityPresent?: boolean | null;
                          SecurityAlarmPresent?: boolean | null;
                          SecurityAlarmType?: string | null;
                          SprinklerPresent?: boolean | null;
                          SprinklerTypeCode?: string | null;
                          PhysicalSecurityTypes?: {
                            __typename?: 'PhysicalSecurityTypesType';
                            PhysicalSecurityType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                    Industry?: {
                      __typename?: 'BusinessIndustryType';
                      IBCCode?: string | null;
                      IBCIndustryDescription?: string | null;
                      SICIndustryDescription?: string | null;
                      SICCode?: string | null;
                    } | null;
                  } | null;
                } | null;
                optaCommercialPerilScoreData?: {
                  __typename?: 'OptaCommercialPerilScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  PropertyPerilScores?: {
                    __typename?: 'PropertyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  CasualtyPerilScores?: {
                    __typename?: 'CasualtyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  AllPerilsScore?: {
                    __typename?: 'CommercialPerilScoreType';
                    LossCostScore?: number | null;
                    PredictedLossCost?: number | null;
                  } | null;
                } | null;
              } | null;
              openingHours: Array<{
                __typename?: 'OpeningHoursType';
                day: number;
                open?: any | null;
                close?: any | null;
              }>;
            } | null;
          }>;
        }>;
        signature?: {
          __typename?: 'SignatureType';
          signatureData?: string | null;
          signatureTime?: any | null;
        } | null;
        bindings: Array<{
          __typename?: 'BindingType';
          label: string;
          bound: boolean;
          unboundReason: string;
        }>;
      }
    | {
        __typename?: 'QuoteType';
        id: string;
        uniqueID: any;
        friendlyId?: string | null;
        additionalInformation: string;
        producerId: string;
        QuoteStatus: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
        businessLine?: {
          __typename?: 'BusinessTypeType';
          id: string;
          displayName: string;
        } | null;
        structuredData?: {
          __typename?: 'StructuredQuoteDataType';
          businessLegalName?: string | null;
          businessName?: string | null;
          businessTradeName?: string | null;
          contactName?: string | null;
          contactEmail?: string | null;
          contactPhoneNumber?: string | null;
          revenue?: number | null;
          alreadyContactedBrokerName?: string | null;
          contactAddress?: {
            __typename?: 'AddressType';
            formattedAddress?: string | null;
          } | null;
        } | null;
        completedForms: Array<{
          __typename?: 'CompletedFormType';
          id: string;
          policy?: {
            __typename?: 'PolicyType';
            id: string;
            displayName: string;
            internalName: string;
          } | null;
          extensions?: Array<{
            __typename?: 'PolicyExtensionType';
            id: string;
            label: string;
            multiplier?: string | null;
            questionId?: string | null;
            value?: string | null;
          } | null> | null;
          answers: Array<{
            __typename?: 'AnswerInstanceType';
            id: string;
            apiName: string;
            displayName: string;
            label: string;
            helpText?: string | null;
            component: string;
            propsBlob?: any | null;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            value: string;
            date: any;
            method?: string | null;
            dataType: string;
            defaultValue?: string | null;
            subAnswers: Array<{
              __typename?: 'AnswerInstanceType';
              id: string;
              apiName: string;
              displayName: string;
              label: string;
              helpText?: string | null;
              component: string;
              propsBlob?: any | null;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              value: string;
              date: any;
              method?: string | null;
              dataType: string;
              defaultValue?: string | null;
              subAnswers: Array<{
                __typename?: 'AnswerInstanceType';
                id: string;
                apiName: string;
                displayName: string;
                label: string;
                helpText?: string | null;
                component: string;
                propsBlob?: any | null;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                value: string;
                date: any;
                method?: string | null;
                dataType: string;
                defaultValue?: string | null;
                subAnswers: Array<{
                  __typename?: 'AnswerInstanceType';
                  id: string;
                  apiName: string;
                  displayName: string;
                  label: string;
                  helpText?: string | null;
                  component: string;
                  propsBlob?: any | null;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  value: string;
                  date: any;
                  method?: string | null;
                  dataType: string;
                  defaultValue?: string | null;
                  subAnswers: Array<{
                    __typename?: 'AnswerInstanceType';
                    id: string;
                    apiName: string;
                    displayName: string;
                    label: string;
                    helpText?: string | null;
                    component: string;
                    propsBlob?: any | null;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    value: string;
                    date: any;
                    method?: string | null;
                    dataType: string;
                    defaultValue?: string | null;
                    subAnswers: Array<{
                      __typename?: 'AnswerInstanceType';
                      id: string;
                      apiName: string;
                      displayName: string;
                      label: string;
                      helpText?: string | null;
                      component: string;
                      propsBlob?: any | null;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      value: string;
                      date: any;
                      method?: string | null;
                      dataType: string;
                      defaultValue?: string | null;
                      subAnswers: Array<{
                        __typename?: 'AnswerInstanceType';
                        id: string;
                        apiName: string;
                        displayName: string;
                        label: string;
                        helpText?: string | null;
                        component: string;
                        propsBlob?: any | null;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        value: string;
                        date: any;
                        method?: string | null;
                        dataType: string;
                        defaultValue?: string | null;
                        subAnswers: Array<{
                          __typename?: 'AnswerInstanceType';
                          id: string;
                          apiName: string;
                          displayName: string;
                          label: string;
                          helpText?: string | null;
                          component: string;
                          propsBlob?: any | null;
                          required: boolean;
                          askOnRenewal: boolean;
                          prefillOnRenewal: boolean;
                          value: string;
                          date: any;
                          method?: string | null;
                          dataType: string;
                          defaultValue?: string | null;
                          subAnswers: Array<{
                            __typename?: 'AnswerInstanceType';
                            id: string;
                            apiName: string;
                            displayName: string;
                            label: string;
                            helpText?: string | null;
                            component: string;
                            propsBlob?: any | null;
                            required: boolean;
                            askOnRenewal: boolean;
                            prefillOnRenewal: boolean;
                            value: string;
                            date: any;
                            method?: string | null;
                            dataType: string;
                            defaultValue?: string | null;
                            subAnswers: Array<{
                              __typename?: 'AnswerInstanceType';
                              id: string;
                              apiName: string;
                              displayName: string;
                              label: string;
                              helpText?: string | null;
                              component: string;
                              propsBlob?: any | null;
                              required: boolean;
                              askOnRenewal: boolean;
                              prefillOnRenewal: boolean;
                              value: string;
                              date: any;
                              method?: string | null;
                              dataType: string;
                              defaultValue?: string | null;
                              details?: {
                                __typename?: 'AnswerInstanceDetailsType';
                                lat?: number | null;
                                lng?: number | null;
                                images: Array<{
                                  __typename?: 'GoogleImageType';
                                  description: string;
                                  shortDescription: string;
                                  category: CoreGoogleImageCategoryChoices;
                                  data?: string | null;
                                }>;
                                google?: {
                                  __typename?: 'GoogleDetailsType';
                                  placeId?: string | null;
                                  rating?: number | null;
                                  numberOfRatings?: number | null;
                                } | null;
                                opta?: {
                                  __typename?: 'OPTADetailsType';
                                  optaFusData?: {
                                    __typename?: 'OptaFusDataType';
                                    Address?: {
                                      __typename?: 'OptaAddressType';
                                      StreetAddress?: string | null;
                                      StreetNumber?: string | null;
                                      StreetName?: string | null;
                                      StreetType?: string | null;
                                      StreetDirection?: string | null;
                                      UnitNumber?: string | null;
                                      Municipality?: string | null;
                                      Province?: string | null;
                                      PostalCode?: string | null;
                                      LegalDescription?: {
                                        __typename?: 'LegalDescriptionType';
                                        Quarter?: string | null;
                                        LegalSubdivision?: string | null;
                                        Section?: string | null;
                                        Township?: string | null;
                                        Range?: string | null;
                                        Meridian?: string | null;
                                      } | null;
                                    } | null;
                                    Commercial?: {
                                      __typename?: 'FusProductCommercialType';
                                      SearchMessage?: string | null;
                                      CommercialGrade: Array<{
                                        __typename?: 'CommercialGradeType';
                                        PublicFireProtectionClassification?:
                                          | number
                                          | null;
                                        WaterSystem?: string | null;
                                        RiskSCORDetail: Array<{
                                          __typename?: 'RiskSCORDetailType';
                                          RiskSCOR?: number | null;
                                          Type?: string | null;
                                          Adjustment: Array<{
                                            __typename?: 'RiskSCORAdjustmentType';
                                            Adjustment?: string | null;
                                            AdjustmentType?: string | null;
                                          }>;
                                        }>;
                                      }>;
                                      RespondingFirehall?: {
                                        __typename?: 'FirehallType';
                                        Address?: string | null;
                                        GoverningAgency?: string | null;
                                        GradingDate?: any | null;
                                        Name?: string | null;
                                        PumperFlowImperialGallonsPerMinute?:
                                          | number
                                          | null;
                                        RoadDistanceKilometres?: number | null;
                                        SuperiorTankerServiceAccreditationDate?:
                                          | any
                                          | null;
                                        FirefightersPerFireStation?: {
                                          __typename?: 'FirefightersPerFireStationType';
                                          FullTime?: number | null;
                                          Volunteer?: number | null;
                                        } | null;
                                        ProtectionGradeTerritories?: {
                                          __typename?: 'ProtectedGradeTerritoriesType';
                                          Commercial?: string | null;
                                          Dwelling?: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    Dwelling?: {
                                      __typename?: 'FusProductDwellingType';
                                      SearchMessage?: string | null;
                                      DwellingGrades?: {
                                        __typename?: 'DwellingGradesType';
                                        AllOtherAreasGrade: Array<{
                                          __typename?: 'DwellingProtectedGradeType';
                                          Grade?: string | null;
                                          WaterSystem?: string | null;
                                        }>;
                                        FirehallProtectedGrade: Array<{
                                          __typename?: 'DwellingProtectedGradeType';
                                          Grade?: string | null;
                                          WaterSystem?: string | null;
                                        }>;
                                        HydrantProtectedGrade: Array<{
                                          __typename?: 'DwellingProtectedGradeType';
                                          Grade?: string | null;
                                          WaterSystem?: string | null;
                                        }>;
                                      } | null;
                                      RespondingFirehall?: {
                                        __typename?: 'FirehallType';
                                        Address?: string | null;
                                        GoverningAgency?: string | null;
                                        GradingDate?: any | null;
                                        Name?: string | null;
                                        PumperFlowImperialGallonsPerMinute?:
                                          | number
                                          | null;
                                        RoadDistanceKilometres?: number | null;
                                        SuperiorTankerServiceAccreditationDate?:
                                          | any
                                          | null;
                                        FirefightersPerFireStation?: {
                                          __typename?: 'FirefightersPerFireStationType';
                                          FullTime?: number | null;
                                          Volunteer?: number | null;
                                        } | null;
                                        ProtectionGradeTerritories?: {
                                          __typename?: 'ProtectedGradeTerritoriesType';
                                          Commercial?: string | null;
                                          Dwelling?: string | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    EarthquakeCodes?: {
                                      __typename?: 'EarthquakeCodesType';
                                      SearchMessage?: string | null;
                                      EarthquakeCode: Array<{
                                        __typename?: 'EarthquakeCodeType';
                                        Description?: string | null;
                                        SystemName?: string | null;
                                        Zone?: string | null;
                                      }>;
                                    } | null;
                                    FusMaps?: {
                                      __typename?: 'FusMapsType';
                                      SearchMessage?: string | null;
                                      FusMap: Array<{
                                        __typename?: 'FusMapType';
                                        Business?: string | null;
                                        FusContent?: {
                                          __typename?: 'ContentType';
                                          ContentURL?: string | null;
                                          Expiry?: any | null;
                                          MIMEType?: string | null;
                                        } | null;
                                      }>;
                                    } | null;
                                    HydrantCounts?: {
                                      __typename?: 'HydrantCountsType';
                                      SearchMessage?: string | null;
                                      HydrantCount: Array<{
                                        __typename?: 'HydrantCountType';
                                        NumberOfHydrants: number;
                                        Ownership?: string | null;
                                        RadiusMetres?: string | null;
                                      }>;
                                    } | null;
                                  } | null;
                                  optaFloodscoreData?: {
                                    __typename?: 'OptaFloodScoreDataType';
                                    Address?: {
                                      __typename?: 'OptaAddressType';
                                      StreetAddress?: string | null;
                                      StreetNumber?: string | null;
                                      StreetName?: string | null;
                                      StreetType?: string | null;
                                      StreetDirection?: string | null;
                                      UnitNumber?: string | null;
                                      Municipality?: string | null;
                                      Province?: string | null;
                                      PostalCode?: string | null;
                                      LegalDescription?: {
                                        __typename?: 'LegalDescriptionType';
                                        Quarter?: string | null;
                                        LegalSubdivision?: string | null;
                                        Section?: string | null;
                                        Township?: string | null;
                                        Range?: string | null;
                                        Meridian?: string | null;
                                      } | null;
                                    } | null;
                                    FloodScores?: {
                                      __typename?: 'FloodScoresType';
                                      FloodScore: Array<{
                                        __typename?: 'FloodScoreType';
                                        AverageAnnualLossScore?: number | null;
                                        Deductible?: number | null;
                                        Default?: boolean | null;
                                        FloodScore?: number | null;
                                        Limit?: number | null;
                                        RateFactor?: number | null;
                                        Zone?: string | null;
                                        ResidentialModifiers?: {
                                          __typename?: 'ResidentialConstructionFeaturesType';
                                          ArchitecturalStyleType?:
                                            | string
                                            | null;
                                          AuxiliaryHeatingType?: string | null;
                                          BathroomCount?: string | null;
                                          CommercialIndicator?: string | null;
                                          ExteriorWallType?: string | null;
                                          FinishedBasement?: boolean | null;
                                          FoundationType?: string | null;
                                          GarageNumberOfCars?: number | null;
                                          GarageType?: string | null;
                                          KitchenCount?: string | null;
                                          OutbuildingPresent?: string | null;
                                          PlumbingType?: string | null;
                                          PrimaryHeatingType?: string | null;
                                          RoofType?: string | null;
                                          SquareFootage?: number | null;
                                          StoreyCount?: string | null;
                                          SwimmingPoolType?: string | null;
                                          YearBuilt?: string | null;
                                          InspectionDetails?: {
                                            __typename?: 'InspectionDetailsType';
                                            InspectionDate?: any | null;
                                            SponsoredInspection?:
                                              | boolean
                                              | null;
                                          } | null;
                                        } | null;
                                        MultiResidentialModifiers?: {
                                          __typename?: 'MultiResidentialConstructionFeaturesType';
                                          BasementPresent?: boolean | null;
                                          ConstructionType?: string | null;
                                          FloorLevel?: number | null;
                                        } | null;
                                        AverageAnnualLossValues?: {
                                          __typename?: 'FloodValueType';
                                          Building?: number | null;
                                          Contents?: number | null;
                                          Total?: number | null;
                                        } | null;
                                        AdditionalDetails: Array<{
                                          __typename?: 'FloodDetailType';
                                          Key?: string | null;
                                          Value?: string | null;
                                        }>;
                                        Premium?: {
                                          __typename?: 'FloodValueType';
                                          Building?: number | null;
                                          Contents?: number | null;
                                          Total?: number | null;
                                        } | null;
                                      }>;
                                      FloodMap: Array<{
                                        __typename?: 'FloodMapType';
                                        FloodEvent?: string | null;
                                        Content?: {
                                          __typename?: 'ContentType';
                                          ContentURL?: string | null;
                                          Expiry?: any | null;
                                          MIMEType?: string | null;
                                        } | null;
                                      }>;
                                    } | null;
                                  } | null;
                                  optaIclarifyCommercialConstructionData?: {
                                    __typename?: 'OptaIclarifyCommercialConstructionDataType';
                                    Address?: {
                                      __typename?: 'OptaAddressType';
                                      StreetAddress?: string | null;
                                      StreetNumber?: string | null;
                                      StreetName?: string | null;
                                      StreetType?: string | null;
                                      StreetDirection?: string | null;
                                      UnitNumber?: string | null;
                                      Municipality?: string | null;
                                      Province?: string | null;
                                      PostalCode?: string | null;
                                      LegalDescription?: {
                                        __typename?: 'LegalDescriptionType';
                                        Quarter?: string | null;
                                        LegalSubdivision?: string | null;
                                        Section?: string | null;
                                        Township?: string | null;
                                        Range?: string | null;
                                        Meridian?: string | null;
                                      } | null;
                                    } | null;
                                    Messages?: {
                                      __typename?: 'Messages';
                                      Message?: Array<string | null> | null;
                                    } | null;
                                    Property?: {
                                      __typename?: 'CommercialPropertyType';
                                      LotSize?: number | null;
                                      LotSizeUnitOfMeasurement?: string | null;
                                      PropertyType?: string | null;
                                      Buildings?: {
                                        __typename?: 'BuildingsType';
                                        Building: Array<{
                                          __typename?: 'BuildingType';
                                          StructureID?: string | null;
                                          ConstructionFeatures?: {
                                            __typename?: 'CommercialConstructionFeaturesType';
                                            CombustibilityClass?: string | null;
                                            ConstructionClass?: string | null;
                                            FloorHeight?: number | null;
                                            FloorHeightUnitOfMeasurement?:
                                              | string
                                              | null;
                                            LoadingDockPresent?: boolean | null;
                                            NumberOfStoreys?: number | null;
                                            NumberOfUnits?: number | null;
                                            TotalFloorArea?: number | null;
                                            TotalFloorAreaUnitOfMeasurement?:
                                              | string
                                              | null;
                                            YearBuilt?: number | null;
                                            Basement?: {
                                              __typename?: 'CommercialBasementType';
                                              BasementArea?: number | null;
                                              BasementAreaUnitOfMeasurement?:
                                                | string
                                                | null;
                                              BasementPresent?: boolean | null;
                                            } | null;
                                            BuildingUpdates?: {
                                              __typename?: 'BuildingUpdatesType';
                                              BuildingUpdate?: Array<
                                                number | null
                                              > | null;
                                            } | null;
                                            CommercialExteriorWallTypes?: {
                                              __typename?: 'CommercialExteriorWallTypesType';
                                              ExteriorWallType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            Elevator?: {
                                              __typename?: 'ElevatorType';
                                              ElevatorCount?: number | null;
                                              ElevatorPresent?: boolean | null;
                                            } | null;
                                            Heating?: {
                                              __typename?: 'CommercialHeatingType';
                                              HeatingFuelSources?: {
                                                __typename?: 'HeatingFuelSourcesType';
                                                HeatingFuelSource?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                              HeatingTypes?: {
                                                __typename?: 'HeatingTypesType';
                                                HeatingType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                            } | null;
                                            Parking?: {
                                              __typename?: 'CommercialParkingType';
                                              ParkingPresent?: boolean | null;
                                              ParkingTypes?: {
                                                __typename?: 'CommercialParkingTypesType';
                                                ParkingType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                            } | null;
                                            PlumbingTypes?: {
                                              __typename?: 'CommercialPlumbingType';
                                              PlumbingType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            RoofAttributes?: {
                                              __typename?: 'RoofAttributesType';
                                              RoofConstructions?: {
                                                __typename?: 'RoofConstructionsType';
                                                RoofConstruction?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                              RoofSurfaces?: {
                                                __typename?: 'RoofSurfacesType';
                                                RoofSurface?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                              RoofTypes?: {
                                                __typename?: 'RoofTypesType';
                                                RoofType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                            } | null;
                                            WaterHeater?: {
                                              __typename?: 'CommercialWaterHeaterType';
                                              WaterHeaterFuelType?: Array<
                                                string | null
                                              > | null;
                                              WaterHeaterType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            Wiring?: {
                                              __typename?: 'CommercialWiringType';
                                              WiringPanelTypes?: {
                                                __typename?: 'CommercialWiringPanelTypesType';
                                                WiringPanelType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                              WiringTypes?: {
                                                __typename?: 'CommercialWiringTypesType';
                                                WiringType?: Array<
                                                  string | null
                                                > | null;
                                              } | null;
                                            } | null;
                                          } | null;
                                          Protection?: {
                                            __typename?: 'ProtectionType';
                                            FireAlarmPresent?: boolean | null;
                                            FireAlarmType?: string | null;
                                            PhysicalSecurityPresent?:
                                              | boolean
                                              | null;
                                            SecurityAlarmPresent?:
                                              | boolean
                                              | null;
                                            SecurityAlarmType?: string | null;
                                            SprinklerPresent?: boolean | null;
                                            SprinklerTypeCode?: string | null;
                                            PhysicalSecurityTypes?: {
                                              __typename?: 'PhysicalSecurityTypesType';
                                              PhysicalSecurityType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                        }>;
                                      } | null;
                                      Industry?: {
                                        __typename?: 'BusinessIndustryType';
                                        IBCCode?: string | null;
                                        IBCIndustryDescription?: string | null;
                                        SICIndustryDescription?: string | null;
                                        SICCode?: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  optaCommercialPerilScoreData?: {
                                    __typename?: 'OptaCommercialPerilScoreDataType';
                                    Address?: {
                                      __typename?: 'OptaAddressType';
                                      StreetAddress?: string | null;
                                      StreetNumber?: string | null;
                                      StreetName?: string | null;
                                      StreetType?: string | null;
                                      StreetDirection?: string | null;
                                      UnitNumber?: string | null;
                                      Municipality?: string | null;
                                      Province?: string | null;
                                      PostalCode?: string | null;
                                      LegalDescription?: {
                                        __typename?: 'LegalDescriptionType';
                                        Quarter?: string | null;
                                        LegalSubdivision?: string | null;
                                        Section?: string | null;
                                        Township?: string | null;
                                        Range?: string | null;
                                        Meridian?: string | null;
                                      } | null;
                                    } | null;
                                    PropertyPerilScores?: {
                                      __typename?: 'PropertyCommercialPerilsType';
                                      Peril?: Array<{
                                        __typename?: 'PerilDetailsType';
                                        LossCostScore?: number | null;
                                        PerilType?: string | null;
                                        PredictedLossCost?: number | null;
                                      } | null> | null;
                                    } | null;
                                    CasualtyPerilScores?: {
                                      __typename?: 'CasualtyCommercialPerilsType';
                                      Peril?: Array<{
                                        __typename?: 'PerilDetailsType';
                                        LossCostScore?: number | null;
                                        PerilType?: string | null;
                                        PredictedLossCost?: number | null;
                                      } | null> | null;
                                    } | null;
                                    AllPerilsScore?: {
                                      __typename?: 'CommercialPerilScoreType';
                                      LossCostScore?: number | null;
                                      PredictedLossCost?: number | null;
                                    } | null;
                                  } | null;
                                } | null;
                                openingHours: Array<{
                                  __typename?: 'OpeningHoursType';
                                  day: number;
                                  open?: any | null;
                                  close?: any | null;
                                }>;
                              } | null;
                            }>;
                            details?: {
                              __typename?: 'AnswerInstanceDetailsType';
                              lat?: number | null;
                              lng?: number | null;
                              images: Array<{
                                __typename?: 'GoogleImageType';
                                description: string;
                                shortDescription: string;
                                category: CoreGoogleImageCategoryChoices;
                                data?: string | null;
                              }>;
                              google?: {
                                __typename?: 'GoogleDetailsType';
                                placeId?: string | null;
                                rating?: number | null;
                                numberOfRatings?: number | null;
                              } | null;
                              opta?: {
                                __typename?: 'OPTADetailsType';
                                optaFusData?: {
                                  __typename?: 'OptaFusDataType';
                                  Address?: {
                                    __typename?: 'OptaAddressType';
                                    StreetAddress?: string | null;
                                    StreetNumber?: string | null;
                                    StreetName?: string | null;
                                    StreetType?: string | null;
                                    StreetDirection?: string | null;
                                    UnitNumber?: string | null;
                                    Municipality?: string | null;
                                    Province?: string | null;
                                    PostalCode?: string | null;
                                    LegalDescription?: {
                                      __typename?: 'LegalDescriptionType';
                                      Quarter?: string | null;
                                      LegalSubdivision?: string | null;
                                      Section?: string | null;
                                      Township?: string | null;
                                      Range?: string | null;
                                      Meridian?: string | null;
                                    } | null;
                                  } | null;
                                  Commercial?: {
                                    __typename?: 'FusProductCommercialType';
                                    SearchMessage?: string | null;
                                    CommercialGrade: Array<{
                                      __typename?: 'CommercialGradeType';
                                      PublicFireProtectionClassification?:
                                        | number
                                        | null;
                                      WaterSystem?: string | null;
                                      RiskSCORDetail: Array<{
                                        __typename?: 'RiskSCORDetailType';
                                        RiskSCOR?: number | null;
                                        Type?: string | null;
                                        Adjustment: Array<{
                                          __typename?: 'RiskSCORAdjustmentType';
                                          Adjustment?: string | null;
                                          AdjustmentType?: string | null;
                                        }>;
                                      }>;
                                    }>;
                                    RespondingFirehall?: {
                                      __typename?: 'FirehallType';
                                      Address?: string | null;
                                      GoverningAgency?: string | null;
                                      GradingDate?: any | null;
                                      Name?: string | null;
                                      PumperFlowImperialGallonsPerMinute?:
                                        | number
                                        | null;
                                      RoadDistanceKilometres?: number | null;
                                      SuperiorTankerServiceAccreditationDate?:
                                        | any
                                        | null;
                                      FirefightersPerFireStation?: {
                                        __typename?: 'FirefightersPerFireStationType';
                                        FullTime?: number | null;
                                        Volunteer?: number | null;
                                      } | null;
                                      ProtectionGradeTerritories?: {
                                        __typename?: 'ProtectedGradeTerritoriesType';
                                        Commercial?: string | null;
                                        Dwelling?: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  Dwelling?: {
                                    __typename?: 'FusProductDwellingType';
                                    SearchMessage?: string | null;
                                    DwellingGrades?: {
                                      __typename?: 'DwellingGradesType';
                                      AllOtherAreasGrade: Array<{
                                        __typename?: 'DwellingProtectedGradeType';
                                        Grade?: string | null;
                                        WaterSystem?: string | null;
                                      }>;
                                      FirehallProtectedGrade: Array<{
                                        __typename?: 'DwellingProtectedGradeType';
                                        Grade?: string | null;
                                        WaterSystem?: string | null;
                                      }>;
                                      HydrantProtectedGrade: Array<{
                                        __typename?: 'DwellingProtectedGradeType';
                                        Grade?: string | null;
                                        WaterSystem?: string | null;
                                      }>;
                                    } | null;
                                    RespondingFirehall?: {
                                      __typename?: 'FirehallType';
                                      Address?: string | null;
                                      GoverningAgency?: string | null;
                                      GradingDate?: any | null;
                                      Name?: string | null;
                                      PumperFlowImperialGallonsPerMinute?:
                                        | number
                                        | null;
                                      RoadDistanceKilometres?: number | null;
                                      SuperiorTankerServiceAccreditationDate?:
                                        | any
                                        | null;
                                      FirefightersPerFireStation?: {
                                        __typename?: 'FirefightersPerFireStationType';
                                        FullTime?: number | null;
                                        Volunteer?: number | null;
                                      } | null;
                                      ProtectionGradeTerritories?: {
                                        __typename?: 'ProtectedGradeTerritoriesType';
                                        Commercial?: string | null;
                                        Dwelling?: string | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  EarthquakeCodes?: {
                                    __typename?: 'EarthquakeCodesType';
                                    SearchMessage?: string | null;
                                    EarthquakeCode: Array<{
                                      __typename?: 'EarthquakeCodeType';
                                      Description?: string | null;
                                      SystemName?: string | null;
                                      Zone?: string | null;
                                    }>;
                                  } | null;
                                  FusMaps?: {
                                    __typename?: 'FusMapsType';
                                    SearchMessage?: string | null;
                                    FusMap: Array<{
                                      __typename?: 'FusMapType';
                                      Business?: string | null;
                                      FusContent?: {
                                        __typename?: 'ContentType';
                                        ContentURL?: string | null;
                                        Expiry?: any | null;
                                        MIMEType?: string | null;
                                      } | null;
                                    }>;
                                  } | null;
                                  HydrantCounts?: {
                                    __typename?: 'HydrantCountsType';
                                    SearchMessage?: string | null;
                                    HydrantCount: Array<{
                                      __typename?: 'HydrantCountType';
                                      NumberOfHydrants: number;
                                      Ownership?: string | null;
                                      RadiusMetres?: string | null;
                                    }>;
                                  } | null;
                                } | null;
                                optaFloodscoreData?: {
                                  __typename?: 'OptaFloodScoreDataType';
                                  Address?: {
                                    __typename?: 'OptaAddressType';
                                    StreetAddress?: string | null;
                                    StreetNumber?: string | null;
                                    StreetName?: string | null;
                                    StreetType?: string | null;
                                    StreetDirection?: string | null;
                                    UnitNumber?: string | null;
                                    Municipality?: string | null;
                                    Province?: string | null;
                                    PostalCode?: string | null;
                                    LegalDescription?: {
                                      __typename?: 'LegalDescriptionType';
                                      Quarter?: string | null;
                                      LegalSubdivision?: string | null;
                                      Section?: string | null;
                                      Township?: string | null;
                                      Range?: string | null;
                                      Meridian?: string | null;
                                    } | null;
                                  } | null;
                                  FloodScores?: {
                                    __typename?: 'FloodScoresType';
                                    FloodScore: Array<{
                                      __typename?: 'FloodScoreType';
                                      AverageAnnualLossScore?: number | null;
                                      Deductible?: number | null;
                                      Default?: boolean | null;
                                      FloodScore?: number | null;
                                      Limit?: number | null;
                                      RateFactor?: number | null;
                                      Zone?: string | null;
                                      ResidentialModifiers?: {
                                        __typename?: 'ResidentialConstructionFeaturesType';
                                        ArchitecturalStyleType?: string | null;
                                        AuxiliaryHeatingType?: string | null;
                                        BathroomCount?: string | null;
                                        CommercialIndicator?: string | null;
                                        ExteriorWallType?: string | null;
                                        FinishedBasement?: boolean | null;
                                        FoundationType?: string | null;
                                        GarageNumberOfCars?: number | null;
                                        GarageType?: string | null;
                                        KitchenCount?: string | null;
                                        OutbuildingPresent?: string | null;
                                        PlumbingType?: string | null;
                                        PrimaryHeatingType?: string | null;
                                        RoofType?: string | null;
                                        SquareFootage?: number | null;
                                        StoreyCount?: string | null;
                                        SwimmingPoolType?: string | null;
                                        YearBuilt?: string | null;
                                        InspectionDetails?: {
                                          __typename?: 'InspectionDetailsType';
                                          InspectionDate?: any | null;
                                          SponsoredInspection?: boolean | null;
                                        } | null;
                                      } | null;
                                      MultiResidentialModifiers?: {
                                        __typename?: 'MultiResidentialConstructionFeaturesType';
                                        BasementPresent?: boolean | null;
                                        ConstructionType?: string | null;
                                        FloorLevel?: number | null;
                                      } | null;
                                      AverageAnnualLossValues?: {
                                        __typename?: 'FloodValueType';
                                        Building?: number | null;
                                        Contents?: number | null;
                                        Total?: number | null;
                                      } | null;
                                      AdditionalDetails: Array<{
                                        __typename?: 'FloodDetailType';
                                        Key?: string | null;
                                        Value?: string | null;
                                      }>;
                                      Premium?: {
                                        __typename?: 'FloodValueType';
                                        Building?: number | null;
                                        Contents?: number | null;
                                        Total?: number | null;
                                      } | null;
                                    }>;
                                    FloodMap: Array<{
                                      __typename?: 'FloodMapType';
                                      FloodEvent?: string | null;
                                      Content?: {
                                        __typename?: 'ContentType';
                                        ContentURL?: string | null;
                                        Expiry?: any | null;
                                        MIMEType?: string | null;
                                      } | null;
                                    }>;
                                  } | null;
                                } | null;
                                optaIclarifyCommercialConstructionData?: {
                                  __typename?: 'OptaIclarifyCommercialConstructionDataType';
                                  Address?: {
                                    __typename?: 'OptaAddressType';
                                    StreetAddress?: string | null;
                                    StreetNumber?: string | null;
                                    StreetName?: string | null;
                                    StreetType?: string | null;
                                    StreetDirection?: string | null;
                                    UnitNumber?: string | null;
                                    Municipality?: string | null;
                                    Province?: string | null;
                                    PostalCode?: string | null;
                                    LegalDescription?: {
                                      __typename?: 'LegalDescriptionType';
                                      Quarter?: string | null;
                                      LegalSubdivision?: string | null;
                                      Section?: string | null;
                                      Township?: string | null;
                                      Range?: string | null;
                                      Meridian?: string | null;
                                    } | null;
                                  } | null;
                                  Messages?: {
                                    __typename?: 'Messages';
                                    Message?: Array<string | null> | null;
                                  } | null;
                                  Property?: {
                                    __typename?: 'CommercialPropertyType';
                                    LotSize?: number | null;
                                    LotSizeUnitOfMeasurement?: string | null;
                                    PropertyType?: string | null;
                                    Buildings?: {
                                      __typename?: 'BuildingsType';
                                      Building: Array<{
                                        __typename?: 'BuildingType';
                                        StructureID?: string | null;
                                        ConstructionFeatures?: {
                                          __typename?: 'CommercialConstructionFeaturesType';
                                          CombustibilityClass?: string | null;
                                          ConstructionClass?: string | null;
                                          FloorHeight?: number | null;
                                          FloorHeightUnitOfMeasurement?:
                                            | string
                                            | null;
                                          LoadingDockPresent?: boolean | null;
                                          NumberOfStoreys?: number | null;
                                          NumberOfUnits?: number | null;
                                          TotalFloorArea?: number | null;
                                          TotalFloorAreaUnitOfMeasurement?:
                                            | string
                                            | null;
                                          YearBuilt?: number | null;
                                          Basement?: {
                                            __typename?: 'CommercialBasementType';
                                            BasementArea?: number | null;
                                            BasementAreaUnitOfMeasurement?:
                                              | string
                                              | null;
                                            BasementPresent?: boolean | null;
                                          } | null;
                                          BuildingUpdates?: {
                                            __typename?: 'BuildingUpdatesType';
                                            BuildingUpdate?: Array<
                                              number | null
                                            > | null;
                                          } | null;
                                          CommercialExteriorWallTypes?: {
                                            __typename?: 'CommercialExteriorWallTypesType';
                                            ExteriorWallType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          Elevator?: {
                                            __typename?: 'ElevatorType';
                                            ElevatorCount?: number | null;
                                            ElevatorPresent?: boolean | null;
                                          } | null;
                                          Heating?: {
                                            __typename?: 'CommercialHeatingType';
                                            HeatingFuelSources?: {
                                              __typename?: 'HeatingFuelSourcesType';
                                              HeatingFuelSource?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            HeatingTypes?: {
                                              __typename?: 'HeatingTypesType';
                                              HeatingType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                          Parking?: {
                                            __typename?: 'CommercialParkingType';
                                            ParkingPresent?: boolean | null;
                                            ParkingTypes?: {
                                              __typename?: 'CommercialParkingTypesType';
                                              ParkingType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                          PlumbingTypes?: {
                                            __typename?: 'CommercialPlumbingType';
                                            PlumbingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofAttributes?: {
                                            __typename?: 'RoofAttributesType';
                                            RoofConstructions?: {
                                              __typename?: 'RoofConstructionsType';
                                              RoofConstruction?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            RoofSurfaces?: {
                                              __typename?: 'RoofSurfacesType';
                                              RoofSurface?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            RoofTypes?: {
                                              __typename?: 'RoofTypesType';
                                              RoofType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                          WaterHeater?: {
                                            __typename?: 'CommercialWaterHeaterType';
                                            WaterHeaterFuelType?: Array<
                                              string | null
                                            > | null;
                                            WaterHeaterType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          Wiring?: {
                                            __typename?: 'CommercialWiringType';
                                            WiringPanelTypes?: {
                                              __typename?: 'CommercialWiringPanelTypesType';
                                              WiringPanelType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                            WiringTypes?: {
                                              __typename?: 'CommercialWiringTypesType';
                                              WiringType?: Array<
                                                string | null
                                              > | null;
                                            } | null;
                                          } | null;
                                        } | null;
                                        Protection?: {
                                          __typename?: 'ProtectionType';
                                          FireAlarmPresent?: boolean | null;
                                          FireAlarmType?: string | null;
                                          PhysicalSecurityPresent?:
                                            | boolean
                                            | null;
                                          SecurityAlarmPresent?: boolean | null;
                                          SecurityAlarmType?: string | null;
                                          SprinklerPresent?: boolean | null;
                                          SprinklerTypeCode?: string | null;
                                          PhysicalSecurityTypes?: {
                                            __typename?: 'PhysicalSecurityTypesType';
                                            PhysicalSecurityType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                      }>;
                                    } | null;
                                    Industry?: {
                                      __typename?: 'BusinessIndustryType';
                                      IBCCode?: string | null;
                                      IBCIndustryDescription?: string | null;
                                      SICIndustryDescription?: string | null;
                                      SICCode?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                optaCommercialPerilScoreData?: {
                                  __typename?: 'OptaCommercialPerilScoreDataType';
                                  Address?: {
                                    __typename?: 'OptaAddressType';
                                    StreetAddress?: string | null;
                                    StreetNumber?: string | null;
                                    StreetName?: string | null;
                                    StreetType?: string | null;
                                    StreetDirection?: string | null;
                                    UnitNumber?: string | null;
                                    Municipality?: string | null;
                                    Province?: string | null;
                                    PostalCode?: string | null;
                                    LegalDescription?: {
                                      __typename?: 'LegalDescriptionType';
                                      Quarter?: string | null;
                                      LegalSubdivision?: string | null;
                                      Section?: string | null;
                                      Township?: string | null;
                                      Range?: string | null;
                                      Meridian?: string | null;
                                    } | null;
                                  } | null;
                                  PropertyPerilScores?: {
                                    __typename?: 'PropertyCommercialPerilsType';
                                    Peril?: Array<{
                                      __typename?: 'PerilDetailsType';
                                      LossCostScore?: number | null;
                                      PerilType?: string | null;
                                      PredictedLossCost?: number | null;
                                    } | null> | null;
                                  } | null;
                                  CasualtyPerilScores?: {
                                    __typename?: 'CasualtyCommercialPerilsType';
                                    Peril?: Array<{
                                      __typename?: 'PerilDetailsType';
                                      LossCostScore?: number | null;
                                      PerilType?: string | null;
                                      PredictedLossCost?: number | null;
                                    } | null> | null;
                                  } | null;
                                  AllPerilsScore?: {
                                    __typename?: 'CommercialPerilScoreType';
                                    LossCostScore?: number | null;
                                    PredictedLossCost?: number | null;
                                  } | null;
                                } | null;
                              } | null;
                              openingHours: Array<{
                                __typename?: 'OpeningHoursType';
                                day: number;
                                open?: any | null;
                                close?: any | null;
                              }>;
                            } | null;
                          }>;
                          details?: {
                            __typename?: 'AnswerInstanceDetailsType';
                            lat?: number | null;
                            lng?: number | null;
                            images: Array<{
                              __typename?: 'GoogleImageType';
                              description: string;
                              shortDescription: string;
                              category: CoreGoogleImageCategoryChoices;
                              data?: string | null;
                            }>;
                            google?: {
                              __typename?: 'GoogleDetailsType';
                              placeId?: string | null;
                              rating?: number | null;
                              numberOfRatings?: number | null;
                            } | null;
                            opta?: {
                              __typename?: 'OPTADetailsType';
                              optaFusData?: {
                                __typename?: 'OptaFusDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                Commercial?: {
                                  __typename?: 'FusProductCommercialType';
                                  SearchMessage?: string | null;
                                  CommercialGrade: Array<{
                                    __typename?: 'CommercialGradeType';
                                    PublicFireProtectionClassification?:
                                      | number
                                      | null;
                                    WaterSystem?: string | null;
                                    RiskSCORDetail: Array<{
                                      __typename?: 'RiskSCORDetailType';
                                      RiskSCOR?: number | null;
                                      Type?: string | null;
                                      Adjustment: Array<{
                                        __typename?: 'RiskSCORAdjustmentType';
                                        Adjustment?: string | null;
                                        AdjustmentType?: string | null;
                                      }>;
                                    }>;
                                  }>;
                                  RespondingFirehall?: {
                                    __typename?: 'FirehallType';
                                    Address?: string | null;
                                    GoverningAgency?: string | null;
                                    GradingDate?: any | null;
                                    Name?: string | null;
                                    PumperFlowImperialGallonsPerMinute?:
                                      | number
                                      | null;
                                    RoadDistanceKilometres?: number | null;
                                    SuperiorTankerServiceAccreditationDate?:
                                      | any
                                      | null;
                                    FirefightersPerFireStation?: {
                                      __typename?: 'FirefightersPerFireStationType';
                                      FullTime?: number | null;
                                      Volunteer?: number | null;
                                    } | null;
                                    ProtectionGradeTerritories?: {
                                      __typename?: 'ProtectedGradeTerritoriesType';
                                      Commercial?: string | null;
                                      Dwelling?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Dwelling?: {
                                  __typename?: 'FusProductDwellingType';
                                  SearchMessage?: string | null;
                                  DwellingGrades?: {
                                    __typename?: 'DwellingGradesType';
                                    AllOtherAreasGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                    FirehallProtectedGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                    HydrantProtectedGrade: Array<{
                                      __typename?: 'DwellingProtectedGradeType';
                                      Grade?: string | null;
                                      WaterSystem?: string | null;
                                    }>;
                                  } | null;
                                  RespondingFirehall?: {
                                    __typename?: 'FirehallType';
                                    Address?: string | null;
                                    GoverningAgency?: string | null;
                                    GradingDate?: any | null;
                                    Name?: string | null;
                                    PumperFlowImperialGallonsPerMinute?:
                                      | number
                                      | null;
                                    RoadDistanceKilometres?: number | null;
                                    SuperiorTankerServiceAccreditationDate?:
                                      | any
                                      | null;
                                    FirefightersPerFireStation?: {
                                      __typename?: 'FirefightersPerFireStationType';
                                      FullTime?: number | null;
                                      Volunteer?: number | null;
                                    } | null;
                                    ProtectionGradeTerritories?: {
                                      __typename?: 'ProtectedGradeTerritoriesType';
                                      Commercial?: string | null;
                                      Dwelling?: string | null;
                                    } | null;
                                  } | null;
                                } | null;
                                EarthquakeCodes?: {
                                  __typename?: 'EarthquakeCodesType';
                                  SearchMessage?: string | null;
                                  EarthquakeCode: Array<{
                                    __typename?: 'EarthquakeCodeType';
                                    Description?: string | null;
                                    SystemName?: string | null;
                                    Zone?: string | null;
                                  }>;
                                } | null;
                                FusMaps?: {
                                  __typename?: 'FusMapsType';
                                  SearchMessage?: string | null;
                                  FusMap: Array<{
                                    __typename?: 'FusMapType';
                                    Business?: string | null;
                                    FusContent?: {
                                      __typename?: 'ContentType';
                                      ContentURL?: string | null;
                                      Expiry?: any | null;
                                      MIMEType?: string | null;
                                    } | null;
                                  }>;
                                } | null;
                                HydrantCounts?: {
                                  __typename?: 'HydrantCountsType';
                                  SearchMessage?: string | null;
                                  HydrantCount: Array<{
                                    __typename?: 'HydrantCountType';
                                    NumberOfHydrants: number;
                                    Ownership?: string | null;
                                    RadiusMetres?: string | null;
                                  }>;
                                } | null;
                              } | null;
                              optaFloodscoreData?: {
                                __typename?: 'OptaFloodScoreDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                FloodScores?: {
                                  __typename?: 'FloodScoresType';
                                  FloodScore: Array<{
                                    __typename?: 'FloodScoreType';
                                    AverageAnnualLossScore?: number | null;
                                    Deductible?: number | null;
                                    Default?: boolean | null;
                                    FloodScore?: number | null;
                                    Limit?: number | null;
                                    RateFactor?: number | null;
                                    Zone?: string | null;
                                    ResidentialModifiers?: {
                                      __typename?: 'ResidentialConstructionFeaturesType';
                                      ArchitecturalStyleType?: string | null;
                                      AuxiliaryHeatingType?: string | null;
                                      BathroomCount?: string | null;
                                      CommercialIndicator?: string | null;
                                      ExteriorWallType?: string | null;
                                      FinishedBasement?: boolean | null;
                                      FoundationType?: string | null;
                                      GarageNumberOfCars?: number | null;
                                      GarageType?: string | null;
                                      KitchenCount?: string | null;
                                      OutbuildingPresent?: string | null;
                                      PlumbingType?: string | null;
                                      PrimaryHeatingType?: string | null;
                                      RoofType?: string | null;
                                      SquareFootage?: number | null;
                                      StoreyCount?: string | null;
                                      SwimmingPoolType?: string | null;
                                      YearBuilt?: string | null;
                                      InspectionDetails?: {
                                        __typename?: 'InspectionDetailsType';
                                        InspectionDate?: any | null;
                                        SponsoredInspection?: boolean | null;
                                      } | null;
                                    } | null;
                                    MultiResidentialModifiers?: {
                                      __typename?: 'MultiResidentialConstructionFeaturesType';
                                      BasementPresent?: boolean | null;
                                      ConstructionType?: string | null;
                                      FloorLevel?: number | null;
                                    } | null;
                                    AverageAnnualLossValues?: {
                                      __typename?: 'FloodValueType';
                                      Building?: number | null;
                                      Contents?: number | null;
                                      Total?: number | null;
                                    } | null;
                                    AdditionalDetails: Array<{
                                      __typename?: 'FloodDetailType';
                                      Key?: string | null;
                                      Value?: string | null;
                                    }>;
                                    Premium?: {
                                      __typename?: 'FloodValueType';
                                      Building?: number | null;
                                      Contents?: number | null;
                                      Total?: number | null;
                                    } | null;
                                  }>;
                                  FloodMap: Array<{
                                    __typename?: 'FloodMapType';
                                    FloodEvent?: string | null;
                                    Content?: {
                                      __typename?: 'ContentType';
                                      ContentURL?: string | null;
                                      Expiry?: any | null;
                                      MIMEType?: string | null;
                                    } | null;
                                  }>;
                                } | null;
                              } | null;
                              optaIclarifyCommercialConstructionData?: {
                                __typename?: 'OptaIclarifyCommercialConstructionDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                Messages?: {
                                  __typename?: 'Messages';
                                  Message?: Array<string | null> | null;
                                } | null;
                                Property?: {
                                  __typename?: 'CommercialPropertyType';
                                  LotSize?: number | null;
                                  LotSizeUnitOfMeasurement?: string | null;
                                  PropertyType?: string | null;
                                  Buildings?: {
                                    __typename?: 'BuildingsType';
                                    Building: Array<{
                                      __typename?: 'BuildingType';
                                      StructureID?: string | null;
                                      ConstructionFeatures?: {
                                        __typename?: 'CommercialConstructionFeaturesType';
                                        CombustibilityClass?: string | null;
                                        ConstructionClass?: string | null;
                                        FloorHeight?: number | null;
                                        FloorHeightUnitOfMeasurement?:
                                          | string
                                          | null;
                                        LoadingDockPresent?: boolean | null;
                                        NumberOfStoreys?: number | null;
                                        NumberOfUnits?: number | null;
                                        TotalFloorArea?: number | null;
                                        TotalFloorAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        YearBuilt?: number | null;
                                        Basement?: {
                                          __typename?: 'CommercialBasementType';
                                          BasementArea?: number | null;
                                          BasementAreaUnitOfMeasurement?:
                                            | string
                                            | null;
                                          BasementPresent?: boolean | null;
                                        } | null;
                                        BuildingUpdates?: {
                                          __typename?: 'BuildingUpdatesType';
                                          BuildingUpdate?: Array<
                                            number | null
                                          > | null;
                                        } | null;
                                        CommercialExteriorWallTypes?: {
                                          __typename?: 'CommercialExteriorWallTypesType';
                                          ExteriorWallType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        Elevator?: {
                                          __typename?: 'ElevatorType';
                                          ElevatorCount?: number | null;
                                          ElevatorPresent?: boolean | null;
                                        } | null;
                                        Heating?: {
                                          __typename?: 'CommercialHeatingType';
                                          HeatingFuelSources?: {
                                            __typename?: 'HeatingFuelSourcesType';
                                            HeatingFuelSource?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          HeatingTypes?: {
                                            __typename?: 'HeatingTypesType';
                                            HeatingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        Parking?: {
                                          __typename?: 'CommercialParkingType';
                                          ParkingPresent?: boolean | null;
                                          ParkingTypes?: {
                                            __typename?: 'CommercialParkingTypesType';
                                            ParkingType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        PlumbingTypes?: {
                                          __typename?: 'CommercialPlumbingType';
                                          PlumbingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofAttributes?: {
                                          __typename?: 'RoofAttributesType';
                                          RoofConstructions?: {
                                            __typename?: 'RoofConstructionsType';
                                            RoofConstruction?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofSurfaces?: {
                                            __typename?: 'RoofSurfacesType';
                                            RoofSurface?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          RoofTypes?: {
                                            __typename?: 'RoofTypesType';
                                            RoofType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                        WaterHeater?: {
                                          __typename?: 'CommercialWaterHeaterType';
                                          WaterHeaterFuelType?: Array<
                                            string | null
                                          > | null;
                                          WaterHeaterType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        Wiring?: {
                                          __typename?: 'CommercialWiringType';
                                          WiringPanelTypes?: {
                                            __typename?: 'CommercialWiringPanelTypesType';
                                            WiringPanelType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                          WiringTypes?: {
                                            __typename?: 'CommercialWiringTypesType';
                                            WiringType?: Array<
                                              string | null
                                            > | null;
                                          } | null;
                                        } | null;
                                      } | null;
                                      Protection?: {
                                        __typename?: 'ProtectionType';
                                        FireAlarmPresent?: boolean | null;
                                        FireAlarmType?: string | null;
                                        PhysicalSecurityPresent?:
                                          | boolean
                                          | null;
                                        SecurityAlarmPresent?: boolean | null;
                                        SecurityAlarmType?: string | null;
                                        SprinklerPresent?: boolean | null;
                                        SprinklerTypeCode?: string | null;
                                        PhysicalSecurityTypes?: {
                                          __typename?: 'PhysicalSecurityTypesType';
                                          PhysicalSecurityType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    }>;
                                  } | null;
                                  Industry?: {
                                    __typename?: 'BusinessIndustryType';
                                    IBCCode?: string | null;
                                    IBCIndustryDescription?: string | null;
                                    SICIndustryDescription?: string | null;
                                    SICCode?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              optaCommercialPerilScoreData?: {
                                __typename?: 'OptaCommercialPerilScoreDataType';
                                Address?: {
                                  __typename?: 'OptaAddressType';
                                  StreetAddress?: string | null;
                                  StreetNumber?: string | null;
                                  StreetName?: string | null;
                                  StreetType?: string | null;
                                  StreetDirection?: string | null;
                                  UnitNumber?: string | null;
                                  Municipality?: string | null;
                                  Province?: string | null;
                                  PostalCode?: string | null;
                                  LegalDescription?: {
                                    __typename?: 'LegalDescriptionType';
                                    Quarter?: string | null;
                                    LegalSubdivision?: string | null;
                                    Section?: string | null;
                                    Township?: string | null;
                                    Range?: string | null;
                                    Meridian?: string | null;
                                  } | null;
                                } | null;
                                PropertyPerilScores?: {
                                  __typename?: 'PropertyCommercialPerilsType';
                                  Peril?: Array<{
                                    __typename?: 'PerilDetailsType';
                                    LossCostScore?: number | null;
                                    PerilType?: string | null;
                                    PredictedLossCost?: number | null;
                                  } | null> | null;
                                } | null;
                                CasualtyPerilScores?: {
                                  __typename?: 'CasualtyCommercialPerilsType';
                                  Peril?: Array<{
                                    __typename?: 'PerilDetailsType';
                                    LossCostScore?: number | null;
                                    PerilType?: string | null;
                                    PredictedLossCost?: number | null;
                                  } | null> | null;
                                } | null;
                                AllPerilsScore?: {
                                  __typename?: 'CommercialPerilScoreType';
                                  LossCostScore?: number | null;
                                  PredictedLossCost?: number | null;
                                } | null;
                              } | null;
                            } | null;
                            openingHours: Array<{
                              __typename?: 'OpeningHoursType';
                              day: number;
                              open?: any | null;
                              close?: any | null;
                            }>;
                          } | null;
                        }>;
                        details?: {
                          __typename?: 'AnswerInstanceDetailsType';
                          lat?: number | null;
                          lng?: number | null;
                          images: Array<{
                            __typename?: 'GoogleImageType';
                            description: string;
                            shortDescription: string;
                            category: CoreGoogleImageCategoryChoices;
                            data?: string | null;
                          }>;
                          google?: {
                            __typename?: 'GoogleDetailsType';
                            placeId?: string | null;
                            rating?: number | null;
                            numberOfRatings?: number | null;
                          } | null;
                          opta?: {
                            __typename?: 'OPTADetailsType';
                            optaFusData?: {
                              __typename?: 'OptaFusDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Commercial?: {
                                __typename?: 'FusProductCommercialType';
                                SearchMessage?: string | null;
                                CommercialGrade: Array<{
                                  __typename?: 'CommercialGradeType';
                                  PublicFireProtectionClassification?:
                                    | number
                                    | null;
                                  WaterSystem?: string | null;
                                  RiskSCORDetail: Array<{
                                    __typename?: 'RiskSCORDetailType';
                                    RiskSCOR?: number | null;
                                    Type?: string | null;
                                    Adjustment: Array<{
                                      __typename?: 'RiskSCORAdjustmentType';
                                      Adjustment?: string | null;
                                      AdjustmentType?: string | null;
                                    }>;
                                  }>;
                                }>;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              Dwelling?: {
                                __typename?: 'FusProductDwellingType';
                                SearchMessage?: string | null;
                                DwellingGrades?: {
                                  __typename?: 'DwellingGradesType';
                                  AllOtherAreasGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  FirehallProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                  HydrantProtectedGrade: Array<{
                                    __typename?: 'DwellingProtectedGradeType';
                                    Grade?: string | null;
                                    WaterSystem?: string | null;
                                  }>;
                                } | null;
                                RespondingFirehall?: {
                                  __typename?: 'FirehallType';
                                  Address?: string | null;
                                  GoverningAgency?: string | null;
                                  GradingDate?: any | null;
                                  Name?: string | null;
                                  PumperFlowImperialGallonsPerMinute?:
                                    | number
                                    | null;
                                  RoadDistanceKilometres?: number | null;
                                  SuperiorTankerServiceAccreditationDate?:
                                    | any
                                    | null;
                                  FirefightersPerFireStation?: {
                                    __typename?: 'FirefightersPerFireStationType';
                                    FullTime?: number | null;
                                    Volunteer?: number | null;
                                  } | null;
                                  ProtectionGradeTerritories?: {
                                    __typename?: 'ProtectedGradeTerritoriesType';
                                    Commercial?: string | null;
                                    Dwelling?: string | null;
                                  } | null;
                                } | null;
                              } | null;
                              EarthquakeCodes?: {
                                __typename?: 'EarthquakeCodesType';
                                SearchMessage?: string | null;
                                EarthquakeCode: Array<{
                                  __typename?: 'EarthquakeCodeType';
                                  Description?: string | null;
                                  SystemName?: string | null;
                                  Zone?: string | null;
                                }>;
                              } | null;
                              FusMaps?: {
                                __typename?: 'FusMapsType';
                                SearchMessage?: string | null;
                                FusMap: Array<{
                                  __typename?: 'FusMapType';
                                  Business?: string | null;
                                  FusContent?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                              HydrantCounts?: {
                                __typename?: 'HydrantCountsType';
                                SearchMessage?: string | null;
                                HydrantCount: Array<{
                                  __typename?: 'HydrantCountType';
                                  NumberOfHydrants: number;
                                  Ownership?: string | null;
                                  RadiusMetres?: string | null;
                                }>;
                              } | null;
                            } | null;
                            optaFloodscoreData?: {
                              __typename?: 'OptaFloodScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              FloodScores?: {
                                __typename?: 'FloodScoresType';
                                FloodScore: Array<{
                                  __typename?: 'FloodScoreType';
                                  AverageAnnualLossScore?: number | null;
                                  Deductible?: number | null;
                                  Default?: boolean | null;
                                  FloodScore?: number | null;
                                  Limit?: number | null;
                                  RateFactor?: number | null;
                                  Zone?: string | null;
                                  ResidentialModifiers?: {
                                    __typename?: 'ResidentialConstructionFeaturesType';
                                    ArchitecturalStyleType?: string | null;
                                    AuxiliaryHeatingType?: string | null;
                                    BathroomCount?: string | null;
                                    CommercialIndicator?: string | null;
                                    ExteriorWallType?: string | null;
                                    FinishedBasement?: boolean | null;
                                    FoundationType?: string | null;
                                    GarageNumberOfCars?: number | null;
                                    GarageType?: string | null;
                                    KitchenCount?: string | null;
                                    OutbuildingPresent?: string | null;
                                    PlumbingType?: string | null;
                                    PrimaryHeatingType?: string | null;
                                    RoofType?: string | null;
                                    SquareFootage?: number | null;
                                    StoreyCount?: string | null;
                                    SwimmingPoolType?: string | null;
                                    YearBuilt?: string | null;
                                    InspectionDetails?: {
                                      __typename?: 'InspectionDetailsType';
                                      InspectionDate?: any | null;
                                      SponsoredInspection?: boolean | null;
                                    } | null;
                                  } | null;
                                  MultiResidentialModifiers?: {
                                    __typename?: 'MultiResidentialConstructionFeaturesType';
                                    BasementPresent?: boolean | null;
                                    ConstructionType?: string | null;
                                    FloorLevel?: number | null;
                                  } | null;
                                  AverageAnnualLossValues?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                  AdditionalDetails: Array<{
                                    __typename?: 'FloodDetailType';
                                    Key?: string | null;
                                    Value?: string | null;
                                  }>;
                                  Premium?: {
                                    __typename?: 'FloodValueType';
                                    Building?: number | null;
                                    Contents?: number | null;
                                    Total?: number | null;
                                  } | null;
                                }>;
                                FloodMap: Array<{
                                  __typename?: 'FloodMapType';
                                  FloodEvent?: string | null;
                                  Content?: {
                                    __typename?: 'ContentType';
                                    ContentURL?: string | null;
                                    Expiry?: any | null;
                                    MIMEType?: string | null;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            optaIclarifyCommercialConstructionData?: {
                              __typename?: 'OptaIclarifyCommercialConstructionDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              Messages?: {
                                __typename?: 'Messages';
                                Message?: Array<string | null> | null;
                              } | null;
                              Property?: {
                                __typename?: 'CommercialPropertyType';
                                LotSize?: number | null;
                                LotSizeUnitOfMeasurement?: string | null;
                                PropertyType?: string | null;
                                Buildings?: {
                                  __typename?: 'BuildingsType';
                                  Building: Array<{
                                    __typename?: 'BuildingType';
                                    StructureID?: string | null;
                                    ConstructionFeatures?: {
                                      __typename?: 'CommercialConstructionFeaturesType';
                                      CombustibilityClass?: string | null;
                                      ConstructionClass?: string | null;
                                      FloorHeight?: number | null;
                                      FloorHeightUnitOfMeasurement?:
                                        | string
                                        | null;
                                      LoadingDockPresent?: boolean | null;
                                      NumberOfStoreys?: number | null;
                                      NumberOfUnits?: number | null;
                                      TotalFloorArea?: number | null;
                                      TotalFloorAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      YearBuilt?: number | null;
                                      Basement?: {
                                        __typename?: 'CommercialBasementType';
                                        BasementArea?: number | null;
                                        BasementAreaUnitOfMeasurement?:
                                          | string
                                          | null;
                                        BasementPresent?: boolean | null;
                                      } | null;
                                      BuildingUpdates?: {
                                        __typename?: 'BuildingUpdatesType';
                                        BuildingUpdate?: Array<
                                          number | null
                                        > | null;
                                      } | null;
                                      CommercialExteriorWallTypes?: {
                                        __typename?: 'CommercialExteriorWallTypesType';
                                        ExteriorWallType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Elevator?: {
                                        __typename?: 'ElevatorType';
                                        ElevatorCount?: number | null;
                                        ElevatorPresent?: boolean | null;
                                      } | null;
                                      Heating?: {
                                        __typename?: 'CommercialHeatingType';
                                        HeatingFuelSources?: {
                                          __typename?: 'HeatingFuelSourcesType';
                                          HeatingFuelSource?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        HeatingTypes?: {
                                          __typename?: 'HeatingTypesType';
                                          HeatingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      Parking?: {
                                        __typename?: 'CommercialParkingType';
                                        ParkingPresent?: boolean | null;
                                        ParkingTypes?: {
                                          __typename?: 'CommercialParkingTypesType';
                                          ParkingType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      PlumbingTypes?: {
                                        __typename?: 'CommercialPlumbingType';
                                        PlumbingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofAttributes?: {
                                        __typename?: 'RoofAttributesType';
                                        RoofConstructions?: {
                                          __typename?: 'RoofConstructionsType';
                                          RoofConstruction?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofSurfaces?: {
                                          __typename?: 'RoofSurfacesType';
                                          RoofSurface?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        RoofTypes?: {
                                          __typename?: 'RoofTypesType';
                                          RoofType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                      WaterHeater?: {
                                        __typename?: 'CommercialWaterHeaterType';
                                        WaterHeaterFuelType?: Array<
                                          string | null
                                        > | null;
                                        WaterHeaterType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      Wiring?: {
                                        __typename?: 'CommercialWiringType';
                                        WiringPanelTypes?: {
                                          __typename?: 'CommercialWiringPanelTypesType';
                                          WiringPanelType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                        WiringTypes?: {
                                          __typename?: 'CommercialWiringTypesType';
                                          WiringType?: Array<
                                            string | null
                                          > | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                    Protection?: {
                                      __typename?: 'ProtectionType';
                                      FireAlarmPresent?: boolean | null;
                                      FireAlarmType?: string | null;
                                      PhysicalSecurityPresent?: boolean | null;
                                      SecurityAlarmPresent?: boolean | null;
                                      SecurityAlarmType?: string | null;
                                      SprinklerPresent?: boolean | null;
                                      SprinklerTypeCode?: string | null;
                                      PhysicalSecurityTypes?: {
                                        __typename?: 'PhysicalSecurityTypesType';
                                        PhysicalSecurityType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  }>;
                                } | null;
                                Industry?: {
                                  __typename?: 'BusinessIndustryType';
                                  IBCCode?: string | null;
                                  IBCIndustryDescription?: string | null;
                                  SICIndustryDescription?: string | null;
                                  SICCode?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            optaCommercialPerilScoreData?: {
                              __typename?: 'OptaCommercialPerilScoreDataType';
                              Address?: {
                                __typename?: 'OptaAddressType';
                                StreetAddress?: string | null;
                                StreetNumber?: string | null;
                                StreetName?: string | null;
                                StreetType?: string | null;
                                StreetDirection?: string | null;
                                UnitNumber?: string | null;
                                Municipality?: string | null;
                                Province?: string | null;
                                PostalCode?: string | null;
                                LegalDescription?: {
                                  __typename?: 'LegalDescriptionType';
                                  Quarter?: string | null;
                                  LegalSubdivision?: string | null;
                                  Section?: string | null;
                                  Township?: string | null;
                                  Range?: string | null;
                                  Meridian?: string | null;
                                } | null;
                              } | null;
                              PropertyPerilScores?: {
                                __typename?: 'PropertyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              CasualtyPerilScores?: {
                                __typename?: 'CasualtyCommercialPerilsType';
                                Peril?: Array<{
                                  __typename?: 'PerilDetailsType';
                                  LossCostScore?: number | null;
                                  PerilType?: string | null;
                                  PredictedLossCost?: number | null;
                                } | null> | null;
                              } | null;
                              AllPerilsScore?: {
                                __typename?: 'CommercialPerilScoreType';
                                LossCostScore?: number | null;
                                PredictedLossCost?: number | null;
                              } | null;
                            } | null;
                          } | null;
                          openingHours: Array<{
                            __typename?: 'OpeningHoursType';
                            day: number;
                            open?: any | null;
                            close?: any | null;
                          }>;
                        } | null;
                      }>;
                      details?: {
                        __typename?: 'AnswerInstanceDetailsType';
                        lat?: number | null;
                        lng?: number | null;
                        images: Array<{
                          __typename?: 'GoogleImageType';
                          description: string;
                          shortDescription: string;
                          category: CoreGoogleImageCategoryChoices;
                          data?: string | null;
                        }>;
                        google?: {
                          __typename?: 'GoogleDetailsType';
                          placeId?: string | null;
                          rating?: number | null;
                          numberOfRatings?: number | null;
                        } | null;
                        opta?: {
                          __typename?: 'OPTADetailsType';
                          optaFusData?: {
                            __typename?: 'OptaFusDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Commercial?: {
                              __typename?: 'FusProductCommercialType';
                              SearchMessage?: string | null;
                              CommercialGrade: Array<{
                                __typename?: 'CommercialGradeType';
                                PublicFireProtectionClassification?:
                                  | number
                                  | null;
                                WaterSystem?: string | null;
                                RiskSCORDetail: Array<{
                                  __typename?: 'RiskSCORDetailType';
                                  RiskSCOR?: number | null;
                                  Type?: string | null;
                                  Adjustment: Array<{
                                    __typename?: 'RiskSCORAdjustmentType';
                                    Adjustment?: string | null;
                                    AdjustmentType?: string | null;
                                  }>;
                                }>;
                              }>;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            Dwelling?: {
                              __typename?: 'FusProductDwellingType';
                              SearchMessage?: string | null;
                              DwellingGrades?: {
                                __typename?: 'DwellingGradesType';
                                AllOtherAreasGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                FirehallProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                                HydrantProtectedGrade: Array<{
                                  __typename?: 'DwellingProtectedGradeType';
                                  Grade?: string | null;
                                  WaterSystem?: string | null;
                                }>;
                              } | null;
                              RespondingFirehall?: {
                                __typename?: 'FirehallType';
                                Address?: string | null;
                                GoverningAgency?: string | null;
                                GradingDate?: any | null;
                                Name?: string | null;
                                PumperFlowImperialGallonsPerMinute?:
                                  | number
                                  | null;
                                RoadDistanceKilometres?: number | null;
                                SuperiorTankerServiceAccreditationDate?:
                                  | any
                                  | null;
                                FirefightersPerFireStation?: {
                                  __typename?: 'FirefightersPerFireStationType';
                                  FullTime?: number | null;
                                  Volunteer?: number | null;
                                } | null;
                                ProtectionGradeTerritories?: {
                                  __typename?: 'ProtectedGradeTerritoriesType';
                                  Commercial?: string | null;
                                  Dwelling?: string | null;
                                } | null;
                              } | null;
                            } | null;
                            EarthquakeCodes?: {
                              __typename?: 'EarthquakeCodesType';
                              SearchMessage?: string | null;
                              EarthquakeCode: Array<{
                                __typename?: 'EarthquakeCodeType';
                                Description?: string | null;
                                SystemName?: string | null;
                                Zone?: string | null;
                              }>;
                            } | null;
                            FusMaps?: {
                              __typename?: 'FusMapsType';
                              SearchMessage?: string | null;
                              FusMap: Array<{
                                __typename?: 'FusMapType';
                                Business?: string | null;
                                FusContent?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                            HydrantCounts?: {
                              __typename?: 'HydrantCountsType';
                              SearchMessage?: string | null;
                              HydrantCount: Array<{
                                __typename?: 'HydrantCountType';
                                NumberOfHydrants: number;
                                Ownership?: string | null;
                                RadiusMetres?: string | null;
                              }>;
                            } | null;
                          } | null;
                          optaFloodscoreData?: {
                            __typename?: 'OptaFloodScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            FloodScores?: {
                              __typename?: 'FloodScoresType';
                              FloodScore: Array<{
                                __typename?: 'FloodScoreType';
                                AverageAnnualLossScore?: number | null;
                                Deductible?: number | null;
                                Default?: boolean | null;
                                FloodScore?: number | null;
                                Limit?: number | null;
                                RateFactor?: number | null;
                                Zone?: string | null;
                                ResidentialModifiers?: {
                                  __typename?: 'ResidentialConstructionFeaturesType';
                                  ArchitecturalStyleType?: string | null;
                                  AuxiliaryHeatingType?: string | null;
                                  BathroomCount?: string | null;
                                  CommercialIndicator?: string | null;
                                  ExteriorWallType?: string | null;
                                  FinishedBasement?: boolean | null;
                                  FoundationType?: string | null;
                                  GarageNumberOfCars?: number | null;
                                  GarageType?: string | null;
                                  KitchenCount?: string | null;
                                  OutbuildingPresent?: string | null;
                                  PlumbingType?: string | null;
                                  PrimaryHeatingType?: string | null;
                                  RoofType?: string | null;
                                  SquareFootage?: number | null;
                                  StoreyCount?: string | null;
                                  SwimmingPoolType?: string | null;
                                  YearBuilt?: string | null;
                                  InspectionDetails?: {
                                    __typename?: 'InspectionDetailsType';
                                    InspectionDate?: any | null;
                                    SponsoredInspection?: boolean | null;
                                  } | null;
                                } | null;
                                MultiResidentialModifiers?: {
                                  __typename?: 'MultiResidentialConstructionFeaturesType';
                                  BasementPresent?: boolean | null;
                                  ConstructionType?: string | null;
                                  FloorLevel?: number | null;
                                } | null;
                                AverageAnnualLossValues?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                                AdditionalDetails: Array<{
                                  __typename?: 'FloodDetailType';
                                  Key?: string | null;
                                  Value?: string | null;
                                }>;
                                Premium?: {
                                  __typename?: 'FloodValueType';
                                  Building?: number | null;
                                  Contents?: number | null;
                                  Total?: number | null;
                                } | null;
                              }>;
                              FloodMap: Array<{
                                __typename?: 'FloodMapType';
                                FloodEvent?: string | null;
                                Content?: {
                                  __typename?: 'ContentType';
                                  ContentURL?: string | null;
                                  Expiry?: any | null;
                                  MIMEType?: string | null;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          optaIclarifyCommercialConstructionData?: {
                            __typename?: 'OptaIclarifyCommercialConstructionDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            Messages?: {
                              __typename?: 'Messages';
                              Message?: Array<string | null> | null;
                            } | null;
                            Property?: {
                              __typename?: 'CommercialPropertyType';
                              LotSize?: number | null;
                              LotSizeUnitOfMeasurement?: string | null;
                              PropertyType?: string | null;
                              Buildings?: {
                                __typename?: 'BuildingsType';
                                Building: Array<{
                                  __typename?: 'BuildingType';
                                  StructureID?: string | null;
                                  ConstructionFeatures?: {
                                    __typename?: 'CommercialConstructionFeaturesType';
                                    CombustibilityClass?: string | null;
                                    ConstructionClass?: string | null;
                                    FloorHeight?: number | null;
                                    FloorHeightUnitOfMeasurement?:
                                      | string
                                      | null;
                                    LoadingDockPresent?: boolean | null;
                                    NumberOfStoreys?: number | null;
                                    NumberOfUnits?: number | null;
                                    TotalFloorArea?: number | null;
                                    TotalFloorAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    YearBuilt?: number | null;
                                    Basement?: {
                                      __typename?: 'CommercialBasementType';
                                      BasementArea?: number | null;
                                      BasementAreaUnitOfMeasurement?:
                                        | string
                                        | null;
                                      BasementPresent?: boolean | null;
                                    } | null;
                                    BuildingUpdates?: {
                                      __typename?: 'BuildingUpdatesType';
                                      BuildingUpdate?: Array<
                                        number | null
                                      > | null;
                                    } | null;
                                    CommercialExteriorWallTypes?: {
                                      __typename?: 'CommercialExteriorWallTypesType';
                                      ExteriorWallType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Elevator?: {
                                      __typename?: 'ElevatorType';
                                      ElevatorCount?: number | null;
                                      ElevatorPresent?: boolean | null;
                                    } | null;
                                    Heating?: {
                                      __typename?: 'CommercialHeatingType';
                                      HeatingFuelSources?: {
                                        __typename?: 'HeatingFuelSourcesType';
                                        HeatingFuelSource?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      HeatingTypes?: {
                                        __typename?: 'HeatingTypesType';
                                        HeatingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    Parking?: {
                                      __typename?: 'CommercialParkingType';
                                      ParkingPresent?: boolean | null;
                                      ParkingTypes?: {
                                        __typename?: 'CommercialParkingTypesType';
                                        ParkingType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                    PlumbingTypes?: {
                                      __typename?: 'CommercialPlumbingType';
                                      PlumbingType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofAttributes?: {
                                      __typename?: 'RoofAttributesType';
                                      RoofConstructions?: {
                                        __typename?: 'RoofConstructionsType';
                                        RoofConstruction?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofSurfaces?: {
                                        __typename?: 'RoofSurfacesType';
                                        RoofSurface?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      RoofTypes?: {
                                        __typename?: 'RoofTypesType';
                                        RoofType?: Array<string | null> | null;
                                      } | null;
                                    } | null;
                                    WaterHeater?: {
                                      __typename?: 'CommercialWaterHeaterType';
                                      WaterHeaterFuelType?: Array<
                                        string | null
                                      > | null;
                                      WaterHeaterType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    Wiring?: {
                                      __typename?: 'CommercialWiringType';
                                      WiringPanelTypes?: {
                                        __typename?: 'CommercialWiringPanelTypesType';
                                        WiringPanelType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                      WiringTypes?: {
                                        __typename?: 'CommercialWiringTypesType';
                                        WiringType?: Array<
                                          string | null
                                        > | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                  Protection?: {
                                    __typename?: 'ProtectionType';
                                    FireAlarmPresent?: boolean | null;
                                    FireAlarmType?: string | null;
                                    PhysicalSecurityPresent?: boolean | null;
                                    SecurityAlarmPresent?: boolean | null;
                                    SecurityAlarmType?: string | null;
                                    SprinklerPresent?: boolean | null;
                                    SprinklerTypeCode?: string | null;
                                    PhysicalSecurityTypes?: {
                                      __typename?: 'PhysicalSecurityTypesType';
                                      PhysicalSecurityType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                  } | null;
                                }>;
                              } | null;
                              Industry?: {
                                __typename?: 'BusinessIndustryType';
                                IBCCode?: string | null;
                                IBCIndustryDescription?: string | null;
                                SICIndustryDescription?: string | null;
                                SICCode?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          optaCommercialPerilScoreData?: {
                            __typename?: 'OptaCommercialPerilScoreDataType';
                            Address?: {
                              __typename?: 'OptaAddressType';
                              StreetAddress?: string | null;
                              StreetNumber?: string | null;
                              StreetName?: string | null;
                              StreetType?: string | null;
                              StreetDirection?: string | null;
                              UnitNumber?: string | null;
                              Municipality?: string | null;
                              Province?: string | null;
                              PostalCode?: string | null;
                              LegalDescription?: {
                                __typename?: 'LegalDescriptionType';
                                Quarter?: string | null;
                                LegalSubdivision?: string | null;
                                Section?: string | null;
                                Township?: string | null;
                                Range?: string | null;
                                Meridian?: string | null;
                              } | null;
                            } | null;
                            PropertyPerilScores?: {
                              __typename?: 'PropertyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            CasualtyPerilScores?: {
                              __typename?: 'CasualtyCommercialPerilsType';
                              Peril?: Array<{
                                __typename?: 'PerilDetailsType';
                                LossCostScore?: number | null;
                                PerilType?: string | null;
                                PredictedLossCost?: number | null;
                              } | null> | null;
                            } | null;
                            AllPerilsScore?: {
                              __typename?: 'CommercialPerilScoreType';
                              LossCostScore?: number | null;
                              PredictedLossCost?: number | null;
                            } | null;
                          } | null;
                        } | null;
                        openingHours: Array<{
                          __typename?: 'OpeningHoursType';
                          day: number;
                          open?: any | null;
                          close?: any | null;
                        }>;
                      } | null;
                    }>;
                    details?: {
                      __typename?: 'AnswerInstanceDetailsType';
                      lat?: number | null;
                      lng?: number | null;
                      images: Array<{
                        __typename?: 'GoogleImageType';
                        description: string;
                        shortDescription: string;
                        category: CoreGoogleImageCategoryChoices;
                        data?: string | null;
                      }>;
                      google?: {
                        __typename?: 'GoogleDetailsType';
                        placeId?: string | null;
                        rating?: number | null;
                        numberOfRatings?: number | null;
                      } | null;
                      opta?: {
                        __typename?: 'OPTADetailsType';
                        optaFusData?: {
                          __typename?: 'OptaFusDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Commercial?: {
                            __typename?: 'FusProductCommercialType';
                            SearchMessage?: string | null;
                            CommercialGrade: Array<{
                              __typename?: 'CommercialGradeType';
                              PublicFireProtectionClassification?:
                                | number
                                | null;
                              WaterSystem?: string | null;
                              RiskSCORDetail: Array<{
                                __typename?: 'RiskSCORDetailType';
                                RiskSCOR?: number | null;
                                Type?: string | null;
                                Adjustment: Array<{
                                  __typename?: 'RiskSCORAdjustmentType';
                                  Adjustment?: string | null;
                                  AdjustmentType?: string | null;
                                }>;
                              }>;
                            }>;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          Dwelling?: {
                            __typename?: 'FusProductDwellingType';
                            SearchMessage?: string | null;
                            DwellingGrades?: {
                              __typename?: 'DwellingGradesType';
                              AllOtherAreasGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              FirehallProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                              HydrantProtectedGrade: Array<{
                                __typename?: 'DwellingProtectedGradeType';
                                Grade?: string | null;
                                WaterSystem?: string | null;
                              }>;
                            } | null;
                            RespondingFirehall?: {
                              __typename?: 'FirehallType';
                              Address?: string | null;
                              GoverningAgency?: string | null;
                              GradingDate?: any | null;
                              Name?: string | null;
                              PumperFlowImperialGallonsPerMinute?:
                                | number
                                | null;
                              RoadDistanceKilometres?: number | null;
                              SuperiorTankerServiceAccreditationDate?:
                                | any
                                | null;
                              FirefightersPerFireStation?: {
                                __typename?: 'FirefightersPerFireStationType';
                                FullTime?: number | null;
                                Volunteer?: number | null;
                              } | null;
                              ProtectionGradeTerritories?: {
                                __typename?: 'ProtectedGradeTerritoriesType';
                                Commercial?: string | null;
                                Dwelling?: string | null;
                              } | null;
                            } | null;
                          } | null;
                          EarthquakeCodes?: {
                            __typename?: 'EarthquakeCodesType';
                            SearchMessage?: string | null;
                            EarthquakeCode: Array<{
                              __typename?: 'EarthquakeCodeType';
                              Description?: string | null;
                              SystemName?: string | null;
                              Zone?: string | null;
                            }>;
                          } | null;
                          FusMaps?: {
                            __typename?: 'FusMapsType';
                            SearchMessage?: string | null;
                            FusMap: Array<{
                              __typename?: 'FusMapType';
                              Business?: string | null;
                              FusContent?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                          HydrantCounts?: {
                            __typename?: 'HydrantCountsType';
                            SearchMessage?: string | null;
                            HydrantCount: Array<{
                              __typename?: 'HydrantCountType';
                              NumberOfHydrants: number;
                              Ownership?: string | null;
                              RadiusMetres?: string | null;
                            }>;
                          } | null;
                        } | null;
                        optaFloodscoreData?: {
                          __typename?: 'OptaFloodScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          FloodScores?: {
                            __typename?: 'FloodScoresType';
                            FloodScore: Array<{
                              __typename?: 'FloodScoreType';
                              AverageAnnualLossScore?: number | null;
                              Deductible?: number | null;
                              Default?: boolean | null;
                              FloodScore?: number | null;
                              Limit?: number | null;
                              RateFactor?: number | null;
                              Zone?: string | null;
                              ResidentialModifiers?: {
                                __typename?: 'ResidentialConstructionFeaturesType';
                                ArchitecturalStyleType?: string | null;
                                AuxiliaryHeatingType?: string | null;
                                BathroomCount?: string | null;
                                CommercialIndicator?: string | null;
                                ExteriorWallType?: string | null;
                                FinishedBasement?: boolean | null;
                                FoundationType?: string | null;
                                GarageNumberOfCars?: number | null;
                                GarageType?: string | null;
                                KitchenCount?: string | null;
                                OutbuildingPresent?: string | null;
                                PlumbingType?: string | null;
                                PrimaryHeatingType?: string | null;
                                RoofType?: string | null;
                                SquareFootage?: number | null;
                                StoreyCount?: string | null;
                                SwimmingPoolType?: string | null;
                                YearBuilt?: string | null;
                                InspectionDetails?: {
                                  __typename?: 'InspectionDetailsType';
                                  InspectionDate?: any | null;
                                  SponsoredInspection?: boolean | null;
                                } | null;
                              } | null;
                              MultiResidentialModifiers?: {
                                __typename?: 'MultiResidentialConstructionFeaturesType';
                                BasementPresent?: boolean | null;
                                ConstructionType?: string | null;
                                FloorLevel?: number | null;
                              } | null;
                              AverageAnnualLossValues?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                              AdditionalDetails: Array<{
                                __typename?: 'FloodDetailType';
                                Key?: string | null;
                                Value?: string | null;
                              }>;
                              Premium?: {
                                __typename?: 'FloodValueType';
                                Building?: number | null;
                                Contents?: number | null;
                                Total?: number | null;
                              } | null;
                            }>;
                            FloodMap: Array<{
                              __typename?: 'FloodMapType';
                              FloodEvent?: string | null;
                              Content?: {
                                __typename?: 'ContentType';
                                ContentURL?: string | null;
                                Expiry?: any | null;
                                MIMEType?: string | null;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        optaIclarifyCommercialConstructionData?: {
                          __typename?: 'OptaIclarifyCommercialConstructionDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          Messages?: {
                            __typename?: 'Messages';
                            Message?: Array<string | null> | null;
                          } | null;
                          Property?: {
                            __typename?: 'CommercialPropertyType';
                            LotSize?: number | null;
                            LotSizeUnitOfMeasurement?: string | null;
                            PropertyType?: string | null;
                            Buildings?: {
                              __typename?: 'BuildingsType';
                              Building: Array<{
                                __typename?: 'BuildingType';
                                StructureID?: string | null;
                                ConstructionFeatures?: {
                                  __typename?: 'CommercialConstructionFeaturesType';
                                  CombustibilityClass?: string | null;
                                  ConstructionClass?: string | null;
                                  FloorHeight?: number | null;
                                  FloorHeightUnitOfMeasurement?: string | null;
                                  LoadingDockPresent?: boolean | null;
                                  NumberOfStoreys?: number | null;
                                  NumberOfUnits?: number | null;
                                  TotalFloorArea?: number | null;
                                  TotalFloorAreaUnitOfMeasurement?:
                                    | string
                                    | null;
                                  YearBuilt?: number | null;
                                  Basement?: {
                                    __typename?: 'CommercialBasementType';
                                    BasementArea?: number | null;
                                    BasementAreaUnitOfMeasurement?:
                                      | string
                                      | null;
                                    BasementPresent?: boolean | null;
                                  } | null;
                                  BuildingUpdates?: {
                                    __typename?: 'BuildingUpdatesType';
                                    BuildingUpdate?: Array<
                                      number | null
                                    > | null;
                                  } | null;
                                  CommercialExteriorWallTypes?: {
                                    __typename?: 'CommercialExteriorWallTypesType';
                                    ExteriorWallType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Elevator?: {
                                    __typename?: 'ElevatorType';
                                    ElevatorCount?: number | null;
                                    ElevatorPresent?: boolean | null;
                                  } | null;
                                  Heating?: {
                                    __typename?: 'CommercialHeatingType';
                                    HeatingFuelSources?: {
                                      __typename?: 'HeatingFuelSourcesType';
                                      HeatingFuelSource?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    HeatingTypes?: {
                                      __typename?: 'HeatingTypesType';
                                      HeatingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  Parking?: {
                                    __typename?: 'CommercialParkingType';
                                    ParkingPresent?: boolean | null;
                                    ParkingTypes?: {
                                      __typename?: 'CommercialParkingTypesType';
                                      ParkingType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  PlumbingTypes?: {
                                    __typename?: 'CommercialPlumbingType';
                                    PlumbingType?: Array<string | null> | null;
                                  } | null;
                                  RoofAttributes?: {
                                    __typename?: 'RoofAttributesType';
                                    RoofConstructions?: {
                                      __typename?: 'RoofConstructionsType';
                                      RoofConstruction?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    RoofSurfaces?: {
                                      __typename?: 'RoofSurfacesType';
                                      RoofSurface?: Array<string | null> | null;
                                    } | null;
                                    RoofTypes?: {
                                      __typename?: 'RoofTypesType';
                                      RoofType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                  WaterHeater?: {
                                    __typename?: 'CommercialWaterHeaterType';
                                    WaterHeaterFuelType?: Array<
                                      string | null
                                    > | null;
                                    WaterHeaterType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  Wiring?: {
                                    __typename?: 'CommercialWiringType';
                                    WiringPanelTypes?: {
                                      __typename?: 'CommercialWiringPanelTypesType';
                                      WiringPanelType?: Array<
                                        string | null
                                      > | null;
                                    } | null;
                                    WiringTypes?: {
                                      __typename?: 'CommercialWiringTypesType';
                                      WiringType?: Array<string | null> | null;
                                    } | null;
                                  } | null;
                                } | null;
                                Protection?: {
                                  __typename?: 'ProtectionType';
                                  FireAlarmPresent?: boolean | null;
                                  FireAlarmType?: string | null;
                                  PhysicalSecurityPresent?: boolean | null;
                                  SecurityAlarmPresent?: boolean | null;
                                  SecurityAlarmType?: string | null;
                                  SprinklerPresent?: boolean | null;
                                  SprinklerTypeCode?: string | null;
                                  PhysicalSecurityTypes?: {
                                    __typename?: 'PhysicalSecurityTypesType';
                                    PhysicalSecurityType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                } | null;
                              }>;
                            } | null;
                            Industry?: {
                              __typename?: 'BusinessIndustryType';
                              IBCCode?: string | null;
                              IBCIndustryDescription?: string | null;
                              SICIndustryDescription?: string | null;
                              SICCode?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        optaCommercialPerilScoreData?: {
                          __typename?: 'OptaCommercialPerilScoreDataType';
                          Address?: {
                            __typename?: 'OptaAddressType';
                            StreetAddress?: string | null;
                            StreetNumber?: string | null;
                            StreetName?: string | null;
                            StreetType?: string | null;
                            StreetDirection?: string | null;
                            UnitNumber?: string | null;
                            Municipality?: string | null;
                            Province?: string | null;
                            PostalCode?: string | null;
                            LegalDescription?: {
                              __typename?: 'LegalDescriptionType';
                              Quarter?: string | null;
                              LegalSubdivision?: string | null;
                              Section?: string | null;
                              Township?: string | null;
                              Range?: string | null;
                              Meridian?: string | null;
                            } | null;
                          } | null;
                          PropertyPerilScores?: {
                            __typename?: 'PropertyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          CasualtyPerilScores?: {
                            __typename?: 'CasualtyCommercialPerilsType';
                            Peril?: Array<{
                              __typename?: 'PerilDetailsType';
                              LossCostScore?: number | null;
                              PerilType?: string | null;
                              PredictedLossCost?: number | null;
                            } | null> | null;
                          } | null;
                          AllPerilsScore?: {
                            __typename?: 'CommercialPerilScoreType';
                            LossCostScore?: number | null;
                            PredictedLossCost?: number | null;
                          } | null;
                        } | null;
                      } | null;
                      openingHours: Array<{
                        __typename?: 'OpeningHoursType';
                        day: number;
                        open?: any | null;
                        close?: any | null;
                      }>;
                    } | null;
                  }>;
                  details?: {
                    __typename?: 'AnswerInstanceDetailsType';
                    lat?: number | null;
                    lng?: number | null;
                    images: Array<{
                      __typename?: 'GoogleImageType';
                      description: string;
                      shortDescription: string;
                      category: CoreGoogleImageCategoryChoices;
                      data?: string | null;
                    }>;
                    google?: {
                      __typename?: 'GoogleDetailsType';
                      placeId?: string | null;
                      rating?: number | null;
                      numberOfRatings?: number | null;
                    } | null;
                    opta?: {
                      __typename?: 'OPTADetailsType';
                      optaFusData?: {
                        __typename?: 'OptaFusDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Commercial?: {
                          __typename?: 'FusProductCommercialType';
                          SearchMessage?: string | null;
                          CommercialGrade: Array<{
                            __typename?: 'CommercialGradeType';
                            PublicFireProtectionClassification?: number | null;
                            WaterSystem?: string | null;
                            RiskSCORDetail: Array<{
                              __typename?: 'RiskSCORDetailType';
                              RiskSCOR?: number | null;
                              Type?: string | null;
                              Adjustment: Array<{
                                __typename?: 'RiskSCORAdjustmentType';
                                Adjustment?: string | null;
                                AdjustmentType?: string | null;
                              }>;
                            }>;
                          }>;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        Dwelling?: {
                          __typename?: 'FusProductDwellingType';
                          SearchMessage?: string | null;
                          DwellingGrades?: {
                            __typename?: 'DwellingGradesType';
                            AllOtherAreasGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            FirehallProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                            HydrantProtectedGrade: Array<{
                              __typename?: 'DwellingProtectedGradeType';
                              Grade?: string | null;
                              WaterSystem?: string | null;
                            }>;
                          } | null;
                          RespondingFirehall?: {
                            __typename?: 'FirehallType';
                            Address?: string | null;
                            GoverningAgency?: string | null;
                            GradingDate?: any | null;
                            Name?: string | null;
                            PumperFlowImperialGallonsPerMinute?: number | null;
                            RoadDistanceKilometres?: number | null;
                            SuperiorTankerServiceAccreditationDate?: any | null;
                            FirefightersPerFireStation?: {
                              __typename?: 'FirefightersPerFireStationType';
                              FullTime?: number | null;
                              Volunteer?: number | null;
                            } | null;
                            ProtectionGradeTerritories?: {
                              __typename?: 'ProtectedGradeTerritoriesType';
                              Commercial?: string | null;
                              Dwelling?: string | null;
                            } | null;
                          } | null;
                        } | null;
                        EarthquakeCodes?: {
                          __typename?: 'EarthquakeCodesType';
                          SearchMessage?: string | null;
                          EarthquakeCode: Array<{
                            __typename?: 'EarthquakeCodeType';
                            Description?: string | null;
                            SystemName?: string | null;
                            Zone?: string | null;
                          }>;
                        } | null;
                        FusMaps?: {
                          __typename?: 'FusMapsType';
                          SearchMessage?: string | null;
                          FusMap: Array<{
                            __typename?: 'FusMapType';
                            Business?: string | null;
                            FusContent?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                        HydrantCounts?: {
                          __typename?: 'HydrantCountsType';
                          SearchMessage?: string | null;
                          HydrantCount: Array<{
                            __typename?: 'HydrantCountType';
                            NumberOfHydrants: number;
                            Ownership?: string | null;
                            RadiusMetres?: string | null;
                          }>;
                        } | null;
                      } | null;
                      optaFloodscoreData?: {
                        __typename?: 'OptaFloodScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        FloodScores?: {
                          __typename?: 'FloodScoresType';
                          FloodScore: Array<{
                            __typename?: 'FloodScoreType';
                            AverageAnnualLossScore?: number | null;
                            Deductible?: number | null;
                            Default?: boolean | null;
                            FloodScore?: number | null;
                            Limit?: number | null;
                            RateFactor?: number | null;
                            Zone?: string | null;
                            ResidentialModifiers?: {
                              __typename?: 'ResidentialConstructionFeaturesType';
                              ArchitecturalStyleType?: string | null;
                              AuxiliaryHeatingType?: string | null;
                              BathroomCount?: string | null;
                              CommercialIndicator?: string | null;
                              ExteriorWallType?: string | null;
                              FinishedBasement?: boolean | null;
                              FoundationType?: string | null;
                              GarageNumberOfCars?: number | null;
                              GarageType?: string | null;
                              KitchenCount?: string | null;
                              OutbuildingPresent?: string | null;
                              PlumbingType?: string | null;
                              PrimaryHeatingType?: string | null;
                              RoofType?: string | null;
                              SquareFootage?: number | null;
                              StoreyCount?: string | null;
                              SwimmingPoolType?: string | null;
                              YearBuilt?: string | null;
                              InspectionDetails?: {
                                __typename?: 'InspectionDetailsType';
                                InspectionDate?: any | null;
                                SponsoredInspection?: boolean | null;
                              } | null;
                            } | null;
                            MultiResidentialModifiers?: {
                              __typename?: 'MultiResidentialConstructionFeaturesType';
                              BasementPresent?: boolean | null;
                              ConstructionType?: string | null;
                              FloorLevel?: number | null;
                            } | null;
                            AverageAnnualLossValues?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                            AdditionalDetails: Array<{
                              __typename?: 'FloodDetailType';
                              Key?: string | null;
                              Value?: string | null;
                            }>;
                            Premium?: {
                              __typename?: 'FloodValueType';
                              Building?: number | null;
                              Contents?: number | null;
                              Total?: number | null;
                            } | null;
                          }>;
                          FloodMap: Array<{
                            __typename?: 'FloodMapType';
                            FloodEvent?: string | null;
                            Content?: {
                              __typename?: 'ContentType';
                              ContentURL?: string | null;
                              Expiry?: any | null;
                              MIMEType?: string | null;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      optaIclarifyCommercialConstructionData?: {
                        __typename?: 'OptaIclarifyCommercialConstructionDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        Messages?: {
                          __typename?: 'Messages';
                          Message?: Array<string | null> | null;
                        } | null;
                        Property?: {
                          __typename?: 'CommercialPropertyType';
                          LotSize?: number | null;
                          LotSizeUnitOfMeasurement?: string | null;
                          PropertyType?: string | null;
                          Buildings?: {
                            __typename?: 'BuildingsType';
                            Building: Array<{
                              __typename?: 'BuildingType';
                              StructureID?: string | null;
                              ConstructionFeatures?: {
                                __typename?: 'CommercialConstructionFeaturesType';
                                CombustibilityClass?: string | null;
                                ConstructionClass?: string | null;
                                FloorHeight?: number | null;
                                FloorHeightUnitOfMeasurement?: string | null;
                                LoadingDockPresent?: boolean | null;
                                NumberOfStoreys?: number | null;
                                NumberOfUnits?: number | null;
                                TotalFloorArea?: number | null;
                                TotalFloorAreaUnitOfMeasurement?: string | null;
                                YearBuilt?: number | null;
                                Basement?: {
                                  __typename?: 'CommercialBasementType';
                                  BasementArea?: number | null;
                                  BasementAreaUnitOfMeasurement?: string | null;
                                  BasementPresent?: boolean | null;
                                } | null;
                                BuildingUpdates?: {
                                  __typename?: 'BuildingUpdatesType';
                                  BuildingUpdate?: Array<number | null> | null;
                                } | null;
                                CommercialExteriorWallTypes?: {
                                  __typename?: 'CommercialExteriorWallTypesType';
                                  ExteriorWallType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                Elevator?: {
                                  __typename?: 'ElevatorType';
                                  ElevatorCount?: number | null;
                                  ElevatorPresent?: boolean | null;
                                } | null;
                                Heating?: {
                                  __typename?: 'CommercialHeatingType';
                                  HeatingFuelSources?: {
                                    __typename?: 'HeatingFuelSourcesType';
                                    HeatingFuelSource?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  HeatingTypes?: {
                                    __typename?: 'HeatingTypesType';
                                    HeatingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                Parking?: {
                                  __typename?: 'CommercialParkingType';
                                  ParkingPresent?: boolean | null;
                                  ParkingTypes?: {
                                    __typename?: 'CommercialParkingTypesType';
                                    ParkingType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                PlumbingTypes?: {
                                  __typename?: 'CommercialPlumbingType';
                                  PlumbingType?: Array<string | null> | null;
                                } | null;
                                RoofAttributes?: {
                                  __typename?: 'RoofAttributesType';
                                  RoofConstructions?: {
                                    __typename?: 'RoofConstructionsType';
                                    RoofConstruction?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  RoofSurfaces?: {
                                    __typename?: 'RoofSurfacesType';
                                    RoofSurface?: Array<string | null> | null;
                                  } | null;
                                  RoofTypes?: {
                                    __typename?: 'RoofTypesType';
                                    RoofType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                                WaterHeater?: {
                                  __typename?: 'CommercialWaterHeaterType';
                                  WaterHeaterFuelType?: Array<
                                    string | null
                                  > | null;
                                  WaterHeaterType?: Array<string | null> | null;
                                } | null;
                                Wiring?: {
                                  __typename?: 'CommercialWiringType';
                                  WiringPanelTypes?: {
                                    __typename?: 'CommercialWiringPanelTypesType';
                                    WiringPanelType?: Array<
                                      string | null
                                    > | null;
                                  } | null;
                                  WiringTypes?: {
                                    __typename?: 'CommercialWiringTypesType';
                                    WiringType?: Array<string | null> | null;
                                  } | null;
                                } | null;
                              } | null;
                              Protection?: {
                                __typename?: 'ProtectionType';
                                FireAlarmPresent?: boolean | null;
                                FireAlarmType?: string | null;
                                PhysicalSecurityPresent?: boolean | null;
                                SecurityAlarmPresent?: boolean | null;
                                SecurityAlarmType?: string | null;
                                SprinklerPresent?: boolean | null;
                                SprinklerTypeCode?: string | null;
                                PhysicalSecurityTypes?: {
                                  __typename?: 'PhysicalSecurityTypesType';
                                  PhysicalSecurityType?: Array<
                                    string | null
                                  > | null;
                                } | null;
                              } | null;
                            }>;
                          } | null;
                          Industry?: {
                            __typename?: 'BusinessIndustryType';
                            IBCCode?: string | null;
                            IBCIndustryDescription?: string | null;
                            SICIndustryDescription?: string | null;
                            SICCode?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      optaCommercialPerilScoreData?: {
                        __typename?: 'OptaCommercialPerilScoreDataType';
                        Address?: {
                          __typename?: 'OptaAddressType';
                          StreetAddress?: string | null;
                          StreetNumber?: string | null;
                          StreetName?: string | null;
                          StreetType?: string | null;
                          StreetDirection?: string | null;
                          UnitNumber?: string | null;
                          Municipality?: string | null;
                          Province?: string | null;
                          PostalCode?: string | null;
                          LegalDescription?: {
                            __typename?: 'LegalDescriptionType';
                            Quarter?: string | null;
                            LegalSubdivision?: string | null;
                            Section?: string | null;
                            Township?: string | null;
                            Range?: string | null;
                            Meridian?: string | null;
                          } | null;
                        } | null;
                        PropertyPerilScores?: {
                          __typename?: 'PropertyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        CasualtyPerilScores?: {
                          __typename?: 'CasualtyCommercialPerilsType';
                          Peril?: Array<{
                            __typename?: 'PerilDetailsType';
                            LossCostScore?: number | null;
                            PerilType?: string | null;
                            PredictedLossCost?: number | null;
                          } | null> | null;
                        } | null;
                        AllPerilsScore?: {
                          __typename?: 'CommercialPerilScoreType';
                          LossCostScore?: number | null;
                          PredictedLossCost?: number | null;
                        } | null;
                      } | null;
                    } | null;
                    openingHours: Array<{
                      __typename?: 'OpeningHoursType';
                      day: number;
                      open?: any | null;
                      close?: any | null;
                    }>;
                  } | null;
                }>;
                details?: {
                  __typename?: 'AnswerInstanceDetailsType';
                  lat?: number | null;
                  lng?: number | null;
                  images: Array<{
                    __typename?: 'GoogleImageType';
                    description: string;
                    shortDescription: string;
                    category: CoreGoogleImageCategoryChoices;
                    data?: string | null;
                  }>;
                  google?: {
                    __typename?: 'GoogleDetailsType';
                    placeId?: string | null;
                    rating?: number | null;
                    numberOfRatings?: number | null;
                  } | null;
                  opta?: {
                    __typename?: 'OPTADetailsType';
                    optaFusData?: {
                      __typename?: 'OptaFusDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Commercial?: {
                        __typename?: 'FusProductCommercialType';
                        SearchMessage?: string | null;
                        CommercialGrade: Array<{
                          __typename?: 'CommercialGradeType';
                          PublicFireProtectionClassification?: number | null;
                          WaterSystem?: string | null;
                          RiskSCORDetail: Array<{
                            __typename?: 'RiskSCORDetailType';
                            RiskSCOR?: number | null;
                            Type?: string | null;
                            Adjustment: Array<{
                              __typename?: 'RiskSCORAdjustmentType';
                              Adjustment?: string | null;
                              AdjustmentType?: string | null;
                            }>;
                          }>;
                        }>;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      Dwelling?: {
                        __typename?: 'FusProductDwellingType';
                        SearchMessage?: string | null;
                        DwellingGrades?: {
                          __typename?: 'DwellingGradesType';
                          AllOtherAreasGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          FirehallProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                          HydrantProtectedGrade: Array<{
                            __typename?: 'DwellingProtectedGradeType';
                            Grade?: string | null;
                            WaterSystem?: string | null;
                          }>;
                        } | null;
                        RespondingFirehall?: {
                          __typename?: 'FirehallType';
                          Address?: string | null;
                          GoverningAgency?: string | null;
                          GradingDate?: any | null;
                          Name?: string | null;
                          PumperFlowImperialGallonsPerMinute?: number | null;
                          RoadDistanceKilometres?: number | null;
                          SuperiorTankerServiceAccreditationDate?: any | null;
                          FirefightersPerFireStation?: {
                            __typename?: 'FirefightersPerFireStationType';
                            FullTime?: number | null;
                            Volunteer?: number | null;
                          } | null;
                          ProtectionGradeTerritories?: {
                            __typename?: 'ProtectedGradeTerritoriesType';
                            Commercial?: string | null;
                            Dwelling?: string | null;
                          } | null;
                        } | null;
                      } | null;
                      EarthquakeCodes?: {
                        __typename?: 'EarthquakeCodesType';
                        SearchMessage?: string | null;
                        EarthquakeCode: Array<{
                          __typename?: 'EarthquakeCodeType';
                          Description?: string | null;
                          SystemName?: string | null;
                          Zone?: string | null;
                        }>;
                      } | null;
                      FusMaps?: {
                        __typename?: 'FusMapsType';
                        SearchMessage?: string | null;
                        FusMap: Array<{
                          __typename?: 'FusMapType';
                          Business?: string | null;
                          FusContent?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                      HydrantCounts?: {
                        __typename?: 'HydrantCountsType';
                        SearchMessage?: string | null;
                        HydrantCount: Array<{
                          __typename?: 'HydrantCountType';
                          NumberOfHydrants: number;
                          Ownership?: string | null;
                          RadiusMetres?: string | null;
                        }>;
                      } | null;
                    } | null;
                    optaFloodscoreData?: {
                      __typename?: 'OptaFloodScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      FloodScores?: {
                        __typename?: 'FloodScoresType';
                        FloodScore: Array<{
                          __typename?: 'FloodScoreType';
                          AverageAnnualLossScore?: number | null;
                          Deductible?: number | null;
                          Default?: boolean | null;
                          FloodScore?: number | null;
                          Limit?: number | null;
                          RateFactor?: number | null;
                          Zone?: string | null;
                          ResidentialModifiers?: {
                            __typename?: 'ResidentialConstructionFeaturesType';
                            ArchitecturalStyleType?: string | null;
                            AuxiliaryHeatingType?: string | null;
                            BathroomCount?: string | null;
                            CommercialIndicator?: string | null;
                            ExteriorWallType?: string | null;
                            FinishedBasement?: boolean | null;
                            FoundationType?: string | null;
                            GarageNumberOfCars?: number | null;
                            GarageType?: string | null;
                            KitchenCount?: string | null;
                            OutbuildingPresent?: string | null;
                            PlumbingType?: string | null;
                            PrimaryHeatingType?: string | null;
                            RoofType?: string | null;
                            SquareFootage?: number | null;
                            StoreyCount?: string | null;
                            SwimmingPoolType?: string | null;
                            YearBuilt?: string | null;
                            InspectionDetails?: {
                              __typename?: 'InspectionDetailsType';
                              InspectionDate?: any | null;
                              SponsoredInspection?: boolean | null;
                            } | null;
                          } | null;
                          MultiResidentialModifiers?: {
                            __typename?: 'MultiResidentialConstructionFeaturesType';
                            BasementPresent?: boolean | null;
                            ConstructionType?: string | null;
                            FloorLevel?: number | null;
                          } | null;
                          AverageAnnualLossValues?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                          AdditionalDetails: Array<{
                            __typename?: 'FloodDetailType';
                            Key?: string | null;
                            Value?: string | null;
                          }>;
                          Premium?: {
                            __typename?: 'FloodValueType';
                            Building?: number | null;
                            Contents?: number | null;
                            Total?: number | null;
                          } | null;
                        }>;
                        FloodMap: Array<{
                          __typename?: 'FloodMapType';
                          FloodEvent?: string | null;
                          Content?: {
                            __typename?: 'ContentType';
                            ContentURL?: string | null;
                            Expiry?: any | null;
                            MIMEType?: string | null;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    optaIclarifyCommercialConstructionData?: {
                      __typename?: 'OptaIclarifyCommercialConstructionDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      Messages?: {
                        __typename?: 'Messages';
                        Message?: Array<string | null> | null;
                      } | null;
                      Property?: {
                        __typename?: 'CommercialPropertyType';
                        LotSize?: number | null;
                        LotSizeUnitOfMeasurement?: string | null;
                        PropertyType?: string | null;
                        Buildings?: {
                          __typename?: 'BuildingsType';
                          Building: Array<{
                            __typename?: 'BuildingType';
                            StructureID?: string | null;
                            ConstructionFeatures?: {
                              __typename?: 'CommercialConstructionFeaturesType';
                              CombustibilityClass?: string | null;
                              ConstructionClass?: string | null;
                              FloorHeight?: number | null;
                              FloorHeightUnitOfMeasurement?: string | null;
                              LoadingDockPresent?: boolean | null;
                              NumberOfStoreys?: number | null;
                              NumberOfUnits?: number | null;
                              TotalFloorArea?: number | null;
                              TotalFloorAreaUnitOfMeasurement?: string | null;
                              YearBuilt?: number | null;
                              Basement?: {
                                __typename?: 'CommercialBasementType';
                                BasementArea?: number | null;
                                BasementAreaUnitOfMeasurement?: string | null;
                                BasementPresent?: boolean | null;
                              } | null;
                              BuildingUpdates?: {
                                __typename?: 'BuildingUpdatesType';
                                BuildingUpdate?: Array<number | null> | null;
                              } | null;
                              CommercialExteriorWallTypes?: {
                                __typename?: 'CommercialExteriorWallTypesType';
                                ExteriorWallType?: Array<string | null> | null;
                              } | null;
                              Elevator?: {
                                __typename?: 'ElevatorType';
                                ElevatorCount?: number | null;
                                ElevatorPresent?: boolean | null;
                              } | null;
                              Heating?: {
                                __typename?: 'CommercialHeatingType';
                                HeatingFuelSources?: {
                                  __typename?: 'HeatingFuelSourcesType';
                                  HeatingFuelSource?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                HeatingTypes?: {
                                  __typename?: 'HeatingTypesType';
                                  HeatingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              Parking?: {
                                __typename?: 'CommercialParkingType';
                                ParkingPresent?: boolean | null;
                                ParkingTypes?: {
                                  __typename?: 'CommercialParkingTypesType';
                                  ParkingType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              PlumbingTypes?: {
                                __typename?: 'CommercialPlumbingType';
                                PlumbingType?: Array<string | null> | null;
                              } | null;
                              RoofAttributes?: {
                                __typename?: 'RoofAttributesType';
                                RoofConstructions?: {
                                  __typename?: 'RoofConstructionsType';
                                  RoofConstruction?: Array<
                                    string | null
                                  > | null;
                                } | null;
                                RoofSurfaces?: {
                                  __typename?: 'RoofSurfacesType';
                                  RoofSurface?: Array<string | null> | null;
                                } | null;
                                RoofTypes?: {
                                  __typename?: 'RoofTypesType';
                                  RoofType?: Array<string | null> | null;
                                } | null;
                              } | null;
                              WaterHeater?: {
                                __typename?: 'CommercialWaterHeaterType';
                                WaterHeaterFuelType?: Array<
                                  string | null
                                > | null;
                                WaterHeaterType?: Array<string | null> | null;
                              } | null;
                              Wiring?: {
                                __typename?: 'CommercialWiringType';
                                WiringPanelTypes?: {
                                  __typename?: 'CommercialWiringPanelTypesType';
                                  WiringPanelType?: Array<string | null> | null;
                                } | null;
                                WiringTypes?: {
                                  __typename?: 'CommercialWiringTypesType';
                                  WiringType?: Array<string | null> | null;
                                } | null;
                              } | null;
                            } | null;
                            Protection?: {
                              __typename?: 'ProtectionType';
                              FireAlarmPresent?: boolean | null;
                              FireAlarmType?: string | null;
                              PhysicalSecurityPresent?: boolean | null;
                              SecurityAlarmPresent?: boolean | null;
                              SecurityAlarmType?: string | null;
                              SprinklerPresent?: boolean | null;
                              SprinklerTypeCode?: string | null;
                              PhysicalSecurityTypes?: {
                                __typename?: 'PhysicalSecurityTypesType';
                                PhysicalSecurityType?: Array<
                                  string | null
                                > | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                        Industry?: {
                          __typename?: 'BusinessIndustryType';
                          IBCCode?: string | null;
                          IBCIndustryDescription?: string | null;
                          SICIndustryDescription?: string | null;
                          SICCode?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    optaCommercialPerilScoreData?: {
                      __typename?: 'OptaCommercialPerilScoreDataType';
                      Address?: {
                        __typename?: 'OptaAddressType';
                        StreetAddress?: string | null;
                        StreetNumber?: string | null;
                        StreetName?: string | null;
                        StreetType?: string | null;
                        StreetDirection?: string | null;
                        UnitNumber?: string | null;
                        Municipality?: string | null;
                        Province?: string | null;
                        PostalCode?: string | null;
                        LegalDescription?: {
                          __typename?: 'LegalDescriptionType';
                          Quarter?: string | null;
                          LegalSubdivision?: string | null;
                          Section?: string | null;
                          Township?: string | null;
                          Range?: string | null;
                          Meridian?: string | null;
                        } | null;
                      } | null;
                      PropertyPerilScores?: {
                        __typename?: 'PropertyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      CasualtyPerilScores?: {
                        __typename?: 'CasualtyCommercialPerilsType';
                        Peril?: Array<{
                          __typename?: 'PerilDetailsType';
                          LossCostScore?: number | null;
                          PerilType?: string | null;
                          PredictedLossCost?: number | null;
                        } | null> | null;
                      } | null;
                      AllPerilsScore?: {
                        __typename?: 'CommercialPerilScoreType';
                        LossCostScore?: number | null;
                        PredictedLossCost?: number | null;
                      } | null;
                    } | null;
                  } | null;
                  openingHours: Array<{
                    __typename?: 'OpeningHoursType';
                    day: number;
                    open?: any | null;
                    close?: any | null;
                  }>;
                } | null;
              }>;
              details?: {
                __typename?: 'AnswerInstanceDetailsType';
                lat?: number | null;
                lng?: number | null;
                images: Array<{
                  __typename?: 'GoogleImageType';
                  description: string;
                  shortDescription: string;
                  category: CoreGoogleImageCategoryChoices;
                  data?: string | null;
                }>;
                google?: {
                  __typename?: 'GoogleDetailsType';
                  placeId?: string | null;
                  rating?: number | null;
                  numberOfRatings?: number | null;
                } | null;
                opta?: {
                  __typename?: 'OPTADetailsType';
                  optaFusData?: {
                    __typename?: 'OptaFusDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Commercial?: {
                      __typename?: 'FusProductCommercialType';
                      SearchMessage?: string | null;
                      CommercialGrade: Array<{
                        __typename?: 'CommercialGradeType';
                        PublicFireProtectionClassification?: number | null;
                        WaterSystem?: string | null;
                        RiskSCORDetail: Array<{
                          __typename?: 'RiskSCORDetailType';
                          RiskSCOR?: number | null;
                          Type?: string | null;
                          Adjustment: Array<{
                            __typename?: 'RiskSCORAdjustmentType';
                            Adjustment?: string | null;
                            AdjustmentType?: string | null;
                          }>;
                        }>;
                      }>;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    Dwelling?: {
                      __typename?: 'FusProductDwellingType';
                      SearchMessage?: string | null;
                      DwellingGrades?: {
                        __typename?: 'DwellingGradesType';
                        AllOtherAreasGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        FirehallProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                        HydrantProtectedGrade: Array<{
                          __typename?: 'DwellingProtectedGradeType';
                          Grade?: string | null;
                          WaterSystem?: string | null;
                        }>;
                      } | null;
                      RespondingFirehall?: {
                        __typename?: 'FirehallType';
                        Address?: string | null;
                        GoverningAgency?: string | null;
                        GradingDate?: any | null;
                        Name?: string | null;
                        PumperFlowImperialGallonsPerMinute?: number | null;
                        RoadDistanceKilometres?: number | null;
                        SuperiorTankerServiceAccreditationDate?: any | null;
                        FirefightersPerFireStation?: {
                          __typename?: 'FirefightersPerFireStationType';
                          FullTime?: number | null;
                          Volunteer?: number | null;
                        } | null;
                        ProtectionGradeTerritories?: {
                          __typename?: 'ProtectedGradeTerritoriesType';
                          Commercial?: string | null;
                          Dwelling?: string | null;
                        } | null;
                      } | null;
                    } | null;
                    EarthquakeCodes?: {
                      __typename?: 'EarthquakeCodesType';
                      SearchMessage?: string | null;
                      EarthquakeCode: Array<{
                        __typename?: 'EarthquakeCodeType';
                        Description?: string | null;
                        SystemName?: string | null;
                        Zone?: string | null;
                      }>;
                    } | null;
                    FusMaps?: {
                      __typename?: 'FusMapsType';
                      SearchMessage?: string | null;
                      FusMap: Array<{
                        __typename?: 'FusMapType';
                        Business?: string | null;
                        FusContent?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                    HydrantCounts?: {
                      __typename?: 'HydrantCountsType';
                      SearchMessage?: string | null;
                      HydrantCount: Array<{
                        __typename?: 'HydrantCountType';
                        NumberOfHydrants: number;
                        Ownership?: string | null;
                        RadiusMetres?: string | null;
                      }>;
                    } | null;
                  } | null;
                  optaFloodscoreData?: {
                    __typename?: 'OptaFloodScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    FloodScores?: {
                      __typename?: 'FloodScoresType';
                      FloodScore: Array<{
                        __typename?: 'FloodScoreType';
                        AverageAnnualLossScore?: number | null;
                        Deductible?: number | null;
                        Default?: boolean | null;
                        FloodScore?: number | null;
                        Limit?: number | null;
                        RateFactor?: number | null;
                        Zone?: string | null;
                        ResidentialModifiers?: {
                          __typename?: 'ResidentialConstructionFeaturesType';
                          ArchitecturalStyleType?: string | null;
                          AuxiliaryHeatingType?: string | null;
                          BathroomCount?: string | null;
                          CommercialIndicator?: string | null;
                          ExteriorWallType?: string | null;
                          FinishedBasement?: boolean | null;
                          FoundationType?: string | null;
                          GarageNumberOfCars?: number | null;
                          GarageType?: string | null;
                          KitchenCount?: string | null;
                          OutbuildingPresent?: string | null;
                          PlumbingType?: string | null;
                          PrimaryHeatingType?: string | null;
                          RoofType?: string | null;
                          SquareFootage?: number | null;
                          StoreyCount?: string | null;
                          SwimmingPoolType?: string | null;
                          YearBuilt?: string | null;
                          InspectionDetails?: {
                            __typename?: 'InspectionDetailsType';
                            InspectionDate?: any | null;
                            SponsoredInspection?: boolean | null;
                          } | null;
                        } | null;
                        MultiResidentialModifiers?: {
                          __typename?: 'MultiResidentialConstructionFeaturesType';
                          BasementPresent?: boolean | null;
                          ConstructionType?: string | null;
                          FloorLevel?: number | null;
                        } | null;
                        AverageAnnualLossValues?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                        AdditionalDetails: Array<{
                          __typename?: 'FloodDetailType';
                          Key?: string | null;
                          Value?: string | null;
                        }>;
                        Premium?: {
                          __typename?: 'FloodValueType';
                          Building?: number | null;
                          Contents?: number | null;
                          Total?: number | null;
                        } | null;
                      }>;
                      FloodMap: Array<{
                        __typename?: 'FloodMapType';
                        FloodEvent?: string | null;
                        Content?: {
                          __typename?: 'ContentType';
                          ContentURL?: string | null;
                          Expiry?: any | null;
                          MIMEType?: string | null;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  optaIclarifyCommercialConstructionData?: {
                    __typename?: 'OptaIclarifyCommercialConstructionDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    Messages?: {
                      __typename?: 'Messages';
                      Message?: Array<string | null> | null;
                    } | null;
                    Property?: {
                      __typename?: 'CommercialPropertyType';
                      LotSize?: number | null;
                      LotSizeUnitOfMeasurement?: string | null;
                      PropertyType?: string | null;
                      Buildings?: {
                        __typename?: 'BuildingsType';
                        Building: Array<{
                          __typename?: 'BuildingType';
                          StructureID?: string | null;
                          ConstructionFeatures?: {
                            __typename?: 'CommercialConstructionFeaturesType';
                            CombustibilityClass?: string | null;
                            ConstructionClass?: string | null;
                            FloorHeight?: number | null;
                            FloorHeightUnitOfMeasurement?: string | null;
                            LoadingDockPresent?: boolean | null;
                            NumberOfStoreys?: number | null;
                            NumberOfUnits?: number | null;
                            TotalFloorArea?: number | null;
                            TotalFloorAreaUnitOfMeasurement?: string | null;
                            YearBuilt?: number | null;
                            Basement?: {
                              __typename?: 'CommercialBasementType';
                              BasementArea?: number | null;
                              BasementAreaUnitOfMeasurement?: string | null;
                              BasementPresent?: boolean | null;
                            } | null;
                            BuildingUpdates?: {
                              __typename?: 'BuildingUpdatesType';
                              BuildingUpdate?: Array<number | null> | null;
                            } | null;
                            CommercialExteriorWallTypes?: {
                              __typename?: 'CommercialExteriorWallTypesType';
                              ExteriorWallType?: Array<string | null> | null;
                            } | null;
                            Elevator?: {
                              __typename?: 'ElevatorType';
                              ElevatorCount?: number | null;
                              ElevatorPresent?: boolean | null;
                            } | null;
                            Heating?: {
                              __typename?: 'CommercialHeatingType';
                              HeatingFuelSources?: {
                                __typename?: 'HeatingFuelSourcesType';
                                HeatingFuelSource?: Array<string | null> | null;
                              } | null;
                              HeatingTypes?: {
                                __typename?: 'HeatingTypesType';
                                HeatingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            Parking?: {
                              __typename?: 'CommercialParkingType';
                              ParkingPresent?: boolean | null;
                              ParkingTypes?: {
                                __typename?: 'CommercialParkingTypesType';
                                ParkingType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            PlumbingTypes?: {
                              __typename?: 'CommercialPlumbingType';
                              PlumbingType?: Array<string | null> | null;
                            } | null;
                            RoofAttributes?: {
                              __typename?: 'RoofAttributesType';
                              RoofConstructions?: {
                                __typename?: 'RoofConstructionsType';
                                RoofConstruction?: Array<string | null> | null;
                              } | null;
                              RoofSurfaces?: {
                                __typename?: 'RoofSurfacesType';
                                RoofSurface?: Array<string | null> | null;
                              } | null;
                              RoofTypes?: {
                                __typename?: 'RoofTypesType';
                                RoofType?: Array<string | null> | null;
                              } | null;
                            } | null;
                            WaterHeater?: {
                              __typename?: 'CommercialWaterHeaterType';
                              WaterHeaterFuelType?: Array<string | null> | null;
                              WaterHeaterType?: Array<string | null> | null;
                            } | null;
                            Wiring?: {
                              __typename?: 'CommercialWiringType';
                              WiringPanelTypes?: {
                                __typename?: 'CommercialWiringPanelTypesType';
                                WiringPanelType?: Array<string | null> | null;
                              } | null;
                              WiringTypes?: {
                                __typename?: 'CommercialWiringTypesType';
                                WiringType?: Array<string | null> | null;
                              } | null;
                            } | null;
                          } | null;
                          Protection?: {
                            __typename?: 'ProtectionType';
                            FireAlarmPresent?: boolean | null;
                            FireAlarmType?: string | null;
                            PhysicalSecurityPresent?: boolean | null;
                            SecurityAlarmPresent?: boolean | null;
                            SecurityAlarmType?: string | null;
                            SprinklerPresent?: boolean | null;
                            SprinklerTypeCode?: string | null;
                            PhysicalSecurityTypes?: {
                              __typename?: 'PhysicalSecurityTypesType';
                              PhysicalSecurityType?: Array<
                                string | null
                              > | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                      Industry?: {
                        __typename?: 'BusinessIndustryType';
                        IBCCode?: string | null;
                        IBCIndustryDescription?: string | null;
                        SICIndustryDescription?: string | null;
                        SICCode?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  optaCommercialPerilScoreData?: {
                    __typename?: 'OptaCommercialPerilScoreDataType';
                    Address?: {
                      __typename?: 'OptaAddressType';
                      StreetAddress?: string | null;
                      StreetNumber?: string | null;
                      StreetName?: string | null;
                      StreetType?: string | null;
                      StreetDirection?: string | null;
                      UnitNumber?: string | null;
                      Municipality?: string | null;
                      Province?: string | null;
                      PostalCode?: string | null;
                      LegalDescription?: {
                        __typename?: 'LegalDescriptionType';
                        Quarter?: string | null;
                        LegalSubdivision?: string | null;
                        Section?: string | null;
                        Township?: string | null;
                        Range?: string | null;
                        Meridian?: string | null;
                      } | null;
                    } | null;
                    PropertyPerilScores?: {
                      __typename?: 'PropertyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    CasualtyPerilScores?: {
                      __typename?: 'CasualtyCommercialPerilsType';
                      Peril?: Array<{
                        __typename?: 'PerilDetailsType';
                        LossCostScore?: number | null;
                        PerilType?: string | null;
                        PredictedLossCost?: number | null;
                      } | null> | null;
                    } | null;
                    AllPerilsScore?: {
                      __typename?: 'CommercialPerilScoreType';
                      LossCostScore?: number | null;
                      PredictedLossCost?: number | null;
                    } | null;
                  } | null;
                } | null;
                openingHours: Array<{
                  __typename?: 'OpeningHoursType';
                  day: number;
                  open?: any | null;
                  close?: any | null;
                }>;
              } | null;
            }>;
            details?: {
              __typename?: 'AnswerInstanceDetailsType';
              lat?: number | null;
              lng?: number | null;
              images: Array<{
                __typename?: 'GoogleImageType';
                description: string;
                shortDescription: string;
                category: CoreGoogleImageCategoryChoices;
                data?: string | null;
              }>;
              google?: {
                __typename?: 'GoogleDetailsType';
                placeId?: string | null;
                rating?: number | null;
                numberOfRatings?: number | null;
              } | null;
              opta?: {
                __typename?: 'OPTADetailsType';
                optaFusData?: {
                  __typename?: 'OptaFusDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Commercial?: {
                    __typename?: 'FusProductCommercialType';
                    SearchMessage?: string | null;
                    CommercialGrade: Array<{
                      __typename?: 'CommercialGradeType';
                      PublicFireProtectionClassification?: number | null;
                      WaterSystem?: string | null;
                      RiskSCORDetail: Array<{
                        __typename?: 'RiskSCORDetailType';
                        RiskSCOR?: number | null;
                        Type?: string | null;
                        Adjustment: Array<{
                          __typename?: 'RiskSCORAdjustmentType';
                          Adjustment?: string | null;
                          AdjustmentType?: string | null;
                        }>;
                      }>;
                    }>;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  Dwelling?: {
                    __typename?: 'FusProductDwellingType';
                    SearchMessage?: string | null;
                    DwellingGrades?: {
                      __typename?: 'DwellingGradesType';
                      AllOtherAreasGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      FirehallProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                      HydrantProtectedGrade: Array<{
                        __typename?: 'DwellingProtectedGradeType';
                        Grade?: string | null;
                        WaterSystem?: string | null;
                      }>;
                    } | null;
                    RespondingFirehall?: {
                      __typename?: 'FirehallType';
                      Address?: string | null;
                      GoverningAgency?: string | null;
                      GradingDate?: any | null;
                      Name?: string | null;
                      PumperFlowImperialGallonsPerMinute?: number | null;
                      RoadDistanceKilometres?: number | null;
                      SuperiorTankerServiceAccreditationDate?: any | null;
                      FirefightersPerFireStation?: {
                        __typename?: 'FirefightersPerFireStationType';
                        FullTime?: number | null;
                        Volunteer?: number | null;
                      } | null;
                      ProtectionGradeTerritories?: {
                        __typename?: 'ProtectedGradeTerritoriesType';
                        Commercial?: string | null;
                        Dwelling?: string | null;
                      } | null;
                    } | null;
                  } | null;
                  EarthquakeCodes?: {
                    __typename?: 'EarthquakeCodesType';
                    SearchMessage?: string | null;
                    EarthquakeCode: Array<{
                      __typename?: 'EarthquakeCodeType';
                      Description?: string | null;
                      SystemName?: string | null;
                      Zone?: string | null;
                    }>;
                  } | null;
                  FusMaps?: {
                    __typename?: 'FusMapsType';
                    SearchMessage?: string | null;
                    FusMap: Array<{
                      __typename?: 'FusMapType';
                      Business?: string | null;
                      FusContent?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                  HydrantCounts?: {
                    __typename?: 'HydrantCountsType';
                    SearchMessage?: string | null;
                    HydrantCount: Array<{
                      __typename?: 'HydrantCountType';
                      NumberOfHydrants: number;
                      Ownership?: string | null;
                      RadiusMetres?: string | null;
                    }>;
                  } | null;
                } | null;
                optaFloodscoreData?: {
                  __typename?: 'OptaFloodScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  FloodScores?: {
                    __typename?: 'FloodScoresType';
                    FloodScore: Array<{
                      __typename?: 'FloodScoreType';
                      AverageAnnualLossScore?: number | null;
                      Deductible?: number | null;
                      Default?: boolean | null;
                      FloodScore?: number | null;
                      Limit?: number | null;
                      RateFactor?: number | null;
                      Zone?: string | null;
                      ResidentialModifiers?: {
                        __typename?: 'ResidentialConstructionFeaturesType';
                        ArchitecturalStyleType?: string | null;
                        AuxiliaryHeatingType?: string | null;
                        BathroomCount?: string | null;
                        CommercialIndicator?: string | null;
                        ExteriorWallType?: string | null;
                        FinishedBasement?: boolean | null;
                        FoundationType?: string | null;
                        GarageNumberOfCars?: number | null;
                        GarageType?: string | null;
                        KitchenCount?: string | null;
                        OutbuildingPresent?: string | null;
                        PlumbingType?: string | null;
                        PrimaryHeatingType?: string | null;
                        RoofType?: string | null;
                        SquareFootage?: number | null;
                        StoreyCount?: string | null;
                        SwimmingPoolType?: string | null;
                        YearBuilt?: string | null;
                        InspectionDetails?: {
                          __typename?: 'InspectionDetailsType';
                          InspectionDate?: any | null;
                          SponsoredInspection?: boolean | null;
                        } | null;
                      } | null;
                      MultiResidentialModifiers?: {
                        __typename?: 'MultiResidentialConstructionFeaturesType';
                        BasementPresent?: boolean | null;
                        ConstructionType?: string | null;
                        FloorLevel?: number | null;
                      } | null;
                      AverageAnnualLossValues?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                      AdditionalDetails: Array<{
                        __typename?: 'FloodDetailType';
                        Key?: string | null;
                        Value?: string | null;
                      }>;
                      Premium?: {
                        __typename?: 'FloodValueType';
                        Building?: number | null;
                        Contents?: number | null;
                        Total?: number | null;
                      } | null;
                    }>;
                    FloodMap: Array<{
                      __typename?: 'FloodMapType';
                      FloodEvent?: string | null;
                      Content?: {
                        __typename?: 'ContentType';
                        ContentURL?: string | null;
                        Expiry?: any | null;
                        MIMEType?: string | null;
                      } | null;
                    }>;
                  } | null;
                } | null;
                optaIclarifyCommercialConstructionData?: {
                  __typename?: 'OptaIclarifyCommercialConstructionDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  Messages?: {
                    __typename?: 'Messages';
                    Message?: Array<string | null> | null;
                  } | null;
                  Property?: {
                    __typename?: 'CommercialPropertyType';
                    LotSize?: number | null;
                    LotSizeUnitOfMeasurement?: string | null;
                    PropertyType?: string | null;
                    Buildings?: {
                      __typename?: 'BuildingsType';
                      Building: Array<{
                        __typename?: 'BuildingType';
                        StructureID?: string | null;
                        ConstructionFeatures?: {
                          __typename?: 'CommercialConstructionFeaturesType';
                          CombustibilityClass?: string | null;
                          ConstructionClass?: string | null;
                          FloorHeight?: number | null;
                          FloorHeightUnitOfMeasurement?: string | null;
                          LoadingDockPresent?: boolean | null;
                          NumberOfStoreys?: number | null;
                          NumberOfUnits?: number | null;
                          TotalFloorArea?: number | null;
                          TotalFloorAreaUnitOfMeasurement?: string | null;
                          YearBuilt?: number | null;
                          Basement?: {
                            __typename?: 'CommercialBasementType';
                            BasementArea?: number | null;
                            BasementAreaUnitOfMeasurement?: string | null;
                            BasementPresent?: boolean | null;
                          } | null;
                          BuildingUpdates?: {
                            __typename?: 'BuildingUpdatesType';
                            BuildingUpdate?: Array<number | null> | null;
                          } | null;
                          CommercialExteriorWallTypes?: {
                            __typename?: 'CommercialExteriorWallTypesType';
                            ExteriorWallType?: Array<string | null> | null;
                          } | null;
                          Elevator?: {
                            __typename?: 'ElevatorType';
                            ElevatorCount?: number | null;
                            ElevatorPresent?: boolean | null;
                          } | null;
                          Heating?: {
                            __typename?: 'CommercialHeatingType';
                            HeatingFuelSources?: {
                              __typename?: 'HeatingFuelSourcesType';
                              HeatingFuelSource?: Array<string | null> | null;
                            } | null;
                            HeatingTypes?: {
                              __typename?: 'HeatingTypesType';
                              HeatingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          Parking?: {
                            __typename?: 'CommercialParkingType';
                            ParkingPresent?: boolean | null;
                            ParkingTypes?: {
                              __typename?: 'CommercialParkingTypesType';
                              ParkingType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          PlumbingTypes?: {
                            __typename?: 'CommercialPlumbingType';
                            PlumbingType?: Array<string | null> | null;
                          } | null;
                          RoofAttributes?: {
                            __typename?: 'RoofAttributesType';
                            RoofConstructions?: {
                              __typename?: 'RoofConstructionsType';
                              RoofConstruction?: Array<string | null> | null;
                            } | null;
                            RoofSurfaces?: {
                              __typename?: 'RoofSurfacesType';
                              RoofSurface?: Array<string | null> | null;
                            } | null;
                            RoofTypes?: {
                              __typename?: 'RoofTypesType';
                              RoofType?: Array<string | null> | null;
                            } | null;
                          } | null;
                          WaterHeater?: {
                            __typename?: 'CommercialWaterHeaterType';
                            WaterHeaterFuelType?: Array<string | null> | null;
                            WaterHeaterType?: Array<string | null> | null;
                          } | null;
                          Wiring?: {
                            __typename?: 'CommercialWiringType';
                            WiringPanelTypes?: {
                              __typename?: 'CommercialWiringPanelTypesType';
                              WiringPanelType?: Array<string | null> | null;
                            } | null;
                            WiringTypes?: {
                              __typename?: 'CommercialWiringTypesType';
                              WiringType?: Array<string | null> | null;
                            } | null;
                          } | null;
                        } | null;
                        Protection?: {
                          __typename?: 'ProtectionType';
                          FireAlarmPresent?: boolean | null;
                          FireAlarmType?: string | null;
                          PhysicalSecurityPresent?: boolean | null;
                          SecurityAlarmPresent?: boolean | null;
                          SecurityAlarmType?: string | null;
                          SprinklerPresent?: boolean | null;
                          SprinklerTypeCode?: string | null;
                          PhysicalSecurityTypes?: {
                            __typename?: 'PhysicalSecurityTypesType';
                            PhysicalSecurityType?: Array<string | null> | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                    Industry?: {
                      __typename?: 'BusinessIndustryType';
                      IBCCode?: string | null;
                      IBCIndustryDescription?: string | null;
                      SICIndustryDescription?: string | null;
                      SICCode?: string | null;
                    } | null;
                  } | null;
                } | null;
                optaCommercialPerilScoreData?: {
                  __typename?: 'OptaCommercialPerilScoreDataType';
                  Address?: {
                    __typename?: 'OptaAddressType';
                    StreetAddress?: string | null;
                    StreetNumber?: string | null;
                    StreetName?: string | null;
                    StreetType?: string | null;
                    StreetDirection?: string | null;
                    UnitNumber?: string | null;
                    Municipality?: string | null;
                    Province?: string | null;
                    PostalCode?: string | null;
                    LegalDescription?: {
                      __typename?: 'LegalDescriptionType';
                      Quarter?: string | null;
                      LegalSubdivision?: string | null;
                      Section?: string | null;
                      Township?: string | null;
                      Range?: string | null;
                      Meridian?: string | null;
                    } | null;
                  } | null;
                  PropertyPerilScores?: {
                    __typename?: 'PropertyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  CasualtyPerilScores?: {
                    __typename?: 'CasualtyCommercialPerilsType';
                    Peril?: Array<{
                      __typename?: 'PerilDetailsType';
                      LossCostScore?: number | null;
                      PerilType?: string | null;
                      PredictedLossCost?: number | null;
                    } | null> | null;
                  } | null;
                  AllPerilsScore?: {
                    __typename?: 'CommercialPerilScoreType';
                    LossCostScore?: number | null;
                    PredictedLossCost?: number | null;
                  } | null;
                } | null;
              } | null;
              openingHours: Array<{
                __typename?: 'OpeningHoursType';
                day: number;
                open?: any | null;
                close?: any | null;
              }>;
            } | null;
          }>;
        }>;
        signature?: {
          __typename?: 'SignatureType';
          signatureData?: string | null;
          signatureTime?: any | null;
        } | null;
        bindings: Array<{
          __typename?: 'BindingType';
          id: string;
          label: string;
          bound: boolean;
          unboundReason: string;
        }>;
      }
    | null;
};

export type GetQuotePdfQueryVariables = Exact<{
  token: Scalars['String'];
  uniqueID: Scalars['String'];
  archiveID?: InputMaybe<Scalars['String']>;
  renewalOnly?: InputMaybe<Scalars['Boolean']>;
  downloadType?: InputMaybe<Scalars['String']>;
}>;

export type GetQuotePdfQuery = {
  __typename?: 'Query';
  quotePdf?: {
    __typename?: 'QuotePdf';
    businessName?: string | null;
    pdfString?: string | null;
  } | null;
};

export type MarkAsRenewalInProgressMutationVariables = Exact<{
  uuid: Scalars['UUID'];
}>;

export type MarkAsRenewalInProgressMutation = {
  __typename?: 'Mutation';
  markAsRenewalInProgress?: {
    __typename?: 'MarkAsRenewalInProgress';
    ok?: boolean | null;
  } | null;
};

export type MarkAsRenewalLinkGeneratedMutationVariables = Exact<{
  uuid: Scalars['UUID'];
  token: Scalars['String'];
}>;

export type MarkAsRenewalLinkGeneratedMutation = {
  __typename?: 'Mutation';
  markAsRenewalLinkGenerated?: {
    __typename?: 'MarkAsRenewalLinkGenerated';
    ok?: boolean | null;
    link?: string | null;
  } | null;
};

export type MarkQuoteAsConfirmationInProgressMutationVariables = Exact<{
  hash: Scalars['UUID'];
}>;

export type MarkQuoteAsConfirmationInProgressMutation = {
  __typename?: 'Mutation';
  markQuoteAsConfirmationInProgress?: {
    __typename?: 'MarkQuoteAsConfirmationInProgress';
    ok?: boolean | null;
    uuid?: any | null;
  } | null;
};

export type EditRateMutationVariables = Exact<{
  displayName: Scalars['String'];
  internalName: Scalars['String'];
  group: Scalars['String'];
  rateId: Scalars['ID'];
  symbol: Scalars['String'];
  value: Scalars['String'];
  token: Scalars['String'];
}>;

export type EditRateMutation = {
  __typename?: 'Mutation';
  editRate?: {
    __typename?: 'EditInsuranceRate';
    rate?: {
      __typename?: 'InsuranceRateType';
      id: string;
      internalName: string;
      displayName: string;
      group: string;
      symbol: string;
      value: any;
    } | null;
  } | null;
};

export type CreateAuthorizationTokenMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  description: Scalars['String'];
  token: Scalars['String'];
}>;

export type CreateAuthorizationTokenMutation = {
  __typename?: 'Mutation';
  createAuthorizationToken?: {
    __typename?: 'CreateAuthorizationToken';
    token?: {
      __typename?: 'AuthorizationTokenType';
      id: string;
      token: string;
      valid: boolean;
    } | null;
  } | null;
};

export type CreateInsuranceRateMutationVariables = Exact<{
  displayName: Scalars['String'];
  group: Scalars['String'];
  internalName: Scalars['String'];
  symbol: Scalars['String'];
  token: Scalars['String'];
  value: Scalars['String'];
}>;

export type CreateInsuranceRateMutation = {
  __typename?: 'Mutation';
  createInsuranceRate?: {
    __typename?: 'CreateInsuranceRate';
    rate?: {
      __typename?: 'InsuranceRateType';
      id: string;
      internalName: string;
      displayName: string;
      value: any;
      group: string;
      symbol: string;
    } | null;
  } | null;
};

export type CreateInsurerQuoteMutationVariables = Exact<{
  quoteUuid: Scalars['String'];
  insurerQuoteIdentifier: Scalars['String'];
  pdfFile: Scalars['String'];
}>;

export type CreateInsurerQuoteMutation = {
  __typename?: 'Mutation';
  createInsurerQuote?: {
    __typename?: 'CreateInsurerQuote';
    ok?: boolean | null;
    insurerQuote?: {
      __typename?: 'InsurerQuoteType';
      insurerQuoteIdentifier?: string | null;
      llmResponseBlob: any;
      pdfFilePath?: string | null;
      isValid: boolean;
    } | null;
  } | null;
};

export type CreateInsurerQuoteComparisonMutationVariables = Exact<{
  insurerQuoteIds: Array<Scalars['String']> | Scalars['String'];
  forceRegen?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreateInsurerQuoteComparisonMutation = {
  __typename?: 'Mutation';
  createInsurerQuoteComparison?: {
    __typename?: 'CreateInsurerQuoteComparison';
    ok?: boolean | null;
    new?: boolean | null;
    insurerQuoteComparison?: {
      __typename?: 'InsurerQuoteComparisonType';
      id: string;
      llmResponseBlob: any;
      uuid: any;
      insurerQuotes: Array<{
        __typename?: 'InsurerQuoteType';
        insurerQuoteIdentifier?: string | null;
      }>;
    } | null;
  } | null;
};

export type CreateQuoteFromPdfMutationVariables = Exact<{
  token: Scalars['String'];
  businessLineId: Scalars['ID'];
  formIds: Array<Scalars['ID']> | Scalars['ID'];
  files: Array<FileInput> | FileInput;
}>;

export type CreateQuoteFromPdfMutation = {
  __typename?: 'Mutation';
  createQuoteFromPdf?: {
    __typename?: 'CreateQuoteFromPdf';
    taskPoolId?: string | null;
  } | null;
};

export type DeleteRateMutationVariables = Exact<{
  rateId: Scalars['ID'];
  token: Scalars['String'];
}>;

export type DeleteRateMutation = {
  __typename?: 'Mutation';
  deleteRate?: {
    __typename?: 'DeleteInsuranceRate';
    id?: string | null;
  } | null;
};

export type DeleteInsurerQuotesMutationVariables = Exact<{
  parentQuoteUuid: Scalars['UUID'];
  insurerQuoteIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteInsurerQuotesMutation = {
  __typename?: 'Mutation';
  deleteInsurerQuotes?: {
    __typename?: 'DeleteInsurerQuotes';
    ok?: boolean | null;
    deletedInsurerQuotes?: Array<{
      __typename?: 'InsurerQuoteType';
      insurerQuoteIdentifier?: string | null;
      llmResponseBlob: any;
      pdfFilePath?: string | null;
    } | null> | null;
  } | null;
};

export type EditBindingsMutationVariables = Exact<{
  token: Scalars['String'];
  bindingsToEdit: Array<EditBindingInput> | EditBindingInput;
}>;

export type EditBindingsMutation = {
  __typename?: 'Mutation';
  editBindings?: {
    __typename?: 'EditBindings';
    bindings?: Array<{
      __typename?: 'BindingType';
      id: string;
      label: string;
      bound: boolean;
      unboundReason: string;
    } | null> | null;
  } | null;
};

export type EditInsurancePolicyMutationVariables = Exact<{
  token: Scalars['String'];
  insurancePolicyId: Scalars['ID'];
  policyNumber?: InputMaybe<Scalars['String']>;
}>;

export type EditInsurancePolicyMutation = {
  __typename?: 'Mutation';
  editInsurancePolicy?: {
    __typename?: 'EditInsurancePolicy';
    policy?: {
      __typename?: 'InsurancePolicyType';
      id: string;
      policyNumber: string;
    } | null;
  } | null;
};

export type EditInsurancePolicyDatesMutationVariables = Exact<{
  token: Scalars['String'];
  insurancePolicyId: Scalars['ID'];
  policyEffectiveDate?: InputMaybe<Scalars['String']>;
  policyExpiryDate?: InputMaybe<Scalars['String']>;
}>;

export type EditInsurancePolicyDatesMutation = {
  __typename?: 'Mutation';
  editInsurancePolicyDates?: {
    __typename?: 'EditInsurancePolicyDates';
    insurancePolicy?: {
      __typename?: 'InsurancePolicyType';
      id: string;
      policyEffectiveDate?: any | null;
      policyExpiryDate?: any | null;
    } | null;
  } | null;
};

export type EditPerilMutationVariables = Exact<{
  token: Scalars['String'];
  perilId: Scalars['ID'];
  premium?: InputMaybe<Scalars['String']>;
  overridePremium?: InputMaybe<Scalars['Boolean']>;
}>;

export type EditPerilMutation = {
  __typename?: 'Mutation';
  editPeril?: {
    __typename?: 'EditPeril';
    peril?: {
      __typename?: 'PerilType';
      id: string;
      name: string;
      limit: number;
      deductible?: number | null;
      premium?: any | null;
      rate?: number | null;
      override: boolean;
      basis?: number | null;
    } | null;
  } | null;
};

export type EditPerilsMutationVariables = Exact<{
  token: Scalars['String'];
  perils: Array<EditPerilInput> | EditPerilInput;
}>;

export type EditPerilsMutation = {
  __typename?: 'Mutation';
  editPerils?: {
    __typename?: 'EditPerils';
    perils?: Array<{
      __typename?: 'PerilType';
      id: string;
      name: string;
      limit: number;
      deductible?: number | null;
      premium?: any | null;
      rate?: number | null;
      override: boolean;
      basis?: number | null;
    } | null> | null;
  } | null;
};

export type SendVagoBordereauEmailMutationVariables = Exact<{
  token: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;

export type SendVagoBordereauEmailMutation = {
  __typename?: 'Mutation';
  sendVagoBordereauEmail?: {
    __typename?: 'SendVagoBordereauEmail';
    emails?: Array<string | null> | null;
    startDate?: any | null;
    endDate?: any | null;
  } | null;
};

export type SetAuthorizationTokenValidityMutationVariables = Exact<{
  authorizationTokenId: Scalars['ID'];
  validity: Scalars['Boolean'];
  token: Scalars['String'];
}>;

export type SetAuthorizationTokenValidityMutation = {
  __typename?: 'Mutation';
  setAuthorizationTokenValidity?: {
    __typename?: 'SetAuthorizationTokenValidity';
    token?: {
      __typename?: 'AuthorizationTokenType';
      id: string;
      valid: boolean;
    } | null;
  } | null;
};

export type MyMutationMutationVariables = Exact<{
  token: Scalars['String'];
  uniqueId: Scalars['String'];
}>;

export type MyMutationMutation = {
  __typename?: 'Mutation';
  vagoForceReRate?: {
    __typename?: 'VagoForceReRate';
    ok?: boolean | null;
  } | null;
};

export type OrganizationFinalFormsSkeletonsQueryVariables = Exact<{
  includeDefault: Scalars['Boolean'];
}>;

export type OrganizationFinalFormsSkeletonsQuery = {
  __typename?: 'Query';
  organizationFinalForms?: Array<{
    __typename?: 'FinalFormType';
    id: string;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
    } | null;
    organization?: { __typename?: 'OrganizationType'; name: string } | null;
  } | null> | null;
};

export type PremiumProposalsFromHashQueryVariables = Exact<{
  hash: Scalars['UUID'];
  insurerName: Scalars['String'];
}>;

export type PremiumProposalsFromHashQuery = {
  __typename?: 'Query';
  premiumProposalsFromHash?: Array<{
    __typename?: 'PremiumProposalType';
    status?: string | null;
    amountInCents: number;
    identifier?: string | null;
    notes: string;
    referralReasons: any;
    rejectedReasons: any;
    quoteStructuredData?: {
      __typename?: 'StructuredQuoteDataType';
      businessName?: string | null;
      contactName?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      revenue?: number | null;
      contactAddress?: {
        __typename?: 'AddressType';
        formattedAddress?: string | null;
      } | null;
    } | null;
    quoteBusinessLine?: {
      __typename?: 'BusinessTypeType';
      displayName: string;
    } | null;
    insurer?: {
      __typename?: 'OrganizationType';
      name: string;
      logoUrl?: string | null;
    } | null;
  } | null> | null;
};

export type PublishNewFormMutationVariables = Exact<{
  questionInstances:
    | Array<InputMaybe<QuestionInstanceInput>>
    | InputMaybe<QuestionInstanceInput>;
  businessLineId: Scalars['ID'];
  policyId?: InputMaybe<Scalars['ID']>;
  token: Scalars['String'];
  qualifyingQuestion?: InputMaybe<QuestionInstanceInput>;
  required?: InputMaybe<Scalars['Boolean']>;
  policyExtension?: InputMaybe<
    Array<InputMaybe<PolicyExtensionInput>> | InputMaybe<PolicyExtensionInput>
  >;
}>;

export type PublishNewFormMutation = {
  __typename?: 'Mutation';
  createFinalForm?: {
    __typename?: 'CreateFinalForm';
    ok?: boolean | null;
    finalForm?: {
      __typename?: 'FinalFormType';
      id: string;
      required: boolean;
      businessLine?: {
        __typename?: 'BusinessTypeType';
        id: string;
        displayName: string;
        internalName: string;
      } | null;
      policy?: {
        __typename?: 'PolicyType';
        id: string;
        displayName: string;
        internalName: string;
      } | null;
      qualifyingQuestion?: {
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      } | null;
      policyExtension: Array<{
        __typename?: 'PolicyExtensionType';
        id: string;
        label: string;
        value?: string | null;
        questionId?: string | null;
        multiplier?: string | null;
      }>;
      questionInstances: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        subQuestions: Array<{
                          __typename?: 'QuestionInstanceType';
                          id: string;
                          apiName?: string | null;
                          component: string;
                          dataType: string;
                          required: boolean;
                          askOnRenewal: boolean;
                          prefillOnRenewal: boolean;
                          propsBlob?: any | null;
                          modified?: boolean | null;
                          defaultValue?: string | null;
                          displayNames: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          labels: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          helpTexts: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          parentForms: Array<{
                            __typename?: 'AmendedFinalFormType';
                            id: string;
                          }>;
                        }>;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
    } | null;
  } | null;
};

export type AllAuthorizationTokensQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type AllAuthorizationTokensQuery = {
  __typename?: 'Query';
  allAuthorizationTokens?: Array<{
    __typename?: 'AuthorizationTokenType';
    id: string;
    token: string;
    description: string;
    valid: boolean;
    createdAt?: any | null;
    organization: { __typename?: 'OrganizationType'; name: string };
  } | null> | null;
};

export type AllOrganizationQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type AllOrganizationQuery = {
  __typename?: 'Query';
  allOrganizations?: Array<{
    __typename?: 'OrganizationType';
    id: string;
    name: string;
    category: CoreOrganizationCategoryChoices;
  } | null> | null;
};

export type ApplicationSpecificInsurerQuotesQueryVariables = Exact<{
  quoteUuid: Scalars['String'];
}>;

export type ApplicationSpecificInsurerQuotesQuery = {
  __typename?: 'Query';
  applicationSpecificInsurerQuotes?: Array<{
    __typename?: 'InsurerQuoteType';
    id: string;
    insurerQuoteIdentifier?: string | null;
    llmResponseBlob: any;
    pdfFilePath?: string | null;
    isValid: boolean;
  } | null> | null;
};

export type FinalFormQueryVariables = Exact<{
  id: Scalars['ID'];
  token?: InputMaybe<Scalars['String']>;
}>;

export type FinalFormQuery = {
  __typename?: 'Query';
  finalForm?: {
    __typename?: 'FinalFormType';
    id: string;
    required: boolean;
    questionInstances: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
    qualifyingQuestion?: {
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
      internalName: string;
      sicCode: number;
      industry: {
        __typename?: 'IndustryType';
        id: string;
        displayName: string;
      };
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
    } | null;
    policyExtension: Array<{
      __typename?: 'PolicyExtensionType';
      id: string;
      label: string;
      multiplier?: string | null;
      questionId?: string | null;
      value?: string | null;
    }>;
  } | null;
};

export type GenericBusinessWordcloudQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GenericBusinessWordcloudQuery = {
  __typename?: 'Query';
  genericBusinessWordcloud?: Array<{
    __typename?: 'WordcloudType';
    value?: string | null;
    frequency?: number | null;
  } | null> | null;
};

export type GenericBusinessWordcloudIndividualQuotesQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GenericBusinessWordcloudIndividualQuotesQuery = {
  __typename?: 'Query';
  genericBusinessWordcloudIndividualQuotes?: Array<{
    __typename?: 'IndividualQuoteWordcloudType';
    quoteIdentifierName?: string | null;
    quoteUuid?: string | null;
    additionalInformation?: string | null;
    wordcloud?: Array<{
      __typename?: 'WordcloudType';
      value?: string | null;
      frequency?: number | null;
    } | null> | null;
  } | null> | null;
};

export type GeographyReportQueryVariables = Exact<{
  organizationName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  token: Scalars['String'];
}>;

export type GeographyReportQuery = {
  __typename?: 'Query';
  geographyReport?: Array<{
    __typename?: 'GeographyReportType';
    uniqueId?: string | null;
    dateAdded?: any | null;
    mailingAddress?: {
      __typename?: 'GenericAddressType';
      address?: string | null;
      city?: string | null;
      province?: string | null;
      postal?: string | null;
    } | null;
  } | null> | null;
};

export type GetInsurerQuoteQueryVariables = Exact<{
  quoteUuid: Scalars['String'];
  insurerQuoteIdentifier: Scalars['String'];
}>;

export type GetInsurerQuoteQuery = {
  __typename?: 'Query';
  getInsurerQuote?: {
    __typename?: 'InsurerQuoteType';
    id: string;
    insurerQuoteIdentifier?: string | null;
    llmResponseBlob: any;
    pdfFilePath?: string | null;
    isValid: boolean;
  } | null;
};

export type GetTaskPoolQueryVariables = Exact<{
  taskPoolId: Scalars['ID'];
}>;

export type GetTaskPoolQuery = {
  __typename?: 'Query';
  getTaskPool?: {
    __typename?: 'TaskPoolType';
    id: any;
    description?: string | null;
    additionalInformation: any;
    error?: string | null;
    tasks: Array<{
      __typename?: 'TaskType';
      id: string;
      description?: string | null;
      status: CoreTaskStatusChoices;
      order?: number | null;
      additionalInformation: any;
      numSubtasks: number;
      numCompletedSubtasks: number;
    }>;
  } | null;
};

export type GetVagoSubscribersQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetVagoSubscribersQuery = {
  __typename?: 'Query';
  getVagoSubscribers?: Array<{
    __typename?: 'VaGoSubscriberType';
    name: string;
  } | null> | null;
};

export type InsuranceRateAuditsQueryVariables = Exact<{
  token: Scalars['String'];
  organizationName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
}>;

export type InsuranceRateAuditsQuery = {
  __typename?: 'Query';
  getRateTableAudit?: Array<{
    __typename?: 'InsuranceRateAuditType';
    id: string;
    actualRateId?: number | null;
    oldValue?: any | null;
    oldGroup: string;
    oldSymbol: string;
    oldDisplayName: string;
    newValue?: any | null;
    newGroup: string;
    newSymbol: string;
    newDisplayName: string;
    userEmail: string;
    createdAt?: any | null;
    action: CoreInsuranceRateAuditActionChoices;
    headers: any;
    organization?: { __typename?: 'OrganizationType'; name: string } | null;
  } | null> | null;
};

export type OrganizationSpecificInsuranceRateQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type OrganizationSpecificInsuranceRateQuery = {
  __typename?: 'Query';
  organizationSpecificInsuranceRates?: Array<{
    __typename?: 'InsuranceRateType';
    id: string;
    internalName: string;
    displayName: string;
    group: string;
    symbol: string;
    value: any;
  } | null> | null;
};

export type QuestionsTableQuestionsQueryVariables = Exact<{
  token: Scalars['String'];
  fuzzy: Scalars['Boolean'];
  search?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;

export type QuestionsTableQuestionsQuery = {
  __typename?: 'Query';
  questionsTableQuestions?: {
    __typename?: 'QuestionInstanceConnection';
    edges: Array<{
      __typename?: 'QuestionInstanceEdge';
      node?: {
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        subQuestions: Array<{
                          __typename?: 'QuestionInstanceType';
                          id: string;
                          apiName?: string | null;
                          component: string;
                          dataType: string;
                          required: boolean;
                          askOnRenewal: boolean;
                          prefillOnRenewal: boolean;
                          propsBlob?: any | null;
                          modified?: boolean | null;
                          defaultValue?: string | null;
                          displayNames: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          labels: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          helpTexts: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          parentForms: Array<{
                            __typename?: 'AmendedFinalFormType';
                            id: string;
                          }>;
                        }>;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type QuoteInsurancePoliciesQueryVariables = Exact<{
  token: Scalars['String'];
  uniqueId: Scalars['String'];
}>;

export type QuoteInsurancePoliciesQuery = {
  __typename?: 'Query';
  quoteInsurancePolicies?: Array<{
    __typename?: 'InsurancePolicyType';
    id: string;
    description: string;
    policyEffectiveDate?: any | null;
    policyExpiryDate?: any | null;
    policyNumber: string;
    coverages: Array<{
      __typename?: 'CoverageType';
      id: string;
      name: string;
      perils: Array<{
        __typename?: 'PerilType';
        id: string;
        name: string;
        limit: number;
        deductible?: number | null;
        premium?: any | null;
        rate?: number | null;
        override: boolean;
        basis?: number | null;
      }>;
      coveredLocations: Array<{
        __typename?: 'CoveredLocationType';
        id: string;
        description?: string | null;
        address?: string | null;
        city?: string | null;
        province?: string | null;
        postal?: string | null;
      }>;
    }>;
  } | null> | null;
};

export type VagoClientSummaryQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type VagoClientSummaryQuery = {
  __typename?: 'Query';
  vagoClientSummary?: string | null;
};

export type VagoDownloadApplicationDocumentationQueryVariables = Exact<{
  token: Scalars['String'];
  quoteUuid: Scalars['ID'];
  includeApplication?: InputMaybe<Scalars['Boolean']>;
}>;

export type VagoDownloadApplicationDocumentationQuery = {
  __typename?: 'Query';
  vagoDownloadApplicationDocumentation?: {
    __typename?: 'VagoDownloadApplicationdocumentationType';
    filename?: string | null;
    data?: string | null;
  } | null;
};

export type VagoIbcListQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type VagoIbcListQuery = {
  __typename?: 'Query';
  vagoIbcList?: Array<{
    __typename?: 'BusinessTypeType';
    id: string;
    displayName: string;
    sicCode: number;
  } | null> | null;
};

export type QuestionsDatabaseQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;

export type QuestionsDatabaseQuery = {
  __typename?: 'Query';
  questionsDatabase?: Array<{
    __typename?: 'QuestionInstanceType';
    id: string;
    apiName?: string | null;
    component: string;
    dataType: string;
    required: boolean;
    propsBlob?: any | null;
    parentForms: Array<{
      __typename?: 'AmendedFinalFormType';
      id: string;
      businessLine?: {
        __typename?: 'BusinessTypeType';
        id: string;
        displayName: string;
      } | null;
      policy?: {
        __typename?: 'PolicyType';
        id: string;
        displayName: string;
      } | null;
    }>;
    subQuestions: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      propsBlob?: any | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        propsBlob?: any | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          propsBlob?: any | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            propsBlob?: any | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              propsBlob?: any | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                propsBlob?: any | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  propsBlob?: any | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    propsBlob?: any | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      propsBlob?: any | null;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
    }>;
    displayNames: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    labels: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
    helpTexts: Array<{
      __typename?: 'LanguageAwareStringType';
      value: string;
      language?: {
        __typename?: 'LanguageType';
        shortName: string;
        fullName: string;
      } | null;
    }>;
  } | null> | null;
};

export type GetAllReferrersQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetAllReferrersQuery = {
  __typename?: 'Query';
  allReferrers?: Array<{
    __typename?: 'ReferrerType';
    id: string;
    name: string;
    referralCode: string;
  } | null> | null;
};

export type CreateReferrerMutationVariables = Exact<{
  name: Scalars['String'];
  referralCode: Scalars['String'];
  token: Scalars['String'];
}>;

export type CreateReferrerMutation = {
  __typename?: 'Mutation';
  createReferrer?: {
    __typename?: 'CreateReferrer';
    ok?: boolean | null;
    referrer?: {
      __typename?: 'ReferrerType';
      id: string;
      name: string;
      referralCode: string;
    } | null;
  } | null;
};

export type CheckReferralCodeValidityQueryVariables = Exact<{
  referralCode: Scalars['String'];
}>;

export type CheckReferralCodeValidityQuery = {
  __typename?: 'Query';
  checkReferralCodeValidity?: boolean | null;
};

export type GetReferrerXlsxQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetReferrerXlsxQuery = {
  __typename?: 'Query';
  referrerXlsx?: string | null;
};

export type RelayQuotesQueryVariables = Exact<{
  token: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type RelayQuotesQuery = {
  __typename?: 'Query';
  relayQuotes?: {
    __typename?: 'QuoteConnection';
    edges: Array<{
      __typename?: 'QuoteEdge';
      node?: {
        __typename?: 'QuoteType';
        id: string;
        uniqueID: any;
        friendlyId?: string | null;
        status: string;
        dateAdded: any;
        businessLine?: {
          __typename?: 'BusinessTypeType';
          id: string;
          displayName: string;
        } | null;
        structuredData?: {
          __typename?: 'StructuredQuoteDataType';
          businessLegalName?: string | null;
          businessName?: string | null;
          businessTradeName?: string | null;
          contactName?: string | null;
        } | null;
        vagoCommercialPackStructuredData?: {
          __typename?: 'VagoCommercialPackStructuredDataType';
          clientName?: string | null;
          transaction?: string | null;
          epicLookupCode?: string | null;
          cglLimit?: number | null;
          tiv?: number | null;
          cglPremium?: number | null;
          propertyPremium?: number | null;
          user?: string | null;
          aragBound?: boolean | null;
          avivaBound?: boolean | null;
          propertyBound?: boolean | null;
          liabilityBound?: boolean | null;
        } | null;
        bindings: Array<{
          __typename?: 'BindingType';
          id: string;
          label: string;
          bound: boolean;
          unboundReason: string;
        }>;
      } | null;
    } | null>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type SendRenewalEmailMutationVariables = Exact<{
  uuid: Scalars['UUID'];
  token: Scalars['String'];
}>;

export type SendRenewalEmailMutation = {
  __typename?: 'Mutation';
  sendRenewalEmail?: {
    __typename?: 'SendRenewalEmail';
    ok?: boolean | null;
  } | null;
};

export type SomeFinalFormsQueryVariables = Exact<{
  policyId?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
}>;

export type SomeFinalFormsQuery = {
  __typename?: 'Query';
  someFinalForms?: Array<{
    __typename?: 'AmendedFinalFormType';
    id: string;
    required: boolean;
    questionInstances: Array<{
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      subQuestions: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    }>;
    qualifyingQuestion?: {
      __typename?: 'QuestionInstanceType';
      id: string;
      apiName?: string | null;
      component: string;
      dataType: string;
      required: boolean;
      askOnRenewal: boolean;
      prefillOnRenewal: boolean;
      propsBlob?: any | null;
      modified?: boolean | null;
      defaultValue?: string | null;
      displayNames: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      labels: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      helpTexts: Array<{
        __typename?: 'LanguageAwareStringType';
        value: string;
        language?: {
          __typename?: 'LanguageType';
          shortName: string;
          fullName: string;
        } | null;
      }>;
      parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
    } | null;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
      internalName: string;
      sicCode: number;
      industry: {
        __typename?: 'IndustryType';
        id: string;
        displayName: string;
      };
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
      internalName: string;
    } | null;
    policyExtension: Array<{
      __typename?: 'PolicyExtensionType';
      id: string;
      label: string;
      multiplier?: string | null;
      questionId?: string | null;
      value?: string | null;
    }>;
  } | null> | null;
};

export type MinimalSomeFinalFormsQueryVariables = Exact<{
  policyId?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
}>;

export type MinimalSomeFinalFormsQuery = {
  __typename?: 'Query';
  someFinalForms?: Array<{
    __typename?: 'AmendedFinalFormType';
    id: string;
    businessLine?: {
      __typename?: 'BusinessTypeType';
      id: string;
      displayName: string;
    } | null;
    policy?: {
      __typename?: 'PolicyType';
      id: string;
      displayName: string;
    } | null;
  } | null> | null;
};

export type CreateStatisticalSessionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateStatisticalSessionMutation = {
  __typename?: 'Mutation';
  createStatisticalSession?: {
    __typename?: 'CreateStatisticalSession';
    session?: { __typename?: 'StatisticalSessionType'; sessionId: any } | null;
  } | null;
};

export type CreateStatisticalEventMutationVariables = Exact<{
  sessionId?: InputMaybe<Scalars['UUID']>;
  eventType: StatisticalEventTypes;
  data?: InputMaybe<Scalars['JSONString']>;
  userEmail?: InputMaybe<Scalars['String']>;
  quoteUuid?: InputMaybe<Scalars['String']>;
}>;

export type CreateStatisticalEventMutation = {
  __typename?: 'Mutation';
  createStatisticalEvent?: {
    __typename?: 'CreateStatisticalEvent';
    session?: { __typename?: 'StatisticalSessionType'; sessionId: any } | null;
    event?: { __typename?: 'StatisticalEventType'; eventId: any } | null;
  } | null;
};

export type SubmitAnswersMutationVariables = Exact<{
  languageCode: Scalars['String'];
  businessLineId?: InputMaybe<Scalars['ID']>;
  completedFormInputs:
    | Array<InputMaybe<CompletedFormInput>>
    | InputMaybe<CompletedFormInput>;
  clientEmail?: InputMaybe<Scalars['String']>;
  clientName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  currentQuoteUUID?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  additionalInformation?: InputMaybe<Scalars['String']>;
  renewalInProgress: Scalars['Boolean'];
  token?: InputMaybe<Scalars['String']>;
  producerId?: InputMaybe<Scalars['String']>;
}>;

export type SubmitAnswersMutation = {
  __typename?: 'Mutation';
  createQuote?: {
    __typename?: 'CreateQuote';
    ok?: boolean | null;
    quote?: {
      __typename?: 'QuoteType';
      id: string;
      uniqueID: any;
      status: string;
      dateAdded: any;
      businessLine?: {
        __typename?: 'BusinessTypeType';
        id: string;
        displayName: string;
      } | null;
      structuredData?: {
        __typename?: 'StructuredQuoteDataType';
        businessLegalName?: string | null;
        businessName?: string | null;
        businessTradeName?: string | null;
      } | null;
      premiumProposals: Array<{
        __typename?: 'PremiumProposalType';
        identifier?: string | null;
        status?: string | null;
        amountInCents: number;
        insurer?: { __typename?: 'OrganizationType'; name: string } | null;
        policies: Array<{
          __typename?: 'PolicyType';
          displayName: string;
          internalName: string;
        }>;
      }>;
    } | null;
  } | null;
};

export type TriggerQuestionDeduplicationQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type TriggerQuestionDeduplicationQuery = {
  __typename?: 'Query';
  triggerQuestionDeduplication?: {
    __typename?: 'DedupeResults';
    ok?: boolean | null;
    questionsDeduped?: number | null;
    error?: string | null;
  } | null;
};

export type UpdateExistingFormMutationVariables = Exact<{
  finalFormId: Scalars['ID'];
  questionInstances:
    | Array<InputMaybe<QuestionInstanceInput>>
    | InputMaybe<QuestionInstanceInput>;
  qualifyingQuestion?: InputMaybe<QuestionInstanceInput>;
  businessLineId: Scalars['ID'];
  token: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
  policyExtension?: InputMaybe<
    Array<InputMaybe<PolicyExtensionInput>> | InputMaybe<PolicyExtensionInput>
  >;
}>;

export type UpdateExistingFormMutation = {
  __typename?: 'Mutation';
  updateFinalForm?: {
    __typename?: 'UpdateFinalForm';
    ok?: boolean | null;
    finalForm?: {
      __typename?: 'FinalFormType';
      id: string;
      required: boolean;
      qualifyingQuestion?: {
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      } | null;
      policyExtension: Array<{
        __typename?: 'PolicyExtensionType';
        label: string;
        value?: string | null;
        questionId?: string | null;
        multiplier?: string | null;
      }>;
      questionInstances: Array<{
        __typename?: 'QuestionInstanceType';
        id: string;
        apiName?: string | null;
        component: string;
        dataType: string;
        required: boolean;
        askOnRenewal: boolean;
        prefillOnRenewal: boolean;
        propsBlob?: any | null;
        modified?: boolean | null;
        defaultValue?: string | null;
        subQuestions: Array<{
          __typename?: 'QuestionInstanceType';
          id: string;
          apiName?: string | null;
          component: string;
          dataType: string;
          required: boolean;
          askOnRenewal: boolean;
          prefillOnRenewal: boolean;
          propsBlob?: any | null;
          modified?: boolean | null;
          defaultValue?: string | null;
          subQuestions: Array<{
            __typename?: 'QuestionInstanceType';
            id: string;
            apiName?: string | null;
            component: string;
            dataType: string;
            required: boolean;
            askOnRenewal: boolean;
            prefillOnRenewal: boolean;
            propsBlob?: any | null;
            modified?: boolean | null;
            defaultValue?: string | null;
            subQuestions: Array<{
              __typename?: 'QuestionInstanceType';
              id: string;
              apiName?: string | null;
              component: string;
              dataType: string;
              required: boolean;
              askOnRenewal: boolean;
              prefillOnRenewal: boolean;
              propsBlob?: any | null;
              modified?: boolean | null;
              defaultValue?: string | null;
              subQuestions: Array<{
                __typename?: 'QuestionInstanceType';
                id: string;
                apiName?: string | null;
                component: string;
                dataType: string;
                required: boolean;
                askOnRenewal: boolean;
                prefillOnRenewal: boolean;
                propsBlob?: any | null;
                modified?: boolean | null;
                defaultValue?: string | null;
                subQuestions: Array<{
                  __typename?: 'QuestionInstanceType';
                  id: string;
                  apiName?: string | null;
                  component: string;
                  dataType: string;
                  required: boolean;
                  askOnRenewal: boolean;
                  prefillOnRenewal: boolean;
                  propsBlob?: any | null;
                  modified?: boolean | null;
                  defaultValue?: string | null;
                  subQuestions: Array<{
                    __typename?: 'QuestionInstanceType';
                    id: string;
                    apiName?: string | null;
                    component: string;
                    dataType: string;
                    required: boolean;
                    askOnRenewal: boolean;
                    prefillOnRenewal: boolean;
                    propsBlob?: any | null;
                    modified?: boolean | null;
                    defaultValue?: string | null;
                    subQuestions: Array<{
                      __typename?: 'QuestionInstanceType';
                      id: string;
                      apiName?: string | null;
                      component: string;
                      dataType: string;
                      required: boolean;
                      askOnRenewal: boolean;
                      prefillOnRenewal: boolean;
                      propsBlob?: any | null;
                      modified?: boolean | null;
                      defaultValue?: string | null;
                      subQuestions: Array<{
                        __typename?: 'QuestionInstanceType';
                        id: string;
                        apiName?: string | null;
                        component: string;
                        dataType: string;
                        required: boolean;
                        askOnRenewal: boolean;
                        prefillOnRenewal: boolean;
                        propsBlob?: any | null;
                        modified?: boolean | null;
                        defaultValue?: string | null;
                        subQuestions: Array<{
                          __typename?: 'QuestionInstanceType';
                          id: string;
                          apiName?: string | null;
                          component: string;
                          dataType: string;
                          required: boolean;
                          askOnRenewal: boolean;
                          prefillOnRenewal: boolean;
                          propsBlob?: any | null;
                          modified?: boolean | null;
                          defaultValue?: string | null;
                          displayNames: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          labels: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          helpTexts: Array<{
                            __typename?: 'LanguageAwareStringType';
                            value: string;
                            language?: {
                              __typename?: 'LanguageType';
                              shortName: string;
                              fullName: string;
                            } | null;
                          }>;
                          parentForms: Array<{
                            __typename?: 'AmendedFinalFormType';
                            id: string;
                          }>;
                        }>;
                        displayNames: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        labels: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        helpTexts: Array<{
                          __typename?: 'LanguageAwareStringType';
                          value: string;
                          language?: {
                            __typename?: 'LanguageType';
                            shortName: string;
                            fullName: string;
                          } | null;
                        }>;
                        parentForms: Array<{
                          __typename?: 'AmendedFinalFormType';
                          id: string;
                        }>;
                      }>;
                      displayNames: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      labels: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      helpTexts: Array<{
                        __typename?: 'LanguageAwareStringType';
                        value: string;
                        language?: {
                          __typename?: 'LanguageType';
                          shortName: string;
                          fullName: string;
                        } | null;
                      }>;
                      parentForms: Array<{
                        __typename?: 'AmendedFinalFormType';
                        id: string;
                      }>;
                    }>;
                    displayNames: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    labels: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    helpTexts: Array<{
                      __typename?: 'LanguageAwareStringType';
                      value: string;
                      language?: {
                        __typename?: 'LanguageType';
                        shortName: string;
                        fullName: string;
                      } | null;
                    }>;
                    parentForms: Array<{
                      __typename?: 'AmendedFinalFormType';
                      id: string;
                    }>;
                  }>;
                  displayNames: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  labels: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  helpTexts: Array<{
                    __typename?: 'LanguageAwareStringType';
                    value: string;
                    language?: {
                      __typename?: 'LanguageType';
                      shortName: string;
                      fullName: string;
                    } | null;
                  }>;
                  parentForms: Array<{
                    __typename?: 'AmendedFinalFormType';
                    id: string;
                  }>;
                }>;
                displayNames: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                labels: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                helpTexts: Array<{
                  __typename?: 'LanguageAwareStringType';
                  value: string;
                  language?: {
                    __typename?: 'LanguageType';
                    shortName: string;
                    fullName: string;
                  } | null;
                }>;
                parentForms: Array<{
                  __typename?: 'AmendedFinalFormType';
                  id: string;
                }>;
              }>;
              displayNames: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              labels: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              helpTexts: Array<{
                __typename?: 'LanguageAwareStringType';
                value: string;
                language?: {
                  __typename?: 'LanguageType';
                  shortName: string;
                  fullName: string;
                } | null;
              }>;
              parentForms: Array<{
                __typename?: 'AmendedFinalFormType';
                id: string;
              }>;
            }>;
            displayNames: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            labels: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            helpTexts: Array<{
              __typename?: 'LanguageAwareStringType';
              value: string;
              language?: {
                __typename?: 'LanguageType';
                shortName: string;
                fullName: string;
              } | null;
            }>;
            parentForms: Array<{
              __typename?: 'AmendedFinalFormType';
              id: string;
            }>;
          }>;
          displayNames: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          labels: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          helpTexts: Array<{
            __typename?: 'LanguageAwareStringType';
            value: string;
            language?: {
              __typename?: 'LanguageType';
              shortName: string;
              fullName: string;
            } | null;
          }>;
          parentForms: Array<{
            __typename?: 'AmendedFinalFormType';
            id: string;
          }>;
        }>;
        displayNames: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        labels: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        helpTexts: Array<{
          __typename?: 'LanguageAwareStringType';
          value: string;
          language?: {
            __typename?: 'LanguageType';
            shortName: string;
            fullName: string;
          } | null;
        }>;
        parentForms: Array<{ __typename?: 'AmendedFinalFormType'; id: string }>;
      }>;
    } | null;
  } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
  token: Scalars['String'];
  quoteEmails:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
  quoteFromEmail?: InputMaybe<Scalars['String']>;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization?: {
    __typename?: 'UpdateOrganization';
    organization?: {
      __typename?: 'OrganizationType';
      name: string;
      id: string;
      quoteFromEmail?: string | null;
      quoteEmails: any;
    } | null;
  } | null;
};

export type WhoamiOrganizationQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type WhoamiOrganizationQuery = {
  __typename?: 'Query';
  whoamiOrganization?: {
    __typename?: 'OrganizationType';
    name: string;
    category: CoreOrganizationCategoryChoices;
    id: string;
    quoteEmails: any;
    quoteFromEmail?: string | null;
  } | null;
};

export const LanguageAwareStringFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LanguageAwareStringFieldsFragment, unknown>;
export const QuestionInstanceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionInstanceFieldsFragment, unknown>;
export const FinalFormFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalFormType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyExtension' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinalFormFieldsFragment, unknown>;
export const AmendedFormFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AmendedFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AmendedFinalFormType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyExtension' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AmendedFormFieldsFragment, unknown>;
export const PerilFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PerilFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PerilType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deductible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'override' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basis' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PerilFieldsFragment, unknown>;
export const PolicyFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PolicyType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PolicyFieldsFragment, unknown>;
export const QuestionDatabaseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionDatabaseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionDatabaseFieldsFragment, unknown>;
export const OptaAddressFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OptaAddressFieldsFragment, unknown>;
export const OptaFusDataFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OptaFusDataFieldsFragment, unknown>;
export const OptaFloodScoreDataFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OptaFloodScoreDataFieldsFragment, unknown>;
export const OptaIclarifyCommercialConstructionDataFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OptaIclarifyCommercialConstructionDataFieldsFragment,
  unknown
>;
export const OptaCommercialPerilScoreDataFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OptaCommercialPerilScoreDataFieldsFragment,
  unknown
>;
export const AnswerInstanceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnswerInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'google' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfRatings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFusData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFusDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFloodscoreData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFloodScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaIclarifyCommercialConstructionData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OptaIclarifyCommercialConstructionDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaCommercialPerilScoreData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaCommercialPerilScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AnswerInstanceFieldsFragment, unknown>;
export const QuoteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuoteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuoteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'friendlyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'QuoteStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structuredData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLegalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessTradeName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPhoneNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'revenue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formattedAddress' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alreadyContactedBrokerName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multiplier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AnswerInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subAnswers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AnswerInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subAnswers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'AnswerInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subAnswers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'AnswerInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subAnswers',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'AnswerInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subAnswers',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'AnswerInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subAnswers',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'AnswerInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subAnswers',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'AnswerInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subAnswers',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'AnswerInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subAnswers',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'AnswerInstanceFields',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureTime' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'producerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bound' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unboundReason' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnswerInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'google' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfRatings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFusData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFusDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFloodscoreData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFloodScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaIclarifyCommercialConstructionData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OptaIclarifyCommercialConstructionDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaCommercialPerilScoreData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaCommercialPerilScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuoteFieldsFragment, unknown>;
export const ArchivedQuoteFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArchivedQuoteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArchivedQuoteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'friendlyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ArchivedQuoteStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structuredData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLegalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessTradeName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPhoneNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'revenue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formattedAddress' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alreadyContactedBrokerName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multiplier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AnswerInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subAnswers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AnswerInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subAnswers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'AnswerInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subAnswers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'AnswerInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subAnswers',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'AnswerInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subAnswers',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'AnswerInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subAnswers',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'AnswerInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subAnswers',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'AnswerInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subAnswers',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'AnswerInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subAnswers',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'AnswerInstanceFields',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureTime' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'producerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bound' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unboundReason' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnswerInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'google' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfRatings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFusData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFusDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFloodscoreData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFloodScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaIclarifyCommercialConstructionData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OptaIclarifyCommercialConstructionDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaCommercialPerilScoreData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaCommercialPerilScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchivedQuoteFieldsFragment, unknown>;
export const DownloadVagoBordereauDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'downloadVagoBordereau' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadVagoBordereau' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadVagoBordereauMutation,
  DownloadVagoBordereauMutationVariables
>;
export const GetFormDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFormData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formDataQuery' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFormDataQuery, GetFormDataQueryVariables>;
export const GetDailyQuotesDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDailyQuotesData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyQuotesData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'quotesToday' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dailyQuotes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weeklyQuotePercentDiff' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDailyQuotesDataQuery,
  GetDailyQuotesDataQueryVariables
>;
export const GetDailyPoliciesDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDailyPoliciesData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyPoliciesData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policiesToday' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dailyPolicies' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'weeklyPolicyPercentDiff' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDailyPoliciesDataQuery,
  GetDailyPoliciesDataQueryVariables
>;
export const GetTotalQuotesDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTotalQuotesData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalQuotesData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalQuotes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyQuotes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyQuotePercentDiff' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTotalQuotesDataQuery,
  GetTotalQuotesDataQueryVariables
>;
export const GetTopBusinessesDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopBusinessesData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topBusinessesData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'topBusinesses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalCount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTopBusinessesDataQuery,
  GetTopBusinessesDataQueryVariables
>;
export const GetMonthlyQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMonthlyQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthlyQuotesData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'monthlyQuotes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMonthlyQuotesQuery,
  GetMonthlyQuotesQueryVariables
>;
export const GetLocationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLocationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geoLocations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'long' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLocationDataQuery,
  GetLocationDataQueryVariables
>;
export const GetSubmissionTimeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubmissionTime' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submissionTimesData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'submissionTimes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSubmissionTimeQuery,
  GetSubmissionTimeQueryVariables
>;
export const GetNewQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNewQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newQuotesData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newQuotes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uniqueID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'friendlyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessLine' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'structuredData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessLegalName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'businessName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessTradeName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contactName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetNewQuotesQuery, GetNewQuotesQueryVariables>;
export const AllBusinessSelectorInputsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allBusinessSelectorInputs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allBusinessSelectorInputs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inputs' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessSelected' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'associatedQuoteUuid' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllBusinessSelectorInputsQuery,
  AllBusinessSelectorInputsQueryVariables
>;
export const AllBusinessTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allBusinessTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allBusinessTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aliases' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllBusinessTypesQuery,
  AllBusinessTypesQueryVariables
>;
export const RelayBusinessesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'relayBusinesses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '2000' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relayBusinesses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cursor' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'internalName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'keywords' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aliases' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sicCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'industry' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isDeletable' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RelayBusinessesQuery,
  RelayBusinessesQueryVariables
>;
export const BusinessTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'businessTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allBusinessTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aliases' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeletable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessTypesQuery, BusinessTypesQueryVariables>;
export const BusinessTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'businessType' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aliases' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessTypeQuery, BusinessTypeQueryVariables>;
export const IndustryTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'industryTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allIndustries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IndustryTypesQuery, IndustryTypesQueryVariables>;
export const CreateBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'displayName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sicCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'aliases' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBusiness' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'displayName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'keywords' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'aliases' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'aliases' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sicCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sicCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'business' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aliases' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sicCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isDeletable' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBusinessMutation,
  CreateBusinessMutationVariables
>;
export const UpdateBusinessesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBusinesses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'displayName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'aliases' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sicCode' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBusinessList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'displayName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'keywords' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'aliases' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'aliases' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sicCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sicCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'business' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keywords' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aliases' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sicCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isDeletable' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBusinessesMutation,
  UpdateBusinessesMutationVariables
>;
export const DeleteBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBusiness' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteBusinessMutation,
  DeleteBusinessMutationVariables
>;
export const AllFinalFormModifiersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allFinalFormModifiers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allFinalFormModifiers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'masked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'business' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllFinalFormModifiersQuery,
  AllFinalFormModifiersQueryVariables
>;
export const AllFinalFormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allFinalForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allFinalForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLine' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllFinalFormsQuery, AllFinalFormsQueryVariables>;
export const AllLanguagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allLanguages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allLanguages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllLanguagesQuery, AllLanguagesQueryVariables>;
export const PoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'policies' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allPolicies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isDeletable' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PoliciesQuery, PoliciesQueryVariables>;
export const PolicyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'policy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'displayName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'displayName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PolicyQuery, PolicyQueryVariables>;
export const CreatePolicyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPolicy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'displayName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPolicy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'displayName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isDeletable' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePolicyMutation,
  CreatePolicyMutationVariables
>;
export const DeletePolicyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePolicy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePolicy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deletedId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;
export const UpdatePolicyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePolicy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'displayName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePolicy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'displayName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isDeletable' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePolicyMutation,
  UpdatePolicyMutationVariables
>;
export const GetAllQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateAdded' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLine' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'structuredData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessLegalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessTradeName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'vagoCommercialPackStructuredData',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'transaction' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'epicLookupCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cglLimit' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'tiv' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cglPremium' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'propertyPremium' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'user' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aragBound' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avivaBound' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'propertyBound' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'liabilityBound' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bound' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unboundReason' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllQuotesQuery, GetAllQuotesQueryVariables>;
export const AllStatisticalSessionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allStatisticalSessions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organization' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allStatisticalSessions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organization' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'organization' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sessionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeCreated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'events' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeCreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventType' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quote' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uniqueID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllStatisticalSessionsQuery,
  AllStatisticalSessionsQueryVariables
>;
export const AvailablePoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'availablePolicies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableFormPolicies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AvailablePoliciesQuery,
  AvailablePoliciesQueryVariables
>;
export const BusinessAndPolicySpecificFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'businessAndPolicySpecificForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessAndPolicySpecificForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalFormFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalFormType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyExtension' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BusinessAndPolicySpecificFormQuery,
  BusinessAndPolicySpecificFormQueryVariables
>;
export const BusinessCompletionPercentagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'businessCompletionPercentages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessCompletionPercentages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessDisplayName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rawPercentage' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'rawBegun' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rawCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumAPosteriori' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lowerCi' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upperCi' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BusinessCompletionPercentagesQuery,
  BusinessCompletionPercentagesQueryVariables
>;
export const GetBusinessSpecificFormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBusinessSpecificForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'someFinalForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'business' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionInstances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subQuestions',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'QuestionInstanceFields',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qualifyingQuestion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLine' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policyExtension' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multiplier' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBusinessSpecificFormsQuery,
  GetBusinessSpecificFormsQueryVariables
>;
export const CheckFormIntegrityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkFormIntegrity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkFormIntegrity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckFormIntegrityQuery,
  CheckFormIntegrityQueryVariables
>;
export const CheckTokenValidityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkTokenValidity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkTokenValidity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckTokenValidityQuery,
  CheckTokenValidityQueryVariables
>;
export const CreateFinalFormModifierDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFinalFormModifier' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'existingFormId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionInstances' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'QuestionInstanceInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'apiNamesForRemoval' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFinalFormModifier' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'existingFormId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'existingFormId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionInstances' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionInstances' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'apiNamesForRemoval' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'apiNamesForRemoval' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finalFormModifier' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'business' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionInstances' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subQuestions',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'QuestionInstanceFields',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'subQuestions',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'FragmentSpread',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'QuestionInstanceFields',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apiNamesForRemoval' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFinalFormModifierMutation,
  CreateFinalFormModifierMutationVariables
>;
export const DeleteFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'formId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFinalForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'formId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'formId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFormMutation, DeleteFormMutationVariables>;
export const FinalFormModifierDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'finalFormModifier' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'raiseErrors' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalFormModifier' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'raiseErrors' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'raiseErrors' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'business' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionInstances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subQuestions',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'QuestionInstanceFields',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'apiNamesForRemoval' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinalFormModifierQuery,
  FinalFormModifierQueryVariables
>;
export const GetMissingPoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getMissingPolicies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doesGeneralInfoExist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allEmptyBusinessSpecificPolicies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMissingPoliciesQuery,
  GetMissingPoliciesQueryVariables
>;
export const FormModifierRelevantFinalFormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'formModifierRelevantFinalForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formModifierRelevantFinalForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLine' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionInstances' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FormModifierRelevantFinalFormsQuery,
  FormModifierRelevantFinalFormsQueryVariables
>;
export const FormModifierRelevantFinalFormsFullDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'formModifierRelevantFinalFormsFull' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'formModifierRelevantFinalForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalFormFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalFormType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyExtension' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FormModifierRelevantFinalFormsFullQuery,
  FormModifierRelevantFinalFormsFullQueryVariables
>;
export const GenerateQuoteConfirmationCorrespondenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateQuoteConfirmationCorrespondence' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'generateQuoteConfirmationCorrespondence',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hash' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateQuoteConfirmationCorrespondenceMutation,
  GenerateQuoteConfirmationCorrespondenceMutationVariables
>;
export const GetAllOptaDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getAllOptaData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllOptaData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFusData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFusDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFloodscoreData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFloodScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaIclarifyCommercialConstructionData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OptaIclarifyCommercialConstructionDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaCommercialPerilScoreData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaCommercialPerilScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllOptaDataMutation,
  GetAllOptaDataMutationVariables
>;
export const GetGeneralInformationFormForBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getGeneralInformationFormForBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'getGeneralInformationFormForBusiness',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalFormFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalFormType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyExtension' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGeneralInformationFormForBusinessQuery,
  GetGeneralInformationFormForBusinessQueryVariables
>;
export const LocationsFromQuoteHashDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'locationsFromQuoteHash' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationsFromQuoteHash' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hash' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hash' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AnswerInstanceFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnswerInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'google' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfRatings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFusData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFusDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFloodscoreData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFloodScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaIclarifyCommercialConstructionData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OptaIclarifyCommercialConstructionDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaCommercialPerilScoreData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaCommercialPerilScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LocationsFromQuoteHashQuery,
  LocationsFromQuoteHashQueryVariables
>;
export const GetOptaCommercialPerilScoreDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getOptaCommercialPerilScoreData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOptaCommercialPerilScoreData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optaCommercialPerilScoreData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OptaCommercialPerilScoreDataFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOptaCommercialPerilScoreDataMutation,
  GetOptaCommercialPerilScoreDataMutationVariables
>;
export const GetOptaFloodScoreDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getOptaFloodScoreData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOptaFloodscoreData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optaFloodscoreData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OptaFloodScoreDataFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOptaFloodScoreDataMutation,
  GetOptaFloodScoreDataMutationVariables
>;
export const GetOptaFusDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'getOptaFusData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOptaFusData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optaFusData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OptaFusDataFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOptaFusDataMutation,
  GetOptaFusDataMutationVariables
>;
export const GetOptaIclarifyCommercialConstructionFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'getOptaIclarifyCommercialConstructionFields',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'answer' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'getOptaIclarifyCommercialConstructionData',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'answer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'answer' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'optaIclarifyCommercialConstructionData',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'OptaIclarifyCommercialConstructionDataFields',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOptaIclarifyCommercialConstructionFieldsMutation,
  GetOptaIclarifyCommercialConstructionFieldsMutationVariables
>;
export const QuoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Quote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uID' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuoteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnswerInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'google' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfRatings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFusData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFusDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFloodscoreData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFloodScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaIclarifyCommercialConstructionData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OptaIclarifyCommercialConstructionDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaCommercialPerilScoreData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaCommercialPerilScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuoteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuoteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'friendlyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'QuoteStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structuredData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLegalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessTradeName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPhoneNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'revenue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formattedAddress' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alreadyContactedBrokerName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multiplier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AnswerInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subAnswers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AnswerInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subAnswers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'AnswerInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subAnswers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'AnswerInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subAnswers',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'AnswerInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subAnswers',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'AnswerInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subAnswers',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'AnswerInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subAnswers',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'AnswerInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subAnswers',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'AnswerInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subAnswers',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'AnswerInstanceFields',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureTime' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'producerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bound' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unboundReason' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuoteQuery, QuoteQueryVariables>;
export const ArchivedQuoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ArchivedQuote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'archiveID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archivedQuote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archiveID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'archiveID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ArchivedQuoteFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnswerInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'google' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfRatings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFusData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFusDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFloodscoreData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFloodScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaIclarifyCommercialConstructionData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OptaIclarifyCommercialConstructionDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaCommercialPerilScoreData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaCommercialPerilScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArchivedQuoteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArchivedQuoteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'friendlyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ArchivedQuoteStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structuredData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLegalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessTradeName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPhoneNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'revenue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formattedAddress' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alreadyContactedBrokerName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multiplier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AnswerInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subAnswers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AnswerInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subAnswers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'AnswerInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subAnswers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'AnswerInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subAnswers',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'AnswerInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subAnswers',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'AnswerInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subAnswers',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'AnswerInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subAnswers',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'AnswerInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subAnswers',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'AnswerInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subAnswers',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'AnswerInstanceFields',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureTime' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'producerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bound' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unboundReason' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArchivedQuoteQuery, ArchivedQuoteQueryVariables>;
export const QuoteVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QuoteVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'uniqueID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quoteVersions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uniqueID' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'quoteId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateAdded' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuoteVersionsQuery, QuoteVersionsQueryVariables>;
export const QuoteFromHashDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'quoteFromHash' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'delete' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quoteFromHash' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hash' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hash' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'delete' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'delete' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'QuoteType' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuoteFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ArchivedQuoteType' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ArchivedQuoteFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaAddressFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaAddressType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'StreetAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'StreetDirection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'UnitNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'LegalDescription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LegalSubdivision' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'Section' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Township' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Range' } },
                { kind: 'Field', name: { kind: 'Name', value: 'Meridian' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'Municipality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'Province' } },
          { kind: 'Field', name: { kind: 'Name', value: 'PostalCode' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFusDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFusDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Commercial' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'CommercialGrade' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RiskSCORDetail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Adjustment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Adjustment' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'AdjustmentType',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RiskSCOR' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PublicFireProtectionClassification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'WaterSystem' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Dwelling' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'DwellingGrades' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AllOtherAreasGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FirehallProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HydrantProtectedGrade' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Grade' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'WaterSystem' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'RespondingFirehall' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'FirefightersPerFireStation',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FullTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Volunteer' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'ProtectionGradeTerritories',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Commercial' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Dwelling' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GoverningAgency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'GradingDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Name' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'PumperFlowImperialGallonsPerMinute',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RoadDistanceKilometres' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'SuperiorTankerServiceAccreditationDate',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'EarthquakeCodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'EarthquakeCode' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SystemName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FusMaps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FusMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FusContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Business' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'HydrantCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'HydrantCount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NumberOfHydrants' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Ownership' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RadiusMetres' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'SearchMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaFloodScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaFloodScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'FloodScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodScore' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ResidentialModifiers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'InspectionDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'InspectionDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SponsoredInspection',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ArchitecturalStyleType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'AuxiliaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BathroomCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'CommercialIndicator',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ExteriorWallType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FinishedBasement' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FoundationType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'GarageNumberOfCars',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'GarageType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'KitchenCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'OutbuildingPresent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'PlumbingType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'PrimaryHeatingType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RoofType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SquareFootage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StoreyCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'SwimmingPoolType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'YearBuilt' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'MultiResidentialModifiers',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'BasementPresent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ConstructionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'FloorLevel' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'AverageAnnualLossValues',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AdditionalDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Value' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AverageAnnualLossScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Deductible' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Default' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodScore' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Limit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Premium' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Building' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Contents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Total' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'RateFactor' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'Zone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'FloodMap' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ContentURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Expiry' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'MIMEType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'FloodEvent' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'OptaIclarifyCommercialConstructionDataFields',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'OptaIclarifyCommercialConstructionDataType',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Messages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'Message' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Property' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'LotSize' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LotSizeUnitOfMeasurement' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PropertyType' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Buildings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Building' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ConstructionFeatures',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Basement' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementArea',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'BasementAreaUnitOfMeasurement',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BasementPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'BuildingUpdates',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'BuildingUpdate',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CombustibilityClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'CommercialExteriorWallTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ExteriorWallType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ConstructionClass',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Elevator' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorCount',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ElevatorPresent',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeight',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FloorHeightUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Heating' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingFuelSources',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingFuelSource',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'HeatingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'HeatingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'LoadingDockPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfStoreys',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'NumberOfUnits',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Parking' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingPresent',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ParkingTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ParkingType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PlumbingTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PlumbingType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'RoofAttributes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofConstructions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofConstruction',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofSurfaces',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofSurface',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'RoofTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'RoofType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorArea',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'TotalFloorAreaUnitOfMeasurement',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'WaterHeater',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterFuelType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WaterHeaterType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Wiring' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringPanelTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringPanelType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'WiringTypes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'WiringType',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'YearBuilt' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'Protection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'FireAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'PhysicalSecurityTypes',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'PhysicalSecurityType',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SecurityAlarmType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerPresent',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'SprinklerTypeCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'StructureID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'IBCIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICIndustryDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SICCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OptaCommercialPerilScoreDataType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'Address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptaAddressFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'PropertyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'CasualtyPerilScores' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'Peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LossCostScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PerilType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PredictedLossCost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'AllPerilsScore' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'LossCostScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'PredictedLossCost' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AnswerInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'label' } },
          { kind: 'Field', name: { kind: 'Name', value: 'helpText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'details' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'google' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'placeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numberOfRatings' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'opta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFusData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFusDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optaFloodscoreData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaFloodScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaIclarifyCommercialConstructionData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value:
                                  'OptaIclarifyCommercialConstructionDataFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'optaCommercialPerilScoreData',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'OptaCommercialPerilScoreDataFields',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuoteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuoteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'friendlyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'QuoteStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structuredData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLegalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessTradeName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPhoneNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'revenue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formattedAddress' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alreadyContactedBrokerName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multiplier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AnswerInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subAnswers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AnswerInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subAnswers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'AnswerInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subAnswers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'AnswerInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subAnswers',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'AnswerInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subAnswers',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'AnswerInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subAnswers',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'AnswerInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subAnswers',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'AnswerInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subAnswers',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'AnswerInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subAnswers',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'AnswerInstanceFields',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureTime' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'producerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bound' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unboundReason' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArchivedQuoteFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArchivedQuoteType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uniqueID' } },
          { kind: 'Field', name: { kind: 'Name', value: 'friendlyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ArchivedQuoteStatus' },
            name: { kind: 'Name', value: 'status' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'structuredData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLegalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessTradeName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactPhoneNumber' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'revenue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'formattedAddress' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alreadyContactedBrokerName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'completedForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'extensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'multiplier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AnswerInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subAnswers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AnswerInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subAnswers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'AnswerInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subAnswers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'AnswerInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subAnswers',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'AnswerInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subAnswers',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'AnswerInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subAnswers',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'AnswerInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subAnswers',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'AnswerInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subAnswers',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'AnswerInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subAnswers',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'AnswerInstanceFields',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatureTime' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'producerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bindings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bound' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unboundReason' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuoteFromHashQuery, QuoteFromHashQueryVariables>;
export const GetQuotePdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getQuotePdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'uniqueID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'archiveID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'renewalOnly' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'downloadType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotePdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uniqueID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archiveID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'archiveID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renewalOnly' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'renewalOnly' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'downloadType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'downloadType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pdfString' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetQuotePdfQuery, GetQuotePdfQueryVariables>;
export const MarkAsRenewalInProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markAsRenewalInProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAsRenewalInProgress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkAsRenewalInProgressMutation,
  MarkAsRenewalInProgressMutationVariables
>;
export const MarkAsRenewalLinkGeneratedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markAsRenewalLinkGenerated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markAsRenewalLinkGenerated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkAsRenewalLinkGeneratedMutation,
  MarkAsRenewalLinkGeneratedMutationVariables
>;
export const MarkQuoteAsConfirmationInProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markQuoteAsConfirmationInProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markQuoteAsConfirmationInProgress' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hash' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hash' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkQuoteAsConfirmationInProgressMutation,
  MarkQuoteAsConfirmationInProgressMutationVariables
>;
export const EditRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'displayName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'symbol' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'displayName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'symbol' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'symbol' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'symbol' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditRateMutation, EditRateMutationVariables>;
export const CreateAuthorizationTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAuthorizationToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAuthorizationToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'organizationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'token' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAuthorizationTokenMutation,
  CreateAuthorizationTokenMutationVariables
>;
export const CreateInsuranceRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createInsuranceRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'displayName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'group' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'internalName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'symbol' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInsuranceRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'displayName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'group' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'internalName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'internalName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'symbol' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'symbol' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'symbol' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateInsuranceRateMutation,
  CreateInsuranceRateMutationVariables
>;
export const CreateInsurerQuoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createInsurerQuote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quoteUuid' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pdfFile' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInsurerQuote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quoteUuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'quoteUuid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pdfFile' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pdfFile' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurerQuote' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'llmResponseBlob' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pdfFilePath' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isValid' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateInsurerQuoteMutation,
  CreateInsurerQuoteMutationVariables
>;
export const CreateInsurerQuoteComparisonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInsurerQuoteComparison' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insurerQuoteIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'forceRegen' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInsurerQuoteComparison' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'insurerQuoteIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'insurerQuoteIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forceRegen' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'forceRegen' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurerQuoteComparison' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurerQuotes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'insurerQuoteIdentifier',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'llmResponseBlob' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                { kind: 'Field', name: { kind: 'Name', value: 'new' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateInsurerQuoteComparisonMutation,
  CreateInsurerQuoteComparisonMutationVariables
>;
export const CreateQuoteFromPdfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createQuoteFromPdf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessLineId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'formIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'files' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FileInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createQuoteFromPdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessLineId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessLineId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'formIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'formIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'files' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'files' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'taskPoolId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateQuoteFromPdfMutation,
  CreateQuoteFromPdfMutationVariables
>;
export const DeleteRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'rateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'rateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'rateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRateMutation, DeleteRateMutationVariables>;
export const DeleteInsurerQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteInsurerQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentQuoteUuid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insurerQuoteIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteInsurerQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentQuoteUuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'parentQuoteUuid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'insurerQuoteIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'insurerQuoteIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deletedInsurerQuotes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'llmResponseBlob' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pdfFilePath' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteInsurerQuotesMutation,
  DeleteInsurerQuotesMutationVariables
>;
export const EditBindingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editBindings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bindingsToEdit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'EditBindingInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editBindings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bindingsToEdit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bindingsToEdit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bindings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bound' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unboundReason' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditBindingsMutation,
  EditBindingsMutationVariables
>;
export const EditInsurancePolicyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editInsurancePolicy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insurancePolicyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyNumber' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editInsurancePolicy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'insurancePolicyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'insurancePolicyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyNumber' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyNumber' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policyNumber' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditInsurancePolicyMutation,
  EditInsurancePolicyMutationVariables
>;
export const EditInsurancePolicyDatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editInsurancePolicyDates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insurancePolicyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyEffectiveDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyExpiryDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editInsurancePolicyDates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'insurancePolicyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'insurancePolicyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyEffectiveDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyEffectiveDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyExpiryDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyExpiryDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurancePolicy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policyEffectiveDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policyExpiryDate' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditInsurancePolicyDatesMutation,
  EditInsurancePolicyDatesMutationVariables
>;
export const EditPerilDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editPeril' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perilId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'premium' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'overridePremium' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPeril' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perilId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'perilId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'premium' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'premium' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'overridePremium' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'overridePremium' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'peril' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PerilFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PerilFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PerilType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deductible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'override' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basis' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPerilMutation, EditPerilMutationVariables>;
export const EditPerilsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editPerils' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'perils' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'EditPerilInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editPerils' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'perils' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'perils' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perils' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PerilFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PerilFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PerilType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deductible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'override' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basis' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditPerilsMutation, EditPerilsMutationVariables>;
export const SendVagoBordereauEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendVagoBordereauEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendVagoBordereauEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emails' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendVagoBordereauEmailMutation,
  SendVagoBordereauEmailMutationVariables
>;
export const SetAuthorizationTokenValidityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setAuthorizationTokenValidity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'authorizationTokenId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'validity' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setAuthorizationTokenValidity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authorizationTokenId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'authorizationTokenId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'validity' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'validity' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'token' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetAuthorizationTokenValidityMutation,
  SetAuthorizationTokenValidityMutationVariables
>;
export const MyMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MyMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'uniqueId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vagoForceReRate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uniqueId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyMutationMutation, MyMutationMutationVariables>;
export const OrganizationFinalFormsSkeletonsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'organizationFinalFormsSkeletons' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeDefault' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationFinalForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeDefault' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeDefault' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLine' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrganizationFinalFormsSkeletonsQuery,
  OrganizationFinalFormsSkeletonsQueryVariables
>;
export const PremiumProposalsFromHashDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'premiumProposalsFromHash' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hash' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insurerName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'premiumProposalsFromHash' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hash' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'hash' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'insurerName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'insurerName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quoteStructuredData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactPhoneNumber' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revenue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contactAddress' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'formattedAddress' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quoteBusinessLine' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logoUrl' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amountInCents' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'identifier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralReasons' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejectedReasons' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PremiumProposalsFromHashQuery,
  PremiumProposalsFromHashQueryVariables
>;
export const PublishNewFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'publishNewForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionInstances' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'QuestionInstanceInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessLineId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'QuestionInstanceInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'required' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyExtension' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PolicyExtensionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFinalForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionInstances' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionInstances' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessLineId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessLineId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'qualifyingQuestion' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'qualifyingQuestion' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'required' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'required' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyExtension' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyExtension' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finalForm' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'required' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessLine' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'internalName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'internalName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualifyingQuestion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policyExtension' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'questionId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'multiplier' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionInstances' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subQuestions',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'QuestionInstanceFields',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'subQuestions',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'FragmentSpread',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'QuestionInstanceFields',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublishNewFormMutation,
  PublishNewFormMutationVariables
>;
export const AllAuthorizationTokensDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allAuthorizationTokens' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allAuthorizationTokens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'valid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllAuthorizationTokensQuery,
  AllAuthorizationTokensQueryVariables
>;
export const AllOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allOrganizations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllOrganizationQuery,
  AllOrganizationQueryVariables
>;
export const ApplicationSpecificInsurerQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'applicationSpecificInsurerQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quoteUuid' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationSpecificInsurerQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quoteUuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'quoteUuid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'llmResponseBlob' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pdfFilePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicationSpecificInsurerQuotesQuery,
  ApplicationSpecificInsurerQuotesQueryVariables
>;
export const FinalFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'finalForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finalForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FinalFormFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FinalFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FinalFormType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyExtension' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinalFormQuery, FinalFormQueryVariables>;
export const GenericBusinessWordcloudDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'genericBusinessWordcloud' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'genericBusinessWordcloud' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenericBusinessWordcloudQuery,
  GenericBusinessWordcloudQueryVariables
>;
export const GenericBusinessWordcloudIndividualQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'genericBusinessWordcloudIndividualQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'genericBusinessWordcloudIndividualQuotes',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quoteIdentifierName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'quoteUuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalInformation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wordcloud' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenericBusinessWordcloudIndividualQuotesQuery,
  GenericBusinessWordcloudIndividualQuotesQueryVariables
>;
export const GeographyReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'geographyReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geographyReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'organizationName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uniqueId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateAdded' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mailingAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'province' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postal' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GeographyReportQuery,
  GeographyReportQueryVariables
>;
export const GetInsurerQuoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getInsurerQuote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quoteUuid' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInsurerQuote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quoteUuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'quoteUuid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'insurerQuoteIdentifier' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'llmResponseBlob' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pdfFilePath' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInsurerQuoteQuery,
  GetInsurerQuoteQueryVariables
>;
export const GetTaskPoolDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTaskPool' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskPoolId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTaskPool' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskPoolId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskPoolId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'additionalInformation' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'additionalInformation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numSubtasks' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numCompletedSubtasks' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTaskPoolQuery, GetTaskPoolQueryVariables>;
export const GetVagoSubscribersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVagoSubscribers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getVagoSubscribers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetVagoSubscribersQuery,
  GetVagoSubscribersQueryVariables
>;
export const InsuranceRateAuditsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'insuranceRateAudits' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'organizationName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getRateTableAudit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'organizationName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'organizationName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'actualRateId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'oldGroup' } },
                { kind: 'Field', name: { kind: 'Name', value: 'oldSymbol' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oldDisplayName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newGroup' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newSymbol' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'newDisplayName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headers' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InsuranceRateAuditsQuery,
  InsuranceRateAuditsQueryVariables
>;
export const OrganizationSpecificInsuranceRateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'organizationSpecificInsuranceRate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizationSpecificInsuranceRates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'group' } },
                { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrganizationSpecificInsuranceRateQuery,
  OrganizationSpecificInsuranceRateQueryVariables
>;
export const QuestionsTableQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'questionsTableQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fuzzy' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionsTableQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fuzzy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fuzzy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subQuestions',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'QuestionInstanceFields',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'subQuestions',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'FragmentSpread',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'QuestionInstanceFields',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QuestionsTableQuestionsQuery,
  QuestionsTableQuestionsQueryVariables
>;
export const QuoteInsurancePoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'quoteInsurancePolicies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'uniqueId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quoteInsurancePolicies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uniqueId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policyEffectiveDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policyExpiryDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coverages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'perils' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PerilFields' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coveredLocations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'province' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'postal' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policyNumber' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PerilFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PerilType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deductible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'override' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basis' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QuoteInsurancePoliciesQuery,
  QuoteInsurancePoliciesQueryVariables
>;
export const VagoClientSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'vagoClientSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vagoClientSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VagoClientSummaryQuery,
  VagoClientSummaryQueryVariables
>;
export const VagoDownloadApplicationDocumentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'vagoDownloadApplicationDocumentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quoteUuid' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeApplication' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'vagoDownloadApplicationDocumentation',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quoteUuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'quoteUuid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeApplication' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeApplication' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VagoDownloadApplicationDocumentationQuery,
  VagoDownloadApplicationDocumentationQueryVariables
>;
export const VagoIbcListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'vagoIbcList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vagoIbcList' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VagoIbcListQuery, VagoIbcListQueryVariables>;
export const QuestionsDatabaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'questionsDatabase' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionsDatabase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionDatabaseFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentForms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessLine' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionDatabaseFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionDatabaseFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionDatabaseFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionDatabaseFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionDatabaseFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionDatabaseFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionDatabaseFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionDatabaseFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionDatabaseFields',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionDatabaseFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QuestionsDatabaseQuery,
  QuestionsDatabaseQueryVariables
>;
export const GetAllReferrersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllReferrers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allReferrers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referralCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllReferrersQuery,
  GetAllReferrersQueryVariables
>;
export const CreateReferrerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createReferrer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReferrer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referralCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'referralCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referrer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'referralCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReferrerMutation,
  CreateReferrerMutationVariables
>;
export const CheckReferralCodeValidityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkReferralCodeValidity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkReferralCodeValidity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referralCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'referralCode' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckReferralCodeValidityQuery,
  CheckReferralCodeValidityQueryVariables
>;
export const GetReferrerXlsxDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReferrerXlsx' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrerXlsx' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReferrerXlsxQuery,
  GetReferrerXlsxQueryVariables
>;
export const RelayQuotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'relayQuotes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cursor' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '100' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'relayQuotes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cursor' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uniqueID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'friendlyId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'businessLine' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'structuredData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'businessLegalName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'businessName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'businessTradeName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'contactName',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'vagoCommercialPackStructuredData',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clientName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'transaction',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'epicLookupCode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cglLimit' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tiv' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cglPremium' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'propertyPremium',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aragBound' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avivaBound' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'propertyBound',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'liabilityBound',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bindings' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bound' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'unboundReason',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RelayQuotesQuery, RelayQuotesQueryVariables>;
export const SendRenewalEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendRenewalEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendRenewalEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendRenewalEmailMutation,
  SendRenewalEmailMutationVariables
>;
export const SomeFinalFormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'someFinalForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'someFinalForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'business' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AmendedFormFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AmendedFormFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AmendedFinalFormType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionInstances' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'QuestionInstanceFields' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subQuestions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'QuestionInstanceFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessLine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sicCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalName' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'policyExtension' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'multiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SomeFinalFormsQuery, SomeFinalFormsQueryVariables>;
export const MinimalSomeFinalFormsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'minimalSomeFinalForms' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'someFinalForms' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'business' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessLine' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'policy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MinimalSomeFinalFormsQuery,
  MinimalSomeFinalFormsQueryVariables
>;
export const CreateStatisticalSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStatisticalSession' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStatisticalSession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'session' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStatisticalSessionMutation,
  CreateStatisticalSessionMutationVariables
>;
export const CreateStatisticalEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStatisticalEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eventType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'StatisticalEventTypes' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JSONString' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userEmail' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quoteUuid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStatisticalEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sessionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'eventType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'data' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userEmail' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quoteUuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'quoteUuid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'session' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'event' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStatisticalEventMutation,
  CreateStatisticalEventMutationVariables
>;
export const SubmitAnswersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitAnswers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'languageCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessLineId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'completedFormInputs' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CompletedFormInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientEmail' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentQuoteUUID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'signature' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'renewalInProgress' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'producerId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createQuote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'languageCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'languageCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessLineId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessLineId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'completedForms' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'completedFormInputs' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientEmail' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'status' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uniqueId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currentQuoteUUID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'signature' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'signature' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'referralCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'referralCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'additionalInformation' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'additionalInformation' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'renewalInProgress' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'renewalInProgress' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'producerId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'producerId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quote' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uniqueID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateAdded' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessLine' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'structuredData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessLegalName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'businessName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessTradeName',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'premiumProposals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'identifier' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'insurer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'policies' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'PolicyFields',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amountInCents' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PolicyFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PolicyType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitAnswersMutation,
  SubmitAnswersMutationVariables
>;
export const TriggerQuestionDeduplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'triggerQuestionDeduplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'triggerQuestionDeduplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsDeduped' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TriggerQuestionDeduplicationQuery,
  TriggerQuestionDeduplicationQueryVariables
>;
export const UpdateExistingFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateExistingForm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'finalFormId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionInstances' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'QuestionInstanceInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'qualifyingQuestion' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'QuestionInstanceInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessLineId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'required' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'policyExtension' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PolicyExtensionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFinalForm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'finalFormId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'finalFormId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionInstances' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionInstances' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'qualifyingQuestion' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'qualifyingQuestion' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessLineId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessLineId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'required' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'required' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'policyExtension' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'policyExtension' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ok' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finalForm' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualifyingQuestion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'policyExtension' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'questionId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'multiplier' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'required' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionInstances' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'QuestionInstanceFields',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subQuestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'QuestionInstanceFields',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'subQuestions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'QuestionInstanceFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'QuestionInstanceFields',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subQuestions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'QuestionInstanceFields',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'subQuestions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'QuestionInstanceFields',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'subQuestions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'FragmentSpread',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'QuestionInstanceFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'subQuestions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'FragmentSpread',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'QuestionInstanceFields',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'subQuestions',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'FragmentSpread',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'QuestionInstanceFields',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'subQuestions',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'FragmentSpread',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'QuestionInstanceFields',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LanguageAwareStringFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LanguageAwareStringType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'language' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'QuestionInstanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionInstanceType' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayNames' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labels' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpTexts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LanguageAwareStringFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'component' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'prefillOnRenewal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propsBlob' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parentForms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultValue' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateExistingFormMutation,
  UpdateExistingFormMutationVariables
>;
export const UpdateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quoteEmails' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'quoteFromEmail' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quoteEmails' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'quoteEmails' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'quoteFromEmail' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'quoteFromEmail' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quoteFromEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quoteEmails' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const WhoamiOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'whoamiOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'whoamiOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quoteEmails' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quoteFromEmail' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WhoamiOrganizationQuery,
  WhoamiOrganizationQueryVariables
>;
