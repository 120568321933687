import { useEffect } from 'react';
import { useUsingVagoSettings } from '../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
import { CircularProgress, styled, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useLazyQuery } from '@apollo/client';
import { VAGO_DOWNLOAD_APPLICATION_DOCUMENTATION } from '../../../../queries';
import { downloadZipFile } from '@calefy-inc/utility';
import { useSnackbar } from 'notistack';
import { StoreState } from '../../../../store';
import { useSelector } from 'react-redux';
import Bugsnag from '@bugsnag/js';

const getSubmittedQuoteUuid = (state: StoreState) => {
  for (let quote of state.quoteWizard.submittedQuotes) {
    if (quote.uniqueID) {
      return quote.uniqueID;
    }
  }
};

export const VagoAutomaticDocumentDownloadComponent = () => {
  const onVago = useUsingVagoSettings();
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const quoteUuid = useSelector(getSubmittedQuoteUuid);
  const [vagoDownloadApplicationDocumentation, { loading, error, data }] =
    useLazyQuery(VAGO_DOWNLOAD_APPLICATION_DOCUMENTATION, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (quoteUuid && token && onVago) {
      vagoDownloadApplicationDocumentation({
        variables: {
          token,
          quoteUuid,
          includeApplication: true,
          // handleNonvital: true
        },
      });
    }
  }, [token, quoteUuid, onVago]);

  // download the file once we get it
  useEffect(() => {
    if (
      data?.vagoDownloadApplicationDocumentation?.data &&
      data.vagoDownloadApplicationDocumentation.filename
    ) {
      (async () => {
        await downloadZipFile(
          data.vagoDownloadApplicationDocumentation?.data || '',
          data.vagoDownloadApplicationDocumentation?.filename || '',
        );
        enqueueSnackbar('Download complete!', { variant: 'success' });
      })();
    }
  }, [data]);

  // if there's an error, display it in the snackbar
  useEffect(() => {
    if (error) {
      // enqueueSnackbar(error.message, { variant: 'error' });
      Bugsnag.notify(error);
    }
  }, [error]);

  if (!onVago || !token) {
    return null;
  }
  return (
    <VagoDownloadContainer>
      {loading ? (
        <>
          <Typography>Fetching documents...</Typography>
          <CircularProgress sx={{ ml: 2 }} size='1rem' />
        </>
      ) : null}
      {data ? (
        <>
          <Typography>Download complete!</Typography>
          <CheckIcon sx={{ ml: 1, color: 'success.main' }} />
        </>
      ) : null}
      {error ? (
        <ErrorP>
          Error downloading the documents: <pre>{error.message}</pre>
        </ErrorP>
      ) : null}
    </VagoDownloadContainer>
  );
};

const VagoDownloadContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const ErrorP = styled(Typography)(({ theme }) => ({
  colour: theme.palette.error.main,
}));
